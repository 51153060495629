import React, {FC, useEffect, useState} from "react";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import {CUSTOM_AVAILABILITY, DEFAULT_AVAILABILITY, IExperience, NOT_ACTIVE} from "../../models/entities/IExperience";
import {useCurrentQueriesStates} from "../../utils/hooks/currentQueriesStates";
import CenteredLoadingSpinner from "../common/CenteredLoadingSpinner";
import {GridLayout, GridLayoutItem} from "@progress/kendo-react-layout";
import {CustomSwitch} from "../common/ValidatedFields";
import BaseButton from "../common/BaseButton";
import {
    IDays_Times,
    IExperienceAvailabilityRange
} from "../../models/entities/experienceAvailability/IExperienceAvailabilityRange";
import {AVA_NEW} from "models/entities/experienceAvailability/IExperienceAvailability";
import DateRangePickerKendo from "components/common/DateRangePickerKendo";
import {useAppSelector} from "../../store";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import {KeyValueMultiselectEvo} from "components/common/KeyValueMultiSelectEvo";
import {IDateRange} from "models/helpers/IDateRange";
import {Typography} from "@mui/material";
import DeleteDialog from "../common/DeleteDialog";
import SaveIcon from "@mui/icons-material/Save";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
    useAddExperienceAvailabilityMutation,
    useDeleteExperienceAvailabilityMutation,
    useGetExperienceAvailabilityQuery,
    useGetTimesMultiselectQuery,
    useUpdateExperienceAvailabilityMutation,
    useUpdateExperienceMutation
} from "../../store/api/experiences";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {compareDate, fromDateToPeriod, fromPeriodToDateNew} from "../../utils/dateHelper";
import {orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {RadioButton, Switch} from "@progress/kendo-react-inputs";
import {SCREEN_MD, SCREEN_XL, useWindowSize} from "../common/ScreenSizes";
import {useCurrentExperience} from "../../utils/hooks/currentExperience";
import {IPlanner} from "../../models/entities/IPlanner";
import {IType} from "../../models/entities/types/IType";

const ACTION_DELETE = 1;
const ACTION_CHANGE = 2;
const ACTION_SWITCH = 3;
const ACTION_AVA_CHANGE = 4;

const TITLE_ROW = 1;
const ADDING = 'adding';
const DELETING = 'deleting';
const UPDATING = 'updating';
const NOTHING = 'nothing';

const BigScreenMacroCols = [
    {width: '25vw'},
    {width: '55vw'},
    {width: '15vw'},
];
const BigScreenMacroColsDEF = [
    {width: '15vw'},
    {width: '60vw'},
    {width: '15vw'},
];
const MediumScreenMacroCols = [
    {width: '345px'},
    {width: '805px'},
    {width: '190px'},
];
const MediumScreenMacroColsDEF = [
    {width: '95px'},
    {width: '940px'},
    {width: '190px'},
];
const SmallScreenMacroCols = [
    {width: '345px'},
    {width: '805px'},
    {width: '190px'},
];

const SmallScreenMacroColsDEF = [
    {width: '0px'},
    {width: '805px'},
    {width: '190px'},
];

const BigScreenDayCols = [
    {width: '5vw'},
    {width: '10vw'},
    {width: '40vw'},
];
const BigScreenDayColsDEF = [
    {width: '5vw'},
    {width: '10vw'},
    {width: '40vw'},
];
const MediumScreenDayCols = [
    {width: '110px'},
    {width: '150px'},
    {width: '400px'},
];
const MediumScreenDayColsDEF = [
    {width: '110px'},
    {width: '150px'},
    {width: '400px'},
];
const SmallScreenDayCols = [
    {width: '110px'},
    {width: '150px'},
    {width: '400px'},
];
const SmallScreenDayColsDEF = [
    {width: '110px'},
    {width: '150px'},
    {width: '400px'},
];



//DAL server per i day, mi arrivano solo key, ma in formato array<string>, devo trasformarle in key value,
// però bisogna considerare la retrocompatibilità, in passato sono stati salvati orari del tipo [{"key":"08:00","label":"08:00"}]
const formatAvaRangeFromApi  = (avarange: IExperienceAvailabilityRange) : IExperienceAvailabilityRange=>{
    return {
        ...avarange,
        //RIFORMATTO LE DATE DEL PERIODO
        date_start : avarange.date_start  ? fromPeriodToDateNew(avarange.date_start as string) : '',
        date_end : avarange.date_start  ? fromPeriodToDateNew(avarange.date_end as string) : '',
        availability_days : avarange.availability_days.map((day : IDays_Times)=>{
            return{
                ...day,
                times_ava :  day.times_ava.map((time : any)=>{
                    if(typeof time == "string") {
                        return {
                            key: time,
                            label: time
                        }
                    }else if (typeof time == 'object'){
                        return {
                            key: time.key,
                            label: time.label
                        }
                    }
                })
            }
        })
    }
}

// AL server devono essere mandate solo le keys, quindi da un array di key value devo estrarre solo le keys
const formatAvaRangeToApi  = (avarange: IExperienceAvailabilityRange) : IExperienceAvailabilityRange=>{
   return {
        ...avarange,
        //RIFORMATTO LE DATE DEL PERIODO
        date_start : fromDateToPeriod(avarange.date_start as Date),
        date_end : fromDateToPeriod(avarange.date_end as Date),
        isAvailabilityCustom: CUSTOM_AVAILABILITY,
        availability_days : avarange.availability_days.map((day : IDays_Times)=>{
            return{
                ...day,
                times_ava :  day.times_ava.map((time : IType)=>{
                    return time.key
                })
            }
        })
    }
}

const formatAvaRangeToApi_asDefaultPeriod  = (avarange: IExperienceAvailabilityRange) : IExperienceAvailabilityRange=>{
    return {
        ...avarange,
        //RIFORMATTO LE DATE DEL PERIODO a tutto l'anno
        isAvailabilityCustom: DEFAULT_AVAILABILITY,
        date_start : fromDateToPeriod(new Date(2000,0,1) as Date),
        date_end : fromDateToPeriod(new Date(3000,11,31) as Date),
        availability_days : avarange.availability_days.map((day : IDays_Times)=>{
            return{
                ...day,
                times_ava :  day.times_ava.map((time : IType)=>{
                    return time.key
                })
            }
        })
    }
}


const sort: Array<SortDescriptor> = [{ field: "key", dir: "asc" }];
export  const ExperienceAvailability: FC<EditFormProps<IExperience>> = (props)  =>{
    const currentPlanner = useCurrentPlanner();
    const { data: experience_availability } = useGetExperienceAvailabilityQuery(props.item.id ?? skipToken)
    const { data: timesmultiselect}  = useGetTimesMultiselectQuery(currentPlanner?.id ?? skipToken);
    const currentExperience = useCurrentExperience();
    const [addExperienceAvailability ,{ data: addAvaData}]  = useAddExperienceAvailabilityMutation()
    const [updateExperienceAvailability,{ data: updateAvaData}] = useUpdateExperienceAvailabilityMutation()
    const [deleteExperienceAvailability] = useDeleteExperienceAvailabilityMutation()
    const [updateExperience, { isLoading: isUpdating }] =
        useUpdateExperienceMutation();

    const {    days} = useAppSelector((store) => store.types);
    const [isErrorAvailabilityRanges, setIsErrorAvailabilityRanges] = useState<boolean>(false);
    const [errorAvailabilityRanges, setErrorAvailabilityRanges] = useState<string>('');

    const {isSomeMutationPending,isSomeQueryPending} = useCurrentQueriesStates();
    //Todo: parte con quello delle props, non vuoto.
    const [isAvailabilityCustom, setIsAvailabilityCustom]  = useState<number>(currentExperience?.isAvailabilityCustom  ?? NOT_ACTIVE);

    const [availabilityRange, setAvailabilityRange] = useState<IExperienceAvailabilityRange|null>(null)
    //Todo: parte con quello delle props, non con il primo.
    const [AvailabilityRanges, setAvailabilityRanges] = useState<IExperienceAvailabilityRange[]>([])

    const [selectedRange,setSelectedRange] = useState<number|string|null>();
    const [isEditing,setIsEditing] = useState<boolean>(false);
    const [requireConfirmation, setRequireConfirmation] = useState<boolean>(false)
    const [confirmationText, setConfirmationText] = useState<string>('')
    const [confirmationAction, setConfirmationAction] = useState<number>(0)
    const [confirmationRange, setConfirmationRange] = useState<IExperienceAvailabilityRange>()

    const [stateOfCalls,setStateOfCalls] = useState(NOTHING)

    const WSize = useWindowSize();
    const [macroColsWidth, setMacroColsWidth] = useState( BigScreenMacroCols)
    const [macroColsWidthStd, setMacroColsWidthStd] = useState( BigScreenMacroCols)
    const [dayColsWidth,setDayColsWidth] = useState(BigScreenDayCols)

    const HandleUpdateDate = (_el: IExperienceAvailabilityRange, dateRange: IDateRange) => {
        setIsErrorAvailabilityRanges(false)

        setAvailabilityRange({..._el, date_end: dateRange.endDate, date_start: dateRange.startDate})
        setIsEditing(true);
    }

    const HandleUpdateTimes = (_el: IExperienceAvailabilityRange, day: string | undefined, times: number[]) => {
        if(day) {
            setIsEditing(true);
            setAvailabilityRange(
                {
                    ..._el, availability_days: _el.availability_days.map((_dt) =>
                        (_dt.day_key == day) ?
                            {..._dt, times_ava: orderBy(times, sort)}
                            :
                            _dt
                    )
                })
        }
    }
    const HandleUpdateDayClosed = (_el: IExperienceAvailabilityRange, day: string | undefined, is_open: boolean) => {
        if(day) {
            setIsEditing(true)
            setAvailabilityRange({
                ..._el, availability_days: _el.availability_days.map((_dt) =>
                    (_dt.day_key == day) ?
                        {
                            ..._dt, is_open: is_open
                            // , times_ava: (!isOpen ? [] : _dt.times_ava)
                        }
                        :
                        _dt
                )
            })
        }
    }


    //check per sovrapposizioni
    //basta cercare una con lo start minore di un qualsiasi end ma maggiore del suo start,
    // o una con un end maggiore di un qualsiasi start ma minore del suo end.
    const checkOverlapOfRanges = () => {
        let trovata = false;
        if(experience_availability && availabilityRange )
                //evito di controllare la riga già salvata
                experience_availability.filter(_el => _el.id !== availabilityRange.id).forEach((row) => {
                    const __row = formatAvaRangeFromApi(row);
                    if(__row.isAvailabilityCustom === CUSTOM_AVAILABILITY) { //controllo solo quelli che hanno un periodo custom.
                        if ( //se l'end è compresa tra inizio e fine di un altro
                            (compareDate(__row.date_end as Date, availabilityRange.date_start as Date) <= 0
                                && compareDate(__row.date_end as Date, availabilityRange.date_end as Date) == 1)
                            ||
                            //se lo start è compreso tra inizio e fine di un altro
                            (compareDate(__row.date_start as Date, availabilityRange.date_end as Date) >= 0
                                && compareDate(__row.date_start as Date, availabilityRange.date_start as Date) == -1)
                        ) {
                            trovata = true;
                        }
                    }

                })

            setIsErrorAvailabilityRanges(trovata);
        if(trovata){
            setErrorAvailabilityRanges('Non si possono sorvrapporre i periodi')
        }
        return trovata;
    };

    const HandleSaveAvailabilityRange = () => {
            //o ha uno start e una end, o è il periodo DEFAULT_AVAILABILITY
            if ((availabilityRange?.date_start && availabilityRange?.date_end) || isAvailabilityCustom === DEFAULT_AVAILABILITY) {
                //quindi faccio il check di overlappinng mi serve solo quando è CUSTOM AVAILABILITY
                if (!checkOverlapOfRanges() || isAvailabilityCustom === DEFAULT_AVAILABILITY ) {
                    if (availabilityRange != null) {
                        setIsEditing(false);
                        if (selectedRange === AVA_NEW) {
                            setStateOfCalls(ADDING)
                            addExperienceAvailability(isAvailabilityCustom === DEFAULT_AVAILABILITY  ? formatAvaRangeToApi_asDefaultPeriod(availabilityRange) : formatAvaRangeToApi(availabilityRange))
                        } else {
                            setStateOfCalls(UPDATING)
                            updateExperienceAvailability(isAvailabilityCustom === DEFAULT_AVAILABILITY  ? formatAvaRangeToApi_asDefaultPeriod(availabilityRange) :formatAvaRangeToApi(availabilityRange))
                        }
                    } else {
                        setStateOfCalls(NOTHING)
                        setIsErrorAvailabilityRanges(true);
                        setErrorAvailabilityRanges('Bisogna impostare almeno un periodo');
                    }
                } else {
                    setIsErrorAvailabilityRanges(true);
                    setErrorAvailabilityRanges('Non si possono sorvrapporre i periodi')
                }
            } else {
                setIsErrorAvailabilityRanges(true);
                setErrorAvailabilityRanges('Bisogna inserire data di inizio e fine periodo')
            }


    }


    const HandleDeleteAvailabilityRange = () => {

        if(availabilityRange != null){
            setIsEditing(false);
            if(availabilityRange.id === 'new'){
                setAvailabilityRanges(AvailabilityRanges.filter(el => el.id !== 'new'));
            }else{
                setStateOfCalls(DELETING)
                deleteExperienceAvailability(availabilityRange)
            }
            setIsErrorAvailabilityRanges(false);
        }else{
            setIsErrorAvailabilityRanges(true);
            setErrorAvailabilityRanges('Bisogna impostare almeno un periodo');
        }
    }

    useEffect(() => {
        if(experience_availability ){
            const newAvas : IExperienceAvailabilityRange[]  = [];
            if(experience_availability.length) {
                experience_availability.map((exp_ava) => {
                    if (exp_ava.id) {
                        if (newAvas.find(__el => __el.id === exp_ava.id)) {
                            const index = newAvas.findIndex(__el => __el.id === exp_ava.id);
                            newAvas.splice(index, 1);
                        }
                        // if(exp_ava.isAvailabilityCustom === CUSTOM_AVAILABILITY){
                            newAvas.push(formatAvaRangeFromApi(exp_ava))
                        // }
                    }
                })

                setIsEditing(false);
                //devo fare un ordinamento.
                setAvailabilityRanges(newAvas.sort((a,b)=> {
                        // posso fare così perchè in questo punto le date | string sono tutte state convertite a Date da  formatAvaRangeFromApi
                        if (typeof a.date_start == 'object' && typeof b.date_start == 'object')  {
                            return a.date_start.getTime() - b.date_start.getTime()
                        }
                        else{
                            return 1
                        }
                    }
                ))
                if(isAvailabilityCustom === CUSTOM_AVAILABILITY ) {
                    if (newAvas.length ) {
                        setSelectedRange(newAvas[0].id)
                        setAvailabilityRange(newAvas[0]);
                    }else{
                        setSelectedRange(null)
                        setAvailabilityRange(null);
                    }
                }else{
                    let yetDefinedDefaultPeriod =undefined;
                    if(!currentExperience?.isAvailabilityCustom){
                        if(experience_availability && experience_availability.length) {
                            yetDefinedDefaultPeriod =  experience_availability.find((exp_ava) =>  exp_ava.isAvailabilityCustom === DEFAULT_AVAILABILITY)
                        }
                    }
                    if(yetDefinedDefaultPeriod){
                        setAvailabilityRange(formatAvaRangeFromApi(yetDefinedDefaultPeriod))
                    }
                }

            }else{
                setAvailabilityRanges([])
            }
            if(stateOfCalls === DELETING ){
                if(newAvas[0]) {
                    setSelectedRange(newAvas[0].id)
                    setAvailabilityRange(newAvas[0]);
                }else{
                    setAvailabilityRange(null)
                }
            }

            if(addAvaData && stateOfCalls === ADDING) {
                setIsEditing(false);
                setAvailabilityRange(formatAvaRangeFromApi(addAvaData))
                setSelectedRange(addAvaData.id)
            }
            if(updateAvaData  && stateOfCalls === UPDATING) {
                setIsEditing(false);
                setAvailabilityRange(formatAvaRangeFromApi(updateAvaData))
                setSelectedRange(updateAvaData.id)
            }
            setIsEditing(false);
            setStateOfCalls(NOTHING)
        }
    }, [experience_availability,isAvailabilityCustom]);

    const PrepareNewRange = () => {
        if( !AvailabilityRanges.find(el => el.id === 'new')){
            if (props.item.id) {
                setIsEditing(true);
                const newAvas = AvailabilityRanges;
                const newEmptyAva = {
                    id: 'new',
                    experience_id: props.item.id, //che poi è l'id dell'esperienza.
                    date_start: '',
                    date_end: '',
                    availability_label: '',
                    isAvailabilityCustom: CUSTOM_AVAILABILITY,
                    availability_days: days.map((el) => {
                        return {day_key: el.key.toString() ?? '', times_ava: [], is_open: false}
                    }),
                }
                newAvas.push(newEmptyAva)
                setSelectedRange('new')
                setAvailabilityRanges(newAvas);
                setIsErrorAvailabilityRanges(isAvailabilityCustom === CUSTOM_AVAILABILITY);
                setErrorAvailabilityRanges('')
                setAvailabilityRange(newEmptyAva)
            }
        }
    }

    useEffect(() => {
        if(isAvailabilityCustom) {
            if (WSize >= SCREEN_XL) {
                setMacroColsWidth(BigScreenMacroCols)
                setDayColsWidth(BigScreenDayCols)
            } else if (WSize < SCREEN_XL && WSize >= SCREEN_MD) {
                setMacroColsWidth(MediumScreenMacroCols)
                setDayColsWidth(MediumScreenDayCols)
            } else {
                setMacroColsWidth(SmallScreenMacroCols)
                setDayColsWidth(SmallScreenDayCols)
            }
        }else{
            if (WSize >= SCREEN_XL) {
                setMacroColsWidth(BigScreenMacroColsDEF)
                setDayColsWidth(BigScreenDayColsDEF)
            } else if (WSize < SCREEN_XL && WSize >= SCREEN_MD) {
                setMacroColsWidth(MediumScreenMacroColsDEF)
                setDayColsWidth(MediumScreenDayColsDEF)
            } else {
                setMacroColsWidth(SmallScreenMacroColsDEF)
                setDayColsWidth(SmallScreenDayColsDEF)
            }
        }
    }, [WSize,isAvailabilityCustom]);



    useEffect(() => {
        //Gestione del salvataggio quando cambio modalità

        if(isAvailabilityCustom !== currentExperience?.isAvailabilityCustom && currentExperience!==undefined) {
            //Vuol dire che ho aggiornato il fatto che l'esperienza abbia gli orari custom o no
            const exp = {...currentExperience};
            exp.isAvailabilityCustom = isAvailabilityCustom;
            if(exp.id) updateExperience({id: exp.id, experience: exp as IExperience});
        }
        //se la imposto a quello di default, cerco se c'è già , se no la creo vuota
        if(isAvailabilityCustom === DEFAULT_AVAILABILITY &&  currentExperience?.id !==undefined){
            let yetDefinedDefaultPeriod =undefined;
            if(currentExperience?.isAvailabilityCustom === DEFAULT_AVAILABILITY){
                if(experience_availability && experience_availability.length) {
                    yetDefinedDefaultPeriod =  experience_availability.find((exp_ava) => exp_ava.isAvailabilityCustom === DEFAULT_AVAILABILITY)
                }
            }
            if(yetDefinedDefaultPeriod){
                setAvailabilityRange(formatAvaRangeFromApi(yetDefinedDefaultPeriod))
            }else{
                setSelectedRange(AVA_NEW)
                setAvailabilityRange({
                    id: 'new',
                    experience_id: currentExperience.id, //che poi è l'id dell'esperienza.
                    date_start: '',
                    date_end: '',
                    isAvailabilityCustom: DEFAULT_AVAILABILITY,
                    availability_label: '',
                    availability_days: days.map((el) => {
                        return {day_key: el.key.toString() ?? '', times_ava: [], is_open: false}
                    }),
                })
            }

        }
    }, [isAvailabilityCustom,experience_availability]);


    return <>
        {((isSomeMutationPending || isSomeQueryPending)) ? (
            <CenteredLoadingSpinner/>
        ) : (
            <>
                <GridLayout
                    className={'availability-grid-container'}
                    rows={
                        (availabilityRange === null && isAvailabilityCustom &&
                        !isSomeQueryPending && !isSomeMutationPending )?
                        [{height: 50},
                             {height: 25}
                        ]:[{height: 50}]

                    }
                    cols={ [{width: '100%'}]}
                    gap={{rows: 25, cols: 25}}
                    align={{
                        horizontal: 'center'
                    }}
                >

                    <GridLayoutItem col={1}>
                        <div className={'availability-switch-container'}>
                            <Switch
                                onChange={() => {
                                    if((isAvailabilityCustom === DEFAULT_AVAILABILITY ) || (isAvailabilityCustom === CUSTOM_AVAILABILITY ) ) {
                                        setIsAvailabilityCustom(NOT_ACTIVE)
                                    }else {
                                        setIsAvailabilityCustom(DEFAULT_AVAILABILITY)
                                    }
                                }}
                                disabled={isEditing}
                                checked={(isAvailabilityCustom === DEFAULT_AVAILABILITY ) || (isAvailabilityCustom === CUSTOM_AVAILABILITY ) }
                                value={(isAvailabilityCustom === DEFAULT_AVAILABILITY ) || (isAvailabilityCustom === CUSTOM_AVAILABILITY ) }
                            />
                            <span style={{marginLeft: 10}}>Attiva la limitazione orari</span>
                        </div>
                        <div className={'availability-switch-container'}>
                            <Switch
                                onChange={() => {
                                    if(!NOT_ACTIVE){
                                        setIsAvailabilityCustom(isAvailabilityCustom === DEFAULT_AVAILABILITY? CUSTOM_AVAILABILITY : DEFAULT_AVAILABILITY)
                                    }
                                }}
                                disabled={isEditing || (isAvailabilityCustom === NOT_ACTIVE)}
                                checked={isAvailabilityCustom === CUSTOM_AVAILABILITY }
                                value={isAvailabilityCustom === CUSTOM_AVAILABILITY}
                            />
                            <span style={{marginLeft: 10}}>Imposta orari personalizzati per periodo</span>
                        </div>

                    </GridLayoutItem>


                    {(isSomeQueryPending || isSomeMutationPending ) && !isAvailabilityCustom &&
                        <CenteredLoadingSpinner/>}

                    {/*mostro "nuovo periodo" solo se è CUSTOM_AVAILABILITY*/}
                    {availabilityRange === null && isAvailabilityCustom === CUSTOM_AVAILABILITY &&
                        !isSomeQueryPending && !isSomeMutationPending &&<GridLayoutItem col={1} row={2}>
                     <BaseButton
                            type="submit"
                            variant="contained"
                            className={"std-button custom-unif-viola-back " + (AvailabilityRanges.length > 0 ? "availability-range-button" : "")}
                            onClick={() => {
                                if (availabilityRange) {
                                    setRequireConfirmation(true);
                                    setConfirmationAction(ACTION_CHANGE);
                                    setConfirmationText('Le modifiche non salvate verranno perse, vuoi continuare?');
                                } else {
                                    PrepareNewRange();
                                }
                            }}
                        >
                            nuovo periodo
                        </BaseButton>

                    </GridLayoutItem>
                    }
                </GridLayout>

                {/*se siamo in modalità custom o default, la mostriamo , se no no.*/}
                {isAvailabilityCustom !== NOT_ACTIVE && <GridLayout
                    className={'availability-grid-container'}
                    rows={
                        [{height: 50}, {height: 70}, {height: 70}, ...AvailabilityRanges.map(__ => {
                            return {height: 70}
                        })]

                    }
                    cols={macroColsWidth}
                    gap={{rows: 5, cols: 25}}
                    align={{
                        horizontal: 'center'
                    }}
                >
                    {availabilityRange && isAvailabilityCustom === CUSTOM_AVAILABILITY && <GridLayoutItem row={TITLE_ROW} col={1}>
                        <Typography variant={"h5"} style={{paddingLeft: "40px"}}>
                            Periodo
                        </Typography>
                    </GridLayoutItem>}
                    {availabilityRange && <GridLayoutItem row={TITLE_ROW} col={2}>
                        <Typography variant={"h5"}>
                            Giorni e Orari
                        </Typography>
                    </GridLayoutItem>}

                    {availabilityRange &&
                        <>
                            {/*i periodi vanno mostrati se e solo se CUSTOM_AVAILABILITY*/}
                            {AvailabilityRanges && isAvailabilityCustom === CUSTOM_AVAILABILITY &&
                                AvailabilityRanges.map((exp_ava_el, index) => {
                                return (
                                    <GridLayoutItem row={TITLE_ROW + 1 + index} col={1}
                                                    key={'availability-row-date' + (exp_ava_el.id ?? 'new')}>
                                        <RadioButton
                                            color={'#5c43b5'}
                                            key={'availability-radio-btn' + (exp_ava_el.id ?? 'new')}
                                            name={'availability-group'}
                                            size={"large"}
                                            className={'availability-range-radio'}
                                            checked={selectedRange === exp_ava_el.id}
                                            onChange={() => {
                                                if (isEditing) {
                                                    setRequireConfirmation(true);
                                                    setConfirmationAction(ACTION_SWITCH)
                                                    setConfirmationText('Le modifiche non salvate andranno perse, sei sicuro di voler continuare')
                                                    setConfirmationRange(exp_ava_el)
                                                } else {

                                                    setIsErrorAvailabilityRanges(false);
                                                    setAvailabilityRange(exp_ava_el)
                                                    setSelectedRange(exp_ava_el.id)
                                                }
                                            }}
                                        />
                                        <DateRangePickerKendo
                                            className={'availability-range-picker'}
                                            range={
                                                selectedRange === exp_ava_el.id ?
                                                    {
                                                        startDate: availabilityRange.date_start as Date,
                                                        endDate: availabilityRange.date_end as Date
                                                    }
                                                    :
                                                    {
                                                        startDate: exp_ava_el.date_start as Date,
                                                        endDate: exp_ava_el.date_end as Date
                                                    }
                                            }
                                            onChange={(dateRange) => {
                                                if (dateRange.endDate !== null && dateRange.startDate !== null) {
                                                    HandleUpdateDate(availabilityRange, dateRange)
                                                }
                                            }
                                            }
                                            disabled={selectedRange !== exp_ava_el.id}

                                            format="dd/MM"
                                        />
                                    </GridLayoutItem>
                                )
                            })}

                            <GridLayoutItem row={TITLE_ROW + 8} col={2}
                                            key={'availability-row-save' + (availabilityRange.id ?? 'new')}>
                                <BaseButton
                                    type="submit"
                                    variant="contained"
                                    disabled={isErrorAvailabilityRanges}
                                    className={"std-button availability-range-button " + (!isErrorAvailabilityRanges ? 'custom-unif-viola-back ' : '')}
                                    onClick={HandleSaveAvailabilityRange}
                                    startIcon={<SaveIcon/>}
                                >
                                    {isAvailabilityCustom === CUSTOM_AVAILABILITY ? "Salva periodo" : "Salva "}
                                </BaseButton>
                                {isAvailabilityCustom === CUSTOM_AVAILABILITY && <BaseButton
                                    type="submit"
                                    variant="contained"
                                    style={{marginLeft: '20px'}}
                                    disabled={isErrorAvailabilityRanges}
                                    className={"std-button availability-range-button " + (!isErrorAvailabilityRanges ? 'custom-unif-arancione-back ' : '')}
                                    onClick={() => {
                                        setRequireConfirmation(true);
                                        setConfirmationText('Sei sicuro di voler eliminare il periodo selezionato');
                                        setConfirmationAction(ACTION_DELETE);
                                    }}
                                    startIcon={<DeleteOutlineIcon/>}
                                >
                                    Elimina periodo
                                </BaseButton>}
                            </GridLayoutItem>
                            <GridLayoutItem row={TITLE_ROW + 3} col={3}>

                            </GridLayoutItem>
                            <GridLayoutItem row={TITLE_ROW + 10} col={2}>
                                {isErrorAvailabilityRanges && <Typography
                                    className={'errori-dentro-availability'}
                                    color={'red'}
                                    align={"center"}
                                    variant="h6"
                                >
                                    {errorAvailabilityRanges}
                                </Typography>}
                            </GridLayoutItem>

                            <GridLayoutItem row={TITLE_ROW + 1} col={2}
                                            key={'availability-row-days' + (availabilityRange.id ?? 'new')} rowSpan={7}>
                                <GridLayout
                                    rows={[
                                        {height: 70},
                                        {height: 70},
                                        {height: 70},
                                        {height: 70},
                                        {height: 70},
                                        {height: 70},
                                        {height: 70},
                                    ]}
                                    cols={dayColsWidth}
                                    gap={{rows: 5, cols: 25}}
                                    align={{
                                        horizontal: 'center'
                                    }}
                                >
                                    {days.map((day, __index) => {
                                        let this_day = null;
                                        if (availabilityRange.availability_days) {
                                            this_day = availabilityRange.availability_days.find(__el => parseInt(__el.day_key) === day.key) ?? null;
                                        }
                                        return day && day.key ? (
                                            <>
                                                <GridLayoutItem row={__index + 1} col={1}
                                                                key={'availability-row-days-title-' + day.label}>
                                                    <Typography
                                                        className={"title-giorno-timetable"}
                                                        align={"left"}
                                                        variant="h6"
                                                    >
                                                        {day.label}
                                                    </Typography>
                                                </GridLayoutItem>
                                                <GridLayoutItem row={__index + 1} col={2}
                                                                key={'availability-row-days-isopen-' + day.label}
                                                                className={"availability-switch"}>
                                                    <CustomSwitch
                                                        label={this_day ? (this_day.is_open ? 'Disponibile' : 'Non disponibile') : 'Non disponibile'}
                                                        value={this_day ? this_day.is_open : false}
                                                        onChange={(e) => HandleUpdateDayClosed(availabilityRange, day.key.toString(), e.value)}
                                                    />
                                                </GridLayoutItem>
                                                <GridLayoutItem row={__index + 1} col={3}
                                                                key={'availability-row-times-times-' + day.label}>
                                                    {this_day ? (this_day.is_open &&
                                                        <KeyValueMultiselectEvo
                                                            additionalClassNames={'availability-times-multiselect'}
                                                            label={"Inizia solo alle"}
                                                            disabled={false}
                                                            values={this_day.times_ava ? this_day.times_ava : []}
                                                            onChange={(e) => {
                                                                HandleUpdateTimes(availabilityRange, day.key.toString(), e.value)
                                                            }}
                                                            data={timesmultiselect ?? []}
                                                        />) : <></>}
                                                </GridLayoutItem>
                                            </>
                                        ) : <></>
                                    })}

                                </GridLayout>
                            </GridLayoutItem>
                            <GridLayoutItem row={TITLE_ROW + (AvailabilityRanges.length ?? 0) + 1} col={1}>
                                {!isSomeQueryPending && !isSomeMutationPending && availabilityRange.id !== 'new' && isAvailabilityCustom === CUSTOM_AVAILABILITY &&
                                    <BaseButton
                                        type="submit"
                                        variant="contained"
                                        className={"new-period-btn std-button custom-unif-viola-back " + (AvailabilityRanges.length > 0 ? "availability-range-button" : "")}
                                        onClick={() => {
                                            if (availabilityRange && isEditing) {
                                                setRequireConfirmation(true);
                                                setConfirmationAction(ACTION_CHANGE);
                                                setConfirmationText('Le modifiche non salvate verranno perse, vuoi continuare?');
                                            } else {

                                                setIsEditing(false);
                                                PrepareNewRange();
                                            }

                                        }}
                                    >
                                        nuovo periodo
                                    </BaseButton>
                                }
                                {availabilityRange.id === 'new' && isAvailabilityCustom ===CUSTOM_AVAILABILITY && <BaseButton
                                    type="submit"
                                    variant="contained"
                                    className={"new-period-btn std-button custom-unif-arancione-back availability-range-button"}
                                    onClick={() => {
                                        HandleDeleteAvailabilityRange()

                                        setAvailabilityRange(AvailabilityRanges[0] ?? null);
                                        if(AvailabilityRanges[0] ) setSelectedRange(AvailabilityRanges[0].id ?? null);
                                    }}
                                >
                                    annulla
                                </BaseButton>
                                }
                            </GridLayoutItem>
                        </>
                    }
                    {requireConfirmation && (
                        <DeleteDialog
                            textDelete={confirmationText}
                            titleDelete={'Attenzione'}
                            toggleDialog={() => {
                                setRequireConfirmation(!requireConfirmation);
                            }}
                            additionalclasses={'form-availability'}
                            onConfirm={() => {
                                switch (confirmationAction) {
                                    case ACTION_DELETE:
                                        setIsEditing(false);
                                        HandleDeleteAvailabilityRange()
                                        break;
                                    case ACTION_CHANGE:
                                        if (props.item.id) {
                                            setIsErrorAvailabilityRanges(false);
                                            //una vuota o carico l'altra.

                                            setIsEditing(false);
                                            PrepareNewRange();
                                        }
                                        break;
                                    case ACTION_SWITCH:
                                        if (confirmationRange) {

                                            setIsErrorAvailabilityRanges(false);
                                            setIsEditing(false);
                                            setAvailabilityRange(confirmationRange)
                                            setSelectedRange(confirmationRange.id)
                                        }
                                        break;
                                    // case ACTION_AVA_CHANGE:
                                    //     setIsAvailabilityCustom(false)
                                    //     break;
                                    default:
                                        // console.log('default');
                                        break;
                                }
                            }}
                        />
                    )}
                </GridLayout>}




            </>)}
    </>
}