import {Grid, IconButton, Typography} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React from "react";


const SelectWeekPlanner = (props: any) => {
    return (
        <Grid container alignItems="stretch" spacing={0} >
            <Grid item xs={12} className={"select-week-title"}>
                <IconButton
                    style={{display: "inline-block"}}
                    className={`select-week-title-child bottoni-tondi bottoni-tondi-responsive std-bottoni-tondi ${
                        (!(props.currentItem?.firstDayWeek === props.staffWeeks[0].firstDayWeek))
                            ? "custom-unif-viola-back"
                            : ""
                    }`}
                    disabled={
                        (props.currentItem?.firstDayWeek === props.staffWeeks[0].firstDayWeek)
                    }
                    onClick={props.handlePrevious}
                >
                    <NavigateBeforeIcon fontSize="small" />
                </IconButton>
                <Typography className={"select-week-title-child"} variant="h6"
                            style={{display: "inline-block"}}>
                    <b> {props.currentItem?.label.split('nw')[0]} - {props.currentItem?.label.split('nw')[1]}</b>
                </Typography>
                <IconButton
                    style={{display: "inline-block"}}
                    className={`select-week-title-child bottoni-tondi bottoni-tondi-responsive std-bottoni-tondi ${
                        (!(props.currentItem?.firstDayWeek === props.staffWeeks[props.staffWeeks.length-1].firstDayWeek))
                            ? "custom-unif-viola-back"
                            : ""
                    }`}
                    disabled={
                        (props.currentItem?.firstDayWeek === props.staffWeeks[props.staffWeeks.length-1].firstDayWeek)
                    }
                    onClick={props.handleNext}
                >
                    <NavigateNextIcon fontSize="small" />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default SelectWeekPlanner;