import {cloneElement, useEffect, useState} from "react";

import {ListItemProps, MultiSelect, MultiSelectChangeEvent,} from "@progress/kendo-react-dropdowns";
import {FieldRenderProps} from "@progress/kendo-react-form";
import {Error} from "@progress/kendo-react-labels";
import {useAppSelector} from "../../store";

export const ExclusionsMultiselect = (props: FieldRenderProps) => {
  const { validationMessage, visited, value, source } = props;
  const { exclusions } = useAppSelector((store) => store.types);
  const [selectedExclusions, setSelectedExclusions] = useState(
    props.value ?? []
  );

  useEffect(() => {
    setSelectedExclusions(props.value);
  }, [props.value]);

  const handleChange = (event: MultiSelectChangeEvent) => {
    if (props.onChange) {
      props.onChange.call(undefined, { value: event.value });
    }

    setSelectedExclusions(event.value);
  };

  const exclusionsItemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    //const exclusionItem = itemProps.dataItem as IExclusionType;

    const itemChildren = [
      <div style={{ alignSelf: "inherit" }}>
        <span style={{ marginLeft: "16px" }}>{li.props.children}</span>
      </div>,
    ];

    return cloneElement(li, li.props, itemChildren);
  };

  return (
      <div>
          <div  className="std-multiselect-container dropdownModifica multiselect-dropdown custom-unif-dropdown-focus" >
              <label
                  className="labelModifica std-multiselect-label"
                  data-shrink="true">{props.label}
              </label>
              <div className="std-multiselect-component">

                  <MultiSelect
                      onChange={handleChange}
                      value={selectedExclusions}
                      data={exclusions}
                      dataItemKey={"key"}
                      textField={"label"}
                      itemRender={exclusionsItemRender}
                      tags={
                          selectedExclusions && selectedExclusions.length > 0
                              ? selectedExclusions.map((item: any) => {
                                  return {
                                      text: `${item?.label}`,
                                      data: [item],
                                  };
                              })
                              : []
                      }
                  />
              </div>
          </div>
          {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
  );
};
