import {FC, Fragment, useEffect, useState} from "react";
import {Box, Divider, Grid} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SeasonPriceItem from "./SeasonPriceItem";
import BaseButton from "components/common/BaseButton";
import {IPrice} from "../../models/entities/prices/IPrice";
import {IExperience} from "../../models/entities/IExperience";
import {fromDateToPeriod, fromPeriodToDate} from "../../utils/dateHelper";
import {IUpdatePriceViewModel} from "../../models/views/experiences/IUpdatePriceViewModel";
import {IPriceViewModel} from "../../models/views/experiences/IPriceViewModel";
import {useGetExperienceQuery, useSavePriceMutation,} from "../../store/api/experiences";
import {skipToken} from "@reduxjs/toolkit/query/react";

export interface buttonStateManager {
  isActive          (id: string) : boolean;
  isOneBtnSelected  () : boolean;
  toggleActive      (id: string ) : void;
}

const SeasonPricesForm: FC<{
  experienceId: number;
  pSectionActive: boolean;
  onSaveParentState: (seasonPrices: IPrice[]) => void;
  setParentSectionActive: (value: boolean) => void;
}> = ({ experienceId, onSaveParentState, pSectionActive, setParentSectionActive}) => {
  const { data: experience = {} as IExperience } =
    useGetExperienceQuery(experienceId ?? skipToken);

  const [seasonPrices, setSeasonPrices] = useState<IPrice[]>(() => {
    // const prices =(experience?.price_type === "periodo" && experience?.prices) || [];
    const prices = experience?.prices
      ? experience.prices.filter((price) => price.price_type === "periodo")
      : [];


    return prices?.map((p) => ({
      ...p,
      date_start: fromPeriodToDate(p.date_start as string),
      date_end: fromPeriodToDate(p.date_end as string),
    }));
  });

  const [savePrice] = useSavePriceMutation();

  const addSeasonPrice = () => {
    setSeasonPrices([
      ...seasonPrices,
      {
        date_start: new Date(),
        date_end: new Date(),
        price: 0,
        price_type: "periodo",
        experience_id: experience.id as number,
      },
    ]);
  };
  const deleteSeasonPrice = (i: number) => {
    setSeasonPrices((prevState) =>
      prevState.filter((sp, index) => i !== index)
    );
  };

  const onUpdate = (seasonPrice: IPrice, index: number) => {
    setSeasonPrices((prevState) =>
      prevState?.map((sp, i) => {
        if (index !== i) {
          return sp;
        }
        return {
          ...sp,
          date_start: seasonPrice.date_start,
          date_end: seasonPrice.date_end,
          price: seasonPrice.price,
        };
      })
    );
  };

  useEffect(() => {
    onSaveParentState(
      seasonPrices?.map((p) => ({
        ...p,
        date_start: fromDateToPeriod(p.date_start as Date),
        date_end: fromDateToPeriod(p.date_end as Date),
      }))
    );
  }, [seasonPrices]);

  const saveSeasonPrice = () => {
    savePrice({
      experienceId: experience.id as number,
      viewModel: createPriceHistory(seasonPrices),
    });
  };

  const createPriceHistory = (prices: IPrice[]): IUpdatePriceViewModel => {
    const initialPrices = experience.prices;

    let history: IUpdatePriceViewModel = {
      price_type: "periodo",
      prices: prices?.map((p) => ({
        ...p,
        date_start: fromDateToPeriod(p.date_start as Date),
        date_end: fromDateToPeriod(p.date_end as Date),
        date_label: undefined,
      })),
    };

    let missingPrices: IPriceViewModel[] = [];

    initialPrices.forEach((ip) => {
      if (!prices.some((p) => p.id === ip.id)) {
        missingPrices.push({
          ...ip,
          isDelete: 1,
        });
      }
    });

    history.prices.concat(missingPrices);

    return history;
  };


  const [buttonStates, setButtonStates] = useState<string | undefined>(undefined);

  const btnManager :buttonStateManager = {
    isActive(id: string): boolean {
      return (buttonStates === id);
    },
    isOneBtnSelected(): boolean {
      return (buttonStates !== undefined);
    },
    toggleActive(id: string) {
      if(id === buttonStates) {
        setParentSectionActive(false);
        setButtonStates(undefined);
      }else{
        setButtonStates(id);
        setParentSectionActive(true);
      }
    }
  };

  return (
    <Fragment>
        <Grid
            item xs={12} md={4} lg={3} style={{textAlign: "center", marginTop:"3vh", paddingLeft: "1vw"}} className={'add-fascia-btn-container'}
        >
          <BaseButton
              className={"custom-unif-viola-back add-period-season"}
            variant={"outlined"}
            startIcon={<AddIcon />}
            onClick={addSeasonPrice}
          >
            Aggiungi intervallo
          </BaseButton>
        </Grid>


        {/* <Box paddingLeft={1}>
          <BaseButton
            tabIndex={-1}
            style={{ borderRadius: 50 }}
            color="primary"
            variant={"outlined"}
            startIcon={<SaveIcon />}
            onClick={saveSeasonPrice}
          >
            Salva prezzi
          </BaseButton>
        </Box> */}
      <Divider style={{textAlign:"center", marginTop:"10px"}}/>

      <Grid
          container
          item
          xs={12}
          style={{textAlign:"center", marginTop:"20px"}}
      >
        {seasonPrices.length === 0 ? (
          <Grid item xs={12}>

          </Grid>
        ) : (
          seasonPrices?.map((ap, i) => (
              <Grid  item xs={12} sm={12} md={12} lg={12} className={"fascia-prezzo-rule"}>
                <Box
                  key={`sp-${i}`}

                >
                  <SeasonPriceItem
                      btnIndex={`sp-${i}`}
                      manager={btnManager}
                      isSectionActive={pSectionActive}
                      setParentIsSectionActive={(value : boolean) => {
                        setParentSectionActive(value);
                      }}

                      seasonPrice={ap}
                      onUpdate={(seasonPrice) => onUpdate(seasonPrice, i)}
                      onDelete={() => deleteSeasonPrice(i)}
                  />
                </Box>
              </Grid>
          ))
        )}
      </Grid>
    </Fragment>
  );
};

export default SeasonPricesForm;
