import {Form, FormElement} from "@progress/kendo-react-form";
import {Box, Theme} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Error} from "@progress/kendo-react-labels";
import PasswordInputField from "../users/PasswordInputField";
import BaseButton from "components/common/BaseButton";
import React from "react";

export interface PasswordForm {
  password?: string;
  confirmedpassword?: string;
}
export interface IChangeRecoverPasswordFormProps {
    isErrorPsw: boolean;
  onConfirmPassword: (event: PasswordForm) => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    fullWidth: {
      width: "100%",
    },
  })
);

function ChangeRecoverPasswordForm(props: IChangeRecoverPasswordFormProps) {
  const classes = useStyles();


    const errorMsg = "Le password devono coincidere";


    return (
    <Form
      onSubmit={props.onConfirmPassword}
      render={(formRenderProps) => (
        <FormElement className={"change-password-dialog"} style={{ maxWidth: 950 }}>
          <Box margin={2} className="rows-grandi">
            <PasswordInputField name="password" label="Password" />
          </Box>
          <Box margin={2}  className="rows-grandi">
            <PasswordInputField
              name="confirmedpassword"
              label="Conferma Password"
            />
              {props.isErrorPsw && <Error>{errorMsg}</Error>}
          </Box>
          <div className={classes.actionsContainer} style={{marginTop:"2vh"}}>
            <div>
              <BaseButton
                type={"submit"}
                variant="contained"

                disabled={!formRenderProps.allowSubmit}
                className={classes.fullWidth + " custom-unif-font custom-unif-viola-back"}
              >
                Accedi
              </BaseButton>
            </div>
          </div>
          {formRenderProps.visited &&
            formRenderProps.errors &&
            formRenderProps.errors.VALIDATION_SUMMARY && (
              <div className={"k-messagebox k-messagebox-error"}>
                <Error>{formRenderProps.errors.VALIDATION_SUMMARY}</Error>
              </div>
            )}
        </FormElement>
      )}
    />
  );
}

export default ChangeRecoverPasswordForm;
