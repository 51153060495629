import {api} from "./api";
import {IWeeklyTimetableDay} from "../../models/entities/IWeeklyTimetableDay";
import {ITimetableDayUpdateViewModel} from "../../models/views/ITimetableDayUpdateViewModel";
import {IStaffTimetableDto} from "../../models/dto/timetables/IStaffTimetableDto";

const parseTimetable = (res: IStaffTimetableDto) =>  res.schedules.map(s => ({
  ...s,
  closed: !s.ranges,
  ranges: s.ranges || []
}))


const staffTimetablesApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getStaffWeeklyTimetable: build.query<IWeeklyTimetableDay[], { staffId: number, firstWeekDay: string }>({
      query: ({ staffId, firstWeekDay }) => `schedule/staff/${staffId}/${firstWeekDay}`,
      transformResponse: (res: IStaffTimetableDto, meta) => parseTimetable(res),
      providesTags: (result, error, id) => result ? [...result.map(s => ({
        type: 'StaffTimetable',
        id: `${id}-${s.date}`
      } as const)), { type: 'StaffTimetable', id: 'LIST' }] : [{ type: 'StaffTimetable', id: 'LIST' }]
    }),
    updateStaffWeeklyTimetable: build.mutation<IWeeklyTimetableDay[], { staffId: number, firstWeekDay: string, viewModel: ITimetableDayUpdateViewModel[] }>({
      query: ({ staffId, viewModel, firstWeekDay }) => ({
        url: `schedule/staff/${staffId}/${firstWeekDay}`,
        method: 'POST',
        body: {
          usePlanner: 0,
          schedules: viewModel
        }
      }),
      transformResponse: (res: IStaffTimetableDto, meta) => parseTimetable(res),
      invalidatesTags: (result, error, id ) => error ? [] : result ? [...result.map(s => ({
        type: 'StaffTimetable',
        id: `${id}-${s.date}`
      } as const)), { type: 'StaffTimetable', id: 'LIST' },{ type: "Planners", id: "LIST" }] : [{ type: 'StaffTimetable', id: 'LIST' },{ type: "Planners", id: "LIST" }]
    }),
    updateStaffWeeklyTimetableToDefault: build.mutation<IWeeklyTimetableDay[], { staffId: number, firstWeekDay: string }>({
      query: ({ staffId, firstWeekDay }) => ({
        url: `schedule/staff/${staffId}/${firstWeekDay}`,
        method: 'POST',
        body: {
          usePlanner: 1,
        }
      }),
      transformResponse: (res: IStaffTimetableDto, meta) => parseTimetable(res),
      invalidatesTags: (result, error , id ) => error ? [] : result ? [...result.map(s => ({
        type: 'StaffTimetable',
        id: `${id}-${s.date}`
      } as const)), { type: 'StaffTimetable', id: 'LIST' },{ type: "Planners", id: "LIST" }] : [{ type: 'StaffTimetable', id: 'LIST' },{ type: "Planners", id: "LIST" }]
    }),
    updateStaffWeeklyTimetableFromCopy: build.mutation<IWeeklyTimetableDay[], { staffId: number, sourceFirstDayWeek: string, destinationFirstDayWeek: string }>({
      query: ({ staffId, destinationFirstDayWeek, sourceFirstDayWeek }) => ({
        url: `schedule/staff/${staffId}/${destinationFirstDayWeek}`,
        method: 'POST',
        body: {
          cloneWeek: sourceFirstDayWeek
        }
      }),
      transformResponse: (res: IStaffTimetableDto, meta) => parseTimetable(res),
      invalidatesTags: (result, error, id ) => error ? [] : result ? [...result.map(s => ({
        type: 'StaffTimetable',
        id: `${id}-${s.date}`
      } as const)), { type: 'StaffTimetable', id: 'LIST' },{ type: "Planners", id: "LIST" }] : [{ type: 'StaffTimetable', id: 'LIST' },{ type: "Planners", id: "LIST" }]
    }),

  })
})

export const {
  useGetStaffWeeklyTimetableQuery,
  useUpdateStaffWeeklyTimetableFromCopyMutation,
  useUpdateStaffWeeklyTimetableMutation,
  useUpdateStaffWeeklyTimetableToDefaultMutation
} = staffTimetablesApi