import React, {FC, useEffect, useState} from "react";
import {
    Grid,
    GridCellProps,
    GridFilterChangeEvent,
    GridSortChangeEvent,
    GridToolbar,
} from "@progress/kendo-react-grid";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor, State} from "@progress/kendo-data-query";
import BaseIconButton from "../common/BaseIconButton";
import GridUi from "@mui/material/Grid";
import {Avatar, Icon, Typography} from "@mui/material";
import {useWindowSize} from "../common/ScreenSizes";
import {IContacts} from "../../models/entities/IMessage";
import {useGetContactsQuery} from "../../store/api/notifications";
import female from "./../../img/Icons/female-avatar-profile-icon.png";
import male from "./../../img/Icons/male-avatar-profile-icon.jpg";
import genderN from "./../../img/Icons/gender-neutral-icon.jpg";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";

const MAX_CHAR = 39;
const MAX_CHAR_MEDIUM = 49;
const MAX_CHAR_SMALL = 37;
const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    // filters: [{ field: "name", operator: "contains", value: "Chef" }],
    filters: [],
};
const POLLING_RATE = 180000; // circa 3 min

const FEMALE_ICON =female;
const MALE_ICON = male;
const GENDER_NEUTRAL_ICON = genderN;





const ContactList : FC<{
    selectedChatRow : string,
    selectedChat : any,
    handleSetSelectedChat : (e: any) => void,
}> = ({   selectedChatRow , selectedChat, handleSetSelectedChat, }) => {

    // data: ContactListGridProps
    const size = useWindowSize();

    const contactListReformatted : IContacts[] = [];
    const [stateContactList, SetStateContactList] = useState<IContacts[]>([])

    const currentPlanner = useCurrentPlanner();

    const {
        data: contactsList,
        refetch
    } = useGetContactsQuery( {
        plannerId:  currentPlanner?.id as number,
    } );


    useEffect(()=>{
        refetch();
    },[selectedChatRow])


    useEffect(()=>{
        if(stateContactList.length) {
            if (selectedChatRow) {
                if (selectedChat === undefined) {

                    if(stateContactList.find((c) => c.chat_id === selectedChatRow)) {

                        handleClickMsg(null, stateContactList.find((c) => c.chat_id === selectedChatRow));
                    }
                }
            }
        }
    },[selectedChat, stateContactList])

    useEffect(()=>{
        if(contactsList && contactsList.contacts){
            contactsList.contacts.forEach((contact: IContacts) => {
                let contactFormatted = null;
                contactFormatted = {...contact};
                if(contact.date_time !== "" && contact.date_time !== undefined) {
                    let date = new Date(contact.date_time);
                    contactFormatted.date_time = date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()+" "+ (date.getHours()<10?'0':'') + date.getHours() +":"+ (date.getMinutes()<10?'0':'') + date.getMinutes() ;
                }
                contactListReformatted.push(contactFormatted);

            });
            SetStateContactList(contactListReformatted);
        }
    },[contactsList])


    useEffect(() => {
        const interval = setInterval(() => {
            refetch()
        }, POLLING_RATE);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    const [filterable, setFilterable] = useState(false);
    const [filter, setFilter] = useState(initialFilter);
    const [iconFilter, setIconFilter] = useState("filter");



    const initialSort: Array<SortDescriptor> = [{ field: "messages_number", dir: "desc" }];
    const [sort, setSort] = useState(initialSort);
    

    const handleClickMsg = (e : any, item: any ) => {

        handleSetSelectedChat(item);
        
    }

    return (
        (contactsList !== undefined && contactsList.contacts && stateContactList.length!==0) ?
            <Grid
                className={"contact-table custom-unif-font std-grid-component"}
                filterable={filterable}
                filter={filter}
                onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                    setSort(e.sort);
                }}
                rowHeight={10}
                data={
                    filterBy(
                        orderBy(stateContactList,
                            sort),
                        filter
                    )}

            >
                <GridToolbar>
                    <GridUi container spacing={1}>
                        <GridUi container item xs={12} spacing={3}>
                            <GridUi container item xs={11} spacing={1} className={"rows-grandi"}>
                                    <Typography  variant="h4" style={{paddingLeft: "15px", margin: "auto", marginLeft: "0px"}}>
                                        <Icon className={"custom-unif-icon-title"}>group</Icon>  Chat
                                    </Typography>
                            </GridUi>
                            <GridUi
                                item
                                xs={1}
                                container
                                alignItems="center"
                                justifyContent="flex-end"
                            >
                                <BaseIconButton
                                    onClick={() => {
                                        setFilterable(!filterable);
                                        setIconFilter(
                                            iconFilter === "filter" ? "filter-clear" : "filter"
                                        );
                                    }}
                                >
                                    {!filterable ? (
                                        <span className="k-icon k-i-filter" />
                                    ) : (
                                        <span className="k-icon k-i-filter-clear" />
                                    )}
                                </BaseIconButton>
                            </GridUi>
                        </GridUi>

                    </GridUi>
                </GridToolbar>
                <Column
                    field="contact_info"

                    title="Informazioni di contatto"
                    width={360}
                    // editor="numeric"

                    cell={(props: GridCellProps) => (
                        <td title={props.dataItem.contact_info}
                            data-target={props.dataItem.chat_id}
                            onClick={(e: any)=> handleClickMsg(e, props.dataItem)}
                            className={
                            `custom-row-item ${
                                props.dataItem.is_disabled
                                    ? "riga-disabilitata"
                                    : ""
                            }
                            ${
                                props.dataItem.chat_id=== parseInt(selectedChatRow)
                                    ? "riga-selezionata"
                                    : ""
                                }`} >
                            {
                                (props.dataItem.messages_number !== undefined && props.dataItem.messages_number !== "0" && props.dataItem.messages_number !== 0)?
                                    <span className={"messages-number"}>
                                        {props.dataItem.messages_number}
                                    </span>
                                    : <></>
                            }
                            <div>
                            <Avatar
                                className={" iniziali-button custom-unif-font-bold "}
                                src={(props.dataItem.sex==="M")? MALE_ICON :
                                    (props.dataItem.sex==="F")? FEMALE_ICON : GENDER_NEUTRAL_ICON}
                            >


                            </Avatar>

                                <span className={"contact-table-nameinfo custom-unif-font-2"} title={props.dataItem.contact_info}>

                                    {props.dataItem.contact_info}

                                </span>

                            </div>


                        </td>

                    )}
                />
                <Column
                    field="experience_info"
                    title="Esperienza"
                    // editor="numeric"

                    cell={(props: GridCellProps) => (
                        <td title={props.dataItem.experience_info}
                            data-target={props.dataItem.chat_id}
                            onClick={(e: any)=> handleClickMsg(e, props.dataItem)}
                            className={
                                `custom-row-item 
                                ${
                                    props.dataItem.is_disabled
                                        ? "riga-disabilitata"
                                        : ""
                                }
                                ${
                                    props.dataItem.chat_id=== parseInt(selectedChatRow)
                                        ? "riga-selezionata"
                                        : ""
                                }
                               `} >
                            <div >


                                <span className={"contact-table-expinfo custom-unif-font-2"} title={props.dataItem.experience_info}>
                                            {
                                                (props.dataItem.experience_info)?
                                                (size!== undefined)?
                                                        (size > 960 )?
                                                            (size < 1895) ?
                                                                (props.dataItem.experience_info.length >= MAX_CHAR_MEDIUM ) ? props.dataItem.experience_info.substring(0,MAX_CHAR_MEDIUM) +"..." : props.dataItem.experience_info
                                                                    :
                                                                (props.dataItem.experience_info.length >= MAX_CHAR ) ? props.dataItem.experience_info.substring(0,MAX_CHAR) +"..." : props.dataItem.experience_info
                                                            :
                                                            (props.dataItem.experience_info.length >= MAX_CHAR_SMALL ) ? props.dataItem.experience_info.substring(0,MAX_CHAR_SMALL) +"..." : props.dataItem.experience_info
                                                        :
                                              (props.dataItem.experience_info.length >= MAX_CHAR ) ? props.dataItem.experience_info.substring(0,MAX_CHAR) +"..." : props.dataItem.experience_info
                                                : ""
                                             }

                                </span>

                            </div>


                        </td>

                    )}
                />

                <Column
                    field="date_time"
                    title="Slot prenotato"
                    width={"200"}
                    // editor="numeric"
                    cell={(props: GridCellProps) => (
                        <td className={
                            `custom-row-item
                             ${
                                props.dataItem.is_disabled
                                    ? "riga-disabilitata"
                                    : ""
                            }
                             ${
                                props.dataItem.chat_id===parseInt(selectedChatRow)
                                    ? "riga-selezionata"
                                    : ""
                            }`}
                            data-target={props.dataItem.chat_id}
                            onClick={(e: any)=> handleClickMsg(e, props.dataItem)}
                        >
                            {props.dataItem.date_time}
                        </td>

                    )}
                />
                {/*<Column*/}
                {/*    // cell={CommandCell}*/}
                {/*    // width={matches ? "150px" : ""}*/}
                {/*    width="100"*/}
                {/*    cell={(props: GridCellProps) => (*/}

                {/*        <td className={*/}
                {/*            `custom-row-item ${*/}
                {/*                props.dataItem.chat_id=== selectedChatRow*/}
                {/*                    ? "riga-selezionata"*/}
                {/*                    : ""*/}
                {/*            }`} >*/}
                {/*            <BaseIconButton color="primary"*/}
                {/*                            data-target={props.dataItem.chat_id}*/}
                {/*                            className={"custom-unif-viola "}*/}
                {/*                            onClick={(e: any)=> handleClickMsg(e, props.dataItem)}*/}
                {/*                // onClick={(e: any) => dispatch(selectPlanner(props.dataItem.id))}*/}
                {/*            >*/}

                {/*                <MessageIcon />*/}
                {/*            </BaseIconButton>*/}
                {/*        </td>*/}
                {/*    )}*/}
                {/*/>*/}


            </Grid> : <></>
    );
};

export default ContactList;
