import {api} from "./api";
import {ILoginViewModel} from "../../models/views/ILoginViewModel";
import {IToken} from "../../models/IToken";
import {ILoginDto} from "models/dto/auth/ILoginDto";
import {decodeToken} from "../../utils/jwt";
import {IIsAuthenticatedDto} from "../../models/dto/auth/IIsAuthenticatedDto";
import {IUserCheckRegistrationPasswordDto} from "../../models/dto/auth/IUserCheckRegistrationPasswordDto";
import {IChangePasswordViewModel} from "models/views/IChangePasswordViewModel";
import {IUserChangeEmailRegistrationViewModel} from "models/views/IUserChangeEmailRegistrationViewModel";
import {IUserCheckRegistrationViewModel} from "models/views/IUserCheckRegistrationViewModel";
import {IUserCheckRegistrationPasswordViewModel} from "models/views/IUserCheckRegistrationPasswordViewModel";
import {IUserCheckRegistrationDto} from "models/dto/auth/IUserCheckRegistrationDto";
import {IForgottenPasswordViewModel} from "models/views/IForgottenPasswordViewModel";
import {IGetActiveUserDto} from "models/dto/auth/IGetActiveUserDto";
import {IActiveUser} from "models/IActiveUser";
import {ISelectUserDto} from "../../models/dto/auth/ISelectUserDto";

export const authApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getActiveUser: build.query<IActiveUser, void>({
      query: () => `auth/getActiveUser`,
      transformResponse: (res: IGetActiveUserDto, meta) => res.user,
      // providesTags: (result, error, id) => [{ type: "ActiveUser", id }],
    }),

    login: build.mutation<{ user: IToken; token: string }, ILoginViewModel>({
      query: (credentials) => ({
        url: "auth/login",
        method: "POST",
        body: credentials,
      }),
      transformResponse: (res: ILoginDto, meta) => {
        const tokenInfo: IToken = decodeToken(res.token) as IToken;
        return {
          token: res.token,
          user: tokenInfo,
        };
      },
      // providesTags: (result, error, { username }) => [{type: 'Auth', id: username}]
    }),
    autoLogin: build.mutation<{ user: IToken; token: string }, void>({
      query: () => ({
        url: "auth/isAuthenticated",
        method: "GET",
      }),
      transformResponse: (res: IIsAuthenticatedDto, meta) => {
        const tokenInfo: IToken = decodeToken(
          localStorage.getItem("token") || ""
        ) as IToken;
        return {
          token: localStorage.getItem("token") || "",
          user: tokenInfo,
        };
      },
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        url: "auth/logout",
        method: "GET",
      }),
      // invalidatesTags: [
      //   { type: "Planners", id: "LIST" },
      //   { type: "CalendarDay", id: "LIST" },
      //   { type: "Appointments", id: "LIST" },
      //   { type: "Experiences", id: "LIST" },
      //   { type: "ExperiencesByStaff", id: "LIST" },
      //   { type: "Companies", id: "LIST" },
      //   { type: "Equipments", id: "LIST" },
      //   { type: "Staffs", id: "LIST" },
      //   { type: "Users", id: "LIST" },
      //   { type: "Locations", id: "LIST" },
      //   { type: "StaffWeeks", id: "LIST" },
      //   { type: "Seasons", id: "LIST" },
      //   { type: "Subscribers", id: "LIST" },
      //   { type: "PlannerWeeks", id: "LIST" },
      //   { type: "DefaultPlannerTimetable", id: "LIST" },
      //   { type: "CustomPlannerTimetable", id: "LIST" },
      //   { type: "StaffTimetable", id: "LIST" },
      // ],
    }),

    changePassword: build.mutation<void, IChangePasswordViewModel>({
      query: (credentials) => ({
        url: "auth/passwordChange",
        method: "POST",
        body: credentials,
      }),
      // invalidatesTags: (result, error, id) => [{ type: "Users", id }],
    }),

    changeEmailRegistration: build.mutation<
      void,
      IUserChangeEmailRegistrationViewModel
    >({
      query: (credentials) => ({
        url: "auth/emailChange",
        method: "POST",
        body: credentials,
      }),
      // invalidatesTags: (result, error, id) => [],
    }),
    userCheckRegistration: build.mutation<
      IUserCheckRegistrationDto,
      IUserCheckRegistrationViewModel
    >({
      query: (credentials) => ({
        url: "auth/userCheckRegistration",
        method: "POST",
        body: credentials,
      }),
      transformResponse: (res: IUserCheckRegistrationDto, meta) => res,
      // invalidatesTags: (result, error, id) => [{ type: "Users", id }],
    }),
    userCheckRegistrationPassword: build.mutation<
      { user: IToken; token: string },
      IUserCheckRegistrationPasswordViewModel
    >({
      query: (credentials) => ({
        url: "auth/userCheckRegistrationPassword",
        method: "POST",
        body: credentials,
      }),
      transformResponse: (res: IUserCheckRegistrationPasswordDto, meta) => {
          const tokenInfo: IToken = decodeToken(res.token) as IToken;
          return {
            token: res.token,
            user: tokenInfo,
          };
       },
      // invalidatesTags: (result, error, id) => [{ type: "Users", id }],
    }),

    recoveryLink: build.mutation<void, IForgottenPasswordViewModel>({
      query: (credentials) => ({
        url: "auth/passwordRecovery",
        method: "POST",
        body: credentials,
      }),
    }),
    userCheckRecoveryLink: build.mutation<
      IUserCheckRegistrationDto,
      IUserCheckRegistrationViewModel
    >({
      query: (credentials) => ({
        url: "auth/checkLinkRecovery",
        method: "POST",
        body: credentials,
      }),
      transformResponse: (res: IUserCheckRegistrationDto, meta) => res,
      // invalidatesTags: (result, error, id) => [{ type: "Users", id }],
    }),

    userCheckRecoveryLinkPassword: build.mutation<
      { user: IToken; token: string },
      IUserCheckRegistrationPasswordViewModel
    >({
      query: (credentials) => ({
        url: "auth/checkLinkRecoveryPassword",
        method: "POST",
        body: credentials,
      }),
      transformResponse: (res: IUserCheckRegistrationPasswordDto, meta) => {
        const tokenInfo: IToken = decodeToken(res.token) as IToken;
        return {
          token: res.token,
          user: tokenInfo,
        };
      },
      // invalidatesTags: (result, error, id) => [{ type: "Users", id }],
    }),
    selectUser: build.mutation<{ user: IToken, token: string }, number>({
      query: (userId) => ({
        url: `auth/selectUser/${userId}`,
        method: "GET",
      }),
      transformResponse: (res: ISelectUserDto, meta) => {
        const tokenInfo: IToken = decodeToken(res.token) as IToken
        return {
          token: res.token,
          user: tokenInfo
        }
      }
    })
  }),
});

export const {
  useGetActiveUserQuery,
  useAutoLoginMutation,
  useLoginMutation,
  useRecoveryLinkMutation,
  useLogoutMutation,
  useChangeEmailRegistrationMutation,
  useChangePasswordMutation,
  useUserCheckRegistrationMutation,
  useUserCheckRecoveryLinkMutation,
  useUserCheckRegistrationPasswordMutation,
  useUserCheckRecoveryLinkPasswordMutation,
  useSelectUserMutation
} = authApi;
