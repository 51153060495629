import {Icon, Theme, Typography} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {FC} from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "1.5vh",
    },
  })
);

const PageTitleGrid: FC<{ title: string; icon: string }> = ({ title, icon }) => {
  const classes = useStyles();

  return (
    <div className={classes.root +" "}>
      <Typography className={"title-right-icon-container"} variant="h4">
          <Icon className={"custom-unif-icon-title title-icon-grid"}>{icon}</Icon> <span className={"title-right-icon"}>{title}</span>
      </Typography>
    </div>
  );
};

export default PageTitleGrid;
