import {Dialog} from "@progress/kendo-react-dialogs";
import ResellerForm from "./ResellerForm";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import CustomTitleBar from "../common/CustomTitleBar";
import {useEffect, useState} from "react";
import {IReseller} from "../../models/entities/IReseller";
import {TabStrip, TabStripSelectEventArguments, TabStripTab} from "@progress/kendo-react-layout";
import ResellerAvailableExperience from "./ResellerAvailableExperience";

const ResellerDialogForm = (props: EditFormProps<IReseller>) => {

    //la tab inizialmente selezionata

    const [selected, setSelected] = useState<number>(0);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [refetchData, setRefetch] = useState<boolean>(false);
    const handleSelect = (e: TabStripSelectEventArguments) => {

        setSelected(e.selected);
        if(e.selected === 0){
            setRefetch(true);
        }
    };
  const handleSubmit = (event: IReseller) => {

      props.onSubmit(event);
      // window.location.reload();
  };

    useEffect(()=>{

        if(props.item.id && props.item.id !== 0){
            setDisabled(false);
        }
        if(props.item.temp_id && props.item.temp_id !== ""){
            setDisabled(true);
        }
    },[props])

  //PREVIENE CHE IL DIALOG SIA CHIUSO CON ESC
  const handleClose = (event : any) => {

      if (event.nativeEvent.keyCode === 27){
          return false;
      }else{

          handleCancelEdit();
      }
  }

  const handleCancelEdit = () => {
    props.cancelEdit();
  };
  const handleNothing = ()=>{

    }

  return (
    <Dialog
      className={"company-dialog-form"}
      title={
        <CustomTitleBar
          type={"Hotel"}
          label={
            !props.item ||
            !props.item.company_name||
            props.item.company_name === ""
              ? " nuovo"
              : ""
          }
          icon="local_hotel"
        />
      }

      onClose={(e) => handleClose(e)}
      width={"100vw"}
      height={"100vh"}
    >
        <TabStrip selected={selected} onSelect={handleSelect} className={"nmp-custom-tabstrip"}>
            <TabStripTab title="Anagrafica" contentClassName={"nmp-custom-tabstrip-tab"}>
                <ResellerForm
                    cancelEdit={handleCancelEdit}
                    onSubmit={handleSubmit}
                    item={props.item}
                    hasCancel={true}
                    onChange={handleNothing}
                    refetchData={refetchData}

                />
            </TabStripTab>
            <TabStripTab title="Esperienze Erogabili" disabled={disabled} contentClassName={"nmp-custom-tabstrip-tab"}>
                <ResellerAvailableExperience   reseller_id={props.item.id ?? 0 } />
            </TabStripTab>
        </TabStrip>

    </Dialog>
  );
};
export default ResellerDialogForm;
