import {Box, Card, CardContent, CardHeader} from "@mui/material";
import {makeStyles} from "@mui/styles";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import ChangeRecoverPasswordForm, {PasswordForm,} from "components/users/ChangeRecoverPasswordForm";
import {IUserCheckRegistrationPasswordViewModel} from "models/views/IUserCheckRegistrationPasswordViewModel";
import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useUserCheckRecoveryLinkMutation, useUserCheckRecoveryLinkPasswordMutation,} from "store/api/auth";
import {IUserCheckRegistrationViewModel} from "../../models/views/IUserCheckRegistrationViewModel";
import {useAppDispatch, useAppSelector} from "../../store";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100vh",
  },
  card: {
    width: 600,
  },
});

//sample http://localhost:3000/change-password?uid=12&checkcode=d6e787c26b11ed74e4d1c6c0795ce61d
//sample con cambio email http://localhost:3000/change-password?uid=4&checkcode=c77b5b5acba07d7aacfe4ffd71765a1b
const ChangePasswordPage = () => {

  const classes = useStyles();
  useAppDispatch();

  const history = useHistory();
  const search = useLocation().search;
  const uidUrl = new URLSearchParams(search).get("uid");
  const checkcodeUrl = new URLSearchParams(search).get("checkcode");
  const { email } = useAppSelector((store) => store.registration);

  const [changeLinkRecoveryPassword, {isSuccess , isError}] =
      useUserCheckRecoveryLinkPasswordMutation();

  const [userCheckRecoveryLink, { isLoading, isUninitialized }] =
      useUserCheckRecoveryLinkMutation();

  const loginStyle = {
    borderRadius: "25px",
    boxShadow: " 0px 0px 16px 2px rgba(0,0,0,0.94)",
  };

  useEffect(() => {
    if (uidUrl === null || checkcodeUrl === null) history.push("/404page");
    else {
      userCheckRecoveryLink({
        uid: parseInt(uidUrl),
        checkcode: checkcodeUrl,
      } as IUserCheckRegistrationViewModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (isSuccess && !isError) history.push("/dashboard/home");

  }, [isSuccess,isError, history]);

  const [isErrorPsw, setIsErrorPsw] = useState(false);

  const onConfirmPassword = (event: PasswordForm) => {
    if(event.password === event.confirmedpassword) {
      setIsErrorPsw(false);

      // history.push("/dashboard/home");
      changeLinkRecoveryPassword({
        uid: parseInt(uidUrl as string),
        checkcode: checkcodeUrl,
        email: email,
        password: event.password,
        passwordconfirm: event.confirmedpassword,
      } as IUserCheckRegistrationPasswordViewModel);
    }else{
      setIsErrorPsw(true);
    }
  };

  return isLoading || isUninitialized ? (
      <CenteredLoadingSpinner />
  ) : (
      <Box
          className={
            classes.root + " custom-unif-font custom-unif-viola custom-back"
          }
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
      >
        <Card className={classes.card + " card-login-forpsw"} style={loginStyle}>
          <CardHeader
              component={Box}
              margin={3}
              title="Cambio password"
              titleTypographyProps={{
                variant: "h3",
                align: "center",
              }}
          ></CardHeader>
          <CardContent component={Box} marginBottom={3}>
            <ChangeRecoverPasswordForm onConfirmPassword={onConfirmPassword} isErrorPsw={isErrorPsw}/>
          </CardContent>
        </Card>
      </Box>
  );
};

export default ChangePasswordPage;
