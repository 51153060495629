import React, {Fragment, useState} from "react";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import {SchedulerFormEditorProps} from "@progress/kendo-react-scheduler";
import {CustomSchedulerDetailFormEditor} from "./CustomSchedulerDetailFormEditor";
import {CustomSchedulerSubscribersFormEditor} from "./CustomSchedulerSubscribersFormEditor";
import CustomTab from "../common/CustomTab";
import {useLocation, useRouteMatch,} from "react-router-dom";
import {TabContext, TabPanel} from "@mui/lab";
import {useCurrentAppointment} from "utils/hooks/currentAppointment";

// const LazyError = (props: any) => {
//   return props.visited ? <Error {...props} /> : null;
// };

export const CustomSchedulerFormEditor = (props: SchedulerFormEditorProps) => {
  const [tabValue, setTabValue] = useState("dettaglio");
  useRouteMatch();
  useLocation();
  const { currentAppointmentId } = useCurrentAppointment();

  return (
    <Fragment>
      <TabContext value={tabValue}>
        <Box component={Box}>
          <Tabs
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            value={tabValue}
            onChange={(event: React.ChangeEvent<{}>, newValue: string) => {
              setTabValue(newValue);
            }}
          >
            {/* <CustomTabs
          variant="fullWidth"
          // className={{ indicator: classes.whiteColor}}
          indicatorColor="primary"
          textColor="primary"
          value={location.pathname}
        > */}
            <CustomTab

              // to={`${path}`}
              // value={`${path}`}
              label="Dettaglio"
              value="dettaglio"
            />
            <CustomTab
              // to={`${path}/schedule-subscribers`}
              // value={`${path}/schedule-subscribers`}
              value="partecipanti"
              label="Partecipanti"
              disabled={!currentAppointmentId}
              // disabled={!props.dataItem || !props.dataItem.id}
            />
            {/* </CustomTabs> */}
          </Tabs>
        </Box>
        <TabPanel value={"dettaglio"}>
          <CustomSchedulerDetailFormEditor   {...props} />
        </TabPanel>
        <TabPanel value={"partecipanti"}>
          <CustomSchedulerSubscribersFormEditor {...props} />
        </TabPanel>
        {/* <Switch>
        <Route
          path={`${path}`}
          exact
          component={CustomSchedulerDetailFormEditor}
        />
        <Route
          path={`${path}/schedule-subscribers`}
          component={CustomSchedulerSubscribersFormEditor}
        />
      </Switch> */}
      </TabContext>
    </Fragment>
  );
};
