import React, {cloneElement, useEffect, useState} from "react";

import {ListItemProps, MultiSelect, MultiSelectChangeEvent,} from "@progress/kendo-react-dropdowns";
import {FieldRenderProps} from "@progress/kendo-react-form";
import {Error} from "@progress/kendo-react-labels";
import {useAppSelector} from "../../store";
import {Icon} from "@mui/material";

import {IStaff} from "../../models/entities/IStaff";
import {useCurrentPlanner} from "utils/hooks/currentPlanner";
import {useGetStaffsQuery} from "../../store/api/staff";

export const StaffMultiselect = (props: FieldRenderProps) => {
  const { validationMessage, visited } = props;
  const currentPlanner = useCurrentPlanner();
  const {
    data: staffs = []
  } = useGetStaffsQuery(currentPlanner?.id as number);
  const { colors, roles } = useAppSelector((store) => store.types);

  const [selectedStaff, setSelectedStaff] = useState(props.value);

  useEffect(() => {
    setSelectedStaff(props.value);

  }, [props.value]);

  const handleChange = (event: MultiSelectChangeEvent) => {


    if (props.onChange) {
      props.onChange.call(undefined, { value: event.value });
    }

    setSelectedStaff(event.value);
  };
  const staffItemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const staffItem = itemProps.dataItem as IStaff;

    const itemChildren = [
      <div style={{ alignSelf: "inherit" }}>
          <span
              className={"nameicon-roles"}
            style={{
              background: colors.find((t) => t.label === staffItem.color)?.hex,
              borderRadius: "50%",
              border: "1px solid #9e9e9e",
                display: "inline-block",
              textAlign: "center",
              overflow: "hidden",
            }}
          ></span>
          <span className={"name-roles"} style={{ marginLeft: "10px" , marginTop: "-6px",}}>
            {staffItem.initials}
          </span>
        <span  style={{ paddingLeft: "10px"}}>
          {" "}
          {roles
            .filter((t) => staffItem?.planner_role?.find((r) => r === t.key))?.map((item: any) => {
              return (
                <Icon className={"nameicon-right-roles"} color="primary" title={item.label}>
                  {item.icon}
                </Icon>
              );
            })}
        </span>
      </div>,
    ];

    return cloneElement(li, li.props, itemChildren);
  };

  return (
      <div>
          <div className="std-multiselect-container dropdownModifica multiselect-dropdown custom-unif-dropdown-focus" >
              <label
                  className="labelModifica std-multiselect-label"
                  data-shrink="true">{props.label}
              </label>
              <div className="std-multiselect-component">
<span
    key={1}
    style={{
        display: "inline",
        // background: color,
        color: "black",
        width: "26px",
        height: "18px",
        textAlign: "left",
        marginRight: "10px",
        marginTop: "-6px"
    }}
> <Icon color="inherit" >how_to_reg</Icon></span>
                  <MultiSelect
                      onChange={handleChange}
                      value={selectedStaff}
                      data={staffs}
                      dataItemKey={"id"}
                      textField={"name"}
                      itemRender={staffItemRender}
                      tags={
                          selectedStaff && selectedStaff.length > 0
                              ? selectedStaff.map((item: any) => {
                                  return {
                                      text: `${item?.initials} `,
                                      data: [item],
                                  };
                              })
                              : []
                      }
                  />
              </div>
          </div>
          {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
  );
};
