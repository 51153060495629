import {Dialog} from "@progress/kendo-react-dialogs";
import {Field, Form, FormElement} from "@progress/kendo-react-form";

import {Error} from "@progress/kendo-react-labels";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import {IEquipment} from "../../models/entities/IEquipment";
import Grid from "@mui/material/Grid";
import {ValidatedInput, ValidatedTextArea,} from "../common/ValidatedFields";
import {requireValidator} from "../../utils/validators/requireValidator";
import {digitValidator} from "../../utils/validators/digitValidator";
import CustomTitleBar from "../common/CustomTitleBar";

import BaseButton from "components/common/BaseButton";

import {useAddEquipmentMutation, useUpdateEquipmentMutation,} from "../../store/api/equipments";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import {Fragment, useEffect} from "react";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import SaveIcon from "@mui/icons-material/Save";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

const EquipmentForm = (props: EditFormProps<IEquipment>) => {
  const [addEquipment, { isLoading: isAdding, isSuccess }] =
    useAddEquipmentMutation();
  const [updateEquipment, { isLoading: isUpdating }] =
    useUpdateEquipmentMutation();
  const currentPlanner = useCurrentPlanner();

  const handleSubmit = (event: IEquipment) => {
    if (!event.id) {
      addEquipment({ ...event, planner_id: currentPlanner?.id as number });
    } else {
      updateEquipment({ id: event.id, equipment: event });
    }
    props.onSubmit(event);
    // props.cancelEdit();
  };
  useEffect(() => {
    if (isSuccess) props.cancelEdit();
  }, [isSuccess]);
  return (
    <Dialog
      className={"equipment-dialog-form"}
      title={
        <CustomTitleBar
          type={(!props.item || !props.item.name || props.item.name === "")
              ? ""
              : "Modifica attrezzatura" }
          label={
            (!props.item || !props.item.name || props.item.name === "")
              ? "Nuova attrezzatura"
              : ""
          }
          icon="build"
        />
      }
      onClose={props.cancelEdit}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={props.item}
        render={(formRenderProps) => (
          <FormElement>
            <Grid container spacing={1} alignItems="stretch">
              <Grid item xs={12} md={12} className="rows-grandi">
                <Field
                  name={"name"}
                  component={ValidatedInput}
                  label={"Nome"}
                  required
                  validator={[requireValidator]}
                />
              </Grid>
              <Grid item xs={12} md={12} className="rows-grandi-2">
                <Field
                  multiline
                  rows={2}
                  className={"custom-unif-font"}
                  name={"description"}
                  component={ValidatedTextArea}
                  label={"Descrizione"}
                  validator={[]}
                />
              </Grid>
              <Grid item xs={12} md={4} className="rows-grandi">
                <div className="mb-3">
                  <Field
                    name={"qty"}
                    required
                    defaultVal={"0"}
                    component={ValidatedInput}
                    label={"Quantità operativa"}
                    validator={[requireValidator, digitValidator]}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4} className="rows-grandi">
                <div className="mb-3">
                  <Field
                    name={"repairing_qty"}
                    defaultVal={0}
                    component={ValidatedInput}
                    label={"Quantità in riparazione"}
                    validator={[digitValidator]}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4} className="rows-grandi">
                <div className="mb-3">
                  <Field
                      defaultVal={0}
                    name={"scrapping_qty"}
                      component={ValidatedInput}
                    label={"Quantità da rottamare"}
                    validator={[digitValidator]}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <div className="k-form-buttons">
                {isAdding || isUpdating ? (
                  <CenteredLoadingSpinner />
                ) : (
                  <Fragment>
                    <BaseButton
                      className={` ${
                        formRenderProps.allowSubmit
                          ? "custom-unif-viola-back"
                          : ""
                      }`}
                      type={"submit"}
                      variant="contained"
                      startIcon={<SaveIcon />}
                      disabled={!formRenderProps.allowSubmit}
                    >
                      Salva
                    </BaseButton>
                    {props.hasCancel && (
                      <BaseButton
                          onClick={props.cancelEdit}
                          disabled={!formRenderProps.allowSubmit}
                          startIcon={<CancelPresentationIcon />}
                          className={`custom-button-annulla custom-unif-grigio-back left-spacing-10p ${
                              (formRenderProps.allowSubmit)
                                  ? "custom-unif-arancione-back"
                                  : ""
                          }`}
                      >
                        Annulla
                      </BaseButton>
                    )}
                  </Fragment>
                )}

                {formRenderProps.visited &&
                  formRenderProps.errors &&
                  formRenderProps.errors.VALIDATION_SUMMARY && (
                    <div className={"k-messagebox k-messagebox-error"}>
                      <Error>{formRenderProps.errors.VALIDATION_SUMMARY}</Error>
                    </div>
                  )}
              </div>
            </Grid>
          </FormElement>
        )}
      />
      {/* <Toaster position={"bottom-center"} /> */}
    </Dialog>
  );
};
export default EquipmentForm;
