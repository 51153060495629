import {Card, CardActions, CardContent} from "@mui/material";
import React, {FC, useState} from "react";
import SelectPlanner from "./SelectPlanner";
import AddIcon from "@mui/icons-material/Add";
import AddPlanner from "./AddPlanner";
import BaseButton from "components/common/BaseButton";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Grid from "@mui/material/Grid";

const PlannerControl: FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const [openForm, setOpenForm] = useState<boolean>(false);
  // const currentPlanner = useCurrentPlanner();
  // const dispatch = useAppDispatch();
  // const classes = useStyles();

  const handleAddPlanner = () => {
    setOpenForm(true);
  };


    const cardStyle = {
        // width : '20vw',
    }

  return (

    <Card variant="outlined" style={cardStyle} >
      <CardContent>
        <SelectPlanner />
      </CardContent>

        <Grid container justifyContent="center">
            <div className="k-form-buttons">
        <CardActions
            className={"actions-popover-planner"}
            style={{paddingLeft :'80px', paddingBottom: '20px'}}>
            <BaseButton

                color="primary"
                className={"custom-unif-viola-back "}
                onClick={handleAddPlanner}
                variant="outlined"
                startIcon={<AddIcon />}
            >
                Nuova agenda
            </BaseButton>
        </CardActions>
        <CardActions
            className={"actions-popover-planner"}
            style={{ paddingBottom: '20px'}}>
            <BaseButton className={"custom-button-annulla custom-unif-grigio-back custom-unif-arancione-back"} onClick={()=>onClose()}  startIcon={<CancelPresentationIcon />}>Annulla</BaseButton>
        </CardActions>
            </div>
        </Grid>
      <AddPlanner
        opened={openForm}
        closeForm={() => {
          setOpenForm(false);
          onClose();
        }}
      />
    </Card>
  );
};

export default PlannerControl;
