  export const CustomSchedulerModelFields = {
    id: "task_id",
    description: "notes",
    start: "start",
    end: "end",
    title: "experience_name",
    recurrenceRule: "recurrenceRule",
    recurrenceId: "recurrenceID",
    recurrenceExceptions: "recurrenceException",
    // locationId: "location_id",
    // personId: "staff_id",
    // personIds: "others_staff",
  };

