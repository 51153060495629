import {createSlice, PayloadAction} from "@reduxjs/toolkit";


interface appointmentOpenedState {
    currentAppointment?: string | null;
}

const initialState: appointmentOpenedState = {
    currentAppointment: null,
};

const AppointmentOpenedSlice = createSlice({
    name: "AppointmentOpened",
    initialState,
    reducers: {
        setAppointmentOpenedState( state, action: PayloadAction<null | string>) {
            state.currentAppointment = action.payload;
        },
    },
});

export const {
    setAppointmentOpenedState,
} = AppointmentOpenedSlice.actions;

export default AppointmentOpenedSlice.reducer;
