import {Box, Divider, Grid, Typography, useMediaQuery, useTheme,} from "@mui/material";
import React, {useState} from "react";
import {useGetPlannersQuery} from "store/api/planners";
import CenteredLoadingSpinner from "../../../components/common/CenteredLoadingSpinner";
import PlannerForm from "../../../components/planners/PlannerForm";
import SeasonsForm from "../../../components/seasons/SeasonsForm";
import {IPlanner} from "../../../models/entities/IPlanner";
import {useCurrentPlanner} from "../../../utils/hooks/currentPlanner";
import BaseIconButton from "../../../components/common/BaseIconButton";
import {SelectionRange} from "@progress/kendo-react-dateinputs";
import AddSeasonForm from "../../../components/seasons/AddSeasonForm";
import AddIcon from "@mui/icons-material/Add";

const PlannerFormPage = () => {

  const {isLoading } = useGetPlannersQuery();
  const currentPlanner = useCurrentPlanner();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));


  const [, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenedAddSeason, setIsOpenedAddSeason] = useState(false);
  const [initialRange, setInitialRange] = useState<SelectionRange>();
  const [minDate, setMinDate] = useState<Date>();
  const [maxDate, setMaxDate] = useState<Date>();

  const handleClose = () => {
    setAnchorEl(null);
  };
  const openAddEmptyDialog = () => {
    setIsOpenedAddSeason(true);

    setMinDate(new Date());
    setMaxDate(undefined);
    setInitialRange(undefined);

    handleClose();
  };


  return (
    <div className={"planner-form-page"} style={{ padding: "1.5vh" }}>
      {/* <Typography variant="h4" style={{ margin: "5px" }}><BusinessIcon></BusinessIcon> Azienda di {user?.id}</Typography> */}
      {/* <PageTitle
        icon="local_library"
        title={`Agenda ${currentPlanner?.name}`}
      /> */}
      {isLoading ? (
        <CenteredLoadingSpinner />
      ) : (
        <Box
          display="flex"

          flexDirection={matches ? "row" : "column"}
          justifyContent="space-around"
        >
          <Box flex="2" padding={3} className={"general-info"}>
            <Typography variant="h5">Informazioni generali</Typography>
            <PlannerForm
              cancelEdit={() => {}}
              onSubmit={() => {}}
              item={currentPlanner as IPlanner}
              hasCancel={false}
            />
          </Box>
          <Divider
            orientation={matches ? "vertical" : "horizontal"}
            flexItem={matches}
          />
          <Box flex="2" padding={3} className={"open-periods"}>
            <Grid container alignItems="stretch" spacing={3}>
              <Grid item className={"periodi-apertura-title"}>
                <Typography variant="h5">Periodi di apertura</Typography>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <BaseIconButton
                    title="Aggiungi Periodo"
                    aria-label="Aggiungi Periodo"
                    onClick={() => openAddEmptyDialog()}
                    className={"custom-unif-viola-back bottoni-tondi-2 bottoni-tondi-responsive std-bottoni-tondi "}
                >
                  <AddIcon fontSize="small" />
                </BaseIconButton>

              </Grid>
            </Grid>
            <AddSeasonForm
                opened={isOpenedAddSeason}
                closeForm={() => setIsOpenedAddSeason(false)}
                initialRange={initialRange}
                minDate={minDate}
                maxDate={maxDate}
            />
            <SeasonsForm></SeasonsForm>

          </Box>
        </Box>
      )}
    </div>
  );
};

export default PlannerFormPage;
