import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {Error} from "@progress/kendo-react-labels";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import {IExperience} from "../../models/entities/IExperience";
import Grid from "@mui/material/Grid";
import {
    AutocompleteCity,
    ValidatedDropDownListKeyLabelTypes_customBehavior,
    ValidatedInput,
    ValidatedSwitch,
} from "../common/ValidatedFields";
import {useAppDispatch, useAppSelector} from "../../store";
import {requireValidator} from "../../utils/validators/requireValidator";

import {IExperienceViewModel} from "../../models/views/IExperienceViewModel";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import BaseButton from "../common/BaseButton";
import {useAddExperienceMutation, useGetExperienceQuery, useUpdateExperienceMutation,} from "store/api/experiences";
import React, {useEffect, useState} from "react";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {useHistory, useLocation} from "react-router-dom";
import {selectExperience} from "../../store/experiences";
import {useCurrentExperience} from "utils/hooks/currentExperience";
import SaveIcon from "@mui/icons-material/Save";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import {maxNumber100Validator} from "../../utils/validators/maxNumber100Validator";
import {MultiSelectChangeEvent} from "@progress/kendo-react-dropdowns";
import {digitValidator} from "../../utils/validators/digitValidator";

const ExperienceSuperAdmin = (props: EditFormProps<IExperience>) => {

    const currentPlanner = useCurrentPlanner();
    const [
        addExperience,
        {
            data: addedExperience,
            isLoading: isAdding,
            isSuccess: isAddExperienceSuccess,
        },
    ] = useAddExperienceMutation();


    const [updateExperience, { isLoading: isUpdating }] =
        useUpdateExperienceMutation();
    const currentExperience = useCurrentExperience();

    const { isFetching } = useGetExperienceQuery(props.item.id ?? skipToken);
    const history = useHistory();
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const [item, setItem] = useState(currentExperience ?? props.item);

    const [allowToResend, setAllowToResend] = useState(false);

    const {  areas, experiencetypes } = useAppSelector((store) => store.types);

    const [valueCity, setValueCity] = useState<string>(
        (currentExperience?.site_city !== undefined) ? (
            currentExperience?.site_city
        )  : ""
    );

    const [cityError, setCityError] = useState<boolean>(
        (currentExperience?.site_city === undefined || currentExperience?.site_city === "" || currentExperience?.site_city === " ") ? true : false
    );

    const [valueAreaFather, setValueAreaFather] = useState(

        (currentExperience?.site_area !== undefined) ? (
            (areas.find((i) => i.key  === currentExperience?.site_area))!== undefined ?
                areas.find((i) => i.key  === currentExperience?.site_area)?.key :  areas[0].key
        ) : areas[0].key
    );


    const [isErrorAreaF, setIsErrorAreaF] = useState(false);

    const [sonAreas, setSonAreas] = useState(
        (currentExperience?.site_area !== undefined) ?
            (areas.find((i) => i.key  === currentExperience?.site_area)?.subareas ) !== undefined ?
                areas.find((i) => i.key  === currentExperience?.site_area)?.subareas : areas[0]?.subareas
            : areas[0]?.subareas
    );

    const [valueAreaSon, setValueAreaSon] = useState(
        (currentExperience?.site_area !== undefined) ? (
            areas.find((i) => i.key  === currentExperience?.site_area)?.subareas?.find((i) => i.key  === currentExperience?.site_region) !== undefined ?
                areas.find((i) => i.key  === currentExperience?.site_area)?.subareas?.find((i) => i.key  === currentExperience?.site_region)?.key
                : (areas[0]?.subareas !== undefined ) ? areas[0]?.subareas[0].key : ""
        ) : ""
    );


    const [valueCategoryFather, setValueCategoryFather] = useState(

        (currentExperience?.site_category_id !== undefined) ? (
            (experiencetypes.find((i) => i.key  === currentExperience?.site_category_id))!== undefined ?
                experiencetypes.find((i) => i.key  === currentExperience?.site_category_id)?.key :  experiencetypes[0].key
        ) : experiencetypes[0].key
    );
    const [isErrorCategoryF, setIsErrorCategoryF] = useState(false);

    const [sonCategories, setSonCategories] = useState(
        (currentExperience?.site_category_id !== undefined) ?
            (experiencetypes.find((i) => i.key  === currentExperience?.site_category_id)?.subcategory ) !== undefined ?
                experiencetypes.find((i) => i.key  === currentExperience?.site_category_id)?.subcategory : experiencetypes[0]?.subcategory
            : experiencetypes[0]?.subcategory
    );

    const [valueCategorySon, setValueCategorySon] = useState(
        (currentExperience?.site_category_id !== undefined) ? (
            experiencetypes.find((i) => i.key  === currentExperience?.site_category_id)?.subcategory?.find((i) => i.key  === currentExperience?.site_subcategory_id) !== undefined ?
                experiencetypes.find((i) => i.key  === currentExperience?.site_category_id)?.subcategory?.find((i) => i.key  === currentExperience?.site_subcategory_id)?.key
                : (experiencetypes[0]?.subcategory !== undefined ) ? experiencetypes[0]?.subcategory[0].key : ""
        ) : ""
    );


    const handleAreasFatherChange= (event: MultiSelectChangeEvent) => {
        //console.log(event);
        //roles.find(t=>t.label==event.value)?.key

        const area = areas.find((i) => i.key  === event.value.key)
        setValueAreaFather(event.value.key);
        setSonAreas(area?.subareas)
    };
    const handleAreasSonChange= (event: MultiSelectChangeEvent) => {
        //console.log(event);
        //roles.find(t=>t.label==event.value)?.key

        const sonArea = areas.find(
            (i) => i.key  === valueAreaFather)?.subareas?.find((i) => i.key  === event.value.key)?.key;

        setValueAreaSon(sonArea)
    };
    const handleCategoryFatherChange= (event: MultiSelectChangeEvent) => {
        //console.log(event);
        //roles.find(t=>t.label==event.value)?.key

        const category = experiencetypes.find((i) => i.key  === event.value.key)
        setValueCategoryFather(event.value.key);
        setSonCategories(category?.subcategory)
    };
    const handleCategorySonChange= (event: MultiSelectChangeEvent) => {
        //console.log(event);
        //roles.find(t=>t.label==event.value)?.key

        const sonCategory = experiencetypes.find((i) => i.key  === valueCategoryFather)?.subcategory?.find((i) => i.key  === event.value.key)?.key;

        setValueCategorySon(sonCategory)
    };

    const [cap, setCap] = useState(currentExperience?.site_cap)
    const handleSubmit = (event: IExperience) => {
        if(valueCategoryFather === undefined ) {
            setIsErrorAreaF(true);
        }else{
            if(valueAreaFather === undefined) {
                setIsErrorCategoryF(true);
            }else{
                setIsErrorAreaF(false);
                setIsErrorCategoryF(false);
                // event.site_area = valueAreaFather;
                if(cap!== undefined) {
                    event.site_cap = cap;
                }
                event.site_city = valueCity;
                if (valueAreaFather)        event.site_area = valueAreaFather;
                if (valueAreaSon)           event.site_region = valueAreaSon;
                if (valueCategoryFather)    event.site_category_id = valueCategoryFather;
                if (valueCategorySon)       event.site_subcategory_id = valueCategorySon;

                if (!event.id) {

                    if (!event.active) event.active = false;
                    if (!event.site_active) event.site_active = false;
                    addExperience({
                        ...event,
                        planner_id: currentPlanner?.id,
                    } as IExperienceViewModel);
                } else {
                    updateExperience({id: event.id, experience: event as IExperience}).then(result => {
                      // @ts-ignore
                        if(result.error){
                            setAllowToResend(true);
                      }else{
                            setAllowToResend(false);
                          setItem(event);
                          props.onSubmit(event);
                      }
                    });
                }

            }
        }


    };
    const getPlacesPostCodeById = async (placeId: any) =>
        new Promise<{ [key: string]: string }>((resolve, reject) => {

            if (!placeId) reject("placeId not provided");

            try {
                new window.google.maps.places.PlacesService(
                    document.createElement("div")
                ).getDetails(
                    {
                        placeId,
                        fields: ["address_components"],
                    },
                    (details) => {

                        let routeInfo: { [key: string]: string } = {};

                        details?.address_components?.forEach((entry) => {
                            routeInfo[entry.types?.[0] as string] = entry.long_name;
                        });

                        return resolve(routeInfo);
                    }
                );
            } catch (e) {
                reject(e);
            }
        });
    const UpdateField = (city: string, isEff: boolean) => {
        let targetDiv = null;

        let targetError = null;
        targetError = window.document.getElementsByClassName("comune-validator")[0]!;


        if(targetError !== undefined && targetError.classList !==undefined) {
            targetError.classList.add("display-true");
        }
        // targetError.classList.add("display-true");
        //PER INIZIALIZZARE L'AUTOCOMPLETE
        targetDiv = window.document.getElementById("auto-city-prova")!;
        if (isEff) {
            if (targetDiv !== null && targetDiv !== undefined) {
                if (
                    targetDiv.getElementsByClassName("css-1wa3eu0-placeholder")[0] !==
                    undefined
                ) {
                    targetDiv.getElementsByClassName(
                        "css-1wa3eu0-placeholder"
                    )[0].textContent = city;
                    targetDiv
                        .getElementsByClassName("css-1wa3eu0-placeholder")[0]
                        .classList.add("css-1uccc91-singleValue");
                }
            }
        } else {
            if (targetDiv !== null && targetDiv !== undefined) {

                if(targetDiv.getElementsByClassName("css-1uccc91-singleValue")[0] === undefined){
                    targetDiv
                        .getElementsByClassName("css-1wa3eu0-placeholder")[0]
                        .classList.add("css-1uccc91-singleValue");
                }

                targetDiv.getElementsByClassName(
                    "css-1uccc91-singleValue"
                )[0].textContent = city;
                if(targetError !== undefined && targetError.classList !== undefined)
                {
                    targetError.classList.remove("display-true");
                }
            }
        }
    };
    useEffect(() => {
        if (isAddExperienceSuccess) {
            dispatch(selectExperience(addedExperience?.id));

            history.push(`${pathname}/prices`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddExperienceSuccess]);


    return isUpdating || isAdding || isFetching ? (
        <CenteredLoadingSpinner />
    ) : (
        <Form
            ignoreModified={allowToResend}
            onSubmit={(e) => handleSubmit(e as IExperience)}
            initialValues={item}
            render={(formRenderProps) => (
                <FormElement style={{ maxWidth: "100vw" }}>
                    <Grid container alignItems="stretch" spacing={3} className={"bottom-spacing"}>
                        <Grid item xs={12} className={"superadmin-dialog-subtitle custom-unif-font-2 "}>
                            <label>ID Gestionale (ID exp): {item.id}</label><br/>
                            <label>ID CMS (ID Macro): {item.cms_page_id ?? '-'}</label>
                        </Grid>
                        <Grid item xs={12} sm={6} className="item-media-rule rows-grandi">
                            <Field
                                onChange={(e) => {

                                    getPlacesPostCodeById(e.value.place_id).then(
                                        function (result: { [key: string]: string }) {


                                            setCityError(true);


                                            if (
                                                e.value.terms[e.value.terms.length - 1].value !== "" &&
                                                e.value.terms[e.value.terms.length - 1].value !== null &&
                                                e.value.terms[e.value.terms.length - 1].value !==
                                                undefined
                                            ) {
                                                formRenderProps.onChange("site_province", {
                                                    value: e.value.terms[e.value.terms.length - 1].value,
                                                });
                                            }


                                            if (
                                                result.postal_code !== "" &&
                                                result.postal_code !== null
                                            ) {

                                                setCap(result.postal_code);
                                            }

                                            if (result.locality !== "" && result.locality !== null  ) {
                                                formRenderProps.onChange("site_city", {
                                                    value: result.locality,
                                                });
                                                setValueCity(result.locality);
                                                UpdateField(result.locality, false);
                                                setCityError(false);
                                            }
                                            if (result.administrative_area_level_3 !== "" && result.administrative_area_level_3 !== null && result.locality === undefined ){

                                                setValueCity(result.administrative_area_level_3);
                                                formRenderProps.onChange("site_city", {
                                                    value: result.administrative_area_level_3,
                                                });
                                                setCityError(false);
                                                UpdateField(result.administrative_area_level_3, false);
                                            }



                                            // formRenderProps.onChange("postal_code", { value: result });
                                        }
                                    );
                                }}
                                className="autocompletamento-citta"
                                name={"site_city"}
                                required
                                value={valueCity}
                                label="Comune *"
                                validator={[requireValidator]}
                                component={AutocompleteCity}
                            />
                            {cityError && <Error>Bisogna selezionare un comune</Error>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                                <Field
                                    name={"site_province"}
                                    component={ValidatedInput}
                                    label={"Prov."}
                                    disabled={true}
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid item xs={12} className={"rows-grandi custom-unif-font-2 "}>
                                <Field
                                    name={"site_area"}
                                    label={"Area *"}
                                    // required
                                    className={"custom-unif-font-2"}
                                    component={ValidatedDropDownListKeyLabelTypes_customBehavior}
                                    // itemRender={areasFatherItemRender}

                                    onChange={handleAreasFatherChange}
                                    data={areas}
                                    value={valueAreaFather}
                                    defaultVal={areas[0].key}

                                />
                                {isErrorAreaF && <Error>Bisogna selezionare un'area</Error>}
                            </Grid>
                            <Grid item xs={12} >
                                <Field
                                    name={"site_region"}
                                    label={"Sottoarea"}
                                    // required
                                    className={"custom-unif-font-2"}
                                    component={ValidatedDropDownListKeyLabelTypes_customBehavior}
                                    // itemRender={areasSonItemRender}
                                    onChange={handleAreasSonChange}
                                    data={sonAreas}
                                    value={valueAreaSon}

                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid item xs={12} className={"rows-grandi"}>
                                <Field
                                    name={"site_category_id"}
                                    label={"Categoria *"}
                                    className={"custom-unif-font-2"}
                                    component={ValidatedDropDownListKeyLabelTypes_customBehavior}
                                    // itemRender={categoryFatherItemRender}
                                    onChange={handleCategoryFatherChange}
                                    data={experiencetypes}
                                    value={valueCategoryFather}
                                    defaultVal={experiencetypes[0].key}

                                />
                                {isErrorCategoryF && <Error>Bisogna selezionare una categoria</Error>}
                            </Grid>
                            <Grid item xs={12} >
                                <Field
                                    name={"site_subcategory_id"}
                                    label={"Sottocategoria "}
                                    // required
                                    className={"custom-unif-font-2"}
                                    component={ValidatedDropDownListKeyLabelTypes_customBehavior}
                                    // itemRender={categoriesSonItemRender}
                                    onChange={handleCategorySonChange}
                                    data={sonCategories}
                                    value={valueCategorySon}

                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={5} className={"rows-grandi"}>
                            <Field
                                name={"site_discount"}
                                component={ValidatedInput}
                                label={"Sconto in acquisto"}
                                adornment={"%"}
                                defaultVal={" "}
                                value={item.site_discount}
                                validator={[digitValidator, maxNumber100Validator]}

                                maxVal={100}
                            />

                        </Grid>
                        <Grid item xs={12} sm={5} className={"rows-grandi"}>
                            <Field
                                name={"site_mark_up"}
                                component={ValidatedInput}
                                label={"Mark-up %"}
                                adornment={"%"}
                                defaultVal={" "}
                                value={item.site_mark_up}
                                validator={[digitValidator, maxNumber100Validator]}

                                maxVal={100}
                            />

                        </Grid>
                        <Grid
                            item
                            xs={2}
                            style={{textAlign: "center"}}
                            className={"attiva-switch"}
                        >
                            {/*<Field*/}

                            {/*    name={"active"}*/}
                            {/*    component={ValidatedSwitch}*/}
                            {/*    label={"Visibile"}*/}
                            {/*    //value={active}*/}
                            {/*    defaultChecked={true}*/}
                            {/*/>*/}
                            <Field
                                name={"site_active"}
                                component={ValidatedSwitch}
                                label={"Prenotabile"}
                                //value={site_active}
                                defaultChecked={true}
                            />
                        </Grid>



                    </Grid>
                    <Grid container justifyContent="center">
                        <div className="k-form-buttons">
                            <BaseButton
                                className={` ${
                                    (formRenderProps.allowSubmit &&
                                        !isErrorAreaF && !isErrorCategoryF
                                    )? "custom-unif-viola-back" : ""
                                }`}
                                type={"submit"}
                                variant="contained"
                                disabled={
                                    !formRenderProps.allowSubmit                               ||
                                    isErrorAreaF || isErrorCategoryF
                                }
                                startIcon={<SaveIcon />}
                            >
                                Salva
                            </BaseButton>
                            {props.hasCancel && (
                                <BaseButton
                                    disabled={!formRenderProps.allowSubmit ||
                                        isErrorAreaF || isErrorCategoryF
                                    }
                                    className={`custom-button-annulla custom-unif-grigio-back left-spacing-10p ${
                                        (formRenderProps.allowSubmit && !isErrorAreaF && !isErrorCategoryF
                                        )
                                            ? "custom-unif-arancione-back"
                                            : ""
                                    }`}

                                    onClick={props.cancelEdit}
                                    startIcon={<CancelPresentationIcon />}
                                >Annulla</BaseButton>
                            )}

                            {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    <Error>{formRenderProps.errors.VALIDATION_SUMMARY}</Error>
                                </div>
                            )}
                        </div>
                    </Grid>
                </FormElement>
            )}
        />
    );
};
export default ExperienceSuperAdmin;
