import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IAgePrice} from "../models/entities/prices/IAgePrice.";

interface PricesState {
  agePrices: IAgePrice[],

}


const initialState: PricesState = {
  agePrices: [],
}


const pricesSlice = createSlice({
  name: "prices",
  initialState,
  reducers: {
    updateAgePrice: (state, action: PayloadAction<{ index: number, agePrice: IAgePrice }>) => {
      state.agePrices[action.payload.index] = action.payload.agePrice
    },
    deleteAgePrice: (state, action: PayloadAction<number>) => {

    }
  },
  extraReducers: {

  }
})

// eslint-disable-next-line
export const {} = pricesSlice.actions

export default pricesSlice.reducer