import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartValueAxis,
    ChartValueAxisItem,
    TooltipContext
} from "@progress/kendo-react-charts";
import * as React from "react";
import CenteredLoadingSpinner from "../common/CenteredLoadingSpinner";
import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";


export interface HistogramChart {
    toggleDialog?: () => void;
    onConfirm?: () => void;
    data : any;
}
const HistogramYearChart = (
    props: HistogramChart
) => {


    // const labelContent = (props : any) => {
    //     return props.value;
    // };
    // const TooltipRenderUt = (props: TooltipContext) => (
    //     <div>
    //         {props.point.value} €
    //     </div>
    // );

    const categories = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
    const series = props.data;

    // const totalCat = ["2020 - 2021"];
    // const seriesTotal =[
    //     {
    //         name: "2020",
    //         color: "#5c43b5",
    //         data: [112000],
    //     },
    //     {
    //         name: "2021",
    //         color: "#f47056",
    //         data: [303367, ],
    //     },
    // ];
    //
    // useEffect(()=>{
    //     console.log("histograaaam");
    //     console.log(props.data);
    // },[props.data])

    return (
        (series)?
            <Grid container>
                <Grid item xs={12}>
                    <Chart renderAs={'canvas'}  zoomable={true} >
                        <ChartLegend position="top" orientation="horizontal" />
                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem categories={categories} startAngle={45} axisCrossingValue={[0, 12]}/>

                        </ChartCategoryAxis>
                        <ChartValueAxis>

                            <ChartValueAxisItem
                                name={"lineax"}
                                labels={{ format: "{0:N0}" }}

                            />

                            <ChartValueAxisItem
                                name={"columnax"}
                                labels={{ format: "{0:N0}" }}
                            />
                        </ChartValueAxis>
                        <ChartSeries>



                            { series.map((item : any) => (
                                 item.types.map((subItem : any) => (


                                 <ChartSeriesItem
                                    axis={subItem.typeName +"ax"}
                                    type={subItem.typeName}
                                    data={subItem.data}
                                    name={item.name+" "+((subItem.typeName === "line") ? " cumulativi" : " mensili")}
                                    color={((subItem.typeName === "column") ?
                                        item.color :
                                        ( (item.color === "#f47056")?
                                            "#db8e7f" :
                                            "#9b84e8"
                                        ))
                                    }
                                    tooltip={{ visible: true, format: 'c0' }}
                                >

                                </ChartSeriesItem>

                                ))
                            )) }
                        </ChartSeries>

                    </Chart>
                </Grid>
            </Grid>
            :
            <>{
                (series!==undefined && series.length === 0) ?
                    <>
                        <Grid item xs={12} style={{  textAlign:"center"}}>
                            <Typography variant="h5" style={{ margin: "5px" , textAlign:"center"}}>

                                Non sono presenti dati da visualizzare
                            </Typography>
                        </Grid>

                    </>
                    :
                    <CenteredLoadingSpinner></CenteredLoadingSpinner>}


            </>
    );
};

export default HistogramYearChart;

export const MemoizedHistogramYearChart = React.memo(HistogramYearChart);