import {Dialog} from "@progress/kendo-react-dialogs";
import BaseButton from "./BaseButton";
import React from "react";

export interface AlertMessageDialogProps {
  toggleDialog: () => void;
  onConfirm: () => void;
}

const AlertMessageDialog = (props: AlertMessageDialogProps) => {
  return (
    <Dialog
        height={"200px"}
        width={"650px"}
      title={"Attenzione!"}
      className={"alert-message-dialog"}
      onClose={props.toggleDialog}
      style={{ zIndex: "1301 !important" }}

    >
      <div className={"message-zone"}>
        <p>
          la scelta di una diversa tipologia di listino comporta la cancellazione dei prezzi già impostati
        </p>
      </div>
        <div className="std-dialog-actions-container">
          <BaseButton className={"custom-unif-arancione-back std-dialog-action-button"} onClick={props.toggleDialog}>Annulla</BaseButton>
          <BaseButton
              className={"custom-unif-viola-back std-dialog-action-button"}
              onClick={() => {
                props.onConfirm();
                props.toggleDialog();
              }}
          >
            Continua
          </BaseButton>
        </div>

    </Dialog>
  );
};

export default AlertMessageDialog;
