import {DropDownList} from '@progress/kendo-react-dropdowns';
import {FieldProps} from '@progress/kendo-react-form';
import {Error} from "@progress/kendo-react-labels";
import {IExperience} from 'models/entities/IExperience';

export const ExperienceDropdownlist = (props: FieldProps) => {
    const { validationMessage, visited, value, source, data, ...others } = props;



    return (
        <div>
            <div className="std-dropdown-container dropdownModifica dropdownModifica-expirience custom-unif-dropdown-focus">
                <label
                    className="labelModifica std-dropdown-label"
                    data-shrink="true">{props.label}
                </label>
                <div className="std-dropdown-component">

                    <DropDownList

                        className={"custom-unif-font-2 "}
                        //onChange={handleChange}
                        value={data?.find((e:IExperience)=> e.id=== value)}
                        data={data}
                        disabled={props.disabled}
                        dataItemKey={'id'}
                        textField={'name'}

                        {...others}
                    />
                </div>
            </div>
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};