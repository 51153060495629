import * as React from "react";
import {useState} from "react";
import {
    Grid,
    GridCellProps,
    GridColumn as Column,
    GridFilterChangeEvent,
    GridHeaderCellProps,
    GridItemChangeEvent,
    GridRowClickEvent,
    GridRowProps,
    GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import {CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {experienceAvailable, useUpdateExperienceAvailableResellerMutation} from "../../store/api/experiences";
import CenteredLoadingSpinner from "../common/CenteredLoadingSpinner";
import {IExperienceReseller} from "../../models/entities/IExperienceReseller";
import {EditorDropDownCell} from "./EditorDropDownCell";
import {EditorCommandCell} from "./EditorCommandCell";
import {apiPath} from "../../store/api/api";
import {useMe} from "../../utils/hooks/me";

const EDIT_FIELD = "inEdit";
const MAX_CHAR = 50;

const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
};
const ResellerAvailableExperience = ( props: { reseller_id: number }) => {

    const token = localStorage.getItem("token");
    const get_path = apiPath + "experience/reseller/";
    const user = useMe();


    const header_get =  {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }

    const [data, setData] = useState<IExperienceReseller[]>([]);
    const [fetching, setFetching] = useState<boolean>(false);
    //FETCH DElle esperienze
    //Uso il fetch perchè questa chiamata va fatta ogni volta, quindi RTK (il quale fa uso di caching,) non mi va ben
    React.useEffect(() => {
        if( props.reseller_id !== undefined) {
            let optObjHeadBody = {
                method: 'GET',
                headers:header_get,

            };
            setFetching(true);
            fetch(get_path + props.reseller_id , optObjHeadBody)
                .then(res => res.json())
                .then(
                    (result) => {
                        if(result.experiences) {

                            setFetching(false);
                            if (user?.role === "reception") {
                                const tmpExp = [...result.experiences];
                               setData(tmpExp.filter((item : IExperienceReseller) => item.available));
                            } else {

                                setData(result.experiences)
                            }
                        }

                    },
                    // Nota: è importante gestire gli errori qui
                    // invece di un blocco catch() in modo da non fare passare
                    // eccezioni da bug reali nei componenti.
                    (error) => {

                        // setIsLoaded(true);
                        // setError(error);
                    }
                )
            return () =>{}
        }
    }, [])

    const [updateExperiences] =
        useUpdateExperienceAvailableResellerMutation();



    const [filter, setFilter] = useState(initialFilter);
    const [filterable, setFilterable] = useState(false);
    const [iconFilter, setIconFilter] = useState("filter");
    const [editedExperiences , setEditedExperiences] = useState<experienceAvailable[]>([])
    const [editID, setEditID] = useState<number | null>(null);
    const [, setChanges] = useState<boolean>(false);
    const [sort, setSort] = useState<SortDescriptor[]>([{ field: "distance", dir: "asc" }]);

    const [originalVal, setOriginalVal] = useState<boolean | null>(false);

    const rowClick = (event: GridRowClickEvent) => {
        setEditID(event.dataItem.id);
    };
    const CommandCell = (props: GridCellProps) => (
        <EditorCommandCell
            {...props}
            edit={enterEdit}
            discard={discard}
            update={update}
            cancel={discard}
            editField={EDIT_FIELD}
        />
    );
    const FilterCell = (props: GridHeaderCellProps) => (
        <button
            className={"custom-filter-button"}
            // style={{right: "40px", position: "relative"}}
            onClick={() => {
                setFilterable(!filterable);
                setIconFilter(
                    iconFilter === "filter" ? "filter-clear" : "filter"
                );
            }}
        >
            {!filterable ? (
                <span className="k-icon k-i-filter" />
            ) : (
                <span className="k-icon k-i-filter-clear" />
            )}
        </button>
    );


    // Local state operations
    const discard = (dataItem: IExperienceReseller) => {
        if(editID && originalVal !== null) {
            const newData = data.map((item) =>
                item.id === dataItem.id ? { ...item, available: originalVal, [EDIT_FIELD]: undefined} : item
            );
            setData(newData)
        }
    };


    const itemChange = (event: GridItemChangeEvent) => {
        const inEditID = event.dataItem.id;
        setEditID(inEditID);
        const field = event.field || "";
        const newData = data.map((item) =>
            item.id === inEditID ? { ...item, [field]: event.value } : item
        );
        // setEditedExperiences([...editedExperiences,{id:event.dataItem.id , available: event.value }])
        setData(newData);
        // setChanges(true);
    };

    const update = (dataItem: IExperienceReseller) => {
        if(dataItem.id) {
            let _editedExperience = [...editedExperiences];

            setEditedExperiences([..._editedExperience.filter(i => i.id !== dataItem.id), {id: dataItem.id, available: dataItem.available}])

            updateExperiences({reseller_id: props.reseller_id, experiences: [{id: dataItem.id, available: dataItem.available}]});
            const newData = data.map((item) =>
                item.id === dataItem.id ? { ...item, available: dataItem.available } : item
            );
            setData(newData);
            setEditedExperiences([])
            setChanges(true);
        }
        exitEdit();


    };

    const enterEdit = (dataItem: IExperienceReseller) => {
       if(dataItem.id && dataItem.available) {
           setEditID(dataItem.id);
           setOriginalVal(dataItem.available);
       }
        data.forEach((e)=>{
            if(e.inEdit){
                update(e);
            }
        })

        let newData = data.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } :  { ...item, inEdit: false }
        );
        setData(newData);
    };

    const exitEdit = () => {
        const newData = data.map((item) => ({ ...item, [EDIT_FIELD]: undefined }));

        setData(newData);
    };


    const rowRender = (
        trElement: React.ReactElement<HTMLTableRowElement>,
        props: GridRowProps
    ) => {
        const available = props.dataItem.available;
        const grey = { backgroundColor: "#c7c6c3" };
        const white = { backgroundColor: "white" };

        const trProps: any = { style: available ? white : grey };
        return React.cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    };

    return( data== undefined || fetching ? (
                <CenteredLoadingSpinner />
            ) : (
        <Grid
            className={"generic-grid-metis experiences-grid-reseller std-grid-component-inside-dialog"}
            sortable={true}
            dataItemKey={"id"}

            filterable={filterable}
            filter={filter}
            onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
            data={orderBy(filterBy(     data , filter), sort)} // devo far vedere solo le aviable se sono ilr eception
            // cellRender={customCellRender}
            rowRender={rowRender}
            editField={EDIT_FIELD}
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
            }}
            onRowClick={rowClick}
            onItemChange={itemChange}
        >
            <Column field="name" title="Nome" width={430}  editable={false}
                    cell={(props: GridCellProps) => (

                        <td style={{cursor: "pointer"}}>
                            <a href={props.dataItem.url} target={"_blank"} title={props.dataItem.name}>{(props.dataItem.name.length >= MAX_CHAR) ? props.dataItem.name.substring(0, MAX_CHAR) + "..." : props.dataItem.name} </a>
                        </td>
                    )}
            />
            <Column field="site_subcategory_label" title="Categoria"  editable={false} />
            <Column field="site_city" title="Località"  editable={false} />
            <Column field="distance" title="Distanza (km)" className={"allinea-colonna-centro"}  headerClassName={"allinea-colonna-centro-header"} editable={false}

                    cell={(props: GridCellProps) => (

                        <td >
                            {props.dataItem.distance.toFixed(1)}
                        </td>
                    )}
            />
            {(user?.role !== "reception") &&
            <Column
                filterable={false}
                field="available"
                title="Stato"
                cell={EditorDropDownCell}
            />}

            {(user?.role !== "reception") && <Column   filterable={false} width={170} cell={CommandCell} headerCell={FilterCell} /> }
        </Grid>
            )
   )
};
export default ResellerAvailableExperience;