import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IUser} from "../models/entities/IUser";
import {SliceState} from "../models/SliceState";
import userApi from "../utils/api/userApi";

export const getAllUsers = createAsyncThunk(
  "/users/getAll",
  async (thunkApi) => {
    return (await userApi.getAll()).data.users as IUser[];
  }
);
export const getUsersCompany = createAsyncThunk(
  "/users/getUsersCompany",
  async (plannerId: number, thunkApi) => {
    return (await userApi.getUsersCompany(plannerId)).data.users as IUser[];
  }
);

export const addUser = createAsyncThunk(
  "/users/add",
  async (user: IUser, thunkApi) => {
    return (await userApi.addUser(user)).data.user as IUser;
  }
);

export const updateUser = createAsyncThunk(
  "/users/update",
  async ({ id, user }: { id: number; user: IUser }, thunkApi) => {
    return (await userApi.updateUser(id, user)).data.user as IUser;
  }
);

export const deleteUser = createAsyncThunk(
  "/users/delete",
  async (id: number, thunkAPI) => {
    await userApi.deleteUser(id);
    return id;
  }
);

interface userState {
  users: IUser[];
  usersCompany?: IUser[];
  currentUserId?: number;

  getAllUsersState: SliceState;
  getUsersCompanyState: SliceState;
  addUserState: SliceState;
  updateUserState: SliceState;
  deleteUserState: SliceState;
}

const initialState: userState = {
  users: [],
  usersCompany: [],
  currentUserId: 0,
  getAllUsersState: { state: "idle" },
  getUsersCompanyState: { state: "idle" },
  addUserState: { state: "idle" },
  updateUserState: { state: "idle" },
  deleteUserState: { state: "idle" },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    selectUser(state, action: PayloadAction<number>) {
      state.currentUserId = action.payload;
    },
    resetGetAllUsers(state) {
      state.getAllUsersState = { state: "idle" };
    },
    resetAddUser(state) {
      state.addUserState = { state: "idle" };
    },
    resetUpdateUser(state) {
      state.updateUserState = { state: "idle" };
    },
    resetDeleteUser(state) {
      state.deleteUserState = { state: "idle" };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state, action) => {
        state.getAllUsersState = { state: "loading" };
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.getAllUsersState = {
          state: "error",
          errorMessage: action.error.message,
        };
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.getAllUsersState = { state: "success" };
      })
      .addCase(getUsersCompany.pending, (state, action) => {
        state.getUsersCompanyState = { state: "loading" };
      })
      .addCase(getUsersCompany.rejected, (state, action) => {
        state.getUsersCompanyState = {
          state: "error",
          errorMessage: action.error.message,
        };
      })
      .addCase(getUsersCompany.fulfilled, (state, action) => {
        state.usersCompany = action.payload;
        state.getUsersCompanyState = { state: "success" };
      })
      .addCase(addUser.pending, (state, action) => {
        state.addUserState = { state: "loading" };
      })
      .addCase(addUser.rejected, (state, action) => {
        state.addUserState = {
          state: "error",
          errorMessage: action.error.message,
        };
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.addUserState = { state: "success" };
        state.users.push(action.payload);
      })
      .addCase(updateUser.pending, (state) => {
        state.updateUserState = { state: "loading" };
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.updateUserState = {
          state: "error",
          errorMessage: action.error.message,
        };
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.updateUserState = { state: "success" };
        const index = state.users.findIndex((c) => c.id === action.payload.id);
        state.users[index] = action.payload;
      })
      .addCase(deleteUser.pending, (state) => {
        state.deleteUserState = { state: "loading" };
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.deleteUserState = {
          state: "error",
          errorMessage: action.error.message,
        };
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.deleteUserState = { state: "success" };
        state.users = state.users.filter((c) => c.id !== action.payload);
      });
  },
});

export const {
  selectUser,
  resetAddUser,
  resetGetAllUsers,
  resetDeleteUser,
  resetUpdateUser,
} = userSlice.actions;

export default userSlice.reducer;
