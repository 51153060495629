import {CircularProgress} from '@mui/material'

const FullPageLoader = () => {
    return (
        <span
            className={'full-page-loader'}
        >
            <CircularProgress className={'full-page-loader-inside'}/>
        </span>
    )
}

export default FullPageLoader
