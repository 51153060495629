import {Dialog} from "@progress/kendo-react-dialogs";
import {useGetExperienceQuery, useGetExperiencesByStaffIdQuery} from "../../store/api/experiences";
import React, {Fragment, useEffect, useState} from "react";
import {IExperience} from "../../models/entities/IExperience";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import {addMinutes, dateToStringDDMMYYYYwithsepator} from "../../utils/dateHelper";
import CenteredLoadingSpinner from "../common/CenteredLoadingSpinner";
import GenericGrid from "../common/GenericGrid";
import {ISubscriber} from "../../models/entities/calendar/ISubscriber";
import AddSubscriberForm from "../subscribers/AddSubscriberForm";
import SubscriberForm from "../subscribers/SubscriberForm";
import {useDeleteSubscriberMutation, useGetSubscribersQuery} from "../../store/api/subscribers";
import {skipToken} from "@reduxjs/toolkit/dist/query/react";
import {SCREEN_LG, SCREEN_MD, useWindowSize} from "../common/ScreenSizes";
import {setAppointmentOpenedState} from "store/detailAppointment";

export const SchedulerDialogDetail = (props: any) => {
    const handleClose = () => {
        setAppointmentOpenedState(null);
        props.toggleDialog();
    };



    const currentPlanner = useCurrentPlanner();
    const {
        data: experiencesByStaffId
    } = useGetExperiencesByStaffIdQuery(
        { plannerId:  props.appointment.planner_id as number, staffId: props.appointment.staffId },
        { skip: !currentPlanner ||  props.appointment.staffId === 0 }
    );
    const {
        data: subscribers,
        isFetching,
    } = useGetSubscribersQuery(props.appointment?.id ?? skipToken);

    const [, SetCurrExpMonoLang] = useState<boolean>(false);
    useEffect(() => {

        if (experiencesByStaffId) {
            const experience = (experiencesByStaffId as IExperience[]).find(
                (item: IExperience) => item.id ===  props.appointment?.experience_id
            ) as IExperience;
            SetCurrExpMonoLang(experience.monolanguage);

        }
    }, [experiencesByStaffId]);


    const generateTitle = () => {
        let dateStart = new Date(props.appointment.start as string);
        dateStart = addMinutes(dateStart, -0);
        // let dateEnd = new Date(props.appointment.end as string);
        // dateEnd = addMinutes(dateEnd, -0);
        let tit = `Partecipanti: ${props.appointment.experience_name} - ${dateToStringDDMMYYYYwithsepator(
            dateStart
        )}` ;
       tit += " " +props.appointment.begin_time + " - " + props.appointment.end_time;
        return tit;
    };

    const [deleteSubscriber, { isLoading: isDeleting }] =
        useDeleteSubscriberMutation();

    const size = useWindowSize();



    const remove = (dataItem: ISubscriber) => {
        // console.log(dataItem);
        deleteSubscriber(dataItem.id as number);
    };

    let nameWidth                = null;
    let countryWidth             = null;
    let phoneWidth               = null;
    let emailWidth               = null;
    let totalNumberWidth         = null;
    let total_priceNumberWidth   = null;





    // console.log(subscribers);
    if(size)
    {
        nameWidth               = (size < 602) ? "220" : "250";
        countryWidth            = (size < SCREEN_LG) ? "0" : null;
        phoneWidth              = (size < 1050) ? "0" : null;
        emailWidth              = (size < SCREEN_MD) ? "0" : null;
        totalNumberWidth        = (size < 750) ? "0" : null;
        total_priceNumberWidth  = (size < 602) ? "0" : "150";
    }

    const { data: experience } = useGetExperienceQuery(
        props.appointment?.experience_id ?? skipToken
    );


    const [, setVisibleError] = useState(false);

    useEffect(() => {
        if (experience && experience.prices !== undefined) {
            setVisibleError(true);
        }else{
            setVisibleError(false);
        }
    },[experience]);




    return (
        <Dialog

            // children={children}
            className={props.className + " appuntamento-dialog"}
            // minWidth={props.minWidth}
            style={props.style}
            title={generateTitle()}
            onClose={handleClose}
            height={"100vh"}
            width={"100vw"}

        >
            <div style={{padding:"0"}}
                 className={"partecipants-table in-detail"}>
                {" "}
                {isFetching || isDeleting ? (
                    <CenteredLoadingSpinner />
                ) : (

                    <Fragment>
                        <GenericGrid
                            titleDelete={"Conferma cancellazione partecipante"}
                            textDelete={"Sicuro di voler cancellare il partecipante "}
                            items={
                                (subscribers?.filter(
                                    (t) => t.appointment_id === props.appointment?.id
                                ) as ISubscriber[]) ||
                                (props.appointment?.subscribers as ISubscriber[])
                            }
                            columns={[
                                { field: "name",            title: "Nome" ,           width : (nameWidth!== null? (  nameWidth): "")  },
                                { field: "country",         title: "Nazionalità" ,    width : (countryWidth!== null? (  countryWidth): "") },
                                { field: "phone",           title: "Telefono",        width : (phoneWidth!== null? (  phoneWidth): "")  },
                                { field: "email",           title: "Email",           width : (emailWidth!== null? (  emailWidth): "") },
                                { field: "total_number",    title: "Partecipanti" ,   width : (totalNumberWidth!== null? (  totalNumberWidth): "")},
                                // { field: "price_type", title: "Tipo prezzo", width: "95" },
                                // { field: "total_discount", title: "Sconto" },
                                { field: "total_price",     title: "Importo",         width : (total_priceNumberWidth!== null? (  total_priceNumberWidth): "") },
                                { field: "prenotation",     title: "Prenotazione",    width :  ((size && size < 602)? "0" : "200")   },
                            ]}
                            enableCancel={true}
                            onRemove={remove}
                            addFormComponent={ AddSubscriberForm }
                            updateFormComponent={SubscriberForm}
                        />

                    </Fragment>

                )}
            </div>
        </Dialog>
    );
};
