import {InputAdornment} from "@mui/material";
import {useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Field, FieldValidatorType} from "@progress/kendo-react-form";
import {ValidatedInput} from "../common/ValidatedFields";
import {requireValidator} from "../../utils/validators/requireValidator";
import BaseIconButton from "components/common/BaseIconButton";

export interface PasswordInputFieldProps {
  name: string;
  label: string;
  validators?: FieldValidatorType | FieldValidatorType[] | undefined;
}
function PasswordInputField(props: PasswordInputFieldProps) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const inputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <BaseIconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </BaseIconButton>
      </InputAdornment>
    ),
  };
  return (
    <Field
      type={showPassword ? "text" : "password"}
      InputProps={inputProps}
      name={props.name}
      required
      component={ValidatedInput}
      label={props.label}
      validator={
        props.validators === undefined ? [requireValidator] : props.validators
      }
    />
  );
}

export default PasswordInputField;
