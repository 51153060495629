import { IExperienceAvailabilityRange } from "./IExperienceAvailabilityRange";

export const   AVA_ALWAYS = 'default';
export const   AVA_NEVER  = 'never';
export const   AVA_CUSTOM = 'custom';
export const   AVA_NEW = 'new';
export const AvailabilityTypes = [
    {key: AVA_ALWAYS, label: 'Standard'},
    {key: AVA_CUSTOM, label: 'Personalizzata'},
]

export const AvailabilityTypesDefault = [
    {key: AVA_ALWAYS, label: 'Limitazioni orari di Default'},
    {key: AVA_CUSTOM, label: 'Limitazioni orari Personalizzata'},
]
export interface stdAvaType {
    key: string | number ;
    label: string;
}

export interface IExperienceAvailability {
    type: string;
    ranges: IExperienceAvailabilityRange[];
}
