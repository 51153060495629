import React, {FC} from "react";
import CenteredLoadingSpinner from "../common/CenteredLoadingSpinner";
import StaffTimetable from "./StaffTimetable";
import {useStaffCurrentWeek,} from "../../utils/hooks/currentWeek";
import {useCurrentStaff} from "../../utils/hooks/currentStaff";
import {IWeeklyTimetableDay} from "../../models/entities/IWeeklyTimetableDay";
import {dateToStringYYYYMMDD} from "../../utils/dateHelper";
import {useGetStaffWeeklyTimetableQuery} from "store/api/staffTimetables";
import {skipToken} from "@reduxjs/toolkit/query/react";

const StaffTimetableForm : FC<{
  dayClicked?: Date;
  closeDialog?: (e: any)=> void;
  currentStaffFromParent?: any;
  currentPlannerFromParent?: any;
  currentWeekFromParent?: any;
}> = ({ dayClicked,closeDialog }) => {

  const currentStaff    =  useCurrentStaff();
  // const currentPlanner  = useCurrentPlanner();
  const currentWeek     = useStaffCurrentWeek();

  const {
    data: currentStaffWeeklyTimetable = [],
    isLoading: isLoadingCurrentStaffWeeklyTimetable,
    isFetching: isFetchingCurrentStaffWeeklyTimetable
  } = useGetStaffWeeklyTimetableQuery(
      {
    staffId: currentStaff?.id as number ,
    firstWeekDay: currentWeek?.firstDayWeek as string ,
  } ?? skipToken
  // , { skip: !currentStaff || !currentWeek}
  );


  const isLoadingOrFetching =
      // isLoadingCurrentPlannerWeeklyTimetable ||
      isLoadingCurrentStaffWeeklyTimetable
      // || isFetchingCurrentPlannerWeeklyTimetable
      || isFetchingCurrentStaffWeeklyTimetable;



  //todo: gestione errori o variabili undefined
  // let weekClosed;
  // if(currentStaffWeeklyTimetable !== undefined) {
  let weekClosed = ( currentStaff !== undefined  && currentWeek !== undefined)?
      (currentStaffWeeklyTimetable as IWeeklyTimetableDay[]).map(
    (item, index) => {
      return {
        date: item.date,
        date_string: item.date_string,
        day_name: item.day_name,
        ranges: [],
        closed: true,
      } as IWeeklyTimetableDay;
    }
  ): undefined;

  let dayNameClicked;
  let onlyOneDay = ( currentStaff !== undefined  && currentWeek !== undefined)?
      (currentStaffWeeklyTimetable as IWeeklyTimetableDay[]).map(
      (item, index) => {
        if(dayClicked !== undefined && item.date === dateToStringYYYYMMDD(dayClicked)) {
          dayNameClicked =item.day_name;
          return {
            date: item.date,
            date_string: item.date_string,
            day_name: item.day_name,
            ranges: [],
            closed: item.closed,
          } as IWeeklyTimetableDay;
        }else {
          return {} as IWeeklyTimetableDay;
        }
      }
  ) : undefined;




  return isLoadingOrFetching && currentStaff !== undefined  && currentWeek !== undefined? (
    <CenteredLoadingSpinner></CenteredLoadingSpinner>
  ) : (
    <StaffTimetable
        dayClicked={dayClicked}
        closeDialog={(e)=> (closeDialog)? closeDialog(e) : undefined}
        dayNameClicked={dayNameClicked}
      timetable={
        (dayClicked!==undefined && onlyOneDay!== undefined )? onlyOneDay
            :(
        currentWeek?.isEmpty === 1
          ? weekClosed
          : currentWeek?.usePlanner === 1
            ? (currentStaffWeeklyTimetable as IWeeklyTimetableDay[])
            : (currentStaffWeeklyTimetable as IWeeklyTimetableDay[])
        )
      }
    />
  );
};

export default StaffTimetableForm;
