import {Dialog} from "@progress/kendo-react-dialogs";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import {ISubscriber} from "../../models/entities/calendar/ISubscriber";
import Grid from "@mui/material/Grid";
import {emailValidator} from "../../utils/validators/emailValidator";
import CustomTitleBar from "../common/CustomTitleBar";
import BaseButton from "components/common/BaseButton";
import {Box, Divider, TextField, Tooltip, Typography,} from "@mui/material";
import React, {Fragment, useEffect, useState} from "react";
import {useCurrentAppointment} from "../../utils/hooks/currentAppointment";
import {useGetExperienceQuery} from "../../store/api/experiences";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {useUpdateSubscriberMutation,} from "store/api/subscribers";
import {useCurrentSubscriber} from "utils/hooks/currentSubscriber";
import CenteredLoadingSpinner from "../common/CenteredLoadingSpinner";
import {round} from "lodash";
import InfoIcon from "@mui/icons-material/Info";
import {ValidatedEmail, ValidatedInput, ValidatedMobile} from "../common/ValidatedFields";
import {requireValidator} from "../../utils/validators/requireValidator";
import {phoneNumberValidator} from "../../utils/validators/phoneNumberValidator";
import {Error} from "@progress/kendo-react-labels";
import {NazionalitaDropdown} from "../common/NazionalitaDropdown";
import {useAppSelector} from "../../store";
import SaveIcon from "@mui/icons-material/Save";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation"
import {Field, Form, FormElement} from "@progress/kendo-react-form";
import BaseIconButton from "../common/BaseIconButton";
import MessageIcon from "@mui/icons-material/Message";
import {Chat, ChatMessageSendEvent, Message} from "@progress/kendo-react-conversational-ui";
import {apiPath} from "../../store/api/api";
import {useSendMessageMutation} from "../../store/api/notifications";
import female from "../../img/Icons/female-avatar-profile-icon.png";
import male from "../../img/Icons/male-avatar-profile-icon.jpg";
import genderN from "../../img/Icons/gender-neutral-icon.jpg";
import {useMeInfo} from "../../utils/hooks/me";

const MAX_CHAR_MEDIUM = 70;

const FEMALE_ICON =female;
const MALE_ICON = male;
const GENDER_NEUTRAL_ICON = genderN;




const SubscriberForm = (props: EditFormProps<ISubscriber>) => {
  const { currentAppointment  } = useCurrentAppointment();
  const { data: experience} = useGetExperienceQuery(
    currentAppointment?.experience_id ?? skipToken
  );
  const {  countries } = useAppSelector((store) => store.types);

  const [formSubscriber, setFormSubscriber] = useState<ISubscriber>(props.item);

  const [countryValue, setCountryValue] = useState(props.item.country);

  const [updateSubscriber, { isLoading: isUpdating }] =
    useUpdateSubscriberMutation();


  const {isLoadingCurrentSubscriber } =
    useCurrentSubscriber();


  const [, setIsPhoneOrEmail] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);

  const [phoneValid, setPhoneValid] = useState<boolean>(false);
  const [mailValid, setMailValid] = useState<boolean>(false);


  const handleChangePhone = (e : any) =>{
    if (e.target.value !==""){
      setPhoneValid(true);
      setIsPhoneOrEmail(mailValid || true);
    }else{
      setPhoneValid(false);
      setIsPhoneOrEmail(mailValid || false);
    }
  }
  const handleChangeMail = (e : any) =>{
    if (e.target.value !== ""){
      setMailValid(true);
      setIsPhoneOrEmail(phoneValid || true);
    }else{
      setMailValid(false);
      setIsPhoneOrEmail(phoneValid || false);
    }
  }


  const [isFromPlatform, setIsFromPlatform] = useState(false);

  useEffect(() => {
    setFormSubscriber(props.item);
    if(props.item.from_platform !== undefined) {
      setIsFromPlatform(props.item.from_platform);
    }else{
      setIsFromPlatform(false);
    }

    if(currentAppointment?.subscribers!==undefined) {

      if(props.item.country !== currentAppointment?.subscribers.find((i : any) => i.id === props.item.id)?.country){
        setCountryValue(props.item.country)
      }else {
        setCountryValue(currentAppointment?.subscribers.find((i: any) => i.id === props.item.id)?.country);
      }


      setFormSubscriber({
        ...props.item,
        country: currentAppointment?.subscribers.find((i : any) => i.id === props.item.id)?.country,
      });
    }

// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item]);

  const checkPhoneOrMail = (event: ISubscriber) =>{

    if( (event.phone !== undefined && event.phone !== "" && event.phone !== null) || (event.email !== undefined && event.email !== "" && event.email !== null))
    {
      setIsPhoneOrEmail(true);
      return true;
    }else{
      setIsPhoneOrEmail(false);
      return false;
    }
  }

  const [ showErrorPart, setShowErrorPart] = useState<boolean>(false);
  const handleSubmit = (event: ISubscriber) => {

    let total =
        event.prices
            ?.map((pr) => pr.subscribe_number)
            .reduce((acc, curr) => acc + curr) || 0;

    let checkedPhoneOrMail = checkPhoneOrMail(event);
    if(( checkedPhoneOrMail) && total!==0) {
      setShowErrorPart(false)
      setShowError(false);
      setCountryValue(event.country);
      event.total_number=totalSubscribers;
      event.total_discount=appliedDiscount;
      event.total_price=totalPrice;
      updateSubscriber({ id: event.id as number, subscriber: event });
      props.onSubmit(event);
    }else{
      if(total===0){
        setShowErrorPart(true);
      }else {
        setShowError(true);
      }
    }


  };

  const totalSubscribers =
    formSubscriber.prices
      ?.map((pr) => pr.subscribe_number)
      .reduce((acc, curr) => acc + curr) || 0;

  const totalPrice = props.item.total_price;
  const totalDiscount = props.item.total_discount;
    // formSubscriber.prices
    //   ?.map((pr) => {
    //
    //     return pr.discount ? pr.subscribe_number * Math.round(pr.price) * pr.discount
    //         :
    //         pr.subscribe_number * Math.round(pr.price)
    //   })
    //   .reduce((acc, curr) => acc + curr) || 0;

  const currentDayIndex =
    new Date(currentAppointment?.start as string).getDay() === 0
      ? 7
      : new Date(currentAppointment?.start as string).getDay();

  const dayDiscount =
    // @ts-ignore
    (experience && experience[`discount_day${currentDayIndex}`]) || 0;

  const groupDiscount =
    experience?.group_discount_number &&
      totalSubscribers >= experience?.group_discount_number
      ? experience?.group_discount
      : 0;


  const appliedDiscount = (1 - dayDiscount / 100) * (1 - groupDiscount / 100);
  // const regex = new RegExp(/^\+?[\d \-\]]+$/);


  const valueRender = (
      element: React.ReactElement<HTMLSpanElement>,
      value: any
  ) => {

    let countryVal = (countryValue)? countryValue : props.item.country;
    const country = countries.find((i : any) => i.key === countryVal);

    const children = [

      <span >{country?.label}</span>
    ];


    return React.cloneElement(element, { ...element.props }, children);
  };
 let [totalSubbedNumber, setTotalSubbedNumber] = useState(0);
  useEffect(()=>{
    if(currentAppointment){
      let totale = 0;
      if( currentAppointment.subscribers !== undefined ) {
        currentAppointment.subscribers.forEach((item: ISubscriber) => {
          totale = totale + item.total_number;

        });
      }
      setTotalSubbedNumber(totale);

    }
  },[currentAppointment]);
  /// codice per messaggi


  const userInfo = useMeInfo();
  const token = localStorage.getItem("token");
  const get_path = apiPath + "notification/getMessagesByID/";

  const [
    sendMessage,
  ] = useSendMessageMutation();



  const header_get =  {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }

  const user = {
    // id: 1
    id: userInfo?.user?.id,
    // userInfo?.user?.id, // SOSTITUIRE CON QUESTO QUANDO LE API FUNZIONERANNO.

  };

  const [selectedChatRow, setSelectedChatRow] = useState<string>("0");
  const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(false);
  const [isOpenMessageForm, setIsOpenMessageForm] = useState<boolean>(false);

  const [titleChat, setTitleChat] = useState<string>("Esperienza");
  const bot = { id: 0 , avatarUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d7/Android_robot.svg/1200px-Android_robot.svg.png"};

  const initialMessages: Message[] = [
    {
      author: bot,
      suggestedActions: [
        {
          type: "reply",
          value: "Neat!",
        },
      ],
      timestamp: new Date(),
      text: "Hello, this is a demo bot. I don't do much, but I can count symbols!",
    },
    {
      author: bot,
      suggestedActions: [
        {
          type: "reply",
          value: "Neat!",
        },
      ],
      timestamp: (new Date() ),
      text: "Hello, this is a demo bot. I don't do much, but I can count symbols!",
    },
    {
      author: bot,
      suggestedActions: [
        {
          type: "reply",
          value: "Neat!",
        },
      ],
      timestamp: new Date(),
      text: "Hello, this is a demo bot. I don't do much, but I can count symbols!",
    },
  ];

  const [messages, setMessages] = React.useState(initialMessages);

  const CustomTitleMob  =

              <Typography  variant="h6" title={titleChat }  style={{paddingLeft: "15px", margin: "auto", marginLeft: "0px"}}>

                {(titleChat.length >= MAX_CHAR_MEDIUM) ? titleChat.substring(0, MAX_CHAR_MEDIUM) + "..." : titleChat}

              </Typography>

  const addNewMessage = (event: ChatMessageSendEvent) => {

    setMessages([...messages, event.message]);

    sendMessage({
      chatId:         selectedChatRow,
      message: {
        authorID:     (  userInfo?.user?.id !== undefined) ? userInfo?.user?.id.toString() : "",
        receiverID:   (  selectedChatRow !== undefined) ? selectedChatRow : "",
        text:         (  event.message.text !== undefined) ? event.message.text : "",
        timestamp:    (  event.message.timestamp !== undefined) ? event.message.timestamp.toString() : "",
      }
    });

  };

  const openMessageForm = (id : any) => {
    setSelectedChatRow(id);
    setIsOpenMessageForm(true);
    setIsLoadingMessages(true);

    if(currentAppointment && currentAppointment.experience_name) {
      setTitleChat(currentAppointment.experience_name);
    }

    let optObjHeadBody = {
      method: 'GET',
      headers:header_get,
    };
    fetch(get_path +id, optObjHeadBody)
        .then(res => res.json())
        .then(
            (result) => {
              if(result.bResult) {
                result.aMessages.forEach((item: Message) => {
                  if( parseInt(item.author.id) === user.id) {
                    item.author = user;

                  }else {

                    if (item.author.avatarUrl === "Female") {
                      item.author.avatarUrl = FEMALE_ICON;
                    } else {
                      if (item.author.avatarUrl === "Male") {
                        item.author.avatarUrl = MALE_ICON;
                      } else {
                        item.author.avatarUrl = GENDER_NEUTRAL_ICON;
                      }
                    }
                  }
                  if (item.timestamp !== undefined) {
                    item.timestamp = new Date(item.timestamp.toString());
                  }

                })
                setMessages(result.aMessages as Message[]);
              }else{

              }

              setIsLoadingMessages(false);
            },
            // Nota: è importante gestire gli errori qui
            // invece di un blocco catch() in modo da non fare passare
            // eccezioni da bug reali nei componenti.
            (error) => {
              // setIsLoaded(true);
              // setError(error);
              setIsLoadingMessages(false);
            }
        )


  }

  const customMessage = (props : any) => {
    return (
        <React.Fragment>
        <textarea
            style={{ resize: "none" }}
            ref={props.messageInput.ref}
            className={props.messageInput.props.className + " text-area-message-chat"}
        />
          {props.sendButton}
        </React.Fragment>
    );
  };

  // fine codice per messaggi
  return (
    <Dialog
      title={
        <>
          <CustomTitleBar
            type={"Iscritto"}
            label={!props.item.id ? "nuovo" : " " +props.item.name}
            icon="people"
            prenoNumb={ props.item.prenotation }
          />
          <BaseIconButton
              className={"custom-unif-viola start-message-icon-title"}
              onClick={() =>
                  openMessageForm(props.item.id)
              }
          >
            <MessageIcon fontSize="medium" />
          </BaseIconButton>
		</>
      }
      className={"dialog-sub"}
      onClose={props.cancelEdit}
    >
      {isLoadingCurrentSubscriber ? (
        <CenteredLoadingSpinner></CenteredLoadingSpinner>
      ) : (
        <Form
            initialValues={props.item}
          onSubmit={(e) => {
            handleSubmit(formSubscriber);
          }}
          render={(formRenderProps) => (
              <FormElement>

                {/*<legend*/}
                {/*    className={"k-form-legend"}*/}
                {/*    style={{ backgroundColor: "black" }}*/}
                {/*></legend>*/}
                <Grid container alignItems="stretch" spacing={3}>


                  <Grid item xs={12} sm={12} md={6} lg={ ( isFromPlatform) ? 6: 3} className={"rows-grandi"}>

                    <Field
                        required
                        name={"name"}
                        value={props.item?.name}
                        component={ValidatedInput}
                        label={"Nome e cognome"}
                        onChange={(event) => {
                          setFormSubscriber({
                            ...formSubscriber,
                            name: event.target.value,
                          });
                        }}
                        disabled={isFromPlatform}
                        validator={[requireValidator]}
                    >
                    </Field>
                  </Grid>
                  { ( !isFromPlatform) ?<>
                        <Grid item xs={12} sm={12} md={6} lg={3} className={"rows-grandi"}>
                          <Field
                              name={"phone"}
                              component={ValidatedMobile}
                              label={"Telefono"}
                              value={props.item?.phone}
                              onChange={(event) => {
                                handleChangePhone(event);
                                setShowError(false);
                                setFormSubscriber({
                                  ...formSubscriber,
                                  phone: event.target.value,
                                });
                              }}
                              validator={[ phoneNumberValidator]}
                          >
                          </Field>
                          {showError && <Error>{"E' necessario inserire il telefono o la mail"}</Error>}

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} className={"rows-grandi"}>

                          <Field

                              name={"email"}
                              component={ValidatedEmail}
                              value={props.item?.email || ""}
                              label={"Email"}
                              onChange={(event) => {
                                handleChangeMail(event);
                                setShowError(false);
                                setFormSubscriber({
                                  ...formSubscriber,
                                  email: event.target.value,
                                });
                              }}
                              validator={[ emailValidator]}
                          >
                          </Field>
                          {showError && <Error>{"E' necessario inserire il telefono o la mail"}</Error>}
                        </Grid>
                      </>
                      : ""
                  }
                  <Grid item xs={12} sm={12} md={6} lg={ ( isFromPlatform) ? 6: 3} >
                    <Field
                        name={"country"}
                        label={"Nazionalità"}
                        data={countries}
                        value={countries.find((i : any) => i.key === props.item?.country) }
                        onChange={(e) => {
                          setCountryValue(e.value)
                          setFormSubscriber({
                            ...formSubscriber,
                            country: e.value,
                          });
                        }}
                        disabled={isFromPlatform}
                        valueRender={valueRender}
                        // itemRender={countriesItemRender}
                        component={NazionalitaDropdown}

                    />

                  </Grid>

                  {/*SPAZIO TRA RIGHE*/}
                  <Grid container item xs={12}></Grid>
                  <Grid container>
                    {formSubscriber?.prices?.map((p, i) => (
                        <Box
                            key={`p-${p.id}-${p.experience_id}`}
                            display={"flex"}
                            flexDirection={"row"}
                            paddingBottom={1}
                            style={{width: "100%"}}
                        >
                          {formSubscriber?.prices &&
                          formSubscriber?.prices[0]?.price_type ===
                          "partecipante" && (
                              <Grid item xs={12} sm={12} md={6} lg={3} >
                                <Typography
                                    className={"labelina-fasce-prezzo"}
                                    align={"right"}
                                    variant="h5"
                                >
                                  { p.price_label_type?.trim() + " (" + p.age_min  + " - " + p.age_max + " anni), "+  parseFloat(String(p.price *(p.discount ? p.discount : 1))).toFixed(2).replace(/[.,]00$/, "") + " €"}
                                </Typography>
                              </Grid>
                          )}
                          {(formSubscriber?.prices[0]?.price_type!== "partecipante") ?
                          <Grid item xs={12} sm={12} md={6} lg={3} >
                            <Typography
                                className={"labelina-fasce-prezzo"}
                                align={"right"}
                                variant="h5"
                            >
                              {
                                "Prezzo " +  parseFloat(String(p.price *(p.discount ? p.discount : 1))).toFixed(2).replace(/[.,]00$/, "") + " €"
                              }
                            </Typography>
                          </Grid>: ""}
                          <Grid item xs={12} sm={12} md={6} lg={3} style={{ margin: "10px" }}  className={"container-input-fasce-prezzo"}>
                            <TextField
                                fullWidth
                                disabled={isFromPlatform}
                                variant={"outlined"}
                                onWheel={event => { event.preventDefault(); }}
                                type={"number"}
                                inputProps={{
                                  min: 0,
                                }}
                                label={"Pax"}
                                value={p.subscribe_number}
                                onChange={(event) => {
                                  if(event.target.value) {
                                    setTotalSubbedNumber(totalSubbedNumber +
                                        (parseInt((event.target.value !== "" && event.target.value) ? event.target.value : "0") - p.subscribe_number)
                                    )
                                    setShowErrorPart(parseInt(event.target.value) === 0 &&
                                        (formSubscriber.prices
                                            ?.map((pr) => pr.subscribe_number)
                                            .reduce((acc, curr) => acc + curr) || 0) === 0
                                    );
                                    setFormSubscriber({
                                      ...formSubscriber,
                                      prices: formSubscriber.prices?.map((pr) => {
                                        if (p.price_id === pr.price_id) {
                                          return {
                                            ...pr,
                                            subscribe_number: parseInt(event.target.value),
                                          };
                                        }
                                        return pr;
                                      }),
                                    });
                                    formRenderProps.onChange("prices", {
                                      value: formSubscriber.prices?.map((pr) => {
                                        if (p.price_id === pr.price_id) {
                                          return {
                                            ...pr,
                                            subscribe_number: parseInt(event.target.value),
                                          };
                                        }
                                        return pr;
                                      })
                                    });
                                  }
                                }}
                            />
                            <TextField
                                style={{display: "none"}}
                                fullWidth
                                variant={"outlined"}
                                onWheel={event => { event.preventDefault(); }}
                                type={"number"}
                                inputProps={{
                                  min: 0,
                                }}
                                label={"si"}
                                value={props.item?.flag }

                            />
                          </Grid>

                        </Box>
                    ))}

                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Field
                        disabled={isFromPlatform}
                        name={"note"}
                        label={"Note relative all'iscrizione"}
                        value={props.item?.note}
                        onChange={(e) => {
                          setFormSubscriber({
                            ...formSubscriber,
                            note: e.target.value,
                          });
                        }}
                        component={ValidatedInput}
                        // value={appointment.location}

                        validator={[]}
                    />

                  </Grid>
                  <Divider />
                  <Grid

                      item xs={12}
                  >
                    <Box>
                      <Typography variant={"h6"}>
                        Totale partecipanti:
                        {" " + totalSubscribers}
                        <br></br>
                        {" Posti rimanenti: "+ ((currentAppointment?.experience_customers_max_number as number) - totalSubbedNumber  )}
                      </Typography>
                      <Typography variant={"h6"}>
                        Importo totale: €{" "}
                        {appliedDiscount !== 1 ? (
                            <Fragment>
                              <del>{totalPrice + totalDiscount}</del>
                              {" " + totalPrice.toFixed(2)}
                              <Tooltip style={{marginBottom: "-5px"}}
                                       className={'tooltip-prezzi'}
                                       title={
                                         <Fragment>
                                           <div>Sconti applicati:</div>
                                           <div>Sconto per giorno: {dayDiscount} %</div>
                                           <div>Sconto per gruppo: {groupDiscount} %</div>
                                         </Fragment>
                                       }
                              >
                                <InfoIcon />
                              </Tooltip>
                            </Fragment>
                        ) : (
                            <span>{" " + totalPrice.toFixed(2).replace(/[.,]00$/, "")}</span>
                        )}
                      </Typography>
                    </Box>
                    {/* <Box>
                      <Tooltip title={
                        <Fragment>
                          <div>Sconti applicati:</div>
                          <div>Sconto per giorno: {dayDiscount} %</div>
                          <div>Sconto per gruppo: {groupDiscount} %</div>
                        </Fragment>
                      }>
                        <InfoIcon />
                      </Tooltip>

                    </Box> */}
                  </Grid>
                </Grid>
                <div>  {showErrorPart && <Error>Inserire almeno un partecipante</Error>}</div>

                {/*SPAZIO TRA RIGHE*/}
                <Grid container item xs={12}></Grid>
                <Grid container justifyContent="center">
                  {isUpdating ? (
                      <CenteredLoadingSpinner />
                  ) : (
                      <Fragment>
                        <BaseButton
                            className={(!isFromPlatform)? "custom-unif-viola-back" : ""}
                            type={"submit"}
                            variant="contained"
                            startIcon={<SaveIcon />}
                            disabled={isFromPlatform}
                            // disabled={!isPhoneOrEmail}
                        >
                          Salva
                        </BaseButton>
                        {props.hasCancel && (
                            <BaseButton
                                className={(!isFromPlatform)? `custom-button-annulla custom-unif-grigio-back left-spacing-10p custom-unif-arancione-back` : " custom-button-annulla  left-spacing-10p custom-unif-grigio-back "}
                                startIcon={<CancelPresentationIcon />}
                                onClick={props.cancelEdit}
                                disabled={isFromPlatform}
                                // disabled={!isPhoneOrEmail}
                            >
                              Annulla</BaseButton>
                        )}
                      </Fragment>
                  )}
                  {/*{formRenderProps.visited &&*/}
                  {/*formRenderProps.errors &&*/}
                  {/*formRenderProps.errors.VALIDATION_SUMMARY && (*/}
                  {/*  <div className={"k-messagebox k-messagebox-error"}>*/}
                  {/*    <Error>{formRenderProps.errors.VALIDATION_SUMMARY}</Error>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </Grid>
                {(isOpenMessageForm)?
                <Dialog
                    className={"dialog-staff-timetable-form-media-rule subscriber-message-form"}
                    title={CustomTitleMob}
                    onClose={() => setIsOpenMessageForm(false)}
                >
                  <>
                    {(isLoadingMessages)?
                        <Grid container item >
                          <CenteredLoadingSpinner />
                        </Grid>
                        :

                            <Chat
                                user={user}
                                messages={messages}
                                onMessageSend={addNewMessage}
                                placeholder={"Scrivi un messaggio..."}
                                messageBox={customMessage}

                            />

                    }

                  </>

                </Dialog> :<></>
                }
              </FormElement>
          )}
        >

        </Form>
      )}
    </Dialog>
  );
};
export default SubscriberForm;
