import {configureStore} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import auth from "./auth";
import changePassword from "./changePassword";
import planners from "./planners";
import staff from "./staff";
import calendar from "./calendar";
import users from "./users";
import subscribers from "./subscribers";
import messageChat from "./messageChat"
import prices from "./prices";
import types from "./types";
import {setupListeners} from "@reduxjs/toolkit/query";
import {api} from "./api/api";
import experiences from "./experiences";
import notificationsMiddleware from "./middlewares/notificationsMiddleware";
import logoutMiddleware from "./middlewares/logoutMiddleware";
import registration from "./registration";
import detailAppointment from "./detailAppointment";
import schedulerView from "./schedulerView";
import loaderInsurance from "./loaderInsurance";

export const store = configureStore({
  reducer: {
    auth,
    changePassword,
    planners,
    registration,
    // equipments,
    // locations,
    [api.reducerPath]: api.reducer,
    staff,
    calendar,
    schedulerView,
    loaderInsurance,
    experiences,
    subscribers,
    users,
    prices,
    types,
    messageChat,
    detailAppointment,


  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      api.middleware,
      notificationsMiddleware,
      logoutMiddleware,
    ]),
  // middleware: [
  //   ...(getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
  //   notificationsMiddleware,
  // ],
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
