import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {authApi} from "./api/auth";

interface registrationState {
  // dayStartTime: string;
  // dayEndTime: string;
  step: number;
  uid?: number;
  checkcode?: string;
  email?: string;
  username?: string;
}

const initialState: registrationState = {
  step: 0,
  uid: undefined,
  checkcode: undefined,
  email: undefined,
  username: undefined,
};

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setStep(state, action: PayloadAction<number>) {
      if (action.payload === 0 || action.payload === 1)
        state.step = action.payload;
    },
    setUid(state, action: PayloadAction<number | undefined>) {
      state.uid = action.payload;
    },
    setCheckcode(state, action: PayloadAction<string | undefined>) {
      state.checkcode = action.payload;
    },
    setEmail(state, action: PayloadAction<string | undefined>) {
      state.email = action.payload;
    },
    setUsername(state, action: PayloadAction<string | undefined>) {
      state.username = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.userCheckRegistration.matchFulfilled,
      (state, action) => {
        state.email = action.payload.email;
        state.username = action.payload.username;
      }
    ).addMatcher(
      authApi.endpoints.userCheckRecoveryLink.matchFulfilled,
      (state, action) => {
        state.email = action.payload.email;
        state.username = action.payload.username;
      }
    );
  },
});

export const { setStep, setUid, setCheckcode, setEmail, setUsername } =
  registrationSlice.actions;

export default registrationSlice.reducer;
