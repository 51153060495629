import React, {useState} from "react";
import {Theme} from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from "@mui/material/FormControl";
import {useAppDispatch} from "../../store";
import {selectPlanner} from "../../store/planners";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import {useGetPlannersQuery} from "store/api/planners";
import {useMe} from "utils/hooks/me";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {Grid, GridCellProps, GridDataStateChangeEvent} from "@progress/kendo-react-grid";
import BaseIconButton from "../common/BaseIconButton";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import CommandTable from "../common/CommandTable";
import {process, SortDescriptor, State} from "@progress/kendo-data-query";
import LoginIcon from "@mui/icons-material/Input";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      color: "white",
      minWidth: 150,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    // colorStyle: {
    //   [theme.breakpoints.up("sm")]: {
    //     color: "white",
    //     borderColor: "white",
    //   },
    // },
  })
);
const SelectPlanner = () => {


  const initialSort: Array<SortDescriptor> = [{ field: "name", dir: "asc" }];

  const initialDataState: State = {
    sort: [{ field: "name", dir: "asc" }],
  };
  const [dataState, setDataState] = React.useState<State>(initialDataState);

  const classes = useStyles();
  const loggedUser = useMe();

  const dispatch = useAppDispatch();

  const currentPlanner = useCurrentPlanner();
  const {
    data: planners = [],
  } = useGetPlannersQuery(!loggedUser ? skipToken : (undefined as void));


    const [skip,] = React.useState<number>(0);

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      color="secondary"
      style={{ width: "96%" }}
    >
      <Grid
          total={planners.filter(item=> item.id !== currentPlanner?.id).length}
          skip={skip}
          pageSize={4}
          sortable={true}
          style={{ height: "200px", width: "400px" }}
          rowHeight={10}
          scrollable={"none"}
          data={process(planners.filter(item=> item.id !== currentPlanner?.id),dataState)}
          className="custom-unif-font-bold agende-table"
          {...dataState}
          onDataStateChange={(e: GridDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}

      >
        <Column
            field="name"
            title="Agenda"
        />

        <Column
            width="100px"
            cell={(props: GridCellProps) => (

                <td>
                  <BaseIconButton color="primary"
                                  className={"custom-unif-viola "} onClick={(e: any) => dispatch(selectPlanner(props.dataItem.id))}>

                      <LoginIcon />
                  </BaseIconButton>
                </td>
            )}
        />
      </Grid>
    </FormControl>
  );
};

export default SelectPlanner;
