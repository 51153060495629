import {Dialog} from "@progress/kendo-react-dialogs";
import {Field, Form, FormElement} from "@progress/kendo-react-form";

import {Error} from "@progress/kendo-react-labels";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import Grid from "@mui/material/Grid";
import {ValidatedInput, ValidatedSwitch,} from "../common/ValidatedFields";
import {requireValidator} from "../../utils/validators/requireValidator";
import CustomTitleBar from "../common/CustomTitleBar";
import BaseButton from "components/common/BaseButton";
import {useAppSelector} from "store";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import React, {Fragment, useEffect, useState} from "react";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SaveIcon from "@mui/icons-material/Save";
import {IGuest} from "../../models/entities/IGuest";
import {
    useAddGuestMutation,
    useGetGuestQuery,
    useInviteGuestMutation,
    useSetLinkCopiedMutation,
    useUpdateGuestMutation
} from "../../store/api/guests";
import CustomDatePicker from "../common/CustomDatePicker";
import {emailValidator} from "../../utils/validators/emailValidator";
import {useMe} from "../../utils/hooks/me";
import {adjustTimezone, compareDate, formatDateNoTimeForApi, toDateNoTimeFromApi} from "../../utils/dateHelper";
import {skipToken} from "@reduxjs/toolkit/dist/query/react";
import {NazionalitaDropdown} from "../common/NazionalitaDropdown";
import DeleteDialog from "../common/DeleteDialog";
import {useGetResellerQuery} from "../../store/api/resellers";
import toast from "react-hot-toast";
import {DatePickerChangeEvent} from "@progress/kendo-react-dateinputs/dist/npm/datepicker/DatePicker";
import {CircularProgress} from "@mui/material";

const erroreDataCheckoutpassata ="Attenzione! non puoi inserire una data di check-out passata";
const erroreAttivaCheckoutPassato ="Attenzione! non puoi attivare un ospite che ha data di check-out passata";
// const erroreLinkCheckoutPassato ="Attenzione! non puoi inviare il link ad un ospite che ha data di check-out passata";



const GuestsForm = (props: EditFormProps<IGuest>) => {

    const user = useMe();
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
    const [isNew,setIsNew] = useState<boolean>(false);
    const [startRange, setStartRange] = useState<Date>();
    const [endRange, setEndRange]     = useState<Date>();
    const [rangeValid, setRangeValid] = useState<boolean>(false);
    const [nationality, setSetNationality] = useState<string>('-');
    const [isError, setIsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [addGuest, { isLoading: isAdding, isSuccess, data: returnedData }] = useAddGuestMutation();

    const {
        data: reseller,
    } = useGetResellerQuery(user?.companyId ?? skipToken);
    const [updateGuest, { isLoading: isUpdating, isSuccess: isSuccessUpdate }] = useUpdateGuestMutation();
    const [copyLink, { isLoading: isCopyingLink, isSuccess: isSuccessCopy }] = useSetLinkCopiedMutation();


    const [inviteGuest, { isLoading: isSendingLink}] = useInviteGuestMutation();
    const {  countries } = useAppSelector((store) => store.types);
    const {
        data: getGuestData,
        isLoading : isLoadingGetGuest,
        isFetching : isFetchingGetGuest,
        refetch
    } = useGetGuestQuery(isNew ? skipToken : (returnedData?.id ?? props.item.id ?? skipToken));
    const [, setIsUrlEnabled] = useState<boolean>(getGuestData?.url_active ?? false);

    const unsecuredCopyToClipboard = (text : string) => {
        const textArea = document.createElement("textarea");
        textArea.value=text;
        document.body.appendChild(textArea);
        textArea.focus();textArea.select();
        try{document.execCommand('copy')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};

    useEffect(()=>{
        if(isSuccess) {
            setIsNew(false);
        }
    },[isSuccess])



    useEffect(()=>{
        // setPaid(getGuestData?.paid  ?? false)
        if(getGuestData && getGuestData.checkout && getGuestData.checkin) {
            let endR  = toDateNoTimeFromApi(getGuestData.checkout as string);
            let startR = toDateNoTimeFromApi(getGuestData.checkin as string);
            setEndRange( endR  );
            setStartRange( startR);
        }
    },[getGuestData])

    useEffect(()=>{
        if(isSuccessUpdate){
            refetch()
        }
    },[isSuccessUpdate])



    useEffect(()=>{
        setIsError(false);
        if(Object.keys(props.item).length){
            setIsNew(false) // vuol dire che non è vuoto
            // refetch()
        }else{
            setIsNew(true)

            setStartRange(undefined);
            setEndRange(undefined);
        }
    },[props.item])


    const handleSubmit = (event: any) => {
        let isErrorInside = false;

        let comparingDate = new Date();
        comparingDate.setUTCHours(endRange?.getUTCHours() ?? 12,0,0,0);
        if(isNew){
            if(endRange && compareDate(endRange ,comparingDate) >=0) {
                setIsError(true);
                isErrorInside = true;
                setErrorMessage(erroreDataCheckoutpassata)
            }else{
                setIsError(false);
                isErrorInside = false;
            }
        }else if (
            endRange && (compareDate(endRange,comparingDate) >=0) &&
            getGuestData?.url_active !== event.url_active &&
            event.url_active

        ){
            setIsError(true);
            isErrorInside=true;
            setErrorMessage(erroreAttivaCheckoutPassato)
        }
        else{
            setIsError(false)
            isErrorInside=false;
        }

        if(!isErrorInside && startRange && endRange) {
            // const defaultplafond = reseller?.plafond;
            // event.plafond = event.plafond ?? defaultplafond; // che è il default
            event.checkin = formatDateNoTimeForApi(startRange);
            event.checkout =   formatDateNoTimeForApi(endRange);
            // let startR = formatDateForApi(startRange as Date);
            // let startR =formatDateForApi(endRange as Date),
            if (!event.id) {
                addGuest({
                    email: event.email,
                    name: event.name,
                    surname: event.surname,
                    nationality: nationality,
                    checkin: event.checkin,
                    checkout: event.checkout,
                    url_active: true,
                    is_link_copied: null,
                    is_link_sent: null,
                copying_link: false,
                        sending_link: false,
                        booking_number: event.booking_number,
                    // plafond: event.plafond,
                    reference: event.reference,
                    // paid: false,
                    company_id: user?.companyId,
                } as IGuest
                );
                // setIsNew(false);
            } else {

                updateGuest({
                    id: event.id,
                    Guest: {
                        company_id: user?.companyId,
                        name: event.name,
                        email: event.email,
                        nationality: nationality,
                        surname: event.surname,
                        checkin: event.checkin,
                        checkout: event.checkout,
                        url_active: event.url_active,
                        booking_number: event.booking_number,
                        // plafond: event.plafond,
                        copying_link: false,
                        sending_link: false,
                        reference: event.reference,
                        // paid: event.paid,
                    } as IGuest,
                });
            }
            props.onSubmit(event);
            // props.cancelEdit();
        }
    };

    const getToday = () : Date => {
        let todayWithTZ = new Date();
        let today= adjustTimezone(todayWithTZ);
        return today;
    }
    const setLinkCopied = () =>{
        if(getGuestData && getGuestData.id) {
            copyLink({
                id: getGuestData.id,
                Guest: {
                    company_id: user?.companyId,
                    name: getGuestData.name,
                    email: getGuestData.email,
                    nationality: nationality,
                    surname: getGuestData.surname,
                    checkin: getGuestData.checkin,
                    checkout: getGuestData.checkout,
                    url_active: getGuestData.url_active,
                    booking_number: getGuestData.booking_number,
                    // plafond: event.plafond,
                    reference: getGuestData.reference,
                    copying_link:  true,
                    // paid: event.paid,
                } as IGuest,
            });
        }
    }
    useEffect(()=>{
        if(isSuccess){

            toast.success("Link copiato negli appunti!");
        }
    },[isSuccessCopy,isCopyingLink])


    const handleChangeStart = ( start : DatePickerChangeEvent) =>{
        if(start.value) {
            if (endRange !== undefined) {
                setRangeValid(!((start.value) < (endRange)))
            } else {
                setRangeValid(false)
            }
            let startR = adjustTimezone(start.value);
            // start.value.setUTCHours(12,0,0,0)
            setStartRange(startR);
        }
    }
    const handleChangeEnd = ( end : DatePickerChangeEvent) =>{
        if(end.value) {
            setEndRange(end.value);
            if (startRange !== undefined) {
                setRangeValid(!((end.value) > (startRange)))
            } else {
                setRangeValid(false)
            }

            let endR = adjustTimezone( end.value);

            setEndRange(endR);
            let comparingDate = new Date();
            comparingDate.setUTCHours(12,0,0,0);
            if (end.value && (compareDate(end.value, comparingDate) >= 0)) {
                setIsError(false);
            }
        }
    }



    return (
        <Dialog
            className={`users-form-media-rule guest-form ${
                isNew
                    ? "edit"
                    : ""
            }`}
            title={
                <CustomTitleBar
                    type={isNew ? "" : "Modifica Ospite"}
                    label={ isNew ? "Nuovo Ospite" : ( getGuestData ? (getGuestData?.name +" "+getGuestData?.surname) : "")}
                    icon="people"
                />
            }
            onClose={props.cancelEdit}
        >
            {isLoadingGetGuest || isFetchingGetGuest ?
                (
                    <CenteredLoadingSpinner />)
                : (
                     <Form
                         key={JSON.stringify(getGuestData)}
                        onSubmit={handleSubmit}
                        initialValues={isNew? props.item : getGuestData}
                        render={(formRenderProps) => (
                            <FormElement >
                                <Grid container alignItems="stretch"  spacing={3}>
                                    <Grid item
                                          xs={12}
                                          sm={6}
                                          md={4}
                                          lg={4}
                                          className="item-media-rule rows-grandi">
                                        <Field
                                            value={getGuestData?.name}
                                            name={"name"}
                                            component={ValidatedInput}
                                            label={"Nome *"}
                                            validator={[requireValidator]}
                                        />
                                    </Grid>
                                    <Grid item
                                          xs={12}
                                          sm={6}
                                          md={4}
                                          lg={4} className="item-media-rule rows-grandi">
                                        <Field
                                            name={"surname"}
                                            component={ValidatedInput}
                                            label={"Cognome *"}
                                            validator={[requireValidator]}
                                        />
                                    </Grid>
                                    <Grid item xs={12}
                                          sm={6}
                                          md={4}
                                          lg={4} className="item-media-rule rows-grandi">
                                        <Field
                                            disabled={props.item.booking_number ? props.item.booking_number > 0 : false}
                                            name={"email"}
                                            component={ValidatedInput}
                                            label={"Email *"}
                                            validator={[requireValidator,emailValidator]}
                                        />
                                    </Grid>
                                    <Grid item xs={12}
                                          sm={6}
                                          md={4}
                                          lg={4} className="item-media-rule rows-grandi">

                                        <Field
                                            name={"nationality"}
                                            label={"Paese"}
                                            data={countries}
                                            value={nationality}
                                            onChange={(e) => {
                                                setSetNationality(e.value);
                                                formRenderProps.onChange("nationality", {
                                                    value: e.value,
                                                });

                                            }}
                                            // itemRender={countriesItemRender}
                                            component={NazionalitaDropdown}

                                        />
                                    </Grid>



                                    <Grid item xs={12} sm={12} md={4} lg={4} className="item-media-rule rows-grandi">
                                        <div className={ "std-date-picker-container dropdownModifica custom-unif-dropdown-focus"} >

                                            <label
                                                className="labelModifica std-date-picker-label"
                                                data-shrink="true"
                                            >
                                                Check-In *
                                            </label>
                                            <div className=" spanFiglia-daIngrandire std-date-picker-component">
                                                <CustomDatePicker
                                                    required
                                                    name={"checkin"}
                                                    value={startRange}
                                                    validator={[requireValidator]}
                                                    format="dd/MM/yyyy"
                                                    onChange={(e: DatePickerChangeEvent) => {
                                                        formRenderProps.onChange("checkin", {
                                                            value: startRange,
                                                        });
                                                        handleChangeStart(e);

                                                    }}

                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} className="item-media-rule rows-grandi">
                                        <div className={ "std-date-picker-container dropdownModifica custom-unif-dropdown-focus"} >

                                            <label
                                                className="labelModifica std-date-picker-label"
                                                data-shrink="true"
                                            >
                                                Check-Out *
                                            </label>
                                            <div className=" spanFiglia-daIngrandire std-date-picker-component">
                                                <CustomDatePicker
                                                    required
                                                    name={"checkout"}
                                                    validator={[requireValidator]}
                                                    value={endRange}
                                                    format="dd/MM/yyyy"
                                                    min={startRange}
                                                    onChange={(e: DatePickerChangeEvent) => {

                                                        formRenderProps.onChange("checkout", {
                                                            value: endRange,
                                                        });
                                                        handleChangeEnd(e);

                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {rangeValid && <Error>La data di check-out deve essere successiva alla data di check-in</Error>}
                                    </Grid>





                                    <Grid item xs={12}
                                          sm={6}
                                          md={4}
                                          lg={4} className="item-media-rule rows-grandi">
                                        <Field
                                            name={"reference"}
                                            component={ValidatedInput}
                                            label={"Riferimento interno"}
                                        />
                                    </Grid>
                                    {!isNew && <Grid item xs={12}
                                           sm={6}
                                           md={2}
                                           lg={2} className="item-media-rule rows-grandi flag-container">
                                        <Field

                                            name={"url_active"}
                                            component={ValidatedSwitch}
                                            label={"Attivo"}
                                            onChange={(val) => setIsUrlEnabled(val)}
                                            // disabled={paid}
                                            value={true}
                                            checked={true}
                                            defaultChecked={true}
                                        />
                                    </Grid>}


                                </Grid>
                                {/*SPAZIO TRA RIGHE*/}

                                <Grid container item xs={12} style={{textAlign:"center"}} className={"align-center-inside-dialog"}>
                                    {isError && <Error className={"big-error-message align-center-inside-dialog"}>{errorMessage}</Error>}
                                </Grid>
                                <Grid container item xs={12}></Grid>

                                <Grid
                                    container
                                    justifyContent="center"
                                    style={{ marginTop: "2vh" }}
                                >
                                    {isAdding || isUpdating ? (
                                        <CenteredLoadingSpinner />
                                    ) : (
                                        <Fragment>
                                            {!isNew &&


                                                <BaseButton
                                                    className={` top-space-mobile ${
                                                        getGuestData?.url_active ?? false
                                                        // && (compareDate((endRange as Date ),(new Date())) <=0)
                                                            ? "custom-unif-viola-back"
                                                            : ""
                                                    }`}
                                                    disabled={!(getGuestData?.url_active ?? false)
                                                        // || (compareDate((endRange as Date ),(new Date())) >0)
                                                }
                                                    variant="contained"
                                                    onClick={() => {
                                                        if(getGuestData?.url_generated) {
                                                            if (window.isSecureContext && navigator.clipboard) {
                                                                navigator.clipboard.writeText(getGuestData.url_generated);
                                                            } else {
                                                                unsecuredCopyToClipboard(getGuestData.url_generated);

                                                            }

                                                            setLinkCopied();
                                                        }
                                                    }}
                                                    startIcon={isCopyingLink?  <CircularProgress style={{ width:"20px", height: "20px", color: "white" }}/> :
                                                    <span className="k-icon k-i-save k-i-floppy"></span>}
                                                >
                                                    Copia link
                                                </BaseButton> }
                                            {!isNew &&
                                                <BaseButton
                                                    className={`left-spacing-10p top-space-mobile ${
                                                        getGuestData?.url_active ?? false
                                                        // && (compareDate((endRange as Date ),(new Date())) <=0)
                                                            ? "custom-unif-viola-back"
                                                            : ""
                                                    }`}
                                                    onClick={()=>{
                                                        setDeleteDialog(true);
                                                    }}
                                                    disabled={!(getGuestData?.url_active ?? false)
                                                        // || (compareDate((endRange as Date ),(new Date())) >0)
                                                }
                                                    variant="contained"
                                                    startIcon={isSendingLink?  <CircularProgress style={{ width:"20px", height: "20px", color: "white" }}/> :<span className="k-icon k-i-upload"></span>}
                                                >
                                                    Invia link
                                                </BaseButton>}
                                            <BaseButton
                                                className={`left-spacing-10p ${
                                                    formRenderProps.allowSubmit
                                                        ? "custom-unif-viola-back"
                                                        : ""
                                                }`}
                                                disabled={!formRenderProps.allowSubmit}
                                                type={"submit"}
                                                variant="contained"
                                                startIcon={<SaveIcon />}
                                            >
                                                Salva
                                            </BaseButton>
                                            {props.hasCancel && (
                                                <BaseButton
                                                    onClick={props.cancelEdit}
                                                    disabled={!formRenderProps.allowSubmit}
                                                    className={`custom-button-annulla custom-unif-grigio-back left-spacing-10p ${
                                                        formRenderProps.allowSubmit
                                                            ? "custom-unif-arancione-back"
                                                            : ""
                                                    }`}
                                                    startIcon={<CancelPresentationIcon />}
                                                >
                                                    Annulla
                                                </BaseButton>
                                            )}
                                        </Fragment>
                                    )}
                                    {formRenderProps.visited &&
                                        formRenderProps.errors &&
                                        formRenderProps.errors.VALIDATION_SUMMARY && (
                                            <div className={"k-messagebox k-messagebox-error"}>
                                                <Error>{formRenderProps.errors.VALIDATION_SUMMARY}</Error>
                                            </div>
                                        )}
                                    {deleteDialog && (
                                        <DeleteDialog
                                            titleDelete={"Conferma Invio"}
                                            textDelete={"Sei sicuro di voler inviare il link all'ospite"}
                                            toggleDialog={() => {
                                                setDeleteDialog(!deleteDialog);
                                            }}
                                            onConfirm={() => {

                                                    if( getGuestData && getGuestData?.id ) {
                                                        inviteGuest({id: getGuestData.id, date:  getToday()});
                                                    }
                                                    // setLinkSent();
                                            }}
                                        />
                                    )}
                                </Grid>
                            </FormElement>
                        )}
                    />
                )
            }

        </Dialog>
    );
};
export default GuestsForm;
