import {Dialog} from "@progress/kendo-react-dialogs";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import {IStaff} from "../../models/entities/IStaff";
import CustomTitleBar from "../common/CustomTitleBar";
import {Fragment, useEffect, useState} from "react";
import {Box} from "@mui/material";
import StaffForm from "./StaffForm";
import CustomTabs from "../common/CustomTabs";
import CustomTab from "../common/CustomTab";
import {Link, Route, Switch, useHistory, useLocation, useRouteMatch,} from "react-router-dom";
import {selectStaff} from "../../store/staff";
import {useAppDispatch} from "../../store";
import {useCurrentStaff} from "../../utils/hooks/currentStaff";
import WeeklyStaffTimetablePageBeta from "./WeeklyStaffTimetablePageBeta";
import {useGetCalendarDayQuery} from "../../store/api/calendar";
import {dateToStringYYYYMMDD} from "../../utils/dateHelper";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";

const TabsStaffForm = (props: EditFormProps<IStaff>) => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const dispatch = useAppDispatch();
  useHistory();
  const currentStaff = useCurrentStaff();

  useEffect(() => {
    if (props.item.id) {
      dispatch(selectStaff(props.item.id));
    } else if (!currentStaff) {
      dispatch(selectStaff(undefined));
    }
  }, [props.item]);

  const [date, ] = useState(new Date());
  const currentPlanner = useCurrentPlanner();
  const {
    refetch,
  } = useGetCalendarDayQuery(
      {
        planner_id: currentPlanner?.id as number,
        date: dateToStringYYYYMMDD(date),
      },
      {
        skip: !currentPlanner || !date,
      }
  );

  // const handleSubmit = (event: IStaff) => {
  //   if (!event.id) {
  //     // console.log("post ");
  //   } else {
  //     // console.log("patch ");
  //   }
  //   props.onSubmit(event);
  // };

  return (
    <Dialog
      height={"100vh"}
      width={"100vw"}
      className={"dialog-staff-form-media-rule"}
      title={
        <CustomTitleBar
          type={!props.item.id ? "" : "Modifica "}
          label={!props.item.id ? "Nuovo personale" : " " +props.item.initials}
          icon="people"
        />
      }
      onClose={() => {
        dispatch(selectStaff(undefined));
        refetch();
        props.cancelEdit();
      }}
    >
      <Fragment>
        <Box component={Box}>
          <CustomTabs
            variant="fullWidth"
            // className={{ indicator: classes.whiteColor}}
            indicatorColor="primary"
            textColor="primary"
            value={location.pathname}
          >
            <CustomTab
              component={Link}
              to={`${path}`}
              value={`${path}`}
              label="Dettaglio"
              // disabled={!props.item.id}
            />
            {/*<CustomTab*/}
            {/*    component={Link}*/}
            {/*    to={`${path}/custom-staff-weekly-timetable`}*/}
            {/*    value={`${path}/custom-staff-weekly-timetable`}*/}
            {/*    label="Orario di lavoro"*/}
            {/*    disabled={!props.item.id && !currentStaff?.id}*/}
            {/*/>*/}
            {/*NUOVO ORARIO DI LAVORO*/}
            <CustomTab
                component={Link}
                to={`${path}/custom-staff-weekly-timetable`}
                value={`${path}/custom-staff-weekly-timetable`}
                label="Orario di lavoro"
                disabled={!props.item.id && !currentStaff?.id}
            />
          </CustomTabs>
        </Box>
        <Switch>
          <Route
            path={`${path}`}
            exact
            component={() =>
              StaffForm({
                item: props.item as IStaff,
                cancelEdit: props.cancelEdit,
                onSubmit: props.onSubmit,
                hasCancel: props.hasCancel,
              })
            }
          />
          {/*<Route*/}
          {/*    path={`${path}/custom-staff-weekly-timetable`}*/}
          {/*    component={WeeklyStaffTimetablePage}*/}
          {/*/>*/}
          <Route
              path={`${path}/custom-staff-weekly-timetable`}
              component={WeeklyStaffTimetablePageBeta}
          />
        </Switch>
      </Fragment>
    </Dialog>
  );
};
export default TabsStaffForm;
