import {Dialog} from "@progress/kendo-react-dialogs";
import {Field, Form, FormElement} from "@progress/kendo-react-form";

import {Error} from "@progress/kendo-react-labels";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import {IUser} from "../../models/entities/IUser";
import Grid from "@mui/material/Grid";
import {ValidatedDropDownListId, ValidatedDropDownListKey, ValidatedInput,} from "../common/ValidatedFields";
import {requireValidator} from "../../utils/validators/requireValidator";
import CustomTitleBar from "../common/CustomTitleBar";
import BaseButton from "components/common/BaseButton";
import {useAppDispatch, useAppSelector} from "store";
import {useGetCompaniesQuery} from "store/api/companies";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import {useAddUserMutation, useUpdateUserMutation} from "store/api/users";
import {IUserViewModel} from "models/views/IUserViewModel";
import React, {Fragment, useEffect, useState} from "react";
import {useSelectUserMutation} from "../../store/api/auth";
import {api} from "../../store/api/api";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SaveIcon from "@mui/icons-material/Save";

const UserForm = (props: EditFormProps<IUser>) => {
  const { roles } = useAppSelector((store) => store.types);
  const [realRoles, ] = useState(roles.filter(function( obj ) {
      return obj.key !== 'superadmin';
  }));
   const dispatch = useAppDispatch()
  const [addUser, { isLoading: isAdding, isSuccess }] = useAddUserMutation();
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();
  const { data: companies = [], isFetching } = useGetCompaniesQuery();
  const [, {  isSuccess: isSelectSucceded }] = useSelectUserMutation()



  const [valueRole, setValueRole] = useState(props.item?.role);
  const [valueCompany,setValueCompany] = useState(props.item?.company);





  const handleSubmit = (event: any) => {
    if (!event.id) {
      addUser({
        email: event.email,
        role: event.role.key,
        companyId: event.company_id.id,
        name: event.name,
        surname: event.surname,
      } as IUserViewModel);
    } else {
      updateUser({
        id: event.id,
        user: {
          email: event.email,
          role: event.role.key,
          companyId: event.company_id,
          name: event.name,
          surname: event.surname,
        } as IUserViewModel,
      });
    }
    props.onSubmit(event);
    // props.cancelEdit();
  };

  useEffect(() => {
    if (isSuccess) 
      props.cancelEdit();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isSelectSucceded) {
      dispatch(api.util.resetApiState())
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectSucceded])

    const handleCompanyChange = (e : any) =>{
        setValueCompany(e.value.company_name);
    }
    const handleRoleChange = (e : any) =>{
        setValueRole(e.value)
    }

  return (
    <Dialog
      className={"users-form-media-rule"}
      title={
        <CustomTitleBar
          type={(!props.item || !props.item.name || props.item.name === "")
              ? ""
              : "Utente"}
          label={
            (!props.item || !props.item.name || props.item.name === "")
              ? "Nuovo utente"
              : props.item.name
          }
          icon="people"
        />
      }
      onClose={props.cancelEdit}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={props.item}
        render={(formRenderProps) => (
          <FormElement style={{ maxWidth: 950 }}>
            <Grid container alignItems="stretch"  spacing={3}>

                <Grid item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      className="item-media-rule rows-grandi">
                  <Field
                    name={"name"}
                    component={ValidatedInput}
                    label={"Nome"}
                    validator={[requireValidator]}
                  />
                </Grid>
                <Grid item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6} className="item-media-rule rows-grandi">
                  <Field
                    name={"surname"}
                    component={ValidatedInput}
                    label={"Cognome"}
                    validator={[requireValidator]}
                  />
                </Grid>

                <Grid item  xs={12}
                      sm={6}
                      md={6}
                      lg={6} className="item-media-rule rows-grandi">
                  <Field
                    name={"email"}
                    component={ValidatedInput}
                    label={"Email"}
                    validator={[requireValidator]}
                  />
                </Grid>
                <Grid item  xs={12}
                      sm={6}
                      md={6}
                      lg={6} className="item-media-rule rows-grandi">
                  <Field
                    name={"role"}
                    required={true}
                    component={ValidatedDropDownListKey}
                    key={"key"}
                    textField={"label"}
                    dataItemKey={"key"}
                    label={"Ruolo"}
                    value={valueRole}
                    data={realRoles.filter((t) => t.isChooseable?.toString() === 'true')}
                    // itemRender={roleItemRender}
                    onChange={handleRoleChange}
                    // onChange={(e) => handleRoleChange(e)}
                    // data={roles
                    //   .filter((t) => t.isChooseable)
                    //   ?.map((item, index) => {
                    //     return item.label;
                    //   })}
                    // value={props.item?.priority}
                    validator={[requireValidator]}
                  />
                </Grid>

                <Grid item xs={12}
                      sm={12}
                      md={12}
                      lg={12}  className="item-media-rule rows-grandi ultima">
                  {isFetching ? (
                    <CenteredLoadingSpinner />
                  ) : (
                    <Field
                      name={"company_id"}
                      required={true}
                      disabled={props.item.id}
                      component={ValidatedDropDownListId}
                      label={"Azienda"}
                      key={"id"}
                      value={valueCompany}
                      onChange={handleCompanyChange}
                      textField={"company_name"}
                      dataItemKey={"id"}
                      // onChange={(e) => {
                      //   props.item.company_id = e.value.id;
                      // }}
                      data={companies}
                      // onChange={handleRoleChange}
                      // data={companies.map((item, index) => {
                      //   return "id: " + item?.id + ", " + item.company_name;
                      // })}
                      // value={props.item?.priority}
                      validator={[requireValidator]}
                    />
                  )}
                </Grid>
              </Grid>
            {/*SPAZIO TRA RIGHE*/}
            <Grid container item xs={12}></Grid>
            <Grid container item xs={12}></Grid>
            <Grid
              container
              justifyContent="center"
              style={{ marginTop: "2vh" }}
            >
              {isAdding || isUpdating ? (
                <CenteredLoadingSpinner />
              ) : (
                <Fragment>
                  <BaseButton
                    className={` ${
                      formRenderProps.allowSubmit
                        ? "custom-unif-viola-back"
                        : ""
                    }`}
                    type={"submit"}
                    variant="contained"
                    disabled={!formRenderProps.allowSubmit}
                    startIcon={<SaveIcon />}
                  >
                    Salva
                  </BaseButton>
                  {props.hasCancel && (
                    <BaseButton
                        onClick={props.cancelEdit}
                        disabled={!formRenderProps.allowSubmit}
                        className={`custom-button-annulla custom-unif-grigio-back left-spacing-10p ${
                            formRenderProps.allowSubmit
                                ? "custom-unif-arancione-back"
                                : ""
                        }`}
                        startIcon={<CancelPresentationIcon />}
                    >
                        Annulla
                    </BaseButton>
                  )}
                </Fragment>
              )}
              {formRenderProps.visited &&
                formRenderProps.errors &&
                formRenderProps.errors.VALIDATION_SUMMARY && (
                  <div className={"k-messagebox k-messagebox-error"}>
                    <Error>{formRenderProps.errors.VALIDATION_SUMMARY}</Error>
                  </div>
                )}
            </Grid>
          </FormElement>
        )}
      />
    </Dialog>
  );
};
export default UserForm;
