import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {formatDateForApi} from "utils/dateHelper";

export type viewType = 'day' | 'month';

export interface IDayInfos  {
    datestring: string,
    percentage: number,
    slot: number,
    prenotation_number: number,
    partecipant_number: number,
    closed: boolean,
}
interface schedulerViewState {
    view: string;
    day: string | null;
    dayInfo: IDayInfos[];
    dateScheduler: string;
}

const initialState: schedulerViewState = {
    view: 'day',
    day: null,
    dateScheduler: formatDateForApi(new Date()),
    dayInfo: [
    //     {
    //     datestring: '20230609'
    // }
    ],
};

const SchedulerViewSlice = createSlice({
    name: "schedulerView",
    initialState,
    reducers: {
        setSchedulerView(state, action: PayloadAction<string>) {
            state.view = action.payload;
        },
        setSchedulerDay(state, action: PayloadAction<string>) {
            state.day = action.payload;
        },
        setSchedulerDayinfos(state, action: PayloadAction<IDayInfos[]>) {
            state.dayInfo = action.payload;
        },
        setDateSchedulerView(state, action: PayloadAction<string>) {
            console.log("quante volte ci passso?")
            state.dateScheduler =action.payload;
        },
    },
});

export const {
    setSchedulerView,
    setSchedulerDay,
    setSchedulerDayinfos,
    setDateSchedulerView,
} = SchedulerViewSlice.actions;

export default SchedulerViewSlice.reducer;
