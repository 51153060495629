import {IWeeklyTimetableDay} from "models/entities/IWeeklyTimetableDay";
import React, {useEffect} from "react";
import {useGetDefaultPlannerWeeklyTimetableQuery} from "store/api/plannerTimetables";
import CenteredLoadingSpinner from "../../../components/common/CenteredLoadingSpinner";
import Timetable from "../../../components/timetables/Timetable";
import {useAppDispatch} from "../../../store";
import {selectWeek} from "../../../store/planners";
import {useCurrentPlanner} from "../../../utils/hooks/currentPlanner";
import {skipToken} from "@reduxjs/toolkit/query/react";

const DefaultWeeklyTimetablePage = () => {
  const currentPlanner = useCurrentPlanner();
  const {
    data: plannerDefaultWeeklyTimetable,
    isLoading,
  } = useGetDefaultPlannerWeeklyTimetableQuery(currentPlanner?.id ?? skipToken);
  // const { getDefaultWeeklyTimetableState, defaultWeeklyTimetable } =
  //   useAppSelector((s) => s.planners);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(selectWeek("default"));
    // dispatch(getDefaultWeeklyTimetable(currentPlanner?.id as number));
    // eslint-disable-next-line
  }, [currentPlanner]);

  if (isLoading) {
    return <CenteredLoadingSpinner></CenteredLoadingSpinner>;
  }

  return (
    <Timetable
      timetable={plannerDefaultWeeklyTimetable as IWeeklyTimetableDay[]}
    />
  );
};

export default DefaultWeeklyTimetablePage;
