import {Box, InputAdornment, Link, TextField} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {Link as RouterLink, useHistory} from "react-router-dom";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Fragment, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {ILoginViewModel} from "../../models/views/ILoginViewModel";
import {useAppDispatch} from "../../store";
import BaseIconButton from "components/common/BaseIconButton";
import BaseButton from "components/common/BaseButton";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import {useLoginMutation, useLogoutMutation} from "store/api/auth";
import {useMe} from "utils/hooks/me";

const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
  },
});

const LoginForm = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ILoginViewModel>({
    mode: "onChange",
  });
  const classes = useStyles();
        useAppDispatch();
        // const authSlice = useAppSelector((store) => store.auth);
        // const { authState, autoLoginState } = useAppSelector((store) => store.auth);
        useMe();
        const [login, { isLoading: isLoadingLogin }] = useLoginMutation()
        useLogoutMutation()
        useHistory()

        const [showPassword, setShowPassword] = useState(false);
        const handleClickShowPassword = () => setShowPassword(!showPassword);
        const handleMouseDownPassword = () => setShowPassword(!showPassword);


        return isLoadingLogin ? (
            <CenteredLoadingSpinner />
        ) : (
            <div className="no-scroll-important z-index-label-auth">
                <Fragment>
                    <form
                        noValidate
                        className={classes.fullWidth + "  login-form-classes"}
                        onSubmit={handleSubmit((data) => {
                            login(data);
                        })}
                    >
                        <Controller
                            name="username"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Il campo è obbligatorio",
                                },
                            }}
                            render={({ field }) => (
                                <Box padding={1} width="100%">
                                    <TextField
                                        error={Boolean(errors?.username)}
                                        helperText={errors.username?.message}
                                        variant="outlined"
                                        className={classes.fullWidth + " custom-unif-font custom-unif-viola"}
                                        required
                                        label="Username"
                                        {...field}
                                    />
                                </Box>
                            )}
                        />
                        <Controller
                            name="password"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Il campo è obbligatorio",
                                },
                            }}
                            render={({ field }) => (
                                <Box padding={1} paddingTop={2} width="100%">
                                    <TextField
                                        //errorText={Boolean(errors?.password)}
                                        error={Boolean(errors?.password)}
                                        helperText={errors.password?.message}
                                        //hintText={errors.password?.message}
                                        variant="outlined"
                                        className={classes.fullWidth}
                                        required
                                        label="Password"
                                        // type="password"
                                        autoComplete="current-password"
                                        //floatingLabelText="Password"
                                        {...field}
                                        type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                                        //onChange={someChangeHandler}
                                        InputProps={{
                                            // <-- This is where the toggle button is added.
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <BaseIconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </BaseIconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            )}
                        />
                        <Box
                            display="flex"
                            marginBottom={4}
                            justifyContent="flex-end"
                            padding={1}
                        >
                            <Link
                                to="/forgotten-password"
                                component={RouterLink}
                                variant="body2"
                                className="custom-unif-font-bold
             custom-unif-viola"
                            >
                                Hai dimenticato la password?
                            </Link>
                        </Box>

                        <BaseButton
                            className={classes.fullWidth + " custom-unif-font custom-unif-arancione-back "}
                            disabled={!isValid || isLoadingLogin}
                            variant="outlined"
                            type="submit"
                        >
                            Accedi
                        </BaseButton>
                    </form>

                    {/*<ResponseSnackbar*/}
                    {/*  responseSnackbar={[*/}
                    {/*    {*/}
                    {/*      sliceState: authSlice.authState,*/}
                    {/*      resetFunction: resetLogin() as any,*/}
                    {/*      notShowForSuccess: true,*/}
                    {/*    } as IResponseSnackbar,*/}
                    {/*  ]}*/}
                    {/*/>*/}
                </Fragment>
            </div>
        );
    }
;

export default LoginForm;