import {Chip, Grid as GridUi, Icon, Switch, TextField, useMediaQuery,} from "@mui/material";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Grid, GridCellProps, GridColumn as Column, GridItemChangeEvent,} from "@progress/kendo-react-grid";
import AddIcon from "@mui/icons-material/AddCircle";
import React, {FC, Fragment, useEffect, useState} from "react";
import {IExperienceEquipment} from "../../models/entities/IExperienceEquipment";
import {useAppDispatch} from "../../store";

// import { addEquipmentToExperience } from "../../store/experiences";
import theme from "../../theme";
import CommandTable from "../common/CommandTable";

import {useGetEquipmentsQuery} from "../../store/api/equipments";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  useAddExperienceEquipmentMutation,
  useDeleteExperienceEquipmentMutation,
  useGetExperienceQuery,
  useUpdateExperienceEquipmentMutation,
} from "store/api/experiences";
import {IExperienceEquipmentViewModel} from "models/views/experiences/IExperienceEquipmentViewModel";
import DeleteDialog from "components/common/DeleteDialog";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import BaseButton from "../common/BaseButton";
import InputAdornment from "@mui/material/InputAdornment";
import {Error} from "@progress/kendo-react-labels";

const editField: string = "inEdit";
export interface DataEditable extends IExperienceEquipment {
  inEdit: Boolean;
}

const EquipmentTable: FC<{
  // equipments: IExperienceEquipment[];
  experienceId: number;
}> = ({ experienceId }) => {

  const currentPlanner = useCurrentPlanner();
  const { data: fullEquipments } = useGetEquipmentsQuery(
    currentPlanner?.id ?? skipToken
  );
  const { data: experience, isFetching } = useGetExperienceQuery(
    experienceId ?? skipToken
  );


  // const [updatingItem, setUpdatingItem] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [deletingItemId, setDeletingItemId] = useState<Number>(-1);

  const [
    addExperienceEquipment,
    { isLoading: isAdding },
  ] = useAddExperienceEquipmentMutation();
  const [
    updateExperienceEquipment,
    { isLoading: isUpdating },
  ] = useUpdateExperienceEquipmentMutation();
  const [
    deleteExperienceEquipment,
    { isLoading: isDeleting },
  ] = useDeleteExperienceEquipmentMutation();

  // TODO:non funziona media query

  const [dataCopy, setDataCopy] = useState<DataEditable[]>(
    [] as DataEditable[]
  );

  useEffect(() => {
    if (experience !== undefined) {
      let array = (experience?.equipments as IExperienceEquipment[])
        ?.map(
          (item) =>
          ({
            ...item,
            inEdit: false,
          } as DataEditable)
        )
        .slice();
      setDataCopy(array as DataEditable[]);
    }
  }, [experience?.equipments]);

  const CommandCell = (props: GridCellProps) => (
    <CommandTable
      {...props}
      // disable={updatingItem}
      edit={enterEdit}
      remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );


  // modify the data in the store, db etc
  const remove = (dataItem: any) => {
    setDeleteDialog(true);
    setDeletingItemId(dataItem.id);
  };

  const add = (dataItem: DataEditable) => {

    if (!dataItem.name || dataItem.name === '' || !dataItem.required_number || dataItem.required_number<= 0)
      return false;
    setIsEditing(false);
    dataItem.inEdit = false;
    //ADD api call
    const equipmentSelected = fullEquipments?.find(
      (e) => e.name === dataItem.name
    );

    if (equipmentSelected)
      addExperienceEquipment({
        experienceId: experienceId,
        experienceEquipment: {
          equipment_id: equipmentSelected.id,
          is_for_experience: dataItem.is_for_experience,
          required_number: dataItem.required_number,
        } as IExperienceEquipmentViewModel,
      });

    // setDataCopy(dataCopy.slice());
  };

  const update = (dataItem: DataEditable) => {
    if (!dataItem.name || dataItem.name === '' || !dataItem.required_number || dataItem.required_number<= 0)
      return false;
    setIsEditing(false);
    dataItem.inEdit = false;

    updateExperienceEquipment({
      experienceId: experienceId,
      id: dataItem.id as number,
      experienceEquipment: {
        equipment_id: dataItem.equipment_id,
        is_for_experience: dataItem.is_for_experience,
        required_number: dataItem.required_number,
        every_target_number: dataItem.every_target_number,
      } as IExperienceEquipmentViewModel,
    });
  };

  // Local state operations
  const discard = () => {
    setIsEditing(false);
    const data = [...dataCopy];
    data.splice(0, 1);
    setDataCopy(data);
  };

  const cancel = (dataItem: any) => {
    setIsEditing(false);
    const originalItem = experience?.equipments?.find(
      (p) => p.id === dataItem.id
    );
    const data = dataCopy?.map((item) =>
      originalItem && item.id === originalItem.id
        ? ({
          id: originalItem.id,
          is_for_experience: originalItem.is_for_experience,
          required_number: originalItem.required_number,
          name: originalItem.name,
          inEdit: false,
        } as DataEditable)
        : item
    );
    setDataCopy(data as DataEditable[]);
    // this.setState({ data });
  };

  const enterEdit = (dataItem: any) => {
    // console.log("enter edit");
    // console.log(dataItem);
    if (isEditing)
      return;
    setIsEditing(true);
    setDataCopy(
      dataCopy.map((item) =>
        item.id === dataItem.id ? { ...item, inEdit: true } : item
      )
    );
  };

  const itemChange = (event: GridItemChangeEvent) => {
    // console.log(event)
    // console.log(event.value);
    // if(event.field ===  "required_number"){
    //
    //   if(event.value < 0){
    //     console.log("ci passo ?");
    //     event.value = 0;
    //     setDataCopy(
    //         dataCopy?.map((item) =>
    //             item.id === event.dataItem.id
    //                 ? { ...item, [event.field || ""]: 0 }
    //                 : item
    //         )
    //     );
    //   }else{
    //     setDataCopy(
    //         dataCopy?.map((item) =>
    //             item.id === event.dataItem.id
    //                 ? { ...item, [event.field || ""]: event.value }
    //                 : item
    //         )
    //     );
    //   }
    // }else {
      setDataCopy(
          dataCopy?.map((item) =>
              item.id === event.dataItem.id
                  ? {...item, [event.field || ""]: event.value}
                  : item
          )
      );
    // }
  };

  const addNew = () => {
    setIsEditing(true);
    if (dataCopy?.length > 0)
      setDataCopy([
        {
          id: undefined,
          is_for_experience: false,
          required_number: 0,
          name: "",
          inEdit: true,
        },
        ...dataCopy,
      ]);
    else {
      setDataCopy([
        {
          id: undefined,
          is_for_experience: false,
          required_number: 0,
          name: "",
          inEdit: true,
        },
      ]);
    }
  };
  const [isErrorReqNumber, setIsErrorReqNumber] = useState(false);

  return isFetching || isAdding || isUpdating || isDeleting ? (
    <CenteredLoadingSpinner />
  ) : (
    <Fragment>
      <Grid
        data={dataCopy}
        onItemChange={itemChange}
        editField={editField}
        className=" equipment-table-exp"
      >

        <Column
          field="name"
          title="Attrezzatura"
          className={"field-attrezzatura-equipment-table"}
          // width={matches ? "150px" : ""}
          cell={(props: GridCellProps) => (
            <td  className={"field-attrezzatura-equipment-table"}>
              {props.dataItem.inEdit ? (
                <DropDownList
                    fillMode="outline"
                  onChange={(e: any) => {
                    if (props.onChange)
                      props.onChange({
                        dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.value,
                      });
                  }}

                  value={props.dataItem.name}
                  data={fullEquipments?.map((item) => item.name)}
                />
              ) : (
                props.dataItem.name.toString()
              )}
            </td>
          )}
        />

        <Column
          field="required_number"
          title="Quantità"
          // width={matches ? "120px" : ""}
          editor="numeric"
          cell={(props: GridCellProps) => (
              <td  className={"field-attrezzatura-number-equipment-table"}>
                {props.dataItem.inEdit ? (
                      <TextField
                          value={props.dataItem.required_number}
                          fullWidth
                          variant="outlined"
                          type="number"

                          onChange={(e: any) => {
                            if(e.target.value <= 0){
                              setIsErrorReqNumber(true);
                            }else{
                              setIsErrorReqNumber(false);

                            }
                            if (props.onChange)
                              props.onChange({
                                dataIndex: 0,
                                dataItem: props.dataItem,
                                field: props.field,
                                syntheticEvent: e.syntheticEvent,
                                value: e.target.value,
                              });
                          }}
                          InputProps={{
                            inputMode: "numeric",
                            endAdornment: (
                                <InputAdornment position="end">
                                  <Error>{(isErrorReqNumber)? "Deve essere maggiore di 0" : ""}</Error>
                                </InputAdornment>
                            )
                          }}
                      />


                    )
                    : (
                        props.dataItem.required_number.toString()
                )}
              </td>
          )}
        />
        <Column
          field="is_for_experience"
          title="Utilizzo"
          // width={matches ? "200px" : ""}
          cell={(props: GridCellProps) => (
            <td className={"utilizzo-column"}>
              <GridUi
                container
                // justifyContent="center"
                alignItems="center"
                style={{textAlign: "center"}}
              // style={{ height: "48px" }}
              >
                {props.dataItem.inEdit ? (
                  <Switch
                    checked={props.dataItem.is_for_experience}
                    onChange={(e: any) => {
                      if (props.onChange)
                        props.onChange({
                          dataIndex: 0,
                          dataItem: props.dataItem,
                          field: props.field,
                          syntheticEvent: e.syntheticEvent,
                          value: e.target.checked,
                        });
                    }}
                    color="primary"
                  />
                ) : (
                  <Fragment />
                )}
                <Chip
                    className={"utilizzo-chip"}
                  variant="outlined"
                  icon={
                    <Icon fontSize={"small"}>
                      {props.dataItem["is_for_experience"]
                        ? "local_activity"
                        : "group"}
                    </Icon>
                  }
                  label={
                    props.dataItem["is_for_experience"]
                      ? "per esperienza"
                      : "per partecipante"
                  }
                />
              </GridUi>
            </td>
          )}
        />

        {/* da mettere una width minima */}
        <Column
          cell={CommandCell}
          // width={matches ? "150px" : ""}
          width="150px"
        />
      </Grid>
      {deleteDialog && (
        <DeleteDialog
          toggleDialog={() => {
            setDeleteDialog(!deleteDialog);
          }}
          onConfirm={() => {
            deleteExperienceEquipment({
              experienceId: experienceId,
              id: deletingItemId as number,
            });
          }}
        />
      )}
      <BaseButton
          tabIndex={-1}
          className={"custom-unif-viola bottoni-bordo-viola align-to-center add-equip-dialog"}
          color="primary"
          disabled={isEditing}

          variant={"outlined"}
          startIcon={<AddIcon />}
          onClick={addNew}
      >
        Aggiungi attrezzatura
      </BaseButton>
    </Fragment>
  );
};

export default EquipmentTable;
