import { FieldValidatorType } from "@progress/kendo-react-form";
const character = [
    { key:'A', value : 10},
    { key:'C', value : 12},
    { key:'D', value : 13},
    { key:'E', value : 4},
    { key:'F', value : 15},
    { key:'G', value : 16},
    { key:'H', value : 17},
    { key:'I', value : 18},
    { key:'J', value : 19},
    { key:'K', value : 20},
    { key:'L', value : 21},
    { key:'M', value : 22},
    { key:'N', value : 23},
    { key:'O', value : 24},
    { key:'P', value : 25},
    { key:'Q', value : 26},
    { key:'R', value : 27},
    { key:'S', value : 28},
    { key:'T', value : 29},
    { key:'U', value : 30},
    { key:'V', value : 31},
    { key:'W', value : 32},
    { key:'X', value : 33},
    { key:'Y', value : 34},
    { key:'Z', value : 35},
    { key:'B', value : 11}
]
//https://en.wikipedia.org/wiki/International_Bank_Account_Number#Validating_the_IBAN
export const checkIban  = (iban: string | undefined) => {
    if(iban) {
        let ibanF = iban.replace(' ', '');
        ibanF = ibanF.substr(4, ibanF.length) + ibanF.substr(0, 2)+ ibanF.substr(2,2);
        ibanF = ibanF.replace(' ', '');
        for (let i : number = 0; i < ibanF.length; i++) {
            if ( ibanF[i].match(/[a-z]/i)) {
                let char = ibanF[i].toString();
                let numericValue = character.find(i => i.key === char)?.value;
                if(numericValue) {
                    ibanF = ibanF.replace(char, numericValue.toString());
                }
            }
        }
        ibanF = ibanF.replace(/\s/g,"");
        return (Number(BigInt(ibanF) % BigInt('97')) === 1);
    }
}


export const ibanValidator: FieldValidatorType = (value: string) => {
   return  ( checkIban(value))
        ? ""
        : "Il campo deve essere un iban valido";
}