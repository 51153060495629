import React, {useEffect, useState} from "react";
import {PageState} from "../../models/helpers/IPageState";
import {CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor,} from "@progress/kendo-data-query";
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridRowProps,
  GridSortChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import AddIcon from "@mui/icons-material/AddCircle";
import LoginIcon from "@mui/icons-material/Input";
import SendEmailIcon from "@mui/icons-material/Email";
import GridUi from "@mui/material/Grid";
import EditCommandCell from "./EditCommandCell";
import {IBase} from "../../models/entities/IBase";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import {RenderProp} from "../../models/helpers/RenderProps";
import {useAppDispatch, useAppSelector} from "../../store";
import DeleteDialog from "./DeleteDialog";
import {CircularProgress, Icon} from "@mui/material";
import BaseIconButton from "./BaseIconButton";
import {useResendInviteUserMutation} from "store/api/users";
import {IntlProvider, load, LocalizationProvider,} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import numbers from "cldr-numbers-full/main/it/numbers.json";
import currencies from "cldr-numbers-full/main/it/currencies.json";
import caGregorian from "cldr-dates-full/main/it/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/it/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/it/timeZoneNames.json";
import {useSelectUserMutation} from "../../store/api/auth";
import {api} from "../../store/api/api";
import {IRoleType} from "models/entities/types/IRoleType";
import {useGetActiveUserQuery} from "store/api/auth";
import PageTitleGrid from "./PageTitleGrid";
import BaseButton from "./BaseButton";
import {DropDownList} from "@progress/kendo-react-dropdowns";

// import itMessages from "./../../globalization/GenericGridIT.json";

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);
// loadMessages(itMessages, "it-IT");

//TODO: Modificare numero di righe per tabelle


const initialFilter: CompositeFilterDescriptor = {
  logic: "and",
  //filters: [{ field: "name", operator: "contains", value: "Chef" }],
  filters: [],
};



const initialCustomFilter: CompositeFilterDescriptor = {
  logic: "and",
  filters : [
    {field: 'checkout', operator: 'gte', value:  new Date()},
    {field: 'checkin', operator: 'lte', value:  new Date()},
  ]
};



interface ColumnHeader {
  field: string;
  title: string;
  format?: string;
  filter?: "boolean" | "numeric" | "text" | "date" | undefined;
  width?: string | number;
  className?: string;
}

export interface GenericPagedGridProps<T extends IBase> {
  items: T[];
  icon?: string;
  title?: string;
  itemsPage?: number;
  columns: ColumnHeader[];
  titleDelete?: string;
  textDelete?: string;
  customFilter?: boolean;
  updateFormComponent: RenderProp<EditFormProps<T>>;
  addFormComponent: RenderProp<EditFormProps<T>>;
  customFormComponent?: RenderProp<EditFormProps<T>>;
  customViewLinkComponent?: RenderProp<EditFormProps<T>>;
  enableCancel: boolean;
  onRemove: (item: T) => void;
  // deleteDispatch: () => void;
}
const ACTION_CONFIRM_INVITE = 1;


const GenericPagedGrid = <T extends IBase>({
  items,
  columns,
  updateFormComponent,
  addFormComponent,
  customFormComponent,
  customViewLinkComponent,
  enableCancel, customFilter,
  onRemove,
  icon = '',
  title = '',
  titleDelete,
  textDelete,
  itemsPage = 20
}: // deleteDispatch,
  GenericPagedGridProps<T>) => {
  const {
    colors,
    roles
  } = useAppSelector((store) => store.types);
  const initialSort: Array<SortDescriptor> = title === "Ospiti"  ? [{ field: "checkin", dir: "asc" }] : [
    { field: "name", dir: "asc" },
  ];
  const { countries } = useAppSelector((store) => store.types);
  const initialDataState: PageState = { skip: 0, take: itemsPage };
  const [openUpdateForm, setOpenUpdateForm] = useState<boolean>(false);
  const [openCustomForm, setOpenCustomForm] = useState<boolean>(false);
  const [openViewLink, setOpenViewLink] = useState<boolean>(false);
  const [openAddForm, setOpenAddForm] = useState<boolean>(false);
  const [openDeleteForm, setOpenDeleteForm] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<T>({} as T);
  const [customItem, setCustomItem] = useState<T>({} as T);
  const [viewLink, setViewLink] = useState<T>({} as T);
  const [valueDRDWN,setValueDRDWN] = useState<string>("Ospiti Presenti")


  const [requireConfirmation, setRequireConfirmation] = useState<boolean>(false)
  const [confirmationDataItem, setConfirmationDataItem] = useState<any>()
  const [confirmationText, setConfirmationText] = useState<string>('')
  const [confirmationAction, setConfirmationAction] = useState<number>(0)

  const [editItem, setEditItem] = useState<T>({} as T);
  const [filter, setFilter] = useState(customFilter?initialCustomFilter : initialFilter);
  const [iconFilter, setIconFilter] = useState("filter");
  const [filterable, setFilterable] = useState(false);
  const [sort, setSort] = useState(initialSort);
  const [page, setPage] = useState<PageState>(initialDataState);

  const [selectUser, { isSuccess: isSelectSucceded }] = useSelectUserMutation()
  const dispatch = useAppDispatch();

  const filteringDate = [
    "Tutti gli Ospiti",
    "Archivio Ospiti",
    "Ospiti in Arrivo",
    "Ospiti in Uscita",
    "Ospiti Presenti",
  ]





  const { } = useGetActiveUserQuery();

  const handleLogin = (id: number) => {
    dispatch(api.util.resetApiState())
    selectUser(id);
    //refetch();
  }

  useEffect(() => {
    if (isSelectSucceded) {
      dispatch(api.util.resetApiState())
    }
  }, [isSelectSucceded])

  const [resendInviteUser, { isLoading: isLoadingResendInviteUser }] =
    useResendInviteUserMutation();
  const [resendInviteUserId, setResendInviteUserId] = useState<number>();

  const pageChange = (event: GridPageChangeEvent) => {
    setPage(event.page);
  };

  const enterCustom = (dataItem: T) => {
    setOpenCustomForm(true);
    setCustomItem(dataItem);
  };

  const enterViewLink = (dataItem: T) => {
    setOpenViewLink(true);
    setViewLink(dataItem);
  };

  const enterEdit = (dataItem: T) => {
    setOpenUpdateForm(true);
    setEditItem(dataItem);
  };

  const enterAdd = () => {
    setOpenAddForm(true);
    setEditItem({} as T);
  };

  const handleAddSubmit = (company: T) => {
    // setOpenAddForm(false);
  };
  const handleUpdateSubmit = (company: T) => {
    // setOpenUpdateForm(false);
  };

  const handleCancelEdit = () => {
    setOpenCustomForm(false);
    setOpenViewLink(false);
    setOpenUpdateForm(false);
    setOpenAddForm(false);
    setCustomItem({} as T);
  };

  const MyEditCommandCell = (props: GridCellProps) => (
    // <EditCommandCell {...props} enterEdit={enterEdit} remove={onRemove} />
          <EditCommandCell
            {...props}
            enterEdit={enterEdit}
            isDeletable={props.dataItem.booking_number === 0 || !props.dataItem.hasOwnProperty("booking_number")}
            remove={(item: T) => {
              setDeleteItem(item);
              setOpenDeleteForm(true);
            }}
          />
  );

  const handleNothing = ()=>{

  }
  const UpdateFormComponentDynamic = updateFormComponent({
    item: editItem,
    onSubmit: handleUpdateSubmit,
    cancelEdit: handleCancelEdit,
    hasCancel: enableCancel,
    onChange: handleNothing,
  });
  const AddFormComponentDynamic = addFormComponent({
    item: editItem,
    onSubmit: handleAddSubmit,
    cancelEdit: handleCancelEdit,
    hasCancel: enableCancel,
    onChange: handleNothing,
  });
  const DeleteDialogDynamic = DeleteDialog({
    titleDelete: titleDelete,
    textDelete: textDelete,
    toggleDialog: () => {
      setOpenDeleteForm(false);
      setDeleteItem({} as T);
    },
    onConfirm: () => {
      onRemove(deleteItem);
      setDeleteItem({} as T);
    },
  });
  const CustomFormComponentDynamic = customFormComponent ? customFormComponent({
    item: customItem,
    onSubmit: handleUpdateSubmit,
    cancelEdit: handleCancelEdit,
    hasCancel: enableCancel,
    onChange: handleNothing,
  }) : null;

  //per vedere i dettagli sull'utilizzo del link da parte del guest
  const CustomFormComponentDynamicLink = customViewLinkComponent ? customViewLinkComponent({
    item: viewLink,
    onSubmit: handleUpdateSubmit,
    cancelEdit: handleCancelEdit,
    hasCancel: enableCancel,
    onChange: handleNothing,
  }) : null;

  useEffect(() => {
    let pagerSize = window.document.querySelector(".k-pager-sizes");
    if(pagerSize) {
      pagerSize!.innerHTML = "" + itemsPage + " righe per pagina";
    }
    // if (pagerSize && pagerSize!.childNodes.length > 1) {
    //   pagerSize!.childNodes[1].nodeValue =
    //   "" + itemsPage + " righe per pagina";
    //   pagerSize!.removeChild(pagerSize.firstChild as ChildNode);
    // }
  }, []);


  const rowRender = (
      trElement: React.ReactElement<HTMLTableRowElement>,
      props: GridRowProps
  ) => {
    const paid = props.dataItem.paid ?? undefined;
    const url_active = props.dataItem.url_active ?? undefined;
    const grey = { backgroundColor: "#d7d6d3" };
    const green = { backgroundColor: "#adf0a9" };
    const white = { backgroundColor: "white" };

    let style = white;
    if(paid !== undefined){
      if(paid) {
        style = green;
      }else{
        if(url_active !== undefined){
          if(!url_active ){
            style = grey;
          }
        }
      }
    }

    const trProps: any = { style: style };
    return React.cloneElement(
        trElement,
        { ...trProps },
        trElement.props.children
    );
  };


  const handleChangeFilterDate = (e : any) =>{
        let value = e.value;
        const today = new Date();
        const tomorrow = new Date(today);
        const yesterday = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setUTCHours(0, 0, 0, 0);
        today.setUTCHours(0, 0, 0, 0);
        tomorrow.setUTCHours(0, 0, 0, 0);

        switch (value) {
          case "Tutti gli Ospiti" : {
            setValueDRDWN("Tutti gli Ospiti")
            setFilter({
              logic: "and",
              filters : [

              ]
            })
            break;
          }
          case "Archivio Ospiti": {
            setValueDRDWN("Archivio Ospiti")
            setFilter({
              logic: "and",
              filters : [
                {field: 'checkout', operator: 'lt', value: today}
              ]
            })
            break;
          }
          case "Ospiti in Arrivo": {
            setValueDRDWN("Ospiti in Arrivo")
            setFilter({
              logic: "and",
              filters : [
                {field: 'checkin', operator: 'gt', value: yesterday},
                {field: 'checkin', operator: 'lte', value: tomorrow},
              ]
            })
            break;
          }
          case "Ospiti in Uscita": {
            setValueDRDWN("Ospiti in Uscita")
            setFilter({
              logic: "and",
              filters : [
                {field: 'checkout', operator: 'gt', value: yesterday},
                {field: 'checkout', operator: 'lte', value: tomorrow},
              ]
            })
            break;
          }
          case "Ospiti Presenti": {
            setValueDRDWN("Ospiti Presenti")
            setFilter({
              logic: "and",
              filters : [
                {field: 'checkout', operator: 'gte', value: today},
                {field: 'checkin', operator: 'lte', value: today},
              ]
            })
            break;
          }
        }
  }

  const DropDownListChangeDate = () => {


    return (
        <div
            style={{marginLeft: "5em"}}
        >
            <DropDownList
                  style={{width: "15em"}}
                  size={"large"}
                  fillMode={"outline"}
                  rounded={"large"}
                  className={"dropdown-origin-kendo"}
                  data={filteringDate}
                  value={valueDRDWN}
                  onChange={(e)=> {
                    handleChangeFilterDate(e)
                  }}
              />
        </div>
    );
  };



  return (
    <React.Fragment>
      <LocalizationProvider language="it-IT">
        <IntlProvider locale="it">
          <Grid
            className={"generic-grid-metis std-grid-component"}
            style={{ border: "0" }}
            data={items?.length > 0 ? orderBy(filterBy(items, filter), sort).slice(
              page.skip,
              page.take + page.skip
            ) : []}
            skip={page.skip}
            take={page.take}
            total={items?.length}
            pageable={{
              buttonCount: 4,
              pageSizes: [itemsPage],
            }}
            rowRender={rowRender}
            onPageChange={pageChange}
            filterable={filterable}
            filter={filter}
            onFilterChange={(e: GridFilterChangeEvent) => {
              setValueDRDWN("Tutti gli Ospiti")
              setFilter(e.filter)
            }}
            sortable={true}
            sort={sort}
            resizable={false}
            onSortChange={(e: GridSortChangeEvent) => {
              setSort(e.sort);
            }}
          >
            <GridToolbar>
              <GridUi container spacing={1}>
                <GridUi container item xs={12} spacing={3}>
                  <GridUi item xs={11} className={"title-bar-grid"}>
                    {title && <div style={{ float: "left" }}><PageTitleGrid icon={icon} title={title} /></div>}
                    {/*<BaseIconButton*/}
                    {/*    // title="Nuovo"*/}
                    {/*    aria-label="Nuovo"*/}
                    {/*    onClick={enterAdd}*/}
                    {/*    //disabled={true}*/}
                    {/*    style={{ marginTop: "2px" }}*/}
                    {/*    className={"custom-unif-viola-back bottoni-tondi-2 generic-grid-button paged-grid-button"}*/}
                    {/*>*/}
                    {/*  <span>+</span>*/}
                    {/*</BaseIconButton>*/}
                    <BaseIconButton
                        color="primary"
                        // title="Nuovo"
                        aria-label="Nuovo"
                        className={"custom-unif-viola grid-button"}
                        onClick={enterAdd}
                        //disabled={true}
                        style={{ fontSize: "25px" }}
                    >
                      <AddIcon />
                    </BaseIconButton>

                    {customFilter && <DropDownListChangeDate />}
                  </GridUi>

                  <GridUi
                    item
                    xs={1}
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <BaseIconButton
                      onClick={() => {
                        setFilterable(!filterable);
                        setFilter(initialFilter);
                        setIconFilter(
                          iconFilter === "filter" ? "filter-clear" : "filter"
                        );
                      }}
                    >
                      {!filterable ? (
                        <span className="k-icon k-i-filter" />
                      ) : (
                        <span className="k-icon k-i-filter-clear" />
                      )}
                    </BaseIconButton>
                  </GridUi>
                </GridUi>

              </GridUi>
            </GridToolbar>
            {columns?.map(
              ({ field, title, format, width, filter, className }, index) =>

                field === "color" ? (
                  <Column
                    key={index}
                    field={field}
                    className={className}
                    title={title}
                    format={format}
                    width={width ?? "90"}
                    cell={(props: GridCellProps) => (
                      <td>
                        <span
                          style={{
                            display: "inline-block",
                            background: colors.find(
                              (t) => t.label === props.dataItem.color
                            )?.hex,
                            borderRadius: "50%",
                            border: "1px solid #9e9e9e",
                            width: "25px",
                            height: "25px",
                            textAlign: "center",
                            overflow: "hidden",
                            marginTop: "6px",
                          }}
                        ></span>
                      </td>
                    )}
                  />
                ) : field === "planner_role" ? (
                  <Column
                    key={index}
                    field={field}
                    title={title}
                    className={className}
                    format={format}
                    width={width ?? "220"}
                    cell={(props: GridCellProps) => (
                      <td>
                        {(props.dataItem.planner_role) ?
                          (props.dataItem.planner_role.length > 2) ?
                            <span>
                              {roles?.find((item: IRoleType) => item.key === props.dataItem.planner_role[0])?.label}
                              <br />{" "}
                              {roles?.find((item: IRoleType) => item.key === props.dataItem.planner_role[1])?.label}
                              {" ..."}
                            </span>
                            :
                            props.dataItem.planner_role.map((role: any) => (


                              <span>
                                {roles?.find((item: IRoleType) => item.key === role)?.label}
                                <br />{" "}
                              </span>
                            )) : null
                        }

                      </td>
                    )}
                  />
                ) : field === "languages" ? (
                  <Column
                    key={index}
                    field={field}
                    title={title}
                    format={format}
                    className={className}
                    width={width ?? "220"}
                    cell={(props: GridCellProps) => (
                      <td>
                        {(props.dataItem.languages) ?
                          (props.dataItem.languages.length > 2) ?
                            <span>
                              {props.dataItem.languages[0]}
                              <br />{" "}
                              {props.dataItem.languages[1]}
                              {" ..."}
                            </span>
                            :
                            props.dataItem.languages.map((l: any) => (
                              <span>
                                {l}
                                <br />{" "}
                              </span>
                            )) : null
                        }
                      </td>
                    )}
                  />
                ) : filter === "boolean" ? (
                  <Column
                    key={index}
                    field={field}
                    title={title}
                    className={className}
                    format={format}
                    width={width ?? "100"}
                    cell={(props: GridCellProps) => (
                      <td>
                        <div>
                          <Icon
                            color="primary"
                            className={"custom-unif-viola "}
                            style={{ display: "block", float: "left" }}
                          >
                            {props.dataItem[field] === true ? "check" : "clear"}
                          </Icon>
                        </div>
                      </td>
                    )}
                  />
                )
                  : field === "enabled" ? (
                    <Column

                      key={index}
                      filterable={false}
                      field={field}
                      className="attivo-colonna"
                      title={title}
                      format={format}
                      width={"90"}
                      cell={(props: GridCellProps) => (

                          <td className={className} >
                            <div>
                              {props.dataItem[field] ? (

                                <div className={"align-side-by-side"}>
                                  <span title="Rimanda invito">
                                  <BaseIconButton
                                      color="primary"
                                      className={"custom-unif-viola "}
                                      disabled={
                                        isLoadingResendInviteUser &&
                                        props.dataItem["id"] === resendInviteUserId
                                      }
                                      onClick={() => {
                                        setRequireConfirmation(true)
                                        setConfirmationDataItem(props.dataItem)
                                        setConfirmationAction(ACTION_CONFIRM_INVITE)
                                        setConfirmationText('Sei sicuro di voler rimandare l\'invito')
                                      }}
                                      style={{ display: "block", float: "left" }}
                                  >
                                    {(isLoadingResendInviteUser &&
                                    props.dataItem["id"] === resendInviteUserId) ? (
                                        <CircularProgress size={20}/>
                                    ) : (
                                        <SendEmailIcon />
                                    )}
                                  </BaseIconButton>
                                  </span>
                                  <span title="Impersonifica">
                                  <BaseIconButton

                                      color="primary"
                                      className={"custom-unif-viola "}
                                      onClick={() => handleLogin(props.dataItem["id"] as number)}
                                      style={{ display: "block", float: "left" }}
                                  >
                                    <LoginIcon />
                                  </BaseIconButton>
                                  </span>
                                </div>
                              ) : (
                                  <span title="Rimanda invito">
                                <BaseIconButton
                                  color="primary"
                                  className={"custom-unif-viola "}
                                  disabled={
                                    isLoadingResendInviteUser &&
                                    props.dataItem["id"] === resendInviteUserId
                                  }
                                  onClick={() => {
                                    resendInviteUser({
                                      id: props.dataItem["id"] as number,
                                      email: props.dataItem["email"] as string,
                                    });
                                    setResendInviteUserId(props.dataItem["id"]);
                                  }}
                                  style={{ display: "block", float: "left" }}
                                >
                                  {isLoadingResendInviteUser &&
                                    props.dataItem["id"] === resendInviteUserId ? (
                                    <CircularProgress />
                                  ) : (
                                    <SendEmailIcon />
                                  )}
                                </BaseIconButton>
                                  </span>
                              )}
                            </div>
                          </td>
                      )}
                    />
                  )
                    : field === "impersonate" ? (
                      <Column
                        key={index}
                        field={field}
                        className="attivo-colonna"
                        title={title}
                        format={format}
                        width={"90"}
                        cell={(props: GridCellProps) => (
                          <td className={className}>
                            <div>
                              <BaseIconButton
                                color="primary"
                                className={"custom-unif-viola "}
                                onClick={() => handleLogin(props.dataItem["id"] as number)}
                                style={{ display: "block", float: "left" }}
                              >
                                <LoginIcon />
                              </BaseIconButton>
                            </div>
                          </td>
                        )}
                      />
                    ) : field === "length_minutes" ? (
                      <Column
                        key={index}
                        field={field}
                        title={title}
                        format={format}
                        width={"90"}
                      />
                    )
                      : field === "customers_max_number" ? (
                        <Column
                          key={index}
                          field={field}
                          title={title}
                          format={format}
                          width={"120"}
                        />
                      )
                        : field === "staffs" ? (
                          <Column
                            key={index}
                            field={field}
                            title={title}
                            format={format}
                            width={"200"}
                            cell={(props: GridCellProps) => (
                              <td>
                                {(props.dataItem.staffs) ?


                                  (props.dataItem.staffs.length > 2) ?
                                    (
                                        // (console.log("--------" + props.dataItem)),
                                      <span>
                                        {props.dataItem.staffs[0].name}{" "}
                                        {props.dataItem.staffs[0].surname}
                                        <br />{" "}
                                        {props.dataItem.staffs[1].name}{" "}
                                        {props.dataItem.staffs[1].surname}

                                        {" ..."}
                                      </span>)
                                    :
                                    (
                                        // (console.log(props.dataItem)),
                                      <span>
                                        {(props.dataItem.staffs[0]) ? props.dataItem.staffs[0].name : ""}{" "}
                                        {(props.dataItem.staffs[0]) ? props.dataItem.staffs[0].surname : ""}
                                        <br />{" "}
                                        {(props.dataItem.staffs[1]) ? props.dataItem.staffs[1].name : ""}{" "}
                                        {(props.dataItem.staffs[1]) ? props.dataItem.staffs[1].surname : ""}

                                      </span>)

                                  : null
                                }
                              </td>
                            )}
                          />
                        )
                          : field === "active" ? (
                                            <Column
                                                key={index}
                                                field={field}
                                                title={title}
                                                format={format}
                                                filterable={false}
                                                width={"90"}
                                                cell={(props: GridCellProps) => (
                                                    <td>
                                                      {(props.dataItem.active) ?
                                                          "Attiva"
                                                          : "Non attiva"
                                                      }
                                                    </td>
                                                )}
                                            />
                                        ) : (field === "nationality" ) ? (
                                            <Column
                                                key={index}
                                                field={field}
                                                title={title}
                                                format={format}
                                                cell={(props: GridCellProps) => (
                                                    <td>
                                                      {countries.find((c)=> c.key === props.dataItem.nationality )?.label ?? props.dataItem.nationality }
                                                    </td>
                                                )}
                                            />
                                        )
                                            : (field === "checkin" ) ? (
                                                    <Column
                                                        key={index}
                                                        field={field}
                                                        title={title}
                                                        format={format}
                                                        filter={"date"}
                                                        cell={(props: GridCellProps) => (
                                                            <td>
                                                              {props.dataItem.checkin.toLocaleDateString('IT')}
                                                            </td>
                                                        )}
                                                    />
                                                )
                                                : (field === "checkout" ) ? (
                                                        <Column
                                                            key={index}
                                                            field={field}
                                                            title={title}
                                                            filter={"date"}
                                                            format={format}
                                                            cell={(props: GridCellProps) => (
                                                                <td>
                                                                  {props.dataItem.checkout.toLocaleDateString('IT')}
                                                                </td>
                                                            )}
                                                        />
                                                    )
                                                    : (field === "booking_number" ) ? (
                                                            <Column
                                                                key={index}
                                                                field={field}
                                                                title={title}
                                                                filterable={false}
                                                                format={format}
                                                                className={'allinea-colonna-centro'}
                                                                cell={(props: GridCellProps) => (
                                                                    <td style={{textAlign: "center"}}>

                                                                      {props.dataItem.booking_number > 0 ? <BaseButton
                                                                          className={"buttons-inside-grids custom-unif-viola-back "}
                                                                          variant="contained"
                                                                          onClick={() => enterCustom(props.dataItem)}
                                                                          endIcon={<span className="k-icon k-i-zoom k-i-search"></span>}
                                                                      >
                                                                        {props.dataItem.booking_number}
                                                                      </BaseButton> :

                                                                           ( props.dataItem.grid_label !== "" )?
                                                                               <BaseButton
                                                                                   className={props.dataItem.is_link_sent? "buttons-inside-grids-view-link custom-unif-viola-back":"buttons-inside-grids-view-link custom-unif-arancione-back "}
                                                                                   variant="contained"
                                                                                   onClick={() => enterViewLink(props.dataItem)}
                                                                                   // endIcon={<span className="k-icon k-i-zoom k-i-search"></span>}
                                                                               >{props.dataItem.grid_label} </BaseButton> : <></>
                                                                      }
                                                                    </td>
                                                                )}
                                                            />
                                                        )



              :(field === "name_surname" ) ? (
                                                    <Column
                                                        key={index}
                                                        field={field}
                                                        title={title}
                                                        format={format}

                                                        width={width}
                                                        cell={(props: GridCellProps) => (
                                                            <td>
                                                              {props.dataItem.name + " " + props.dataItem.surname  }
                                                            </td>
                                                        )}
                                                    />
                                                )
                                                : (
                              <Column
                                key={index}
                                field={field}
                                className={"prova " + className}
                                title={title}
                                format={format}
                              // width={width ?? (window.innerWidth < 900 ? "200" : "")}
                              />

                            )
            )}

            <Column
              className={"actions "}
              // headerCell={FilterButtonHeader}
              cell={MyEditCommandCell}
              filterable={false}
              sortable={false}
              width={title === "Ospiti" ? "190px" :  "170px"}
            />
            {/*<Column*/}
            {/*  className={"actions "}*/}
            {/*  cell={MyEditCommandCell}*/}
            {/*  filterable={false}*/}
            {/*  sortable={false}*/}
            {/*  width="170px"*/}
            {/*/>*/}
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
      {openAddForm && AddFormComponentDynamic}
      {openUpdateForm && UpdateFormComponentDynamic}
      {openDeleteForm && DeleteDialogDynamic}
      {openCustomForm && CustomFormComponentDynamic}
      {openViewLink && CustomFormComponentDynamicLink}
      {requireConfirmation && (
          <DeleteDialog
              textDelete={confirmationText}
              titleDelete={'Attenzione'}
              toggleDialog={() => {
                setRequireConfirmation(!requireConfirmation);
              }}
              additionalclasses={'form-availability'}
              onConfirm={() => {
                switch (confirmationAction) {
                  case ACTION_CONFIRM_INVITE:
                    if(confirmationDataItem) {
                      resendInviteUser({
                        id: confirmationDataItem["id"] as number,
                        email: confirmationDataItem["email"] as string
                      });
                      setResendInviteUserId(confirmationDataItem["id"]);
                    }
                    break;

                  default:
                    // console.log('default');
                    break;
                }
              }}
          />
      )}
    </React.Fragment>
  );
};

export default GenericPagedGrid;
