import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {Error} from "@progress/kendo-react-labels";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import {IExperience} from "../../models/entities/IExperience";
import Grid from "@mui/material/Grid";
import {
  ValidatedDropDownListKeyLabelTypes, ValidatedDropDownListKeyLabelTypes_customBehavior,
  ValidatedDropDownListPrenTypes,
  ValidatedInput,
  ValidatedSwitch,
} from "../common/ValidatedFields";
import {useAppDispatch, useAppSelector} from "../../store";
import {requireValidator} from "../../utils/validators/requireValidator";

import {IExperienceViewModel} from "../../models/views/IExperienceViewModel";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import {StaffMultiselect} from "../staff/StaffMultiselect";
import {LocationsMultiselect} from "../locations/LocationsMultiselect";
import {ExclusionsMultiselect} from "../experiences/ExclusionsMultiselect";
import {Typography} from "@mui/material";
import BaseButton from "../common/BaseButton";
import {useAddExperienceMutation, useGetExperienceQuery, useUpdateExperienceMutation,} from "store/api/experiences";
import React, {useEffect, useState} from "react";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {useHistory, useLocation} from "react-router-dom";
import {selectExperience} from "../../store/experiences";
import {useCurrentExperience} from "utils/hooks/currentExperience";
import {digitValidator} from "../../utils/validators/digitValidator";
import SaveIcon from "@mui/icons-material/Save";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import {maxNumber100Validator} from "../../utils/validators/maxNumber100Validator";
import {minVal60Validator} from "../../utils/validators/minVal60Validator";
import {ILocation} from "../../models/entities/ILocation";
import {ListItemProps, MultiSelectChangeEvent} from "@progress/kendo-react-dropdowns";
import {IStaff} from "../../models/entities/IStaff";
import {resetState} from "../../store/changePassword";
import {resetAppointmentState} from "../../store/calendar";
import {KeyValueMultiselect} from "../common/KeyValueMultiselect";
import {apiPath} from "../../store/api/api";
import {parseInt} from "lodash";
import {IType} from "../../models/entities/types/IType";
import {maxNumber24Validator} from "../../utils/validators/maxNumber24Validator";
import CustomDatePicker from "../common/CustomDatePicker";
import {formatDateForApi, toDateFromApi} from "../../utils/dateHelper";
import { KeyValueMultiselectEvo } from "components/common/KeyValueMultiSelectEvo";
//import { useCurrentExperience } from "../../utils/hooks/currentExperience";

const ExperienceForm = (props: EditFormProps<IExperience>) => {
  const currentPlanner = useCurrentPlanner();
  const [
    addExperience,
    {
      data: addedExperience,
      isLoading: isAdding,
      isSuccess: isAddExperienceSuccess,
    },
  ] = useAddExperienceMutation();
  const [updateExperience, { isLoading: isUpdating }] =
    useUpdateExperienceMutation();
  const currentExperience = useCurrentExperience();

  const { isFetching } = useGetExperienceQuery(props.item.id ?? skipToken);
  const { user } = useAppSelector((store) => store.auth);
  const { customertargettypes,difficultylevels } = useAppSelector((store) => store.types);
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const [item, setItem] = useState(currentExperience ?? props.item);


  /**
   * modifica necessaria,
   * inizialmente le locations erano una multiselect, quindi un array di oggetti e il server le gestisce tutt'ora così
   * mi arrivano N location, di cui io prendo ed uso la prima, dopo un po' di tempo da questa modifica è molto improbabile che l'array contenga + di una location.
   */
  const [locations, setLocations] = useState<ILocation>();
  useEffect(()=>{
    if(currentExperience && currentExperience.locations){
      setLocations(currentExperience.locations[0]);
    }else if(props.item.locations && props.item.locations[0]) {
      setLocations(props.item.locations[0]);
    }

  },[currentExperience,props.item.locations])


  const [staffs, setStaffs] = useState<IStaff[]>(currentExperience?.staffs ?? props.item.staffs);
  const [locationsLength, setLocationsLength] = useState((currentExperience?.locations?.length ?? props.item.locations?.length) || 0);

  const [durataErrore , setDurataErrore] = useState<boolean>(item.length_minutes !== undefined ? ((item.length_minutes as number) >= 60 ? false : true) : false);
  const [preavvisoErrore , setPreavvisoErrore] = useState(false);
  const [preavvisoAmpio , setPreavvisoAmpio] = useState<number>((item.refund_hour_limit1 as number) || 0);
  const [preavvisoStretto , setPreavvisoStretto] = useState<number>((item.refund_hour_limit2 as number) || 0);

  const { prenotationtypes ,   days} = useAppSelector((store) => store.types);

  const [timesmultiselect, setTimesMultiselect] = useState<IType[] | undefined>(undefined);

  const [timesAva, setTimesAva] = useState(currentExperience?.times_ava ?? props.item.times_ava);
  const [daysAva, setDaysAva] = useState((currentExperience?.days_ava ?? props.item.days_ava));
  const token = localStorage.getItem("token");
  const get_path = apiPath + "schedule/planner";

  const [startRange, setStartRange] = useState<string | Date>();
  const [endRange, setEndRange]     = useState<string | Date>();
  const [rangeValid, setRangeValid] = useState(false);

  const [customerTargetTypes, setCustomerTargetTypes]  = useState(currentExperience?.customer_target_types ?? props.item.customer_target_types);
  const [difficultyLevel, setDifficultyLevel]  = useState<string|null>(currentExperience?.difficulty_level ?? props.item.difficulty_level)


  //*RIMOSSO PERIODO DI VALIDITA' 26/02/2024
  // useEffect(() => {
  //   if(currentExperience!==undefined) {
  //     let todayYear = (new Date()).getFullYear();
  //     if (
  //         currentExperience.valid_period_start !== undefined &&   currentExperience.valid_period_start !== null) {
  //
  //       setStartRange(toDateFromApi(todayYear+""+(currentExperience?.valid_period_start as string)));
  //     }
  //     if (currentExperience.valid_period_end !== undefined && currentExperience.valid_period_end !== null    ) {
  //
  //       setEndRange(toDateFromApi(todayYear+""+(currentExperience?.valid_period_end as string)));
  //     }
  //   }
  // }, [currentExperience]);


  const header_get =  {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }

  const  optObjHeadBody = {
    method: 'GET',
    headers:header_get,

  };

  useEffect(()=>{
    if(props.timesCombo !== undefined){
      setTimesMultiselect(props.timesCombo);
    }
  },[props.timesCombo])


  useEffect(()=>{

    if(timesmultiselect=== undefined) {
      fetch(get_path + `/getTimesMultiselect/${currentPlanner?.id}`, optObjHeadBody)
          .then(res => res.json())
          .then(
              (result) => {
                if (result.schedules !== undefined) {
                  setTimesMultiselect(result.schedules);
                }
              },
              // Nota: è importante gestire gli errori qui
              // invece di un blocco catch() in modo da non fare passare
              // eccezioni da bug reali nei componenti.
              (error) => {
                // setIsLoaded(true);
                // setError(error);
              }
          );
    }
  },[])


  const [valuePrenotationT, setValuePrenotationT] = useState<string|undefined>(currentExperience?.booking_mode);

  const handleSubmit = (event: IExperience) => {

    if(!event.buffer_minutes_before){
      event.buffer_minutes_before = 0;
    }
    if(!event.buffer_minutes_after){
      event.buffer_minutes_after = 0;
    }
    if(!errorMinPax) {
      event.booking_mode = valuePrenotationT;


      //*RIMOSSO PERIODO DI VALIDITA' 26/02/2024 *
      // if(startRange !== undefined  && startRange !== null){
      //   event.valid_period_start  = formatDateForApi(startRange as Date).substr(4,4);
      // }else{
      //   event.valid_period_start  = "";
      // }
      //
      // if(endRange !== undefined && endRange !== null){
      //   event.valid_period_end  =formatDateForApi(endRange as Date).substr(4,4);
      // }else{
      //   event.valid_period_end  = "";
      // }



      /**
       * modifica necessaria,
       * inizialmente le locations erano una multiselect, quindi un array di oggetti e il server le gestisce tutt'ora così
       * a posteriori si è deciso di renderla monoscelta, quindi devo rendere quell'unica location un array di lunghezza 1.
       */
      let tmpLoc : ILocation[] = [];
      tmpLoc.push(locations as unknown as ILocation);
      event.locations =tmpLoc;


      event.days_ava = (daysAva)? daysAva.map((item: any) =>{
        if(item.key!== undefined && typeof item !== "number") {
        return item.key
        }else{
          return item
        }
      }) : [];

      event.times_ava =(timesAva)? timesAva.map((item: any) =>{
        if(item.key!== undefined && typeof item !== "number") {
          return item.key
        }else{
          return item
        }
      }) : [];

      event.customer_target_types =(customerTargetTypes)? customerTargetTypes.map((item: any) =>{
        if(item.key!== undefined && typeof item !== "string") {
          return item.key
        }else{
          return item
        }
      }) : [];

      if(difficultyLevel) event.difficulty_level = difficultyLevel



      //ricontrolliamo valore multiplo di 5 in buffer prima / dopo
      if(event.buffer_minutes_before) {
        let v = parseInt(event.buffer_minutes_before.toString());
        let resto = v % 5;
          v = (resto < 2.5 )?( v - resto) :( v + (5 - resto));
          event.buffer_minutes_before = parseInt(v.toString());
      }
      if(event.buffer_minutes_after) {
        let v = parseInt(event.buffer_minutes_after.toString());
        let resto = v % 5;
          v = resto < 2.5 ? v - resto : v + (5 - resto);
          event.buffer_minutes_after = parseInt(v.toString());
      }

      //ricontrolliamo valore dei rimborsi
      if(!event.refund_perc_limit2){
        event.refund_perc_limit2 = null;
      }

      // if (!event.booking_mode) {
      //   event.booking_mode = "resource" //<= default
      // }
      if (!event.id) {

        if (!event.active) event.active = false;
        if (!event.site_active) event.site_active = false;
        addExperience({
          ...event,
          planner_id: currentPlanner?.id,
        } as IExperienceViewModel);
      } else {
        updateExperience({id: event.id, experience: event as IExperience});
      }
      setItem(event);
      props.onSubmit(event);
      dispatch(resetState());
      dispatch(resetAppointmentState());
    }
  };

  //*RIMOSSO PERIODO DI VALIDITA' 26/02/2024 *
  // const handleChangeStart = ( start : any) =>{
  //   if(endRange !== undefined) {
  //     setRangeValid(!((start.value as Date) < (endRange as Date)))
  //   }else{
  //     setRangeValid(false)
  //   }
  //
  //   setStartRange(start.value as Date);
  // }
  //
  // const handleChangeEnd = ( end : any) =>{
  //   if(startRange !== undefined) {
  //     setRangeValid(!((end.value as Date) > (startRange as Date)))
  //   }else{
  //     setRangeValid(false)
  //   }
  //   setEndRange(end.value as Date);
  // }


  const [errorMinPax, setErrorMinPax] = useState<boolean>(false);
  const [maxPax, setMaxPax] = useState<number>((currentExperience?.customers_max_number !== undefined) ? currentExperience.customers_max_number : 0);
  const [minPax, setMinPax] = useState<number>((currentExperience?.customers_min_number !== undefined) ? currentExperience.customers_min_number : 0);

  useEffect(() => {
    if (isAddExperienceSuccess) {
      dispatch(selectExperience(addedExperience?.id));

      history.push(`${pathname}/prices`);
    }
  }, [isAddExperienceSuccess]);

  useEffect(()=>{
    if(locations !== undefined) {
      setLocationsLength((Object.values(locations)[0] as unknown as ILocation[]).length);
    }
  },[locations])

  useEffect(()=>{
    if(staffs=== undefined){
      setStaffs([]);
    }
  },[staffs])


  return isUpdating || isAdding || isFetching  ? (
    <CenteredLoadingSpinner />
  ) : (
    <Form
      onSubmit={(e) => handleSubmit(e as IExperience)}
      initialValues={item}
      render={(formRenderProps) => (
        <FormElement style={{ maxWidth: "100vw" }}>
          <Grid container alignItems="stretch" spacing={3} className={"bottom-spacing experience-form-container"}>
            <Grid item xs={12} sm={6} md={6} lg={6} className="rows-grandi">
              <Field
                name={"name"}
                required
                component={ValidatedInput}
                label={"Titolo"}
                validator={[requireValidator]}
              />
            </Grid>
             {/*<Grid item xs={3}></Grid>*/}
            <Grid
                item xs={12} sm={6} md={6}
                lg={6}
                container
            >
              <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  style={{textAlign: "center"}}
                  className={"attiva-switch"}
              >
                <Field

                    name={"active"}
                    component={ValidatedSwitch}
                    label={"Attiva"}
                    //value={active}
                    defaultChecked={true}
                />
              </Grid>
              <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  style={{textAlign: "center"}}
                  className={"attiva-switch"}
              >
                <Field

                    name={"private"}
                    component={ValidatedSwitch}
                    label={"Privata"}
                    //value={active}
                    defaultChecked={false}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                style={{textAlign: "center"}}
                className={"attiva-switch"}
            >
              <Field

                  name={"monolanguage"}
                  component={ValidatedSwitch}
                  label={"Monolingua"}
                  //value={active}
                  defaultChecked={false}
              />
            </Grid>
            </Grid>
            <Grid item xs={12} className="rows-grandi-5">
              <Field
                multiline
                rows={5}
                name={"description"}
                component={ValidatedInput}
                label={"Descrizione"}
                validator={[]}
              />
            </Grid>


            <Grid item xs={12} sm={6} className="rows-grandi dropdown-multi-container">
              <Field
                required
                name={"staffs"}
                label={"Personale abilitato *"}
                component={StaffMultiselect}
                value={item.staffs}
                validator={[requireValidator]}
                onChange={(e)=> {
                  setStaffs(e.value)
                }}
              />
              {staffs?.length === 0 && <Error>Inserire almeno una persona abilitata</Error>}
            </Grid>
            <Grid item xs={12} sm={6} >
              <Field
                  name={"booking_mode"}
                  label={"Prenotazioni da nowmyplace.com"}
                  className={"custom-unif-font-2"}
                  component={ValidatedDropDownListPrenTypes}
                  onChange={(event: any)=>{
                    setValuePrenotationT(event.value.key);
                  }}
                  data={prenotationtypes}
                  value={valuePrenotationT}
              />
            </Grid>
            <Grid item xs={12} className="rows-grandi dropdown-multi-container">
              <Field
                required
                name={"locations"}
                displError={locationsLength}
                label={"Sito in cui può essere svolta l’esperienza *"}
                component={LocationsMultiselect}
                onChange={(e)=>setLocations(e.value)}
                value={locations}
                validator={[requireValidator]}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} className={"rows-grandi"}>

              <Field
                  required
                  name={"length_minutes"}
                  value={item.length_minutes}
                  component={ValidatedInput}
                  label={"Durata"}
                  defaultVal={""}
                  minVal={60}
                  onChange={(e)=>{

                    if(parseInt(e.target.value) >= 60) {
                      setDurataErrore(false);
                    }else{

                      setDurataErrore(true);
                    }

                  }}
                  iconText={"access_time_filled"}
                  adornment={"minuti"}
                  validator={[requireValidator, digitValidator, minVal60Validator]}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} className={"rows-grandi"}>
              <Field
                name={"buffer_minutes_before"}
                value={item.buffer_minutes_before}
                component={ValidatedInput}
                label={"Buffer prima"}
                adornment={"minuti"}
                validator={[digitValidator]}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} className={"rows-grandi"}>
              <Field
                name={"buffer_minutes_after"}
                value={item.buffer_minutes_after}
                component={ValidatedInput}
                label={"Buffer dopo"}
                adornment={"minuti"}
                validator={[digitValidator]}
              />
            </Grid>

            {/*SPAZIO TRA RIGHE*/}
            {/*RIMOSSO PERIODO DI VALIDITA' 26/02/2024 */}
            {/*<Grid container item xs={12} ></Grid>*/}
            {/*<Grid   container item spacing={1} className={"custom-section-forms-container"}>*/}
            {/*<Typography variant="h6" style={{fontWeight:"bold", paddingLeft:"4px"}} >Periodo di validità</Typography>*/}
            {/*  <BaseButton*/}
            {/*      className={"left-spacing-10p custom-unif-viola-back"}*/}
            {/*      variant="contained"*/}
            {/*      onClick={()=>{*/}
            {/*        setStartRange(undefined);*/}
            {/*        setEndRange(undefined);*/}
            {/*        formRenderProps.onChange("valid_period_start", {*/}
            {/*          value:undefined,*/}
            {/*        });*/}
            {/*        formRenderProps.onChange("valid_period_start", {*/}
            {/*          value:undefined,*/}
            {/*        });*/}
            {/*      }}*/}
            {/*  >*/}
            {/*    Reset*/}
            {/*  </BaseButton>*/}
            {/*<Grid*/}
            {/*    container*/}
            {/*    spacing={2}*/}
            {/*    alignItems="stretch"*/}
            {/*    className="custom-section-forms-exp"*/}
            {/*>*/}
            {/*  <Grid item xs={12} sm={12} md={6} lg={6}>*/}
            {/*    <div className={ "std-date-picker-container dropdownModifica custom-unif-dropdown-focus"} >*/}

            {/*      <label*/}
            {/*          className="labelModifica std-date-picker-label"*/}
            {/*          data-shrink="true"*/}
            {/*      >*/}
            {/*        Dal*/}
            {/*      </label>*/}
            {/*      <div className=" spanFiglia-daIngrandire std-date-picker-component">*/}
            {/*        <CustomDatePicker*/}
            {/*            name={"valid_period_start"}*/}
            {/*            value={startRange}*/}
            {/*            format="dd/MM"*/}
            {/*            onChange={(e: any) => {*/}
            {/*              handleChangeStart(e);*/}
            {/*              formRenderProps.onChange("valid_period_start", {*/}
            {/*                value: startRange,*/}
            {/*              });*/}
            {/*            }}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </Grid>*/}
            {/*  <Grid item xs={12} sm={12} md={6} lg={6}>*/}
            {/*    <div className={ "std-date-picker-container dropdownModifica custom-unif-dropdown-focus"} >*/}

            {/*      <label*/}
            {/*          className="labelModifica std-date-picker-label"*/}
            {/*          data-shrink="true"*/}
            {/*      >*/}
            {/*        Al*/}
            {/*      </label>*/}
            {/*      <div className=" spanFiglia-daIngrandire std-date-picker-component">*/}
            {/*        <CustomDatePicker*/}
            {/*            name={"valid_period_end"}*/}
            {/*            value={endRange as Date}*/}
            {/*            format="dd/MM"*/}
            {/*            onChange={(e: any) => {*/}
            {/*              handleChangeEnd(e);*/}
            {/*              formRenderProps.onChange("valid_period_end", {*/}
            {/*                value: endRange,*/}
            {/*              });*/}

            {/*            }}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    {rangeValid && <Error>La data di fine deve essere successiva alla data iniziale</Error>}*/}
            {/*</Grid>*/}
            {/*</Grid>*/}
            {/*</Grid>*/}
            {/*SPAZIO TRA RIGHE*/}

            <Grid container item xs={12} ></Grid>
            <Grid container item spacing={1} className={"custom-section-forms-container"}>
            <Typography variant="h6" style={{fontWeight:"bold", paddingLeft:"4px"}} >Suggerimenti e limitazioni</Typography>
            <Grid
              container
              spacing={2}
              alignItems="stretch"
              className="custom-section-forms-exp"
            >
              {/* TODO: tooltip non si vede */}
              <Grid item xs={12} sm={12} md={6} lg={6} className={"rows-grandi dropdown-multi-container"}>
                <Field
                    name={"customer_target_types"}
                    label={"Ideale per"}
                    data={customertargettypes}
                    onChange={(e)=>{
                      setCustomerTargetTypes(e.value)
                    }}
                    component={KeyValueMultiselect}
                    values={customerTargetTypes}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} className={"rows-grandi dropdown-multi-container"}>
                <Field
                    name={"difficulty_level"}
                    label={"Livello di difficoltà"}
                    data={difficultylevels}
                    onChange={(e)=>{
                      setDifficultyLevel(e.value.key)
                    }}
                    component={ValidatedDropDownListKeyLabelTypes_customBehavior}
                    value={difficultyLevel}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className={"rows-grandi dropdown-multi-container"}>
                <Field
                  name={"customer_exclusion"}
                  label={"Categorie escluse"}
                  component={ExclusionsMultiselect}
                  value={item.customer_exclusion}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>

                <Field
                    title={"Preavviso minimo con cui accetto di ricevere la prima prenotazione ad un appuntamento"}
                    name={"pre_booking_hours"}
                    component={ValidatedInput}
                    validator={[digitValidator]}
                    label={"Preavviso minimo lungo"}
                    adornment={"ore"}
                    minVal={0}
                    value={item?.pre_booking_hours}
                    defaultVal={item?.pre_booking_hours}

                />

              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>

                <Field
                    name={"pre_booking_hours_planned"}
                    component={ValidatedInput}
                    label={"Preavviso minimo breve"}
                    title={"Preavviso minimo con cui accetto di ricevere nuove iscrizioni ad un appuntamento presente in agenda e che conta già uno o più iscritti"}
                    validator={[digitValidator,maxNumber24Validator]}
                    adornment={"ore"}
                    minVal={0}
                    maxVal={24}
                    value={item?.pre_booking_hours_planned}
                    defaultVal={(item?.pre_booking_hours_planned)? item?.pre_booking_hours_planned : 0 }
                />

              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} className={"rows-grandi"} >

                <Field
                    minVal={0}
                    InputProps={{ inputProps: { min: 0 } }}
                    name={"customers_min_number"}
                    component={ValidatedInput}
                    label={"Min pax"}
                    defaultVal={""}
                    onChange={(e)=> {
                      setMinPax(parseInt(e.target.value));
                      setErrorMinPax(parseInt(e.target.value) > maxPax);

                    }}

                    validator={[digitValidator, requireValidator]}
                    required
                />
                {errorMinPax && <Error>Il min pax deve essere minore di max pax</Error>}
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} className={"rows-grandi"}>
                <Field
                  name={"age_min"}
                  component={ValidatedInput}
                  validator={[digitValidator]}
                  label={"Età minima"}
                  defaultVal={""}
                  adornment={"anni"}
                  minVal={0}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} className={"rows-grandi"}>
                <Field
                  name={"weight_min"}
                  component={ValidatedInput}
                  validator={[digitValidator]}
                  label={"Peso minimo"}
                  defaultVal={""}
                  adornment={"kg"}
                  min={0}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} className={"rows-grandi"}>
                <Field
                  name={"height_min"}
                  component={ValidatedInput}
                  validator={[digitValidator]}
                  label={"Altezza minima"}
                  adornment={"cm"}
                  defaultVal={""}
                  min={0}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} className={"rows-grandi"}>
                <Field
                    minVal={1}
                    InputProps={{ inputProps: { min: 1 } }}
                    name={"customers_max_number"}
                    component={ValidatedInput}
                    validator={[requireValidator, digitValidator]}
                    label={"Max pax"}
                    defaultVal={" "}
                    onChange={(e)=> {

                        setErrorMinPax(parseInt(e.target.value) < minPax);
                        setMaxPax(parseInt(e.target.value));
                    }}
                    required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} className={"rows-grandi"}>
                <Field
                  name={"age_max"}
                  component={ValidatedInput}
                  validator={[maxNumber100Validator, digitValidator]}
                  label={"Età massima"}
                  defaultVal={" "}

                  adornment={"anni"}
                  min={0}
                  maxVal={100}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} className={"rows-grandi"}>
                <Field
                  name={"weight_max"}
                  component={ValidatedInput}
                  validator={[digitValidator]}
                  label={"Peso massimo"}
                  adornment={"kg"}
                  defaultVal={" "}
                  maxVal={180}
                  min={0}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} className={"rows-grandi"}>
                <Field
                  name={"height_max"}
                  component={ValidatedInput}
                  validator={[digitValidator]}
                  label={"Altezza massima"}
                  adornment={"cm"}
                  defaultVal={" "}
                  maxVal={200}
                  min={0}
                />
              </Grid>
            </Grid>
            </Grid>
            {/*SPAZIO TRA RIGHE*/}
            <Grid container item xs={12} ></Grid>
            <Grid   container item spacing={1} className={"custom-section-forms-container"}>
            <Typography variant="h6" style={{fontWeight:"bold", paddingLeft:"4px"}}>Policy di cancellazione</Typography>
            <Grid   container
                    alignItems="stretch"
                    spacing={2}
                    className="custom-section-forms-exp">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                    name={"refund_hour_limit1"}
                    component={ValidatedInput}
                    validator={[digitValidator]}
                    label={"Preavviso ampio"}


                    onChange={(e)=>{

                      if(parseInt(e.target.value) >= preavvisoStretto) {
                        setPreavvisoErrore(false);
                      }else{

                        setPreavvisoErrore(true);
                      }

                      setPreavvisoAmpio(parseInt(e.target.value));
                    }}
                    required
                    maxVal={168}
                    adornment={"ore"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field

                    required
                    name={"refund_perc_limit1"}
                    component={ValidatedInput}
                    validator={[digitValidator]}
                    label={"Rimborso preavviso ampio"}
                    adornment={"%"}
                    value={item.refund_perc_limit1}

                    maxVal={100}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className={"rows-grandi"}>
                <Field
                    defaultVal={null}
                    showError={preavvisoErrore}
                    name={"refund_hour_limit2"}
                    component={ValidatedInput}
                    validator={[digitValidator]}
                    label={"Preavviso stretto"}
                    onChange={(e) => {


                      if(parseInt(e.target.value) >= preavvisoAmpio){
                        setPreavvisoErrore(true);
                      }else{

                        setPreavvisoErrore(false);
                      }

                      setPreavvisoStretto(parseInt(e.target.value));


                    }}
                    min={0}
                    adornment={"ore"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                    name={"refund_perc_limit2"}
                    component={ValidatedInput}
                    validator={[digitValidator]}
                    value={item.refund_perc_limit2}
                    label={"Rimborso preavviso stretto"}
                    defaultVal={null}
                    adornment={"%"}
                    maxVal={100}
                />
              </Grid>


            </Grid>

            </Grid>
            <Grid container item xs={12} ></Grid>
            <Grid item xs={12} className="rows-grandi-3" style={{paddingRight: "16px"}}>
              <Field
                  multiline
                  rows={3}
                  name={"note"}
                  component={ValidatedInput}
                  label={"Note"}
                  validator={[]}
              />
            </Grid>

            {/*{!currentExperience?.isAvailabilityCustom && <Grid item xs={12} sm={6} className="rows-grandi  dropdown-multi-container">*/}

            {/*      <Field*/}
            {/*          required*/}
            {/*          name={"times_ava"}*/}
            {/*          label={"Inizia solo alle"}*/}
            {/*          component={KeyValueMultiselect}*/}
            {/*          onChange={(e) => {*/}

            {/*            setTimesAva(e.value)*/}
            {/*          }}*/}
            {/*          values={item.times_ava}*/}
            {/*          data={timesmultiselect}*/}
            {/*      />*/}
            {/*</Grid>}*/}
            {/*{!currentExperience?.isAvailabilityCustom && <Grid item xs={12} sm={6} className="rows-grandi dropdown-multi-container">*/}

            {/*  <Field*/}
            {/*      required*/}
            {/*      name={"days_ava"}*/}
            {/*      label={"Disponibile solo nei giorni di"}*/}
            {/*      component={KeyValueMultiselect}*/}
            {/*      onChange={(e)=>setDaysAva(e.value)}*/}
            {/*      values={item.days_ava}*/}
            {/*      data={days}*/}
            {/*  />*/}
            {/*</Grid>}*/}

          </Grid>
          <Grid container justifyContent="center">
            <div className="k-form-buttons">
              <BaseButton
                className={` ${
                  (formRenderProps.allowSubmit && !preavvisoErrore && !durataErrore 
                       && (locationsLength!==0) && (  staffs?.length !== 0)
                  )? "custom-unif-viola-back" : ""
                }`}
                type={"submit"}
                variant="contained"
                disabled={!formRenderProps.allowSubmit || preavvisoErrore || durataErrore
                 ||  locationsLength===0 || staffs?.length === 0
                }
                startIcon={<SaveIcon />}
              >
                Salva
              </BaseButton>
              {props.hasCancel && (
                <BaseButton
                    disabled={!formRenderProps.allowSubmit || preavvisoErrore || durataErrore
                     ||  locationsLength===0 ||  staffs?.length === 0
                    }
                    className={`custom-button-annulla custom-unif-grigio-back left-spacing-10p ${
                        (formRenderProps.allowSubmit  && !preavvisoErrore && !durataErrore 
                             && (locationsLength!=0)  && (  staffs?.length !== 0)
                        )
                            ? "custom-unif-arancione-back"
                            : ""
                    }`}

                    onClick={props.cancelEdit}
                    startIcon={<CancelPresentationIcon />}
                >Annulla</BaseButton>
              )}

              {formRenderProps.visited &&
                formRenderProps.errors &&
                formRenderProps.errors.VALIDATION_SUMMARY && (
                  <div className={"k-messagebox k-messagebox-error"}>
                    <Error>{formRenderProps.errors.VALIDATION_SUMMARY}</Error>
                  </div>
                )}
            </div>
          </Grid>
        </FormElement>
      )}
    />
  );
};
export default ExperienceForm;
