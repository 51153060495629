import {api} from "./api";
import {ISubscriber} from "../../models/entities/calendar/ISubscriber";
import {IGetSubscribersDto} from "../../models/dto/subscribers/IGetSubscribersDto";
import {IGetSubscriberDto} from "../../models/dto/subscribers/IGetSubscriberDto";
import {IAddSubscriberDto} from "../../models/dto/subscribers/IAddSubscriberDto";
import {IUpdateSubscriberDto} from "../../models/dto/subscribers/IUpdateSubscriberDto";

export const subscribersApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getSubscribers: build.query<ISubscriber[], number>({
      query: (appointmentId) => ({
        url: `subscriber`,
        params: { 'appointment_id' : appointmentId }
      }),
      transformResponse: (res: IGetSubscribersDto, meta) => res.subscribers,
      providesTags: result => result ? [...result.map(r => ({ type: 'Subscribers', id: r.id } as const)), { type: 'Subscribers', id: 'LIST'}] : [{ type: 'Subscribers', id: 'LIST '}]
    }),
    getSubscriber: build.query<ISubscriber, number>({
      query: id => `subscriber/${id}`,
      transformResponse: (res: IGetSubscriberDto, meta) => res.subscriber,
      providesTags: (result, error, id) => [{ type: 'Subscribers', id }]
    }),
    addSubscriber: build.mutation<ISubscriber, ISubscriber>({
      query: (subscriber) => ({
        url: 'subscriber',
        method: 'POST',
        body: subscriber
      }),
      transformResponse: (res: IAddSubscriberDto, meta) => res.subscriber,
      invalidatesTags: (result, error ) => error ? [] : [{ type: 'Subscribers', id: 'LIST' }],
    }),
    updateSubscriber: build.mutation<ISubscriber, { id: number, subscriber: ISubscriber }>({
      query: ({ id, subscriber }) => ({
        url: `subscriber/${id}`,
        method: 'PUT',
        body: subscriber
      }),
      transformResponse: (res: IUpdateSubscriberDto, meta) => res.subscriber,
      invalidatesTags: (result, error, { id }) => error ? [] :  [{ type: 'Subscribers', id }],
      async onQueryStarted({id, ...subscriber}, {dispatch, queryFulfilled}) {
        const putResult = dispatch(
          subscribersApi.util.updateQueryData('getSubscriber', id, (draft) => {
            Object.assign(draft, subscriber)
          })
        )
        try {
          await queryFulfilled
        } catch {
          putResult.undo()
        }
        // queryFulfilled.catch(putResult.undo)
      },
    }),
    deleteSubscriber: build.mutation<void, number>({
      query: (id) => ({
        url: `subscriber/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id ) => error ? [] :  [{type: 'Subscribers', id}],
    })
  })
})

export const {
  useGetSubscriberQuery,
  useGetSubscribersQuery,
  useAddSubscriberMutation,
  useDeleteSubscriberMutation,
  useUpdateSubscriberMutation,
} = subscribersApi