import { addMinutes } from "date-fns";
import { IAppointment } from "models/entities/calendar/IAppointment";
import { IExperience } from "models/entities/IExperience";
import {
  dateToStringYYYYMMDD,
  timeToStringHHMMwithsepator
} from "./dateHelper";

export function createAppointmentFromSchedule(schedule: any): IAppointment {
  let bt = new Date(schedule.start)
  //bt=addMinutes(bt,-120);
  let et = new Date(schedule.end)
  //et=addMinutes(et,-120);

  let a = {} as IAppointment;
  a.task_id = undefined;
  a.selected_staff_id = schedule.selected_staff_id;
  a.staff_id = schedule.staff_id;
  a.staff_id_list = [];
  a.length_minutes = 15;
  a.experience_id = undefined;
  a.experience_name = "";
  a.experience_note = "";
  a.experience_monolanguage = false;
  a.experience_customers_max_number  = 0;
  a.experience_customer_exclusion = [];
  a.experience_age_min = 0;
  a.experience_age_max = 100;
  a.experience = {} as IExperience;
  a.location_id = undefined;
  a.number_partecipants = 0;
  a.calendar_date = dateToStringYYYYMMDD(bt);
  a.start = bt.toUTCString();
  a.begin_time = timeToStringHHMMwithsepator(bt);
  a.startTimezone = "Europe/Rome";
  a.end = et.toUTCString();
  a.end_time = timeToStringHHMMwithsepator(et);
  a.endTimezone = "Europe/Rome";
  a.isAllDay = false;
  a.recurrenceRule = schedule.recurrenceRule;
  a.recurrenceId = schedule.recurrenceId;
  a.recurrenceExceptions = schedule.recurrenceExceptions;
  a.subscribers = [];

  return a;
}

export function addExperienceToAppointment(appointment: IAppointment, experience: IExperience): IAppointment {
  let app = {
    ...appointment,
    experience: experience,
    experience_age_max: experience.age_max,
    experience_age_min: experience.age_min,
    experience_customer_exclusion: experience.customer_exclusion,
    experience_id: experience.id,
    experience_customers_max_number : experience.customers_max_number,
    experience_name: experience.name,
    experience_note: experience.note,
    experience_monolanguage: experience.monolanguage,
    length_minutes: experience.length_minutes,
    end: addMinutes(appointment.start as Date, experience.length_minutes),
    //calendar_date: dateToStringDDMMYYYYwithsepator(appointment.start as Date),
    //begin_time: timeToStringDDMMYYYYwithsepator(appointment.start as Date),
    end_time: timeToStringHHMMwithsepator(addMinutes(appointment.start as Date, experience.length_minutes))
  };
  return app;
}

export function changeReferentForAppointment(appointment: IAppointment, staffId: number): IAppointment {
  let app = {
    ...appointment,
    staff_id: staffId
  };
  return app;
}

export function changeStartTimeAppointment(appointment: IAppointment, date: Date): IAppointment {
  let app = {
    ...appointment,
    start: date,
    end: addMinutes(date, appointment?.length_minutes ? appointment?.length_minutes : 15),
    calendar_date: dateToStringYYYYMMDD(appointment.start as Date),
    begin_time: timeToStringHHMMwithsepator(appointment.start as Date),
    end_time: timeToStringHHMMwithsepator(addMinutes(date, appointment?.length_minutes ? appointment?.length_minutes : 15))
  };
  return app;
}

export function changeLengthAppointment(appointment: IAppointment, length: number): IAppointment {
  let app = {
    ...appointment,
    length_minutes: length,
    end: addMinutes(appointment.start as Date, length),
    //calendar_date: dateToStringDDMMYYYYwithsepator(appointment.start as Date),
    //begin_time: timeToStringDDMMYYYYwithsepator(appointment.start as Date),
    end_time: timeToStringHHMMwithsepator(addMinutes(appointment.start as Date, length))
  };
  return app;
}

export function changeEndTimeAppointment(appointment: IAppointment, date: Date): IAppointment {
  let app = {
    ...appointment,
    end: date,
    length_minutes: ((appointment.end as Date).getTime() - (appointment.start as Date).getTime()) / 60000,
    end_time: timeToStringHHMMwithsepator(date)
  };
  return app;
}
