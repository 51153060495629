import { useGetStaffsQuery } from "store/api/staff";
import { useAppSelector } from "../../store";
import { useCurrentPlanner } from "./currentPlanner";

export function useCurrentStaff() {
  const currentPlanner = useCurrentPlanner();
  const id = useAppSelector((s) => s.staff.currentStaffId);
  const { data: staffs = [] } = useGetStaffsQuery(currentPlanner?.id as number);

  return staffs.find((p) => p.id === id);
}


export function useCurrentStaffInCalendarDay() {
  const currentPlanner = useCurrentPlanner();
  const id = useAppSelector((s) => s.staff.currentStaffId);
  const { data: staffs = [] } = useGetStaffsQuery(currentPlanner?.id as number);

  return staffs.find((p) => p.id === id);
}