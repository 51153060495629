import {Fragment} from "react";
import {IUser} from "../../models/entities/IUser";
import CenteredLoadingSpinner from "../../components/common/CenteredLoadingSpinner";
import GenericPagedGrid from "../../components/common/GenericPagedGrid";
import UserForm from "components/users/UserForm";
import {useDeleteUserMutation, useGetUsersQuery} from "store/api/users";

const UsersPage = () => {
  const {
    data: users = [],
    isLoading,
  } = useGetUsersQuery();
  const [deleteUser ] = useDeleteUserMutation();

  const remove = (dataItem: IUser) => {
    deleteUser(dataItem.id as number);
  };


  return (
    <div style={{ padding: "2.5vh" }} className={"users_grid"}>
      {isLoading ? (
        <CenteredLoadingSpinner />
      ) : (
        <Fragment>
              <GenericPagedGrid
              icon="group" title="Utenti"
              itemsPage={30}
                  items={users}
                  columns={[
                   // { field: "name", title: "Nome" },
                    // { field: "surname", title: "Cognome"},
                    { field: "fullname", title: "Nome utente" },
                    { field: "username", title: "Username"},
                    { field: "role", title: "Ruolo" },
                    { field: "company_name", title: "Azienda"},
                    {
                      field: "enabled",
                      title: "Attivo",


                    },
                    // { field: "impersonate", title: "Login", width: 90 },
                  ]}
                  enableCancel={true}
                  onRemove={remove}
                  addFormComponent={UserForm}
                  updateFormComponent={UserForm}
              />

        </Fragment>
      )}
    </div>
  );
};

export default UsersPage;
