import {SchedulerSlot} from "@progress/kendo-react-scheduler";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store";
import {SchedulerSlotMouseEvent} from "@progress/kendo-react-scheduler/dist/es/models";
import {setSchedulerDay, setSchedulerView} from "../../store/schedulerView";
import {dateToStringYYYYMMDD, formatDateForApi} from "../../utils/dateHelper";
import {SchedulerSlotProps} from "@progress/kendo-react-scheduler/dist/npm/slots/SchedulerSlot";
import {GridLayout, GridLayoutItem} from "@progress/kendo-react-layout";
import {SCREEN_LG, SCREEN_XL, useWindowSize} from "../common/ScreenSizes";
import GroupIcon from '@mui/icons-material/Group';
import PieChartIcon from '@mui/icons-material/PieChart';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import EventNoteIcon from '@mui/icons-material/EventNote';

const formatForCss = (workload: number | undefined) =>{
    if(workload) {
        if(workload === 0){
            return 'white'
        }else if (workload > 0 && workload <25) {
            return 'light-green'
        }else if (workload >= 25 && workload <75) {
            return 'light-yellow'
        }else if (workload >= 75) {
            return  'light-red'
        }
    }else{
        return 'white'
    }
}


const SquareGapRow = 10
const SquareGapCols = 25
export const CustomSchedulerSlotMonth = function (props: SchedulerSlotProps) {

    const size = useWindowSize();
    const dispatch = useAppDispatch();
    const [SquareWidth, SetSquareWidth] = useState<string>( '35px')
    const [SquareHeight,SetSquareHeight] =useState<string>( '22px');

    const MonthInfo = useAppSelector((state)=>state.schedulerView.dayInfo)

    const DayInfo = MonthInfo.find((el)=> el.datestring === formatDateForApi(props.start));
    const workload = formatForCss(MonthInfo.find((el)=> el.datestring === formatDateForApi(props.start))?.percentage);


    useEffect(()=>{
        if(size) {
            if (size > SCREEN_XL ){
                SetSquareWidth('60px')
                SetSquareHeight('28px')
            }else{
                SetSquareWidth('50px')
                SetSquareHeight('30px')
            }

        }
    },[size]);

    const SlotContent = () =>{

        if(DayInfo && !DayInfo.closed) {
            return (
                <GridLayout
                    gap={{rows: SquareGapRow, cols: SquareGapCols}}
                    rows={size <= SCREEN_LG ?  [{height: SquareHeight}, {height: SquareHeight}, {height: SquareHeight}, {height: SquareHeight}, {height: SquareHeight}] :
                        [{height: SquareHeight}, {height: SquareHeight}, {height: SquareHeight}]}
                    cols={size <= SCREEN_LG ?[{width: SquareWidth}]:[{width: SquareWidth}, {width: SquareWidth}]}
                    align={{horizontal: "stretch"}}
                >
                    <GridLayoutItem row={1} colSpan={1} className={"day-date-slot"}>
                        <span className={'slot-day-circle'}>{props.start.getDate()}</span>
                    </GridLayoutItem>
                    <GridLayoutItem row={2} colSpan={1} className={"day-info-slot"} >
                       <span title={'Percentuale di sfruttamento della capacità produttiva'}>
                           <PieChartIcon className={'icon-inside-day-slot'}  fontSize={'small'}/> {DayInfo.percentage}
                           {/*%*/}
                       </span>
                    </GridLayoutItem>
                    <GridLayoutItem row={size <= SCREEN_LG ? 3 : 2} colSpan={1} className={"day-info-slot"}>
                         <span title={'Numero totale di prenotazioni'}>
                            <BookmarksIcon className={'icon-inside-day-slot'} fontSize={'small'}/> {DayInfo.prenotation_number}
                             {/*Prenotazioni*/}
                         </span>
                    </GridLayoutItem>
                    <GridLayoutItem row={size <= SCREEN_LG ?4 :3} colSpan={1} className={"day-info-slot"}>
                           <span title={'Numero totale di slot con almeno una prenotazione'}>
                        <EventNoteIcon className={'icon-inside-day-slot'} fontSize={'small'}/> {DayInfo.slot}
                               {/*Slot*/}
                         </span>
                    </GridLayoutItem>
                    <GridLayoutItem row={size <= SCREEN_LG ? 5 : 3} colSpan={1} className={"day-info-slot"}>
                           <span title={'Numero totale di partecipanti'}>
                        <GroupIcon className={'icon-inside-day-slot'} fontSize={'small'}/>  {DayInfo.partecipant_number}
                               {/*Partecipanti*/}
                         </span>
                    </GridLayoutItem>
                </GridLayout>
            );
        }else{
            return (
                <GridLayout
                    gap={{rows: SquareGapRow, cols: SquareGapCols}}
                    rows={size <= SCREEN_LG ?  [{height: SquareHeight}, {height: SquareHeight}, {height: SquareHeight}, {height: SquareHeight}, {height: SquareHeight}] :
                        [{height: SquareHeight}, {height: SquareHeight}, {height: SquareHeight}]}
                    cols={size <= SCREEN_LG ?[{width: SquareWidth}]:[{width: SquareWidth}, {width: SquareWidth}]}
                    align={{horizontal: "stretch"}}
                >
                    <GridLayoutItem row={1} colSpan={1} className={"day-date-slot"}>
                        <span className={'slot-day-circle'}>{props.start.getDate()}</span>
                    </GridLayoutItem>

                </GridLayout>
            );
        }
    }

    return (
        <SchedulerSlot
            {...props}
            className={'scheduler-slot-month work-load-'+(DayInfo && DayInfo.closed ? 'closed': workload)}
            onDoubleClick={(e: SchedulerSlotMouseEvent)=>{
                dispatch(setSchedulerDay(dateToStringYYYYMMDD(props.start)))
                dispatch(setSchedulerView('day'))
            }
            }
            // onDoubleClick={!props.isWorkHour ? () => false : props.onDoubleClick}

        >
            <SlotContent />
        </SchedulerSlot>
    );
};
