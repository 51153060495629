import * as React from "react";
import {Fragment, useEffect, useState} from "react";
import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {Error} from "@progress/kendo-react-labels";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import {IStaff} from "../../models/entities/IStaff";
import Grid from "@mui/material/Grid";
import {
    ValidatedDropDownList,
    ValidatedEmail,
    ValidatedInitialsInput,
    ValidatedInput,
    ValidatedMobile,
    ValidatedMultiSelect,
    ValidatedMultiSelectAgende,
    ValidatedMultiSelectLan,
    ValidatedSwitch,
} from "../common/ValidatedFields";
import {requireValidator} from "../../utils/validators/requireValidator";
import {emailValidator} from "../../utils/validators/emailValidator";
import {useAppDispatch, useAppSelector} from "../../store";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import {ListItemProps, MultiSelectChangeEvent,} from "@progress/kendo-react-dropdowns";

import {Icon} from "@mui/material";
import BaseButton from "components/common/BaseButton";
import {useAddStaffMutation, useGetStaffsQuery, useUpdateStaffMutation,} from "store/api/staff";
import {useGetPlannersQuery} from "store/api/planners";
import {IPlanner} from "models/entities/IPlanner";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {useHistory, useLocation} from "react-router-dom";
import {selectStaff} from "../../store/staff";
import {useCurrentStaff} from "../../utils/hooks/currentStaff";
import {IRoleType} from "models/entities/types/IRoleType";
import SaveIcon from "@mui/icons-material/Save";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import PlannerIcon from "@mui/icons-material/LocalLibrary";

const StaffForm = (props: EditFormProps<IStaff>) => {
  const { languages } = useAppSelector((store) => store.types);
  const currentPlanner = useCurrentPlanner();
  const currentStaff = useCurrentStaff();
  //SORTING
  const languagesSorted =[...languages].sort((a, b) => (a.label!== undefined && b.label!== undefined) ? (a.label > b.label ? 1:-1) : -1);

  const {
    data: planners,
  } = useGetPlannersQuery();
  const {
    data: staffs = [],
  } = useGetStaffsQuery(currentPlanner?.id ?? skipToken);
  const [
    addStaff,
    { data: addedStaff,  isSuccess: isAddStaffSuccess },
  ] = useAddStaffMutation();
  const [updateStaff, ] = useUpdateStaffMutation();
  const history = useHistory();
  const { pathname } = useLocation();
  const { colors, roles } = useAppSelector((store) => store.types);
  const dispatch = useAppDispatch();

  if (
    props.item !== undefined &&
    !props.item.planner_list &&
    currentPlanner &&
    currentPlanner.id
  ) {
    props.item.planner_list = [currentPlanner?.id];
  }
  const [item, setItem] = useState(currentStaff ?? props.item);
  const [valuePlanner, setValuePlanner] = useState(item.planner_list);
  const [valueRoles, setValueRoles] = useState(item.planner_role);

  useEffect(() => {
    if (isAddStaffSuccess) {
      dispatch(selectStaff(addedStaff?.id as number));
      setItem(addedStaff as IStaff);
      history.push(`${pathname}/custom-staff-weekly-timetable`);
    }
  }, [isAddStaffSuccess]);

  const handleSubmit = (event: IStaff) => {
    // setItem(event);
    if (!event.isVisible) event.isVisible = false;
    if (!event.notify) event.notify = false;
    if (!event.id) {
      event.planner_id = currentPlanner?.id;

      addStaff(event as IStaff);
    } else {
      updateStaff({ id: event.id, staff: event as IStaff });
      // console.log("patch ");
    }
    props.onSubmit(event);
  };

  //TODO gestione staff undefined (per ora blanda, se no non potevo testare frontend)

  const usedColors = staffs?.map((item, index) => {
    return item.color;
  });

  const roleItemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const rolekey = itemProps.dataItem;
    const role = roles.find((i) => i.key === rolekey);
    const itemChildren = [
      <Icon className={"role-icon-drowpdown"} color="primary">
        {/* {itemProps.dataItem.icon} */}
        {role?.icon}
      </Icon>,
      <span
          className={"roles-bolder role-label-drowpdown"}
        style={{ paddingLeft: "10px", marginTop: "-5px",}}
      >
        {/* {itemProps.dataItem.label} */}
        {role?.label}
      </span>,
      <span  className={"role-description-drowpdown"}  style={{ paddingLeft: "10px", marginTop: "-5px" }}>
        {/* {itemProps.dataItem.description} */}
        {role?.description}
      </span>,
    ];

    return React.cloneElement(li, li.props, itemChildren);
  };

  const handleRolesChange = (event: MultiSelectChangeEvent) => {
    //console.log(event);
    //roles.find(t=>t.label==event.value)?.key

    setValueRoles(event.value);
  };

  const plannerItemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const plannerId = itemProps.dataItem;
    const itemChildren = [

      <span style={{ paddingLeft: "10px", marginTop: "-5px" }}>
        {(planners as IPlanner[]).find((i) => i.id === plannerId)?.name}
      </span>,
    ];

    return React.cloneElement(li, li.props, itemChildren);
  };

  const languageItemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const languageName = itemProps.dataItem;
    const itemChildren = [
      <span color="primary">
        {languagesSorted.find((i) => i.label === languageName)?.key}
      </span>,
      <span style={{ paddingLeft: "10px", marginTop: "0px" }}>
        {li.props.children}
      </span>,
    ];

    return React.cloneElement(li, li.props, itemChildren);
  };

  const handleLanguagesChange = (event: MultiSelectChangeEvent) => {};

  const handlePlannersChange = (event: MultiSelectChangeEvent) => {
    // console.log(event);
    if (
      currentPlanner &&
      (event.value as number[]).find((t) => t === currentPlanner?.id)
    )
      setValuePlanner(event.value);
    else if (currentPlanner) event.value.push(currentPlanner?.id);
  };


  const colorItemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps,
    color: any
  ) => {
    const colorName = itemProps.dataItem;
    color = colors.find((i) => i.label === colorName)?.hex;
    const countOccurrences = (arr: any, val: any) =>
      arr.reduce((a: any, v: any) => (v === val ? a + 1 : a), 0);
    const used = countOccurrences(usedColors, colorName);
    const itemChildren = [
      <span
        style={{
          display: "inline-block",
          background: color,
          borderRadius: "50%",
          width: "20px",
          height: "20px",
          marginTop: "2px",
          paddingTop: "8px",
          textAlign: "center",
          overflow: "hidden",
        }}
      ></span>,
      <span style={{ paddingLeft: "10px", marginTop: "-5px" }}>
         {li.props.children} <small>({used})</small>
      </span>,
    ];

    return React.cloneElement(li, li.props, itemChildren);
  };

  const plannerValueRender = ( element: React.ReactElement<HTMLSpanElement>,
                               value: any
  ) => {
    if (!value) {
      return element;
    }

    const children = [
      <span
          key={1}
          style={{
            display: "inline",
            // background: color,
            color: "black",
            width: "26px",
            height: "18px",
            textAlign: "left",
            // overflow: "hidden",
            marginLeft: "10px",
            paddingTop: "10px"
          }}
      > <PlannerIcon color="inherit" /></span>,
      <span  style={{
        display: "inline",
        marginLeft: "50px"

      }} key={2}>&nbsp; {element.props.children}</span>,
    ];

    return React.cloneElement(element, { ...element.props }, children);
  };

  const colorValueRender = (
    element: React.ReactElement<HTMLSpanElement>,
    value: any,
    color: any
  ) => {
    if (!value) {
      return element;
    }
    color = colors.find((i) => i.label === value)?.hex;
    const children = [
      <span
        key={1}
        style={{
          display: "inline-block",
          background: color,
          color: "#fff",
          borderRadius: "50%",
          width: "18px",
          height: "18px",
          textAlign: "center",
          overflow: "hidden",
        }}
      ></span>,
      <span key={2}>&nbsp; {element.props.children}</span>,
    ];

    return React.cloneElement(element, { ...element.props }, children);
  };
  const tagRender = (
      tagData: any,
      li: React.ReactElement<
          HTMLLIElement,
          string | React.JSXElementConstructor<any>
          >
  ) =>
      React.cloneElement(li, li.props, [
        <span className="custom-tag">

            <Icon  className={"role-icon"} >{

                        (roles as IRoleType[]).find((t) => t.key === tagData?.data[0])
                            ?.icon
                    }</Icon>
          <span className={"role-label"}>{

            (roles as IRoleType[]).find((t) => t.key === tagData?.data[0])
                ?.label
          }</span>
      </span>,

        li.props.children
      ]);

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={item}
      render={(formRenderProps) => (
        <Fragment>
          <FormElement>
            {/*SPAZIO TRA RIGHE*/}
            <Grid
              container
              alignItems="stretch"
              spacing={3}
              className={"staff-form-media-rule bottom-spacing"}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={5}
                className={"rows-grandi "}
              >
                <Field
                  name={"name"}
                  component={ValidatedInput}
                  label={"Nome"}
                  required
                  validator={[requireValidator]}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={5} className={"rows-grandi"}>
                <Field
                  name={"surname"}
                  component={ValidatedInput}
                  label={"Cognome"}
                  required
                  validator={[requireValidator]}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={2} className={"rows-grandi"}>
                <Field
                  name={"initials"}
                  component={ValidatedInitialsInput}
                  label={"Nome breve"}
                  required
                  validator={[requireValidator]}
                  maxlenght="15"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={"rows-grandi dropdown-multi-container"}
              >
                <Field
                  name={"planner_role"}
                  required
                  className={"roles-dropw custom-unif-font"}
                  component={ValidatedMultiSelect}
                  label={"Ruoli *"}
                  data={roles.filter((t) => t.isChooseable?.toString() === 'true')  //TODO: simone, questa cosa è "fa cagare" (.cit), rimettila a posto anche lato server.
                    ?.map((item, index) => {
                      return item.key;
                    })}
                  value={valueRoles}
                  itemRender={roleItemRender}
                  onChange={handleRolesChange}
                  validator={[requireValidator]}
                  tagRender={tagRender}

                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className={"rows-grandi dropdown-multi-container"}>
                <Field
                  name={"languages"}
                  component={ValidatedMultiSelectLan}
                  label={"Lingue parlate"}
                  data={languagesSorted?.map((item, index) => {
                    return item.label;
                  })}
                  value={item?.languages}
                  itemRender={languageItemRender}
                  onChange={handleLanguagesChange}
                  validator={[requireValidator]}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={10} className={"rows-grandi dropdown-multi-container" }>
                <Field
                  name={"planner_list"}
                  component={ValidatedMultiSelectAgende}
                  label={"Agende"}
                  data={(planners as IPlanner[]).map((item, index) => {
                    return item.id;
                  })}
                  // textField="name"
                  // dataItemKey="id"
                  tags={
                    valuePlanner && valuePlanner.length > 0
                      ? valuePlanner.map((item, index) => {
                          return {
                            text: `${
                              (planners as IPlanner[]).find(
                                (t) => t.id === item
                              )?.name
                            }`,
                            data: [item],
                          };
                        })
                      : []
                  }
                  // tagRender={tagRender}
                  value={props.item?.planner_list}
                  defaultValue={currentPlanner ? [currentPlanner.id] : []}
                  valueRender={plannerValueRender}
                  itemRender={plannerItemRender}
                  onChange={handlePlannersChange}
                  validator={[requireValidator]}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={5} className={"rows-grandi"}>
                <Field
                  name={"mobile"}
                  component={ValidatedMobile}
                  label={"Cellulare"}
                  //validator={[/*requireValidator,*/phoneNumberValidator]}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={5} className={"rows-grandi"}>
                <Field
                  name={"email"}
                  component={ValidatedEmail}
                  label={"Email"}
                  required
                  validator={[requireValidator, emailValidator]}
                />
              </Grid>
              <Grid
                item
                xs={5}
                sm={6}
                md={6}
                lg={1}
                className={
                  "rows-grandi notifiche-switch-visible  notifiche-switch"
                }
              >
                <Field
                  name={"notify"}
                  value={"notify ?? true"}
                  component={ValidatedSwitch}
                  label={"Notifiche"}
                  defaultChecked={true}
                />
              </Grid>
              <Grid
                item
                xs={5}
                sm={6}
                md={6}
                lg={1}
                className={"rows-grandi notifiche-switch-visible"}
              >
                <Field
                  name={"isVisible"}
                  component={ValidatedSwitch}
                  label={"Visibile"}
                  value={"isVisible ?? true"}
                  defaultChecked={true}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className={"rows-grandi"}
              >
                <Field
                  name={"color"}
                  component={ValidatedDropDownList}
                  label={"Colore"}
                  className={" custom-unif-font-2"}
                  data={colors?.map((item, index) => {
                    return item.label;
                  })}
                  valueRender={colorValueRender}
                  value={item?.color}
                  itemRender={colorItemRender}
                  defaultValue={props.item?.color}
                  validator={[requireValidator]}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className={"rows-grandi"}
              >
                <Field
                  name={"priority"}
                  component={ValidatedDropDownList}
                  className={" custom-unif-font-2"}
                  label={"Priorità"}
                  data={["Nessuna", "Massima", "Media", "Bassa"]}
                  value={item?.priority}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" className={"top-spacing"}>
              <div className="k-form-buttons">
                <BaseButton
                  className={` ${
                    (formRenderProps.allowSubmit && formRenderProps.valid) ? "custom-unif-viola-back" : ""
                  }`}
                  type={"submit"}
                  variant="contained"
                  disabled={!formRenderProps.allowSubmit || !formRenderProps.valid}
                  startIcon={<SaveIcon />}
                >
                  Salva
                </BaseButton>
                {props.hasCancel && (
                  <BaseButton
                    onClick={props.cancelEdit}
                    className={`custom-button-annulla custom-unif-grigio-back left-spacing-10p ${
                      (formRenderProps.allowSubmit && formRenderProps.valid)
                        ? "custom-unif-arancione-back"
                        : ""
                    }`}
                    disabled={!formRenderProps.allowSubmit || !formRenderProps.valid}
                    startIcon={<CancelPresentationIcon />}
                  >
                    Annulla
                  </BaseButton>
                )}
                {formRenderProps.visited &&
                  formRenderProps.errors &&
                  formRenderProps.errors.VALIDATION_SUMMARY && (
                    <div className={"k-messagebox k-messagebox-error"}>
                      <Error>{formRenderProps.errors.VALIDATION_SUMMARY}</Error>
                    </div>
                  )}
              </div>
            </Grid>
          </FormElement>
        </Fragment>
      )}
    />
  );
};
export default StaffForm;
