import {Icon, Theme, Typography} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {FC} from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "1.5vh",
    },
  })
);

const PageTitle: FC<{ title: string; icon: string }> = ({ title, icon }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h4">
        <Icon className={"custom-unif-icon-title"}>{icon}</Icon> {title}
      </Typography>
    </div>
  );
};

export default PageTitle;
