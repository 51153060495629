import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import {SelectionRange} from "@progress/kendo-react-dateinputs";
import React, {Fragment, useMemo, useState} from "react";
import {addDays, toDateFromApi} from "../../utils/dateHelper";
import DateRangeIcon from "@mui/icons-material/DateRange";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import MoreIcon from "@mui/icons-material/MoreVert";
import {ISeason} from "../../models/entities/ISeason";
import AddSeasonForm from "./AddSeasonForm";
import UpdateSeasonForm from "./UpdateSeasonForm";
import BaseButton from "components/common/BaseButton";
import BaseIconButton from "components/common/BaseIconButton";
import {useGetSeasonsQuery} from "store/api/seasons";
import {useCurrentPlanner} from "utils/hooks/currentPlanner";
import {skipToken} from "@reduxjs/toolkit/query/react";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";

const SeasonsForm = () => {
  const [showPastSeasons, setShowPastSeasons] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenedAddSeason, setIsOpenedAddSeason] = useState(false);
  const [isOpenedUpdateSeason, setIsOpenedUpdateSeason] = useState(false);

  const [initialRange, setInitialRange] = useState<SelectionRange>();
  const [minDate, setMinDate] = useState<Date>();
  const [maxDate, setMaxDate] = useState<Date>();
  const [seasonId, setSeasonId] = useState<number>();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const currentPlanner = useCurrentPlanner();


  const {
    data: seasons = [],
    isFetching,
  } = useGetSeasonsQuery(currentPlanner?.id ?? skipToken);

  const splittedSeasons = useMemo(() => {
    const sortedWithDates = seasons
      ?.map((s) => ({
        ...s,
        season_begin_day: toDateFromApi(s.season_begin_day as string),
        season_end_day: toDateFromApi(s.season_end_day as string),
      }))
      .sort((a, b) => (a.season_begin_day <= b.season_begin_day ? -1 : 1));

    let splittedSeasons = {
      pastSeasons: [] as ISeason[],
      seasons: [] as ISeason[],
    };

    sortedWithDates.forEach((s) => {
      if (s.season_end_day < new Date()) {
        splittedSeasons.pastSeasons.push(s);
      } else {
        splittedSeasons.seasons.push(s);
      }
    });

    return splittedSeasons;
  }, [seasons]);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openUpdateDialog = (
    season: ISeason,
    prevSeason?: ISeason,
    nextSeason?: ISeason
  ) => {
    setIsOpenedUpdateSeason(true);
    setInitialRange({
      start: season.season_begin_day as Date,
      end: season.season_end_day as Date,
    });
    setSeasonId(season.id);

    const min = prevSeason && addDays(prevSeason?.season_end_day as Date, 1);
    const max = nextSeason && addDays(nextSeason?.season_begin_day as Date, -1);
    setMinDate(min || new Date());
    setMaxDate(max);

    handleClose();
  };

  return (
    <Fragment>
      <Box display="flex" flexDirection="column" paddingTop={2}>

        {isFetching ? (
          <CenteredLoadingSpinner />
        ) : (
          <List>
            {splittedSeasons.seasons?.map((s, index) => {
              const nextSeason =
                index + 1 === seasons.length ? undefined : seasons[index + 1];
              const prevSeason = index === 0 ? undefined : seasons[index - 1];

              return (
                <ListItem key={s.id} divider>
                  <ListItemIcon>
                    <DateRangeIcon />
                  </ListItemIcon>
                  <ListItemText>{s.label}</ListItemText>
                  <ListItemSecondaryAction>
                    {matches ? (
                      <Fragment>
                        <BaseIconButton
                          color="inherit"
                          onClick={() =>
                            openUpdateDialog(s, prevSeason, nextSeason)
                          }
                        >
                          <EditIcon fontSize="small" />
                        </BaseIconButton>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <BaseIconButton color="inherit" onClick={openMenu}>
                          <MoreIcon />
                        </BaseIconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem
                            onClick={() =>
                              openUpdateDialog(s, prevSeason, nextSeason)
                            }
                          >
                            Modifica stagione
                          </MenuItem>
                        </Menu>
                      </Fragment>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        )}
        <Box alignSelf="center" paddingY={2}>
          <BaseButton
            variant="outlined"
            // style={{ borderRadius: 30 }}
            startIcon={<HistoryIcon />}
            onClick={() => setShowPastSeasons(!showPastSeasons)}
          >
            {showPastSeasons ? "Mostra periodi passati" : "Nascondi periodi passati"}

          </BaseButton>
        </Box>
        {showPastSeasons ? (
          <Fragment />
        ) : splittedSeasons.pastSeasons.length === 0 ? (
          <Box paddingY={2}>
            <Typography variant="body1" align="center">
              nessuna periodo passato
            </Typography>
          </Box>
        ) : (
          <List>
            {splittedSeasons.pastSeasons?.map((s) => (
              <ListItem key={s.id} divider>
                <ListItemIcon>
                  <DateRangeIcon />
                </ListItemIcon>
                <ListItemText>{s.label}</ListItemText>
              </ListItem>
            ))}
          </List>
        )}
      </Box>
      <AddSeasonForm
        opened={isOpenedAddSeason}
        closeForm={() => setIsOpenedAddSeason(false)}
        initialRange={initialRange}
        minDate={minDate}
        maxDate={maxDate}
      />
      <UpdateSeasonForm
        opened={isOpenedUpdateSeason}
        closeForm={() => setIsOpenedUpdateSeason(false)}
        initialRange={initialRange}
        minDate={minDate}
        maxDate={maxDate}
        seasonId={seasonId}
      />
    </Fragment>
  );
};

export default SeasonsForm;
