import {DropDownList, ListItemProps,} from '@progress/kendo-react-dropdowns';
import {FieldProps} from '@progress/kendo-react-form';
import React from "react";
import {IType} from "../../models/entities/types/IType";

export const NazionalitaDropdown = (props: FieldProps) => {
    const {  visited, value, source, data, ...others } = props;

    const valueRender = (
        element: React.ReactElement<HTMLSpanElement>,
        value: any
    ) => {
        if (!value) {
            return element;
        }

        const country = data.find((i : any) => i.key === value);

        const children = [

            <span >{(value!==undefined) ? country?.label : ""}</span>
        ];


        return React.cloneElement(element, { ...element.props }, children);
    };

    const itemRender = (
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
    ) => {
        const prenKey = itemProps.dataItem;
        const country = data.find((i : any) => i.key === prenKey)

        const itemChildren = [

            <span  key={country.key}>{country?.label}</span>,

        ];

        return React.cloneElement(li, li.props, itemChildren);
    };

    return (
        <div>
            <div className="std-dropdown-container dropdownModifica custom-unif-dropdown-focus">
                <label
                    className="labelModifica std-dropdown-label"
                    data-shrink="true">{props.label}
                </label>
                <div className="std-dropdown-component">

                    <DropDownList
                        className={"custom-unif-font-2"}
                        defaultValue={data[0].label}
                        // onChange={handleChange}
                        value={ value}
                        data={ data.map((item: IType, index : any) => {
                            return item.key;
                        })}
                        itemRender={itemRender}

                        valueRender={valueRender}
                        {...others}

                    />
                </div>
            </div>
        </div>
    );
};