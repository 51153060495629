import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import BaseButton from "./BaseButton";
import React from "react";

export interface AlertMessageAppointmentDialogProps {
    toggleDialog: () => void;
    onConfirm: () => void;
}

const AlertMessageAppointmentDialog = (props: AlertMessageAppointmentDialogProps) => {
    return (
        <Dialog
            title={"Attenzione!"}
            className={"alert-message-dialog"}
            onClose={props.toggleDialog}
            style={{ zIndex: 1301 }}

        >
            <div className={"message-zone"}>
                <p>
                    Per aggiungere dei partecipanti, bisogna prima impostare i prezzi e sconti nella pagina dell'esperienza.
                </p>
            </div>
            <DialogActionsBar>
        {/*        <BaseButton className={"custom-unif-arancione-back"} onClick={props.toggleDialog}>Annulla</BaseButton>*/}
        {/*        /!* <button className="k-button" onClick={props.toggleDialog}>*/}
        {/*  No*/}
        {/*</button> *!/*/}
                <BaseButton
                    className={"custom-unif-viola-back"}
                    onClick={() => {
                        props.onConfirm();
                        props.toggleDialog();
                    }}
                >
                    Continua
                </BaseButton>

            </DialogActionsBar>
        </Dialog>
    );
};

export default AlertMessageAppointmentDialog;
