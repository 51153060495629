import React, {cloneElement, FC, useEffect, useState} from "react";
import {Grid} from "@mui/material";
import NumberRangeInput from "../common/NumberRangeInput";
import PriceInput from "./PriceInput";
import CloseIcon from "@mui/icons-material/Close";
import {AgePriceItemProps} from "../../models/props/AgePriceItemProps";
import BaseIconButton from "components/common/BaseIconButton";
import BaseButton from "../common/BaseButton";
import {DropDownList, ListItemProps} from "@progress/kendo-react-dropdowns";
import {IType} from "../../models/entities/types/IType";
import {useAppSelector} from "../../store";
import {Switch} from "@progress/kendo-react-inputs";

const AgePriceItem: FC<AgePriceItemProps> = (props) => {
    const updatePriceNet = () => {
        (window.document.getElementById("input-day-prezzinetti-1")  as HTMLInputElement).value =
            (props.agePrice.price - props.agePrice.price *
                parseInt((window.document.getElementById("sconto-day-prezzinetti-1") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();

        (window.document.getElementById("input-day-prezzinetti-2")  as HTMLInputElement).value =
            (props.agePrice.price - props.agePrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-2") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();

        (window.document.getElementById("input-day-prezzinetti-3")  as HTMLInputElement).value =
            (props.agePrice.price - props.agePrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-3") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();

        (window.document.getElementById("input-day-prezzinetti-4")  as HTMLInputElement).value =
            (props.agePrice.price - props.agePrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-4") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();

        (window.document.getElementById("input-day-prezzinetti-5")  as HTMLInputElement).value =
            (props.agePrice.price - props.agePrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-5") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();

        (window.document.getElementById("input-day-prezzinetti-6")  as HTMLInputElement).value =
            (props.agePrice.price - props.agePrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-6") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();

        (window.document.getElementById("input-day-prezzinetti-7")  as HTMLInputElement).value =
            (props.agePrice.price - props.agePrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-7") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();


    }


    const { priceLabelTypes } = useAppSelector((store) => store.types);

    const [ priceLabelTypesData, setPriceLabelTypesData ] = useState(
        priceLabelTypes.map((item: IType, index: any) => {
        return item.key;
    })
    );

    useEffect(()=>{
       if(props.agePrice.price_label_type?.trim() === 'Adulti' && props.managerPriceLabel.isAdultSelectedYet() === undefined ){
           props.managerPriceLabel.setAdultSelected(props.btnIndex);
         }
    },[])


    const handleChange = (e: any) =>{
        props.onUpdate({
            ...props.agePrice,
            price: isNaN(parseFloat(e.target.value))? 0 : parseFloat(e.target.value),
        });

        if(props.manager?.isActive(props.btnIndex)){
            updatePriceNet();
        }
    }

    const [valueLabel ,setValueLabel] = useState<string|undefined>( priceLabelTypesData[0]);
    const [validForPax ,setValidForPax] = useState<boolean>( props.agePrice.valid_for_pax ?? true);



    const handleViewPrice = (e: string | undefined) => {
        if(e!= undefined) {
            props.manager?.toggleActive(e);


            updatePriceNet();
        }
    }

    const keyLabelItemRender = (
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
    ) => {
        const keyToSearch = itemProps.dataItem;

        const objFound = priceLabelTypes.find((i : any) => i.key === keyToSearch);


        const itemChildren = [
            <span key={objFound?.key}
            >
                {objFound?.label}
      </span>,

        ];

        return React.cloneElement(li, li.props, itemChildren);
    };

    const keyLabelValueRender = (
        element: React.ReactElement<HTMLSpanElement>,
        value: any
    ) => {

        let objFound =   priceLabelTypes.find((i : any) => i.key === value);


        const children = [
            <span className={"custom-unif-font-2"} style={{width: "100%"}}>
              {objFound?.label}
      </span>,
        ];

        return cloneElement(element, { ...element.props }, children);
    }


    useEffect(()=>{
        if( props.managerPriceLabel.isAdultSelectedYet() === undefined){
            setPriceLabelTypesData(
                priceLabelTypes.map((item: IType, index: any) => {
                    return item.key;
                }));
        }else {
            if (props.btnIndex !== props.managerPriceLabel.isAdultSelectedYet()) {

                setPriceLabelTypesData((priceLabelTypes.map((item: IType, index: any) => {
                    return item.key;
                })).filter(function (obj: any) {
                    return obj !== 'Adulti';
                }));
            } else {
                setPriceLabelTypesData(
                    priceLabelTypes.map((item: IType, index: any) => {
                        return item.key;
                    }));
            }
        }

    },[props.managerPriceLabel.isAdultSelectedYet(), props.adultSelected]);



    // useEffect(()=>{
    //     console.log(props.managerPriceLabel.isAdultSelectedYet());
    //     console.log(props.btnIndex);
    //     console.log(priceLabelTypesData);
    // },[priceLabelTypesData]);
  return (
    <Grid  container spacing={2} style={{marginBottom:"20px"}}>

        <Grid item xs={12} sm={3} md={2} lg={2} className={"fasce-anni-prezzi-2"}>
            <div>
                <div className="std-dropdown-container dropdownModifica custom-unif-dropdown-focus">
                    <label
                        className="labelModifica std-dropdown-label"
                        data-shrink="true"
                    >
                        Etichetta
                    </label>
                    <div className="etichetta-eta border-custom-components std-dropdown-component custom-unif-font ">
                        <DropDownList
                            required
                            itemRender={keyLabelItemRender}
                            data={
                                priceLabelTypesData
                            }
                            value={
                                (props.agePrice.price_label_type)?
                                    priceLabelTypes.find((i) => i.key  === props.agePrice.price_label_type?.trim())?.key
                                    : valueLabel
                            }
                            onChange={(e)=> {
                                setValueLabel(e.value);
                                if(priceLabelTypes.find((i) => i.key === e.value)?.key === 'Adulti') {
                                    props.managerPriceLabel.setAdultSelected(props.btnIndex);
                                }else{
                                    if(props.btnIndex === props.managerPriceLabel.isAdultSelectedYet()){
                                        props.managerPriceLabel.setAdultSelected(undefined);
                                    }
                                }
                                if(priceLabelTypes.find((i) => i.key  === e.value)?.key !== undefined) {
                                    props.onUpdate({
                                        ...props.agePrice,
                                        price_label_type:  priceLabelTypes.find((i) => i.key === e.value)?.key,
                                    });
                                }

                            }
                            }
                            valueRender={keyLabelValueRender}
                        />
                    </div>
                </div>
            </div>
        </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}  className={"fasce-anni-eta"}>
        <NumberRangeInput
          range={{min: props.agePrice.age_min as number, max: props.agePrice.age_max as number }}
          onChange={(range) =>
            props.onUpdate({ ...props.agePrice, age_min: range.min, age_max: range.max  })

          }
          minLabel={"da"}
          maxLabel={"a"}
        />
      </Grid>
      <Grid   item xs={12} sm={4} md={1} lg={1} className={"price-to-discount" + `${(props.manager?.isActive(props.btnIndex))? " currentPriceToDiscount" : ""} `}>
        <PriceInput

          value={props.agePrice.price}
          onChange={(e) =>
              handleChange(e)
          }
        />
      </Grid>
        <Grid item xs={12}
                         sm={6}
                         md={1}
                         lg={1} >
            <p style={{marginTop: "5px",marginBottom: "-2px" }}>Calcolato in Pax</p>
            <Switch
                onChange={(val) => {
                    props.onUpdate({
                        ...props.agePrice,
                        valid_for_pax: val.value,
                    });
                    setValidForPax(val.value)
                }}
                defaultChecked={true}
                value={validForPax}

            />

        </Grid>
        <Grid  item xs={10} sm={6} md={3} lg={3} >
            <BaseButton tabIndex={-1} variant={"outlined"}
                        id={props.btnIndex} onClick={(e) => handleViewPrice(props.btnIndex)}
                        className={ `bottoni-calcola-prezzi  bottoni-tondi-margin ${props.manager?.isActive(props.btnIndex) ? "custom-unif-selected-back" : "custom-unif-unselected-back "} `}
            >
                calcola prezzi netti
            </BaseButton>
        </Grid>
      <Grid  item xs={1} sm={1} md={1} lg={1}  className={"delete-fascia-prezzo-eta"}>
        <BaseIconButton tabIndex={-1} onClick={props.onDelete} className={"custom-unif-arancione-back delete-fascia-bottone bottoni-tondi std-bottoni-tondi bottoni-tondi-margin"}>
          <CloseIcon />
        </BaseIconButton>
      </Grid>
    </Grid>
  );
};

export default AgePriceItem;
