import {createSlice, PayloadAction,} from "@reduxjs/toolkit";
import {IWeeklyTimetableDay} from "../models/entities/IWeeklyTimetableDay";


interface staffState {
  copiedWeek: IWeeklyTimetableDay[];
  copiedFirstWeekDay: string | Date;
  isCopy: boolean;

  currentStaffId?: number;
  currentFirstDayWeek: string;
}

const initialState: staffState = {
  copiedWeek: [],
  copiedFirstWeekDay: "",
  isCopy: false,
  currentStaffId: undefined,
  currentFirstDayWeek: "default",
};

const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    selectStaff(state, action: PayloadAction<number | undefined>) {
      state.currentStaffId = action.payload;
    },
    selectStaffWeek(state, action: PayloadAction<string>) {
      state.currentFirstDayWeek = action.payload;
    },
    setIsCopy(state, action: PayloadAction<boolean>) {
      state.isCopy = action.payload;
    },
    setCopiedWeek(state, action: PayloadAction<IWeeklyTimetableDay[]>) {
      state.copiedWeek = action.payload;
    },
    setCopiedFirstWeekDay(state, action: PayloadAction<string | Date>) {
      state.copiedFirstWeekDay = action.payload;
    },
  },
});

export const {
  selectStaff,
  selectStaffWeek,
  setCopiedWeek,
  setCopiedFirstWeekDay,
  setIsCopy,
} = staffSlice.actions;

export default staffSlice.reducer;
