import {Box, Card, CardContent, CardHeader} from "@mui/material";
import {makeStyles} from "@mui/styles";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import React, {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useUserCheckRegistrationMutation} from "store/api/auth";
import {setCheckcode, setStep, setUid} from "store/registration";
import StepperRegister from "../../components/users/StepperRegister";
import {IUserCheckRegistrationViewModel} from "../../models/views/IUserCheckRegistrationViewModel";
import {useAppDispatch, useAppSelector} from "../../store";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100vh",
  },
  card: {
    width: 600,
  },
});

//sample http://localhost:3000/register?uid=1&checkcode=e3b7fea1edf40045453cb6b446e6f265
//sample con cambio email http://localhost:3000/register?uid=4&checkcode=c77b5b5acba07d7aacfe4ffd71765a1b
const RegisterPage = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const { uid, checkcode } = useAppSelector((store) => store.registration);

  const history = useHistory();
  const search = useLocation().search;
  const uidUrl = new URLSearchParams(search).get("uid");
  const checkcodeUrl = new URLSearchParams(search).get("checkcode");
  let step = new URLSearchParams(search).get("step");

  const [userCheckRegistration, { isLoading, isUninitialized }] =
    useUserCheckRegistrationMutation();

  const loginStyle = {
    borderRadius: "25px",
    boxShadow: " 0px 0px 16px 2px rgba(0,0,0,0.94)",
  };

  useEffect(() => {
    if (uidUrl === null || checkcodeUrl === null) history.push("/404page");
    else {
      dispatch(setUid(parseInt(uidUrl)));
      dispatch(setCheckcode(checkcodeUrl));
      dispatch(setStep(parseInt(step === null ? "0" : step)));
    }
    // eslint-disable-next-line
  }, [uidUrl, checkcodeUrl, step]);

  useEffect(() => {
    if (uid && checkcode)
      userCheckRegistration({
        uid: uid,
        checkcode: checkcode,
      } as IUserCheckRegistrationViewModel);
    // eslint-disable-next-line
  }, [uid, checkcode]);

  return isLoading || isUninitialized ? (
    <CenteredLoadingSpinner />
  ) : (
    <Box
      className={
        classes.root + " custom-unif-font custom-unif-viola custom-back"
      }
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Card className={classes.card + " card-login-forpsw"} style={loginStyle}>
        <CardHeader
          component={Box}
          margin={3}
          title="Registrazione"
          titleTypographyProps={{
            variant: "h3",
            align: "center",
          }}
        ></CardHeader>
        <CardContent component={Box} marginBottom={3}>
          <StepperRegister />
        </CardContent>
      </Card>
    </Box>
  );
};

export default RegisterPage;
