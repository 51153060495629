import {FC} from "react";
import {InputAdornment, TextField, TextFieldProps} from "@mui/material";

const DiscountTextField: FC<TextFieldProps> = (props) => {
  return (
    <TextField
        style={{width:"100%"}}
      type={"text"}
      {...props}
      inputProps={{
          min: 0,
          max: 100,
      }}
      InputProps={{


        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}

        // onWheel={event => { event.preventDefault(); }}
      error={(props.value as number) < 0 || (props.value as number) > 100}
      variant="outlined"
    />
  );
};

export default DiscountTextField;
