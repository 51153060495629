
import {useAppSelector} from "../../store";
import { useGetPlannerQuery} from "../../store/api/planners";
import {IPlanner} from "../../models/entities/IPlanner";
import { skipToken } from "@reduxjs/toolkit/query/react";

export function useCurrentPlanner(): IPlanner | undefined {
  const id = useAppSelector((s) => s.planners.currentPlannerId);
  const { data: currentPlanner } = useGetPlannerQuery(id ?? skipToken);

  return currentPlanner;
}
