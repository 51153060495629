import {FC, Fragment, useEffect, useState} from "react";
import {Divider, Grid,} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AgePriceItem from "./AgePriceItem";
import BaseButton from "components/common/BaseButton";
import {IPrice} from "../../models/entities/prices/IPrice";
import {IExperience} from "../../models/entities/IExperience";
import {IUpdatePriceViewModel} from "../../models/views/experiences/IUpdatePriceViewModel";
import {IPriceViewModel} from "../../models/views/experiences/IPriceViewModel";
import {useGetExperienceQuery, useSavePriceMutation,} from "../../store/api/experiences";
import {skipToken} from "@reduxjs/toolkit/query/react";


export interface buttonStateManager {
  isActive          (id: string) : boolean;
  isOneBtnSelected  () : boolean;
  toggleActive      (id: string ) : void;
}


export interface priceLabelStateManager {
  isAdultSelectedYet  () : string | undefined;
  setAdultSelected  (id: string | undefined) : void;
}

const AgePricesForm: FC<{
  experienceId: number;
  onSaveParentState: (agePrices: IPrice[]) => void;
  pSectionActive: boolean;
  setParentSectionActive: (value: boolean) => void;
}> = ({ experienceId, onSaveParentState, pSectionActive, setParentSectionActive }) => {
  const { data: experience = {} as IExperience, isFetching } =
    useGetExperienceQuery(experienceId ?? skipToken);
  const [agePrices, setAgePrices] = useState<IPrice[]>(() => {
    // return (
    //   (experience?.price_type === "partecipante" && experience?.prices) || []
    // );
    return experience?.prices
      ? experience.prices.filter((price) => price.price_type === "partecipante")
      : [];
  });
  const [savePrice] = useSavePriceMutation();


  useEffect(() => {
    onSaveParentState(agePrices);
  }, [agePrices]);

  const addAgePrice = () => {
    const values: IPrice[] = [
      ...agePrices,
      {
        age_min: 0,
        age_max: 50,
        price: 0,
        price_type: "partecipante",
        experience_id: experience?.id as number,
        price_label_type: "Neonati"
      },
    ];
    setAgePrices(values);
  };
  const deleteAgePrice = (i: number) => {
    setAgePrices((prevState) => prevState.filter((ap, index) => i !== index));
  };



  const onUpdate = (agePrice: IPrice, index: number) => {
    setAgePrices((prevState) =>
      prevState?.map((ap, i) => {
        if (i !== index) {
          return ap;
        }
        return {
          ...ap,
          price_label_type: agePrice.price_label_type,
          age_min: agePrice.age_min,
          age_max: agePrice.age_max,
          price: agePrice.price,
          valid_for_pax: agePrice.valid_for_pax,
        };
      })
    );
  };

  const createPriceHistory = (prices: IPrice[]): IUpdatePriceViewModel => {
    const initialPrices = experience.prices;

    let history: IUpdatePriceViewModel = {
      price_type: "partecipante",
      prices: prices?.map((p) => ({
        ...p,
        date_label: undefined,
      })),
    };

    let missingPrices: IPriceViewModel[] = [];

    initialPrices.forEach((ip) => {
      if (!prices.some((p) => p.id === ip.id)) {
        missingPrices.push({
          ...ip,
          isDelete: 1,
        });
      }
    });

    history.prices.concat(missingPrices);

    return history;
  };

  const [buttonStates, setButtonStates] = useState<string | undefined>(undefined);
  const [adultSelected , setAdultSelected ] = useState<string | undefined>(undefined);

  const btnManager :buttonStateManager = {
    isActive(id: string): boolean {
      return (buttonStates === id);
    },
    isOneBtnSelected(): boolean {
      return (buttonStates !== undefined);
    },
    toggleActive(id: string) {
      if(id === buttonStates) {
        setParentSectionActive(false);
        setButtonStates(undefined);
      }else{
        setButtonStates(id);
        setParentSectionActive(true);
      }
    }
  };

  const typeLabelManager: priceLabelStateManager = {
    isAdultSelectedYet  () : string | undefined {
      return adultSelected ;
    },
    setAdultSelected(id: string | undefined) {
      if(id === adultSelected) {
        setAdultSelected(undefined);
      }else{
        setAdultSelected(id);
      }
    }
  }
  return isFetching ? (
    <Fragment />
  ) : (
    <Fragment>
      <Grid item xs={12}  md={4} lg={6} style={{textAlign:"center", marginTop:"3vh", paddingLeft: "11vw"}} className={'add-fascia-btn-container'}

      >
          <BaseButton
            tabIndex={-1}
            className={"custom-unif-viola-back add-period-season"}
            variant={"outlined"}
            startIcon={<AddIcon />}
            onClick={addAgePrice}
          >
            Aggiungi intervallo
          </BaseButton>
      </Grid>
      <Divider />
      <Grid container item xs={12} style={{textAlign:"center", marginTop:"20px"}}>
        {agePrices.length === 0 ? (
          <Grid item xs={12} style={{textAlign:"center"}}>

          </Grid>
        ) : (
          agePrices?.map((ap, i) => (
            <Grid
                container
                item xs={12} style={{textAlign:"center"}} className={"fascia-prezzo-rule"}
            >
              <AgePriceItem

                  btnIndex={`sp-${i}`}
                  manager={btnManager}
                  managerPriceLabel={typeLabelManager}
                  adultSelected={adultSelected}
                  isSectionActive={pSectionActive}
                  setParentIsSectionActive={(value : boolean) => {
                    setParentSectionActive(value);
                  }}

                key={`ap-${i}`}
                agePrice={ap}
                onUpdate={(agePrice) => onUpdate(agePrice, i)}
                onDelete={() => deleteAgePrice(i)}
              />
            </Grid>
          ))
        )}
      </Grid>
    </Fragment>
  );
};

export default AgePricesForm;
