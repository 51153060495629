import {Fragment} from "react";
import {IUser} from "../../models/entities/IUser";
import CenteredLoadingSpinner from "../../components/common/CenteredLoadingSpinner";
import GenericPagedGrid from "../../components/common/GenericPagedGrid";
import ReceptionistsForm from "components/users/ReceptionistsForm";
import {useDeleteUserMutation, useGetReceptionistsQuery} from "store/api/users";

const ReceptionistsPage = () => {
    const {
        data: users = [],
        isLoading,
    } = useGetReceptionistsQuery();
    const [deleteUser ] = useDeleteUserMutation();

    const remove = (dataItem: IUser) => {
        deleteUser(dataItem.id as number);
    };


    return (
        <div style={{ padding: "2.5vh" }} className={"users_grid"}>
            {isLoading ? (
                <CenteredLoadingSpinner />
            ) : (
                <Fragment>
                    <GenericPagedGrid
                        icon="group" title="Utenti Hotel"
                        itemsPage={30}
                        items={users}
                        columns={[
                            // { field: "name", title: "Nome" },
                            // { field: "surname", title: "Cognome"},
                            { field: "fullname", title: "Nome" },
                            { field: "username", title: "Username"},
                            { field: "company_name", title: "Hotel"},
                            {
                                field: "enabled",
                                title: "Attivo",


                            },
                            // { field: "impersonate", title: "Login", width: 90 },
                        ]}
                        enableCancel={true}
                        onRemove={remove}
                        addFormComponent={ReceptionistsForm}
                        updateFormComponent={ReceptionistsForm}
                    />

                </Fragment>
            )}
        </div>
    );
};

export default ReceptionistsPage;
