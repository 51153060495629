

const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export const emailValidator = (value: string) =>
{
    return (emailRegex.test(value) || value==="" || value===undefined || value === null) ? "" : "Il campo deve contenere un indirzzo email valido";
}

export const emailValidatorNew = (value: string) =>
emailRegex.test(value) ? true : "Il campo deve contenere un indirzzo email valido";