import { createTheme } from "@mui/material/styles";

const theme = createTheme({
   mixins: {
    toolbar: {
      height: 64
    }
  },
  palette: {
    primary: {
      light: '#5c43b5',
      main: '#5c43b5',
      dark: '#5c43b5',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f47056',
      main: '#f50057',
      dark: '#f47056',
      contrastText: '#fff',
    },
  },
})

export default theme