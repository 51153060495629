import * as React from "react";

export const EditorCommandCell = (props : any) => {
    const { dataItem } = props;
    const inEdit = dataItem[props.editField];
    const isNewItem = dataItem.id === undefined;

    return inEdit ? (
        <td className="k-command-cell">
            <button
                className="k-button k-button-md k-rounded-md k-button-solid bottoni-bordo-viola custom-unif-viola-back"
                onClick={() =>
                    isNewItem ? props.add(dataItem) : props.update(dataItem)
                }
            >
                <span className="k-icon k-i-check k-i-checkmark"></span>
            </button>
            <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bottoni-bordo-arancione custom-unif-arancione-back"
                onClick={() =>
                    isNewItem ? props.discard(dataItem) : props.cancel(dataItem)
                }
            >
                <span className="k-icon k-i-reset"></span>
            </button>
        </td>
    ) : (
        <td className="k-command-cell">
            <button
                className="k-button k-button-md k-rounded-md k-button-solid bottoni-bordo-viola custom-unif-viola"
                onClick={() => props.edit(dataItem)}
            >
                <span className="k-icon k-i-edit k-i-pencil"></span>
            </button>

        </td>
    );
};