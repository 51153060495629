import React, {useEffect, useState} from "react";
import {CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor,} from "@progress/kendo-data-query";
import {
    Grid,
    GridCellProps,
    GridColumn as Column,
    GridFilterChangeEvent,
    GridRowProps,
    GridSortChangeEvent,
    GridToolbar,
} from "@progress/kendo-react-grid";
import AddIcon from "@mui/icons-material/AddCircle";
import LoginIcon from "@mui/icons-material/Input";
import SendEmailIcon from "@mui/icons-material/Email";
import GridUi from "@mui/material/Grid";
import EditCommandCell from "./EditCommandCell";
import {IBase} from "../../models/entities/IBase";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import {RenderProp} from "../../models/helpers/RenderProps";
import {useAppDispatch, useAppSelector} from "../../store";
import DeleteDialog from "./DeleteDialog";
import {CircularProgress, Icon} from "@mui/material";
import BaseIconButton from "./BaseIconButton";
import {useResendInviteUserMutation} from "store/api/users";
import {IntlProvider, load, LocalizationProvider,} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import numbers from "cldr-numbers-full/main/it/numbers.json";
import currencies from "cldr-numbers-full/main/it/currencies.json";
import caGregorian from "cldr-dates-full/main/it/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/it/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/it/timeZoneNames.json";
import {useSelectUserMutation} from "../../store/api/auth";
import {api} from "../../store/api/api";
import {IRoleType} from "models/entities/types/IRoleType";
import PageTitleGrid from "./PageTitleGrid";
import BaseButton from "./BaseButton";

// import itMessages from "./../../globalization/GenericGridIT.json";

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);
// loadMessages(itMessages, "it-IT");


const initialFilter: CompositeFilterDescriptor = {
  logic: "and",
  //filters: [{ field: "name", operator: "contains", value: "Chef" }],
  filters: [],
};

const initialSort: Array<SortDescriptor> = [{ field: "name", dir: "asc" },{ field: "initials", dir: "asc" },{ field: "checkout", dir: "asc" }];

interface ColumnHeader {
  field: string;
  title: string;
  format?: string;
  filter?: "boolean" | "numeric" | "text" | "date" | undefined;
  width?: string | number;
  height?: string | number;
  className?: string;
}

export interface GenericGridProps<T extends IBase> {
  items: T[];
  icon?: string;
  title?: string;
  titleDelete?: string;
  textDelete?: string;
  itemPage?: number;
  columns: ColumnHeader[];
  updateFormComponent: RenderProp<EditFormProps<T>>;
  addFormComponent: RenderProp<EditFormProps<T>>;
  customFormComponent?: RenderProp<EditFormProps<T>>;
  enableCancel: boolean;
  canDelete?: boolean;
  onRemove: (item: T) => void;
  // deleteDispatch: () => void;
}

const GenericGrid = <T extends IBase>({
  items,
  columns,
  updateFormComponent,
  customFormComponent,
  addFormComponent,
  enableCancel,
                                        canDelete,
  onRemove,
  icon = '',
  title = '',
    titleDelete,
                                        textDelete,
}: // deleteDispatch,
  GenericGridProps<T>) => {
  const {
    colors,
    roles
  } = useAppSelector((store) => store.types);

  const [openUpdateForm, setOpenUpdateForm] = useState<boolean>(false);
  const [openAddForm, setOpenAddForm] = useState<boolean>(false);
  const [openCustomForm, setOpenCustomForm] = useState<boolean>(false);
  const [openDeleteForm, setOpenDeleteForm] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<T>({} as T);

  const [editItem, setEditItem] = useState<T>({} as T);
  const [customItem, setCustomItem] = useState<T>({} as T);
  const [filter, setFilter] = useState(initialFilter);
  const [iconFilter, setIconFilter] = useState("filter");
  const [filterable, setFilterable] = useState(false);
  const [sort, setSort] = useState(initialSort);

  const { countries } = useAppSelector((store) => store.types);
  const [selectUser, { isSuccess: isSelectSucceded }] = useSelectUserMutation()
  const dispatch = useAppDispatch();

  const handleLogin = (id: number) => {
    dispatch(api.util.resetApiState())
    selectUser(id);
  }

  useEffect(() => {
    if (isSelectSucceded) {
      dispatch(api.util.resetApiState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectSucceded])

  const [resendInviteUser, { isLoading: isLoadingResendInviteUser }] =
    useResendInviteUserMutation();
  const [resendInviteUserId, setResendInviteUserId] = useState<number>();


  const enterEdit = (dataItem: T) => {
    setOpenUpdateForm(true);
    setEditItem(dataItem);
  };

  const enterCustom = (dataItem: T) => {
    setOpenCustomForm(true);
    setCustomItem(dataItem);
  };

  const enterAdd = () => {
    setOpenAddForm(true);
    setEditItem({} as T);
  };

  const handleAddSubmit = (company: T) => {
    // setOpenAddForm(false);
  };
  const handleUpdateSubmit = (company: T) => {
    // setOpenUpdateForm(false);
  };

  const handleCancelEdit = () => {
    setOpenAddForm(false);
    setOpenUpdateForm(false);
    setOpenCustomForm(false);
    setEditItem({} as T);
  };

  const MyEditCommandCell = (props: GridCellProps) => (
    // <EditCommandCell {...props} enterEdit={enterEdit} remove={onRemove} />
    <EditCommandCell
      {...props}
      enterEdit={enterEdit}
      enterCustom={enterCustom}
      isDeletable={canDelete ?? true}
      remove={(item: T) => {
        setDeleteItem(item);
        setOpenDeleteForm(true);
      }}
    />
  );

  const handleNothing = ()=>{

  }
  const UpdateFormComponentDynamic = updateFormComponent({
    item: editItem,
    onSubmit: handleUpdateSubmit,
    cancelEdit: handleCancelEdit,
    hasCancel: enableCancel,
    onChange: handleNothing,
  });
  const CustomFormComponentDynamic = customFormComponent ? customFormComponent({
    item: customItem,
    onSubmit: handleUpdateSubmit,
    cancelEdit: handleCancelEdit,
    hasCancel: enableCancel,
    onChange: handleNothing,
  }) : null;
  const AddFormComponentDynamic = addFormComponent({
    item: editItem,
    onSubmit: handleAddSubmit,
    cancelEdit: handleCancelEdit,
    hasCancel: enableCancel,
    onChange: handleNothing,
  });
  const DeleteDialogDynamic = DeleteDialog({
    item: deleteItem,
    titleDelete: titleDelete,
    textDelete: textDelete,
    toggleDialog: () => {
      setOpenDeleteForm(false);
      setDeleteItem({} as T);
    },
    onConfirm: () => {
      onRemove(deleteItem);
      setDeleteItem({} as T);
    },
  });


  const rowRender = (
      trElement: React.ReactElement<HTMLTableRowElement>,
      props: GridRowProps
  ) => {
    const paid = props.dataItem.paid ?? undefined;
    const url_active = props.dataItem.url_active ?? undefined;
    const grey = { backgroundColor: "#d7d6d3" };
    const green = { backgroundColor: "#adf0a9" };
    const white = { backgroundColor: "white" };

    let style = white;
    if(paid !== undefined){
      if(paid) {
        style = green;
      }else{
        if(url_active !== undefined){
          if(!url_active ){
            style = grey;
          }
        }
      }
    }

    const trProps: any = { style: style };
    return React.cloneElement(
        trElement,
        { ...trProps },
        trElement.props.children
    );
  };

  return (
    <React.Fragment>
      <LocalizationProvider language="it-IT">
        <IntlProvider locale="it">
          <Grid
            className={"generic-grid-metis std-grid-component"}
            style={{ border: "0" }}
            data={items?.length > 0 ?  orderBy(filterBy(items, filter), sort) : []}
            total={items?.length}
            filterable={filterable}
            filter={filter}
            onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
            sortable={true}
            sort={sort}
            resizable={false}
            rowRender={rowRender}
            onSortChange={(e: GridSortChangeEvent) => {
              setSort(e.sort);
            }}
          >
            <GridToolbar>
              <GridUi container spacing={1}>
                <GridUi container item xs={12} spacing={3}>
                  <GridUi item xs={10} sm={10} lg={11} className={"title-bar-grid-container"}>
                    <div className={"title-bar-grid"}>
                      {title && <div style={{ float: "left" }}><PageTitleGrid icon={icon} title={title} /></div>}
                      <BaseIconButton
                          color="primary"
                          // title="Nuovo"
                          aria-label="Nuovo"
                          className={"custom-unif-viola grid-button"}
                          onClick={enterAdd}
                          //disabled={true}
                          style={{ fontSize: "25px" }}
                      >
                        <AddIcon />
                      </BaseIconButton>
                    </div>
                  </GridUi>

                  <GridUi
                    item
                    className={"filter-grid-container"}
                    xs={2} sm={2}
                    lg={1}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <BaseIconButton
                        className={"custom-filter-button"}
                      onClick={() => {
                        setFilterable(!filterable);
                        setIconFilter(
                          iconFilter === "filter" ? "filter-clear" : "filter"
                        );
                      }}
                    >
                      {!filterable ? (
                        <span className="k-icon k-i-filter" />
                      ) : (
                        <span className="k-icon k-i-filter-clear" />
                      )}
                    </BaseIconButton>
                  </GridUi>
                </GridUi>

              </GridUi>
            </GridToolbar>
            {columns?.map(
              ({ field, title, format, width, height,  filter, className }, index) =>
                //da qui in poi vengono definiti i vari field che vogliamo customizzare, o futuri programmatori attenti a modificarle, percheè tutte le griglie passano da qui
                field === "color" ? (
                  <Column
                    key={index}
                    field={field}
                    className={className}
                    title={title}
                    format={format}
                    width={width ?? "90"}
                    cell={(props: GridCellProps) => (
                      <td>
                        <span
                          style={{
                            display: "inline-block",
                            background: colors.find(
                              (t) => t.label === props.dataItem.color
                            )?.hex,
                            borderRadius: "50%",
                            border: "1px solid #9e9e9e",
                            width: "25px",
                            height: "25px",
                            textAlign: "center",
                            overflow: "hidden",
                            marginTop: "6px",
                          }}
                        ></span>
                      </td>
                    )}
                  />
                ) : field === "planner_role" ? (
                  <Column
                    key={index}
                    field={field}
                    title={title}
                    className={className}
                    format={format}
                    width={width ?? "220"}
                    cell={(props: GridCellProps) => (
                      <td>
                        {(props.dataItem.planner_role) ?
                          (props.dataItem.planner_role.length > 2) ?
                            <span>
                              {roles?.find((item: IRoleType) => item.key === props.dataItem.planner_role[0])?.label}
                              <br />{" "}
                              {roles?.find((item: IRoleType) => item.key === props.dataItem.planner_role[1])?.label}
                              {" ..."}
                            </span>
                            :
                            props.dataItem.planner_role.map((role: any) => (


                              <span>
                                {roles?.find((item: IRoleType) => item.key === role)?.label}
                                <br />{" "}
                              </span>
                            )) : null
                        }

                      </td>
                    )}
                  />
                ) : field === "languages" ? (
                  <Column
                    key={index}
                    field={field}
                    title={title}
                    format={format}
                    className={className}
                    width={width ?? "220"}
                    cell={(props: GridCellProps) => (
                      <td>
                        {(props.dataItem.languages) ?
                          (props.dataItem.languages.length > 2) ?
                            <span>
                              {props.dataItem.languages[0]}
                              <br />{" "}
                              {props.dataItem.languages[1]}
                              {" ..."}
                            </span>
                            :
                            props.dataItem.languages.map((l: any) => (
                              <span>
                                {l}
                                <br />{" "}
                              </span>
                            )) : null
                        }
                      </td>
                    )}
                  />
                ) : filter === "boolean" ? (
                  <Column
                    key={index}
                    field={field}
                    title={title}
                    filterable={false}
                    className={className}
                    format={format}
                    width={width ?? "100"}
                    cell={(props: GridCellProps) => (
                      <td className={"boolean"} style={{ color: "black"}}>

                          <Icon
                              style={{ color: "black"}}

                          >
                            {props.dataItem[field] === true ? "check" : "clear"}
                          </Icon>
                      </td>
                    )}
                  />
                ): (field === "checkin" ) ? (
                        <Column
                            key={index}
                            field={field}
                            title={title}
                            format={format}
                            filter={"date"}
                            cell={(props: GridCellProps) => (
                                <td>
                                  {props.dataItem.checkin.toLocaleDateString('IT')}
                                </td>
                            )}
                        />
                    )
                    : (field === "checkout" ) ? (
                            <Column
                                key={index}
                                field={field}
                                title={title}
                                filter={"date"}
                                format={format}
                                cell={(props: GridCellProps) => (
                                    <td>
                                      {props.dataItem.checkout.toLocaleDateString('IT')}
                                    </td>
                                )}
                            />
                        )
                        : (field === "booking_number" ) ? (
                                <Column
                                    key={index}
                                    field={field}
                                    title={title}
                                    filterable={false}
                                    format={format}
                                    cell={(props: GridCellProps) => (
                                        <td>


                                          {props.dataItem.booking_number && <BaseButton
                                              className={"buttons-inside-grids custom-unif-viola-back "}
                                              variant="contained"
                                              onClick={() => enterCustom(props.dataItem)}
                                              startIcon={<span className="k-icon k-i-zoom k-i-search"></span>}
                                          >
                                            {props.dataItem.booking_number}
                                          </BaseButton>}

                                          { props.dataItem.url_active && <BaseButton
                                              className={"buttons-inside-grids left-spacing-10p custom-unif-viola-back"}
                                              variant="contained"
                                              onClick={() => {
                                                if(props.dataItem.url_generated) {
                                                  window.open(props.dataItem.url_generated, '_blank')!.focus();
                                                }
                                              }}
                                              startIcon={<span className="k-icon k-i-hyperlink-open"></span>}
                                          >
                                            Vai a link
                                          </BaseButton> }
                                        </td>
                                    )}
                                />
                            )

                            : field === "active" ? (
                                    <Column
                                        key={index}
                                        field={field}
                                        title={title}
                                        filterable={false}
                                        className={className}
                                        format={format}
                                        width={width ?? "120"}
                                        cell={(props: GridCellProps) => (
                                            <td className={"boolean"} >
                                              <Icon

                                                  style={{  color: "black"}}
                                              >
                                                {props.dataItem[field] === true ? "check" : "clear"}
                                              </Icon>
                                            </td>
                                        )}
                                    />
                                )
                                : field === "site_active" ? (
                                        <Column
                                            key={index}
                                            field={field}
                                            title={title}
                                            filterable={false}
                                            className={className}
                                            format={format}
                                            width={width ?? "120"}
                                            cell={(props: GridCellProps) => (
                                                <td className={"boolean"} >
                                                  <Icon

                                                      style={{  color: "black"}}
                                                  >
                                                    {props.dataItem[field] === true ? "check" : "clear"}
                                                  </Icon>
                                                </td>
                                            )}
                                        />
                                    )
                    : field === "country" ? (
                            <Column
                                key={index}
                                field={field}
                                title={title}
                                filterable={false}
                                className={className}
                                format={format}
                                width={width ?? "200"}
                                cell={(props: GridCellProps) => (
                                    <td className={
                                      (props.dataItem.from_platform !== null && props.dataItem.from_platform !== undefined) ?
                                          (props.dataItem.from_platform)? "highlight-row-from-plat" : ""
                                          : ""
                                    }>


                                      {countries.find((i : any) => i.key === props.dataItem.country)?.label}

                                    </td>

                                )}
                            />
                        )
                  : field === "enabled" ? (
                    <Column

                      key={index}
                      filterable={false}
                      field={field}
                      className="attivo-colonna"
                      title={" "}
                      format={format}
                      width={"90"}
                      cell={(props: GridCellProps) => (
                        <span data-title={(props.dataItem[field]) ? "impersonifica utente attivo" : "manda nuovamente email all'utente non attivato"} className={"alt-colonne-ipresonifica"}>
                          <td className={className} >
                            <div>
                              {props.dataItem[field] ? (


                                <BaseIconButton

                                  color="primary"
                                  className={"custom-unif-viola "}
                                  onClick={() => handleLogin(props.dataItem["id"] as number)}
                                  style={{ display: "block", float: "left" }}
                                >
                                  <LoginIcon />
                                </BaseIconButton>
                              ) : (
                                <BaseIconButton
                                  color="primary"
                                  className={"custom-unif-viola "}
                                  disabled={
                                    isLoadingResendInviteUser &&
                                    props.dataItem["id"] === resendInviteUserId
                                  }
                                  onClick={() => {
                                    resendInviteUser({
                                      id: props.dataItem["id"] as number,
                                      email: props.dataItem["email"] as string
                                    });
                                    setResendInviteUserId(props.dataItem["id"]);
                                  }}
                                  style={{ display: "block", float: "left" }}
                                >
                                  {isLoadingResendInviteUser &&
                                    props.dataItem["id"] === resendInviteUserId ? (
                                    <CircularProgress />
                                  ) : (
                                    <SendEmailIcon />
                                  )}
                                </BaseIconButton>
                              )}
                            </div>
                          </td>
                        </span>
                      )}
                    />
                  )
                    : field === "impersonate" ? (
                      <Column
                        key={index}
                        field={field}
                        className="attivo-colonna"
                        title={title}
                        format={format}
                        width={"90"}
                        cell={(props: GridCellProps) => (
                          <td className={className}>
                            <div>
                              <BaseIconButton
                                color="primary"
                                className={"custom-unif-viola "}
                                onClick={() => handleLogin(props.dataItem["id"] as number)}
                                style={{ display: "block", float: "left" }}
                              >
                                <LoginIcon />
                              </BaseIconButton>
                            </div>
                          </td>
                        )}
                      />
                    ) : field === "length_minutes" ? (
                      <Column
                        key={index}
                        field={field}
                        title={title}
                        format={format}
                        width={width? width: "130"}

                        cell={(props: GridCellProps) => (
                            <td className={className} style={{textAlign: "center"}}>
                              {props.dataItem.length_minutes}'
                            </td>
                        )}
                      />
                    )

                      : field === "customers_max_number" ? (
                        <Column
                          key={index}
                          field={field}
                          title={title}
                          format={format}
                          width={width? width: "120"}
                        // cell={(props: GridCellProps) => (
                        //     <td className={className}>
                        //       <div>
                        //         <BaseIconButton
                        //             color="primary"
                        //             onClick={() => handleLogin(props.dataItem["id"] as number)}
                        //             style={{ display: "block", float: "left" }}
                        //         >
                        //           <LoginIcon />
                        //         </BaseIconButton>
                        //       </div>
                        //     </td>
                        // )}
                        />
                      )
                                            : field === "total_price" ? (
                                                    <Column
                                                        key={index}
                                                        field={field}
                                                        title={title}
                                                        format={format}
                                                        width={width? width: "120"}
                                                        cell={(props: GridCellProps) => (
                                                            <td style={{textAlign: "center"}}  className={
                                                              (props.dataItem.from_platform !== null && props.dataItem.from_platform !== undefined) ?
                                                                  (props.dataItem.from_platform)? "highlight-row-from-plat" : ""
                                                                  : ""
                                                            }>
                                                              {props.dataItem.total_price.toFixed(2)}

                                                            </td>
                                                        )}
                                                    />
                                                )
                                                        : field === "prenotation" ? (
                                                                <Column
                                                                    key={index}
                                                                field={field}
                                                                title={title}
                                                                format={format}
                                                                width={width? width: "120"}
                                                                cell={(props: GridCellProps) => (
                                                                    <td style={{textAlign: "center"}}  className={
                                                                      (props.dataItem.from_platform !== null && props.dataItem.from_platform !== undefined) ?
                                                                          (props.dataItem.from_platform)? "highlight-row-from-plat" : ""
                                                                          : ""
                                                                    }>
                                                                      {props.dataItem.prenotation}

                                                                    </td>
                                                                )}
                                                                />
                                                            )
                                                : field === "total_number" ? (
                                                        <Column
                                                            key={index}
                                                            field={field}
                                                            title={title}
                                                            format={format}
                                                            width={width}
                                                            cell={(props: GridCellProps) => (
                                                                <td style={{textAlign: "center"}}  className={
                                                                  (props.dataItem.from_platform !== null && props.dataItem.from_platform !== undefined) ?
                                                                      (props.dataItem.from_platform)? "highlight-row-from-plat" : ""
                                                                      : ""
                                                                }>
                                                                  {(props.dataItem.total_number)}

                                                                </td>
                                                            )}
                                                        />
                                                    )
                        : field === "staffs" ? (
                          <Column
                            key={index}
                            field={field}
                            title={title}
                            format={format}
                            width={width ? width :"240"}
                            cell={(props: GridCellProps) => (
                              <td>
                                {(props.dataItem.staffs) ?


                                  (props.dataItem.staffs.length > 2) ?
                                    (
                                      <span>
                                        {props.dataItem.staffs[0].name}{" "}
                                        {props.dataItem.staffs[0].surname}
                                        <br />{" "}
                                        {props.dataItem.staffs[1].name}{" "}
                                        {props.dataItem.staffs[1].surname}

                                        {" ..."}
                                      </span>)
                                    :
                                    (
                                      <span>
                                        {(props.dataItem.staffs[0]) ? props.dataItem.staffs[0].name : ""}{" "}
                                        {(props.dataItem.staffs[0]) ? props.dataItem.staffs[0].surname : ""}
                                        <br />{" "}
                                        {(props.dataItem.staffs[1]) ? props.dataItem.staffs[1].name : ""}{" "}
                                        {(props.dataItem.staffs[1]) ? props.dataItem.staffs[1].surname : ""}

                                      </span>)

                                  : null
                                }
                              </td>
                            )}
                          />
                        )
                          : field === "active" ? (
                            <Column
                              key={index}
                              filterable={false}
                              field={field}
                              title={title}
                              format={format}
                              width={width ? width :"90"}
                              cell={(props: GridCellProps) => (
                                <td>
                                  {(props.dataItem.active) ?
                                    "Attiva"
                                    : "Non attiva"
                                  }
                                </td>
                              )}
                            />
                          )
                            : (

                                     <Column
                                        key={index}
                                        field={field}
                                        width={(width && width!=="" ) ?  width : "auto"}
                                        className={"prova " + className }
                                        title={title}
                                        format={format}
                                        cell={(props: GridCellProps) => (
                                            <td className={
                                              (props.dataItem.from_platform !== null && props.dataItem.from_platform !== undefined) ?
                                                  (props.dataItem.from_platform)? "highlight-row-from-plat" : ""
                                                  : ""
                                            }>
                                              {(props.dataItem[field] !== null && props.dataItem[field] !== undefined) ?
                                                  props.dataItem[field] : ""
                                              }
                                            </td>
                                        )}
                                        // width={width ?? (window.innerWidth < 900 ? "200" : "")}
                                    />



                            )
            )}

            <Column
              className={"actions "}
              cell={MyEditCommandCell}
              filterable={false}
              sortable={false}
              width={customFormComponent ? "190px" : "170px"}
            />
            {/*<Column*/}
            {/*  className={"actions "}*/}
            {/*  cell={MyEditCommandCell}*/}
            {/*  filterable={false}*/}
            {/*  sortable={false}*/}
            {/*  width="170px"*/}
            {/*/>*/}
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
      {openAddForm && AddFormComponentDynamic}
      {openUpdateForm && UpdateFormComponentDynamic}
      {openCustomForm && CustomFormComponentDynamic}
      {openDeleteForm && DeleteDialogDynamic}
    </React.Fragment>
  );
};

export default GenericGrid;
