import { useAppSelector } from "../../store";
import { useGetExperiencesQuery } from "store/api/experiences";
import { useCurrentPlanner } from "./currentPlanner";

export function useCurrentExperience() {

  const currentPlanner = useCurrentPlanner();
  const id = useAppSelector((s) => s.experiences.currentExperienceId);
  const { data: experiences = [] } = useGetExperiencesQuery(currentPlanner?.id as number);

  return experiences.find((p) => p.id === id);
}
