import {FieldRenderProps} from "@progress/kendo-react-form";
import {Input, NumericTextBox, Switch} from "@progress/kendo-react-inputs";
import {DropDownList, MultiSelect} from "@progress/kendo-react-dropdowns";

import {Error} from "@progress/kendo-react-labels";
import {Icon, TextField,} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import {IBase} from "models/entities/IBase";
import React, {useEffect, useState} from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {IType} from "models/entities/types/IType";
import CustomDatePicker from "./CustomDatePicker";
import {requireValidator} from "../../utils/validators/requireValidator";
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebookF, faInstagram} from '@fortawesome/free-brands-svg-icons'
import PlannerIcon from "@mui/icons-material/LocalLibrary";
import {useAppSelector} from "../../store";
import {NumberFormatOptions} from "@progress/kendo-react-intl";
import {checkIban} from "../../utils/validators/ibanValidator";

library.add(faFacebookF);
library.add(faInstagram);
export const AutocompleteCity = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    visited,
    val,
    label,
  } = fieldRenderProps;


    const { apikey } = useAppSelector((store) => store.types);
    const UpdateField = (city: string, isEff: boolean) => {

        let targetDiv = null;

        let targetError = null;
        targetError = window.document.getElementsByClassName("comune-validator")[0]!;


        if(targetError !== undefined && targetError.classList !==undefined) {
            targetError.classList.add("display-true");
        }
        // targetError.classList.add("display-true");
        //PER INIZIALIZZARE L'AUTOCOMPLETE
        targetDiv = window.document.getElementById("auto-city-prova")!;
        if (isEff) {

            if (targetDiv !== null && targetDiv !== undefined) {
                if (
                    targetDiv.getElementsByClassName("css-1wa3eu0-placeholder")[0] !== undefined
                ) {
                    targetDiv.getElementsByClassName(
                        "css-1wa3eu0-placeholder"
                    )[0].textContent = city;
                    targetDiv
                        .getElementsByClassName("css-1wa3eu0-placeholder")[0]
                        .classList.add("css-1uccc91-singleValue");
                }
                // console.log() css-1uccc91-singleValue
            }
        } else {
            if (targetDiv !== null && targetDiv !== undefined) {
                targetDiv.getElementsByClassName(
                    " css-1uccc91-singleValue"
                )[0].textContent = city;
                // console.log()
                if(targetError !== undefined && targetError.classList !==undefined)
                {
                    targetError.classList.remove("display-true");
                }
            }
        }
    };
      useEffect(()=> {
          UpdateField(fieldRenderProps.value,true);
// eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);


  const handleChange  = (e : any) => {

      fieldRenderProps.onChange(e);
  }



  return (
    <div>
      <div
        id="auto-city-prova"
        className="std-dropdown-container dropdownModifica custom-unif-font-2 custom-unif-dropdown-focus"
      >
        <label
          className="labelModifica std-dropdown-label"
          data-shrink="true"
          style={{zIndex: 999}}
        >
          {label}
        </label>
        <GooglePlacesAutocomplete
          apiKey={apikey}
          selectProps={{
            value: val || fieldRenderProps.value,
            onChange: handleChange,
            placeHolder: "Cerca il comune...",
            // filterOption:customFilter,
            loadingMessage: () => "Cercando...",
            // defaultValue:"Cerca il comune...",
            // // inputValue:"Cerca il comune...",
              
              // !! ** togliere per togliere il rosso ad inizio del campo
              startAdornment: (
                  <InputAdornment  className={'std-adornment'} position="start">
                      {validationMessage ? (
                          validationMessage && <Icon color="error">short_text</Icon>
                      ) : (
                          <span />
                      )}
                  </InputAdornment>
              ),
          }}
          onLoadFailed={(error => console.log(error))}
          withSessionToken={false}
          autocompletionRequest={{ types: ["(cities)"] }}
          apiOptions={{ language: "it", region: "it",  apiKey: apikey }}
        />
      </div>
     <Error className={"comune-validator " + (visited && validationMessage) ? "display-true" : "" } style={{display:"none"}}>{validationMessage}</Error>
    </div>
  );
};

export const TimeDuration = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, value, onChange, minVal, adornment, ...others } =
        fieldRenderProps;

    const [localValue, setLocalValue] = useState(value || 0);

    return (
        <div>
            <TextField
                {...others}
                fullWidth
                variant="outlined"
                type="number"
                value={localValue}
                onChange={(e) => {
                    if (e.target.value) {

                    setLocalValue(e.target.value);

                    }else{
                        setLocalValue(e.target.value);
                        let x : any = 0;
                        onChange(x);
                    }
                }}
                onBlur={(e) => {
                    if (e.target.value) {
                        let v = parseInt(e.target.value);
                        let resto = v % 5;
                        v = resto < 2.5 ? v - resto : v + (5 - resto);
                        setLocalValue(v);
                        onChange(e);
                    }
                }}
                inputProps={{
                    min: 0 || minVal,
                    step: "5",
                }}
                InputProps={{
                    inputMode: "numeric",
                    startAdornment: (
                        <InputAdornment className={'std-adornment'} position="start">

                            {validationMessage ? (
                                validationMessage && <Icon color="error">short_text</Icon>
                            ) : (
                                <span />
                            )}
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment className={'std-adornment'} position="end">
                            {adornment ?
                                (
                                    adornment
                                ) : ""
                            }
                        </InputAdornment>
                    )
                }}
            />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

export const TimeDurationLength = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, value, onChange, minVal,adornment, iconText, ...others } =
        fieldRenderProps;

    const [localValue, setLocalValue] = useState(value || 0);

    return (
        <div>
            <TextField
                {...others}
                fullWidth
                variant="outlined"
                type="number"
                value={localValue}
                onChange={(e) => {
                    // if (e.target.value) {
                    setLocalValue(e.target.value);

                    // }
                }}
                onBlur={(e) => {
                    if (e.target.value) {
                        let v = parseInt(e.target.value);
                        let resto = v % 5;
                        v = resto < 2.5 ? v - resto : v + (5 - resto);
                        setLocalValue(v);
                        onChange(e);
                    }
                }}
                inputProps={{
                    min: 0 || minVal,
                    step: "1",
                }}
                InputProps={{
                    inputMode: "numeric",
                    startAdornment: (
                        <InputAdornment  className={'std-adornment'} position="start">
                            {iconText ? (
                                <Icon> {iconText}</Icon>
                                ) : ""
                            }
                            {validationMessage ? (
                                validationMessage && <Icon color="error">short_text</Icon>
                            ) : (
                                <span />
                            )}
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment className={'std-adornment'} position="end">
                            {adornment ?
                                (
                                    adornment
                                ) : ""
                            }
                        </InputAdornment>
                    )
                }}

            />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};
export const TimeDurationLengthSched = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, value, onChange, minVal,adornment, iconText, ...others } =
        fieldRenderProps;

    const [localValue, setLocalValue] = useState(fieldRenderProps.realValue || 60);

    useEffect(()=>{

        setLocalValue(fieldRenderProps.realValue);
        fieldRenderProps.form.onChange("length_minutes", {
            value: fieldRenderProps.realValue,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[fieldRenderProps.realValue] )

    const handleChange = (e: any) => {
        setLocalValue(e.target.value);
        fieldRenderProps.onChange(e);
    }

    return (
        <div>
            <TextField
                {...others}
                fullWidth
                variant="outlined"
                type="number"
                value={localValue}
                onChange={handleChange}
                onBlur={(e) => {
                    if (e.target.value) {
                        let v = parseInt(e.target.value);
                        let resto = v % 5;
                        v = resto < 2.5 ? v - resto : v + (5 - resto);
                        setLocalValue(v);
                        fieldRenderProps.onChange(e);
                    }
                }}
                inputProps={{
                    min: 0 || minVal,
                    step: "5",
                }}
                InputProps={{
                    inputMode: "numeric",
                    startAdornment: (
                        <InputAdornment className={'std-adornment'} position="start">
                            {iconText ? (
                                <Icon> {iconText}</Icon>
                            ) : ""
                            }
                            {validationMessage ? (
                                validationMessage && <Icon color="error">short_text</Icon>
                            ) : (
                                <span />
                            )}
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment className={'std-adornment'} position="end">
                            {adornment ?
                                (
                                    adornment
                                ) : ""
                            }
                        </InputAdornment>
                    )
                }}

            />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};


export const TimeDurationHour = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, value, onChange, defaultVal, ...others } =
        fieldRenderProps;

    const [localValue, setLocalValue] = useState((value!==undefined) ? value: ((defaultVal!==undefined)? defaultVal: 0));
    const changeHandler = (event: any) => {
        setLocalValue(event.target.value);

        fieldRenderProps.onChange({ value: event.newState });
    }


    return (
        <div>
            <TextField
                {...others}
                fullWidth
                helperText="In ore"
                variant="outlined"
                type="number"
                value={localValue}
                onChange={changeHandler}

                inputProps={{
                    min: 0,
                    step: "1",
                }}
                InputProps={{
                    inputMode: "numeric",
                    startAdornment: (
                        <InputAdornment className={'std-adornment'} position="start">
                            {validationMessage ? (
                                validationMessage && <Icon color="error">short_text</Icon>
                            ) : (
                                <span />
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

export const ValidatedDatePickerInput = (
  fieldRenderProps: FieldRenderProps
) => {
  const { validationMessage, visited, label, value, onChange, ...others } =
    fieldRenderProps;


  return (
    <div>
      <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth dropdownModifica custom-unif-dropdown-focus">
        <label
          className="labelModifica MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-filled"
          data-shrink="true" style={{paddingLeft:"2px"}}
        >
          {label}
        </label>
        <div className=" spanFiglia-daIngrandire MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedStart MuiOutlinedInput-adornedStart">
          <CustomDatePicker
            {...others}
            value={(value)? value as Date : null}
            // disabled={!currentAppointment?.id}
            format="dd/MM/yyyy"
            required={true}
            validator={[requireValidator]}
            onChange={onChange}
            min={new Date()}
            InputProps={{

              startAdornment: (
                <InputAdornment  className={'std-adornment'} position="start">
                  {validationMessage ? (
                    validationMessage && <Icon color="error">short_text</Icon>
                  ) : (
                    <span />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

//https://material-ui.com/components/text-fields/
export const ValidatedInput = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, label,defaultVal, adornment, value,  ...others } =
    fieldRenderProps;


  return (
    <div>
      <TextField

          className={"custom-unif-font"}
        {...others}
        label={label}
        value={fieldRenderProps.value}
        fullWidth
          defaultValue={(defaultVal=== " ")?   "" : (defaultVal === 0 || defaultVal)? defaultVal: null}

          rows={fieldRenderProps.rows? fieldRenderProps.rows: 1 }
          multiline={!!fieldRenderProps.rows}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment className={'std-adornment'} position="start">
              {validationMessage ? (
                validationMessage && <Icon color="error">short_text</Icon>
              ) : (
                <span />
              )}
            </InputAdornment>
          ),
            endAdornment: (
                <InputAdornment  className={'std-adornment'} position="end">
                    {adornment ?
                        (
                            adornment
                        ) : ""
                    }
                </InputAdornment>
            )
        }}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const ValidatedInputUpperCase = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, label, value,adornment, ...others } =
        fieldRenderProps;

    return (
        <div>

            <TextField
                className={"custom-unif-font"}
                {...others}
                onChange={(e :any)=>fieldRenderProps.onChange({value: e.target.value.toString().toUpperCase()})}
                label={label}
                value={fieldRenderProps.value}
                fullWidth
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment className={'std-adornment'} position="start">
                            {validationMessage ? (
                                validationMessage && <Icon color="error">short_text</Icon>
                            ) : (
                                <span />
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

export const ValidatedIBANField = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, label, value,adornment, ...others } =
        fieldRenderProps;

    return (
        <div>

            <TextField
                className={"custom-unif-font"}
                {...others}
                onChange={(e :any)=>fieldRenderProps.onChange({value: e.target.value.toString().toUpperCase()})}
                label={label}
                value={fieldRenderProps.value}
                fullWidth
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment className={'std-adornment'} position="start">
                            {validationMessage ? (
                                validationMessage && <Icon color="error">short_text</Icon>
                            ) : (
                                <span />
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            {(fieldRenderProps.value && !checkIban(fieldRenderProps.value)) && <Error style={{color: "#9e6702"}}>Attenzione l'iban potrebbe non essere valido</Error>}
        </div>
    );
};

export const ValidatedInputNumber = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, val, defaultVal,adornment, minVal, maxVal, title, ...others } = fieldRenderProps;

    return (
        <div>
            <TextField
                {...others}
                fullWidth
                type="number"
                variant="outlined"
                title={title}
                defaultValue={(defaultVal=== " ")?   "" : (defaultVal === 0 || defaultVal)? defaultVal: null}

                inputProps={{
                    min: (minVal)? minVal : 0,
                    max: (maxVal)? maxVal : null,
                }}
                InputProps={{
                    inputMode: "numeric",
                    startAdornment: (
                        <InputAdornment className={'std-adornment'} position="start">
                            {validationMessage ? (
                                validationMessage && <Icon color="error">short_text</Icon>
                            ) : (
                                <span />
                            )}
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment className={'std-adornment'} position="end">
                            {adornment ?
                                (
                                    adornment
                                ) : ""
                            }
                        </InputAdornment>
                    )
                }}
            />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
            {fieldRenderProps.showError && <Error>Preavviso ampio deve essere maggiore di preavviso stretto</Error>}
        </div>
    );
};

export const ValidatedInputNumberKendo = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited,  label, defaultVal,adornment, minVal, step, format,disabled, required } = fieldRenderProps;

    const formatOptions: NumberFormatOptions = {
        style: "currency",
        currency: "EUR",
        currencyDisplay: "name",
    };

    const [value,setValue] = useState<number|null>(fieldRenderProps.value)
    return (
        <div>
            <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth dropdownModifica custom-unif-dropdown-focus">
                <label
                    className="labelModifica MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-filled"
                    data-shrink="true" style={{paddingLeft:"2px"}}
                >
                    {label}
                </label>
                <div className=" spanFiglia-daIngrandire MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedStart MuiOutlinedInput-adornedStart">

                <NumericTextBox
                    step={(step)? step : 1}
                    min={(minVal)? minVal : 0}
                    format={format === 'eur' ? formatOptions : undefined}
                    defaultValue={(defaultVal=== " ")?   "" : (defaultVal === 0 || defaultVal)? defaultVal: null}
                    onChange={(e)=>{
                        setValue(e.value)
                        fieldRenderProps.onChange(e);
                    }}
                    required={required}
                    disabled={disabled ?? false}
                    value={value}


            />
                </div>
                {adornment && <label className={"custom-unif-font labelModifica custom-end-adornment"}>{adornment}</label>}
            </div>
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

export const ValidatedInputNumberHour = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, val, defaultVal, ...others } = fieldRenderProps;
    return (
        <div>
            <TextField
                {...others}
                fullWidth
                type="number"
                variant="outlined"
                helperText={"In ore"}
                defaultValue={0 || defaultVal}
                inputProps={{
                    min: 0,
                }}
                InputProps={{
                    inputMode: "numeric",
                    startAdornment: (
                        <InputAdornment className={'std-adornment'} position="start">
                            {validationMessage ? (
                                validationMessage && <Icon color="error">short_text</Icon>
                            ) : (
                                <span />
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

export const ValidatedFacebook = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
        <div>
            <TextField
                {...others}
                fullWidth
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment className={'std-adornment'} position="start">
                            <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                        </InputAdornment>
                    ),
                }}
            />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};
export const ValidatedInstagram = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
        <div>
            <TextField
                {...others}
                fullWidth
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment className={'std-adornment'} position="start">
                            <FontAwesomeIcon icon={['fab', 'instagram']} />
                        </InputAdornment>
                    ),
                }}
            />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

export const ValidatedWebsite = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
        <div>
            <TextField
                {...others}
                fullWidth
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment className={'std-adornment'} position="start">
                            <Icon>language</Icon>
                        </InputAdornment>
                    ),
                }}
            />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

export const ValidatedEmail = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <TextField
        {...others}
        fullWidth
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment className={'std-adornment'} position="start">
              <Icon>email</Icon>
            </InputAdornment>
          ),
        }}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const ValidatedMobile = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <TextField
        {...others}
        fullWidth
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment className={'std-adornment'} position="start">
              <Icon>smartphone</Icon>
            </InputAdornment>
          ),
        }}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const ValidatedInitialsInput = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <TextField
        {...others}
        fullWidth
        inputProps={{
          maxLength: 10,
        }}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment className={'std-adornment'} position="start">
              {validationMessage ? (
                validationMessage && <Icon color="error">short_text</Icon>
              ) : (
                <span />
              )}
            </InputAdornment>
          ),
        }}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};
export const ValidatedSwitch = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Switch
        {...others}
        value={others.value ?? false}
        checked={others.value ?? false}
      />
      <span style={{ marginLeft: 10 }}>{others.label}</span>

      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export interface CustomSwitchProps {
    label: string;
    value: boolean;
    onChange: (event: {
        target?: any;
        value?: any;
    }) => void;
}
export const CustomSwitch = (fieldRenderProps: CustomSwitchProps) => {
  return (
    <div>
      <Switch
          onChange={fieldRenderProps.onChange}
            value={fieldRenderProps.value }
            checked={fieldRenderProps.value }
      />
      <span style={{ marginLeft: 10 }}>{fieldRenderProps.label}</span>

    </div>
  );
};

export const ValidatedDropDownList = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, value, source, label, val, displError , ...others } =
    fieldRenderProps;

  return (
    <div>
      <div className="std-dropdown-container dropdownModifica custom-unif-dropdown-focus">
        <label
          className="labelModifica std-dropdown-label"
          data-shrink="true"
        >
          {label}
        </label>
        <div className="std-dropdown-component custom-unif-font-2">
          <DropDownList data={source} value={value} {...others} />
        </div>
      </div>
        {/*{visited && validationMessage && <Error>{validationMessage}</Error>}*/}
        {displError && <Error>Campo obbligatorio</Error>}
    </div>
  );
};

export const ValidatedDropDownListKeyLabelTypes_customBehavior = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, value, data,  label,  defaultVal, disabled } =
        fieldRenderProps;


    return (
        <div>
            <div className="std-dropdown-container dropdownModifica custom-unif-dropdown-focus">
                <label
                    className="labelModifica std-dropdown-label"
                    data-shrink="true"
                >
                    {label}
                </label>
                <div className="border-custom-components std-dropdown-component custom-unif-font ">
                    <DropDownList
                        disabled={disabled}
                        dataItemKey={'key'}
                        textField={'label'}
                        data={data}
                        onChange={(e)=> {
                            fieldRenderProps.onChange(e)
                        }}
                        // valueRender={keyLabelValueRender}
                        value={(value)?
                            ((typeof value == 'object') ?  data?.find((el:any) => el.key === value.key ) :
                            data?.find((el:any) => el.label === value ) ?? data?.find((el:any) => el.key === value ) )
                            :
                            data.find((el:any) => el.key ===defaultVal )} />
                </div>
            </div>
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

export interface DropDownListKeyLabelTypesComponentProps {
    additionalClassNames?: string;
    label?: string;
    disabled?: boolean;
    onChange: (e: any)=>void;
    data: {
        key: any;
        label: any;
    }[];
    value: {
        key: any;
        label: any;
    };
    defaultVal?:{
        key: any;
        label: any;
    };

}
export const DropDownListKeyLabelTypesComponent = (fieldRenderProps: DropDownListKeyLabelTypesComponentProps) => {

    return (
            <div className={"std-dropdown-container dropdownModifica custom-unif-dropdown-focus "+(fieldRenderProps.additionalClassNames?? '')}>
                <label
                    className="labelModifica std-dropdown-label"
                    data-shrink="true"
                >
                    {fieldRenderProps.label}
                </label>
                <div className="border-custom-components std-dropdown-component custom-unif-font ">
                    <DropDownList
                        disabled={fieldRenderProps.disabled ?? false}
                        dataItemKey={'key'}
                        textField={'label'}
                        data={fieldRenderProps.data}
                        onChange={(e)=> fieldRenderProps.onChange(e)}
                        // valueRender={keyLabelValueRender}
                        value={ (fieldRenderProps.value) ? fieldRenderProps.data.find((el:any) => el.key === fieldRenderProps.value.key ) :
                            fieldRenderProps.data.find((el:any) => el.key === fieldRenderProps.defaultVal )} />
                </div>
            </div>
    );
};
export const ValidatedDropDownListKeyLabelTypes = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, value, data,  label, defaultVal, disabled } =
        fieldRenderProps;


    return (
        <div>
            <div className="std-dropdown-container dropdownModifica custom-unif-dropdown-focus">
                <label
                    className="labelModifica std-dropdown-label"
                    data-shrink="true"
                >
                    {label}
                </label>
                <div className="border-custom-components std-dropdown-component custom-unif-font ">
                    <DropDownList
                        disabled={disabled}
                        dataItemKey={'key'}
                        textField={'label'}
                        data={data}
                        onChange={(e)=> fieldRenderProps.onChange(e)}
                        // valueRender={keyLabelValueRender}
                        value={(value)? data.find((el:any) => el.key ===value.key ) : data.find((el:any) => el.key ===defaultVal )} />
                </div>
            </div>
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

export const ValidatedDropDownListPrenTypes = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, value, data,  label,  defaultVal } =
        fieldRenderProps;

    return (
        <div>
            <div className="std-dropdown-container dropdownModifica custom-unif-dropdown-focus">
                <label
                    className="labelModifica std-dropdown-label"
                    data-shrink="true"
                >
                    {label}
                </label>
                <div className="border-custom-components std-dropdown-component custom-unif-font ">
                    <DropDownList
                        data={ data }
                        textField={'label'}
                        dataItemKey={'key'}
                        onChange={(e)=> fieldRenderProps.onChange(e)}
                        value={ (value)?
                            (data.find((el:any) => el.key === value.key)?
                                data.find((el:any) => el.key === value.key) :
                                data.find((el:any) => el.key === value) ):
                            defaultVal} />
                </div>
            </div>
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

export const ValidatedDropDownListId = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, value, data, label, ...others } =
    fieldRenderProps;

  useEffect(()=>{
      // console.log(fieldRenderProps.useDefaultValue);
      if(value===undefined){
          if(fieldRenderProps.useDefaultValue) {
              fieldRenderProps.onChange({value: data[0].id})
          }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <div>
      <div className="std-dropdown-container dropdownModifica custom-unif-dropdown-focus ">
        <label
          className="labelModifica  std-dropdown-label"
          data-shrink="true"
        >
          {label}
        </label>
        <div className="std-dropdown-component">
          <DropDownList
            data={data}
            className={"custom-unif-font-2 "}
            // defaultValue={(fieldRenderProps.useDefaultValue ) ?  data?.find((e: IBase) => e.id === data[0].id) : null}
            value={(value===undefined)?
                ((fieldRenderProps.useDefaultValue ) ?
                    (  data?.find((e: IBase) => e.id === data[0].id)) :  data?.find((e: IBase) => e.id === value))
                :  data?.find((e: IBase) => e.id === value)}
            {...others}
          />
        </div>
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const ValidatedDropDownListLocationId = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, value, data, label, ...others } =
        fieldRenderProps;
    useEffect(()=>{
        // console.log(fieldRenderProps.useDefaultValue);
        if(value===undefined){
            if(fieldRenderProps.useDefaultValue && data) {
                fieldRenderProps.onChange({value: data[0].id})
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div>
            <div className="std-dropdown-container dropdownModifica custom-unif-dropdown-focus ">
                <label
                    className="labelModifica   std-dropdown-label"
                    data-shrink="true"
                >
                    {label}
                </label>
                <div className="std-dropdown-component">
                    <DropDownList
                        data={data}
                        className={"custom-unif-font-2 "}
                        // defaultValue={(fieldRenderProps.useDefaultValue ) ?  data?.find((e: IBase) => e.id === data[0].id) : null}
                        value={(value===undefined)?
                            ((fieldRenderProps.useDefaultValue && true) ?
                                (  data?.find((e: IBase) => e.id === data[0].id)) :  data?.find((e: IBase) => e.id === value))
                            : (typeof value === "object")? data?.find((e: IBase) => e.id === value.id) : data?.find((e: IBase) => e.id === value)}
                        {...others}
                    />
                </div>
            </div>
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};
export const ValidatedDropDownListKey = (
  fieldRenderProps: FieldRenderProps
) => {
  const { validationMessage, visited, value, data, label, ...others } =
    fieldRenderProps;


  return (
    <div>
      <div className="std-dropdown-container dropdownModifica">
        <label
          className="labelModifica  std-dropdown-label"
          data-shrink="true"
        >
          {label}
        </label>
        <div className="custom-unif-font-2 std-dropdown-component">
          <DropDownList
            data={data}
            // onChange={(e)=>fieldRenderProps.onChange(e)}
            value={(typeof value === "object")? data?.find((e: IType) => e.key === value.key) : data?.find((e: IType) => e.key === value)}
            {...others}
          />
        </div>
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const ValidatedMultiSelect = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, value, source, label, ...others } =
        fieldRenderProps;

    let element: HTMLElement = document.getElementById("roles"+value) as HTMLElement;
    const handleClick = () =>{
        element.click();
    }

    return (
        <div>
            <div className="std-multiselect-container dropdownModifica multiselect-dropdown custom-unif-dropdown-focus" onClick={handleClick}>
                <label
                    className="labelModifica std-multiselect-label"
                    data-shrink="true"
                >
                    {label}
                </label>
                <div className="std-multiselect-component">
                    <MultiSelect  className={"roles-multiselect"} id={"roles"+value} data={source} value={value}  {...others} />
                </div>
            </div>

            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

export const ValidatedMultiSelectLan = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, value, source, label, ...others } =
        fieldRenderProps;

    return (
        <div>
            <div className="std-multiselect-container dropdownModifica multiselect-dropdown custom-unif-dropdown-focus" >
                <label
                    className="labelModifica std-multiselect-label"
                    data-shrink="true"
                >
                    {label}
                </label>
                <div className="std-multiselect-component">
                    <MultiSelect   data={source} value={value}  {...others} />
                </div>
            </div>

            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};
export const ValidatedMultiSelectAgende = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, value, source, label, ...others } =
        fieldRenderProps;


    return (
        <div>
            <div className="std-multiselect-container dropdownModifica multiselect-dropdown custom-unif-dropdown-focus">
                <label
                    className="labelModifica std-multiselect-label"
                    data-shrink="true"
                >


                        {label}
                    {/*</span>*/}
                </label>
                <div className="std-multiselect-component">
                     <span
                         className={"multiselect-icon"}
                         key={1}
                         style={{
                             display: "inline",
                             // background: color,
                             color: "black",
                             width: "26px",
                             height: "18px",
                             textAlign: "left",
                             marginRight: "10px",
                             marginTop: "-6px"
                         }}
                     > <PlannerIcon color="inherit" /></span>
                    <MultiSelect className={"roles-multiselect"} data={source} value={value}   {...others} />
                </div>
            </div>

            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

export const ValidatedInputKendo = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};
export const ValidatedTextArea = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, label, ...others } = fieldRenderProps;
  return (
    <div>
      <TextField
        {...others}
        fullWidth
        label={label}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment className={'std-adornment'} position="start">
              {validationMessage ? (
                validationMessage && <Icon color="error">short_text</Icon>
              ) : (
                <span />
              )}
            </InputAdornment>
          ),
        }}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};
