import {Fragment} from "react";
import {IStaff} from "../../models/entities/IStaff";
import {useAppSelector} from "../../store";
import CenteredLoadingSpinner from "../../components/common/CenteredLoadingSpinner";
import GenericGrid from "../../components/common/GenericGrid";
import TabsStaffForm from "../../components/staff/TabsStaffForm";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import {useDeleteStaffMutation, useGetStaffsQuery} from "store/api/staff";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {SCREEN_LG, SCREEN_MD, useWindowSize} from "../../components/common/ScreenSizes";

const StaffPage = () => {
  // const dispatch = useAppDispatch();
  // const {
  //   staff,
  //   getAllStaffState,
  //   addStaffState,
  //   updateStaffState,
  //   deleteStaffState,
  // } = useAppSelector((store) => store.staff);
  const { currentStaffId } = useAppSelector((store) => store.staff);
  const currentPlanner = useCurrentPlanner();
  const {
    data: staffs,
    isFetching,
  } = useGetStaffsQuery(currentPlanner?.id ?? skipToken);
  const [deleteStaff, ] = useDeleteStaffMutation();

  const remove = (dataItem: IStaff) => {
    deleteStaff(dataItem.id as number);
  };


  const size = useWindowSize();

  let roleWidth = null;
  let notifWidth = null;
  let langWidth = null;
  let prioWidth = null;

  if(size) {
    roleWidth   = (size < 650) ? "0" : null;
    notifWidth  = (size < 770) ? "0" : "110";
    langWidth   = (size < SCREEN_MD) ? "0" : null;
    prioWidth   = (size < SCREEN_LG) ? "0" :null;
  }

  return (
    <div style={{ padding: "2.5vh" }} className="custom-unif-font staff-table">
      {/* <PageTitle icon="group" title="Personale" /> */}
      {isFetching && !currentStaffId ? (
        <CenteredLoadingSpinner />
      ) : (
        <Fragment>
          <GenericGrid
            icon="group" title="Personale"
            items={staffs as IStaff[]}
            titleDelete={"Conferma cancellazione personale"}
            textDelete={"Sicuro di voler cancellare il personale"}
            columns={[
              // { field: "name", title: "Nome" },
              // { field: "name", title: "Cognome" },
              roleWidth=== null ? { field: "color", title: "Colore" } : { field: "sta123ffs", title: "Personale",  width :"0" },
              { field: "initials", width:(roleWidth!== null? (  "250"): "180"), title: "Nome breve" },
              roleWidth=== null?{ field: "planner_role", title: "Ruolo",  width : (roleWidth!== null? (  roleWidth): "") }: { field: "sta123ffs", title: "Personale",  width :"0" },
              { field: "notify", title: "Notifiche", filter: "boolean" ,  width : (notifWidth!== null? (  notifWidth): "") },
              roleWidth=== null?{ field: "languages", title: "Lingue parlate",   width : (langWidth!== null? (  langWidth): "")  }:  { field: "sta123ffs", title: "Personale",  width :"0" },
              { field: "priority", title: "Priorità",   width : (prioWidth!== null? (  prioWidth): "")  },
              // { field: "isVisible", title: "Visibile", filter: "boolean" },
            ]}
            enableCancel={true}
            onRemove={remove}
            addFormComponent={TabsStaffForm}
            updateFormComponent={TabsStaffForm}
          />
        </Fragment>
      )}
      {/* <ResponseSnackbar
        responseSnackbar={[
          {
            sliceState: addStaffState,
            resetFunction: () => resetAddStaff(),
          } as IResponseSnackbar,
          {
            sliceState: updateStaffState,
            resetFunction: () => resetUpdateStaff(),
          } as IResponseSnackbar,
          {
            sliceState: deleteStaffState,
            resetFunction: () => resetDeleteStaff(),
          } as IResponseSnackbar,
        ]}
      /> */}
    </div>
  );
};

export default StaffPage;
