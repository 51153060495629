import { useAppSelector } from "../../store";
import { useGetActiveUserQuery } from "store/api/auth";

export function useMe() {
  const { user } = useAppSelector((s) => s.auth);
  return user;
}
export function useMeInfo() {
  const { data: user, isFetching, refetch } = useGetActiveUserQuery();
  return { user, isFetching, refetch };
}
