import {Grid, Typography} from "@mui/material";
import BaseIconButton from "./BaseIconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React from "react";


const CustomTitleBar = (props: any) => {
    return (
        <Grid container alignItems="stretch" spacing={0} >
            <Grid item xs={12} className={"select-week-title"}>
                                <BaseIconButton
                                    style={{display: "inline-block"}}
                                    className={`select-week-title-child custom-button-uniformed  bottoni-tondi-responsive  std-bottoni-tondi ${
                                        (!(props.currentItem?.firstDayWeek === props.staffWeeks[0].firstDayWeek))
                                            ? "custom-unif-viola-back"
                                            : ""
                                    }`}
                                    disabled={
                                        (props.currentItem?.firstDayWeek === props.staffWeeks[0].firstDayWeek)
                                    }
                                    onClick={props.handlePrevious}
                                >
                                  <NavigateBeforeIcon fontSize="small" />
                                </BaseIconButton>
                                <Typography className={"select-week-title-child"} variant="h6"
                                            style={{display: "inline-block"}}>
                                    <b><span title={"nome breve"}>{props.initials}</span>: {props.currentItem?.label}</b>
                                </Typography>
                                <BaseIconButton
                                    style={{display: "inline-block"}}
                                    className={`select-week-title-child custom-button-uniformed bottoni-tondi-responsive std-bottoni-tondi ${
                                        (!(props.currentItem?.firstDayWeek === props.staffWeeks[props.staffWeeks.length-1].firstDayWeek))
                                            ? "custom-unif-viola-back"
                                            : ""
                                    }`}
                                    disabled={
                                        (props.currentItem?.firstDayWeek === props.staffWeeks[props.staffWeeks.length-1].firstDayWeek)
                                    }
                                    onClick={props.handleNext}
                                >
                                    <NavigateNextIcon fontSize="small" />
                                </BaseIconButton>
            </Grid>
        </Grid>
    );
};

export default CustomTitleBar;