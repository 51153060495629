import {api} from "./api";
import {IChat, IContactsResp, IMessage, INewMessages} from "../../models/entities/IMessage";


const notificationsApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({


    sendMessage: build.mutation<IMessage,  { chatId: string,  message: IMessage }>({
      query: ({chatId, message}) => ({
        url: `notification/sendMessage`,
        method: 'POST',
        body: {
          chatId,
          message
        }
      }),
      transformResponse: (res: IMessage, meta) => res,
      // invalidatesTags: (result, error, {id}) => error ? [] : [{type: 'Message', id}],
      // async onQueryStarted({id, ...company}, {dispatch, queryFulfilled}) {
      //   const putResult = dispatch(
      //       companiesApi.util.updateQueryData('getCompany', id, (draft) => {
      //         Object.assign(draft, company)
      //       })
      //   )
      //   try {
      //     await queryFulfilled
      //   } catch {
      //     putResult.undo()
      //   }
      //   // queryFulfilled.catch(putResult.undo)
      // },
    }),
    readMessage: build.mutation<boolean, IChat>({
      query: ( chat) => ({
        url: `notification/readMessage`,
        method: 'POST',
        body: chat
      }),
      transformResponse: (res: boolean, meta) => res,
      // invalidatesTags: (result, error, {id}) => error ? [] : [{type: 'Message', id}],
      // async onQueryStarted({id, ...company}, {dispatch, queryFulfilled}) {
      //   const putResult = dispatch(
      //       companiesApi.util.updateQueryData('getCompany', id, (draft) => {
      //         Object.assign(draft, company)
      //       })
      //   )
      //   try {
      //     await queryFulfilled
      //   } catch {
      //     putResult.undo()
      //   }
      //   // queryFulfilled.catch(putResult.undo)
      // },
    }),

    getMessages: build.query< INewMessages,  number >({
      query: ( id) => ({
        url: `notification/getMessages/${id}`,
        method: 'GET',
      }),
      transformResponse: (res: INewMessages, meta) => res,
      // invalidatesTags: (result, error, {id}) => error ? [] : [{type: 'Message', id}],
      // async onQueryStarted({id, ...company}, {dispatch, queryFulfilled}) {
      //   const putResult = dispatch(
      //       companiesApi.util.updateQueryData('getCompany', id, (draft) => {
      //         Object.assign(draft, company)
      //       })
      //   )
      //   try {
      //     await queryFulfilled
      //   } catch {
      //     putResult.undo()
      //   }
      //   // queryFulfilled.catch(putResult.undo)
      // },
    }),
    getContacts: build.query< IContactsResp, {  plannerId: number }>({
      query: ({ plannerId}) => ({
        url: `notification/getContacts/${plannerId}`,
        method: 'GET',
      }),
      transformResponse: (res: IContactsResp, meta) => res,
      // invalidatesTags: (result, error, {id}) => error ? [] : [{type: 'Message', id}],
      // async onQueryStarted({id, ...company}, {dispatch, queryFulfilled}) {
      //   const putResult = dispatch(
      //       companiesApi.util.updateQueryData('getCompany', id, (draft) => {
      //         Object.assign(draft, company)
      //       })
      //   )
      //   try {
      //     await queryFulfilled
      //   } catch {
      //     putResult.undo()
      //   }
      //   // queryFulfilled.catch(putResult.undo)
      // },
    }),
  }),
})

export const {
  useSendMessageMutation,
  useReadMessageMutation,
  useGetMessagesQuery,
  useGetContactsQuery,
} = notificationsApi