import { useAppSelector } from "../../store";
import { useGetAppointmentQuery } from "../../store/api/calendar";
import { skipToken } from "@reduxjs/toolkit/query/react";

export function useCurrentAppointment() {
  const currentAppointmentId = useAppSelector(
    (s) => s.calendar.currentAppointmentId
  );
  let selectedAppointment = useAppSelector(
    (s) => s.calendar.currentAppointmentItem
  );
  const {
    data: currentAppointment = selectedAppointment,
    isLoading: isLoadingCurrentAppointment,
    isSuccess: isSuccessCurrentAppointment,
    isFetching: isFetchingCurrentAppointment,
  } = useGetAppointmentQuery(currentAppointmentId ?? skipToken);
  return {
    currentAppointmentId,
    currentAppointment,
    isLoadingCurrentAppointment,
    isSuccessCurrentAppointment,
    isFetchingCurrentAppointment,
  };
}
