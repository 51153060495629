import {IBase} from "../../models/entities/IBase";
import {EditCommandCellProps} from "../../models/helpers/IEditCommandCellProps";
import EditIcon from "@mui/icons-material/Edit";
import BaseIconButton from "./BaseIconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {Grid} from "@mui/material";
import {useMe} from "../../utils/hooks/me";
import React from "react";

const EditCommandCell = <T extends IBase>(props: EditCommandCellProps<T>) => {
  const loggedUser = useMe();
  return (
    <td className={"actions " +
    (props.dataItem.from_platform !== null && props.dataItem.from_platform !== undefined) ?
        (props.dataItem.from_platform)? "actions-highlight highlight-row-from-plat" : ""
        : ""
    }>
      <Grid container direction="row">
        {props.dataItem.prenotation && props.dataItem.from_platform  &&
            (loggedUser?.role === "superadmin" || loggedUser?.userId !== loggedUser?.userRealId) &&
            <BaseIconButton
            color="primary"
            className={"custom-unif-viola "}

            onClick={() => props.enterCustom? props.enterCustom(props.dataItem) : null}
        >
          <span className="k-icon k-i-calendar" style={{fontSize: '20px'}}></span>
        </BaseIconButton>}
        <BaseIconButton
          color="primary"
          className={"custom-unif-viola "}
          onClick={() => props.enterEdit(props.dataItem)}
        >
          <EditIcon />
        </BaseIconButton>
        {/* &nbsp;&nbsp;&nbsp; */}


        {/*il problema è che alcune volte solo il superadmin può cancellare le cose, allora nascondo direttamente il pulsant per farlo , senza cambiare nessuna logica*/}
        { (props.isDeletable === undefined || props.isDeletable) ?
            ((props.dataItem.from_platform !== null && props.dataItem.from_platform !== undefined && props.dataItem.from_platform) ?
              (loggedUser?.role === "superadmin" || loggedUser?.userId !== loggedUser?.userRealId) ?
                  <BaseIconButton onClick={() => props.remove(props.dataItem)}>
                    <DeleteOutlineIcon/>
                  </BaseIconButton> : ""

              :
              <BaseIconButton onClick={() => props.remove(props.dataItem)}>
                <DeleteOutlineIcon/>
              </BaseIconButton>
        ) :
           ( <span style={{width: "48px"}}></span>)
        }

          { props.dataItem.url_active &&
            <BaseIconButton
                color="primary"
                className={"custom-unif-viola buttons-inside-grids button-open-link"}
                onClick={() => {
                  if(props.dataItem.url_generated) {
                    window.open(props.dataItem.url_generated, '_blank')!.focus();
                  }
                }}
            >
              <span className="k-icon k-i-hyperlink-open"></span>
            </BaseIconButton>
        }
      </Grid>
    </td>
  );
};

export default EditCommandCell;
