import React, {ChangeEvent, FC} from "react";
import {Grid, TextField} from "@mui/material";
import {NumberRangeInputProps} from "../../models/props/NumberRangeInputProps";
import InputAdornment from "@mui/material/InputAdornment";

const NumberRangeInput: FC<NumberRangeInputProps> = ({
  allowedMax = Infinity,
  allowedMin = -Infinity,
  children,
  maxLabel,
  minLabel,
  onChange,
  range
}) => {

  const onMinChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onChange({ min: isNaN(parseFloat(e.target.value))? 0 : parseFloat(e.target.value), max: range.max})
  const onMaxChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onChange({ min: range.min, max: isNaN(parseFloat(e.target.value))? 0 : parseFloat(e.target.value)})


  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={5} md={5} lg={5}>
        <TextField
          type={"text"}
          variant={"outlined"}
          fullWidth
          label={minLabel}
          value={range.min}
          onChange={onMinChange}
          error={range.min < allowedMin || range.min > range.max}

          // onWheel={event => { event.preventDefault(); }}
          InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                 anni
                </InputAdornment>
            ),
          }}
        />
      </Grid>
        <Grid item xs={12} sm={5} md={5} lg={5} >
        <TextField
          type={"text"}
          fullWidth
          variant={"outlined"}
          label={maxLabel}
          value={range.max}
          onChange={onMaxChange}
          error={range.max > allowedMax || range.min > range.max}

          InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                  anni
                </InputAdornment>
            ),
          }}
        />
        </Grid>
    </Grid>
  )
}

export default NumberRangeInput