import React, {useEffect, useState} from "react";
import {SchedulerForm, SchedulerFormProps, useSchedulerFieldsContext,} from "@progress/kendo-react-scheduler";
import {CustomSchedulerFormEditor} from "./CustomSchedulerFormEditor";
import {CustomSchedulerDialog} from "./CustomSchedulerDialog";
import {addMinutes, dateToStringDDMMYYYYwithsepator,} from "../../utils/dateHelper";
import {selectStaff} from "../../store/staff";
import {useAppDispatch, useAppSelector} from "../../store";
import {useCurrentAppointment} from "../../utils/hooks/currentAppointment";
import {resetAppointmentState, selectNewAppointment, setAppointmentTitle,} from "../../store/calendar";
import {createAppointmentFromSchedule} from "utils/appointmentHelper";
import {useGetCalendarDayQuery} from "store/api/calendar";
import {useCurrentPlanner} from "utils/hooks/currentPlanner";
import {resetState} from "../../store/changePassword";

export const CustomSchedulerForm = (props: SchedulerFormProps) => {
  const dispatch = useAppDispatch();
  const { appointmentTitle } = useAppSelector(
    (s) => s.calendar
  );
  const currentPlanner = useCurrentPlanner();
  const { currentAppointment } = useCurrentAppointment();


  const isNew = (!props.dataItem.experience_id)? true : false;
  useSchedulerFieldsContext(); //ho i nomi dei customModelFields
  const [item, setItem] = useState(props.dataItem);
  const {refetch } = useGetCalendarDayQuery(
    {
      planner_id: currentPlanner?.id as number,
      date: currentAppointment?.calendar_date as string,
    },
    {
      skip:
        !currentPlanner ||
        !currentAppointment
          ||
        !currentAppointment?.calendar_date,
    }
  );



  const handleClose = (e: any) => {

    resetAppointmentState();
    resetState();
    refetch();

    dispatch(resetAppointmentState());
    dispatch(resetState());
    if (props.onClose) props.onClose(e);
  };

  const generateTitle = () => {
    let dateStart = new Date(props.dataItem.start as string);
    dateStart = addMinutes(dateStart, -0);
    // let dateEnd = new Date(props.dataItem.end as string);
    // dateEnd = addMinutes(dateEnd, -0);
    let tit = `${dateToStringDDMMYYYYwithsepator(
      dateStart
    )}` ;
    if(!isNew) {
      tit += " " +item.begin_time + " - " + item.end_time;
    }


    if (appointmentTitle !== tit) setAppointmentTitle(tit);
    return tit;
  };

  const [titleCustom, setTitleCustom] = useState("");

  useEffect(() => {
    setTitleCustom(generateTitle());
    if (!props.dataItem.experience_id ) {
      props.dataItem.staff_id = props.dataItem.selected_staff_id;

      let newAppointment = createAppointmentFromSchedule(props.dataItem);
      dispatch(selectNewAppointment(newAppointment));
      setItem(newAppointment);
    }
    if (props.dataItem.staff_id) {
      dispatch(selectStaff(props.dataItem.staff_id));
    }
  }, []);

  useEffect(() => {

    //let tit = generateTitle();
    if (appointmentTitle !== "") setTitleCustom(appointmentTitle);
  }, [appointmentTitle]);

  // const requiredValidator = useCallback(
  //   (value) =>
  //     value === undefined || value === null || value === ""
  //       ? "Field is required."
  //       : undefined,
  //   []
  // );

  //console.log(fields) //???
  // const customValidator = useCallback(
  //   (_dataItem, formValueGetter) => {
  //     let result = {} as any;
  //     //result[fields.title] = [requiredValidator(formValueGetter(fields.title)), titleLengthValidator(formValueGetter(fields.title))].filter(Boolean).reduce((current, acc) => current || acc, '');
  //     result[fields.description] = [
  //       requiredValidator(formValueGetter(fields.description)),
  //     ]
  //       .filter(Boolean)
  //       .reduce((current, acc) => current || acc, "");
  //     return result;
  //   },
  //   [fields, requiredValidator, emailValidator, phoneNumberValidator]
  // );

  // const formValidator = (_dataItem: any, formValueGetter: any) => {
  //   console.log(_dataItem);
  //   let result: any = {};

  //   result.staff_id = [requiredValidator(formValueGetter("staff_id"))]
  //     .filter(Boolean)
  //     .reduce((current, acc) => current || acc, "");

  //   result.location_id = [requiredValidator(formValueGetter("location_id"))]
  //     .filter(Boolean)
  //     .reduce((current, acc) => current || acc, "");

  //   return result;
  // };

  return (
    <SchedulerForm
      {...props}
      editor={(props) => (
        <CustomSchedulerFormEditor {...props} closeIcon={isNew} dataItem={item} onClose={(e: any)=>handleClose(e)}/>
      )}
      dialog={(props) => (
        <CustomSchedulerDialog {...props} closeIcon={isNew} onClose={(e)=>handleClose(e)} title={titleCustom} />
      )}
      // validator={customValidator}
      onClose={handleClose}
      initialValues={item}
    />
  );
};
