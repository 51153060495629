import {Drawer, Icon, List, Theme} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {FC, Fragment, useEffect, useState} from "react";
import clsx from "clsx";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {Link, useLocation} from "react-router-dom";
import menu from "../../models/constants/menu.json";
import {useAppDispatch} from "../../store";
import {useMe} from "../../utils/hooks/me";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {useGetMessagesQuery} from "../../store/api/notifications";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    pusher: {
      paddingTop: 64,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: 0,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7.2) ,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  })
);

const POLLING_RATE = 300000;

const DrawerMenu: FC<{ opened: boolean }> = ({ opened }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [messages, setMessages] =useState<string>();
  const currentPlanner = useCurrentPlanner();

  const {
        data: numMessag,
        refetch
    } = useGetMessagesQuery(  currentPlanner?.id as number  ?? skipToken);


    useEffect(() => {
        const interval = setInterval(() => {
            refetch()
        }, POLLING_RATE);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])
    useEffect(() => {
       if(numMessag && numMessag?.bResult === true && numMessag?.newMessages !== ""){
           setMessages(numMessag.newMessages);
       }
    }, [numMessag]);


  const user = useMe()
  return (
    <Drawer
      variant="permanent"
      // variant="permanent"
      classes={{
        paper: clsx({
          [classes.drawerOpen]: opened,
          [classes.drawerClose]: !opened,
        }),
      }}
      anchor="left"
    >
      <div className={classes.pusher + " custom-unif-font drawer-menu-custom"}>
        <List>
          {menu?.map((item, index) => {
            return user === undefined || !item.roles.includes(user.role) ? (
              <Fragment key={index} />
            ) : (
              <ListItem key={index} button component={Link} to={item.path}>
                <ListItemIcon className={"icon-drawer-menu"}>
                  <Icon
                    color={pathname.includes(item.path) ? "primary" : undefined}
                  >
                    {item.icon}

                  </Icon>

                    {(item.label === "Chat" && messages) ?

                            <span className={"badge-numnotifiche "}>{messages}</span>

                        : ""
                    }
                </ListItemIcon>
                <ListItemText className={"text-drawer-menu"} primary={item.label} />
              </ListItem>
            );
          })}
        </List>
      </div>
    </Drawer>
  );
};

export default DrawerMenu;
