import {FC, Fragment, useEffect, useState} from "react";
import {Grid,} from "@mui/material";
import {IPrice} from "../../models/entities/prices/IPrice";
import {IExperience} from "../../models/entities/IExperience";
import {useGetExperienceQuery, useSavePriceMutation,} from "../../store/api/experiences";
import {skipToken} from "@reduxjs/toolkit/query/react";
import CenteredLoadingSpinner from "../common/CenteredLoadingSpinner";
import GroupPriceItem from "./GroupPriceItem";


export interface buttonStateManager {
    isActive          (id: string) : boolean;
    isOneBtnSelected  () : boolean;
    toggleActive      (id: string ) : void;
}


export interface priceLabelStateManager {
    isAdultSelectedYet  () : string | undefined;
    setAdultSelected  (id: string | undefined) : void;
}

const PRICE_TYPE = "gruppo";

const GroupPricesForm: FC<{
    experienceId: number;
    onSaveParentState: (agePrices: IPrice[]) => void;
    pSectionActive: boolean;
    setParentSectionActive: (value: boolean) => void;
}> = ({ experienceId, onSaveParentState, pSectionActive, setParentSectionActive }) => {
    const { data: experience = {} as IExperience, isFetching } =
        useGetExperienceQuery(experienceId ?? skipToken);

    const [groupPrices, setGroupPrices] = useState<IPrice[]>(() => {
        return experience?.prices
            ? experience.prices.filter((price) => price.price_type === "gruppo")
            : [];
    });

    useEffect(() => {
        onSaveParentState(groupPrices.filter((price: IPrice)=>{
            return price.group_number && price.group_number >= experience?.customers_min_number && price.group_number <= experience?.customers_max_number
        }));
    }, [groupPrices]);
    const [] = useSavePriceMutation();



    useEffect(()=>{
        if(experience && experience.id){
            const price_slices = experience.customers_max_number - experience.customers_min_number +1;
            const prices : IPrice[] = [];
            for (let p = 1; p<= price_slices ; p++){
                const groupNumb = p -1+ experience.customers_min_number ;
                const existingPrice = experience.prices.find((pr : IPrice) => pr.group_number === groupNumb);
                if(existingPrice && existingPrice.id){
                    prices.push({
                        id: existingPrice.id,
                        experience_id: experience.id,
                        price_type: PRICE_TYPE,
                        price:existingPrice.price,
                        price_label_group: groupNumb+( (groupNumb>1)?" partecipanti":" partecipante"),
                        group_number: existingPrice.group_number
                    });
                }else{
                    prices.push({
                        experience_id: experience.id,
                        price_type: PRICE_TYPE,
                        price:0,
                        price_label_group: groupNumb+( (groupNumb>1)?" partecipanti":" partecipante"),
                        group_number: groupNumb
                    })
                }

            }
            setGroupPrices(prices);

        }
    },[experience])






    const onUpdate = (groupPrice: IPrice, index: number) => {
        setGroupPrices((prevState) =>
            prevState?.map((gp, i) => {
                if (i !== index) {
                    return gp;
                }
                return {
                    ...gp,
                    group_number: groupPrice.group_number,
                    price: groupPrice.price,
                    price_label_group: groupPrice.price_label_group,
                };
            })
        );
    };



    const [buttonStates, setButtonStates] = useState<string | undefined>(undefined);
    const [adultSelected , setAdultSelected ] = useState<string | undefined>(undefined);

    const btnManager :buttonStateManager = {
        isActive(id: string): boolean {
            return (buttonStates === id);
        },
        isOneBtnSelected(): boolean {
            return (buttonStates !== undefined);
        },
        toggleActive(id: string) {
            if(id === buttonStates) {
                setParentSectionActive(false);
                setButtonStates(undefined);
            }else{
                setButtonStates(id);
                setParentSectionActive(true);
            }
        }
    };


    return isFetching ? (
        <CenteredLoadingSpinner />
    ) : (
        <Fragment>

            <Grid container item xs={12} style={{textAlign:"center", marginTop:"20px"}}>

                {groupPrices.length === 0 ? (
                    <Grid item xs={12} style={{textAlign:"center"}}>

                    </Grid>
                ) : (
                    groupPrices?.map((gp, i) => (
                        <Grid
                            container
                            item xs={12} style={{textAlign:"center"}} className={"fascia-prezzo-rule"}
                        >
                            <GroupPriceItem

                                btnIndex={`gp-${i}`}
                                manager={btnManager}
                                isSectionActive={pSectionActive}
                                setParentIsSectionActive={(value : boolean) => {
                                    setParentSectionActive(value);
                                }}

                                key={`gp-${i}`}
                                groupPrice={gp}
                                onUpdate={(groupPrice) => onUpdate(groupPrice, i)}
                            />
                        </Grid>
                    ))
                )}
            </Grid>
        </Fragment>
    );
};

export default GroupPricesForm;
