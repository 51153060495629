import {Fragment} from "react";
import {ICompany} from "../../models/entities/ICompany";
import {useDeleteCompanyMutation, useGetCompaniesQuery,} from "../../store/api/companies";
import CenteredLoadingSpinner from "../../components/common/CenteredLoadingSpinner";
import GenericPagedGrid from "../../components/common/GenericPagedGrid";
import CompanyDialogForm from "../../components/companies/CompanyDialogForm";

const CompaniesPage = () => {
  const {
    data: companies,
    isLoading,
  } = useGetCompaniesQuery();
  const [deleteCompany] = useDeleteCompanyMutation();

  const remove = (dataItem: ICompany) => {
    deleteCompany(dataItem.id as number);
  };

  return (
    <div style={{ padding: "2.5vh" }}
      className={"companies_grid"}>
      {/* <PageTitle icon="business" title="Aziende" /> */}
      {isLoading ? (
        <CenteredLoadingSpinner />
      ) : (
        <Fragment >
          <GenericPagedGrid
            icon="business" title="Aziende"
            itemsPage={20}
            items={companies as ICompany[]}
            columns={[
              { field: "company_name", title: "Ragione sociale", className: "colonna_nome_companies" },
              { field: "vat", title: "P. Iva" },
              { field: "email", title: "Email" },
              { field: "mobile", title: "Cellulare" },
              { field: "city", title: "Comune" },
            ]}
            enableCancel={true}
            onRemove={remove}
            addFormComponent={CompanyDialogForm}
            updateFormComponent={CompanyDialogForm}
          />
        </Fragment>
      )}

      {/*<ResponseSnackbar responseSnackbar={[*/}
      {/*  { sliceState: addCompanyState, resetFunction: resetAddCompany() as any } as IResponseSnackbar,*/}
      {/*  { sliceState: updateCompanyState, resetFunction: resetUpdateCompany() as any } as IResponseSnackbar,*/}
      {/*  { sliceState: deleteCompanyState, resetFunction: () => resetDeleteCompany() as any } as IResponseSnackbar,*/}
      {/*]} />*/}
    </div>
  );
};

export default CompaniesPage;
