import { useState } from "react";
import { IWeeklyTimetableDay } from "../../models/entities/IWeeklyTimetableDay";
import { ITimeRangeUpdateViewModel } from "../../models/views/ITimeRangeUpdateViewModel";
import { ITimetableDayUpdateViewModel } from "../../models/views/ITimetableDayUpdateViewModel";
import {
  fromDateToTime,
  fromTimeToDate,
  aroundMinutesDate,
} from "../dateHelper";
import { cloneDeep, isEqual, remove } from "lodash";

export function useTimetable() {
  const [timetable, setTimetable] = useState<IWeeklyTimetableDay[]>([]);
  const [initialTimetable, setInitialTimetable] = useState<
    IWeeklyTimetableDay[]
  >([]);

  const fromDefaultToCustom = () => {
    setTimetable(
      timetable?.map((d) => ({
        ...d,
        ranges: d.ranges?.map((r) => ({
          ...r,
          id: undefined,
        })),
      }))
    );
  };

  const createHistory = (overwriteDefault = false) => {
    // console.log("history");
    // console.log(initialTimetable);
    // console.log(timetable);
    const cleanedInitialForm = initialTimetable.map((d) => ({
      ...d,
      ranges: d.ranges.filter((r) => r.begin_time && r.end_time),
    }));
    const cleanedCurrentTimetable = timetable?.map((d) => ({
      ...d,
      ranges: d.ranges.filter((r) => r.begin_time && r.end_time),
    }));

    if (overwriteDefault) {
      return cleanedCurrentTimetable?.map((d) => ({
        ...d,
        closed: undefined,
        ranges: d.ranges?.map((r) => ({
          id: undefined,
          begin_time: fromDateToTime(r.begin_time as Date),
          end_time: fromDateToTime(r.end_time as Date),
        })),
      })) as ITimetableDayUpdateViewModel[];
    }

    //TODO: dobbiamo mandare sempre tutto
    const onlyModifiedDaysCurrentTimetable = cleanedCurrentTimetable?.filter(
      (d, index) => !isEqual(d, cleanedInitialForm[index])
    );

    const history: ITimetableDayUpdateViewModel[] =
      onlyModifiedDaysCurrentTimetable?.map((d) => {
        const initialDayState = cleanedInitialForm.find(
          (day) => d.date === day.date
        );
        const modifiedRanges = cloneDeep(d.ranges);

        let modifiedRangesViewModel: ITimeRangeUpdateViewModel[] =
          modifiedRanges?.map((r) => ({
            ...r,
            begin_time: fromDateToTime(r.begin_time as Date),
            end_time: fromDateToTime(r.end_time as Date),
          }));

        initialDayState?.ranges.forEach((r) => {
          const modifiedRange = modifiedRanges.find((mr) => mr.id === r.id);

          if (!modifiedRange) {
            modifiedRangesViewModel.push({
              ...r,
              begin_time: fromDateToTime(r.begin_time as Date),
              end_time: fromDateToTime(r.end_time as Date),
              isDelete: 1,
            });
          } else if (
            fromDateToTime(modifiedRange?.begin_time as Date) ===
            fromDateToTime(r.begin_time as Date) &&
            fromDateToTime(modifiedRange?.end_time as Date) ===
            fromDateToTime(r.end_time as Date)
          ) {
            remove(modifiedRangesViewModel, (mrvm) => mrvm.id === r.id);
          }
        });

        return {
          ...d,
          closed: undefined,
          ranges: modifiedRangesViewModel,
        };
      });
    // console.log(history);
    return history;
  };

  const openDay = (day_date: string) => {
    let date = aroundMinutesDate(new Date());
    setTimetable(
      timetable?.map((d) => {
        if (day_date === d.date) {
          d.ranges = [
            {
              id: undefined,
              begin_time: date,
              end_time: new Date(date.getTime() + 15 * 60000),
            },
          ];
          return {
            ...d,
            closed: false,
          };
        }
        return d;
      })
    );
  };

  const setInitialState = (initialForm: IWeeklyTimetableDay[]) => {
    if (initialForm !== undefined) {
      const mappedInitialForm = initialForm.map((d) => ({
        ...d,
        ranges:
          d.ranges.length === 0
            ? [{ id: undefined, begin_time: undefined, end_time: undefined }]
            : d.ranges?.map((r) => ({
              ...r,
              begin_time: typeof r.begin_time === "string"
                ? fromTimeToDate(r.begin_time as string) : r.begin_time,
              end_time: typeof r.end_time === "string"
                ? fromTimeToDate(r.end_time as string) : r.end_time,
            })),
        closed: d.ranges.length === 0,
      }));
      // console.log("initialState")
      // console.log(mappedInitialForm)

      setTimetable(mappedInitialForm);
      setInitialTimetable(mappedInitialForm);
    }
  };

  const setInitialFromOldState = (oldForm: IWeeklyTimetableDay[]) => {
    // console.log(oldForm);
    const mappedInitialForm = oldForm.map((d) => ({
      ...d,
      ranges: d.ranges?.map((r) => ({
        ...r,
        begin_time:
          typeof r.begin_time === "string"
            ? fromTimeToDate(r.begin_time as string)
            : r.begin_time,
        end_time:
          typeof r.end_time === "string"
            ? fromTimeToDate(r.end_time as string)
            : r.end_time,
      })),
      closed: d.ranges.length === 0,
    }));

    setTimetable(mappedInitialForm);
    setInitialTimetable(mappedInitialForm);
  };

  const setInitialFromOfficeState = (initialForm: IWeeklyTimetableDay[]) => {
    const mappedInitialForm = initialForm?.map((d) => ({
      ...d,
      ranges: d.ranges?.map((r) => {
        return {
          //...r,
          id: undefined,

           begin_time: typeof r.begin_time === "string"
            ? fromTimeToDate(r.begin_time as string) : r.begin_time,
          end_time: typeof r.end_time === "string"
            ? fromTimeToDate(r.end_time as string) : r.end_time,
  
        };
      }),
      closed: d.ranges.length === 0,
    }));
    setTimetable(mappedInitialForm);
    setInitialTimetable(mappedInitialForm);
  };

  const copyForm = (copiedForm: IWeeklyTimetableDay[]) => {

    // console.log("copiedForm")
    // console.log(copiedForm)
    // console.log("timetable")
    // console.log(timetable)
    const mappedInitialForm = timetable?.map((d) => ({
      ...d,
      ranges: copiedForm.find((t) => t.day_name === d.day_name)?.ranges ?? [
        { id: undefined, begin_time: undefined, end_time: undefined },
      ],
      closed: copiedForm.find((t) => t.day_name === d.day_name)?.closed ?? true,
    }));
   
    // console.log("mappedInitialForm")
    // console.log(mappedInitialForm)
    setTimetable(mappedInitialForm);
    setInitialTimetable(mappedInitialForm);
  };

  const updateBeginTime = (
    day_date: string,
    rangeIndex: number,
    value: Date
  ) => {
    if (isNaN(value.getTime())) {
      return;
    }
    // value = aroundMinutesDate(value);
    setTimetable((t) =>
      t?.map((day, dIndex) => {
        if (day_date === day.date) {
          return {
            ...day,
            ranges: day.ranges?.map((range, rIndex) => {
              if (rangeIndex === rIndex) {
                return {
                  ...range,
                  begin_time: value,
                };
              }
              return range;
            }),
          };
        }
        // console.log("updateBeginTime");
        // console.log(day);
        return day;
      })
    );
  };

  const updateEndTime = (day_date: string, rangeIndex: number, value: Date) => {
    if (isNaN(value.getTime())) {
      return;
    }
    // value = aroundMinutesDate(value);

    setTimetable((t) =>
      t?.map((day, dIndex) => {
        if (day_date === day.date) {
          return {
            ...day,
            ranges: day.ranges?.map((range, rIndex) => {
              if (rangeIndex === rIndex) {
                return {
                  ...range,
                  end_time: value,
                };
              }
              return range;
            }),
          };
        }
        return day;
      })
    );
  };

  const addTimeRange = (day_date: string, rangeIndex: number) => {
    let date = aroundMinutesDate(new Date());
    setTimetable((t) =>
      t?.map((day, dIndex) => {
        if (day_date === day.date) {
          return {
            ...day,
            ranges: [
              ...day.ranges.slice(0, rangeIndex + 1),
              {
                id: undefined,
                begin_time: date,
                end_time: new Date(date.getTime() + 15 * 60000),
              },
              ...day.ranges.slice(rangeIndex + 1),
            ],
          };
        }

        return day;
      })
    );
  };

  const removeTimeRange = (day_date: string, rangeIndex: number) => {
    setTimetable((t) =>
      t?.map((day, dIndex) => {
        if (day_date === day.date) {
          return {
            ...day,
            ranges: day.ranges.filter((r, index) => index !== rangeIndex),
            closed: rangeIndex === 0 && day.ranges.length === 1,
          };
        }
        return day;
      })
    );
  };

  return {
    timetable,
    updateBeginTime,
    updateEndTime,
    setInitialState,
    setInitialFromOldState,
    setInitialFromOfficeState,
    copyForm,
    addTimeRange,
    removeTimeRange,
    createHistory,
    fromDefaultToCustom,
    openDay,
  };
}
