import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IExperience} from "../models/entities/IExperience";


interface ExperiencesState {
  currentExperienceId?: number;
  currentExperience?: IExperience;
  experiencesByStaffId: IExperience[];
}

const initialState: ExperiencesState = {
  currentExperienceId: undefined,
  currentExperience: undefined,
  experiencesByStaffId: [],
};

const ExperiencesSlice = createSlice({
  name: "Experiences",
  initialState,
  reducers: {
    selectExperience(state, action: PayloadAction<number | undefined>) {
      state.currentExperienceId = action.payload;
    },  
  },  
});

export const {
  selectExperience,
} = ExperiencesSlice.actions;

export default ExperiencesSlice.reducer;
