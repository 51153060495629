import {Button, ToolbarSpacer} from "@progress/kendo-react-buttons";
import {SchedulerHeader} from "@progress/kendo-react-scheduler";
import {useAppDispatch, useAppSelector} from "../../store";
import React from "react";
import {Calendar, DatePicker} from "@progress/kendo-react-dateinputs";
import {CalendarProps} from "@progress/kendo-react-dateinputs/dist/npm/calendar/components/Calendar";
import {DatePickerChangeEvent} from "@progress/kendo-react-dateinputs/dist/npm/datepicker/DatePicker";
import {formatDateForApi, toDateFromApi} from "utils/dateHelper";
import {setDateSchedulerView} from "store/schedulerView";

const CustomSchedulerHeader = (props: any) => {

    const dispatch = useAppDispatch();
    const currentView = useAppSelector((state)=>state.schedulerView.view)
    const currentDate = useAppSelector((state)=>state.schedulerView.dateScheduler)


    const CustomCalendar = (propsC: CalendarProps)=> {
        return (
            <Calendar
                bottomView="year"
                topView="year"
                value={propsC.value}
                onChange={propsC.onChange}
            />
        );
    }
    const HadleChangeMonth = (e: DatePickerChangeEvent) =>{
        if(e.value) dispatch(setDateSchedulerView( formatDateForApi(e.value)))
    }

  return (
    <SchedulerHeader >
      <ToolbarSpacer />

         {currentView === 'day' && props.children[0].props.children.props.children[1]}
         {currentView === 'day' && props.children[1]}
         {currentView === 'day' && props.children[0].props.children.props.children[2]}

        {currentView === 'month' && <Button
            className={'left-right-icon-calendar'}
            onClick={()=>{
                const currDate = toDateFromApi(currentDate)
                currDate.setMonth(currDate.getMonth()-1)
                dispatch(setDateSchedulerView( formatDateForApi(currDate)))
            }}
        >
            <span className="k-icon k-i-arrow-60-left"></span>
        </Button> }
         {currentView === 'month' && <DatePicker
             format={'MM/yyyy'}
             calendar={CustomCalendar}
             onChange={HadleChangeMonth}
             value={toDateFromApi(currentDate)}

         />}
        {currentView === 'month' &&<Button
            className={'left-right-icon-calendar'}
            onClick={()=>{
                const currDate = toDateFromApi(currentDate)
                currDate.setMonth(currDate.getMonth()+1)
                dispatch(setDateSchedulerView( formatDateForApi(currDate)))
            }}
        >
            <span className="k-icon k-i-arrow-60-right"></span>
        </Button>}

      <ToolbarSpacer />
        {/*<BaseButton*/}
        {/*    className={`${currentView === 'day' ? 'custom-unif-arancione-back' : ' custom-unif-viola-back'} select-slot-btn`}*/}
        {/*    variant="contained"*/}
        {/*    size={'small'}*/}
        {/*    onClick={() => {*/}
        {/*        if(currentView === 'day' ) {*/}
        {/*            dispatch(setSchedulerView('month'))*/}
        {/*        }else{*/}
        {/*            dispatch(setSchedulerView('day'))*/}
        {/*        }*/}
        {/*    }}*/}
        {/*>{currentView === 'day' ? 'Vista giornaliera' : 'Vista mensile'}</BaseButton>*/}
    </SchedulerHeader>
  );
};

export default CustomSchedulerHeader;
