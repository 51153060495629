import { api } from "./index";

const authApi = {
  login: (username: string, password: string) =>
    api().post("/auth/login", { username, password }),
  logout: () => api().get("/auth/logout"),
  changePassword: (
    oldpassword: string,
    newpassword: string,
    newpasswordconfirm: string
  ) =>
    api().post("/auth/passwordChange", {
      oldpassword,
      newpassword,
      newpasswordconfirm,
    }),
  userCheckRegistration: (uid: number, checkcode: string) =>
    api().post("/auth/userCheckRegistration", {
      uid,
      checkcode,
    }),
  userCheckRecoveryLink: (uid: number, checkcode: string) =>
    api().post("/auth/checkLinkRecovery", {
      uid,
      checkcode,
    }),
  changeLinkRecoveryPassword: (
    uid: number,
    checkcode: string,
    email: string,
    password: string,
    passwordconfirm: string
  ) =>
    api().post("/auth/checkLinkRecoveryPassword", {
      uid,
      checkcode,
      email,
      password,
      passwordconfirm,
    }),
  userCheckRegistrationPassword: (
    uid: number,
    checkcode: string,
    email: string,
    password: string,
    passwordconfirm: string
  ) =>
    api().post("/auth/userCheckRegistrationPassword", {
      uid,
      checkcode,
      email,
      password,
      passwordconfirm,
    }),
  userChangeEmail: (
    uid: number,
    checkcode: string,
    oldemail: string,
    newemail: string
  ) =>
    api().post("/auth/emailChange", {
      uid,
      checkcode,
      oldemail,
      newemail,
    }),
};

export default authApi;