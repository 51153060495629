import {Tab, Theme} from "@mui/material";
import {createStyles, withStyles} from "@mui/styles";

const CustomTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderColor: theme.palette.grey[500],
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: "20px 20px 0 0"
    },
    selected: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.getContrastText(theme.palette.grey[100])
    }
  }
  ))(Tab)

export default CustomTab