import {Box, Card, CardContent, CardHeader, Link} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100vh",
  },
  card: {
    width: 500,
  },
});

const ForgottenPassword = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box
      className={classes.root+"  custom-back custom-unif-font"}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Card className={classes.card + " card-404"}>
        <CardHeader
          component={Box}
          margin={3}
          title="ERROR 404"
          titleTypographyProps={{
            variant: "h3",
            color: "primary",
            align: "center",
          }}
        ></CardHeader>
        <CardContent component={Box} marginBottom={3} className={"custom-unif-font-bold"}>
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              history.push("/login");
            }}
          >
            torna alla login
          </Link>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ForgottenPassword;
