import * as React from "react";
import {useState} from "react";
import {DropDownList, DropDownListChangeEvent,} from "@progress/kendo-react-dropdowns";
import {GridCellProps} from "@progress/kendo-react-grid";

export const EditorDropDownCell = (props: GridCellProps) => {
    const localizedData = [
        { text: "Attiva", value: true },
        { text: "Disattiva", value: false },
    ];

    const [dataValue, setDataValue] = useState<boolean>(props.dataItem.available)
    const handleChange = (e: DropDownListChangeEvent) => {
        if (props.onChange) {
            setDataValue(e.target.value.value);
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.value,
            });
        }
    };


    return (
        <td>
            {props.dataItem.inEdit ? (
                <DropDownList
                    fillMode={"flat"}
                    style={{ width: "100px" }}
                    onChange={handleChange}
                    value={localizedData.find((c) => c.value === dataValue) ?? { text: "Disattiva", value: false }}
                    data={localizedData}
                    textField="text"
                />
            ) : (
                (props.dataItem.available)? 'Attiva' : 'Disattiva'
            )}
        </td>
    );
};