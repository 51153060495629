import {Box, Popover, Tooltip} from "@mui/material";
import {Fragment, useState} from "react";
import PlannerIcon from "@mui/icons-material/LocalLibrary";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import PlannerControl from "./PlannerControl";
import BaseButton from "components/common/BaseButton";

const PlannerButton = () => {
  const currentPlanner = useCurrentPlanner();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>();
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "planner-popover" : undefined;

  const popoverStyle = {
    width : '80vw !important'
  }

  return (
    <Fragment>
      <Box paddingRight={2}>
        <Tooltip title="Agenda">
          <BaseButton
            className={"button-planner-toolbar"}
            size="large"
            onClick={handleClick}
            color="inherit"
            variant="outlined"
              startIcon={<PlannerIcon color="inherit" />}
          >
            <b>{currentPlanner?.name}</b>
          </BaseButton>
        </Tooltip>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={popoverStyle}
        className={"card-dialog-from-drawer"}
      >
        <PlannerControl onClose={handleClose} />
      </Popover>
    </Fragment>
  );
};

export default PlannerButton;
