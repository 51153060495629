import {SchedulerItem} from "@progress/kendo-react-scheduler";
import {IAppointment} from "models/entities/calendar/IAppointment";
import {isStarted} from "../../utils/dateHelper";
import {useEffect, useState} from "react";
import {IExperience} from "../../models/entities/IExperience";
import {useGetExperiencesByStaffIdQuery} from "../../store/api/experiences";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";

export const CustomSchedulerItem = function (props: any) {


    const currentPlanner = useCurrentPlanner();
    const {
        data: experiencesByStaffId
    } = useGetExperiencesByStaffIdQuery(
        { plannerId:  props.dataItem.planner_id as number, staffId: props.dataItem.staffId },
        { skip: !currentPlanner ||  props.dataItem.staffId === 0 }
    );

    const [CurrExpMonoLang, SetCurrExpMonoLang] = useState<boolean>(false);
    useEffect(() => {

        if (experiencesByStaffId) {
            const experience = (experiencesByStaffId as IExperience[]).find(
                (item: IExperience) => item.id ===  props.dataItem?.experience_id
            ) as IExperience;
            SetCurrExpMonoLang(experience.monolanguage);

        }
    }, [experiencesByStaffId]);

  const generateTitle = (dataItem: IAppointment) => {
      // console.log(currentAppointment);
      if (CurrExpMonoLang) {
          return <div style={{padding: "5px"}}>
              {dataItem.experience_name}
               <br></br>
              {"("}{dataItem.language} {", "}  {dataItem.number_partecipants} {"/"} {dataItem.experience_customers_max_number}{")"}
          </div>
      }else{
          return <div style={{padding: "5px"}}>
              {dataItem.experience_name} {" "}
              {dataItem.number_partecipants}/{dataItem.experience_customers_max_number }
          </div>
      }



  };

  const arancione = "#f47056";


    return (
            <SchedulerItem
                {...props}
                onRemoveClick={
                (props.dataItem.expire_at)? null :
                    props.onRemoveClick}
                title={generateTitle(props.dataItem)}
                style={{
                    ...props.style,
                    backgroundColor: (props.dataItem.expire_at)? arancione :  isStarted(props.start) ? "LightGreen" : "green",
                    display: props.isAllDay ? "none" : undefined,
                }}
                className={(props.dataItem.expire_at)? "temp-appointment" : ""}
                children={generateTitle(props.dataItem)}
            />
    );
};
