import {AppBar, Theme, Toolbar, Typography,} from "@mui/material";
import clsx from "clsx";
import {Fragment, useCallback, useEffect, useState} from "react";
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import HomePage from "../pages/dashboard/HomePage";
import SchedulerPage from "../pages/dashboard/SchedulerPage";
import CompanyPage from "../pages/dashboard/CompanyPage";
import PlannerPage from "../pages/dashboard/planner";
import EquipmentsPage from "../pages/dashboard/EquipmentsPage";
import ExperiencesPage from "../pages/dashboard/ExperiencesPage";
import StaffPage from "../pages/dashboard/StaffPage";
import LocationsPage from "../pages/dashboard/LocationsPage";
import CompaniesPage from "../pages/dashboard/CompaniesPage";
import UsersPage from "../pages/dashboard/UsersPage";
import {useAppDispatch, useAppSelector} from "../store";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerMenu from "../components/drawers/DrawerMenu";
import {createStyles, makeStyles} from "@mui/styles";
import UserAvatar from "../components/users/UserAvatar";
import SwipeableDrawerMenu from "../components/drawers/SwipeableDrawerMenu";

import {setMessageState} from "../store/messageChat";
import CenteredLoadingSpinner from "../components/common/CenteredLoadingSpinner";
import {useCurrentPlanner} from "../utils/hooks/currentPlanner";
import PlannerButton from "../components/planners/PlannerButton";

import BaseIconButton from "components/common/BaseIconButton";
import {useGetPlannersQuery} from "store/api/planners";
import {selectPlanner} from "store/planners";
import {getAllTypes} from "../store/types";
import NmpLogo from "./../img/Icons/nowmyplace-icon.svg";
import NmpLogoText from "./../img/Icons/nowmyplace-icon-text.svg";
import {useMe} from "../utils/hooks/me";
import {skipToken} from "@reduxjs/toolkit/query/react";
import MessagesPage from "../pages/dashboard/MessagesPage";
import ResellersPage from "../pages/dashboard/ResellersPage";
import ReceptionistsPage from "../pages/dashboard/ReceptionistsPage";
import GuestsPage from "../pages/dashboard/GuestsPage";
import ResellerPage from "../pages/dashboard/ResellerPage";
import {setAppointmentOpenedState} from "store/detailAppointment";
import ResellerGraphs from "../pages/dashboard/ResellerGraphs";

const drawerMinWidthOpened = 58;
const drawerFullWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("sm")]: {
        marginLeft: drawerMinWidthOpened,
      },
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      [theme.breakpoints.up("sm")]: {
        marginLeft: drawerFullWidth,
      },
    },
    title: {
      flexGrow: 1,
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      ...theme.mixins.toolbar,
    },
  })
);

function DashboardLayout(props: any) {
  const authSlice = useAppSelector((store) => store.auth);
  const currentPlanner = useCurrentPlanner();

  const token = localStorage.getItem("token");

  useHistory();
  const { path } = useRouteMatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const loggedUser = useMe();



  const {
    data: planners,
    isLoading: isLoadingPlanners,
    isError: isErrorPlanners,
  } = useGetPlannersQuery(!loggedUser || loggedUser.role === "superadmin" ? skipToken : undefined as void);

  useEffect(() => {
    // dispatch(getAllPlanners());
    if(token){
      dispatch(getAllTypes());
    }
    // dispatch(getAllEquipments());

    // eslint-disable-next-line
  }, [token]);
  useEffect(() => {

    if (planners) {
      dispatch(selectPlanner(planners[0].id as number));
    }
    // eslint-disable-next-line
  }, [planners]);

  const urlParams = new URLSearchParams(window.location.search);
  const [,setChat] = useState<string|null>(null);
  useEffect(()=>{
    if(urlParams.get('chat') && urlParams.get('chat')!= null ) {
      setChat(urlParams.get('chat'));
      dispatch(setMessageState(urlParams.get('chat')));
    }
  });

  useEffect(()=>{
    if(urlParams.get('appointmentToOpen') && urlParams.get('appointmentToOpen')!= null ) {
      dispatch(setAppointmentOpenedState(urlParams.get('appointmentToOpen')));
    }
  });

  const escFunction = useCallback((event) =>{
    if(event.keycode === 27){
      return false;
    }
  }, []);
  useEffect(()=>{
    window.document.getElementById("root")!.addEventListener("keydown", escFunction, false);

  },  [escFunction])

  const toggle = () => setOpen(!open);
  const toggleMobile =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(open);
    };

  if (loggedUser?.role !== "superadmin" && (isLoadingPlanners || isErrorPlanners || currentPlanner === undefined)) {
    return (
      <div style={{ height: "100vh", width: "100%" }}>
        <CenteredLoadingSpinner />
      </div>
    );
  }



  return (
    <Fragment>
      <AppBar
        position="fixed"
        className={classes.appBar + " custom-back custom-unif-font"}
      >
        <Toolbar>
          <BaseIconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggle}
          >
            <MenuIcon />
          </BaseIconButton>
          <Typography variant="h6" className={"  titlePiuLogo"}>
            <img id="now-my-place-icon" src={NmpLogo} alt={"Logo"}/>
          </Typography>
          <Typography variant="h6" className={classes.title +" now-my-place-icon-text-container titlePiuLogo"}>
          <img id="now-my-place-icon-text" src={NmpLogoText} alt={"Now My Place"}/>
          </Typography>
          {authSlice.user?.role !== "superadmin" && authSlice.user?.role !== "reception" &&  window.innerWidth > 600 && (
            <PlannerButton />
          )}
          <UserAvatar />
        </Toolbar>
      </AppBar>

      {window.innerWidth > 600 ? (
          <DrawerMenu opened={open} />
        ) : (
          <SwipeableDrawerMenu opened={open} toggle={toggleMobile} />
        )}

      {/* <DrawerMenu opened={open} /> */}
      <main
        className={  (clsx(classes.content, {
          [classes.contentShift]: open,
        }))}
      >
        <div className={classes.toolbar} />
        <Switch>
          <Route path={`${path}/graphs`} component={HomePage}></Route>
          {/*<Route path={`${path}/messages`} component={MessagesPage}></Route>*/}
          <Route path={`${path}/planner`} component={PlannerPage}></Route>
          {/* <Route path={`${path}/planners`} component={PlannersPage}></Route> */}
          <Route path={`${path}/scheduler`} component={SchedulerPage}></Route>
          <Route path={`${path}/equipments`} component={EquipmentsPage}></Route>
          <Route path={`${path}/company`} component={CompanyPage}></Route>
          <Route path={`${path}/messages`} component={MessagesPage}></Route>
          <Route
            path={`${path}/experiences`}
            component={ExperiencesPage}
          ></Route>
          <Route path={`${path}/staff`} component={StaffPage}></Route>
          <Route path={`${path}/locations`} component={LocationsPage}></Route>
          <Route path={`${path}/companies`} component={CompaniesPage}></Route>
          <Route path={`${path}/users`} component={UsersPage}></Route>
          <Route path={`${path}/reseller`} component={ResellerPage}></Route>
          <Route path={`${path}/resellers`} component={ResellersPage}></Route>
          <Route path={`${path}/hotel-graph`} component={ResellerGraphs}></Route>
          <Route path={`${path}/receptionists`} component={ReceptionistsPage}></Route>
          <Route path={`${path}/guests`} component={GuestsPage}></Route>
        </Switch>
      </main>
    </Fragment>
  );
}

export default DashboardLayout;
