import React, {FC, useState} from "react";
import {DateRangePickerProps} from "../../models/props/DateRangePickerProps";
import {Grid} from "@mui/material";
import CustomDatePicker from "./CustomDatePicker";
import {Error} from "@progress/kendo-react-labels";
import {compareDate} from "../../utils/dateHelper";

const DateRangePicker: FC<DateRangePickerProps> = (props) => {

    const [rangeValid, setRangeValid]
        = useState(compareDate((props.range.startDate as Date), (props.range.endDate as Date)) !== 1);

      const onStartChange = (date: Date) => {
        props.onChange({ ...props.range, startDate: date})
      }
      const onEndChange = (date: Date) => {
        props.onChange({ ...props.range, endDate: date})
      }

    const handleChangeStart = ( start : any) =>{
        if(props.range.endDate !== undefined) {
            setRangeValid(!((start.value as Date) < (props.range.endDate as Date)))
        }else{
            setRangeValid(false)
        }

        onStartChange(start.value as Date)
    }

    const handleChangeEnd = ( end : any) =>{
        if(props.range.startDate !== undefined) {
            setRangeValid(!((end.value as Date) > (props.range.startDate as Date)))
        }else{
            setRangeValid(false)
        }
        onEndChange(end.value as Date)
    }

  return (
    <Grid container spacing={2} >
      <Grid  item xs={1} sm={2} md={2} lg={2} ></Grid>
      <Grid  item xs={12} sm={5} md={5} lg={5}>
          <div className={ "std-date-picker-container dropdownModifica custom-unif-dropdown-focus"} >

              <label
                  className="labelModifica std-date-picker-label"
                  data-shrink="true"
              >
                  Dal
              </label>
              <div className=" spanFiglia-daIngrandire std-date-picker-component">
                  <CustomDatePicker
                      name={"valid_period_start"}
                      value={props.range.startDate}
                      format="dd/MM"
                      onChange={(date: any) => {

                          handleChangeStart(date);
                          // onStartChange(date.value as Date)
                      }}
                  />
              </div>
          </div>

      </Grid>
      <Grid  item xs={12} sm={5} md={5} lg={5} >
          <div className={ "std-date-picker-container dropdownModifica custom-unif-dropdown-focus"} >

              <label
                  className="labelModifica std-date-picker-label"
                  data-shrink="true"
              >
                  al
              </label>
              <div className=" spanFiglia-daIngrandire std-date-picker-component">
                  <CustomDatePicker
                      name={"valid_period_start"}
                      value={props.range.endDate}
                      format="dd/MM"
                      onChange={(date: any) => {
                          handleChangeEnd(date);
                          // onEndChange(date.value as Date)
                      }}
                  />
              </div>
          </div>
          {rangeValid && <Error>La data di fine deve essere successiva alla data iniziale</Error>}
      </Grid>
    </Grid>
  )
}

export default DateRangePicker