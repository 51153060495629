import { FieldValidatorType } from "@progress/kendo-react-form";


export const requireValidator: FieldValidatorType = (value: string) =>
  value ? "" : "Campo obbligatorio"

export const requireNumberValidator: FieldValidatorType = (value: number) => {
    if(value ==undefined){
        return ""
    }
   return  value ? "" : "Campo obbligatorio"
}

