import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {Error} from "@progress/kendo-react-labels";
import {GridLayout, GridLayoutItem} from "@progress/kendo-react-layout";
import {requireValidator} from "../../utils/validators/requireValidator";

import {digitValidator} from "../../utils/validators/digitValidator";
import {emailValidator} from "../../utils/validators/emailValidator";
import {minLengthValidatorFactory} from "../../utils/validators/minLengthValidator";
import {alfaNumericValidator} from "../../utils/validators/alfaNumericValidator";
import {urlValidator} from "../../utils/validators/urlValidator";
import {alphabeticValidator} from "../../utils/validators/alphabeticValidator";

import {
  AutocompleteCity,
  ValidatedDropDownListKeyLabelTypes_customBehavior,
  ValidatedEmail,
  ValidatedFacebook,
  ValidatedIBANField,
  ValidatedInput,
  ValidatedInputUpperCase,
  ValidatedInstagram,
  ValidatedMobile,
  ValidatedSwitch,
  ValidatedWebsite,
} from "../common/ValidatedFields";
import {EditFormProps} from "../../models/helpers/IEditFormProps";

import BaseButton from "components/common/BaseButton";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import React, {Fragment, useCallback, useEffect, useState,} from "react";
import {useMe} from "utils/hooks/me";
import {skipToken} from "@reduxjs/toolkit/query/react";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SaveIcon from "@mui/icons-material/Save";
import ServerErrorDialog from "../common/ServerErrorDialog";
import {IReseller} from "../../models/entities/IReseller";
import {useAddResellerMutation, useGetResellerQuery, useUpdateResellerMutation} from "../../store/api/resellers";
import {UploadOnStatusChangeEvent} from "@progress/kendo-react-upload";
import {memoizedSingleFileUploader} from "../common/UploadSingleFileCustom";
import {coordinateValidator} from "../../utils/validators/coordinateValidator";
import {useAppSelector} from "../../store";
import {apiPath} from "../../store/api/api";
import {SCREEN_LG, SCREEN_XL, useWindowSize} from "../common/ScreenSizes";
import toast from "react-hot-toast";
import {CircularProgress} from "@mui/material";

const ResellerForm = (props: EditFormProps<IReseller>) => {
  const token = localStorage.getItem("token");
  const get_path = apiPath + "reseller/";
  const put_path = apiPath + "reseller/";
  /**
   * Set toast display duration
   * */
  const TOAST_DURATION = 5000;

  const header_get =  {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
  const [addReseller, { isLoading: isAdding, isSuccess }] =
    useAddResellerMutation();
  const { tipologyreseller, priceincreases } = useAppSelector((store) => store.types);

  const [
    updateReseller,
    {
      isLoading: isUpdating,
    },
  ] = useUpdateResellerMutation();
  const user = useMe();
  const [canEdit, ] = useState<boolean>(user?.userId !== user?.userRealId || user?.role === "superadmin");

  const {
    data: reseller,
    isLoading,
  } = useGetResellerQuery(props?.item.id ?? skipToken);

  const [serverErrorDialog, setServerErrorDialog] = useState(false);
  const [fetchedReseller, setFetchedReseller] = useState<IReseller|undefined>(undefined);
  const [isLoadingMatrix, setIsLoadingMatrix] = useState<boolean>(false);
  const size = useWindowSize();
  const [colWidth , setColWidth] = useState<string>('7.1vw')
  const [switchColSpan , setSwitchColSpan] = useState<boolean>(false)

  const [priceIncreaseValue, setPriceIncreaseValue] = useState(
      (props?.item.id && reseller) ? reseller?.price_increase  : priceincreases[0]?.key
  )

  useEffect(()=>{
    if(size) {
      if ( size <=SCREEN_LG){

        setColWidth('6.3vw')
        setSwitchColSpan(true);
      }
      if (size > SCREEN_LG && size <=1350){

        setSwitchColSpan(false);
        setColWidth('6.5vw')
      }
      if (size > 1350 && size <=SCREEN_XL){

        setSwitchColSpan(false);
        setColWidth('6.7vw')
      }
      if (size > SCREEN_XL ){
        setSwitchColSpan(false);
        setColWidth('7.1vw')
      }

    }
  },[size]);

  const [lat,setLat] = useState<string>( ' ' );
  const [lng,setLng] = useState<string>(' ' );

  useEffect(()=>{
    if(props.refetchData){
      if( props.item.id !== undefined) {
        let optObjHeadBody = {
          method: 'GET',
          headers:header_get,

        };
        fetch(get_path + props.item.id  , optObjHeadBody)
            .then(res => res.json())
            .then(
                (result) => {
                  if(result.reseller)
                    setFetchedReseller(result.reseller)
                },
                // Nota: è importante gestire gli errori qui
                // invece di un blocco catch() in modo da non fare passare
                // eccezioni da bug reali nei componenti.
                (error) => {

                  // setIsLoaded(true);
                  // setError(error);
                }
            )
        return () =>{}
      }
    }
  },[])



  useEffect(()=>{
    if(reseller?.latitude && reseller?.longitude) {
      setLat(reseller.latitude);
      setLng(reseller.longitude);
    }else{
      if(props.item.latitude && props.item.longitude) {
        setLat(props.item.latitude);
        setLng(props.item.longitude);
      }
    }
  },[props.item])

  const [, setCityVal] = useState(props.item.city);


  function gen4() {
    return Math.random().toString(16).slice(-4);
  }


  const _temp_id = ""
    .concat(
      [gen4(), gen4(), gen4(), gen4(), gen4(), gen4(), gen4(), gen4()].join("")
    )
    .toString();

  const collectAllegati = () => {
    let files_id: string[] = [];
    const files: Element[] = Array.from(
      window.document.getElementsByClassName("k-file")!
    );
    if (files.length !== 0) {
      files.forEach((el: Element) => {
        files_id.push(el.getAttribute("data-uid")!.toString());
      });
    }
    return files_id;
  };
  const handleSubmit = (event: Partial<IReseller>) => {
    let allegati = collectAllegati();


    // event.insurance_attach = allegati;

    event.logo_attachment = allegati[0];
    event.cover_attachment = allegati[1];
    // if(event.iban) {
    //   event.iban = event.iban.replace(/\s/g, "");
    // }

    //DEFAULTS - Se non viene modificato il campo, per qualche motivo rimane vuoto, per ora mi accontento di questa soluzione
    event.tipology = tipologyreseller[0]?.key; //BUSINESS LOGIC, SONO SOLO ALBERGHI
    // event.exclude_vat_zero = event.exclude_vat_zero ? event.exclude_vat_zero : false;
    event.notifications = event.notifications ? event.notifications : false;
    // event.price_increase = event.price_increase ? event.price_increase : 0;
    // event.plafond = event.plafond ?? PlafondValues[0]?.key ;
    event.radius = event.radius ? event.radius : 10;

    if(priceIncreaseValue ){
      event.price_increase = priceIncreaseValue;
    }else{
      event.price_increase  = 0;
    }

    if (!event.id) {
      event.temp_id = props.item.temp_id;

      addReseller(event as IReseller);
    } else {
      updateReseller({
        id: event.id,
        reseller: { ...event, id: event.id,
        } as IReseller,
      });
    }

      // props.onSubmit(event);
  };

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      event.preventDefault();

      return false;
    }
  }, []);


  useEffect(() => {

    window.document.addEventListener("keydown", escFunction, false);
  }, [escFunction]);

  const getPlacesPostCodeById = async (placeId: any) =>
    new Promise<{ [key: string]: string }>((resolve, reject) => {
      if (!placeId) reject("placeId not provided");

      try {
        new window.google.maps.places.PlacesService(
          document.createElement("div")
        ).getDetails(
          {
            placeId,
            fields: ["address_components"],
          },
          (details) => {

            let routeInfo: { [key: string]: string } = {};

            details?.address_components?.forEach((entry) => {
              routeInfo[entry.types?.[0] as string] = entry.long_name;
            });

            return resolve(routeInfo);
          }
        );
      } catch (e) {
        reject(e);
      }
    });

  const UpdateField = (city: string, isEff: boolean) => {
    let targetDiv = null;

    let targetError = null;
    targetError = window.document.getElementsByClassName("comune-validator")[0]!;


    if(targetError !== undefined && targetError.classList !==undefined) {
      targetError.classList.add("display-true");
    }
    // targetError.classList.add("display-true");
    //PER INIZIALIZZARE L'AUTOCOMPLETE
    targetDiv = window.document.getElementById("auto-city-prova")!;
    if (isEff) {
      if (targetDiv !== null && targetDiv !== undefined) {
        if (
          targetDiv.getElementsByClassName("css-1wa3eu0-placeholder")[0] !=
          undefined
        ) {
          targetDiv.getElementsByClassName(
            "css-1wa3eu0-placeholder"
          )[0].textContent = city;
          targetDiv
            .getElementsByClassName("css-1wa3eu0-placeholder")[0]
            .classList.add("css-1uccc91-singleValue");
        }
      }
    } else {
      if (targetDiv !== null && targetDiv !== undefined) {

        if(targetDiv.getElementsByClassName("css-1uccc91-singleValue")[0] === undefined){
          targetDiv
              .getElementsByClassName("css-1wa3eu0-placeholder")[0]
              .classList.add("css-1uccc91-singleValue");
        }

        targetDiv.getElementsByClassName(
          "css-1uccc91-singleValue"
        )[0].textContent = city;
        if(targetError !== undefined && targetError.classList !==undefined)
        {
          targetError.classList.remove("display-true");
        }
      }
    }
  };

  // const PlafondValues = [
  //   { key: 500,  label: "500 euro"},
  //   { key: 1000, label: "1.000 euro"},
  //   { key: 1500, label: "1.500 euro"},
  //   { key: 2000, label: "2.000 euro"},
  //   { key: 2500, label: "2.500 euro"},
  //   { key: 3000, label: "3.000 euro"},
  //   { key: 3500, label: "3.500 euro"},
  //   { key: 4000, label: "4.000 euro"},
  //   { key: 4500, label: "4.500 euro"},
  //   { key: 5000, label: "5.000 euro"},
  // ]


  useEffect(() => {
    if (isSuccess) props.cancelEdit();
    //PER INIZIALIZZARE L'AUTOCOMPLETE

    if (props.item?.id === null) {
      props.item.temp_id = _temp_id;
    }

    if (props.item?.city !== undefined) {
      UpdateField(props.item?.city as string, true);
    } else {
      UpdateField("Cerca il comune...", true);
    }
  }, [isSuccess]);

  const handleClickDistances  = ()=> {
    setIsLoadingMatrix(true);
    let optObjHeadBody = {
      method: 'PUT',
      headers:header_get,

    };
    fetch(put_path + props.item.id  +"/setMatrix", optObjHeadBody)
        .then(res => res.json())
        .then(
            (result) => {
                if(result.bResult) {
                  toast.success("Operazione avvenuta con successo", {duration: TOAST_DURATION});
                }else{
                  toast.error(result.aMessage, {duration: TOAST_DURATION});
                }
              setIsLoadingMatrix(false);
            },
            // Nota: è importante gestire gli errori qui
            // invece di un blocco catch() in modo da non fare passare
            // eccezioni da bug reali nei componenti.
            (error) => {

              setIsLoadingMatrix(false);
              toast.error("Si è verificato un errore, riprova più tardi", {duration: TOAST_DURATION});
              // setIsLoaded(true);
              // setError(error);
            }
        )
  }


  return (isLoading || (props.refetchData && fetchedReseller==undefined)) ? (
    <CenteredLoadingSpinner />
  ) : (
    <Form

      onSubmit={handleSubmit}
      initialValues={(props.refetchData)? fetchedReseller : props.item}
      render={(formRenderProps) => (
        <FormElement
          full-width="true"
          id="form-reseller"
          className="z-index-label-forms reseller-form"
        >
          <GridLayout
              gap={{ rows:5, cols: 17 }}
              //10 righe, magari troviamo il modo di indicarlo diversamente
              rows={
                !switchColSpan ? [
                { height: '90px' }, //1
                { height: '90px' }, //2
                { height: '90px' }, //3
                { height: '90px' }, //4
                { height: '90px' }, //5
                { height: '90px' }, //6
                { height: '160px' }, //7
                { height: '90px' },//8
                { height: '90px' },//9
              ] : [
                  { height: '90px' }, //1
                  { height: '90px' }, //2
                  { height: '90px' }, //3
                  { height: '90px' }, //4
                  { height: '90px' }, //5
                  { height: '90px' }, //6
                  { height: '90px' }, //7
                  { height: '90px' },//8
                  { height: '90px' },//9
                  { height: '90px' },//10
                  { height: '90px' }, //11
                  { height: '90px' },//12
                  { height: '90px' }, //13
                  { height: '90px' },//14
                  { height: '160px' }, //15
                  { height: '160px' }, //16
                  { height: '90px' }, //16
                  { height: '90px' }, //16
                  { height: '90px' }, //16
                  { height: '90px' }, //16
                  { height: '90px' }, //16
                ]

              }
              cols={ [
                { width: colWidth },
                { width: colWidth },
                { width: colWidth },
                { width: colWidth },
                { width: colWidth },
                { width: colWidth },
                { width: colWidth },
                { width: colWidth },
                { width: colWidth },
                { width: colWidth },
                { width: colWidth },
                { width: colWidth },
              ] }
              align={{ horizontal: "stretch" }}

          >
            {/*Rag sociale, nome, piva, cod fisc, cod sdi*/}
            <GridLayoutItem  row={1}  colSpan={switchColSpan? 6 : 4}
                             // className="item-media-rule rows-grandi"
            >
              <Field
                  name={"company_name"}
                  required
                  component={ValidatedInput}
                  rows={1}
                  disabled={!canEdit}
                  label={"Ragione Sociale"}
                  // value={props.item?.company_name}
                  validator={[requireValidator]}
              />
            </GridLayoutItem >
            <GridLayoutItem row={1}  colSpan={switchColSpan? 6 :2}>
              <Field
                  disabled={!canEdit}
                  name={"name"}
                  required
                  component={ValidatedInput}
                  rows={1}
                  label={"Nome"}
                  // value={props.item?.company_name}
                  validator={[requireValidator]}
              />
            </GridLayoutItem >
            <GridLayoutItem  row={switchColSpan? 2 :1}  colSpan={switchColSpan? 6 :2}>
              <Field
                  disabled={!canEdit}
                name={"vat"}
                component={ValidatedInput}
                rows={1}
                label={"Partita iva"}
                validator={[ digitValidator]}
              />
            </GridLayoutItem >
            <GridLayoutItem  row={switchColSpan? 2 :1} colSpan={switchColSpan? 6 :2}>
              <Field
                  disabled={!canEdit}
                name={"fiscal_code"}
                component={ValidatedInputUpperCase}
                label={"Codice fiscale"}
                validator={[
                  alfaNumericValidator,
                  // rangeLengthValidator(9, 11),
                ]}
              />
            </GridLayoutItem >
            <GridLayoutItem row={switchColSpan? 3 :1} colSpan={switchColSpan? 6 :2}>
              <Field
                  disabled={!canEdit}
                name={"cuu_code"}
                component={ValidatedInputUpperCase}
                label={"Codice SDI"}
                validator={[

                  alfaNumericValidator,
                  minLengthValidatorFactory(7),
                  // maxLengthValidatorFactory(6),
                ]}
              />
            </GridLayoutItem >

            {/*indirizzo, civ, comune, cap, prov*/}
            <GridLayoutItem  row={switchColSpan? 4 :2}  colSpan={switchColSpan? 6 :4}>
              <Field
                  disabled={!canEdit}
                name={"address"}
                required
                component={ValidatedInput}
                rows={1}
                label={"Indirizzo"}
                validator={[requireValidator]}
              />
            </GridLayoutItem >
            <GridLayoutItem row={switchColSpan? 4 :2}  colSpan={switchColSpan? 6 :1}>
              <Field
                  disabled={!canEdit}
                name={"address_number"}
                required
                component={ValidatedInput}
                rows={1}
                label={"Civ."}
                validator={[requireValidator]}
              />
            </GridLayoutItem >

            <GridLayoutItem  row={switchColSpan? 5 :2}  colSpan={switchColSpan? 6 :4}>
              {canEdit ?
                  <Field
                      onChange={(e) => {
                        getPlacesPostCodeById(e.value.place_id).then(
                            function (result: { [key: string]: string }) {

                              if (
                                  e.value.terms[e.value.terms.length - 1].value !== "" &&
                                  e.value.terms[e.value.terms.length - 1].value !== null &&
                                  e.value.terms[e.value.terms.length - 1].value !==
                                  undefined
                              ) {
                                formRenderProps.onChange("province", {
                                  value: e.value.terms[e.value.terms.length - 1].value,
                                });
                              }

                              if (
                                  result.postal_code !== "" &&
                                  result.postal_code !== null
                              ) {
                                formRenderProps.onChange("postal_code", {
                                  value: result.postal_code,
                                });
                              }

                              if (result.locality !== "" && result.locality !== null  ) {
                                formRenderProps.onChange("city", {
                                  value: result.locality,
                                });
                                setCityVal(result.locality);
                                UpdateField(result.locality, false);
                              }
                              if (result.administrative_area_level_3 !== "" && result.administrative_area_level_3 !== null && result.locality === undefined ){

                                formRenderProps.onChange("city", {
                                  value: result.administrative_area_level_3,
                                });
                                setCityVal(result.administrative_area_level_3);
                                UpdateField(result.administrative_area_level_3, false);
                              }



                              // formRenderProps.onChange("postal_code", { value: result });
                            }
                        );
                      }}
                      className="autocompletamento-citta"
                      name={"city"}
                      required
                      value={props.item?.city}
                      label="Comune *"
                      validator={[requireValidator]}
                      component={AutocompleteCity}

                  /> :
                  <Field
                      disabled={!canEdit}
                      name={"city"}
                      required
                      component={ValidatedInput}
                      label={"Comune"}
                  />
              }

            </GridLayoutItem >
            <GridLayoutItem row={switchColSpan?5 :2}  colSpan={switchColSpan? 3 :2}>
              <Field
                // value={codPostale}
                name={"postal_code"}
                required
                component={ValidatedInput}
                rows={1}
                label={"Cap"}
                validator={[requireValidator, digitValidator]}
                disabled={!canEdit}
              />
            </GridLayoutItem >
            <GridLayoutItem row={switchColSpan? 5 :2}  colSpan={switchColSpan? 3 :1}>
              <Field
                name={"province"}
                required
                component={ValidatedInput}
                rows={1}
                label={"Prov."}
                validator={[requireValidator, alphabeticValidator]}
                disabled={!canEdit}
              />
            </GridLayoutItem >

            {/*Telefono, cellulare, pec, email,*/}
            <GridLayoutItem row={switchColSpan? 6 :3} colSpan={switchColSpan? 6 : 3}>
              <Field
                name={"phone"}
                disabled={!canEdit}
                component={ValidatedMobile}
                label={"Telefono"}
                validator={[
                  digitValidator,
                  // phoneNumberValidator, senza il trattino funziona
                ]}
              />
            </GridLayoutItem >
            <GridLayoutItem row={switchColSpan? 6 :3}  colSpan={switchColSpan? 6 :3}>
              <Field
                name={"mobile"}
                disabled={!canEdit}
                required
                component={ValidatedMobile}
                label={"Cellulare"}
                validator={[
                  requireValidator,
                  digitValidator,
                  // phoneNumberValidator
                ]}
              />
            </GridLayoutItem >
            <GridLayoutItem row={switchColSpan? 7 :3}  colSpan={switchColSpan? 6 :3}>
              <Field
                name={"pec"}
                disabled={!canEdit}
                component={ValidatedEmail}
                label={"Pec"}
                validator={[emailValidator]}
              />
            </GridLayoutItem >
            <GridLayoutItem row={switchColSpan? 7 :3}  colSpan={switchColSpan? 6 :3}>
              <Field
                  disabled={!canEdit}
                name={"email"}
                required
                component={ValidatedEmail}
                label={"Email"}
                validator={[emailValidator]}
              />
            </GridLayoutItem >

            {/*sito web, faceboook, instagram,*/}
            <GridLayoutItem row={switchColSpan? 8 :4} colSpan={switchColSpan? 6 :4}>
              <Field
                  disabled={!canEdit}
                name={"web_address"}
                component={ValidatedWebsite}
                label={"Sito web"}
                validator={[urlValidator]}
              />
            </GridLayoutItem >
            <GridLayoutItem row={switchColSpan? 8 :4} colSpan={switchColSpan? 6 :4}>
              <Field
                  disabled={!canEdit}
                name={"facebook"}
                component={ValidatedFacebook}
                label={"Facebook"}
                // validator={[urlValidator]}
              />
            </GridLayoutItem >
            <GridLayoutItem row={switchColSpan? 9 :4}  colSpan={switchColSpan? 6 :4}>
              <Field
                  disabled={!canEdit}
                name={"instagram"}
                component={ValidatedInstagram}
                label={"Instagram"}
                // validator={[urlValidator]}
              />
            </GridLayoutItem >

            <GridLayoutItem  row={switchColSpan? 10 :5}  colSpan={switchColSpan? 6 :2}>
              <Field
                  disabled={!canEdit}
                  name={"radius"}
                  defaultVal={"10"}
                  value={(props.item.radius ?? "10" )}
                  component={ValidatedInput}
                  label={"Raggio"}
                  adornment={"Km"}
                  required
                  validator={[digitValidator]}
              />
            </GridLayoutItem>

            <GridLayoutItem row={switchColSpan? 11 :5}  colSpan={switchColSpan? 6 :2}>
              <Field
                  disabled={!canEdit}
                  name={"latitude"}
                  required
                  component={ValidatedInput}
                  label={"Latitudine"}
                  onChange={(e)=>setLat(e.target.value.toString())}
                  validator={[
                    requireValidator,
                    digitValidator,
                    coordinateValidator,
                  ]}
              />
            </GridLayoutItem>
            <GridLayoutItem row={switchColSpan? 11 :5}  colSpan={switchColSpan? 6 :2}>
              <Field
                  disabled={!canEdit}
                  name={"longitude"}
                  component={ValidatedInput}
                  label={"Longitudine"}
                  onChange={(e)=>setLng(e.target.value.toString())}
                  required
                  validator={[
                    requireValidator,
                    digitValidator,
                    coordinateValidator,
                  ]}
              />
            </GridLayoutItem>
            <GridLayoutItem row={switchColSpan? 12 :5}  colSpan={switchColSpan? 4 :2}  style={{paddingTop:"15px"}}>
              <a href={'https://www.google.com/maps/search/?api=1&query='+lat+','+lng} target={"_blank"}>Visualizza sulla mappa </a>
            </GridLayoutItem>
            <GridLayoutItem  row={switchColSpan? 12 :5}  colSpan={switchColSpan? 4 :2}
                             className={"attiva-switch"}
            >
              <Field
                  disabled={!canEdit}

                  name={"notifications"}
                  component={ValidatedSwitch}
                  label={"Notifiche"}
                  //value={active}
                  defaultChecked={false}
              />
            </GridLayoutItem>

            <GridLayoutItem  row={switchColSpan? 13 :6}  colSpan={switchColSpan? 6 :2}
            >
              <Field

                  disabled={!canEdit}
                  name={"price_increase"}
                  component={ValidatedDropDownListKeyLabelTypes_customBehavior}
                  onChange={(event)=>{setPriceIncreaseValue(event.value.key)}}
                  data={priceincreases}
                  value={priceIncreaseValue}
                  defaultVal={priceincreases[0]?.key}
                  label={"Ricarico"}
              />
            </GridLayoutItem>
            <GridLayoutItem  row={switchColSpan? 14 :6}  colSpan={switchColSpan? 12 :4}>
              <Field
                  disabled={!canEdit}
                  name={"iban"}
                  component={ValidatedIBANField}
                  label={"IBAN"}
                  // validator={[
                  //   ibanValidator,
                  // ]}
              />
            </GridLayoutItem >
            {/*<GridLayoutItem  row={switchColSpan? 12 :6}  colSpan={switchColSpan? 4 :6}*/}
            {/*                 style={{textAlign: "left"}}*/}
            {/*                 className={"attiva-switch"}*/}
            {/*>*/}
            {/*  <Field*/}

            {/*      disabled={!canEdit}*/}
            {/*      name={"exclude_vat_zero"}*/}
            {/*      component={ValidatedSwitch}*/}
            {/*      label={"Escludi esperienze esenti iva"}*/}
            {/*      value={false}*/}
            {/*      defaultChecked={false}*/}
            {/*  />*/}
            {/*</GridLayoutItem>*/}



            {/*img logo*/}
            <GridLayoutItem row={switchColSpan? 15 :7}  colSpan={switchColSpan? 12 :6}>
              <div className="std-singleupload-container">
                <label
                  className="std-singleupload-label upload-label-custom"
                  data-shrink="true">Logo</label>

                <Field
                    disabled={!canEdit}
                    className={"upload-attachments"}
                    name={"logo_attachment"}
                    multiline
                    rows={4}
                    type={'logo'}
                    // displayError={isAllegatiEmpty}
                    // setAllegati={(e) => setIsAllegatiEmpty(e)}
                    component={memoizedSingleFileUploader}
                    label={"allegati"}
                    company_id={props.item.id}
                    temp_company_id={props.item.temp_id}
                    // required
                    // validator={[requireFileValidator]}
                />


              </div>
            </GridLayoutItem >

            {/*img Copertina*/}
            <GridLayoutItem row={switchColSpan? 16 :7}   colSpan={switchColSpan? 12 :6}>
              <div className="std-singleupload-container">
                <label
                  className="std-singleupload-label upload-label-custom"
                  data-shrink="true">Copertina</label>
                <Field
                    disabled={!canEdit}
                    className={"upload-attachments"}
                    name={"cover_attachment"}
                    type={'cover'}
                    multiline
                    rows={4}
                    // displayError={isAllegatiEmpty}
                    // setAllegati={(e) => setIsAllegatiEmpty(e)}
                    component={memoizedSingleFileUploader}
                    label={"allegati"}
                    company_id={props.item.id}
                    temp_company_id={props.item.temp_id}
                    // required
                    // validator={[requireFileValidator]}
                />
              </div>
            </GridLayoutItem >

            {/*NOTE*/}
            <GridLayoutItem row={switchColSpan? 17 :8}  colSpan={12}>
              <Field
                  disabled={!canEdit}
                name={"notes"}
                multiline
                rows={3}
                className={"custom-unif-font"}
                component={ValidatedInput}

                label={"Note"}
                validator={[]}
              />
            </GridLayoutItem >

            {/*Salva e annulla*/}
            <GridLayoutItem  row={switchColSpan? 18 :9} col={6} colSpan={switchColSpan? 12 :4}>
              <div className="k-form-buttons">
                {canEdit && (isAdding || isUpdating ? (
                    <CenteredLoadingSpinner/>
                ) : (
                    <Fragment>

                          <BaseButton
                          id={"submit-button-company-id"}
                          className={"custom-unif-viola-back"}
                          type={"submit"}
                          variant="contained"
                          startIcon={<SaveIcon/>}
                      >
                        Salva
                      </BaseButton>

                      <BaseButton
                          id="cancel-button-company"
                          className={"custom-button-annulla custom-unif-grigio-back left-spacing-10p custom-unif-arancione-back"}
                          // disabled={!formRenderProps.allowSubmit  || !formRenderProps.valid || isAllegatiEmpty}
                          onClick={
                            (props.hasCancel) ? props.cancelEdit : formRenderProps.onFormReset


                          }
                          startIcon={<CancelPresentationIcon/>}
                      >
                        Annulla
                      </BaseButton>

                    </Fragment>
                ))}

                {formRenderProps.visited &&
                  formRenderProps.errors &&
                  formRenderProps.errors.VALIDATION_SUMMARY && (
                    <div className={"k-messagebox k-messagebox-error"}>
                      <Error>{formRenderProps.errors.VALIDATION_SUMMARY}</Error>
                    </div>
                  )}
              </div>
            </GridLayoutItem >
            <GridLayoutItem  row={switchColSpan? 19 :10} col={6} colSpan={switchColSpan? 12 :4}>
                    <Fragment>

                      {(user?.role === "superadmin" || user?.userId !== user?.userRealId) && props.item.id &&
                          <BaseButton
                              className={"custom-unif-viola-back"}
                              variant={"contained"}
                              // disabled={!formRenderProps.allowSubmit  || !formRenderProps.valid || isAllegatiEmpty}
                              onClick={handleClickDistances}
                              startIcon={isLoadingMatrix?  <CircularProgress style={{ width:"20px", height: "20px", color: "white" }}/> : <span></span>}
                          >
                            Calcola matrice distanze
                          </BaseButton>
                      }
                    </Fragment>
            </GridLayoutItem >
          </GridLayout>
          {serverErrorDialog && (
              <ServerErrorDialog
                  toggleDialog={() => {  setServerErrorDialog(!serverErrorDialog);}}
                  onConfirm={() => {window.location.reload()}}

              />
          )}
        </FormElement>
      )}
    />
  );
};
export default ResellerForm;
