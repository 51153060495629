import {cloneElement, useState} from "react";

import {ListItemProps, MultiSelect, MultiSelectChangeEvent,} from "@progress/kendo-react-dropdowns";
import {FieldRenderProps} from "@progress/kendo-react-form";
import {Error} from "@progress/kendo-react-labels";
import {useAppSelector} from "../../store";
import {Icon} from "@mui/material";

import {IStaff} from "../../models/entities/IStaff";
import {IRoleType} from "models/entities/types/IRoleType";

export const OtherStaffMultiselect = (props: FieldRenderProps) => {
  const {
    validationMessage,
    visited,
    value,
    data,
    staffAvailable,
  } = props;
  const { colors, roles } = useAppSelector((store) => store.types);

  const [otherStaffSelected, setOtherStaffSelected] = useState(
    value
      ? value.map((id: number) => {
          return staffAvailable.find((item: IStaff) => item.id === id);
        })
      : []
  );

  const handleChange = (event: MultiSelectChangeEvent) => {
    if (props.onChange) {
      props.onChange.call(undefined, { value: event.value });
    }
    setOtherStaffSelected(event.value);
  };

  const staffItemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const staffItem = itemProps.dataItem as IStaff;

    const itemChildren = [
      <div style={{ alignSelf: "inherit" }}>
        <span style={{ paddingLeft: "1px", marginTop: "-5px" }}>
          <span

              className={"nameicon-roles"}
            style={{
              background: colors.find((t) => t.label === staffItem.color)?.hex,
              borderRadius: "50%",
              border: "1px solid #9e9e9e",
                display: "inline-block",
                textAlign: "center",
                overflow: "hidden",
            }}
          ></span>
          <span className={"name-roles"} style={{ marginLeft: "10px" , marginTop: "-6px",}}>
            {staffItem.initials}
          </span>
        </span>
        <span style={{ paddingLeft: "10px" }}>
          {" "}
          {roles
            .filter((t) => staffItem?.planner_role?.find((r) => r === t.label))?.map((item: IRoleType) => {
              return (
                <Icon className={"nameicon-right-roles"}  color="primary" title={item.label}>
                  {item.icon}
                </Icon>
              );
            })}
        </span>
      </div>,
    ];

    return cloneElement(li, li.props, itemChildren);
  };

  return (
      <div>
          <div className="std-multiselect-container dropdownModifica custom-unif-dropdown-focus" style={{marginTop: "0vh"}}>
              <label
                  className="labelModifica std-multiselect-label"
                  data-shrink="true">{props.label}
              </label>
              <div className="std-multiselect-component">

                  <MultiSelect
                      onChange={handleChange}
                      value={otherStaffSelected}
                      data={data}
                      dataItemKey={"id"}
                      textField={"name"}
                      itemRender={staffItemRender}
                      tags={
                          otherStaffSelected && otherStaffSelected.length > 0
                              ? otherStaffSelected.map((item: any) => {
                                  return {
                                      text: `${item?.name} ${item?.surname}`,
                                      data: [item],
                                  };
                              })
                              : []
                      }
                  />
              </div>
          </div>
          {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
  );
};
