import React, {useState} from "react";
import {Dialog} from "@progress/kendo-react-dialogs";
import {useCurrentStaff} from "../../utils/hooks/currentStaff";
import {useGetStaffWeeksQuery} from "../../store/api/staff";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {useAppDispatch} from "../../store";
import {CompositeFilterDescriptor, process, State} from "@progress/kendo-data-query";
import {Grid, GridCellProps, GridDataStateChangeEvent} from "@progress/kendo-react-grid";
import {load,} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import numbers from "cldr-numbers-full/main/it/numbers.json";
import currencies from "cldr-numbers-full/main/it/currencies.json";
import caGregorian from "cldr-dates-full/main/it/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/it/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/it/timeZoneNames.json";
import BaseIconButton from "../common/BaseIconButton";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import EditIcon from "@mui/icons-material/Edit";
import {IWeek} from "../../models/entities/IWeek";
import StaffTimetable from "./StaffTimetable";
import {IWeeklyTimetableDay} from "../../models/entities/IWeeklyTimetableDay";
import {useGetStaffWeeklyTimetableQuery} from "../../store/api/staffTimetables";
import {selectStaffWeek} from "../../store/staff";
import SelectWeekTitleBar from "../common/SelectWeekTitleBar";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import {useWindowSize} from "../common/ScreenSizes";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);

const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    //filters: [{ field: "name", operator: "contains", value: "Chef" }],
    filters: [],
};

const WeeklyStaffTimetablePageBeta = (staffId?: number) => {

    const currentStaff = useCurrentStaff();
    const [currentItem, setCurrentItem] = useState<IWeek>();
    const [currentFirstDayWeek, setCurrentFirstDayWeek] = useState("");
    const [openForm, setOpenForm] = useState(false);

    const {
        data: currentStaffWeeklyTimetable = [],
        isFetching: isFetchingCurrentStaffWeeklyTimetable
    } = useGetStaffWeeklyTimetableQuery({
        staffId: currentStaff !== undefined ? (currentStaff.id as number) : 0,
        firstWeekDay: currentFirstDayWeek as string,
    }, { skip: !currentStaff || !currentFirstDayWeek });

    const {
        data: staffWeeks = [],
        isFetching,
        refetch: refetchStaffWeeks
    } = useGetStaffWeeksQuery(currentStaff?.id ?? skipToken);

    const dispatch = useAppDispatch();
    const [sort, ] = useState();
    const [filter, ] = useState(initialFilter);
    const [filterable, ] = useState(false);
    const initialDataState: State = {
        sort: [],
    };
    const [dataState, setDataState] = React.useState<State>(initialDataState);

    const handlePrevious = () => {
        if (currentItem) {
            var itemIndex = (staffWeeks as IWeek[]).findIndex(t=>t.firstDayWeek===currentItem.firstDayWeek);

            //console.log((staffWeeks as IWeek[]).indexOf(currentItem));
            let prev = staffWeeks[itemIndex - 1];
            setCurrentItem(prev);
            dispatch(selectStaffWeek(prev.firstDayWeek as string));
        }
    };
    const handleNext = () => {
        if (currentItem) {
            var itemIndex = (staffWeeks as IWeek[]).findIndex(t=>t.firstDayWeek===currentItem.firstDayWeek);
            //console.log(itemIndex);
            //console.log((staffWeeks as IWeek[]).indexOf(currentItem));
            let succ = staffWeeks[itemIndex + 1]
            setCurrentItem(succ);
            dispatch(selectStaffWeek(succ.firstDayWeek as string));
        }
    };


    const handleChange = (e: any) => {
        // console.log(e);
        setCurrentItem(e);

        // console.log("currentStaffWeeklyTimetable");
        dispatch(selectStaffWeek(e.firstDayWeek as string));
        // console.log(isLoadingCurrentStaffWeeklyTimetable);
        // setCurrentStaffWeeklyTimetable(week);
        setCurrentFirstDayWeek(e.firstDayWeek);
        setOpenForm(true);

    };


    return (isFetching && !openForm ? <CenteredLoadingSpinner /> : (<React.Fragment>
            <Grid
                className={"grid-orari"}
                // total={staffWeeks.length}
                // pageSize={4}
                sortable={true}
                filterable={filterable}
                filter={filter}
                rowHeight={10}
                sort={sort}
                resizable={false}
                data={process(staffWeeks, dataState)}
                // onItemChange={itemChange}
                // editField={editField}
                {...dataState}
                onDataStateChange={(e: GridDataStateChangeEvent) => {
                    setDataState(e.dataState);
                }}

            >
                <Column
                    field="label"
                    title="Settimana"
                // width={matches ? "120px" : ""}
                // editor="numeric"
                />
                {/*<Column*/}
                {/*    field="planning"*/}
                {/*    title="Pianificazione"*/}
                {/*    // width={matches ? "120px" : ""}*/}
                {/*    // editor="numeric"*/}
                {/*    cell={(props: GridCellProps) => (*/}

                {/*        <td>*/}
                {/*            {(props.dataItem.planning === "mancante") ?*/}
                {/*                <span className={"custom-unif-arancione"}>{props.dataItem.planning}</span>*/}
                {/*                : <span>{props.dataItem.planning}</span>*/}
                {/*            }*/}

                {/*        </td>*/}
                {/*    )}*/}
                {/*/>*/}
                <Column
                    field="timeType"
                    title="Tipo orario"
                    // width={matches ? "120px" : ""}
                    // editor="numeric"
                    cell={(props: GridCellProps) => (

                        <td className="orario-column-staff">
                            {(props.dataItem.planning !== "mancante") ?
                                <span>{props.dataItem.timeType}</span>
                                :
                                    (props.dataItem.planning === "mancante") ?
                                        <span className={"custom-unif-arancione"}>{props.dataItem.planning}</span>
                                        : <span>{props.dataItem.planning}</span>
                            }
                        </td>
                    )}
                />


                {/* da mettere una width minima */}
                <Column
                    // cell={CommandCell}
                    // width={matches ? "150px" : ""}
                    width="100px"
                    cell={(props: GridCellProps) => (

                        <td className="matita-column-staff">
                            {/*${console.log(props)}*/}
                            <BaseIconButton color="primary"
                                className={"custom-unif-viola "} onClick={(e: any) => handleChange(props.dataItem)}>

                                <EditIcon />
                            </BaseIconButton>

                        </td>
                    )}
                />

            </Grid>
            {(openForm === true) ?
                <Dialog
                    height={"100vh"}
                    width={"60vw"}
                    className={"dialog-staff-timetable-form-media-rule"}
                    title={
                        <span>

                            <SelectWeekTitleBar
                                currentItem={currentItem}
                                staffWeeks={staffWeeks}
                                handlePrevious={handlePrevious}
                                handleNext={handleNext}
                                initials={currentStaff?.initials}
                            />

                        </span>
                    }
                    onClose={() => {
                        setOpenForm(false);
                        refetchStaffWeeks();
                    }}
                >
                    {isFetchingCurrentStaffWeeklyTimetable ? <CenteredLoadingSpinner /> : (<StaffTimetable
                        timetable={
                            currentStaffWeeklyTimetable as IWeeklyTimetableDay[]
                        }
                    />)

                    }
                    
                </Dialog>
                // <TabsStaffDialogForm firstDayWeek={currentFirstDayWeek} item={currentItem} onSubmit={} />
                : ""
            }

        </React.Fragment>) );
};

export default WeeklyStaffTimetablePageBeta;
