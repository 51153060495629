import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {Error} from "@progress/kendo-react-labels";
import Grid from "@mui/material/Grid";

import BaseButton from "components/common/BaseButton";
import {IUserViewModel} from "models/views/IUserViewModel";
import {ValidatedInput} from "components/common/ValidatedFields";
import {requireValidator} from "utils/validators/requireValidator";
import {useUpdateProfileMutation} from "store/api/users";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import React, {FC, Fragment, useEffect} from "react";
import {useMeInfo} from "utils/hooks/me";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SaveIcon from "@mui/icons-material/Save";

const ChangeProfileForm: FC<{
  cancelEdit: () => void;
}> = ({ cancelEdit }) => {
  const [updateProfile, { isLoading: isUpdating, isSuccess }] =
      useUpdateProfileMutation();
  // const user = useCurrentUser();
  const { user, isFetching, refetch } = useMeInfo();
  const handleSubmit = (event: any) => {
    // console.log(event);

      updateProfile({
      id: event.id,
      user: {
        email: event.email,
        //role: event.role,
        //companyId: event.company_id.id,
        name: event.name,
        surname: event.surname,
      } as IUserViewModel,
    });
  };
  useEffect(() => {
    // console.log("user");
    // console.log(user);
    // console.log("-------");
    if (isSuccess) {
      cancelEdit();
      refetch();
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return isFetching ? (
    <CenteredLoadingSpinner />
  ) : (
    <Form
      onSubmit={handleSubmit}
      initialValues={user}
      render={(formRenderProps) => (
        <FormElement style={{ maxWidth: 950 }}>
          <Grid container spacing={1}>
              <Grid item xs={12} className="row-media-rule rows-grandi">
                <Field
                  name={"name"}
                  component={ValidatedInput}
                  label={"Nome"}
                  required
                  validator={[requireValidator]}
                />
              </Grid>
              <Grid item xs={12} className="row-media-rule rows-grandi">
                <Field
                  name={"surname"}
                  component={ValidatedInput}
                  label={"Cognome"}
                  validator={[requireValidator]}
                />
              </Grid>
              <Grid item xs={12} className="row-media-rule ">
                <Field
                  name={"email"}
                  component={ValidatedInput}
                  label={"Email"}
                  validator={[requireValidator]}
                />
              </Grid>
            {/* <Grid container item xs={12} spacing={3}>
              <Grid item xs={12}>
                <Field
                  name={"username"}
                  component={ValidatedInput}
                  label={"Username"}
                  validator={[requireValidator]}
                />
              </Grid>
            </Grid> */}

            <Grid container item xs={12}></Grid>
          </Grid>
          {/*SPAZIO TRA RIGHE*/}
          <Grid container item xs={12}></Grid>
          <Grid container item xs={12}></Grid>
          <Grid container justifyContent="center" style={{ marginTop: "2vh" }}>
              <div className="k-form-buttons">
            {isUpdating ? (
              <CenteredLoadingSpinner />
            ) : (
              <Fragment>
                <BaseButton
                  className={` ${
                    formRenderProps.allowSubmit ? "custom-unif-viola-back" : ""
                  }`}
                  type={"submit"}
                  variant="contained"
                  disabled={!formRenderProps.allowSubmit}
                  startIcon={<SaveIcon />}
                >
                  Salva
                </BaseButton>
                <BaseButton
                    onClick={cancelEdit}
                    disabled={!formRenderProps.allowSubmit}
                    className={`custom-button-annulla custom-unif-grigio-back left-spacing-10p ${
                        formRenderProps.allowSubmit
                            ? "custom-unif-arancione-back"
                            : ""
                    }`}

                    startIcon={<CancelPresentationIcon />}
                >
                  Annulla
                </BaseButton>
              </Fragment>
            )}


            {formRenderProps.visited &&
              formRenderProps.errors &&
              formRenderProps.errors.VALIDATION_SUMMARY && (
                <div className={"k-messagebox k-messagebox-error"}>
                  <Error>{formRenderProps.errors.VALIDATION_SUMMARY}</Error>
                </div>
              )}
              </div>
          </Grid>
        </FormElement>
      )}
    />
  );
};
export default ChangeProfileForm;
