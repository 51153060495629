import React, {FC, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store";
import CenteredLoadingSpinner from "../common/CenteredLoadingSpinner";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import {usePlannerCurrentWeek} from "../../utils/hooks/currentWeek";
import {IWeeklyTimetableDay} from "../../models/entities/IWeeklyTimetableDay";
import {useGetPlannerWeeklyTimetableQuery} from "store/api/plannerTimetables";
import {Grid, GridCellProps, GridDataStateChangeEvent} from "@progress/kendo-react-grid";
import {CompositeFilterDescriptor, process, State} from "@progress/kendo-data-query";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import BaseIconButton from "../common/BaseIconButton";
import EditIcon from "@mui/icons-material/Edit";
import {Dialog} from "@progress/kendo-react-dialogs";
import {IWeek} from "../../models/entities/IWeek";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {useGetPlannerWeeksQuery} from "../../store/api/planners";
import {selectWeek} from "../../store/planners";
import TimetableCustom from "./TimetableCustom";
import SelectWeekPlanner from "../common/SelectWeekPlanner";

const initialFilter: CompositeFilterDescriptor = {
  logic: "and",
  //filters: [{ field: "name", operator: "contains", value: "Chef" }],
  filters: [],
};


const TimetableForm: FC<{
  staff?: boolean;
}> = ({ staff }) => {

  const [currentItem, setCurrentItem] = useState<IWeek>();
  const [openForm, setOpenForm] = useState(false);
  const [, setCurrentFirstDayWeekState] = useState("");

  const [sort] = useState();
  const [filter] = useState(initialFilter);
  const [filterable] = useState(false);
  const initialDataState: State = {
    sort: [],
  };
  const [dataState, setDataState] = React.useState<State>(initialDataState);
  const { currentFirstDayWeek } = useAppSelector((store) => store.planners);

  const currentPlanner = useCurrentPlanner();
  const currentWeek = usePlannerCurrentWeek();

  useEffect(() => {
    // console.log("currentFirstDayWeek");
    // console.log(currentFirstDayWeek);
    if (currentPlanner && currentWeek) {
      setCurrentFirstDayWeekState(currentFirstDayWeek);
    }
  }, [currentFirstDayWeek, currentPlanner, currentWeek]);

  const {
    data: currentWeeklyTimetable,
    isLoading : isLoadingCurrentWeeklyTimetable,
    isFetching
  } = useGetPlannerWeeklyTimetableQuery({
    plannerId: currentPlanner?.id as number,
    firstWeekDay: currentWeek?.firstDayWeek as string,
  }, { skip: !currentWeek || !currentPlanner });


  const {
    data: plannerWeeks = []
  } = useGetPlannerWeeksQuery(currentPlanner?.id ?? skipToken);

  const dispatch = useAppDispatch();










  const handlePrevious = () => {
    let prev;

    prev = plannerWeeks?.find((item: IWeek) => item.firstDayWeek === currentItem?.firstDayWeek );

    if(prev!== undefined){

      prev = plannerWeeks[(plannerWeeks as IWeek[]).indexOf(prev) -1];

      setCurrentItem(prev);

      dispatch(selectWeek(prev?.firstDayWeek as string));
    }

  };
  const handleNext = () => {
    let succ;

      succ = plannerWeeks?.find((item: IWeek) => item.firstDayWeek === currentItem?.firstDayWeek );

      if(succ!== undefined){

        succ = plannerWeeks[(plannerWeeks as IWeek[]).indexOf(succ) +1];

        setCurrentItem(succ);

        dispatch(selectWeek(succ?.firstDayWeek as string));
      }


  };


  const handleChange = (e: any) => {
    // console.log(e);
    setCurrentItem(e);

    // console.log("currentStaffWeeklyTimetable");
    dispatch(selectWeek(e.firstDayWeek as string));
    // console.log(isLoadingCurrentWeeklyTimetable);
    // setCurrentStaffWeeklyTimetable(week);
    setCurrentFirstDayWeekState(e.firstDayWeek);
    setOpenForm(true);

  };



  return (
      <React.Fragment>
      {
        plannerWeeks ?
           (
               <>
              <Grid
                  className={"grid-orari-agenda"}
                  // total={plannerWeeks.length}
                  // pageSize={4}
                  sortable={true}
                  filterable={filterable}
                  filter={filter}
                  rowHeight={10}
                  sort={sort}
                  resizable={false}
                  data={process(plannerWeeks, dataState)}
                  // onItemChange={itemChange}
                  // editField={editField}
                  {...dataState}
                  onDataStateChange={(e: GridDataStateChangeEvent) => {
                    setDataState(e.dataState);
                  }}

              >
                <Column
                    field="label"
                    title="Settimana"
                    // width={matches ? "120px" : ""}
                    // editor="numeric"
                    cell={(props: GridCellProps) => (

                        <td>
                          {props.dataItem.label.split('nw')[0]} <br></br> {props.dataItem.label.split('nw')[1]}
                        </td>
                    )}
                />
                <Column
                    field="isDefault"
                    title="Tipo orario"
                    // width={matches ? "120px" : ""}
                    editor="numeric"
                    cell={(props: GridCellProps) => (

                        <td>
                          {(props.dataItem.isDefault !== 1) ?
                              <span>Personalizzato</span>
                              : <span>Standard</span>
                          }

                        </td>
                    )}
                />
                <Column

                    width="100px"
                    cell={(props: GridCellProps) => (

                        <td>
                          {/*${console.log(props)}*/}
                          <BaseIconButton color="primary"
                                          className={"custom-unif-viola "}
                                          onClick={(e: any) => handleChange(props.dataItem)}>

                            <EditIcon/>
                          </BaseIconButton>

                        </td>
                    )}
                />

              </Grid>
              {(openForm === true) ?
                  <Dialog
                      height={"100vh"}
                      width={"60vw"}
                      className={"dialog-staff-timetable-form-media-rule"}
                      title={
                        <span>
                            {/*<SelectWeek />*/}
                          <SelectWeekPlanner
                              currentItem={currentItem}
                              staffWeeks={plannerWeeks}
                              handlePrevious={handlePrevious}
                              handleNext={handleNext}
                              // initials={currentStaff?.initials}
                          />

                        </span>
                      }
                      onClose={() => setOpenForm(false)}
                  >
                    {(isLoadingCurrentWeeklyTimetable || isFetching) ? (
                        <CenteredLoadingSpinner></CenteredLoadingSpinner>
                    ) : (<TimetableCustom
                        timetable={
                          currentWeeklyTimetable as IWeeklyTimetableDay[]
                        }
                    />)}

                  </Dialog>
                  // <TabsStaffDialogForm firstDayWeek={currentFirstDayWeek} item={currentItem} onSubmit={} />
                  : ""
              }

               </>

           )
            :
           ( <CenteredLoadingSpinner/>)
      }
      </React.Fragment>
    // <Timetable timetable={currentWeeklyTimetable as IWeeklyTimetableDay[]} />
  );
};

export default TimetableForm;
