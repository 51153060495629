import SaveIcon from "@mui/icons-material/Save";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import RestoreIcon from "@mui/icons-material/Restore";
import EditIcon from "@mui/icons-material/Edit";
import BaseIconButton from "./BaseIconButton";
import {Grid} from "@mui/material";

const CommandTable = (props: any) => {
  const { dataItem } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem.id === undefined;

  return inEdit ? (
    <td>
      <Grid container direction="row">
        <BaseIconButton
            className={"custom-unif-viola "}
          color="primary"
          onClick={() =>
            isNewItem ? props.add(dataItem) : props.update(dataItem)
          }
        >
          <SaveIcon />
        </BaseIconButton>

        <BaseIconButton
          onClick={() =>
            isNewItem ? props.discard(dataItem) : props.cancel(dataItem)
          }
        >
          {isNewItem ? <CancelIcon /> : <RestoreIcon />}
        </BaseIconButton>
      </Grid>
    </td>
  ) : (
    <td>
      <Grid container direction="row">
        <BaseIconButton color="primary"
                        className={"custom-unif-viola "} onClick={() => props.edit(dataItem)}>
          <EditIcon />
        </BaseIconButton>
        <BaseIconButton onClick={() => props.remove(dataItem)}>
          <DeleteOutlineIcon />
        </BaseIconButton>
      </Grid>
    </td>
  );
};

export default CommandTable;
