import {Calendar, DatePicker} from "@progress/kendo-react-dateinputs";
import {Error} from "@progress/kendo-react-labels";
import React from "react";
import {currentYear, getFirstDayOfYear, getLastDayOfYear} from "utils/dateHelper";


export const CustomCalendar =(props: any)  => {
    const { visited, validationMessage, onChange, ...others } = props;

    return (
            <Calendar
                topView="month"
                // min={getFirstDayOfYear}
                min={new Date((new Date).getFullYear(),0,1)}
                max={new Date((new Date).getFullYear(),11,31)}
                onChange={(e) => {
                    if (e.value) {
                        onChange(e);
                    }
                }}
            />
        );

}


const CustomDatePicker = (props: any) => {

  const { visited, validationMessage, onChange, ...others } = props;
  return (
    <span>
      <DatePicker
      onChange={(e) => {
        if (e.value) {
          onChange(e);
        }
      }} 
      {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </span>
  );
};
export const CustomDatePickerForRanges = (props: any) => {

  const { visited, validationMessage, onChange, ...others } = props;
  return (
    <span>
      <DatePicker
          calendar={CustomCalendar}
          onChange={(e) => {
            if (e.value) {
              onChange(e);
            }
          }}
          {...others} />
          {visited && validationMessage && <Error>{validationMessage}</Error>}
    </span>
  );
};

export default CustomDatePicker;