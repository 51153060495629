import React, {cloneElement, useEffect, useState} from "react";

import {DropDownList, DropDownListChangeEvent, ListItemProps,} from "@progress/kendo-react-dropdowns";
import {FieldRenderProps} from "@progress/kendo-react-form";
import {Error} from "@progress/kendo-react-labels";

import {ILocation} from "../../models/entities/ILocation";
import {useGetLocationsQuery} from "../../store/api/locations";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {Icon} from "@mui/material";

export const LocationsMultiselect = (props: FieldRenderProps) => {
  const { validationMessage, visited,  } = props;
  const currentPlanner = useCurrentPlanner();
  const { data: locations = [],  } = useGetLocationsQuery(
    currentPlanner?.id ?? skipToken
  );

  const [selectedLocations, setSelectedLocations] = useState();

  useEffect(() => {
      if(props.value) {
          if (props.value.length) {
              setSelectedLocations(props.value[0]);
          }
      }
  }, [props.value]);

  const handleChange = (event: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange.call(undefined, { value: event.value });
    }
      setSelectedLocations(event.value);
  };

    const valueRender = (
        element: React.ReactElement<HTMLSpanElement>,
        value: any
    ) => {
        if (!value) {
            return element;
        }

        const children = [
            <span key={value?.name ?? 'value-key'}>
        {value?.name}
      </span>,
        ];

        return cloneElement(element, { ...element.props }, children);
    };

  const locationsItemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {

    const itemChildren = [
      <div style={{ alignSelf: "inherit" }}  key={itemProps.dataItem.id}>
        <span style={{ marginLeft: "10px" }}>{li.props.children}</span>
      </div>,
    ];

    return cloneElement(li, li.props, itemChildren);
  };

  return (
      <div>
          <div className="std-dropdown-container dropdownModifica multiselect-dropdown custom-unif-dropdown-focus" >
              <label
                  className="labelModifica std-dropdown-label"
                  data-shrink="true">{props.label}
              </label>
              <div className="std-dropdown-component">
                 <span
                     key={'dropdown-'+props.name}
                     style={{
                         display: "inline",
                         // background: color,
                         color: "black",
                         width: "26px",
                         height: "18px",
                         textAlign: "left",
                         marginRight: "10px",
                         marginTop: "-6px"
                     }}
                 > <Icon color="inherit" >room</Icon></span>

                  <DropDownList
                      className={"custom-unif-font-2"}

                      onChange={handleChange}
                      value={selectedLocations}
                      data={locations}
                      disabled={props.disabled}
                      dataItemKey={"id"}
                      key={"id"}
                      textField={"name"}
                      valueRender={valueRender}
                      itemRender={locationsItemRender}
                  />
              </div>
          </div>
          {((visited && validationMessage) || props.displError==0 ) ? (<Error>Campo obbligatorio</Error> ) : ""}
      </div>
  );
};
