import {EditFormProps} from "../../models/helpers/IEditFormProps";
import * as React from "react";
import {useState} from "react";
import {IReseller} from "../../models/entities/IReseller";
import {TabStrip, TabStripSelectEventArguments, TabStripTab} from "@progress/kendo-react-layout";
import ResellerForm from "../../components/resellers/ResellerForm";
import ResellerAvailableExperience from "../../components/resellers/ResellerAvailableExperience";
import {useMe} from "../../utils/hooks/me";
import {skipToken} from "@reduxjs/toolkit/dist/query/react";
import {useGetResellerQuery} from "../../store/api/resellers";
import CenteredLoadingSpinner from "../../components/common/CenteredLoadingSpinner";

const ResellerPage = (props: EditFormProps<IReseller>) => {
    const user = useMe()
    const {
        data: company,
        isLoading,
    } = useGetResellerQuery(user?.companyId ?? skipToken);
    //la tab inizialmente selezionata

    const [selected, setSelected] = useState<number>(0);
    const handleSelect = (e: TabStripSelectEventArguments) => {

        setSelected(e.selected);
    };
    const handleSubmit = (event: IReseller) => {

        props.onSubmit(event);
        // window.location.reload();
    };



    const handleCancelEdit = () => {
        props.cancelEdit();
    };
    const handleNothing = ()=>{

    }

    return (
        company=== undefined || isLoading ? (
            <CenteredLoadingSpinner />
        ) : (
            <TabStrip selected={selected} onSelect={handleSelect} className={"nmp-custom-tabstrip"} style={{marginTop: "1vh"}}>
                <TabStripTab title="Anagrafica" contentClassName={"nmp-custom-tabstrip-tab"}>
                    <ResellerForm
                        cancelEdit={handleCancelEdit}
                        onSubmit={handleSubmit}
                        item={company}
                        hasCancel={true}
                        onChange={handleNothing}

                    />
                </TabStripTab>
                <TabStripTab title="Esperienze Erogabili" contentClassName={"nmp-custom-tabstrip-tab"}>
                    <ResellerAvailableExperience   reseller_id={company.id ?? 0 } />
                </TabStripTab>
            </TabStrip>
        )

    );
};
export default ResellerPage;
