import {IGetLocationsDto} from "../../models/dto/locations/IGetLocationsDto";
import {ILocation} from "../../models/entities/ILocation";
import {IGetLocationDto} from "../../models/dto/locations/IGetLocationDto";
import {IAddLocationDto} from "../../models/dto/locations/IAddLocationDto";
import {IUpdateLocationDto} from "../../models/dto/locations/IUpdateLocationDto";
import {api} from "./api";

const locationsApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getLocations: build.query<ILocation[], number>({
      query: (id) => ({
        url: 'location',
        params: {'planner_id': id}
      }),
      transformResponse: (baseQueryReturnValue: IGetLocationsDto, meta) => baseQueryReturnValue.locations,
      providesTags: result => result ? [...result.map(({id}) => ({type: 'Locations', id} as const)), {
        type: 'Locations',
        id: 'LIST'
      },] : [{type: 'Locations', id: 'LIST'},]
    }),
    getLocation: build.query<ILocation, number>({
      query: (id) => `location/${id}`,
      transformResponse: (baseQueryReturnValue: IGetLocationDto, meta) => baseQueryReturnValue.location,
      providesTags: (result, error, id) => [{type: 'Locations', id}],
    }),
    addLocation: build.mutation<ILocation, ILocation>({
      query: (location) => ({
        url: `location`,
        method: 'POST',
        body: location
      }),
      transformResponse: (res: IAddLocationDto, meta) => res.location,
      invalidatesTags: (result, error) => error ? [] : [{type: 'Locations', id: 'LIST'}],
    }),
    updateLocation: build.mutation<ILocation, { id: number, location: ILocation }>({
      query: ({id, location}) => ({
        url: `location/${id}`,
        method: 'PUT',
        body: location
      }),
      transformResponse: (res: IUpdateLocationDto, meta) => res.location,
      invalidatesTags:  (result, error, {id}) => error ? [] :  [{type: 'Locations', id}],
      async onQueryStarted({id, ...location}, {dispatch, queryFulfilled}) {
        const putResult = dispatch(
          locationsApi.util.updateQueryData('getLocation', id, (draft) => {
            Object.assign(draft, location)
          })
        )
        try {
          await queryFulfilled
        } catch {
          putResult.undo()
        }
        // queryFulfilled.catch(putResult.undo)
      },
    }),
    deleteLocation: build.mutation<void, number>({
      query: (id) => ({
        url: `location/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => error ? [] :  [{type: 'Locations', id}],
    }),
  }),
})

export const {
  useGetLocationsQuery,
  useAddLocationMutation,
  useUpdateLocationMutation,
  useDeleteLocationMutation
} = locationsApi