import {api} from "./api";
import {IPlanner} from "../../models/entities/IPlanner";
import {IGetPlannersDto} from "../../models/dto/planners/IGetPlannersDto";
import {IGetPlannerDto} from "../../models/dto/planners/IGetPlannerDto";
import {IAddPlannerDto} from "../../models/dto/planners/IAddPlannerDto";
import {IUpdatePlannerDto} from "../../models/dto/planners/IUpdatePlannerDto";
import {IWeek} from "../../models/entities/IWeek";
import {IGetPlannerWeeksDto} from "../../models/dto/planners/IGetPlannerWeeksDto";

export const plannersApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getPlanners: build.query<IPlanner[], void>({
      query: () => `planner`,
      transformResponse: (res: IGetPlannersDto, meta) => res.planners,
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: "Planners", id } as const)),
            { type: "Planners", id: "LIST" },
          ]
          : [{ type: "Planners", id: "LIST" }],
    }),
    getPlanner: build.query<IPlanner, number>({
      query: (id) => `planner/${id}`,
      transformResponse: (res: IGetPlannerDto, meta) => res.planner,
      providesTags: (result, error, id) => [{ type: "Planners", id }],
    }),
    addPlanner: build.mutation<IPlanner, IPlanner>({
      query: (planner) => ({
        url: "planner",
        method: "POST",
        body: planner,
      }),
      transformResponse: (res: IAddPlannerDto, meta) => res.agenda,
      invalidatesTags: (result, error) => error ? [] : [{ type: "Planners", id: "LIST" }],
    }),
    updatePlanner: build.mutation<IPlanner, { id: number; planner: IPlanner }>({
      query: ({ id, planner }) => ({
        url: `planner/${id}`,
        method: "PUT",
        body: planner,
      }),
      transformResponse: (res: IUpdatePlannerDto, meta) => res.agenda,
      invalidatesTags: (result, error, { id }) => error ? [] : [{ type: "Planners", id }],
    }),
    deletePlanner: build.mutation<void, number>({
      query: (id) => ({
        url: `planner/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => error ? [] : [{ type: "Planners", id }],
    }),
    getPlannerWeeks: build.query<IWeek[], number>({
      query: (id) => `planner/${id}/weeks`,
      transformResponse: (res: IGetPlannerWeeksDto, meta) => res.weeks,
      providesTags: (result) =>
        result
          ? [
            ...result.map(
              ({ firstDayWeek }) =>
              ({
                type: "PlannerWeeks",
                id: firstDayWeek as string,
              } as const)
            ),
            { type: "PlannerWeeks", id: "LIST" },
          ]
          : [{ type: "PlannerWeeks", id: "LIST" }],
    }),
  }),
});

export const {
  useGetPlannerQuery,
  useGetPlannersQuery,
  useAddPlannerMutation,
  useUpdatePlannerMutation,
  useDeletePlannerMutation,
  useGetPlannerWeeksQuery,
} = plannersApi;
