import {Form, FormElement} from "@progress/kendo-react-form";

import {Error} from "@progress/kendo-react-labels";

import Grid from "@mui/material/Grid";

import {useAppDispatch} from "../../store";
import {IChangePasswordViewModel} from "../../models/views/IChangePasswordViewModel";
import {IChangePassword} from "../../models/entities/IChangePassword";
import PasswordInputField from "./PasswordInputField";
import BaseButton from "components/common/BaseButton";
import {useChangePasswordMutation} from "store/api/auth";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import React, {useEffect, useState} from "react";
import SaveIcon from "@mui/icons-material/Save";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

interface ChangePasswordFormProps {
  hasCancel: boolean;
  cancelEdit: () => void;
  onClose: () => void;
}

const ChangePasswordForm = (props: ChangePasswordFormProps) => {
  useAppDispatch();
  const [changePassword, { isLoading, isSuccess }] =
    useChangePasswordMutation();

  const errorMsg = "Le password devono coincidere";
  const [isErrorPsw, setIsErrorPsw] = useState(false);

  const handleSubmit = (event: IChangePassword) => {
    if(event.newpassword === event.newpasswordconfirm) {
      setIsErrorPsw(false);
      changePassword(event as IChangePasswordViewModel);
    }else{
      setIsErrorPsw(true);
    }
    // props.onSubmit();
  };
  useEffect(() => {
    if (isSuccess) {
      props.onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Form
      onSubmit={handleSubmit}
      render={(formRenderProps) => (
        <FormElement full-width>
          <Grid container direction="column" spacing={2} >
            <Grid item className="rows-grandi">
              <PasswordInputField name="oldpassword" label="Password vecchia" />
            </Grid>
            <Grid item className="rows-grandi">
              <PasswordInputField name="newpassword" label="Password nuova" />
            </Grid>
            <Grid item className="rows-grandi">
              <PasswordInputField
                name="newpasswordconfirm"
                label="Conferma password nuova"
              />
              {isErrorPsw && <Error>{errorMsg}</Error>}
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            <div className="k-form-buttons">
              {isLoading ? (
                <CenteredLoadingSpinner />
              ) : (
                <BaseButton
                  type={"submit"}
                  className={`custom-button-annulla  ${
                    formRenderProps.allowSubmit ? "custom-unif-viola-back" : "custom-unif-grigio-back "
                  }`}
                  disabled={!formRenderProps.allowSubmit}
                  startIcon={<SaveIcon />}
                >
                  Salva
                </BaseButton>
              )}
              {props.hasCancel && (
                  <BaseButton
                      onClick={props.cancelEdit}
                      disabled={!formRenderProps.allowSubmit}
                      startIcon={<CancelPresentationIcon />}
                      className={`custom-button-annulla custom-unif-grigio-back left-spacing-10p ${
                          (formRenderProps.allowSubmit)
                              ? "custom-unif-arancione-back"
                              : ""
                      }`}
                  >
                    Annulla
                  </BaseButton>
              )}
              {formRenderProps.visited &&
                formRenderProps.errors &&
                formRenderProps.errors.VALIDATION_SUMMARY && (
                  <div className={"k-messagebox k-messagebox-error"}>
                    <Error>{formRenderProps.errors.VALIDATION_SUMMARY}</Error>
                  </div>
                )}
            </div>
          </Grid>
        </FormElement>
      )}
    />
  );
};
export default ChangePasswordForm;
