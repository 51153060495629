import {SchedulerSlot} from "@progress/kendo-react-scheduler";
import {useEffect, useState} from "react";
import {ITimeRange} from "../../models/entities/ITimeRange";

export const CustomSchedulerSlot = function (props: any) {

  const [isAva, setIsava] = useState<boolean>(false);
  const checkAva = ()=>{
      if( props.group.resources[0].schedule[0]) {
          let time = new Date();
          time.setHours(props.end.getHours());
          time.setMinutes(props.end.getMinutes());
          time.setSeconds(0);




          if (props.group.resources[0].schedule[0].ranges.find((i: ITimeRange) => {
              if(i.begin_time && i.end_time) {
                  let timeStart = new Date();

                  timeStart.setHours(parseInt(i.begin_time.toString().split(':')[0]));
                  timeStart.setMinutes(parseInt(i.begin_time.toString().split(':')[1]));

                  let timeEnd = new Date();

                  timeEnd.setHours(parseInt(i.end_time.toString().split(':')[0]));
                  timeEnd.setMinutes(parseInt(i.end_time.toString().split(':')[1]));

                  if(time>=timeStart && time<=timeEnd){
                      return true;
                  }else{
                      return false;
                  }
              }
          })) {
              return true;
          } else {
              return false
          }
      }else{
          return false;
      }
  }
  useEffect(()=>{
      setIsava(checkAva());
  },[props])

  return (
    <SchedulerSlot
      {...props}
      // onDoubleClick={!props.isWorkHour ? () => false : props.onDoubleClick}
      onDoubleClick={
        props.group.resources[0].breaktime &&
        props.group.resources[0].breaktime.includes(props.row)  || !isAva
          ? () => false
          : props.onDoubleClick
      }
      style={{
        ...props.style,
        height: "0vh",
        backgroundColor:
          (props.group.resources[0].breaktime &&
          props.group.resources[0].breaktime.includes(props.row) ) || !isAva
            ? "#c4c4c2"
            : " ",
        cursor:
          props.group.resources[0].breaktime &&
          props.group.resources[0].breaktime.includes(props.row)  || !isAva
            ? "not-allowed"
            : "pointer",
        // minWidth: "100px",
      }}
    />
  );
};
