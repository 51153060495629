import {Box, Button, Grid, IconButton, Switch, TextField, Typography, useMediaQuery, useTheme,} from "@mui/material";
import React, {FC, useEffect, useState} from "react";
import {IWeeklyTimetableDay} from "../../models/entities/IWeeklyTimetableDay";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import {useTimetable} from "../../utils/hooks/timetable";
import {useAppDispatch, useAppSelector} from "../../store";
import {setCopiedFirstWeekDay, setCopiedWeek, setIsCopy,} from "../../store/staff";
import SaveIcon from "@mui/icons-material/Save";
import {useCurrentStaff} from "../../utils/hooks/currentStaff";
import {useStaffCurrentWeek} from "../../utils/hooks/currentWeek";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import CenteredLoadingSpinner from "../common/CenteredLoadingSpinner";
import {useGetPlannerWeeklyTimetableQuery} from "store/api/plannerTimetables";
import {
    useGetStaffWeeklyTimetableQuery,
    useUpdateStaffWeeklyTimetableMutation,
    useUpdateStaffWeeklyTimetableToDefaultMutation,
} from "store/api/staffTimetables";
import {
    addDays,
    aroundMinutesDate,
    dateFromStringYYYYMMDD,
    dateToStringDDMMYYYYwithsepator,
    dateToStringYYYYMMDD,
    fromDateToTime,
    fromTimeToDate,
} from "utils/dateHelper";
import {useGetStaffWeeksQuery} from "store/api/staff";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {TimePicker} from "@progress/kendo-react-dateinputs";


const StaffTimetable: FC<{
    dayClicked?: Date;
    dayNameClicked? : string;
    timetable?: IWeeklyTimetableDay[] ;
    closeDialog?: (e:any)=> void;
}> = ({dayClicked, dayNameClicked,  timetable , closeDialog}) => {

    const {
        timetable: formTimetable,
        setInitialState,
        // setInitialFromOldState,
        // setInitialFromOfficeState,
        copyForm,
        updateBeginTime,
        updateEndTime,
        addTimeRange,
        removeTimeRange,
        createHistory,
        openDay,
    } = useTimetable();

    const currentStaff = useCurrentStaff();
    const currentWeek = useStaffCurrentWeek();
    const currentPlanner = useCurrentPlanner();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const { copiedWeek, isCopy } = useAppSelector((s) => s.staff);

    const [hasModified, setHasModified] = useState(false);
    const [isCurrentWeeklyTimetableLoaded, setCurrentWeeklyTimetableLoaded] =
        useState(false);

    const dispatch = useAppDispatch();
    const {
        isFetching: isFetchingStaffWeeks,
        refetch: refetchStaffWeeks,
    } = useGetStaffWeeksQuery(currentStaff?.id ?? skipToken);
    const {
        data: currentPlannerWeeklyTimetable = [],
        isSuccess: isSuccessCurrentPlannerWeeklyTimetable,
        isFetching: isFetchingCurrentPlannerWeeklyTimetable,
    } = useGetPlannerWeeklyTimetableQuery(
        {
            plannerId: currentPlanner?.id as number,
            firstWeekDay: currentWeek?.firstDayWeek as string,
        },
        { skip: !currentPlanner || !currentWeek }
    );
    const {
        data: currentStaffWeeklyTimetable = [],
        isFetching: isFetchingCurrentStaffWeeklyTimetable,
    } = useGetStaffWeeklyTimetableQuery(
        {
            staffId: currentStaff?.id as number ?? skipToken,
            firstWeekDay: currentWeek?.firstDayWeek as string ?? skipToken,
        } ?? skipToken
        // ,
        // { skip: !(currentStaff?.id) || !(currentWeek?.firstDayWeek) }
    );

    const [
        updateStaffWeeklyTimetable,
        {
            isLoading: isUpdatingStaffWeeklyTimetable,
        },
    ] = useUpdateStaffWeeklyTimetableMutation();

    const [
        updateStaffWeeklyTimetableToDefault,
        { isLoading: isUpdatingStaffWeeklyTimetableToDefault },
    ] = useUpdateStaffWeeklyTimetableToDefaultMutation();

    const createTimeLabel = (tabel: IWeeklyTimetableDay[], date = "") => {
        let label = "";
        if (date) label += date + "\n";
        tabel.forEach((t) => {
            label += t.day_name;
            if (t.closed || t.ranges.length === 0) label += " chiuso";
            else {
                t.ranges.forEach((r) => {
                    label +=
                        "\n" +
                        (typeof r.begin_time === "object"
                            ? fromDateToTime(new Date(r.begin_time.toISOString()))
                            : r.begin_time) +
                        " - " +
                        (typeof r.end_time === "object"
                            ? fromDateToTime(new Date(r.end_time.toISOString()))
                            : r.end_time);
                });
            }
            label += "\n\n";
        });
        //console.log(label);
        return label;
    };

    const [usePlanner, setUsePlanner] = useState(false);
    const [isInitialUsePlanner, setInitialUsePlanner] = useState(false);
    const [initialTimetable, setInitialTimetable] = useState(
        [] as IWeeklyTimetableDay[]
    );
    const [officeTimeLabel, setOfficeTimeLabel] = useState("");
    const [copiedTimeLabel, setCopiedTimeLabel] = useState(
        copiedWeek
            ? createTimeLabel(copiedWeek, currentWeek?.firstDayWeek as string)
            : ""
    );

    useEffect(() => {
        if (currentWeek) {
            // console.log("currentWeek");
            // console.log(currentWeek);
            setUsePlanner(currentWeek.usePlanner === 1);
            setInitialUsePlanner(currentWeek.usePlanner === 1);
            setHasModified(false);
            // console.log(currentWeek.usePlanner === 1)
        }
    }, [currentWeek]);

    useEffect(() => {

        if (
            !isFetchingCurrentStaffWeeklyTimetable
        ) {
            let tt = initialTimetable === formTimetable && formTimetable.length > 0 && formTimetable.length === currentStaffWeeklyTimetable.length ? formTimetable : currentStaffWeeklyTimetable;


            setInitialState(
                tt?.map((d, dIndex) => ({
                    date: d.date,
                    date_string: d.date_string,
                    day_name: d.day_name,
                    ranges: d.ranges?.map((r) => ({
                        begin_time: r.begin_time,
                        end_time: r.end_time,
                    })),
                }))
            );
            setInitialTimetable(
                tt?.map((d, dIndex) => ({
                    date: d.date,
                    date_string: d.date_string,
                    day_name: d.day_name,
                    ranges: d.ranges?.map((r) => ({
                        begin_time: r.begin_time,
                        end_time: r.end_time,
                    })),
                }))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingCurrentStaffWeeklyTimetable, currentWeek]); //currentWeek per gestire il cambio settimana, ma parte anche quando in una settimana cambio da default o viceversa

    useEffect(() => {
        if (isSuccessCurrentPlannerWeeklyTimetable) {
            //setto la timetable del planner
            //console.log("setto la timetable del planner");
            setCurrentWeeklyTimetableLoaded(true);
            setOfficeTimeLabel(createTimeLabel(currentPlannerWeeklyTimetable));
            // if (usePlanner) setPlannerWeeklyTimetable();
            if (currentWeek?.isEmpty === 1) {
                let tt = currentPlannerWeeklyTimetable?.map((d, dIndex) => ({
                    date: d.date,
                    date_string: d.date_string,
                    day_name: d.day_name,
                    ranges: [],
                    closed: true,
                }));
                setInitialTimetable(tt);
                setInitialState(tt);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccessCurrentPlannerWeeklyTimetable, currentWeek]);

    const setPlannerWeeklyTimetable = () => {
        setInitialState(
            currentPlannerWeeklyTimetable?.map((d, dIndex) => ({
                date: d.date,
                date_string: d.date_string,
                day_name: d.day_name,
                ranges: d.ranges?.map((r) => ({
                    begin_time: r.begin_time,
                    end_time: r.end_time,
                })),
            }))
        );
    };

    useEffect(() => {
        if (isUpdatingStaffWeeklyTimetable) {
            //faccio update a planner
            //console.log("faccio update a staff personalizzato");
            refetchStaffWeeks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdatingStaffWeeklyTimetable]);

    useEffect(() => {
        if (isUpdatingStaffWeeklyTimetableToDefault) {
            //faccio update a planner
            //console.log("faccio update a planner");
            refetchStaffWeeks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdatingStaffWeeklyTimetableToDefault]);

    const handleCopy = () => {
        if (currentWeek) {
            dispatch(
                setCopiedWeek(
                    formTimetable?.map((d) => ({
                        ...d,
                        ranges: d.ranges
                            .filter((t) => t.begin_time && t.end_time)
                            ?.map((r) => {
                                return {
                                    //...r,
                                    id: undefined,
                                    begin_time: r.begin_time
                                        ? fromDateToTime(r.begin_time as Date)
                                        : undefined,
                                    end_time: r.end_time
                                        ? fromDateToTime(r.end_time as Date)
                                        : undefined,
                                };
                            }),
                        closed:
                            d.ranges.filter((t) => t.begin_time && t.end_time).length === 0,
                    }))
                )
            );
            dispatch(setCopiedFirstWeekDay(currentWeek.firstDayWeek));
            dispatch(setIsCopy(true));
            setCopiedTimeLabel(
                createTimeLabel(
                    currentPlannerWeeklyTimetable,
                    currentWeek.firstDayWeek as string
                )
            );
        }
    };

    const handlePaste = () => {
        copyForm(
            copiedWeek?.map((d, index) => ({
                ...d,
                date: dateToStringYYYYMMDD(
                    addDays(
                        dateFromStringYYYYMMDD(currentWeek?.firstDayWeek as string),
                        index
                    )
                ),
                date_string: dateToStringDDMMYYYYwithsepator(
                    addDays(
                        dateFromStringYYYYMMDD(currentWeek?.firstDayWeek as string),
                        index
                    )
                ),
                ranges: d.ranges
                    .filter((t) => t.begin_time && t.end_time)
                    ?.map((r) => {
                        return {
                            //...r,
                            id: undefined,
                            begin_time: r.begin_time
                                ? fromTimeToDate(r.begin_time as string)
                                : undefined,
                            end_time: r.end_time
                                ? fromTimeToDate(r.end_time as string)
                                : undefined,
                        };
                    }),
                closed: d.ranges.filter((t) => t.begin_time && t.end_time).length === 0,
            })));

        setHasModified(true);
        setUsePlanner(false);
    };

    const handleReset = () => {
        setInitialState(initialTimetable);
        setUsePlanner(isInitialUsePlanner);
        setHasModified(false);
    };

    const handleSave = () => {
        if (!currentWeek) {
            return;
        }


        if (!usePlanner) {
            let tt = createHistory(true);
            updateStaffWeeklyTimetable({
                staffId: currentStaff?.id as number,
                firstWeekDay: currentWeek.firstDayWeek as string,
                viewModel: tt, //|| isUsePlannerInitial
            });
        } else {
            updateStaffWeeklyTimetableToDefault({
                staffId: currentStaff?.id as number,
                firstWeekDay: currentWeek.firstDayWeek as string,
            });
        }

        timetable = formTimetable;
        setInitialTimetable(timetable);
        //setInitialState(timetable);
        setInitialUsePlanner(usePlanner);

        if(dayClicked && closeDialog) {
            closeDialog(false);
        }
    };

    const changeUsePlannerState = (checked: boolean) => {
        setHasModified(true);
        setUsePlanner(checked);
        //setIsUpdating(true);

        if (checked && currentPlanner && isCurrentWeeklyTimetableLoaded)
            setPlannerWeeklyTimetable();
    };

    return isFetchingStaffWeeks || isFetchingCurrentPlannerWeeklyTimetable ||
    isFetchingCurrentStaffWeeklyTimetable ? (
        <CenteredLoadingSpinner />
    ) : (
        <React.Fragment>
            <Grid container alignItems="stretch" spacing={0} className={"timetable-container"}>


                <Grid
                    item
                    style={{ textAlign: "center" }}
                    xs={12}
                    className="item-media-rule "
                >
                    {/*TODO: correggere a capo*/}
                    <span data-title={officeTimeLabel} style={{ alignSelf: "inherit" }}>
                        <Switch
                            className=" custom-unif-arancione"
                            checked={usePlanner}
                            onChange={(e) => {
                                changeUsePlannerState(e.target.checked);
                            }}
                        />

                        <Typography
                            variant="body1"
                            component="span"
                            style={{ marginLeft: "5px" }}
                        >
                           Applica <span className={"custom-unif-viola"}>orario apertura</span> al pubblico
                        </Typography>
                    </span>
                </Grid>
            </Grid>


            <Grid xs={12} style={{ textAlign: "center" }} item>
                <Grid
                    container
                    alignItems="stretch"
                    spacing={0}
                    className={"labels-planners "}
                    style={{ paddingLeft: "40px", marginTop: "2vh" }}
                >
                    {formTimetable &&
                        formTimetable.map((day, dayIndex) => (
                            (dayClicked !== undefined)?
                                (dateToStringYYYYMMDD(dayClicked) === day.date && dayNameClicked === day.day_name )?  (
                                    <Grid item xs={12} sm={12} md={12} lg={12} key={dayIndex}>
                                        <Box
                                            // key={day.date}

                                            display="flex"
                                            flexDirection={matches ? "row" : "column"}

                                        >

                                            <Grid container item xs={12} className={"timetable-row-container "} alignItems="stretch" spacing={2}>


                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    {day.closed ? (
                                                        <Grid
                                                            container
                                                            item
                                                            className={" custom-section-forms-timetable align-center-inside-container"}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={5}
                                                                sm={5}
                                                                md={3}
                                                                lg={4}
                                                                className="allinea-orari-chiusi"
                                                                style={{ marginRight: "10px" }}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    className={"custom-input-timetable"}
                                                                    inputProps={{ readOnly: true }}
                                                                    variant="outlined"
                                                                    value={"Non disponibile"}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                className={"bottoni-timetable"}
                                                                item
                                                                xs={3}
                                                                sm={3}
                                                                md={3}
                                                                lg={2}
                                                                style={{ paddingTop: "12px", textAlign: "left" }}
                                                            >
                                                                <IconButton
                                                                    color="inherit"
                                                                    onClick={() => {
                                                                        openDay(day.date);
                                                                        setHasModified(true);
                                                                    }}
                                                                    style={{ marginLeft: "24px" }}
                                                                    className={`tabindex-minus-1 bottoni-tondi std-bottoni-tondi ${usePlanner ? "" : "custom-unif-viola-back"
                                                                    }`}
                                                                    disabled={usePlanner}
                                                                    size="large">
                                                                    <AddIcon fontSize="small" />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    ) : (
                                                        day.ranges?.map((range, rangeIndex) => (
                                                            <Grid
                                                                key= {rangeIndex}
                                                                container
                                                                item
                                                                className={
                                                                    "a-bit-of-margin-top custom-section-forms-timetable"
                                                                }
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={3}
                                                                    lg={2}
                                                                    className="std-time-picker-container inputTimePicker-custom da-a-orari custom-unif-dropdown-focus"
                                                                >
                                                                    <label
                                                                        style={{ paddingLeft: "10px" }}
                                                                        className="std-time-picker-label labelModifica"
                                                                        data-shrink="true"
                                                                    >
                                                                        dalle
                                                                    </label>
                                                                    <div className="std-time-picker-component">
                                                                        <TimePicker
                                                                            disabled={usePlanner}
                                                                            steps={{ minute: 15 }}
                                                                            // ampm={false}
                                                                            // inputVariant="outlined"
                                                                            value={range.begin_time as Date}
                                                                            onBlur={(event: any) => {
                                                                                if (event.target.value) {
                                                                                    updateBeginTime(
                                                                                        day.date,
                                                                                        rangeIndex,
                                                                                        aroundMinutesDate(
                                                                                            fromTimeToDate(event.target.value)
                                                                                        )
                                                                                    );
                                                                                    setHasModified(true);
                                                                                }
                                                                            }}
                                                                            onChange={(event) => {
                                                                                if (event.value) {
                                                                                    updateBeginTime(
                                                                                        day.date,
                                                                                        rangeIndex,
                                                                                        event.value as Date
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>

                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={3}
                                                                    lg={2}
                                                                    className="std-time-picker-container  inputTimePicker-custom da-a-orari custom-unif-dropdown-focus"
                                                                >
                                                                    <label
                                                                        style={{ paddingLeft: "10px" }}
                                                                        className="std-time-picker-label  labelModifica"
                                                                        data-shrink="true"
                                                                    >
                                                                        alle
                                                                    </label>
                                                                    <div className="std-time-picker-component">

                                                                        <TimePicker
                                                                            disabled={usePlanner}
                                                                            steps={{ minute: 15 }}
                                                                            // ampm={false}
                                                                            // inputVariant="outlined"
                                                                            value={range.end_time as Date}
                                                                            onBlur={(event: any) => {
                                                                                if (event.target.value) {
                                                                                    updateEndTime(
                                                                                        day.date,
                                                                                        rangeIndex,
                                                                                        aroundMinutesDate(
                                                                                            fromTimeToDate(event.target.value)
                                                                                        )
                                                                                    );
                                                                                    setHasModified(true);
                                                                                }
                                                                            }}
                                                                            onChange={(event) => {
                                                                                if (event.value) {
                                                                                    updateEndTime(
                                                                                        day.date,
                                                                                        rangeIndex,
                                                                                        event.value as Date
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    className={"bottoni-timetable"}
                                                                    item
                                                                    xs={8}
                                                                    sm={3}
                                                                    md={3}
                                                                    lg={3}
                                                                    style={{ paddingTop: "12px", textAlign: "left" }}
                                                                >
                                                                    <IconButton
                                                                        color="inherit"
                                                                        className={`tabindex-minus-1 bottoni-tondi std-bottoni-tondi ${usePlanner ? "" : "custom-unif-viola-back"
                                                                        }`}
                                                                        style={{
                                                                            marginRight: "16px",
                                                                            marginLeft: "24px",
                                                                        }}
                                                                        onClick={() => {
                                                                            setHasModified(true);
                                                                            addTimeRange(day.date, rangeIndex);
                                                                        }}
                                                                        disabled={usePlanner}
                                                                        size="large">
                                                                        <AddIcon fontSize="small" />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        className={`tabindex-minus-1 bottoni-tondi std-bottoni-tondi ${usePlanner ? "" : "custom-unif-arancione-back"
                                                                        }`}
                                                                        // color="inherit"
                                                                        onClick={() => {
                                                                            removeTimeRange(day.date, rangeIndex);
                                                                            setHasModified(true);
                                                                        }}
                                                                        disabled={
                                                                            /*day.ranges.length === 1 || */ usePlanner
                                                                        }
                                                                        size="large">
                                                                        <CloseIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item xs={12}></Grid>
                                                            </Grid>
                                                        ))
                                                    )}
                                                    <Grid container item xs={12} sm={12} md={12} lg={12}></Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>) : (<></>)
                                : (
                                    <Grid item xs={12} sm={12} md={12} lg={12} key={dayIndex}>
                                        <Box
                                            // key={day.date}

                                            display="flex"
                                            flexDirection={matches ? "row" : "column"}

                                        >
                                            <Grid container item xs={12} className={"timetable-row-container "} alignItems="stretch" spacing={2}>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={1}
                                                    md={2}
                                                    lg={4}
                                                    className={"day-name-timetable"}
                                                >
                                                    <Typography
                                                        className={` title-giorno-timetable ${(day.closed)
                                                            ? "title-giorno-timetable-closed"
                                                            : ""
                                                        }`}
                                                        variant="h6"
                                                        // align={matches ? "right" : "left"}
                                                    >
                                                        <b>{day.day_name}</b>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={10} md={10} lg={8}>
                                                    {day.closed ? (
                                                        <Grid
                                                            container
                                                            item
                                                            className={" custom-section-forms-timetable align-center-inside-container"}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={5}
                                                                sm={5}
                                                                md={3}
                                                                lg={4}
                                                                className="allinea-orari-chiusi"
                                                                style={{ marginRight: "10px" }}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    className={"custom-input-timetable"}
                                                                    inputProps={{ readOnly: true }}
                                                                    variant="outlined"
                                                                    value={"Non disponibile"}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                className={"bottoni-timetable"}
                                                                item
                                                                xs={3}
                                                                sm={3}
                                                                md={3}
                                                                lg={2}
                                                                style={{ paddingTop: "12px", textAlign: "left" }}
                                                            >
                                                                <IconButton
                                                                    color="inherit"

                                                                    onClick={() => {
                                                                        openDay(day.date);
                                                                        setHasModified(true);
                                                                    }}
                                                                    style={{ marginLeft: "24px" }}
                                                                    className={`tabindex-minus-1 bottoni-tondi bottoni-tondi-responsive std-bottoni-tondi ${usePlanner ? "" : "custom-unif-viola-back"
                                                                    }`}
                                                                    disabled={usePlanner}
                                                                    size="medium">
                                                                    <AddIcon fontSize="small" />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    ) : (
                                                        day.ranges?.map((range, rangeIndex) => (
                                                            <Grid
                                                                key= {rangeIndex}
                                                                container
                                                                item
                                                                className={
                                                                    "a-bit-of-margin-top custom-section-forms-timetable"
                                                                }
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={3}
                                                                    lg={2}
                                                                    className="std-time-picker-container inputTimePicker-custom da-a-orari custom-unif-dropdown-focus"
                                                                >
                                                                    <label
                                                                        style={{ paddingLeft: "10px" }}
                                                                        className="labelModifica std-time-picker-label"
                                                                        data-shrink="true"
                                                                    >
                                                                        dalle
                                                                    </label>
                                                                    <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedStart ">
                                                                        <TimePicker
                                                                            disabled={usePlanner}
                                                                            steps={{ minute: 15 }}
                                                                            // ampm={false}
                                                                            // inputVariant="outlined"
                                                                            value={range.begin_time as Date}
                                                                            onBlur={(event: any) => {
                                                                                if (event.target.value) {
                                                                                    updateBeginTime(
                                                                                        day.date,
                                                                                        rangeIndex,
                                                                                        aroundMinutesDate(
                                                                                            fromTimeToDate(event.target.value)
                                                                                        )
                                                                                    );
                                                                                    setHasModified(true);
                                                                                }
                                                                            }}
                                                                            onChange={(event) => {
                                                                                if (event.value) {
                                                                                    updateBeginTime(
                                                                                        day.date,
                                                                                        rangeIndex,
                                                                                        event.value as Date
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={3}
                                                                    lg={2}
                                                                    className="std-time-picker-container inputTimePicker-custom da-a-orari custom-unif-dropdown-focus"
                                                                >
                                                                    <label
                                                                        style={{ paddingLeft: "10px" }}
                                                                        className="labelModifica std-time-picker-label"
                                                                        data-shrink="true"
                                                                    >
                                                                        alle
                                                                    </label>
                                                                    <div className="std-time-picker-component ">
                                                                        <TimePicker
                                                                            disabled={usePlanner}
                                                                            steps={{ minute: 15 }}
                                                                            // ampm={false}
                                                                            // inputVariant="outlined"
                                                                            value={range.end_time as Date}
                                                                            onBlur={(event: any) => {
                                                                                if (event.target.value) {
                                                                                    updateEndTime(
                                                                                        day.date,
                                                                                        rangeIndex,
                                                                                        aroundMinutesDate(
                                                                                            fromTimeToDate(event.target.value)
                                                                                        )
                                                                                    );
                                                                                    setHasModified(true);
                                                                                }
                                                                            }}
                                                                            onChange={(event) => {
                                                                                if (event.value) {
                                                                                    updateEndTime(
                                                                                        day.date,
                                                                                        rangeIndex,
                                                                                        event.value as Date
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    className={"bottoni-timetable"}
                                                                    item
                                                                    xs={8}
                                                                    sm={3}
                                                                    md={3}
                                                                    lg={3}
                                                                    style={{ paddingTop: "12px", textAlign: "left" }}
                                                                >
                                                                    <IconButton
                                                                        color="inherit"
                                                                        className={`tabindex-minus-1 bottoni-tondi std-bottoni-tondi ${usePlanner ? "" : "custom-unif-viola-back"
                                                                        }`}
                                                                        style={{
                                                                            marginRight: "16px",
                                                                            marginLeft: "24px",
                                                                        }}
                                                                        onClick={() => {
                                                                            setHasModified(true);
                                                                            addTimeRange(day.date, rangeIndex);
                                                                        }}
                                                                        disabled={usePlanner}
                                                                        size="large">
                                                                        <AddIcon fontSize="small" />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        className={`tabindex-minus-1 bottoni-tondi std-bottoni-tondi ${usePlanner ? "" : "custom-unif-arancione-back"
                                                                        }`}
                                                                        // color="inherit"
                                                                        onClick={() => {
                                                                            removeTimeRange(day.date, rangeIndex);
                                                                            setHasModified(true);
                                                                        }}
                                                                        disabled={
                                                                            /*day.ranges.length === 1 || */ usePlanner
                                                                        }
                                                                        size="large">
                                                                        <CloseIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item xs={12}></Grid>
                                                            </Grid>
                                                        ))
                                                    )}
                                                    <Grid container item xs={12} sm={12} md={12} lg={12}></Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                )
                        ))}
                </Grid>
                <Grid container alignItems="center" spacing={3} >
                    <Grid item xs={12} sm={12} md={12} lg={10} className={"align-center-inside-container top-spacing"}>
                        {(dayClicked === undefined) ? (

                            <Grid className={"bottoni-timetable-container"} item xs={12} sm={6} md={3} >
                                <Button
                                    disabled={
                                        isUpdatingStaffWeeklyTimetable ||
                                        isUpdatingStaffWeeklyTimetableToDefault
                                    }
                                    variant="contained"
                                    className="custom-button-uniformed custom-unif-viola-back"
                                    startIcon={<FileCopyIcon />}
                                    onClick={handleCopy}
                                >
                                    Copia
                                </Button>
                            </Grid>
                        ) : (<></>)}
                        {(dayClicked === undefined) ? (
                            <Grid className={"bottoni-timetable-container"} item xs={12} sm={6} md={3} >
                                <Button
                                    className={`custom-button-uniformed   left-spacing-10p  ${!isUpdatingStaffWeeklyTimetable &&
                                    !isUpdatingStaffWeeklyTimetableToDefault &&
                                    isCopy
                                        ? "custom-unif-viola-back "
                                        : ""
                                    }`}
                                    disabled={
                                        isUpdatingStaffWeeklyTimetable ||
                                        isUpdatingStaffWeeklyTimetableToDefault ||
                                        !isCopy
                                    }
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<AssignmentIcon />}
                                    onClick={handlePaste}
                                    data-title={copiedTimeLabel}
                                >
                                    Incolla
                                </Button>
                            </Grid>
                        ) : (<></>)}
                        <Grid className={"bottoni-timetable-container"} item xs={12} sm={6} md={3} >
                            <Button
                                disabled={
                                    isUpdatingStaffWeeklyTimetable ||
                                    isUpdatingStaffWeeklyTimetableToDefault ||
                                    !hasModified
                                }
                                variant="contained"
                                className={`custom-button-uniformed   left-spacing-40p  ${!isUpdatingStaffWeeklyTimetable &&
                                !isUpdatingStaffWeeklyTimetableToDefault &&
                                hasModified
                                    ? "custom-unif-viola-back"
                                    : ""
                                }`}
                                startIcon={<SaveIcon />}
                                onClick={handleSave}
                            >
                                Salva
                            </Button>
                        </Grid>
                        <Grid className={"bottoni-timetable-container"} item xs={12} sm={6} md={3} >
                            <Button
                                className={`custom-button-uniformed   left-spacing-10p  ${!(isUpdatingStaffWeeklyTimetable ||
                                    isUpdatingStaffWeeklyTimetableToDefault ||
                                    !hasModified)
                                    ? "custom-unif-arancione-back "
                                    : ""
                                }`}
                                disabled={
                                    isUpdatingStaffWeeklyTimetable ||
                                    isUpdatingStaffWeeklyTimetableToDefault ||
                                    !hasModified
                                }
                                variant="contained"
                                startIcon={<CancelPresentationIcon />}
                                onClick={handleReset}
                            >
                                Annulla
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default StaffTimetable;
