import React, {useEffect, useState} from "react";
import {Dialog} from "@progress/kendo-react-dialogs";
import {Chat, ChatMessageSendEvent, Message,} from "@progress/kendo-react-conversational-ui";
import {useMe, useMeInfo} from "../../utils/hooks/me";
import Grid from "@mui/material/Grid";
import ContactList from "../../components/lists/ContactList";
import {useWindowSize} from "../../components/common/ScreenSizes";
import {Typography} from "@mui/material";
import {apiPath} from "../../store/api/api";
import CenteredLoadingSpinner from "../../components/common/CenteredLoadingSpinner";
import {useReadMessageMutation, useSendMessageMutation} from "../../store/api/notifications";
import {IChat} from "../../models/entities/IMessage";
import female from "../../img/Icons/female-avatar-profile-icon.png";
import male from "../../img/Icons/male-avatar-profile-icon.jpg";
import genderN from "../../img/Icons/gender-neutral-icon.jpg";
import {useCurrentMessage} from "../../utils/hooks/currentMessageState";
import {setMessageState} from "../../store/messageChat";
import {useAppDispatch} from "../../store";


const MAX_CHAR = 70;
const MAX_CHAR_MEDIUM = 70;
const MAX_CHAR_SMALL = 38;

interface Contacts {
    chat_id: string;
    contact_icon: string;
    contact_info: string;
    date_time: string;
    selected?: boolean;
    messages_number?: string;
    sex?: string;
    is_disabled?: boolean;

}

const FEMALE_ICON =female;
const MALE_ICON = male;
const GENDER_NEUTRAL_ICON = genderN;

const MessagesPage = () => {
    const userInfo = useMeInfo();
    const loggedUser = useMe();

    const size = useWindowSize(); //960 width.

    const token = localStorage.getItem("token");
    const get_path = apiPath + "notification/getMessagesByID/";

    const messageState = useCurrentMessage();


    const [
        sendMessage,
    ] = useSendMessageMutation();

    const [
        readMessage,

    ] = useReadMessageMutation();
    const header_get =  {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }

    const user = {
        id: userInfo?.user?.id,

    };
    const loggedUser_id = {
        id:  loggedUser?.userRealId,
        name: 'Tu',

    };

    const dispatch = useAppDispatch();
    const [selectedChat, setSelectedChat] = useState<Contacts>();
    const [selectedChatRow, setSelectedChatRow] = useState<string>("");
    const [receiverId, setReceiverId] = useState<string>("");
    const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(false);

    const [openForm, setOpenForm] = useState(false);


    const initialMessages: Message[] = [];

    const [messages, setMessages] = React.useState(initialMessages);


    //DA RICHIAMARE QUANDO CAMBIO CHAT
    useEffect(() => {

        if(selectedChatRow) {
            setMessages([]);
            dispatch(setMessageState(null));
            setIsLoadingMessages(true);
            let optObjHeadBody = {
                method: 'GET',
                headers: header_get,
            };
            fetch(get_path + selectedChatRow+"?read=true", optObjHeadBody)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.bResult) {
                            result.aMessages.forEach((item: Message) => {


                                    switch (selectedChat?.sex){
                                        case 'M': {
                                            item.author.avatarUrl = "Male";
                                            break;
                                        }
                                        case 'F': {
                                            item.author.avatarUrl = "Female";
                                            break;
                                        }
                                       default: {
                                            item.author.avatarUrl = "Neutral";
                                            break;
                                        }

                                    }
                                    if(item.author.name?.includes('subscriber_')) {
                                        // item.author.name = item.author.name?.replace('subscriber_','');
                                        item.author.name = 'Subscriber';

                                        if (item.author.avatarUrl === "Female") {
                                            item.author.avatarUrl = FEMALE_ICON;
                                        } else {
                                            if (item.author.avatarUrl === "Male") {
                                                item.author.avatarUrl = MALE_ICON;
                                            } else {
                                                item.author.avatarUrl = GENDER_NEUTRAL_ICON;
                                            }
                                        }
                                    }else{
                                        if (parseInt(item.author.id) === loggedUser_id.id){
                                            //dobbiamo mettere il messaggio a destra
                                            item.author  = loggedUser_id;

                                        }else{
                                            //Dobbiamo metterlo a sinistra.
                                            item.author = {
                                                id: item.author.id,
                                                name: item.author.name,
                                                avatarUrl: GENDER_NEUTRAL_ICON

                                            }
                                        }

                                    }


                                if (item.timestamp !== undefined) {

                                    item.timestamp = new Date(item.timestamp.toString());
                                }

                            })

                            if (result.aMessages.length !== 0) {
                                // @ts-ignore
                                setMessages(result.aMessages.sort((a: Message, b: Message) => (a.timestamp <= b.timestamp ? -1 : 1)) as Message[]);
                            }
                        } else {

                        }

                        setIsLoadingMessages(false);
                    },
                    // Nota: è importante gestire gli errori qui
                    // invece di un blocco catch() in modo da non fare passare
                    // eccezioni da bug reali nei componenti.
                    (error) => {
                        // setIsLoaded(true);
                        // setError(error);
                        setIsLoadingMessages(false);
                    }
                )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChatRow])

    const addNewMessage = (event: ChatMessageSendEvent) => {

        setMessages([...messages, event.message]);

        sendMessage({
            chatId:         selectedChatRow,
            message: {

                authorID:     (  loggedUser?.userRealId !== undefined) ? loggedUser.userRealId.toString() : "",
                receiverID:   (  receiverId !== undefined) ? receiverId : "",
                text:         (  event.message.text !== undefined) ? event.message.text : "",
                timestamp:    (  event.message.timestamp !== undefined) ? event.message.timestamp.toString() : "",
            }
        });

    };

    const customMessage = (props : any) => {
        return (
            <React.Fragment>
        <textarea
            disabled={selectedChat?.is_disabled ?? false}
            style={{ resize: "none" }}
            placeholder={selectedChat?.is_disabled ? 'Non è più possibile scrivere in questa chat' : 'Scrivi un messaggio...'}
            ref={props.messageInput.ref}
            className={props.messageInput.props.className + " text-area-message-chat"}
        />
                {props.sendButton}
            </React.Fragment>
        );
    };


    const [titleChat, setTitleChat] = useState("Seleziona un destinatario");

    const handleSelectChat = (e: any) => {
        if (size) {
            if (size <= 1445) {
                setOpenForm(true);
            }
        }
        setSelectedChat(e);
        setSelectedChatRow(e.chat_id);
        setReceiverId(e.receiver_id);
        let titolo;
        if (size){
            titolo = e.contact_info.toString() +
                ((e.language !== undefined && e.language !== ""&& e.language !== "-" && e.language !== null ) ? " - " + e.language : "")
                +
                ((e.eta !== undefined && e.eta !== ""&& e.eta !== null && e.eta !=="0"&& e.eta !==0) ? " - " + ((size < 670) ? " \n " : "") + e.eta + " anni" : "")
                +
                ((e.num_pax !== undefined && e.num_pax !== ""&& e.num_pax !== null) ? " - Pax " + e.num_pax : "");
        }else{
            titolo = e.contact_info.toString() +
                ((e.language !== undefined && e.language !== ""&& e.language !== "-" && e.language !== null ) ? " - " + e.language : "")
                +
                ((e.eta !== undefined && e.eta !== ""&& e.eta !== null && e.eta !=="0"&& e.eta !==0) ? " - " +  e.eta + " anni" : "")
                +
                ((e.num_pax !== undefined && e.num_pax !== ""&& e.num_pax !== null) ? " - Pax " + e.num_pax : "");
        }


        setTitleChat(titolo.toString() )
        let chat : IChat = {
            authorID:     (  userInfo?.user?.id !== undefined) ? userInfo?.user?.id.toString() : "",
            receiverID:  e.chat_id,
        }
        readMessage(chat);
    }

    useEffect(()=>{
        if(messageState) {
            setSelectedChatRow(messageState)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        if(size) {
            if (size <= 1445) {
                setOpenForm(true);
            }
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChat]);

    const CustomTitle  =
        <Typography  variant="h6" title={(selectedChat) ? titleChat : ""}  style={{paddingLeft: "15px", margin: "auto", marginLeft: "0px"}}>



            {
                (size!== undefined)?
                    (size> 960 )?
                        (size < 1895) ?
                            (titleChat.length >= MAX_CHAR_MEDIUM ) ? titleChat.substring(0,MAX_CHAR_MEDIUM) +"..." : titleChat
                            :
                            (titleChat.length >= MAX_CHAR ) ?   titleChat.substring(0,MAX_CHAR) +"..." : titleChat
                        :
                        (titleChat.length >= MAX_CHAR_SMALL ) ? titleChat.substring(0,MAX_CHAR_SMALL) +"..." : titleChat
                    :
                    titleChat

            }
        </Typography>


    const CustomTitleMob  =
        (size!== undefined)?
            (size < 670) ?
                <>
                    <Typography  variant="h6" title={(selectedChat) ? titleChat : ""}  style={{paddingLeft: "15px", margin: "auto", marginLeft: "0px"}}>


                        {
                            (size!== undefined)?
                                (size>760 )?
                                    // (size < 760) ?
                                    //     (titleChat.length >= MAX_CHAR_MEDIUM ) ? titleChat.substring(0,MAX_CHAR_MEDIUM) +"..." : titleChat
                                    //     :
                                    (titleChat.length >= MAX_CHAR ) ?   titleChat.substring(0,MAX_CHAR) +"..." : titleChat

                                    :
                                    (titleChat.length >= MAX_CHAR_MEDIUM ) ? titleChat.substring(0,MAX_CHAR_MEDIUM) +"..." : titleChat
                                :
                                titleChat

                        }
                    </Typography>
                </>
                :
                <Typography  variant="h6" title={(selectedChat) ? titleChat : ""}  style={{paddingLeft: "15px", margin: "auto", marginLeft: "0px"}}>


                    {
                        (size!== undefined)?
                            (size>760 )?
                                // (size < 760) ?
                                //     (titleChat.length >= MAX_CHAR_MEDIUM ) ? titleChat.substring(0,MAX_CHAR_MEDIUM) +"..." : titleChat
                                //     :
                                (titleChat.length >= MAX_CHAR ) ?   titleChat.substring(0,MAX_CHAR) +"..." : titleChat

                                :
                                (titleChat.length >= MAX_CHAR_MEDIUM ) ? titleChat.substring(0,MAX_CHAR_MEDIUM) +"..." : titleChat
                            :
                            titleChat

                    }
                </Typography>
            : <></>


    return (
        <Grid  container alignItems="stretch" className={" custom-unif-font container-messages-section "}>
            <Grid item sm={12} md={12} lg={(size !== undefined && size > 1445 ) ? 7 : 12} xl={6}>
                <ContactList selectedChat={selectedChat} selectedChatRow={selectedChatRow} handleSetSelectedChat={(e : any) => handleSelectChat(e)}/>
            </Grid>
            {(size!==undefined) ?
                (size > 1445 ) ?
                    <Grid item sm={12} md={12} lg={5} xl={6}>
                        <Grid container item  spacing={1} className={"title-row"}>
                            {CustomTitle}
                        </Grid>
                        <>
                            {(isLoadingMessages)?
                                <Grid container item >
                                    <CenteredLoadingSpinner />
                                </Grid>
                                :
                                (selectedChat && selectedChat !== undefined) ? (
                                    <Chat
                                        //( loggedUser && loggedUser.role === "superadmin") ? superAdminUser :
                                        user={loggedUser_id}
                                        messages={messages}
                                        onMessageSend={addNewMessage}
                                        placeholder={"Scrivi un messaggio..."}

                                        messageBox={customMessage}
                                    />
                                ) : ""

                            }

                        </>

                    </Grid>
                    : (openForm ?
                        <Dialog
                            height={"100vh"}
                            width={(size !== undefined && size > 960 ) ? "80vw" : "100vw"}
                            className={"dialog-staff-timetable-form-media-rule chat-dialog"}
                            title={CustomTitleMob}
                            onClose={() => setOpenForm(false)}
                        >
                            <>
                                {(isLoadingMessages)?
                                    <Grid container item >
                                        <CenteredLoadingSpinner />
                                    </Grid>
                                    :
                                    (selectedChat && selectedChat !== undefined) ? (
                                        <Chat
                                            user={user}
                                            messages={messages}
                                            onMessageSend={addNewMessage}
                                            placeholder={"Scrivi un messaggio..."}

                                            messageBox={customMessage}
                                        />
                                    ) : ""

                                }

                            </>

                        </Dialog>
                        // <TabsStaffDialogForm firstDayWeek={currentFirstDayWeek} item={currentItem} onSubmit={} />
                        : "")
                // todo: aprire chat dialog
                :  <Grid item sm={12} md={12} lg={6}>
                    <Chat
                        user={user}
                        messages={messages}
                        onMessageSend={addNewMessage}
                        placeholder={"Scrivi un messaggio..."}
                        messageBox={customMessage}

                    />
                </Grid>
            }
        </Grid>
    );
};

export default MessagesPage;
