import {Fragment} from "react";
import {IReseller} from "../../models/entities/IReseller";
import {useDeleteResellerMutation, useGetResellersQuery,} from "../../store/api/resellers";
import CenteredLoadingSpinner from "../../components/common/CenteredLoadingSpinner";
import GenericPagedGrid from "../../components/common/GenericPagedGrid";
import ResellerDialogForm from "../../components/resellers/ResellerDialogForm";

const ResellersPage = () => {
    const {
        data: resellers,
        isLoading,
    } = useGetResellersQuery();
    const [deleteCompany] = useDeleteResellerMutation();

    const remove = (dataItem: IReseller) => {
        deleteCompany(dataItem.id as number);
    };

    return (
        <div style={{ padding: "2.5vh" }}
             className={"companies_grid"}>
            {/* <PageTitle icon="business" title="Aziende" /> */}
            {isLoading ? (
                <CenteredLoadingSpinner />
            ) : (
                <Fragment >
                    <GenericPagedGrid
                        icon="local_hotel" title="Hotels"
                        itemsPage={20}
                        items={resellers as IReseller[]}
                        columns={[
                            { field: "company_name", title: "Ragione sociale", className: "colonna_nome_companies" },
                            { field: "vat", title: "P. Iva" },
                            { field: "email", title: "Email" },
                            { field: "mobile", title: "Cellulare" },
                            { field: "city", title: "Comune" },
                        ]}
                        enableCancel={true}
                        onRemove={remove}
                        addFormComponent={ResellerDialogForm}
                        updateFormComponent={ResellerDialogForm}
                    />
                </Fragment>
            )}

            {/*<ResponseSnackbar responseSnackbar={[*/}
            {/*  { sliceState: addCompanyState, resetFunction: resetAddCompany() as any } as IResponseSnackbar,*/}
            {/*  { sliceState: updateCompanyState, resetFunction: resetUpdateCompany() as any } as IResponseSnackbar,*/}
            {/*  { sliceState: deleteCompanyState, resetFunction: () => resetDeleteCompany() as any } as IResponseSnackbar,*/}
            {/*]} />*/}
        </div>
    );
};

export default ResellersPage;
