import {Button, ButtonProps} from "@mui/material";
import {FC} from "react";

const BaseButton: FC<ButtonProps> = (props) => {
  return (
    <Button {...props} style={{ borderRadius: 50 }}>
      {props.children}
    </Button>
  );
};

export default BaseButton;
