import React, {useEffect, useRef, useState} from "react";
import {SchedulerFormEditorProps,} from "@progress/kendo-react-scheduler";
import Grid from "@mui/material/Grid";
import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {Error} from "@progress/kendo-react-labels";
import {DatePickerChangeEvent, TimePickerChangeEvent,} from "@progress/kendo-react-dateinputs";

import {TextField, Typography,} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

import {DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {
    TimeDurationLengthSched,
    ValidatedDropDownList,
    ValidatedDropDownListLocationId,
    ValidatedInput,
} from "../common/ValidatedFields";
import CenteredLoadingSpinner from "../../components/common/CenteredLoadingSpinner";
import {StaffDropdownlist} from "../../components/staff/StaffDropdownlist";
import {OtherStaffMultiselect} from "../staff/OtherStaffMultiselect";
import {ExperienceDropdownlist} from "../../components/experiences/ExperienceDropdownlist";
import {
    addMinutes,
    aroundMinutesDate,
    createDateTimeFromDateAndTime,
    formatDateForApi,
    fromTimeToDate,
    toDateFromApi,
} from "../../utils/dateHelper";
import {requireValidator} from "../../utils/validators/requireValidator";
import {selectExperience} from "../../store/experiences";
import {useAppDispatch, useAppSelector} from "../../store";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import {setAppointmentTitle} from "../../store/calendar";
import {useCurrentAppointment} from "utils/hooks/currentAppointment";
import {IExperience} from "models/entities/IExperience";
import {IExclusionType} from "models/entities/types/IExclusionType";

import {useGetExperiencesByStaffIdQuery} from "store/api/experiences";
import {
    useAddAppointmentMutation,
    useGetCalendarDayQuery,
    useUpdateAppointmentMutation,
} from "../../store/api/calendar";
import {IAppointment} from "models/entities/calendar/IAppointment";
import {
    addExperienceToAppointment,
    changeEndTimeAppointment,
    changeLengthAppointment,
    changeReferentForAppointment,
    changeStartTimeAppointment,
} from "utils/appointmentHelper";
import CustomTimePicker from "components/common/CustomTimePicker";
import CustomDatePicker from "components/common/CustomDatePicker";
import BaseButton from "components/common/BaseButton";
import {isEmpty} from "lodash";
import {IStaff} from "models/entities/IStaff";
import SaveIcon from "@mui/icons-material/Save";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import {minVal60Validator} from "../../utils/validators/minVal60Validator";
import {useGetStaffQuery} from "../../store/api/staff";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {ISubscriber} from "../../models/entities/calendar/ISubscriber";
import {digitValidator} from "../../utils/validators/digitValidator";

// const LazyError = (props: any) => {
//   return props.visited ? <Error {...props} /> : null;
// };

export const CustomSchedulerDetailFormEditor = (
    props: SchedulerFormEditorProps
) => {

  const dispatch = useAppDispatch();

  const currentPlanner = useCurrentPlanner();
  const {
    currentAppointment,
    isLoadingCurrentAppointment,
    isFetchingCurrentAppointment,
  } = useCurrentAppointment();

  const { colors } = useAppSelector((store) => store.types);

  const { data: calendarDay } = useGetCalendarDayQuery(
      {
        planner_id: currentPlanner?.id as number,
        date: currentAppointment?.calendar_date as string,
      },
      {
        skip:
            !currentPlanner ||
            !currentAppointment ||
            !currentAppointment?.calendar_date,
      }
  );

  const {data : currentStaffSelected} = useGetStaffQuery((currentAppointment?.selected_staff_id || currentAppointment?.staff_id ) ?? skipToken);

  const [monoLanguages, setMonolanguages] = useState<string[] | undefined>(currentStaffSelected?.languages);
  const [ languageRequired, setLanguageRequired] = useState<boolean | undefined>(currentAppointment?.experience?.monolanguage);
  const [ languageError, setLanguageError] = useState<boolean>(false);

  const [addAppointment, { isLoading: isAdding, isSuccess: isSuccessAdding }] =
      useAddAppointmentMutation();
  const [updateAppointment, { isLoading: isUpdating , isSuccess:  isSuccessUpdating }] =
      useUpdateAppointmentMutation();

  const [appointment, setAppointment] = useState<IAppointment>(
      {} as IAppointment
  );

  const [dayStaff, setDayStaff] = useState(calendarDay?.staff || []);

  const [hasModified, setModified] = useState(false);
  const [selectedDate , setSelectedDate] = useState<string>(currentAppointment?.calendar_date as string);
  const [otherStaff, setOtherStaff] = useState(
      dayStaff.filter((t) => t.id !== (currentAppointment?.staff_id as number))
  );

  const [isOneSubFromPlat, setIsOneSubFromPlat] = useState(false);

  const [isErrorExperience, setIsErrorExperience] = useState(false);


  useEffect(()=>{
    if(currentAppointment){
      if(currentAppointment.subscribers){
        let isIt = currentAppointment.subscribers.find((s : ISubscriber) => s.from_platform === true);
        if(isIt!== undefined){
          setIsOneSubFromPlat(true);
        }
      }
    }

  },[currentAppointment])

const [localLengthMin, setLocalLengthMin] = useState<number|undefined>();

  useEffect(()=>{

    setLocalLengthMin(appointment.length_minutes as number);
  }, [appointment])





  const [referentId, setReferentId] = useState<number>(0);
  const [locationValue, setLocationValue] = useState(appointment?.experience?.locations);


  let currentStaff = calendarDay?.staff.find((s : IStaff) => s.id === referentId);
  const {
    data: experiencesByStaffId,
    isLoading: isLoadingExperiencesByStaff,
  } = useGetExperiencesByStaffIdQuery(
      { plannerId: currentPlanner?.id as number, staffId: referentId,  day: props.dataItem.calendar_date },
      { skip: !currentPlanner || referentId === 0 }
  );

  useEffect(()=>{

    if(currentStaffSelected!== undefined && currentStaffSelected?.languages ) {
      let languagesSorted =[...currentStaffSelected.languages].sort((a, b) => (a!== undefined && b!== undefined) ? (a > b ? 1:-1) : -1);

      setMonolanguages(languagesSorted);
    }

    setLanguageRequired((appointment?.experience?.monolanguage!==undefined)? appointment?.experience?.monolanguage : false);

  },[currentStaffSelected,currentAppointment,appointment])

  useEffect(()=>{
    if(experiencesByStaffId && currentAppointment) {
      let selectedExp = experiencesByStaffId.find((exp) => exp.id === currentAppointment.experience_id);
      if(selectedExp) {
        setDayStaff(selectedExp.staffs);
        setOtherStaff(selectedExp.staffs.filter((t) => t.id !== (currentAppointment?.staff_id as number)))
      }
    }

  },[currentAppointment,experiencesByStaffId])

  const isNew = props.closeIcon;
  useEffect(() => {
    if (currentAppointment) {

      // console.log("currentAppointment");
      // console.log(currentAppointment);

      let startT;
      let endT;
      //TODO: riscrivere meglio, se >30, è un appuntamento già esistente, se è <30 è un appuntamento che sto creando adessoo, magari usare isNew
      // if(props.dataItem.start.toString().length >= 30){
        startT = currentAppointment.begin_time;
        endT =  currentAppointment.end_time;
      // }else{
      //   startT = props?.dataItem?.start.toString().substring(17,22);
      //   endT = props?.dataItem?.end.toString().substring(17,22);
      // }
      let currApp = currentAppointment;
      setReferentId(currentAppointment?.staff_id as number);
      setAppointment({
        ...currApp,
        begin_time:  startT as string,
        end_time: endT as string,
        start: createDateTimeFromDateAndTime(
            currApp?.calendar_date as string,
            startT as string,
        ),
        end: createDateTimeFromDateAndTime(
            currApp?.calendar_date as string,
            endT as string,
        ),
      });
    }
  }, [currentAppointment]);


  useEffect(()=>{

  },[appointment])

  const handleChangeExperience = (e: DropDownListChangeEvent) => {

    dispatch(selectExperience(e.value));
    setAppointment(addExperienceToAppointment(appointment, e.value));
    setModified(true);
  };

  const handleChangeReferent = (e: DropDownListChangeEvent) => {
    if (currentPlanner?.id) {
      // if (!currentAppointment?.experience_id) {
      setReferentId(e.value.id);
      currentStaff = e.value as IStaff;
      setAppointment(
          changeReferentForAppointment(appointment, e.value.id)
      );
      //}
      setOtherStaff(dayStaff.filter((t) => t.id !== e.value.id));
      setModified(true);
    }
  };
  // useEffect(()=>{
  //
  //   console.log(appointment);
  // },[appointment])

  useEffect(() => {

    if (experiencesByStaffId) {
      const experience = (experiencesByStaffId as IExperience[]).find(
          (item: IExperience) => item.id === currentAppointment?.experience_id
      ) as IExperience;
      appointment.experience = experience;
      if (experience && experience.locations)
        appointment.location = experience.locations.find(
            (item) => item.id === appointment.location_id
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experiencesByStaffId]);

  const handleTimeEndChange = (e: TimePickerChangeEvent) => {
    if (e.value) {
      //if (e.value.getTime() > (appointment.start as Date).getTime())
      appointment.end = e.value;
      setModified(true);
    }
  };

  const handleTimeEndBlur = (event: any) => {
    let value = event.target.value;
    if (value) {
      value = aroundMinutesDate(fromTimeToDate(value));
      if (value.getTime() > (appointment.start as Date).getTime()) {
        appointment.end = value;
        setAppointment(changeEndTimeAppointment(appointment, value));
        createTitle();
      } else {
        appointment.end = addMinutes(
            appointment.start as Date,
            appointment.length_minutes as number
        );
      }
    }
  };

  const createTitle = () => {
    let startT;
    let endT;
    // if(props.dataItem.start.toString().length >= 30){
      startT = currentAppointment?.begin_time;
      endT =  currentAppointment?.end_time;
    // }else{
    //   startT = props?.dataItem?.start.toString().substring(17,22);
    //   endT = props?.dataItem?.end.toString().substring(17,22);
    // }
    let title =
        appointment?.calendar_date +
        " " +
        startT +
        " - " +
        endT;
    setAppointmentTitle(title);
  };

  const handleDateChange = (event: DatePickerChangeEvent) => {
    let value = event.target.value;

    if (value) {
      event.value = value;
      value.setHours((appointment.start as Date).getHours());
      value.setMinutes((appointment.start as Date).getHours());
      appointment.calendar_date = formatDateForApi(value);
      setSelectedDate(formatDateForApi(value));
      setAppointment(changeStartTimeAppointment(appointment, value));
      createTitle();
      setModified(true);
    }
  };

  const handleTimeStartBlur = (event: any) => {
    let value = event.target.value;
    if (value) {
      value = aroundMinutesDate(fromTimeToDate(value));
      appointment.start = value;
      setAppointment(changeStartTimeAppointment(appointment, value));
      createTitle();
    }
  };

  const handleTimeStartChange = (e: TimePickerChangeEvent) => {
    if (e.value) {
      appointment.start = e.value;
      setModified(true);
    }
  };

  useEffect(() => {
    if (isAdding || isUpdating) {
      setModified(false);
    }
  }, [isAdding, isUpdating]);

  const monolinguaDDN = useRef<HTMLDivElement>(document.createElement("div"));
  const scrollToRef = () =>  monolinguaDDN.current.scrollIntoView();

  const handleSubmit = (event: any) => {

    if(appointment?.experience?.monolanguage !== undefined) {
      appointment.experience_monolanguage = appointment?.experience?.monolanguage;
    }
    if(languageRequired){
      if( appointment.language=== undefined){
        setLanguageError(true);
        scrollToRef();
      }else{
        setLanguageError(false);
        if (selectedDate.toString() !== appointment.calendar_date) {
          appointment.calendar_date = selectedDate.toString();
        }
        if (!appointment.id) {
          addAppointment({appointment, selectedDate}).then((response : any) => {
            if( ! response?.error){

              // dispatch(resetState());
              // dispatch(resetAppointmentState());
              // props.onClose();
            }
          });
        } else {
          updateAppointment({
            id: appointment.id,
            appointment: appointment,
            selectedDate,
          });
        }

      }
    }
    else {
      if (selectedDate.toString() !== appointment.calendar_date) {
        appointment.calendar_date = selectedDate.toString();
      }
      if (!appointment.id) {
        addAppointment({appointment, selectedDate}).then(() => {
          // dispatch(resetState());
          // dispatch(resetAppointmentState());
          // props.onClose();
        });
      } else {
        updateAppointment({
          id: appointment.id,
          appointment: appointment,
          selectedDate,
        });

      }
      // dispatch(resetState());
      // dispatch(resetAppointmentState());
    }
    // dispatch();

  };

  useEffect(() => {
    // props.onClose();
    if (isSuccessAdding || isSuccessUpdating) {
      props.onClose();
      // dispatch(selectAppointment(currentAppointment?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAdding , isSuccessUpdating]);


  useEffect(()=>{
    if(appointment?.id) {
      if (experiencesByStaffId) {
        if (!experiencesByStaffId.find((i) => i.id === appointment.experience_id)) {
            setIsErrorExperience(true);
        } else {
          setIsErrorExperience(false);
        }
      }
    }else{
      setIsErrorExperience(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[experiencesByStaffId])



  //TODO  provare anche a capire se si possono disabilitare alcuni range per i breaktime del personale
  return isEmpty(appointment) ||
  isLoadingCurrentAppointment ||
  isAdding ||
  isUpdating ||
  isFetchingCurrentAppointment ? (
      <CenteredLoadingSpinner />
  ) : (
      <Form
          onSubmit={handleSubmit}
          initialValues={appointment}
          render={(formRenderProps) =>
              appointment && (
                  <FormElement>
                    <Grid container alignItems="stretch" spacing={3}>

                      <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}

                      >

                      </Grid>
                      {isErrorExperience && (
                          <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                          >
                              <Error style={{fontSize:"15px"}} >
                                Si è verificato un errore: l'esperienza "{appointment.experience_name}" a cui fa riferimento l'appuntamento non è prenotabile.
                              </Error>
                          </Grid>
                      )}
                      <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={appointment.experience_id?  6 : 12}
                          className="rows-grandi  "
                      >
                        {isLoadingExperiencesByStaff || isEmpty(appointment) ? (
                            <CenteredLoadingSpinner />
                        ) : (
                            <Field
                                name={"experience_id"}
                                label={"Esperienza"}

                                className={"custom-unif-font-2 "}
                                onChange={(e) => {
                                  appointment.experience_id = e.value.id;
                                  handleChangeExperience(e);
                                  setModified(true);

                                }}
                                component={ExperienceDropdownlist}
                                disabled={appointment?.id}
                                required={true}
                                validator={[requireValidator]}
                                data={experiencesByStaffId}
                            />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={appointment.experience_id?  6 : 12} className={ `rows-grandi  ${isNew? "da-non-visualizzare" : ""}`}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <div className={ "std-date-picker-container dropdownModifica custom-unif-dropdown-focus"} >

                            <label
                                className="labelModifica std-date-picker-label"
                                data-shrink="true"
                            >
                              Data
                            </label>
                            <div className=" spanFiglia-daIngrandire std-date-picker-component">
                              <CustomDatePicker
                                  steps={{ minute: 15 }}
                                  value={toDateFromApi(selectedDate) as Date}
                                  disabled={(!currentAppointment?.id || isOneSubFromPlat) &&  !isErrorExperience}
                                  format="dd/MM/yyyy"
                                  required={true}
                                  validator={[requireValidator]}
                                  onChange={handleDateChange}
                                  min={new Date()}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      {appointment &&
                      appointment.experience_id &&
                      appointment.experience_id > 0 &&
                      !isEmpty(appointment.experience) ? (
                          <>
                            <Grid item xs={12} sm={6} md={6} lg={3} className="rows-grandi">

                              <div className="std-time-picker-scheduler-container dropdownModifica custom-unif-dropdown-focus">
                                <label
                                    className="labelModifica std-time-picker-scheduler-label"
                                    data-shrink="true"
                                >
                                  Inizio
                                </label>
                                <div className="custom-unif-font spanFiglia-daIngrandire std-time-picker-scheduler-component">
                                  <CustomTimePicker
                                      className="custom-unif-font"
                                      name={"start"}
                                      value={(appointment) ? appointment.start as Date : "" as unknown as Date}
                                      format="HH:mm"
                                      min={
                                        appointment?.calendar_date && calendarDay?.startTime

                                            ? createDateTimeFromDateAndTime(
                                                selectedDate as string,
                                                calendarDay?.startTime as string,
                                            )
                                            : new Date()
                                      }
                                      max={
                                        appointment?.calendar_date && calendarDay?.startTime
                                            ? createDateTimeFromDateAndTime(
                                                selectedDate as string,
                                                calendarDay?.endTime as string,
                                            )
                                            : new Date()
                                      }
                                      disabled={isOneSubFromPlat}
                                      steps={{ minute: 15 }}
                                      onChange={handleTimeStartChange}
                                      onBlur={handleTimeStartBlur}
                                      required={true}
                                      validator={[requireValidator]}
                                  />
                                </div>
                              </div>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={3} className="rows-grandi">
                              <div className="std-time-picker-scheduler-container  dropdownModifica custom-unif-dropdown-focus">
                                <label
                                    className="labelModifica std-time-picker-scheduler-label"
                                    data-shrink="true"
                                >
                                  Fine
                                </label>
                                <div className="spanFiglia-daIngrandire std-time-picker-scheduler-component">
                                  <CustomTimePicker
                                      name={"end"}
                                      // disabled={true}
                                      value={appointment.end as Date}
                                      disabled={isOneSubFromPlat}
                                      format="HH:mm"
                                      min={
                                        appointment?.calendar_date && calendarDay?.startTime
                                            ? createDateTimeFromDateAndTime(
                                                appointment?.calendar_date as string,
                                                calendarDay?.startTime as string,
                                            )
                                            : new Date()
                                      }
                                      max={
                                        appointment?.calendar_date && calendarDay?.startTime
                                            ? createDateTimeFromDateAndTime(
                                                appointment?.calendar_date as string,
                                                calendarDay?.endTime as string,
                                            )
                                            : new Date()
                                      }
                                      steps={{ minute: 15 }}
                                      onChange={handleTimeEndChange}
                                      onBlur={handleTimeEndBlur}
                                      required={true}
                                      validator={[requireValidator]}
                                  />
                                </div>
                              </div>
                            </Grid>


                                <Grid item xs={12} sm={12} md={4} lg={2}>
                                  <Field
                                      required
                                      name={"length_minutes"}
                                      value={localLengthMin}
                                      realValue={localLengthMin}
                                      form={formRenderProps}
                                      component={TimeDurationLengthSched}
                                      onChange={(e) => {
                                        if (e.target.value) {
                                          setAppointment(changeLengthAppointment(appointment, e.target.value as number));
                                          appointment.length_minutes = e.value;
                                          setModified(true);
                                        }
                                      }}
                                      label={"Durata"}
                                      minVal={60}
                                      iconText={"access_time_filled"}
                                      adornment={"minuti"}
                                      validator={[requireValidator, minVal60Validator]}
                                  />

                                </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                              <TextField
                                  fullWidth
                                  disabled={true}
                                  variant={"outlined"}
                                  label={"Buffer prima"}
                                  InputProps={{
                                    readOnly: true,
                                    tabIndex: -1,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                          minuti
                                        </InputAdornment>
                                    )
                                  }}
                                  tabIndex={-1}
                                  value={appointment?.experience?.buffer_minutes_before}

                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                              <TextField
                                  fullWidth
                                  disabled={true}
                                  variant={"outlined"}
                                  label={"Buffer dopo"}
                                  InputProps={{
                                    readOnly: true,
                                    tabIndex: -1,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                          minuti
                                        </InputAdornment>
                                    )
                                  }}
                                  tabIndex={-1}
                                  value={appointment?.experience?.buffer_minutes_after}

                              />
                            </Grid>



                            <Grid item xs={12} sm={12} md={6} lg={6} className="rows-grandi label-no-white-background">
                              {isLoadingExperiencesByStaff || isEmpty(appointment) ? (
                                  <CenteredLoadingSpinner />
                              ) : (
                                  !currentAppointment?.id ? (
                                        <TextField
                                            fullWidth
                                            name={"staff_id"}
                                            variant={"outlined"}
                                            label={"Referente"}
                                            className={"custom-unif-font-2"}
                                            // component={StaffDropdownlist}
                                            key={"id"}
                                            disabled={!currentAppointment?.id}
                                            // required={true}
                                            // validator={[requireValidator]}
                                            // onChange={handleChangeReferent}
                                            // data={dayStaff}
                                            // color={colors.find((p) => p.label === currentStaff?.color)}
                                            value={currentStaff?.name}
                                        />
                                      ): (
                                      <Field
                                          name={"staff_id"}
                                          label={"Referente"}
                                          className={"custom-unif-font-2 referente-dropdown"}
                                          component={StaffDropdownlist}
                                          key={"id"}
                                          disabled={!currentAppointment?.id}
                                          required={true}
                                          validator={[requireValidator]}
                                          onChange={handleChangeReferent}
                                          data={dayStaff}
                                          color={colors.find((p) => p.label === currentStaff?.color)}
                                          currentStaff={currentStaff}
                                      />
                                      )

                              )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="rows-grandi">
                              <Field
                                  name={"staff_id_list"}
                                  label={"Altro personale"}
                                  component={OtherStaffMultiselect}
                                  onChange={(e) => {
                                    appointment.staff_id_list = e.value?.map((e: any) => e.id);

                                    setModified(true);
                                  }}
                                  data={otherStaff}
                                  staffAvailable={dayStaff.filter(
                                      (p) => p.id !== currentStaff?.id
                                  )}
                              />
                            </Grid>
                            {appointment.experience?.monolanguage &&
                            <Grid item xs={12} sm={12} md={6} lg={6} className="rows-grandi">
                              <Field
                                  className={"custom-unif-font-2"}
                                  name={"language"}
                                  ref={monolinguaDDN}
                                  label={"Lingua *"}
                                  component={ValidatedDropDownList}
                                  onChange={(e) => {
                                    appointment.language = e.value;

                                    setModified(true);
                                  }}
                                  data={monoLanguages}
                                  required
                                  displError={languageError}
                                  validator={[requireValidator]}
                              />
                            </Grid>
                            }


                            {/*SPAZIO TRA RIGHE*/}
                            {appointment &&
                            appointment.experience_id &&
                            appointment.experience_id > 0 &&
                            !isEmpty(appointment.experience) ? (
                                <>
                                  {/*SPAZIO TRA RIGHE*/}
                                  {/*<Grid item xs={12} sm={12} md={12} lg={12}>*/}
                                  {/*  <Field*/}
                                  {/*      name={"booking_mode"}*/}
                                  {/*      label={"Prenotazioni da nowmyplace.com"}*/}
                                  {/*      required*/}
                                  {/*      className={"custom-unif-font-2"}*/}
                                  {/*      component={ValidatedDropDownListPrenTypes}*/}
                                  {/*      itemRender={prenotationTItemRender}*/}
                                  {/*      onChange={handlePTypeChange}*/}
                                  {/*      data={prenotationtypes}*/}
                                  {/*      value={valuePrenotationT}*/}
                                  {/*      validator={[requireValidator]}*/}
                                  {/*      defaultVal={currentPlanner?.booking_mode}*/}

                                  {/*  />*/}
                                  {/*</Grid>*/}
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Field
                                        name={"location_id"}
                                        label={"Sito"}
                                        onChange={(e) => {
                                          setLocationValue(e.value);
                                          appointment.location_id = (e.value.id) ?  e.value.id : e.value;
                                          setModified(true);
                                        }}
                                        value={locationValue}
                                        useDefaultValue={ locationValue===undefined}
                                        className={"custom-unif-font-2"}
                                        component={ValidatedDropDownListLocationId}
                                        data={appointment?.experience?.locations}
                                        // value={appointment.location}
                                        key={"id"}
                                        textField={"name"}
                                        dataItemKey={"id"}
                                        required={true}
                                        validator={[requireValidator]}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12} className="rows-grandi-2">
                                    <Field
                                        name={"note"}
                                        value={appointment?.note}
                                        label={"Note relative all'appuntamento"}
                                        rows={2}

                                        multiline
                                        onChange={(e) => {
                                          appointment.note = e.target.value;
                                          setModified(true);
                                        }}
                                        component={ValidatedInput}
                                        // value={appointment.location}

                                        validator={[]}
                                    />

                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        fullWidth
                                        disabled={true}
                                        variant={"outlined"}
                                        label={"Attrezzature"}
                                        InputProps={{
                                          readOnly: true,
                                          tabIndex: -1,
                                        }}
                                        tabIndex={-1}
                                        value={` ${
                                            (appointment?.experience?.equipments === undefined) ? " " : 
                                            appointment?.experience?.equipments?.map((item, index) => {
                                              return (
                                                  (     (index > 0 ? " " : "") +
                                                      item.name + " " +item.required_number)
                                              );
                                            })
                                        }`}

                                    />

                                  </Grid>
                                  <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      className="rows-grandi-2"
                                  >
                                    <TextField
                                        fullWidth
                                        disabled={true}
                                        variant={"outlined"}
                                        multiline
                                        rows={2}
                                        label={"Note relative all'esperienza"}
                                        InputProps={{
                                          readOnly: true,
                                          tabIndex: -1,
                                        }}
                                        tabIndex={-1}
                                        value={(appointment?.experience_note === "" ||appointment?.experience_note === undefined)? " ":  appointment?.experience_note}

                                    />
                                  </Grid>


                                  <Grid container item spacing={1} className={"custom-section-forms-container"}>
                                    <Typography variant="h6" style={{fontWeight:"bold", paddingLeft:"4px"}} >Limitazioni</Typography>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems="stretch"
                                        className="custom-section-forms-exp"
                                    >
                                      <Grid item xs={12} sm={12} md={6} lg={6}  className={"rows-grandi dropdown-multi-container"}>
                                        {appointment?.experience?.customer_exclusion ? (

                                            <TextField
                                                fullWidth
                                                disabled={true}
                                                variant={"outlined"}
                                                label={"Categorie escluse"}
                                                rows={2}
                                                multiline
                                                InputProps={{
                                                  readOnly: true,
                                                  tabIndex: -1,
                                                }}
                                                tabIndex={-1}
                                                value={` ${
                                                    appointment.experience.customer_exclusion?.map(
                                                        (item: IExclusionType, index) => {
                                                          return (
                                                              ((index > 0) ? " " : "") + item.label +" "
                                                          )
                                                        }
                                                    )
                                                }`}

                                            />

                                        ) : (
                                            <TextField
                                                fullWidth
                                                disabled={true}
                                                variant={"outlined"}
                                                rows={2}
                                                multiline
                                                label={"Categorie escluse"}
                                                InputProps={{
                                                  readOnly: true,
                                                  tabIndex: -1,
                                                }}
                                                tabIndex={-1}
                                                value={"Nessuna categoria esclusa"}

                                            />
                                        )}
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={3} lg={3}>

                                        <Field
                                            disabled={true}
                                            title={"Preavviso minimo con cui accetto di ricevere la prima prenotazione ad un appuntamento"}
                                            name={"pre_booking_hours"}
                                            component={ValidatedInput}
                                            validator={[digitValidator]}
                                            label={"Preavviso minimo lungo"}
                                            adornment={"ore"}
                                            minVal={0}
                                            value={appointment?.experience?.pre_booking_hours}
                                            defaultVal={appointment?.experience?.pre_booking_hours}
                                        />

                                      </Grid>
                                      <Grid item xs={12} sm={12} md={3} lg={3}>

                                        <Field
                                            disabled={true}
                                            name={"pre_booking_hours_short"}
                                            component={ValidatedInput}
                                            validator={[digitValidator]}
                                            label={"Preavviso minimo breve"}
                                            title={"Preavviso minimo con cui accetto di ricevere nuove iscrizioni ad un appuntamento presente in agenda e che conta già uno o più iscritti"}
                                            adornment={"ore"}
                                            minVal={0}
                                            maxVal={24}
                                            value={appointment?.experience?.pre_booking_hours_planned}
                                            defaultVal={appointment?.experience?.pre_booking_hours_planned}
                                        />

                                      </Grid>

                                      <Grid item xs={12} sm={6} md={3} lg={3}>
                                        {/*<Field*/}
                                        {/*    // disabled={true}*/}
                                        {/*    name={"customers_max_number"}*/}
                                        {/*    component={ValidatedInputNumber}*/}
                                        {/*    label={"Max Pax"}*/}
                                        {/*    // onChange={(e) => (formRenderProps.modified = true)} TODO: rendere editabile*/}
                                        {/*    validator={[]}*/}
                                        {/*    value={(appointment?.max_pax) ? appointment?.max_pax : appointment?.experience?.customers_max_number}*/}
                                        {/*    // defaultVal={appointment?.experience?.customers_max_number}*/}
                                        {/*/>*/}

                                        <Field
                                            disabled={true}
                                          // minVal={1}
                                          // InputProps={{ inputProps: { min: 1 } }}
                                          name={"customers_min_number"}
                                            component={ValidatedInput}
                                            validator={[digitValidator]}
                                          label={"Min pax"}
                                          defaultVal={appointment?.experience?.customers_min_number || " "}
                                          value={appointment?.experience?.customers_min_number|| " "}
                                          // onChange={(e)=> {
                                          //   appointment.customers_min_number = e.target.value as number;
                                          //   setModified(true);
                                          // }}
                                          // validator={[requireValidator]}
                                          // required
                                      />
                                      </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                      <Field
                                          disabled={true}
                                          name={"age_min"}
                                          component={ValidatedInput}
                                          validator={[digitValidator]}
                                          label={"Età minima"}
                                          adornment={"anni"}
                                          min={0}
                                          value={appointment?.experience?.age_min || " "}
                                          defaultVal={appointment?.experience?.age_min || " "}
                                      />
                                    </Grid>
                                      <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <Field
                                            disabled={true}
                                            name={"weight_min"}
                                            component={ValidatedInput}
                                            validator={[digitValidator]}
                                            label={"Peso minimo"}
                                            adornment={"kg"}
                                            min={0}
                                            value={appointment?.experience?.weight_min || " "}
                                            defaultVal={appointment?.experience?.weight_min || " "}
                                        />

                                      </Grid>
                                      <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <Field
                                            disabled={true}
                                            name={"height_min"}
                                            component={ValidatedInput}
                                            validator={[digitValidator]}
                                            label={"Altezza minima"}
                                            adornment={"cm"}
                                            min={0}
                                            value={appointment?.experience?.height_min || " "}
                                            defaultVal={appointment?.experience?.height_min || " "}
                                        />

                                      </Grid>
                                      <Grid item xs={12} sm={6} md={3} lg={3}>

                                        <Field
                                            minVal={1}
                                            InputProps={{ inputProps: { min: 1 } }}
                                            name={"customers_max_number"}
                                            component={ValidatedInput}
                                            validator={[digitValidator]}
                                            label={"Max pax"}
                                            defaultVal={(appointment?.experience_customers_max_number ) ? appointment?.experience_customers_max_number  : appointment?.experience?.customers_max_number}
                                            value={(appointment?.experience_customers_max_number ) ? appointment?.experience_customers_max_number  : appointment?.experience?.customers_max_number}
                                            onChange={(e)=> {
                                              appointment.experience_customers_max_number  = e.target.value as number;
                                              setModified(true);
                                            }}
                                            // validator={[requireValidator]}
                                            // required
                                        />

                                      </Grid>

                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                      <Field
                                          disabled={true}
                                          name={"age_max"}
                                          component={ValidatedInput}
                                          validator={[digitValidator]}
                                          label={"Età massima"}
                                          adornment={"anni"}
                                          min={0}
                                          value={appointment?.experience?.age_max || " "}
                                          defaultVal={appointment?.experience?.age_max || " "}
                                      />

                                    </Grid>


                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                      <Field
                                          disabled={true}
                                          name={"weight_max"}
                                          component={ValidatedInput}
                                          validator={[digitValidator]}
                                          label={"Peso massimo"}
                                          adornment={"kg"}
                                          min={0}
                                          value={appointment?.experience?.weight_max || " "}
                                          defaultVal={appointment?.experience?.weight_max || " "}
                                      />

                                    </Grid>
                                      <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <Field
                                            disabled={true}
                                            name={"height_max"}
                                            component={ValidatedInput}
                                            validator={[digitValidator]}
                                            label={"Altezza massima"}
                                            adornment={"cm"}
                                            min={0}
                                            value={appointment?.experience?.height_max || " "}
                                            defaultVal={appointment?.experience?.height_max || " "}
                                        />

                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </>
                            ) : (
                                <Grid />
                            )}
                          </>
                      ) : ""}
                      <Grid container justifyContent="center" style={{marginBottom: "2vh" }}>
                        <div className="k-form-buttons">
                          <BaseButton
                              className={` ${
                                  hasModified ? "custom-unif-viola-back" : ""
                              }`}
                              type={"submit"}
                              variant="contained"
                              disabled={!hasModified}
                              onClick={handleSubmit}
                              startIcon={<SaveIcon />}
                          >
                            Salva
                          </BaseButton>

                          <BaseButton
                              onClick={props.cancelEdit}
                              className={`custom-button-annulla custom-unif-grigio-back left-spacing-10p ${
                                  hasModified 
                                      ? "custom-unif-arancione-back"
                                      : ""
                              }`}
                              startIcon={<CancelPresentationIcon />}
                              disabled={!hasModified}
                          >Annulla</BaseButton>

                          {formRenderProps.visited &&
                          formRenderProps.errors &&
                          formRenderProps.errors.VALIDATION_SUMMARY && (
                              <div className={"k-messagebox k-messagebox-error"}>
                                <Error>
                                  {formRenderProps.errors.VALIDATION_SUMMARY}
                                </Error>
                              </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </FormElement>
              )
          }
      />
  );
};
