import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ISubscriber} from "../models/entities/calendar/ISubscriber";


interface SubscribersState {
  currentSubscriberId?: number;
  currentSubscriberItem?: ISubscriber;
}

const initialState: SubscribersState = {
  currentSubscriberId: undefined,
  currentSubscriberItem: undefined,
};

const SubscribersSlice = createSlice({
  name: "Subscribers",
  initialState,
  reducers: {
    selectSubscriber(state, action: PayloadAction<number>) {
      state.currentSubscriberId= action.payload;
    },
    selectNewSubscriber(state, action: PayloadAction<ISubscriber>) {
      state.currentSubscriberItem = action.payload;
    },
  },

});

export const {
  selectSubscriber,
  selectNewSubscriber
} = SubscribersSlice.actions;

export default SubscribersSlice.reducer;
