import {api} from "./api";
import {ISeason} from "../../models/entities/ISeason";
import {IGetSeasonsDto} from "../../models/dto/seasons/IGetSeasonsDto";
import {IGetSeasonDto} from "../../models/dto/seasons/IGetSeasonDto";
import {IAddSeasonDto} from "../../models/dto/seasons/IAddSeasonDto";
import {IUpdateSeasonDto} from "../../models/dto/seasons/IUpdateSeasonDto";

const seasonsApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getSeasons: build.query<ISeason[], number>({
      query: (plannerId) => ({
        url: 'plannerSeason',
        params: { 'planner_id': plannerId }
      }),
      transformResponse: (res: IGetSeasonsDto, meta) => res.plannerSeasons,
      providesTags: result => result ? [...result.map(({id}) => ({ type: 'Seasons', id } as const)), { type: 'Seasons', id: 'LIST' }] : [{ type: 'Seasons', id: 'LIST' }]
    }),
    getSeason: build.query<ISeason, number>({
      query: id => `plannerSeason/${id}`,
      transformResponse: (res: IGetSeasonDto, meta) => res.plannerSeason,
      providesTags: (result, error, id) => [{ type: 'Seasons', id }]
    }),
    addSeason: build.mutation<ISeason, ISeason>({
      query: season => ({
        url: 'plannerSeason',
        method: 'POST',
        body: season
      }),
      transformResponse: (res: IAddSeasonDto, meta) => res.plannerSeason,
      invalidatesTags: (result, error ) => error ? [] :  [{ type: 'Seasons', id: 'LIST' },{ type: "CalendarDay", id: "LIST" }]
    }),
    updateSeason: build.mutation<ISeason, { id: number, season: ISeason }>({
      query: ({ id, season }) => ({
        url: `plannerSeason/${id}`,
        method: 'PUT',
        body: season
      }),
      transformResponse: (res: IUpdateSeasonDto, meta) => res.plannerSeason,
      invalidatesTags: (result, error, {id}) =>  error ? [] :  [{ type: 'Seasons', id },{ type: "CalendarDay", id: "LIST" }]
    }),
    deleteSeason: build.mutation<void, number>({
      query: id => ({
        url: `plannerSeason/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, id) =>  error ? [] :  [{ type: 'Seasons', id }]
    })
  })
})

export const {
  useGetSeasonQuery,
  useGetSeasonsQuery,
  useAddSeasonMutation,
  useUpdateSeasonMutation,
  useDeleteSeasonMutation
} = seasonsApi