import {Grid} from "@mui/material";
// import {
//   DateRangePicker,
//   DateRangePickerChangeEvent,
//   SelectionRange,
// } from "@progress/kendo-react-dateinputs";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useAppDispatch} from "../../store";
// import {
//   deleteSeason,
//   resetAddSeason,
//   resetDeleteSeason,
//   resetUpdateSeason,
//   updateSeason,
// } from "../../store/planners";
import {formatDateForApi} from "../../utils/dateHelper";
import {SeasonFormProps} from "../../models/props/SeasonFormProps";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import BaseButton from "components/common/BaseButton";
import {useDeleteSeasonMutation, useUpdateSeasonMutation,} from "store/api/seasons";
import {ISeason} from "models/entities/ISeason";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SaveIcon from "@mui/icons-material/Save";
import CustomDatePicker from "../common/CustomDatePicker";
import {requireValidator} from "../../utils/validators/requireValidator";
import {Error} from "@progress/kendo-react-labels";

const UpdateSeasonForm: FC<SeasonFormProps> = ({
  opened,
  closeForm,
  initialRange,
  seasonId,
}) => {
  const [isError] = useState(false);

   useAppDispatch();
  const currentPlanner = useCurrentPlanner();
  const [updateSeason, { isLoading: isUpdating }] = useUpdateSeasonMutation();
  const [deleteSeason, { isLoading: isDeleting, isSuccess: isDeleted }] =
    useDeleteSeasonMutation();
  // const { updateSeasonState, deleteSeasonState } = useAppSelector(
  //   (s) => s.planners
  // );

  const [startRange, setStartRange] = useState<Date | null>();
  const [endRange, setEndRange]     = useState<Date | null>();
  const [rangeValid, setRangeValid] = useState(false);

  useEffect(() => {
    // console.log(initialRange);
    setStartRange(initialRange?.start);
    setEndRange(initialRange?.end);
  }, [initialRange]);

  const handleSubmit = () => {
    updateSeason({
      id: seasonId as number,
      season: {
        planner_id: currentPlanner?.id as number,
        season_begin_day: formatDateForApi(startRange as Date),
        season_end_day: formatDateForApi(endRange as Date),
      } as ISeason,
    });
    closeForm();
  };

  // const handleChange = (e: DateRangePickerChangeEvent) => {
  //   if (initialRange && (initialRange.start as Date) < new Date()) {
  //     e.value.end = e.value.start;
  //     e.value.start = initialRange?.start as Date;
  //   }
  //   setDateRange(e.value);
  //   //closeForm();
  // };

  const handleChangeStart = ( start : any) =>{
    // if (initialRange && (initialRange.start as Date) < new Date()) {
    //   e.value.end = e.value.start;
    //   e.value.start = initialRange?.start as Date;
    // }
    // setDateRange(e.value);
    setRangeValid(!((start.value as Date) < (endRange as Date)))
   setStartRange(start.value as Date);
  }
  const handleChangeEnd = ( end : any) =>{
    // if (initialRange && (initialRange.start as Date) < new Date()) {
    //   e.value.end = e.value.start;
    //   e.value.start = initialRange?.start as Date;
    // }

    // setDateRange(e.value);
    setRangeValid(!((end.value as Date) > (startRange as Date)))
    setEndRange(end.value as Date);
  }

  const handleDelete = () => {
    deleteSeason(seasonId as number);
  };

  // useEffect(() => {
  //   if (!isUpdating) {
  //     //dispatch(resetUpdateSeason());
  //     closeForm();
  //   }
  // }, [isUpdating]);

  useEffect(() => {
    if (isDeleted) {
      closeForm();
    }
  }, [isDeleted]);

  return (
    <Fragment>
      {opened && (
          <Dialog
              height={'25vh'}
              onClose={() => closeForm()}
              title={"Modifica periodo"}

              className={"add-edit-season-dialog"}
          >
            <div style={{height: '140px', textAlign: "center"}}>
              <Grid container>
                <Grid container spacing={3} justifyContent="center" >
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className={"std-date-picker-container"}>

                      <label
                          className="std-date-picker-label"
                          data-shrink="true"
                      >
                        Dal
                      </label>
                      <div className="std-date-picker-component">
                        <CustomDatePicker
                            value={startRange}
                            format="dd/MM/yyyy"
                            required={true}
                            validator={[requireValidator]}
                            onChange={(e: any) => handleChangeStart(e)}
                            min={new Date()}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className={"std-date-picker-container"}>

                      <label
                          className="std-date-picker-label"
                          data-shrink="true"
                      >
                        Al
                      </label>
                      <div className="std-date-picker-component">
                        <CustomDatePicker
                            value={endRange as Date}
                            format="dd/MM/yyyy"
                            required={true}
                            validator={[requireValidator]}
                            onChange={(e: any) => handleChangeEnd(e)}
                            min={new Date()}
                        />
                      </div>
                    </div>
                    {rangeValid && <Error>La data di fine deve essere successiva alla data iniziale</Error>}
                  </Grid>
                </Grid>
              </Grid>
            </div>

            <DialogActionsBar>
                {isUpdating || isDeleting ? (
                    <CenteredLoadingSpinner/>
                ) : (
                    <Fragment>
                      <BaseButton
                          onClick={handleDelete}
                          color="secondary"
                          disabled={isDeleting}
                          startIcon={<CancelPresentationIcon/>}
                      >
                        Elimina
                      </BaseButton>
                      <BaseButton
                          onClick={handleSubmit}
                          color="primary"
                          autoFocus
                          disabled={isError || isUpdating}
                          className={"custom-unif-viola"}
                          startIcon={<SaveIcon/>}
                      >
                        Salva
                      </BaseButton>
                    </Fragment>
                )}
              </DialogActionsBar>
          </Dialog>
        )}

      {/* <ResponseSnackbar responseSnackbar={[
        { sliceState: deleteSeasonState, resetFunction:  resetDeleteSeason() } as IResponseSnackbar,
        { sliceState: updateSeasonState, resetFunction: resetUpdateSeason() } as IResponseSnackbar,
      ]} /> */}
    </Fragment>
  );
};

export default UpdateSeasonForm;
