import {Box, Grid, OutlinedInput, TextField, Typography} from "@mui/material";
import {useAppSelector} from "../../store";
import React, {FC, Fragment, useEffect, useState} from "react";
import {IDayDiscount} from "../../models/entities/discounts/IDayDiscount";
import DiscountTextField from "./DiscountTextField";
import {IExperience} from "../../models/entities/IExperience";

import {useCurrentExperience} from "utils/hooks/currentExperience";
import {useGetExperienceQuery, useUpdateExperienceMutation,} from "../../store/api/experiences";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import {skipToken} from "@reduxjs/toolkit/query/react";
import InputAdornment from "@mui/material/InputAdornment";
import {DropDownList, ListItemProps} from "@progress/kendo-react-dropdowns";
import {parseInt} from "lodash";
import {IType} from "../../models/entities/types/IType";

const DiscountsForm: FC<EditFormProps<IExperience>> = ({
                                                           item: item,
                                                           onSubmit,
                                                           isSectionActive,
                                                       }) => {

    const currentExperience= useCurrentExperience();

    const[ AliIVA, setAliIVA] = useState(currentExperience?.iva || 0);
    const defaultExperience = {
        discount_day1: 0,
        discount_day2: 0,
        discount_day3: 0,
        discount_day4: 0,
        discount_day5: 0,
        discount_day6: 0,
        discount_day7: 0,
        group_discount: 0,
        group_discount_number: 0,
        iva : 0
    } as IExperience;
    const { isFetching } =
        useGetExperienceQuery(item.id ?? skipToken);
    const { days , ivacombos} = useAppSelector((store) => store.types);

    const [] = useUpdateExperienceMutation();

    const [discounts, setDiscounts] = useState<IDayDiscount[]>(
        days?.map((d, i) => ({
            ...d,
            label: d.label,
            // @ts-ignore
            discount: currentExperience ? currentExperience[`discount_day${i + 1}`] : defaultExperience[`discount_day${i + 1}`],
        }))
    );

    useEffect(() => {
        setDiscounts(
            days?.map((d, i) => ({
                ...d,
                label: d.label,
                // @ts-ignore
                discount:currentExperience ? currentExperience[`discount_day${i + 1}`] : defaultExperience[`discount_day${i + 1}`],
            }))
        );
    }, [currentExperience]);

    const [groupDiscount, setGroupDiscount] = useState(
        currentExperience?.group_discount || 0
    );
    const [groupPartecipants, setGroupPartecipants] = useState(
        currentExperience?.group_discount_number || 0
    );

    const onUpdateDiscount = (id: string | undefined, discount: number) => {
        if(id)
        setDiscounts((prevState) =>
            prevState?.map((d) => (d.key === parseInt(id) ? { ...d, discount } : d))
        );
    };

    useEffect(() => {

        saveStateParent();
    }, [discounts, groupDiscount, groupPartecipants, AliIVA]);

    const saveStateParent = () => {
        const model: IExperience = currentExperience ?{
            ...currentExperience,
            discount_day1: discounts[0].discount,
            discount_day2: discounts[1].discount,
            discount_day3: discounts[2].discount,
            discount_day4: discounts[3].discount,
            discount_day5: discounts[4].discount,
            discount_day6: discounts[5].discount,
            discount_day7: discounts[6].discount,
            group_discount: groupDiscount,
            group_discount_number: groupPartecipants,
            iva: AliIVA,
        } : defaultExperience;
        onSubmit(model);
    };
    const valueRender = (
        element: React.ReactElement<HTMLSpanElement>,
        value: any
    ) => {
        if (value === null || value === undefined) {
            return element;
        }

        const combo = ivacombos.find((i : any) => i.key === value);

        const children = [

            <span >{(value!==undefined) ? combo?.label+ " %": ""}</span>
        ];


        return React.cloneElement(element, { ...element.props }, children);
    };


    const itemRender = (
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
    ) => {
        const prenKey = itemProps.dataItem;
        const country = ivacombos.find((i : any) => i.key === prenKey)

        const itemChildren = [

            <span  key={country?.key}>{country?.label + " %"} </span>,

        ];

        return React.cloneElement(li, li.props, itemChildren);
    };

    const netPriceClassName = (isSectionActive)? "display-true": "display-false";

    return isFetching ? (
        <Fragment />
    ) : (
        <Grid container alignItems={"stretch"} spacing={1}>
            {/* <Box paddingY={2} paddingTop={0}>
        <BaseButton
          tabIndex={-1}
          style={{ borderRadius: 50 }}
                    className="custom-unif-viola-back"
          variant={"outlined"}
          startIcon={<SaveIcon />}
          onClick={onSaveDiscounts}
        >
          Salva sconti
        </BaseButton>
      </Box> */}
            <Grid item xs={1} className={"custom-left-spacing-prices"}>

            </Grid>
            <Grid item xs={7} sm={6} md={5}>

                {discounts?.map((d) => (

                    <Box key={`day-${d.key}`} paddingY={1}>
                        <Grid container spacing={3} xs={12}>
                            <Grid item xs={4} sm={6} lg={6}>
                                <Typography
                                    className={"title-giorno-timetable"}
                                    align={"right"}
                                    variant="h6"
                                >
                                    {d.label}
                                </Typography>
                            </Grid>


                            <Grid item xs={8} sm={6}  lg={6} className={"sconto-prezzinetti"}>

                                <DiscountTextField
                                    className={`sconto-prezzinetti-${d.key}`}
                                    value={d.discount.toString()}
                                    id={`sconto-day-prezzinetti-${d.key}`}
                                    variant={"outlined"}
                                    onChange={(e) => {
                                        onUpdateDiscount(d.key ? d.key.toString() : undefined, isNaN( parseFloat(e.target.value)) ? 0 :  parseFloat(e.target.value))
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                ))}
                <Grid item xs={12}></Grid>
                <Box display={"flex"} flexDirection={"column"} alignItems={"stretch"} style={{marginTop:"10px"}}>
                    <Grid container spacing={3} xs={12}>
                        <Grid item xs={4} sm={6} lg={6}>
                            <Typography
                                className={"title-giorno-timetable group-discount-label"}
                                align={"right"}
                                variant="h6"
                            >
                                Sconto di gruppo
                            </Typography>
                        </Grid>
                        <Grid item  xs={8} sm={6} lg={6}  className={"sconto-prezzinetti"}>
                            <TextField
                                type={"number"}
                                fullWidth
                                variant={"outlined"}
                                value={groupPartecipants}

                                onWheel={event => { event.preventDefault(); }}
                                onChange={(e) => setGroupPartecipants(parseInt(e.target.value))}
                                InputProps={{

                                    endAdornment: (
                                        <InputAdornment position="end">
                                            min pax
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item  xs={4} sm={6} lg={6}>
                            <Typography
                                className={"title-giorno-timetable"}
                                align={"right"}
                                variant="h6"
                            >
                                Aliquota iva
                            </Typography>
                        </Grid>
                        <Grid item  xs={8} sm={6} lg={6} className={"sconto-prezzinetti"}>

                            <div>
                                <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth dropdownModifica dropdownAli custom-unif-dropdown-focus">

                                    <div className="etichetta-eta border-custom-components MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedStart MuiOutlinedInput-adornedStart custom-unif-font ">
                                        <DropDownList
                                            itemRender={itemRender}
                                            valueRender={valueRender}
                                            data={
                                                ivacombos.map((item: IType, index : any) => {
                                                    return item.key;
                                                })
                                            }
                                            value={AliIVA}
                                            onChange={(e)=> {
                                                setAliIVA(parseInt(e.target.value));

                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Grid>

                    </Grid>

                </Box>
            </Grid>
            {/*<Divider flexItem orientation={"vertical"} />*/}
            <Grid  item xs={5}>

                <div className={"prezzinetti-section " +netPriceClassName }  >
                    {discounts?.map((d) => (
                        <Box className={`day-prezzinetti-${d.key}`} paddingY={1}>
                            <TextField
                                disabled
                                fullWidth
                                id={`input-day-prezzinetti-${d.key}`}
                                variant={"outlined"}

                                InputProps={{

                                    endAdornment: (
                                        <InputAdornment position="end">
                                            euro
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                    ))}
                </div>
                <DiscountTextField
                    fullWidth
                    style={(!isSectionActive)? {marginTop:"520px"} : {marginTop:"10px"}}
                    className={`sconto-prezzinetti-digruppo`}
                    variant={"outlined"}
                    value={groupDiscount}
                    onChange={(e) => setGroupDiscount(parseFloat(e.target.value))}
                />
            </Grid>
        </Grid>
    );
};

export default DiscountsForm;
