import { IBase } from "./IBase";
import { IStaff } from "./IStaff";
import { IExperienceEquipment } from "./IExperienceEquipment";
import { ILocation } from "./ILocation";
import { IExclusionType } from "./types/IExclusionType";
import { IPrice } from "./prices/IPrice";
import {IExperienceAvailability} from "./experienceAvailability/IExperienceAvailability";

export const NOT_ACTIVE = 0;
export const DEFAULT_AVAILABILITY = 1;
export const CUSTOM_AVAILABILITY = 2;
export interface IExperience extends IBase {
  name: string;
  planner_id: number;
  description: string;
  active: boolean;
  monolanguage: boolean;
  private: boolean;
  isBookable: boolean;
  note: string;
  price_type: string;
  booking_mode?: string;
  cancellation_type: string;
  refund_hour_limit1: number | null;
  refund_hour_limit2: number | null;
  refund_perc_limit1: number | null;
  refund_perc_limit2: number | null;
  discount_day1: number;
  discount_day2: number;
  discount_day3: number;
  discount_day4: number;
  discount_day5: number;
  discount_day6: number;
  discount_day7: number;
  group_discount_number: number;
  group_discount: number;
  aliquota_iva: string;
  length_minutes: number;
  buffer_minutes_before: number;
  buffer_minutes_after: number;
  customers_max_number: number;
  customers_min_number: number;
  age_min: number;
  age_max: number;
  height_min: number;
  height_max: number;
  weight_min: number;
  weight_max: number;
  pre_booking_hours_planned : number;
  pre_booking_hours : number;
  iva: number;
  notification_hours: number;
  deleted_at?: string;
  created_at?: string;
  updated_at?: string;
  cms_page_id: any;

  valid_period_start?: string | Date;
  valid_period_end?: string | Date;

  customer_exclusion: IExclusionType[];
  difficulty_level: string;
  customer_target_types: string[];

  prices: IPrice[];
  staffs: IStaff[];
  equipments: IExperienceEquipment[];
  locations: ILocation[];

  isAvailabilityCustom: number;

  availability: IExperienceAvailability;

  times_ava: number[],
  days_ava: number[],

  //campi solo per super admin
  /*Area (combo con lista vincolata, in partenza solo Lago di Garda)
    Sottoarea (combo con lista vincolata alle sottoaree dell’area selezionata, quindi in partenza solo costa nord, costa est, costa sud e costa ovest)
    Categoria (combo con lista vincolata delle categorie)
    Sottocategoria (combo con lista vincolata delle sottocategorie appartenenti alla categoria selezionata)
    Sconto in acquisto % (indica lo sconto applicato al prezzo al pubblico
    Mark-up % (indica la percentuale di ricarico applicata al prezzo al pubblico
    Visibile, un campo booleano che ci permetta di decidere di non intermediare la vendita di una data esperienza; di default true.
    */
  site_city: string;
  site_cap: string;
  site_province: string;
  site_active: boolean;
  site_mark_up: number;
  site_discount: number;
  site_area: string;
  site_region?: string;
  site_category_id?: number| string;
  site_subcategory_id?: number | string;


}

