import React, {cloneElement, useEffect, useState} from "react";

import {ListItemProps, MultiSelect, MultiSelectChangeEvent,} from "@progress/kendo-react-dropdowns";
import {FieldRenderProps} from "@progress/kendo-react-form";
import {IType} from "../../models/entities/types/IType";

export interface KeyValueMultiselectEvoProps {
    disabled?: boolean;
    values?: any[];
    placeholder?: string;
    additionalClassNames?: string;
    onChange: (event: {
        target?: any;
        value?: any;
    }) => void;
    label: string;
    data: any[]
}
export const KeyValueMultiselectEvo = (props: KeyValueMultiselectEvoProps) => {
    const {data}  = props;

    const [selectedItem, setSelectedItem] = useState(props.values ?? []);


    useEffect(() => {
        if(props.values !== null && props.values !== undefined) {
            let selectedValues: IType[] = [];
            props.values.forEach((item: any) => {
                let realItem;
                if(data!== undefined && item !== undefined) {
                    realItem = data.find((itemExtended: any) => itemExtended.key === item);
                    if (realItem === undefined && item.key !== undefined) {
                        realItem = data.find((itemExtended: any) => itemExtended.key === item.key);
                    }
                }else{
                    realItem = {
                        key : item,
                        label : item
                    };
                }
                selectedValues = [...selectedValues, realItem];
            })
            setSelectedItem(selectedValues);
        }
    }, [props.values, data]);


    const handleChange = (event: MultiSelectChangeEvent) => {
        if (props.onChange) {
            props.onChange.call(undefined, { value: event.value });
        }

        setSelectedItem(event.value);
    };

    const KeyValueItemRender = (
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
    ) => {

        const itemChildren = [
            <div style={{ alignSelf: "inherit" }} key={itemProps.dataItem.key}>
                <span style={{ marginLeft: "10px" }}>{li.props.children}</span>
            </div>,
        ];

        return cloneElement(li, li.props, itemChildren);
    };


    return (
            <div className={"std-multiselect-container dropdownModifica multiselect-dropdown custom-unif-dropdown-focus "+(props.additionalClassNames?? '')} >
                <label
                    className="labelModifica std-multiselect-label"
                    data-shrink="true">{props.label}
                </label>
                <div className="std-multiselect-component">

                    <MultiSelect
                        style={{width: '100%'}}
                        disabled={(!data) || (props.disabled ?? false)}
                        onChange={handleChange}
                        value={selectedItem}
                        data={(data)? data: []}
                        dataItemKey={"key"}
                        textField={"label"}
                        placeholder={props.placeholder ?? ''}
                        itemRender={KeyValueItemRender}
                        tags={
                            selectedItem && selectedItem.length > 0
                                ? selectedItem.map((item: any) => {

                                    return {
                                        text: `${item?.label} `,
                                        data: [item],
                                    };
                                })
                                : []
                        }
                    />
                </div>
            </div>
    );
};
