import {
    DayView,
    MonthView,
    Scheduler,
    SchedulerDataChangeEvent,
    SchedulerDateChangeEvent,
    SchedulerGroup,
} from "@progress/kendo-react-scheduler";
import {CustomSchedulerForm} from "../../components/scheduler/CustomSchedulerForm";
import CustomSchedulerHeader from "../../components/scheduler/CustomSchedulerHeader";
import {CustomSchedulerEditItem} from "../../components/scheduler/CustomSchedulerEditItem";
import {CustomSchedulerModelFields} from "../../models/constants/CustomSchedulerModelFields";
import {CustomSchedulerItem} from "../../components/scheduler/CustomSchedulerItem";
import {CustomSchedulerSlot} from "../../components/scheduler/CustomSchedulerSlot";

import React, {Fragment, useEffect, useState} from "react";

import {useAppDispatch, useAppSelector} from "../../store";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import {dateToStringYYYYMMDD, toDateFromApi} from "../../utils/dateHelper";
import {IAppointment} from "models/entities/calendar/IAppointment";
import {useDeleteAppointmentMutation, useGetAppointmentQuery, useGetCalendarDayQuery,} from "../../store/api/calendar";
import {IStaff} from "../../models/entities/IStaff";
import {Box} from "@mui/material";
import DetailResource from "components/scheduler/DetailResource";
import '@progress/kendo-date-math/tz/Europe/Rome';
import {useCurrentAppointmentOpened} from "../../utils/hooks/currentAppointmentOpenedState";
import {SchedulerDialogDetail} from "../../components/scheduler/SchedulerDialogDetail";
import {skipToken} from "@reduxjs/toolkit/dist/query/react";
import {CustomSchedulerSlotMonth} from "../../components/scheduler/CustomSchedulerSlotMonth";
import {setSchedulerDay} from "../../store/schedulerView";
import FullPageLoader from "components/common/FullaPageLoader";

const SchedulerPage = () => {

  const dispatch = useAppDispatch();
  const currentDate = useAppSelector((state)=>state.schedulerView.dateScheduler)
  const currentView = useAppSelector((state)=>state.schedulerView.view)
  const currentDay = useAppSelector((state)=>state.schedulerView.day)
  const [date, setDate] = useState(currentDay !== null ? toDateFromApi(currentDay) : new Date());
  // const {
  //   isLoadingGetMonth,
  //   isfetchingGetMonth,
  //   isErrorGetMonth} = useCurrentMonthInfo()
  const [selectedResource, setSelectedResource] = useState<IStaff | undefined>(
    undefined
  );
  const group: SchedulerGroup = {
    resources: ["Staff"],
    orientation: "horizontal",
  };
  const [deleteAppointment, ] =
    useDeleteAppointmentMutation();

  const appointmentState = useCurrentAppointmentOpened();
  const [appDetailOpen, setAppDetailOpen] = useState<boolean>(true);
  const {
    data: currentAppointment ,
  } = useGetAppointmentQuery(appointmentState!==null? parseInt(appointmentState) : skipToken);


  const currentPlanner = useCurrentPlanner();
  const { colors } = useAppSelector((store) => store.types);
  const {
    data: calendarDay,
    isLoading,
    isError,
    isSuccess,
    isFetching,
    refetch,
  } = useGetCalendarDayQuery(
    {
      planner_id: currentPlanner?.id as number,
      date: dateToStringYYYYMMDD(date),
    },
    {
      skip: !currentPlanner || !date,
    }
  );



  useEffect(() => {
    if (isSuccess || isError || !isLoading) {
      if(currentView ==='day'){
        colorCells()
      }
    }
    // eslint-disable-next-line
  }, [calendarDay, isFetching,currentView]);

  const onDateChange = (e: SchedulerDateChangeEvent) => {
    refetch();
    setDate(e.value);
    dispatch(setSchedulerDay(dateToStringYYYYMMDD(e.value)))
  };


  const colorCells = () => {
    const cells = document.getElementsByClassName("k-heading-cell");

    const resourcesCells = Array.from(cells).slice(
      0,
      calendarDay?.staff?.length
    );
    resourcesCells.forEach((val, index) => {
      const color = colors.find(
        (t) => t.label === calendarDay?.staff[index].color
      )?.hex;
      val.setAttribute(
        "style",
        `background-color: ${color || "white"};cursor: pointer`
      );
      if (calendarDay?.staff[index])
        val.addEventListener(
          "click",
          () => detailResource(calendarDay?.staff[index]),
          false
        );
    });
  };
  const detailResource = (staff: IStaff) => {
    // console.log(staff);
    setSelectedResource(staff);
  };

  const handleDataChange = ({  deleted }: SchedulerDataChangeEvent) => {

    if(deleted && deleted[0] && deleted[0].id) {
      deleteAppointment(deleted[0].id);
      refetch();
    }
  };


  useEffect(()=>{
    if(currentDay !== null && dateToStringYYYYMMDD(date) !== currentDay){
      refetch();
      setDate(toDateFromApi(currentDay));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentDay])


  return (
    <div  className={`custom-unif-font-bold scheduler-container-page scheduler-view-${currentView}`}>
      {(isLoading || isFetching  )&&
      <FullPageLoader/>
      }
      {isLoading || isFetching  ? (
        <></>
      ) : !calendarDay?.staff ? (
        <Box
          display="flex"
          flex="1"
          justifyContent="center"
          alignItems="center"
          style={{ height: "50%" }}
        >
          <div>Scheduler ancora non disponibile </div>
        </Box>
      ) : (
        <Fragment>
          <Scheduler

            className="custom-unif-font-bold"
            height={"calc(98vh - 72px)"}
            slot={currentView==='day' ? CustomSchedulerSlot : CustomSchedulerSlotMonth} //celle custom
            header={CustomSchedulerHeader}
            footer={(props) => <Fragment />}
            defaultDate={currentView==='day' ? date : toDateFromApi(currentDate)}
            date={currentView==='day' ? date : toDateFromApi(currentDate)}
            onDateChange={onDateChange}
            group={currentView==='day'?group : undefined}
            view={currentView}
            resources={currentView==='day' ?[
              {
                name: "Staff",
                data: calendarDay?.staff
                  ? (calendarDay?.staff as IStaff[])
                  : [],
                field: "selected_staff_id",
                valueField: "id",
                textField: "initials",
                colorField: "color",
              },
            ]: []}
            modelFields={CustomSchedulerModelFields}
            timezone={"Europe/Rome"} 
            data={
              currentView==='day' ?
              calendarDay?.appointments.map((item: IAppointment) => ({

              ...item,
              start: new Date((item.start as string)),
              end: new Date((item.end as string)),
              startTimezone: "Europe/Rome",
              endTimeZone: "Europe/Rome",
            }))
                  :
                  []
            }
            //{new Date(currentDay)}

            item={ currentView==='day' ?  CustomSchedulerItem : undefined}
            editItem={ currentView==='day' ?CustomSchedulerEditItem : undefined}
            editable={{
              edit: true,
              drag: false,
              remove: true,
              resize: false,
              add: true,
              select: true,
            }}


            form={currentView==='day' ?  CustomSchedulerForm : undefined} //popup item form
            onDataChange={handleDataChange}

          // style={{ minWidth: "700px" }}
          // basato su https://www.telerik.com/kendo-react-ui/components/scheduler/customization/form/editor/#toc-provide-custom-editor
          >
            <DayView
              currentTimeMarker={true}
              // showWorkHours={false}
              numberOfDays={1}
              slotDuration={60}
              slotDivisions={4}
              startTime={calendarDay?.startTime || "8:00"}
              endTime={calendarDay?.endTime || "8:00"}
              workDayStart={calendarDay?.startTime || "8:00"}
              workDayEnd={calendarDay?.endTime || "8:00"}
            // per cambiare slot di working hour https://dojo.telerik.com/iWeqoQub
            />

            <MonthView
                title="Month"
                selectedDateFormat="{0:M}"
                selectedShortDateFormat="{0:M}"
            />
          </Scheduler>
          {selectedResource !== undefined && (
            <DetailResource
              staff={selectedResource}
              cancelEdit={() => {
                setSelectedResource(undefined);
                //refetch();
              }
              }
              dayClicked ={date}
              onModifyStaff={() => {
                refetch();
              }}
            />
          )}
        </Fragment>
      )}
      {appointmentState && currentAppointment && appDetailOpen &&
          <SchedulerDialogDetail appointment={currentAppointment} toggleDialog={()=>setAppDetailOpen(false)} />
      }
    </div>
  );
};

export default SchedulerPage;
