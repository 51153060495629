import React from "react";
import {Theme} from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

import EmailRegisterForm from "./EmailRegisterForm";
import PasswordRegisterForm from "./PasswordRegisterForm";

import {useAppSelector} from "../../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  })
);

export default function StepperRegister() {
  const classes = useStyles();
  const { step } = useAppSelector((store) => store.registration);

  return (
    <div className={classes.root+" change-password-dialog"}>
      <Stepper activeStep={step} orientation="vertical">
        <Step>
          <StepLabel>Scegliere email</StepLabel>
          <StepContent>
            <EmailRegisterForm />
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Crea password</StepLabel>
          <StepContent>
            <PasswordRegisterForm />
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
}
