import {api} from "./api";
import {IStaff} from "../../models/entities/IStaff";
import {IGetStaffsDto} from "../../models/dto/staff/IGetStaffsDto";
import {IGetStaffDto} from "../../models/dto/staff/IGetStaffDto";
import {IAddStaffDto} from "../../models/dto/staff/IAddStaffDto";
import {IUpdateStaffDto} from "../../models/dto/staff/IUpdateStaffDto";
import {IWeek} from "../../models/entities/IWeek";
import {IGetStaffWeeksDto} from "../../models/dto/staff/IGetStaffWeeksDto";

export const staffApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getStaffs: build.query<IStaff[], number>({
      query: (plannerId) => ({
        url: `staff`,
        params: { 'planner_id': plannerId }
      }),
      transformResponse: (res: IGetStaffsDto, meta) => res.staffs,
      providesTags: result => result ? [...result.map(r => ({ type: 'Staffs', id: r.id } as const)), { type: 'Staffs', id: 'LIST' }] : [{ type: 'Staffs', id: 'LIST ' }]
    }),
    getStaff: build.query<IStaff, number>({
      query: id => `staff/${id}`,
      transformResponse: (res: IGetStaffDto, meta) => res.staff,
      providesTags: (result, error, id) => [{ type: 'Staffs', id }]
    }),
    addStaff: build.mutation<IStaff, IStaff>({
      query: (staff) => ({
        url: 'staff',
        method: 'POST',
        body: staff
      }),
      transformResponse: (res: IAddStaffDto, meta) => res.staff,
      invalidatesTags: (res, error) => error ? [] : [{ type: 'Staffs', id: 'LIST' }],
    }),
    updateStaff: build.mutation<IStaff, { id: number, staff: IStaff }>({
      query: ({ id, staff }) => ({
        url: `staff/${id}`,
        method: 'PUT',
        body: staff
      }),
      transformResponse: (res: IUpdateStaffDto, meta) => res.staff,
      invalidatesTags: (result, error, { id }) => error ? [] : [{ type: 'Staffs', id }],
      async onQueryStarted({ id, ...staff }, { dispatch, queryFulfilled }) {
        const putResult = dispatch(
          staffApi.util.updateQueryData('getStaff', id, (draft) => {
            Object.assign(draft, staff)
          })
        )
        try {
          await queryFulfilled
        } catch {
          putResult.undo()
        }
        // queryFulfilled.catch(putResult.undo)
      },
    }),
    deleteStaff: build.mutation<void, number>({
      query: (id) => ({
        url: `staff/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => error ? [] : [{ type: 'Staffs', id }],
    }),
    getStaffWeeks: build.query<IWeek[], number>({
      query: id => `staff/${id}/weeks`,
      transformResponse: (res: IGetStaffWeeksDto, meta) => res.weeks,
      providesTags: result => result ? [...result.map(({ firstDayWeek }) => ({ type: 'StaffWeeks', id: firstDayWeek as string } as const)), { type: 'StaffWeeks', id: 'LIST' }] : [{ type: 'StaffWeeks', id: 'LIST' }],
    })
  })
})

export const {
  useGetStaffQuery,
  useGetStaffsQuery,
  useAddStaffMutation,
  useDeleteStaffMutation,
  useUpdateStaffMutation,
  useGetStaffWeeksQuery
} = staffApi