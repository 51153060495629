import {SchedulerEditItem, SchedulerEditItemHandle} from "@progress/kendo-react-scheduler";
import {CustomSchedulerForm} from "./CustomSchedulerForm";

import {useCallback, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store";
import {selectAppointment} from "../../store/calendar";
import {IAppointment} from "../../models/entities/calendar/IAppointment";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import {useGetExperiencesByStaffIdQuery} from "../../store/api/experiences";
import {IExperience} from "../../models/entities/IExperience";


export const CustomSchedulerEditItem = (props: any) => {

    const dispatch = useAppDispatch();


    const currentPlanner = useCurrentPlanner();
    const {
        data: experiencesByStaffId,
    } = useGetExperiencesByStaffIdQuery(
        { plannerId:  props.dataItem.planner_id as number, staffId: props.dataItem.staffId },
        { skip: !currentPlanner ||  props.dataItem.staffId === 0 }
    );

    const [CurrExpMonoLang, SetCurrExpMonoLang] = useState<boolean>(false);
    useEffect(() => {

        if (experiencesByStaffId) {
            const experience = (experiencesByStaffId as IExperience[]).find(
                (item: IExperience) => item.id ===  props.dataItem?.experience_id
            ) as IExperience;
            SetCurrExpMonoLang(experience.monolanguage);

        }
    }, [experiencesByStaffId]);



    const [dragItem, setDragItem] = useState(null); //blocca il drag and drop

    const [formItem, setFormItem] = useState(null);
    const ref = useRef<SchedulerEditItemHandle>(null);



    const handleFormItemChange = useCallback((event) => {

        setFormItem(event.value);
        if (event.value) {
            dispatch(selectAppointment(event.value.id));
        }
        else
            dispatch(selectAppointment(undefined));
    }, []);



    const handleDragItemChange = (event: any) => {
        //per limitare il draganddrop
        if (!event || !event.value)
            return;
        if (event.value.start < new Date()) //se si tenta di metterlo nel passato
            return;

        //TODO controllare che il personale sia disponibile ecc 
        //TODO provare a salvare

        //se tutto ok accetto lo spostamento
        setDragItem(event.value);
        //setDragItem(null);
    };



    const generateTitle = (dataItem: IAppointment) => {
        // console.log(currentAppointment);
        if (CurrExpMonoLang) {
            return <div style={{padding: "5px"}}>
                {dataItem.experience_name}
                <br></br>
                {"("}{dataItem.language} {", "}  {dataItem.number_partecipants} {"/"} {dataItem.experience_customers_max_number}{")"}
            </div>
        }else{
            return <div style={{padding: "5px"}}>
                {dataItem.experience_name} {" "}
                {dataItem.number_partecipants}/{dataItem.experience_customers_max_number }
            </div>
        }
    };

    return <SchedulerEditItem
        ref={ref}
        {...props}
        //onFocus={handleFocus}
        formItem={formItem}
        onFormItemChange={handleFormItemChange}
        title={generateTitle(props.dataItem)}
        dragItem={dragItem}
        onDragItemChange={handleDragItemChange}
        //onDoubleClick={handleDoubleClick}
        form={CustomSchedulerForm}
        children={generateTitle(props.dataItem)}


    />;
};