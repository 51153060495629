import {TimePicker} from "@progress/kendo-react-dateinputs";
import {Error} from "@progress/kendo-react-labels";
import React from "react";


const CustomTimePicker = (props: any) => {
  const { visited, validationMessage, onChange,...others } = props;



  return (
    <span>
      <TimePicker
         onChange={(e)=> {
           if (e.value) {
             //setDateTime(onChange(e));
             onChange(e);
           }
         }}
        //value={dateTime}
        {...others}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </span>
  );
};

export default CustomTimePicker;
