import { FieldValidatorType } from "@progress/kendo-react-form";

const regex = new RegExp(
  /^(https?:\/\/){0,1}?([\da-z.-]+)\.([a-z.]{2,6})([\w .-]*)*\/?$/
);

export const urlValidator: FieldValidatorType = (value: string) => {

    if(value==="" || value===undefined){
        return "";
    }else{
        return    regex.test(value) ? "" : "L'url non ha un formato corretto";
    }
}
