import {ICalendarDay} from "../../models/entities/calendar/ICalendarDay";
import {IAppointment} from "../../models/entities/calendar/IAppointment";
import {IGetCalendarDayDto} from "../../models/dto/calendar/IGetCalendarDayDto";
import {IGetAppointmentsDayDto} from "../../models/dto/calendar/IGetAppointmentsDayDto";
import {IGetAppointmentDto} from "../../models/dto/calendar/IGetAppointmentDto";
import {IAddAppointmentDto} from "../../models/dto/calendar/IAddAppointmentDto";
import {IUpdateAppointmentDto} from "../../models/dto/calendar/IUpdateAppointmentDto";
import {api} from "./api";
import {ApiAppointmentExtended, IGetDaysDto, IGetDaySlotsDto} from "../../models/dto/calendar/IGetDaysDto";
import {IResultDto} from "../../models/dto/IResultDto";
import {IGetMonthInfoDto} from "../../models/dto/calendar/IGetMonthInfoDto";

export const calendarApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getCalendarDay: build.query<
      ICalendarDay,
      { planner_id: number; date: string }
    >({
      query: ({ planner_id, date }) => ({
        url: `appointment/${planner_id}/${date}`,
        // params: { 'planner_id': planner_id, 'date': date }
      }),
      transformResponse: (baseQueryReturnValue: IGetCalendarDayDto, meta) => ({
        appointments: baseQueryReturnValue.appointments,
        staff: baseQueryReturnValue.staff,
        startTime: baseQueryReturnValue.startTime,
        endTime: baseQueryReturnValue.endTime,
      }),
      providesTags: (result, error, id) => [
        { type: "CalendarDay", id: "LIST" },
      ],
    }),
    getAppointmentsDay: build.query<
      IAppointment[],
      { planner_id: number; date: string }
    >({
      query: ({ planner_id, date }) => ({
        url: "appointment",
        params: { planner_id: planner_id, date: date },
      }),
      transformResponse: (baseQueryReturnValue: IGetAppointmentsDayDto, meta) =>
        baseQueryReturnValue.appointments,
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }) => ({ type: "Appointments", id } as const)
              ),
              {
                type: "Appointments",
                id: "LIST",
              },
            ]
          : [{ type: "Appointments", id: "LIST" }],
    }),
    getAppointment: build.query<IAppointment, number>({
      query: (id) => `appointment/${id}`,
      transformResponse: (baseQueryReturnValue: IGetAppointmentDto, meta) =>
        baseQueryReturnValue.appointment,
      providesTags: (result, error, id) => [{ type: "Appointments", id }],
    }),

    getAppointmentPossibleDays: build.query<IGetDaysDto, number >({
      query: (id : number) => ({
        url: `subscriber/${id}/get-days`,
        method: "get",
      }),
      transformResponse: (res: IGetDaysDto, meta) =>
        res,
      providesTags: (result, error, id) => [{ type: "PossibleDays", id: 'posDays' }],
      // async onQueryStarted(...args) {
      //   calendarApi.invalidatesTags({ type: "PossibleDays", id: 'posDays' })
      // }
    }),
    invalidateAppointmentPossibleDays: build.mutation<IGetDaysDto, number >({
      query: (id : number) => ({
        url: `subscriber/${id}/get-days`,
        method: "get",
      }),
      transformResponse: (res: IGetDaysDto, meta) =>
        res,
      invalidatesTags: () =>  [{ type: "PossibleDays", id: "posDays" }],
    }),
    getAppointmentPossibleSlots: build.query<IGetDaySlotsDto, { id: number, day: string }>({
      query:({id, day}) => ({
        url: `subscriber/${id}/get-slots/${day}`,
        method: "get",
      }),
      transformResponse: (res: IGetDaySlotsDto, meta) =>
          res,
      // providesTags: (result, error, id) => [{ type: "PossibleSlots", id }],
    }),
    setNewDate: build.mutation<IResultDto, { id: number, appointment: ApiAppointmentExtended }>({
      query: ({id, appointment}) => ({
        url: `subscriber/set-new-date/${id}`,
        method: "put",
        body: {
          appointment: appointment
        }
      }),
      transformResponse: (res: IResultDto, meta) =>
          res,
      invalidatesTags: [{ type: 'Subscribers'}]
      // providesTags: (result, error, id) => [{ type: "PossibleSlots", id }],
    }),

    addAppointment: build.mutation<
      ICalendarDay & { appointment: IAppointment },
      { appointment: IAppointment; selectedDate: string }
    >({
      query: ({ selectedDate, appointment }) => ({
        url: `appointment`,
        method: "POST",
        body: appointment,
        params: { date: selectedDate },
      }),
      transformResponse: (res: IAddAppointmentDto, meta) => ({
        appointment: res.appointment,
        appointments: res.appointments,
        staff: res.staff,
        startTime: res.startTime,
        endTime: res.endTime,
      }),
      // invalidatesTags: (result, error, res) =>
      //   error ? [] : [{ type: "Appointments", id: res.appointment.id },{ type: "CalendarDay", id: "LIST" }],
    }),
    updateAppointment: build.mutation<
      ICalendarDay & { appointment: IAppointment },
      { id: number; appointment: IAppointment; selectedDate: string }
    >({
      query: ({ id, selectedDate, ...appointment }) => ({
        url: `appointment/${id}`,
        method: "PUT",
        body: appointment.appointment,
        params: { date: selectedDate },
      }),
      transformResponse: (res: IUpdateAppointmentDto, meta) => ({
        appointment: res.appointment,
        appointments: res.appointments,
        staff: res.staff,
        startTime: res.startTime,
        endTime: res.endTime,
      }),
      // invalidatesTags: (result, error, { id }) =>
      //   error ? [] : [{ type: "Appointments", id },{ type: "CalendarDay", id: "LIST" }],
      //providesTags: (result, error, id) => [{ type: 'CalendarDay', id : 'LIST'}],
    }),
    deleteAppointment: build.mutation<void, number>({
      query: (id) => ({
        url: `appointment/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) =>
        error ? [] : [{ type: "Appointments", id }],
    }),
    getMonthInfo: build.query<IGetMonthInfoDto, { planner_id: number; date: string }>({
      query: ({ planner_id, date }) => ({
        url: `appointment/${planner_id}/month-info/${date}`,
        params: { planner_id: planner_id, date: date },
      }),
      transformResponse: (baseQueryReturnValue: IGetMonthInfoDto, meta) => baseQueryReturnValue,
      providesTags: (result, error, id) => error ?  [] :[
        { type: "SchedulerMonth", id: "MONTH" },
      ],
    }),
  }),
});

export const {
  useGetCalendarDayQuery,
  useGetAppointmentsDayQuery,
  useGetMonthInfoQuery,
  useGetAppointmentPossibleDaysQuery,
  useLazyGetAppointmentPossibleDaysQuery,
  useInvalidateAppointmentPossibleDaysMutation,
  useGetAppointmentPossibleSlotsQuery,
  useGetAppointmentQuery,
  useAddAppointmentMutation,
  useSetNewDateMutation,
  useUpdateAppointmentMutation,
  useDeleteAppointmentMutation,
} = calendarApi;
