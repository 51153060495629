import {isRejectedWithValue, Middleware, MiddlewareAPI} from "@reduxjs/toolkit";
import {api} from "store/api/api";
import {authApi} from "../api/auth";

const logoutMiddleware: Middleware =
  ({dispatch}: MiddlewareAPI) => (next) => (action) => {

    if (isRejectedWithValue(action)) {
    
      if (action?.meta?.arg?.endpointName==="autoLogin") {
        localStorage.clear();      
      }
      if (action.payload.status === 401) {
        // const [logout] = useLogoutMutation()
        // logout();
        localStorage.clear();
        // @ts-ignore
        dispatch(authApi.endpoints.logout.initiate(), { track: false })
        dispatch(api.util.resetApiState())
      }
    }

    return next(action);
  };

export default logoutMiddleware;