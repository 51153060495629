import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import BaseButton from "./BaseButton";

export interface IAlertDialog {
  toggleDialog: () => void;
  onConfirm: () => void;
}

const AlertDialog = (props: IAlertDialog) => {
  return (
      <Dialog
          title={"Formato del file non valido!"}
          onClose={props.toggleDialog}
          style={{ zIndex: 1301 }}

      >
        <p style={{ margin: "25px", textAlign: "center" }}>
          I file accettati sono solo i .pdf , .docx , .jpeg e i .jpg
        </p>
        <DialogActionsBar>
          <BaseButton onClick={props.toggleDialog}>Ok</BaseButton>

        </DialogActionsBar>
      </Dialog>
  );
};


export default AlertDialog;
