import {api} from "./api";
import {IEquipment} from "../../models/entities/IEquipment";
import {IGetEquipmentsDto} from "../../models/dto/equipments/IGetEquipmentsDto";
import {IGetEquipmentDto} from "../../models/dto/equipments/IGetEquipmentDto";
import {IAddEquipmentDto} from "../../models/dto/equipments/IAddEquipmentDto";
import {IUpdateEquipmentDto} from "../../models/dto/equipments/IUpdateEquipmentDto";

const equipmentsApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getEquipments: build.query<IEquipment[], number>({
      query: (id) => ({
        url: "equipment",
        params: { planner_id: id },
      }),
      transformResponse: (baseQueryReturnValue: IGetEquipmentsDto, meta) =>
        baseQueryReturnValue.equipments,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Equipments", id } as const)),
              {
                type: "Equipments",
                id: "LIST",
              },
            ]
          : [{ type: "Equipments", id: "LIST" }],
    }),
    getEquipment: build.query<IEquipment, number>({
      query: (id) => `equipment/${id}`,
      transformResponse: (baseQueryReturnValue: IGetEquipmentDto, meta) =>
        baseQueryReturnValue.equipment,
      providesTags: (result, error, id) => [{ type: "Equipments", id }],
    }),
    addEquipment: build.mutation<IEquipment, IEquipment>({
      query: (equipment) => ({
        url: `equipment`,
        method: "POST",
        body: equipment,
      }),
      transformResponse: (res: IAddEquipmentDto, meta) => res.equipment,
      invalidatesTags: (result, error)  => error ? [] : [{ type: "Equipments", id: "LIST" }],
    }),
    updateEquipment: build.mutation<
      IEquipment,
      { id: number; equipment: IEquipment }
    >({
      query: ({ id, equipment }) => ({
        url: `equipment/${id}`,
        method: "PUT",
        body: equipment,
      }),
      transformResponse: (res: IUpdateEquipmentDto, meta) => res.equipment,
      invalidatesTags: (result, error, { id }) =>  error ? [] : [{ type: "Equipments", id }],
    }),
    deleteEquipment: build.mutation<void, number>({
      query: (id) => ({
        url: `equipment/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id)  =>  error ? [] :  [{ type: "Equipments", id }],
    }),
  }),
});

export const {
  useGetEquipmentQuery,
  useGetEquipmentsQuery,
  useAddEquipmentMutation,
  useDeleteEquipmentMutation,
  useUpdateEquipmentMutation,
} = equipmentsApi;
