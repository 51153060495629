import {Box, Grid} from "@mui/material";
import {Dialog} from "@progress/kendo-react-dialogs";
import {Field, Form, FormElement} from "@progress/kendo-react-form";
import React, {Fragment, useState} from "react";
import {IPlanner} from "../../models/entities/IPlanner";
import CustomTitleBar from "../common/CustomTitleBar";
import {useAppSelector} from "../../store";
import {requireValidator} from "../../utils/validators/requireValidator";
import {ValidatedDropDownListPrenTypes, ValidatedInput, ValidatedSwitch,} from "../common/ValidatedFields";
import {Error} from "@progress/kendo-react-labels";
import BaseButton from "components/common/BaseButton";
import {useAddPlannerMutation} from "store/api/planners";
import {MultiSelectChangeEvent} from "@progress/kendo-react-dropdowns";


export interface AddPlannerProps {
  opened: boolean;
  closeForm: () => void;
}
const AddPlanner = (props: AddPlannerProps) => {
  const [planner, ] = useState<IPlanner>({
    name: "",
    booking_mode: "slot",
    notifications_flag: true,
    description: "",
  });

  const { user } = useAppSelector((store) => store.auth);

  const [addPlanner, { isLoading: isAdding }] = useAddPlannerMutation();

  const { prenotationtypes } = useAppSelector((store) => store.types);
  const [valuePrenotationT, setValuePrenotationT] = useState(planner.booking_mode);
  const handleSubmit = (planner: IPlanner) => {
    planner.company_id = user?.companyId;
    planner.booking_mode = valuePrenotationT;
    addPlanner(planner);
  };

  const handlePTypeChange= (event: MultiSelectChangeEvent) => {
    setValuePrenotationT(event.value.key);
  };

  return (
    <Fragment>
      {props.opened && (
        <Dialog
            className={"add-planner-dialog card-dialog"}
          onClose={props.closeForm}
          title={
            <CustomTitleBar
              type={"Aggiungi agenda"}
              label={""}
              icon="local_library"
            />
          }

          height={"52vh"}
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={planner}
            render={(formRenderProps) => (
              <FormElement full-width="true">
                <Grid container alignItems="stretch"  spacing={3}>
                    <Grid item xs={12} className="rows-grandi">
                      <Field
                        name={"name"}
                        required
                        component={ValidatedInput}
                        label={"Nome"}
                        value={planner.name}
                        validator={[requireValidator]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name={"booking_mode"}
                        required
                        component={ValidatedDropDownListPrenTypes}
                        onChange={handlePTypeChange}
                        data={prenotationtypes}
                        value={valuePrenotationT}
                        label={"Prenotazione da nowmyplace.com"}
                        // validator={[requireValidator]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name={"description"}
                        multiline
                        rows={2}
                        value={planner.description}
                        component={ValidatedInput}
                        label={"Descrizione"}
                        validator={[]}
                      />
                    </Grid>
                  <Grid container item xs={12} spacing={3}></Grid>
                </Grid>
                <Grid
                  container
                  item
                  sm={6}
                  xs={12}
                  alignItems="flex-end"
                  style={{ marginTop: 10 }}
                >
                  <Field
                    name={"notifications_flag"}
                    id={"notifications_flag"}
                    value={planner.notifications_flag}
                    component={ValidatedSwitch}
                    label={"Notifiche ai clienti"}
                  />
                </Grid>
                <Box
                  display="flex"
                  flexDirection="row"
                  p={3}
                  justifyContent="center"
                >
                  <BaseButton
                    type="submit"
                    variant="contained"

                    className={`std-button  ${!(!formRenderProps.allowSubmit || isAdding) ? "custom-unif-viola-back" : ""}`}
                    disabled={!formRenderProps.allowSubmit || isAdding}
                  >
                    Salva
                  </BaseButton>
                  <BaseButton
                      className={"std-button  custom-unif-arancione-back left-spacing-10p"}
                      onClick={props.closeForm}
                      // () =>
                      //   window.confirm('Tutte le modifiche andranno perse, \n sei sicuro di voler uscire?') && props.cancelEdit }
                  >
                    Annulla
                  </BaseButton>
                  {formRenderProps.visited &&
                    formRenderProps.errors &&
                    formRenderProps.errors.VALIDATION_SUMMARY && (
                      <div className={"k-messagebox k-messagebox-error"}>
                        <Error>
                          {formRenderProps.errors.VALIDATION_SUMMARY}
                        </Error>
                      </div>
                    )}
                </Box>
              </FormElement>
            )}
          />
        </Dialog>
      )}

      {/* <ResponseSnackbar
        responseSnackbar={[
          {
            sliceState: addPlannerState,
            resetFunction: resetAddPlanner() as any,
          } as IResponseSnackbar,
        ]}
      /> */}
    </Fragment>
  );
};

export default AddPlanner;
