import {Dialog} from "@progress/kendo-react-dialogs";
import {Field, Form, FormElement} from "@progress/kendo-react-form";

import {Error} from "@progress/kendo-react-labels";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import {ISubscriber} from "../../models/entities/calendar/ISubscriber";
import Grid from "@mui/material/Grid";
import {ValidatedEmail, ValidatedInput, ValidatedMobile} from "../common/ValidatedFields";
import {requireValidator} from "../../utils/validators/requireValidator";
import {emailValidator} from "../../utils/validators/emailValidator";
import CustomTitleBar from "../common/CustomTitleBar";
import BaseButton from "components/common/BaseButton";
import {Box, Divider, TextField, Tooltip, Typography,} from "@mui/material";
import React, {Fragment, useEffect, useState} from "react";
import {useCurrentAppointment} from "../../utils/hooks/currentAppointment";
import {useGetExperienceQuery} from "../../store/api/experiences";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {fromPeriodToDate} from "../../utils/dateHelper";

import {useAddSubscriberMutation,} from "store/api/subscribers";
import {useCurrentSubscriber} from "utils/hooks/currentSubscriber";
import {IExperience} from "../../models/entities/IExperience";
import {ISubscriberPrice} from "../../models/entities/calendar/ISubscriberPrice";
import {IPrice} from "../../models/entities/prices/IPrice";
import {round} from "lodash";
import InfoIcon from "@mui/icons-material/Info";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import {phoneNumberValidator} from "../../utils/validators/phoneNumberValidator";
import {NazionalitaDropdown} from "../common/NazionalitaDropdown";
import {useAppSelector} from "../../store";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SaveIcon from "@mui/icons-material/Save";
import AlertMessageAppointmentDialog from "../common/AlertMessageAppointmentDialog";

const AddSubscriberForm = (props: EditFormProps<ISubscriber>) => {
  const { currentAppointment } = useCurrentAppointment();
  const {  countries } = useAppSelector((store) => store.types);
  const { data: experience } = useGetExperienceQuery(
    currentAppointment?.experience_id ?? skipToken
  );

  const [isPhoneOrEmail, setIsPhoneOrEmail] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const [phoneValid, setPhoneValid] = useState<boolean>(false);
  const [mailValid, setMailValid] = useState<boolean>(false);

  const handleChangePhone = (e : any) =>{

    if (e.target.value !== ""){
      setPhoneValid(true);
      setIsPhoneOrEmail(mailValid || true);
    }else{
      setPhoneValid(false);
      setIsPhoneOrEmail(mailValid || false);
    }
  }
  const handleChangeMail = (e : any) =>{

    if (e.target.value !== ""){
      setMailValid(true);
      setIsPhoneOrEmail(phoneValid || true);
    }else{
      setMailValid(false);
      setIsPhoneOrEmail(phoneValid || false);
    }
 }


  const [addSubscriber, { isLoading: isAdding, isSuccess }] =
    useAddSubscriberMutation();
 useCurrentSubscriber();

  const [formSubscriber, setFormSubscriber] = useState<ISubscriber>(
    {} as ISubscriber
  );

  useEffect(() => {
    if (experience && experience.prices !== undefined) {
      setFormSubscriber({
        ...formSubscriber,
        prices: setPrices(experience),
      });
    }else{

    }
  }, [experience]);

  useEffect(() => {

    if (isSuccess && experience) {
      setFormSubscriber({
        id: undefined,
        email: "",
        name: "",
        phone: "",
        language: "",
        prices: setPrices(experience),
      } as ISubscriber);
      props.cancelEdit();
    }
  }, [isSuccess]);

  const setPrices = (experience: IExperience): ISubscriberPrice[] => {
    if (experience.price_type === "fisso") {
      const experiencePrice = experience?.prices[0];
      return [
        {
          ...experiencePrice,
          id: undefined,
          subscribe_number: 0,
          price_id: experiencePrice?.id as number,
          experience_id: experience.id as number,
        },
      ];
    } else if (experience.price_type === "periodo") {
      const experiencePrice = experience?.prices?.find((p) => {
        const appointmentStart = new Date(currentAppointment?.start as string);
        const appointmentEnd = new Date(currentAppointment?.end as string);
        let startPrice = fromPeriodToDate(p.date_start as string);
        startPrice.setFullYear(appointmentStart.getFullYear());
        let endPrice = fromPeriodToDate(p.date_end as string);
        endPrice.setFullYear(appointmentEnd.getFullYear());

        return appointmentStart >= startPrice && appointmentEnd <= endPrice;
      }) as IPrice;
        const prezzi = experience.prices.map((p) => ({
            ...p,
            id: undefined,
            subscribe_number: 0,
            price_id: p.id as number,
            experience_id: experience.id as number,
        }));
      return prezzi;
    } else {
      return experience.prices.map((p) => ({
        ...p,
        id: undefined,
        subscribe_number: 0,
        price_id: p.id as number,
        experience_id: experience.id as number,
      }));
    }
  };

  const [ showErrorPart, setShowErrorPart] = useState<boolean>(false);
  const handleSubmit = (event: ISubscriber) => {
      let total =
          event.prices
              ?.map((pr) => pr.subscribe_number)
              .reduce((acc, curr) => acc + curr) || 0;



    if(isPhoneOrEmail && total !== 0) {
        setShowErrorPart(false);

        setShowError(false);
        event.appointment_id = currentAppointment?.id as number;

        addSubscriber(event);

        props.onSubmit(event);
    }else{
        if(total===0){
            setShowErrorPart(true);
        }else {
            setShowError(true);
        }
    }

  };

  const totalSubscribers =
    formSubscriber.prices
      ?.map((pr) => pr.subscribe_number)
      .reduce((acc, curr) => acc + curr) || 0;
  const totalPrice =
    formSubscriber.prices
      ?.map((pr) => pr.subscribe_number * Math.round(pr.price))
      .reduce((acc, curr) => acc + curr) || 0;

  const currentDayIndex =
    new Date(currentAppointment?.start as string).getDay() === 0
      ? 7
      : new Date(currentAppointment?.start as string).getDay();

  const dayDiscount =
    // @ts-ignore
    (experience && experience[`discount_day${currentDayIndex}`]) || 0;

  const groupDiscount =
    experience?.group_discount_number &&
    totalSubscribers >= experience?.group_discount_number
      ? experience?.group_discount
      : 0;

  const appliedDiscount = (1 - dayDiscount / 100) * (1 - groupDiscount / 100);

//   useEffect(()=>{
//     // console.log("-------------v qui");
//     console.log(formSubscriber.prices
//         );
//   },[]
// )


    let [totalSubbedNumber, setTotalSubbedNumber] = useState(0);
    useEffect(()=>{
        if(currentAppointment){
            let totale = 0;
            if( currentAppointment.subscribers !== undefined ) {
                currentAppointment.subscribers.map((item: ISubscriber) => {
                    totale = totale + item.total_number;
                });
            }
            setTotalSubbedNumber(totale);

        }
    },[currentAppointment]);



  return (
      (experience?.prices === undefined) ?
      <AlertMessageAppointmentDialog

          toggleDialog={ props.cancelEdit}
          onConfirm={ props.cancelEdit}
      />
          :
    <Dialog
      title={<CustomTitleBar type={""} label={"Nuova iscrizione"} icon="people" />}
      onClose={props.cancelEdit}
      className={"dialog-sub"}
      style={{textAlign:"center"}}
    >
      <Form
        onSubmit={(e) => {
          handleSubmit(formSubscriber);
        }}
        render={(formRenderProps) => (
            <FormElement>

              {/*<legend*/}
              {/*    className={"k-form-legend"}*/}
              {/*    style={{ backgroundColor: "black" }}*/}
              {/*></legend>*/}
              <Grid container alignItems="stretch" spacing={2}>


                  <Grid item xs={12} sm={12} md={6} lg={3} className={"rows-grandi"}>
                  <Field
                      required
                      name={"name"}
                      component={ValidatedInput}
                      label={"Nome e cognome"}
                      onChange={(event) => {
                        setFormSubscriber({
                          ...formSubscriber,
                          name: event.target.value,
                        });
                      }}
                      validator={[requireValidator]}
                  >
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} className={"rows-grandi"}>
                  <Field
                      name={"phone"}
                      component={ValidatedMobile}
                      label={"Telefono"}
                      onChange={(event) => {
                        handleChangePhone(event);
                        setShowError(false);
                        setFormSubscriber({
                          ...formSubscriber,
                          phone: event.target.value,
                        });
                      }}
                      validator={[ phoneNumberValidator]}
                  >
                  </Field>
                    {showError && <Error>{"E' necessario inserire il telefono o la mail"}</Error>}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} className={"rows-grandi"}>

                  <Field
                      name={"email"}
                      component={ValidatedEmail}
                      label={"Email"}
                      onChange={(event) => {
                        handleChangeMail(event);
                          setShowError(false);
                        setFormSubscriber({
                          ...formSubscriber,
                          email: event.target.value,
                        });
                      }}
                      validator={[ emailValidator]}
                  >
                  </Field>
                    {showError && <Error>{"E' necessario inserire il telefono o la mail"}</Error>}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} className={"custom-unif-font"}>
                  {/*<Field*/}
                  {/*    name={"language"}*/}
                  {/*    component={ValidatedInput}*/}
                  {/*    label={"Nazionalità"}*/}
                  {/*    onChange={(event) => {*/}
                  {/*      setFormSubscriber({*/}
                  {/*        ...formSubscriber,*/}
                  {/*        language: event.target.value,*/}
                  {/*      });*/}
                  {/*    }}*/}
                  {/*>*/}
                  {/*</Field>*/}
                  <Field
                      name={"language"}
                      label={"Nazionalità"}
                      data={countries}
                      value={countries[0].label}
                      onChange={(e) => {
                        setFormSubscriber({
                          ...formSubscriber,
                          country: e.value,
                        });
                      }}
                      // itemRender={countriesItemRender}
                      component={NazionalitaDropdown}

                  />
                </Grid>
                  {/*SPAZIO TRA RIGHE*/}
                  <Grid container item xs={12}></Grid>
                <Grid container >
                  {formSubscriber?.prices?.map((p, i) => (
                      <Box
                          key={`p-${p?.price_id}-${p.experience_id}`}
                          display={"flex"}
                          flexDirection={"row"}
                          paddingBottom={1}
                          style={{width: "100%"}}
                      >
                        {experience?.price_type === "partecipante" && (
                            <Grid item xs={12} sm={12} md={6} lg={3} >
                                <Typography
                                    className={"labelina-fasce-prezzo"}
                                    align={"right"}
                                    variant="h5"
                                >
                                    { p.price_label_type?.trim() + " (" + p.age_min  + " - " + p.age_max + " anni), "+  Math.round(parseFloat((p.price).toString())).toFixed(0) + " €"}
                                </Typography>
                              {/*<TextField*/}
                              {/*    fullWidth*/}
                              {/*    disabled={true}*/}
                              {/*    variant={"outlined"}*/}
                              {/*    label={"Fascia di età"}*/}
                              {/*    className={"content-padding-left"}*/}
                              {/*    // InputProps={{*/}
                              {/*    //   readOnly: true,*/}
                              {/*    //   tabIndex: -1,*/}
                              {/*    //   endAdornment: (*/}
                              {/*    //       <InputAdornment position="end">*/}
                              {/*    //         anni*/}
                              {/*    //       </InputAdornment>*/}
                              {/*    //   )*/}
                              {/*    // }}*/}
                              {/*    tabIndex={-1}*/}
                              {/*    value={`${p.price_label_type?.trim()} ${(p.price_label_type?.trim() === "Over") ? p.age_min : ''}`}*/}
                              {/*/>*/}
                            </Grid>
                        )}
                          {(experience?.price_type !== "partecipante") ?
                              <Grid item xs={12} sm={12} md={6} lg={3} >
                                  <Typography
                                      className={"labelina-fasce-prezzo"}
                                      align={"right"}
                                      variant="h5"
                                  >
                                      {
                                              "Prezzo " + Math.round(parseFloat(String(p.price))).toFixed(0) + " €"
                                      }
                                  </Typography>

                                  {/*<TextField*/}
                                  {/*    fullWidth*/}
                                  {/*    disabled={true}*/}
                                  {/*    variant={"outlined"}*/}
                                  {/*    className={"content-padding-left"}*/}
                                  {/*    label={"Prezzo"}*/}
                                  {/*    InputProps={{*/}
                                  {/*      readOnly: true,*/}
                                  {/*      endAdornment: (*/}
                                  {/*          <InputAdornment position="end">*/}
                                  {/*            euro*/}
                                  {/*          </InputAdornment>*/}
                                  {/*      )*/}
                                  {/*    }}*/}
                                  {/*    tabIndex={-1}*/}
                                  {/*    value={parseFloat(String(p.price)).toFixed(2)}*/}
                                  {/*/>*/}

                              </Grid> : ""}
                        <Grid  item xs={12} sm={12} md={6} lg={3} style={{margin:"10px"}} className={"container-input-fasce-prezzo"}>
                          <TextField
                              fullWidth
                              variant={"outlined"}
                              type={"number"}
                              className={"content-padding-left"}
                              label={"Pax"}
                              value={p.subscribe_number}

                              onWheel={event => { event.preventDefault(); }}
                              inputProps={{
                                min: 0 ,
                              }}
                              onChange={(event) => {
                                  if(event.target.value) {
                                      setTotalSubbedNumber(totalSubbedNumber +
                                          (parseInt((event.target.value !== "" && event.target.value) ? event.target.value : "0") - p.subscribe_number)
                                      )
                                      setShowErrorPart(parseInt(event.target.value) === 0 &&
                                          (formSubscriber.prices
                                              ?.map((pr) => pr.subscribe_number)
                                              .reduce((acc, curr) => acc + curr) || 0) === 0
                                      );
                                      setFormSubscriber({
                                          ...formSubscriber,
                                          prices: formSubscriber.prices?.map((pr) => {
                                              if (p.price_id === pr.price_id) {
                                                  return {
                                                      ...pr,
                                                      subscribe_number: parseInt(event.target.value),
                                                  };
                                              }
                                              return pr;
                                          }),
                                      });
                                  }


                              }}

                          />
                        </Grid>
                      </Box>
                  ))}
                </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} >
                          <Field
                              name={"note"}
                              label={"Note relative all'iscrizione"}
                              onChange={(e) => {
                                  setFormSubscriber({
                                      ...formSubscriber,
                                      note: e.target.value,
                                  });
                              }}
                              component={ValidatedInput}
                              // value={appointment.location}

                              validator={[]}
                          />

                      </Grid>
                <Divider />
                <Grid

                    item xs={12}
                    style={{textAlign:"left"}}
                >
                  <Box>
                    <Typography variant={"h6"}>
                      Partecipanti:
                      {" " + totalSubscribers}
                        <br></br>
                      {" Posti rimanenti: "+ ((currentAppointment?.experience_customers_max_number !== undefined)?
                          ((currentAppointment.experience_customers_max_number) as number) -  totalSubbedNumber : 0)}
                    </Typography>
                    <Typography variant={"h6"}>
                      Importo totale: {" "}
                      {appliedDiscount !== 1 ? (
                          <Fragment>

                            {(round(totalPrice).toFixed(2) !== round(totalPrice * appliedDiscount).toFixed(2) )?
                                (
                                    <del>{round(totalPrice).toFixed(2)}</del>
                                ): ""}

                            {` ${ round(totalPrice * appliedDiscount).toFixed(2)}`}
                          </Fragment>
                      ) : (
                          <span>{" " +totalPrice}</span>
                      )}
                      {" "}&euro;{" "}

                      <Tooltip style={{marginBottom: "-5px"}}
                               className={'tooltip-prezzi'}
                          title={
                            <Fragment>
                              <div>Sconti applicati:</div>
                              <div>Sconto per giorno: {dayDiscount} %</div>
                              <div>Sconto per gruppo: {groupDiscount} %</div>
                            </Fragment>
                          }
                      >
                        <InfoIcon />
                      </Tooltip>
                    </Typography>
                  </Box>
                  {/* <Box>
                <Tooltip title={
                  <Fragment>
                    <div>Sconti applicati:</div>
                    <div>Sconto per giorno: {dayDiscount} %</div>
                    <div>Sconto per gruppo: {groupDiscount} %</div>
                  </Fragment>
                }>
                  <InfoIcon />
                </Tooltip>

              </Box> */}
                </Grid>
              </Grid>

                {/*SPAZIO TRA RIGHE*/}
                <Grid container item xs={12}></Grid>
              <Grid item xs={12}  style={{margin:"10px"}}></Grid>
                  {showErrorPart && <Error>Inserire almeno un partecipante</Error>}
              <Grid container justifyContent="center" style={{marginBottom: "2vh"}}>
                  <div className="k-form-buttons">
                {isAdding ? (
                    <CenteredLoadingSpinner />
                ) : (
                    <Fragment>
                      <BaseButton
                          className={`${
                              formRenderProps.allowSubmit 
                              // && isPhoneOrEmail
                                  ? "custom-unif-viola-back"
                                  : ""
                          }`}
                          type={"submit"}
                          variant="contained"
                          disabled={!formRenderProps.allowSubmit
                          // || !isPhoneOrEmail
                          }
                          startIcon={<SaveIcon />}
                      >
                        Salva
                      </BaseButton>
                      {props.hasCancel && (
                          <BaseButton
                              onClick={props.cancelEdit}
                              className={`custom-button-annulla custom-unif-grigio-back left-spacing-10p ${
                                  formRenderProps.allowSubmit 
                                  // && isPhoneOrEmail
                                      ? "custom-unif-arancione-back"
                                      : ""
                              }`}
                              startIcon={<CancelPresentationIcon />}
                              disabled={!formRenderProps.allowSubmit
                              // || !isPhoneOrEmail
                              }
                          >
                            Annulla</BaseButton>
                      )}
                    </Fragment>
                )}
                {/*{formRenderProps.visited &&*/}
                {/*formRenderProps.errors &&*/}
                {/*formRenderProps.errors.VALIDATION_SUMMARY && (*/}
                {/*  <div className={"k-messagebox k-messagebox-error"}>*/}
                {/*    <Error>{formRenderProps.errors.VALIDATION_SUMMARY}</Error>*/}
                {/*  </div>*/}
                {/*)}*/}
                  </div>
              </Grid>
            </FormElement>
        )}
      >
      </Form>
    </Dialog>
  );
};
export default AddSubscriberForm;
