import * as React from "react";
import {useState} from "react";
import {FieldRenderProps} from "@progress/kendo-react-form";
import AlertDialog from "components/common/AlertDialog";
import {
    Upload,
    UploadFileInfo,
    UploadHttpHeaders,
    UploadListItemProps,
    UploadOnAddEvent,
    UploadOnBeforeRemoveEvent,
    UploadOnBeforeUploadEvent,
    UploadOnProgressEvent,
    UploadOnStatusChangeEvent,
} from "@progress/kendo-react-upload";
import {Error} from "@progress/kendo-react-labels";
import {apiPath} from "../../store/api/api";
import toast from "react-hot-toast";
import {IntlProvider, loadMessages, LocalizationProvider,} from "@progress/kendo-react-intl";
import bgMessages from "./../../globalization/UploadComponent.json";
import {Icon} from "@mui/material";
import {useWindowSize} from "./ScreenSizes";

loadMessages(bgMessages, "it-IT");


/**
 * FILE UPLOADER
 * Al suo rendering recupera i dati dei file esistenti per un dato Company_id
 * Se la company è in fase di creazione genera un id temporaneo.
 * Ad ogni caricamento file -> chiamata alle api /upload con
 *      - id file ( temporaneo se appena caricato, risposta con id file standard che sostituisce il temporaneo)
 *      - Company id (temporaneo o no)
 * Click sulla X  -> chiamata alle api /remove
 *      - con id file
 *
 * Non è possibile chiudere la dialog se il file è in fase di caricameno, solo ad upload terminato.
 *
 * le modifiche non sono permanenti, solo se in company form viene premuto salva allora upload/delete salvati.
 */
export function FileUploaderSingle (fieldRenderProps: FieldRenderProps)  {

    const {validationMessage, visited,  company_id, temp_company_id,  type, disabled,  } = fieldRenderProps;


    const token = localStorage.getItem("token");
    const get_path = apiPath + "reseller/"+type+"/getFile/";
    const remove_path = apiPath+"reseller/"+type+"/remove";
    const upload_path = apiPath + "reseller/"+type+"/upload";
    const [alertDialog, setAlertDialog] = useState(false);

    const header_get =  {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }

    const header_upload = {
        'Authorization' : `Bearer ${token}`,

    };


    const [files, setFiles] = React.useState<Array<UploadFileInfo>>([]);
    const [, setFileStatus] = React.useState(0);

    const [, setAffectedFiles] = React.useState<Array<UploadFileInfo>>([]);
    const [, setIsOnProgress] = React.useState<boolean>(false);



    //FETCH DEI FILE
    React.useEffect(() => {
        if(company_id!== null && company_id !== undefined) {
            let optObjHeadBody = {
                method: 'GET',
                headers:header_get,

            };
            fetch(get_path + company_id, optObjHeadBody)
                .then(res => res.json())
                .then(
                    (result) => {
                        if(result.Files) {
                            const returnedFiles: Array<UploadFileInfo> = [];
                            if (Object.keys(result.Files)) {
                                returnedFiles.push(result.Files);
                            }
                            setFiles(returnedFiles)
                        }
                    },
                    // Nota: è importante gestire gli errori qui
                    // invece di un blocco catch() in modo da non fare passare
                    // eccezioni da bug reali nei componenti.
                    (error) => {

                        // setIsLoaded(true);
                        // setError(error);
                    }
                )
            return () =>{}
        }
    }, [])


    const onAdd = (event: UploadOnAddEvent) => {

        if(
            event.affectedFiles[0].extension === ".png" ||
            event.affectedFiles[0].extension === ".jpg" ||
            event.affectedFiles[0].extension === ".jpeg") {
            setFiles(event.newState);
            setAffectedFiles(event.affectedFiles);
        }else{
            setAlertDialog(true);

        }
    };



    const onChangeHandler = (event: any) => {
        fieldRenderProps.onChange({ value: event.newState });
        onAdd(event);
        // fieldRenderProps.setAllegati(true);
    };
    const onRemoveHandler = (event: any) => {
        fieldRenderProps.onChange({ value: event.newState });
    };


    const onProgress = (event: UploadOnProgressEvent) => {
        setFiles(event.newState);


        setIsOnProgress(event.affectedFiles[0].progress !== 100)

        let target = event.affectedFiles[0].uid;

        // pBar.querySelectorAll<HTMLElement>(".k-progress")[0].style.width = event.affectedFiles[0].progress.toString()+"% !important";

        setFileStatus(event.affectedFiles[0].progress);

        if(event.affectedFiles[0].progress !== 100) {

            let closeDialog = window.document.getElementsByClassName('k-dialog-close')[0]!;
            if(closeDialog !== undefined){
                closeDialog.setAttribute('disabled', 'true');
                closeDialog.classList.add('Mui-disabled');
            }
            let annullaButton = window.document.getElementById('cancel-button-company')!;
            if(annullaButton !== undefined) {
                annullaButton.setAttribute('disabled', 'true');
                annullaButton.classList.add('Mui-disabled');
            }

        }else{
            let closeDialog = window.document.getElementsByClassName('k-dialog-close')[0]!;
            if(closeDialog !== undefined) {
                closeDialog.setAttribute('disabled', 'false');
                closeDialog.classList.remove('Mui-disabled');
            }
            let annullaButton = window.document.getElementById('cancel-button-company')!;
            if(annullaButton !== undefined) {
                annullaButton.setAttribute('disabled', 'false');
                annullaButton.classList.remove('Mui-disabled');
            }
        }
    };

    const onStatusChange = (event: UploadOnStatusChangeEvent) => {

        let target = event.affectedFiles[0].uid.toString();
        // @ts-ignore
        let bResult = ( event.response?.response) ? event.response?.response.bResult : null;
        // // @ts-ignore
        // let nFileId = event.response?.response.file_id;
        const fileP = event.affectedFiles[0];

        const pBar = window.document.querySelector("[data-uid='"+target+"']")!;


        if (bResult) {
            setFiles(event.newState);
            pBar.getElementsByClassName("k-progress")[0].classList.add('full-width-important');
            pBar.getElementsByClassName("status-success")[0].classList.add('status-visible');
            // pBar.getElementsByClassName("k-delete")[0].classList.add('status-visible');
            let newFileId = event.response?.response.file_id;
            pBar.getElementsByClassName("k-delete")[0].setAttribute('data-uid', newFileId);
            pBar.setAttribute('data-uid', newFileId);

            // // @ts-ignore
            // window.document.querySelector(".submit-button-company")!.setAttribute('disabled', 'false');
            // window.document.querySelector(".submit-button-company")!.classList.remove('Mui-disabled');
            // window.document.querySelector(".submit-button-company")!.classList.add('custom-unif-viola-back');
            // window.document.querySelector(".submit-button-company")!.removeAttribute('disabled');
            // window.document.querySelector("#submit-button-company-id")!.setAttribute('disabled', 'false');

        } else {
            let newFiles: Array<UploadFileInfo> = [];
            let intI = 0;
            event.newState.forEach(function (file) {
                if (file.name !== fileP.name && file.status !== fileP.status) {
                    newFiles[intI] = file;

                } else {
                    if(bResult !== null) {
                        toast.error("File " + fileP.name + " già presente");
                    }
                }
            })

            // setFiles(newFiles);

            if(pBar) {
                pBar.getElementsByClassName("k-progress")[0].classList.add('full-width-important', 'error-color');
                pBar.getElementsByClassName("status-error")[0].classList.add('status-visible');
            }
        }

    };
    const onBeforeUpload = (event: UploadOnBeforeUploadEvent) => {

        setFileStatus(0);
        event.additionalData.id = event.files[0].uid.toString();
        event.additionalData.file_name = event.files[0].name;
        event.additionalData.file_type = type;

        if(company_id !== null && company_id !== undefined ){
            event.additionalData.company_id = company_id;
        } else{
            event.additionalData.temp_company_id =temp_company_id;
        }

    };
    const onBeforeRemove = (event: UploadOnBeforeRemoveEvent) => {

        event.additionalData.id = event.files[0].uid.toString();
        event.additionalData.file_name = event.files[0].name;
        event.additionalData.file_type = type;

        if(company_id !== null){
            event.additionalData.company_id = company_id;
        } else{
            event.additionalData.temp_company_id =temp_company_id;
        }
    };

    const download  = (name: string, URL : string) => {
        fetch(URL, {
            method: 'GET',
            headers: header_get,
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    name,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                if(link.parentNode) {
                    link.parentNode.removeChild(link);
                }


            }, (error) => {


            });
    }
    const handleClickDownload = (event : UploadFileInfo) =>{

        const extensions = ['pdf','jpeg','jpg'];
        let a:any = event;
        if(extensions.includes(a.extension) ){
            window.open("/downloadFile.php?token="+a.token+"&type=2", "Downloading", "_blank");
        }else{
            download(a.name,"/downloadFile.php?token="+a.token);
        }

    }


    const handleClick = (event: any) => {

        setIsOnProgress(false);


        onRemoveHandler(event)
        let fileUid = (event.target.attributes['data-uid'] === undefined)? event.target.childNodes[0].attributes['data-uid']['nodeValue'] : event.target.attributes['data-uid']['nodeValue'];
        let optObjHeadBody ={};

        if(fileUid && window.document.querySelector("[data-uid='" + fileUid + "']")) {
            window.document.querySelector("[data-uid='" + fileUid + "']")!.remove();


            if (company_id !== null) {

                optObjHeadBody = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'file_uid': fileUid,
                        'company_id': company_id
                    },
                };
            } else {
                optObjHeadBody = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'file_uid': fileUid,
                        'temp_company_id': temp_company_id
                    },
                };
            }


            fetch(remove_path, optObjHeadBody)
                .then(response => response.json())
                .then(result => {

                    // @ts-ignore
                    // window.document.querySelector(".submit-button-company")!.setAttribute('disabled', 'false');
                    // window.document.querySelector(".submit-button-company")!.classList.remove('Mui-disabled');
                    // window.document.querySelector(".submit-button-company")!.classList.add('custom-unif-viola-back');
                    // window.document.querySelector(".submit-button-company")!.removeAttribute('disabled');


                })
                .catch(error => {
                    // console.error('Error:', error);
                });
        }

    }
    const CustomListItemUI = (props: UploadListItemProps) => {
        return (
            <>

                {props.files.map((file) => (
                    <div className="inline-contents" key={file.uid} data-uid={file.uid}>
                        <Icon className="file-upload-icon cursor-pointer" onClick={(e)=>{handleClickDownload(file)}}>description</Icon>
                        <div className="file-name-upload cursor-pointer" onClick={(e)=>{handleClickDownload(file)}}>{file.name}</div>
                        <div className="k-upload-status delete-button-upload">
                            <button

                                type="button"
                                onClick={(e) =>{handleClick(e)}}
                                className="k-button k-button-icon k-flat k-upload-action " >
                                <span
                                    aria-label="Rimuovi"
                                    title="Rimuovi"
                                    className="k-icon k-delete k-i-x"
                                    data-uid={file.uid}
                                    data-compid={(company_id === null ? temp_company_id : company_id)}
                                ></span>
                            </button>
                        </div>
                    </div>
                ))}

                <div className="k-progressbar k-progressbar-cust">
                    <span className="status-error">Errore nel caricamento del file!</span>
                    <span className="status-success">File caricato correttamente</span>
                    <span className="status-error status-file-not-valid">Formato file non valido!</span>
                    <span></span>
                    <span className="k-progress" style={{width: '0%'}}></span>
                </div>
            </>
        );
    };




    return (
        <div>
            <LocalizationProvider language="it-IT">
                <IntlProvider locale="it">
                    {/*{fieldRenderProps.displayError && <Error>Bisogna inserire almeno un allegato</Error>}*/}
                    <Upload
                        disabled={disabled}
                        className={"fileupload "}
                        restrictions={{
                            allowedExtensions: [ ".jpeg", ".jpg",".png"],
                        }}
                        batch={false}
                        showFileList={true}
                        multiple={false}
                        files={files}
                        onAdd={onChangeHandler}
                        // onRemove={onRemove}
                        onProgress={onProgress}
                        onStatusChange={onStatusChange}
                        onBeforeUpload={onBeforeUpload}
                        onBeforeRemove={onBeforeRemove}
                        withCredentials={false}
                        saveHeaders={ header_upload as UploadHttpHeaders}
                        listItemUI={CustomListItemUI}
                        saveUrl={upload_path}
                    />
                </IntlProvider>
            </LocalizationProvider>
            {/*<Error className={"file-validator " + (visited && validationMessage) ? "display-true" : "" } style={{display:"none"}}>{validationMessage}</Error>*/}
            {visited && validationMessage && <Error>{validationMessage}</Error>}
            {alertDialog && (
            <AlertDialog
                toggleDialog={() => {  setAlertDialog(!alertDialog);}}
                onConfirm={() => {}}

            />
            )}
        </div>
    );
};
export const memoizedSingleFileUploader = React.memo(FileUploaderSingle);