import {cloneElement} from "react";

import {DropDownList, DropDownListChangeEvent, ListItemProps,} from "@progress/kendo-react-dropdowns";
import {FieldProps} from "@progress/kendo-react-form";
import {IStaff} from "models/entities/IStaff";
import {Error} from "@progress/kendo-react-labels";

export const StaffDropdownlist = (props: FieldProps) => {
  const {
    validationMessage,
    visited,
    value,
    source,
    data,
    color,
    currentStaff,
    ...others
  } = props;


  const handleChange = (event: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange.call(undefined, { value: event.value.id });
    }
  };

  const valueRender = (
    element: React.ReactElement<HTMLSpanElement>,
    value: any
  ) => {
    if (!value) {
      return element;
    }

    const children = [
      <span>
        {value?.initials}
      </span>,
    ];

    return cloneElement(element, { ...element.props }, children);
  };

  const customItemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const staffItem = itemProps.dataItem as IStaff;
    const itemChildren = [
      <span
        style={{ paddingLeft: "10px", marginTop: "-5px" }}
        key={staffItem.id}
      >
        {staffItem.initials}
      </span>,
    ];

    return cloneElement(li, li.props, itemChildren);
  };

  return (
      <div>
        <div className="std-dropdown-colored-container dropdownModifica2" style={{
          backgroundColor: color?.hex,
        }}  >
          <label
              className="labelModifica std-dropdown-colored-label"
              data-shrink="true">{props.label}
          </label>
          <div  style={{
            backgroundColor: color?.hex,
          }} className="std-dropdown-colored-component">

            <DropDownList
                className={"custom-unif-font-2"}
                style={{
                  backgroundColor: color?.hex,
                  borderRadius: "5px",
                }}
                onChange={handleChange}
                value={currentStaff}
                data={data}
                disabled={props.disabled}
                dataItemKey={"id"}
                key={"id"}
                textField={"initials"}
                valueRender={valueRender}
                itemRender={customItemRender}
                {...others}
            />
          </div>
        </div>
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
  );
};
