import {useEffect, useState} from "react";
import {debounce} from "lodash";

export const SCREEN_XS = 300;
export const SCREEN_SM = 600;
export const SCREEN_MD = 900;
export const SCREEN_LG = 1200;
export const SCREEN_CLASSIC_LG = 1280;
export const SCREEN_XL = 1536;

export function useWindowSize() {


    const [windowSize, setWindowSize] = useState<number>(window.innerWidth);

    useEffect(() => {
        // Handler to call on window resize
        const handleResize = debounce(()=> {
            // Set window width/height to state
            if (window.innerWidth <= 600 ){
                setWindowSize(601);
            }else {
                setWindowSize(window.innerWidth);
            }
        }, 200 );
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, [windowSize]); // Empty array ensures that effect is only run on mount
    return windowSize;
}