import React, {cloneElement, FC, Fragment, useEffect, useState} from "react";
import {Divider, Grid, Typography,} from "@mui/material";
import AgePricesForm from "./AgePricesForm";
import SeasonPricesForm from "./SeasonPricesForm";
import {useAppSelector} from "../../store";
import SaveIcon from "@mui/icons-material/Save";
import {EditFormProps} from "models/helpers/IEditFormProps";
import {IExperience} from "models/entities/IExperience";
import BaseButton from "components/common/BaseButton";
import {useGetExperienceQuery, useSavePriceMutation, useUpdateExperienceMutation,} from "../../store/api/experiences";
import {IPriceViewModel} from "../../models/views/experiences/IPriceViewModel";
import DiscountsForm from "./DiscountsForm";
import {IPrice} from "models/entities/prices/IPrice";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {useHistory, useLocation} from "react-router-dom";
import {useCurrentExperience} from "utils/hooks/currentExperience";
import PriceInputFullWidth from "./PriceInputFullWidth";
import AlertMessageDialog from "../common/AlertMessageDialog";
import {DropDownList, ListItemProps} from "@progress/kendo-react-dropdowns";
import {IType} from "../../models/entities/types/IType";
import GroupPricesForm from "./GroupPricesForm";

const PricesFormBeta: FC<EditFormProps<IExperience>> = (props) => {
  const currentExperience = useCurrentExperience();

  const { isFetching, isLoading } = useGetExperienceQuery(
      props.item.id ?? skipToken
  );


  const [alertMessageDialog, setAlertMessageDialog] = useState(false);

  const [item, ] = useState(currentExperience ?? props.item);

  //sta roba fa schifo, dovrò metterla a posto

  const handleUpdateNetPrice = (price: number) => {
    window.document.getElementsByClassName("prezzinetti-section")[1]?.classList.add("display-true");
    window.document.getElementsByClassName("prezzinetti-section")[1]?.classList.remove("display-false");

    setIsSectionActive(true);
    if(window.document.getElementById("input-day-prezzinetti-1")  as HTMLInputElement) {
      (window.document.getElementById("input-day-prezzinetti-1") as HTMLInputElement).value =
          (price - price * parseInt((window.document.getElementById("sconto-day-prezzinetti-1") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();
    }
    if(window.document.getElementById("input-day-prezzinetti-2")  as HTMLInputElement) {
      (window.document.getElementById("input-day-prezzinetti-2") as HTMLInputElement).value =
          (price - price * parseInt((window.document.getElementById("sconto-day-prezzinetti-2") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();
    }
    if(window.document.getElementById("input-day-prezzinetti-3")  as HTMLInputElement) {
      (window.document.getElementById("input-day-prezzinetti-3") as HTMLInputElement).value =
          (price - price * parseInt((window.document.getElementById("sconto-day-prezzinetti-3") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();
    }
    if(window.document.getElementById("input-day-prezzinetti-4")  as HTMLInputElement) {
      (window.document.getElementById("input-day-prezzinetti-4") as HTMLInputElement).value =
          (price - price * parseInt((window.document.getElementById("sconto-day-prezzinetti-4") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();
    }
    if(window.document.getElementById("input-day-prezzinetti-5")  as HTMLInputElement) {
      (window.document.getElementById("input-day-prezzinetti-5") as HTMLInputElement).value =
          (price - price * parseInt((window.document.getElementById("sconto-day-prezzinetti-5") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();
    }
    if(window.document.getElementById("input-day-prezzinetti-6")  as HTMLInputElement) {
      (window.document.getElementById("input-day-prezzinetti-6") as HTMLInputElement).value =
          (price - price * parseInt((window.document.getElementById("sconto-day-prezzinetti-6") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();
    }
    if(window.document.getElementById("input-day-prezzinetti-7")  as HTMLInputElement) {
      (window.document.getElementById("input-day-prezzinetti-7") as HTMLInputElement).value =
          (price - price * parseInt((window.document.getElementById("sconto-day-prezzinetti-7") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();
    }

  }
  const handleSetFixedPrice = (item : any) => {

    window.document.getElementsByClassName("prezzinetti-section")[1]?.classList.add("display-true");
    window.document.getElementsByClassName("prezzinetti-section")[1]?.classList.remove("display-false");

    setIsSectionActive(true);

    if(window.document.getElementById("input-day-prezzinetti-1")  as HTMLInputElement) {
      (window.document.getElementById("input-day-prezzinetti-1") as HTMLInputElement).value =
          (item - item * parseInt((window.document.getElementById("sconto-day-prezzinetti-1") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();
    }

    if(window.document.getElementById("input-day-prezzinetti-2")  as HTMLInputElement) {
      (window.document.getElementById("input-day-prezzinetti-2") as HTMLInputElement).value =
          (item - item * parseInt((window.document.getElementById("sconto-day-prezzinetti-2") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();
    }


    if(window.document.getElementById("input-day-prezzinetti-3")  as HTMLInputElement) {
      (window.document.getElementById("input-day-prezzinetti-3") as HTMLInputElement).value =
          (item - item * parseInt((window.document.getElementById("sconto-day-prezzinetti-3") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();
    }
    if(window.document.getElementById("input-day-prezzinetti-4")  as HTMLInputElement) {
      (window.document.getElementById("input-day-prezzinetti-4") as HTMLInputElement).value =
          (item - item * parseInt((window.document.getElementById("sconto-day-prezzinetti-4") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();
    }
    if(window.document.getElementById("input-day-prezzinetti-5")  as HTMLInputElement) {
      (window.document.getElementById("input-day-prezzinetti-5") as HTMLInputElement).value =
          (item - item * parseInt((window.document.getElementById("sconto-day-prezzinetti-5") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();
    }
    if(window.document.getElementById("input-day-prezzinetti-6")  as HTMLInputElement) {
      (window.document.getElementById("input-day-prezzinetti-6") as HTMLInputElement).value =
          (item - item * parseInt((window.document.getElementById("sconto-day-prezzinetti-6") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();
    }
    if(window.document.getElementById("input-day-prezzinetti-7")  as HTMLInputElement) {
      (window.document.getElementById("input-day-prezzinetti-7") as HTMLInputElement).value =
          (item - item * parseInt((window.document.getElementById("sconto-day-prezzinetti-7") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();
    }

    setFixedPrice(item)
  }

  const [fixedPrice, setFixedPrice] = useState(
      (item?.price_type === "fisso" )? (item?.prices!== undefined) ? item?.prices[0]?.price : 0  : 0
  );
  const [experienceData, setExperienceData] = useState<IExperience>(item);
  const [experienceDataPrices, setExperienceDataPrices] = useState<IPrice[]>(item.prices ?? []);
  const handleChangeDiscounts = (e: any) =>{

  }

  const [
    updateExperience,
    { isLoading: isUpdating },
  ] = useUpdateExperienceMutation();

  const { pricesType } = useAppSelector((store) => store.types);

  const [updatePrice] = useSavePriceMutation();

  const [priceType, setPriceType] = useState(
      item ? (item.price_type as string) : "fisso"
  );


  const [priceTypeTmp, setPriceTypeTmp] =useState<string>();
  const handleChange = (e : any) => {
    setAlertMessageDialog(true);
    setPriceTypeTmp(e.target.value as string);
    setOtherBtnSelected(false);

  }



  useEffect(() => {
    setExperienceData(item);
    setPriceType(item.price_type);
  }, [item.price_type]);

  //Aggiorno i dati se il prezzo è fisso
  useEffect(() => {
    if(priceType === undefined){
      setPriceType("fisso");
    }
    if(fixedPrice === undefined){
      setFixedPrice(0);
    }
    if (priceType === "fisso" || priceType === "fixed") {
      setIsSectionActive(true);

      handleSetFixedPrice(fixedPrice);
      setExperienceData({
        ...experienceData,
        price_type: "fisso",
        prices: [
          {
            experience_id: experienceData?.id as number,
            price_type: "fisso",
            price_label_type: undefined,
            age_min: undefined,
            age_max: undefined,
            date_start: undefined,
            date_end: undefined,
            price: fixedPrice,
            // id: item?.prices?.length > 0 ? item?.prices[0]?.id : undefined, //questo id boh
          } as IPriceViewModel,
        ],
      });
    }else{
      setIsSectionActive(false);
    }
  }, [priceType, fixedPrice,pricesType]);

  const onSaved = () => {
    if(checkDiscounts()) {
      if(priceType === "gruppo" || priceType === "group") {
        const formattedData = { ...experienceData, prices: experienceDataPrices };
        updateExperience({
          id: formattedData.id as number,
          experience: formattedData,
        });
      }else{
        updateExperience({
          id: experienceData.id as number,
          experience: experienceData,
        });
      }
    }else{
      setIsFormValid(checkDiscounts());
    }

  };




  const [ isSectionActive,setIsSectionActive] =useState(false);
  const [ ,setOtherBtnSelected] =useState(false);

  const netPriceClassName = (isSectionActive)? "display-true": "display-false";

  const [isFormValid, setIsFormValid] = useState(false);

  const classSave = (isFormValid)? "custom-unif-viola-back" : "custom-unif-grigio-back";


  useEffect(()=>{
    if (priceType === "fisso" || priceType === "fixed") {
      handleSetFixedPrice(fixedPrice)
    }else{
      let price = (window.document.getElementsByClassName("currentPriceToDiscount")[0]?.children[0]?.children[0]?.firstChild as HTMLInputElement)?.value as unknown as number;
      // console.log(price);
      if(price!=undefined) {
        handleUpdateNetPrice(price);
      }
    }
    setIsFormValid(checkDiscounts());
  },[experienceData, pricesType])


  const checkDiscounts = () =>{
    let isValid = true;
    if(experienceData.discount_day1 <= 100 && experienceData.discount_day1>=0){
      isValid = isValid && true;
    }else{
      isValid = isValid && false;
    }
    if(experienceData.discount_day2 <= 100 && experienceData.discount_day2>=0){
      isValid = isValid && true;
    }else{
      isValid = isValid && false;
    }
    if(experienceData.discount_day3 <= 100 && experienceData.discount_day3>=0){
      isValid = isValid && true;
    }else{
      isValid = isValid && false;
    }
    if(experienceData.discount_day4 <= 100 && experienceData.discount_day4>=0){
      isValid = isValid && true;
    }else{
      isValid = isValid && false;
    }
    if(experienceData.discount_day5 <= 100 && experienceData.discount_day5>=0){
      isValid = isValid && true;
    }else{
      isValid = isValid && false;
    }
    if(experienceData.discount_day6 <= 100 && experienceData.discount_day6>=0){
      isValid = isValid && true;
    }else{
      isValid = isValid && false;
    }
    if(experienceData.discount_day7 <= 100 && experienceData.discount_day7>=0){
      isValid = isValid && true;
    }else{
      isValid = isValid && false;
    }
    if(experienceData.group_discount <= 100 && experienceData.group_discount>=0){
      isValid = isValid && true;
    }else{
      isValid = isValid && false;
    }

    return isValid
  }


  const keyLabelItemRender = (
      li: React.ReactElement<HTMLLIElement>,
      itemProps: ListItemProps
  ) => {
    const keyToSearch = itemProps.dataItem;

    const objFound = pricesType.find((i : any) => i.key === keyToSearch);


    const itemChildren = [
      <span
      >
                {objFound?.label}
      </span>,

    ];

    return React.cloneElement(li, li.props, itemChildren);
  };

  const keyLabelValueRender = (
      element: React.ReactElement<HTMLSpanElement>,
      value: any
  ) => {

    let objFound =   pricesType.find((i : any) => i.key === value);


    const children = [
      <span className={"custom-unif-font-2"}>
            {objFound?.label}
      </span>,
    ];

    return cloneElement(element, { ...element.props }, children);
  }

  return isUpdating || isFetching || isLoading ? (
      <CenteredLoadingSpinner />
  ) : (
      <Fragment>

        <Grid container alignItems="stretch"  spacing={1} >
          <Grid className={"spacer-left-select"} item xs={12} sm={2} md={2} lg={2}></Grid>
          <Grid item xs={12} sm={2} md={2} lg={3} className={"tipo-prezzo-price-form"}>
              <div>
                <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth dropdownModifica custom-unif-dropdown-focus">

                  <div className="border-custom-components MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedStart MuiOutlinedInput-adornedStart custom-unif-font ">
                    <DropDownList
                        itemRender={keyLabelItemRender}
                        data={
                          pricesType.map((item: IType, index : any) => {
                            return item.key;
                          })}
                        onChange={(e) => handleChange(e)}
                        valueRender={keyLabelValueRender}
                        value={(priceType === "fixed" || priceType === "fisso")? "fisso" : priceType} />
                  </div>
                </div>
              </div>

          </Grid>

          {(priceType === "fixed" || priceType === "fisso") ? (

              <Grid item xs={12} sm={12} md={4} lg={3} className={"prezzo-price-form "} style={{marginTop: "2vh"}}>
                <PriceInputFullWidth
                    value={fixedPrice}
                    onChange={(e) => handleSetFixedPrice(isNaN(parseFloat(e.target.value))? 0 : parseFloat(e.target.value))}
                />
              </Grid>
          ) : priceType === "periodo" ? (
              <SeasonPricesForm
                  pSectionActive={isSectionActive}
                  setParentSectionActive={(value : boolean) => {
                    setIsSectionActive(value);
                  }}

                  experienceId={item.id as number}
                  onSaveParentState={(seasonPrices: IPrice[]) => {
                    setExperienceData({
                      ...experienceData,
                      price_type: "periodo",
                      prices: seasonPrices,
                    });
                  }}
              />
          ) : priceType === "partecipante" ? (
              <AgePricesForm
                  pSectionActive={isSectionActive}

                  setParentSectionActive={(value : boolean) => {
                    setIsSectionActive(value);
                  }}


                  experienceId={item.id as number}
                  onSaveParentState={(agePrices: IPrice[]) => {
                    setExperienceData({
                      ...experienceData,
                      price_type: "partecipante",
                      prices: agePrices,
                    });
                  }}
              />
          ): priceType === "gruppo" ? (
              <GroupPricesForm
                  pSectionActive={isSectionActive}

                  setParentSectionActive={(value : boolean) => {
                    setIsSectionActive(value);
                  }}


                  experienceId={item.id as number}
                  onSaveParentState={(groupPrices: IPrice[]) => {

                    setExperienceData({
                      ...experienceData,
                      price_type: "gruppo",
                      prices: groupPrices.filter((price: IPrice)=>{
                        return price.group_number && price.group_number >= experienceData?.customers_min_number && price.group_number <= experienceData?.customers_max_number
                      }),
                    });
                    setExperienceDataPrices(groupPrices.filter((price: IPrice)=>{
                      return price.group_number && price.group_number >= experienceData?.customers_min_number && price.group_number <= experienceData?.customers_max_number
                    }));
                  }}
              />
          ) : <></>}

          <Divider />

          <Grid container alignItems="stretch" style={{textAlign:"center", marginTop: "2vh"}}>
            <Grid item xs={12} sm={2} md={3} lg={4}></Grid>
            <Grid item xs={7} sm={3} md={2} lg={2}>
              <Typography variant={"h5"} className={ "titles-priceformbeta discount-title-pfb"}>
                Sconti
              </Typography>
            </Grid>
            <Grid item xs={5} sm={12} md={3} lg={3} className={"prezzinetti-section-title"}>
              <div className={" prezzinetti-section "} >
                <Typography variant={"h5"} className={ "titles-priceformbeta "+ netPriceClassName}>
                  Prezzi netti
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={3}></Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={1} lg={2}></Grid>
          <Grid item xs={12} sm={12} md={10} lg={8}>
            <DiscountsForm
                {...props}
                isSectionActive={isSectionActive}
                onChange={(e)=> handleChangeDiscounts(e)}
                onSubmit={(model: IExperience) =>

                    setExperienceData({
                      ...experienceData,
                      discount_day1: model.discount_day1,
                      discount_day2: model.discount_day2,
                      discount_day3: model.discount_day3,
                      discount_day4: model.discount_day4,
                      discount_day5: model.discount_day5,
                      discount_day6: model.discount_day6,
                      discount_day7: model.discount_day7,
                      group_discount: model.group_discount,
                      group_discount_number: model.group_discount_number,
                      iva : model.iva
                    })
                }
            />
          </Grid>
          {/* <Divider /> */}
          <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:"center", marginTop: "2vh"}}>
            <BaseButton
                // color="primary"
                disabled={!isFormValid}
                className={classSave + " custom-unif-viola-back"}
                variant={"outlined"}
                startIcon={<SaveIcon />}
                onClick={onSaved}
            >
              Salva prezzi e sconti
            </BaseButton>
          </Grid>
        </Grid>
        {alertMessageDialog && (
            <AlertMessageDialog
                toggleDialog={() => {
                  setAlertMessageDialog(!alertMessageDialog);
                }}
                onConfirm={() => {
                  setPriceType(priceTypeTmp!)
                }}
            />
        )}

      </Fragment>
  );
};

export default PricesFormBeta;
