import {
    Chart,
    ChartAxisDefaults,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels
} from "@progress/kendo-react-charts";
import CenteredLoadingSpinner from "../common/CenteredLoadingSpinner";
import * as React from "react";
import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import {IPieChartData} from "../../models/entities/IPieChartData";


// export interface HistogramChart {
//     toggleDialog: () => void;
//     onConfirm: () => void;
// }


const labelContent = (props : any) => {
    let formatedNumber = Number(props.dataItem.value?.toFixed(0))+"%";
    let category  = props.dataItem.category?.substring(0, 18);
    if(props.dataItem.category?.length >= 18) {
        category+="...";
    }
    return category + " - " + formatedNumber;
};




export interface PieChartProps {
    toggleDialog?: () => void;
    onConfirm?: () => void;
    data : any;
    total : number;
    isLoading: boolean;
}
const PieChart = (
    props: PieChartProps
) => {


    const tmpSeries = props.data;
    const series : IPieChartData[] = [];

    Object.getOwnPropertyNames(tmpSeries ?? {} ).forEach((name)=>{
        if(!isNaN(parseInt(name))){
            series.push(tmpSeries[name])
        }
    });

    // const series = [...data];
    //     [
    //     { category: "Programmazione Avanzato 1", value: "0.2545" },
    //     { category: "Alessandro Experience", value: 0.1552 },
    //     { category: "Arrampicata", value: 0.4059 },
    //     { category: "Lancio del mouse", value: 0.0911 },
    //     { category: "Festa", value: 0.0933 },
    // ];
    //
    //
    // useEffect(()=>{
    //
    //     setSeries(props.data);
    // },[props.data])

    const donutCenterRenderer = () => (<span>{Math.round(props.total).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €</span>);

    return (
        (series?.length && !props.isLoading) ?
        <Chart donutCenterRender={donutCenterRenderer} renderAs={'canvas'} zoomable={true}>

            <ChartAxisDefaults labels={{ format: 'n0' }} />
            <ChartLegend  visible={false} />
            <ChartSeries>
                <ChartSeriesItem
                    type="donut"
                    startAngle={30}
                    data={series}
                    field="value"

                    categoryField="category"
                    autoFit={true}
                    // size={20}
                    // sizeField={'20'}
                    // labels={{ visible: true,  content: labelContent }}
                >
                    <ChartSeriesLabels
                        position="outsideEnd"
                        background="none"
                        content={labelContent}
                    />
                </ChartSeriesItem>
            </ChartSeries>
        </Chart>
            : (
                <>{
                    (props.data!==undefined && !props.isLoading) ?
                        <>
                            <Grid item xs={12} style={{  textAlign:"center"}}>
                                <Typography variant="h5" style={{ margin: "5px" , textAlign:"center"}}>

                                    Non sono presenti dati da visualizzare per l'anno selezionato
                                </Typography>
                            </Grid>

                        </>
                        :
                        <CenteredLoadingSpinner></CenteredLoadingSpinner>}


                </>
            )
    );
};

export default PieChart;

export const MemoizedPieChart = React.memo(PieChart);