import {Dialog} from "@progress/kendo-react-dialogs";
import {Field, Form, FormElement} from "@progress/kendo-react-form";

import {Error} from "@progress/kendo-react-labels";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import {ILocation} from "../../models/entities/ILocation";
import Grid from "@mui/material/Grid";
import {ValidatedDropDownList, ValidatedInput, ValidatedTextArea,} from "../common/ValidatedFields";
import {coordinateValidator} from "../../utils/validators/coordinateValidator";
import {requireValidator} from "../../utils/validators/requireValidator";
import {digitValidator} from "../../utils/validators/digitValidator";
import CustomTitleBar from "../common/CustomTitleBar";
import {useAppDispatch, useAppSelector} from "../../store";
// import { addLocation, updateLocation } from "../../store/locations";
// import { ILocationViewModel } from "../../models/views/ILocationViewModel";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";

import BaseButton from "components/common/BaseButton";

import {useAddLocationMutation, useUpdateLocationMutation,} from "../../store/api/locations";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import React, {Fragment, useEffect, useState} from "react";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SaveIcon from "@mui/icons-material/Save";

//import { SiteTypeDropdownlist } from "./SiteTypeDropdownlist";

const LocationForm = (props: EditFormProps<ILocation>) => {
  const dispatch = useAppDispatch();
  const currentPlanner = useCurrentPlanner();

    const { sitesType, meteoIndexes } = useAppSelector((store) => store.types);
  const [addLocation, { isLoading: isAdding, isSuccess }] =
    useAddLocationMutation();
  const [updateLocation, { isLoading: isUpdating }] =
    useUpdateLocationMutation();

  const handleSubmit = (event: ILocation) => {
    // console.log(event);
    if (!event.id) {
      // dispatch(
      //   addLocation({
      //     ...event,
      //     planner_id: currentPlanner?.id,
      //   } as ILocationViewModel)
      // );
      addLocation({ ...event, planner_id: currentPlanner?.id });
    } else {
      // dispatch(updateLocation({ id: event.id, location: event as ILocation }));
      // console.log("patch ");
      updateLocation({
        id: event.id,
        location: { ...event, planner_id: currentPlanner?.id },
      });
    }

    props.onSubmit(event);
    // props.cancelEdit();
  };

    const [lat,setLat] = useState<string>( ' ' );
    const [lng,setLng] = useState<string>(' ' );

    useEffect(()=>{
        if(props.item.latitude && props.item.longitude) {
            setLat(props.item.latitude);
            setLng(props.item.longitude);
        }
    },[props.item])

  useEffect(() => {

    if (isSuccess) props.cancelEdit();
  }, [isSuccess]);

  return (
    <Dialog
        className={"location-form-media-rule"}
      title={
        <CustomTitleBar
          type={ (!props.item || !props.item.name || props.item.name === "")
              ? ""

              : "Modifica sito"}
          label={
            (!props.item || !props.item.name || props.item.name === "")
              ? " Nuovo Sito"

              : ""
          }
          icon="room"
        />
      }
        width={"90vw"}
        style={{maxHeight: "80vh"}}
      onClose={props.cancelEdit}
    >
      <Form
        onSubmit={(e) => handleSubmit(e as ILocation)}
        initialValues={props.item}
        render={(formRenderProps) => (
          <FormElement style={{overflow:"auto"}} >
            <fieldset className={"k-form-fieldset"}>
              <Grid container spacing={3} xs={12}
                    alignItems="stretch" >
                  <Grid container item spacing={3} xs={12} md={6}>
                      <Grid item xs={12} md={6}  className="item-media-rule rows-grandi ">
                          <Field
                            name={"name"}
                            required
                            component={ValidatedInput}
                            label={"Nome"}
                            validator={[requireValidator]}
                          />
                      </Grid>
                      <Grid item xs={12} md={6} className="item-media-rule rows-grandi ">
                          {/* TODO: tipology initial value non funziona */}
                          <Field
                            name={"tipology"}
                            component={ValidatedDropDownList}
                            data={sitesType?.map((t) => t.label)}
                            // textField={"label"}
                            // dataItemKey={"key"}
                            value={props.item?.tipology}
                            label={"Contesto *"}
                            // editorValue={"name"}
                            validator={[requireValidator]}
                            required
                          />
                      </Grid>

                      <Grid item xs={12} md={12}  className="item-media-rule rows-grandi-2 custom-unif-font-2">
                          <Field
                            name={"description"}
                            multiline
                            rows={2}
                            component={ValidatedTextArea}
                            label={"Descrizione"}
                            validator={[]}
                          />
                      </Grid>

                      <Grid item xs={12} md={6} className="item-media-rule rows-grandi ">
                          <Field
                            name={"latitude"}
                            required
                            component={ValidatedInput}
                            label={"Latitudine"}
                            onChange={(e)=>setLat(e.target.value.toString())}
                            validator={[
                              requireValidator,
                              digitValidator,
                              coordinateValidator,
                            ]}
                          />
                      </Grid>
                      <Grid item xs={12} md={6} className="item-media-rule rows-grandi ">
                          <Field
                            name={"longitude"}
                            component={ValidatedInput}
                            label={"Longitudine"}
                            onChange={(e)=>setLng(e.target.value.toString())}
                            required
                            validator={[
                              requireValidator,
                              digitValidator,
                              coordinateValidator,
                            ]}
                          />
                      </Grid>
                      <Grid item xs={12} className="item-media-rule rows-grandi  cond-met-label">
                          {/* TODO: cambiarla in dropdownlist */}
                          <Field
                            name={"meteo_index"}
                            component={ValidatedDropDownList}
                            className={"condiz-meteo"}
                            label={"Condizionamento meteo *"}
                            validator={[requireValidator]}
                            // name={"tipology"}
                              val={meteoIndexes}
                            data={meteoIndexes?.map((t) => t.label)}
                            //   // textField={"label"}
                            //   // dataItemKey={"key"}
                            value={props.item?.meteo_index}
                            // label={"Tipo"}
                            //   // editorValue={"name"}
                            // validator={[requireValidator]}
                            // required.
                          />
                      </Grid>
                {/*SPAZIO TRA RIGHE*/}
                  </Grid>
                      <Grid item container  spacing={3} xs={12} md={6} >
                          <Grid item xs={12}  md={12} className="item-media-rule rows-grandi  custom-unif-font-2">
                              <Field
                                label="Indicazioni stradali"
                                name={"note"}
                                multiline
                                rows={2}
                                component={ValidatedTextArea}
                                validator={[]}
                                placeholder={"Indicazioni"}
                              />
                          </Grid>
                          <Grid item xs={12}  md={12} className="item-media-rule rows-grandi  custom-unif-font-2">
                              <Field
                                label="Indicazioni stradali (Inglese)"
                                name={"note_en"}
                                multiline
                                rows={2}
                                component={ValidatedTextArea}
                                validator={[]}
                                placeholder={"Indicazioni"}
                              />
                          </Grid>
                          <Grid item xs={12}  md={12} className="item-media-rule rows-grandi  custom-unif-font-2">
                              <Field
                                label="Indicazioni stradali (Tedesco)"
                                name={"note_de"}
                                multiline
                                rows={2}
                                component={ValidatedTextArea}
                                validator={[]}
                                placeholder={"Indicazioni"}
                              />
                          </Grid>
                          <Grid item xs={12} className="item-media-rule rows-grandi " >
                              <a href={'https://www.google.com/maps/search/?api=1&query='+lat+','+lng} target={"_blank"}>Visualizza il sito sulla mappa </a>
                          </Grid>
                  </Grid>
              </Grid>
            </fieldset>
            <Grid container justifyContent="center">
              <div className="k-form-buttons">
                {isAdding || isUpdating ? (
                  <CenteredLoadingSpinner />
                ) : (
                  <Fragment>
                    <BaseButton

                        className={` ${formRenderProps.allowSubmit ? "custom-unif-viola-back" : ""}`}
                      type={"submit"}
                      variant="contained"
                        startIcon={<SaveIcon />}
                      disabled={!formRenderProps.allowSubmit}
                    >
                      Salva
                    </BaseButton>
                    {props.hasCancel && (
                      <BaseButton
                          onClick={props.cancelEdit}
                          disabled={!formRenderProps.allowSubmit}
                          startIcon={<CancelPresentationIcon />}
                          className={`custom-button-annulla custom-unif-grigio-back left-spacing-10p ${
                              (formRenderProps.allowSubmit)
                                  ? "custom-unif-arancione-back"
                                  : ""
                          }`}
                      >
                        Annulla
                      </BaseButton>
                    )}
                  </Fragment>
                )}

                {formRenderProps.visited &&
                  formRenderProps.errors &&
                  formRenderProps.errors.VALIDATION_SUMMARY && (
                    <div className={"k-messagebox k-messagebox-error"}>
                      <Error>{formRenderProps.errors.VALIDATION_SUMMARY}</Error>
                    </div>
                  )}
              </div>
                <Grid container item xs={12} className={"spacing-top-timetable"}></Grid>
            </Grid>


          </FormElement>
        )}
      />
      {/* <Toaster position={"bottom-center"} /> */}
    </Dialog>
  );
};
export default LocationForm;
