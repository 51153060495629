import { IUser } from "../../models/entities/IUser";
import { api } from "./index";

const userApi = {
  getById: (id: number) => api().get(`/auth/user/${id}`),
  getAll: () => api().get(`/auth/user`),
  getUsersCompany: (plannerId: number) =>
    api().get(`/company/${plannerId}/users`),
  addUser: (user: IUser) => api().post("/auth/user", user),
  updateUser: (id: number, user: IUser) => api().put(`/auth/user/${id}`, user),
  deleteUser: (id: number) => api().delete(`/auth/user/${id}`),
};

export default userApi;
