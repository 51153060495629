import {Tabs, Theme} from "@mui/material";
import {createStyles, withStyles} from "@mui/styles";

const CustomTabs = withStyles((theme: Theme) =>
  createStyles({
    indicator: {
      // backgroundColor: "white"
    }
  })
)(Tabs)

export default CustomTabs