import {Dialog} from "@progress/kendo-react-dialogs";
import CustomTitleBar from "components/common/CustomTitleBar";
import {IStaff} from "models/entities/IStaff";
import React, {FC, useEffect, useState} from "react";
import {useAppDispatch} from "store";
import {selectStaff} from "store/staff";
import {useLocation, useRouteMatch,} from "react-router-dom";
import {useCurrentStaff} from "../../utils/hooks/currentStaff";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import {useGetStaffWeeksQuery} from "store/api/staff";
import {skipToken} from "@reduxjs/toolkit/query/react";
import StaffTimetableForm from "../staff/StaffTimetableForm";
import {IWeeklyTimetableDay} from "../../models/entities/IWeeklyTimetableDay";
import {dateToStringYYYYMMDD} from "../../utils/dateHelper";
import {useGetStaffWeeklyTimetableQuery} from "../../store/api/staffTimetables";
import {useStaffCurrentWeek} from "../../utils/hooks/currentWeek";
import {useWindowSize} from "../common/ScreenSizes";

const DetailResource: FC<{
  staff: IStaff;
  cancelEdit: () => void;
  onModifyStaff: () => void;
  dayClicked: Date;
}> = ({ staff, cancelEdit, onModifyStaff, dayClicked }) => {
  useRouteMatch();
  useLocation();
  const dispatch = useAppDispatch();


  const currentStaff    = useCurrentStaff();
  const currentWeek     = useStaffCurrentWeek();

    const { isFetching, isUninitialized } = useGetStaffWeeksQuery(
        currentStaff?.id ?? skipToken
    );

  const [modifiedStaff, setModifiedStaff] = useState(false);

  const [isVisible, setIsVisible]  = useState<boolean>(true);

  useEffect(() => {
    dispatch(selectStaff(staff.id));
  }, [staff]);


  useEffect(() => {
    if (isFetching && !isUninitialized) setModifiedStaff(true);
  }, [isFetching, isUninitialized]);

  const {
    data: currentStaffWeeklyTimetable = []
  } =  useGetStaffWeeklyTimetableQuery(
      {
        staffId: ((currentStaff?.id)? currentStaff?.id as number : staff.id as number) ?? skipToken,
        firstWeekDay: currentWeek?.firstDayWeek as string ?? skipToken,
      }, { skip: !(currentStaff?.id) || !(currentWeek?.firstDayWeek) }
  ) ;


  const [dayNameClicked ,setDayNameClicked] = useState("");
  useEffect(()=>{
      if ( currentStaff !== undefined  && currentWeek !== undefined) {


          (currentStaffWeeklyTimetable as IWeeklyTimetableDay[]).forEach(
              (item) => {
                  if(dayClicked !== undefined && item.date === dateToStringYYYYMMDD(dayClicked)) {
                      setDayNameClicked(item.day_name);

                  }
              }
          )
      }
  },[currentStaffWeeklyTimetable]);




  const handleClose = (e : any)=>{
    // console.log(e);;
    setIsVisible(e);
    dispatch(selectStaff(undefined));
     onModifyStaff();
    cancelEdit();
  }

  const [dialogWidth , setDialogWidth] = useState<number>(40);
  const size = useWindowSize();

  useEffect(()=>{
      if (size) {
          if (size > 1600) {
              setDialogWidth(40);
          }
          if (size < 1600){
              setDialogWidth(44);
          }
          if (size < 1460){
              setDialogWidth(48);
          }
          if (size < 1220){
              setDialogWidth(50);
          }
          if (size < 1165){
              setDialogWidth(60);
          }
          if (size < 950){
              setDialogWidth(70);
          }
          if (size < 760){
              setDialogWidth(80);
          }
          if (size <= 601){
              setDialogWidth(100);
          }
      }
  },[size]);





  return !( dayNameClicked!==undefined  && currentWeek!==undefined) ? (
      <div className={"spinner-with-bg"} style={{position: "absolute", backgroundColor: "rgba(255, 255, 255, 0.6)"}}>
        <CenteredLoadingSpinner />
      </div>
  ) : (
       (isVisible)? (
          <Dialog
              // height={"60vh"}
              width={dialogWidth+"vw"}

              className={"location-form-media-rule staff-change-single-day-time"}
              title={
                <CustomTitleBar type={`Modifica orario di ${staff.initials} - ${dayNameClicked}`} icon="people"/>
              }
              onClose={() => {
                dispatch(selectStaff(undefined));
                if (modifiedStaff) onModifyStaff();
                cancelEdit();
              }}
          >
            <StaffTimetableForm dayClicked={dayClicked} closeDialog={(e : any)=> handleClose(e)}

            />
          </Dialog>
        ) : (<></>)
  );
};

export default DetailResource;
