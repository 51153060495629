import {Dialog} from "@progress/kendo-react-dialogs";
import {useAppDispatch} from "../../store";
import {resetState} from "../../store/changePassword";
import ChangeProfileForm from "./ChangeProfileForm";
import CustomTitleBar from "../common/CustomTitleBar";

interface ChangeProfileDialogFormProps {
  onClose: () => void;
}

const ChangeProfileDialogForm = (props: ChangeProfileDialogFormProps) => {
  const dispatch = useAppDispatch();

  const onClose = () => {
    props.onClose();
    dispatch(resetState());
  };
  // <ChangeProfileIcon />
  return (
    <Dialog className="change-profile-media-rule card-dialog"

            title={
      <CustomTitleBar
          label={'Modifica profilo'}
          icon="person"
      />} onClose={onClose} width={400} autoFocus={true}
    >
      <ChangeProfileForm cancelEdit={onClose}  />
    </Dialog>
  );
};
export default ChangeProfileDialogForm;
