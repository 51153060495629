import {Box} from "@mui/material";
import React from "react";
import TimetableForm from "../../../components/timetables/TimetableForm";

const WeeklyTimetablePage = (  staff?: boolean) => {

  // const { getWeeklyTimetableState, getPlannerWeeksState } = useAppSelector(
  //   (s) => s.planners
  // );

  // return getWeeklyTimetableState.state === "loading" ||
  //   getPlannerWeeksState === "loading" ? (
  //   <CenteredLoadingSpinner />
  // ) :
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="stretch"
      alignItems="stretch"
    >

      {/*<Box style={{ width: "100%" }}>*/}
      {/*  <SelectWeek />*/}
      {/*</Box>*/}
      <Box style={{ width: "100%" , }}>
        <TimetableForm   />
      </Box>
    </Box>
  );
};

export default WeeklyTimetablePage;

