import React, {Fragment, useEffect, useState} from "react";
import {SchedulerFormEditorProps,} from "@progress/kendo-react-scheduler";
import GenericGrid from "../common/GenericGrid";

import {ISubscriber} from "../../models/entities/calendar/ISubscriber";

import CenteredLoadingSpinner from "../common/CenteredLoadingSpinner";
import SubscriberForm from "../subscribers/SubscriberForm";
import {useCurrentAppointment} from "../../utils/hooks/currentAppointment";
import {useDeleteSubscriberMutation, useGetSubscribersQuery,} from "../../store/api/subscribers";

import {skipToken} from "@reduxjs/toolkit/query/react";
import AddSubscriberForm from "../subscribers/AddSubscriberForm";
import {SCREEN_LG, SCREEN_MD, useWindowSize} from "../common/ScreenSizes";
import {useGetExperienceQuery} from "../../store/api/experiences";
import {SubscriberChangePren} from "../subscribers/SubscriberChangePren";


export const CustomSchedulerSubscribersFormEditor = (
  props: SchedulerFormEditorProps
) => {

  const size = useWindowSize();



  const { currentAppointment } =
    useCurrentAppointment();

  const [deleteSubscriber, { isLoading: isDeleting }] =
    useDeleteSubscriberMutation();

  const {
    data: subscribers,
    isFetching,
  } = useGetSubscribersQuery(currentAppointment?.id ?? skipToken);

  const remove = (dataItem: ISubscriber) => {
    deleteSubscriber(dataItem.id as number);
  };

  let nameWidth                = null;
  let countryWidth             = null;
  let phoneWidth               = null;
  let emailWidth               = null;
  let totalNumberWidth         = null;
  let total_priceNumberWidth   = null;





  // console.log(subscribers);
  if(size)
  {
    nameWidth               = (size < 602) ? "220" : "250";
    countryWidth            = (size < SCREEN_LG) ? "0" : null;
    phoneWidth              = (size < 1050) ? "0" : null;
    emailWidth              = (size < SCREEN_MD) ? "0" : null;
    totalNumberWidth        = (size < 750) ? "0" : "160";
    total_priceNumberWidth  = (size < 602) ? "0" : "150";
  }

  const { data: experience } = useGetExperienceQuery(
      currentAppointment?.experience_id ?? skipToken
  );


  const [, setVisibleError] = useState(false);

  useEffect(() => {
    if (experience && experience.prices !== undefined) {
      setVisibleError(true);
    }else{
      setVisibleError(false);
    }
  },[experience]);




  return (
    <div style={{padding:"0"}}
         className={"partecipants-table"}>
      {" "}
      {isFetching || isDeleting ? (
        <CenteredLoadingSpinner />
      ) : (

        <Fragment>
          <GenericGrid
            titleDelete={"Conferma cancellazione partecipante"}
            textDelete={"Sicuro di voler cancellare il partecipante "}
            items={
              (subscribers?.filter(
                (t) => t.appointment_id === currentAppointment?.id
              ) as ISubscriber[]) ||
              (currentAppointment?.subscribers as ISubscriber[])
            }
            columns={[
              { field: "name",            title: "Nome" ,           width : (nameWidth!== null? (  nameWidth): "")  },
              { field: "country",         title: "Nazionalità" ,    width : (countryWidth!== null? (  countryWidth): "") },
              { field: "phone",           title: "Telefono",        width : (phoneWidth!== null? (  phoneWidth): "")  },
              { field: "email",           title: "Email",           width : (emailWidth!== null? (  emailWidth): "") },
              { field: "total_number",    title: "Partecipanti" ,   width : (totalNumberWidth!== null? (  totalNumberWidth): "")},
              // { field: "price_type", title: "Tipo prezzo", width: "95" },
              // { field: "total_discount", title: "Sconto" },
              { field: "total_price",     title: "Importo",         width : (total_priceNumberWidth!== null? (  total_priceNumberWidth): "") },
              { field: "prenotation",     title: "Prenotazione",    width :  ((size && size < 602)? "0" : "200")   },
            ]}
            canDelete={true}
            enableCancel={true}
            onRemove={remove}
            customFormComponent={SubscriberChangePren}
            addFormComponent={ AddSubscriberForm }
            updateFormComponent={SubscriberForm}
          />

        </Fragment>

      )}
    </div>
  );
};
