import DialogExperienceForm from "../../components/experiences/DialogExperienceForm";
import {IExperience} from "../../models/entities/IExperience";
import CenteredLoadingSpinner from "../../components/common/CenteredLoadingSpinner";
import GenericGrid from "../../components/common/GenericGrid";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import {useDeleteExperienceMutation, useGetExperiencesQuery,} from "store/api/experiences";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {useAppSelector} from "store";
import {SCREEN_LG, useWindowSize} from "../../components/common/ScreenSizes";
import {useMe} from "../../utils/hooks/me";


const ExperiencesPage = () => {
  const user = useMe();
  const currentPlanner = useCurrentPlanner();
  const { currentExperienceId } = useAppSelector((store) => store.experiences);
  const {
    data: experiences,
    isFetching,
  } = useGetExperiencesQuery(currentPlanner?.id ?? skipToken);
  const [deleteExperience ] =
    useDeleteExperienceMutation();

  const remove = (dataItem: IExperience) => {
    deleteExperience(dataItem.id as number);
  };


  const size = useWindowSize();

  let maxPWidth = null;
  let lenMWidth = null;
  let stafWidth = null;
  let actWidth = null;

  if(size) {
     maxPWidth  = (size < 650) ? "0" : null;
     lenMWidth  = (size < 770) ? "0" : "110";
     stafWidth  = (size < 1080) ? "0" : "240";
     actWidth   = (size < SCREEN_LG) ? "0" : "90";
  }

  return (
    <div style={{ padding: "2.5vh" }} className="custom-unif-font expiriences-grid">
      {/* <PageTitle icon="local_activity" title="Esperienze" /> */}
      {isFetching && !currentExperienceId ? (
        <CenteredLoadingSpinner />
      ) : (
        <GenericGrid
            textDelete={"Cancellare l'esperienza "}
          icon="local_activity" title="Esperienze"
          items={experiences as IExperience[]}
          columns={[
            { field: "name", title: "Titolo", width : (maxPWidth!== null? (  "240px"): "") },
            // { field: "description", title: "Descrizione" },
            { field: "customers_max_number", title: "Max Pax",  width : (maxPWidth!== null? (  maxPWidth): "") },
            { field: "length_minutes", title: "Durata",  width : (lenMWidth!== null? (  lenMWidth): "") },
            stafWidth !=="0" ? { field: "staffs", title: "Personale",  width : (stafWidth!== null? (  stafWidth): "") } : { field: "sta123ffs", title: "Personale",  width :"0" },
            actWidth !== "0"? { field: "active", title: "Attiva" } : { field: "sta123ffs", title: "Personale",  width :"0" },
            actWidth !== "0"? { field: "site_active", title: "Prenotabile" } : { field: "sta123ffs", title: "Personale",  width :"0" },
          ]}
          canDelete={(user) ? (user?.userId !== user?.userRealId) : false} //cancellazione esperienza solo da parte di supera admin  ( ovvero l'id e l'id reale sono diversi. vuol dire che superadmin sta impersonificando qualcuno).
          enableCancel={true}
          onRemove={remove}
          addFormComponent={DialogExperienceForm}
          updateFormComponent={DialogExperienceForm}
        />
      )}

      {/* <ResponseSnackbar
        responseSnackbar={[
          {
            sliceState: addExperienceState,
            resetFunction: resetAddExperience() as any,
          } as IResponseSnackbar,
          {
            sliceState: updateExperienceState,
            resetFunction: resetUpdateExperience() as any,
          } as IResponseSnackbar,
          {
            sliceState: deleteExperienceState,
            resetFunction: resetDeleteExperience() as any,
          } as IResponseSnackbar,
        ]}
      /> */}
    </div>
  );
};

export default ExperiencesPage;
