import {Dialog} from "@progress/kendo-react-dialogs";

import {EditFormProps} from "../../models/helpers/IEditFormProps";
import {IExperience} from "../../models/entities/IExperience";
import CustomTitleBar from "../common/CustomTitleBar";
import {useEffect} from "react";
import {Box} from "@mui/material";
import ExperienceForm from "./ExperienceForm";
import CustomTabs from "../common/CustomTabs";
import CustomTab from "../common/CustomTab";
import {Link, Route, Switch, useHistory, useLocation, useRouteMatch,} from "react-router-dom";
import {useAppDispatch} from "../../store";
import EquipmentTable from "../equipments/EquipmentTable";
import PricesFormBeta from "../prices/PricesFormBeta";
import {selectExperience} from "../../store/experiences";
import {useCurrentExperience} from "../../utils/hooks/currentExperience";
import {useMe} from "utils/hooks/me";
import ExperienceSuperAdmin from "../experiences/ExperienceSuperAdmin";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import {apiPath} from "../../store/api/api";
import {useWindowSize} from "../common/ScreenSizes";
import { ExperienceAvailability } from "./ExperienceAvailability";

const DialogExperienceForm = (props: EditFormProps<IExperience>) => {
  const user = useMe();

  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();

    const size = useWindowSize();
  const dispatch = useAppDispatch();

  const currentExperience = useCurrentExperience();
  const handleNothing = ()=>{

  }

  useEffect(() => {
    if (props.item) {
      dispatch(selectExperience(props.item.id));
    } else if (!currentExperience) {
      dispatch(selectExperience(undefined));
    }
  }, [props.item]);

  return (
    <Dialog
      height={"100vh"}
      width={"100vw"}
      className={"dialog-expirience-form"}
      title={
        <CustomTitleBar
          type={!props.item.id ? "" : (size && size < 700)? "": "Modifica "}
          label={!props.item.id ? "Nuova esperienza" : props.item.name}
          icon="local_activity"
        />
      }
      onClose={() => {
        dispatch(selectExperience(undefined));
        history.push(`/dashboard/experiences`);
        props.cancelEdit();
      }}
    >
      {/* <TabContext value={tabValue}> */}
      <Box>
        <CustomTabs
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          value={location.pathname}
          //value={tabValue}
          // onChange={(event: React.ChangeEvent<{}>, newValue: string) => {
          //   setTabValue(newValue);
          // }}
        >
          <CustomTab
            component={Link}
            to={`${path}`}
            value={`${path}`}
            label="Dettaglio"
          />
          <CustomTab
            component={Link}
            to={`${path}/times`}
            value={`${path}/times`}
            label="Limitazioni Orari"
            disabled={!props.item.id && !currentExperience?.id}
          />
          <CustomTab
              component={Link}
              label="Prezzi e Sconti"
              to={`${path}/prices`}
              value={`${path}/prices`}
              disabled={!props.item.id && !currentExperience?.id}
          />
          <CustomTab
            component={Link}
            label="Attrezzature"
            to={`${path}/equipments`}
            value={`${path}/equipments`}
            disabled={!props.item.id && !currentExperience?.id}
          />
          {user?.userId !== user?.userRealId && ( //se gli id sono diversi allora vuol dire che è il super admin che impersonifica un utente
            <CustomTab
              component={Link}
              label="Super admin"
              to={`${path}/superAdmin`}
              value={`${path}/superAdmin`}
              disabled={!props.item.id && !currentExperience?.id}
            />
          )}
        </CustomTabs>
      </Box>
      <Switch>
        <Route
          path={`${path}`}
          exact
          component={() =>
            ExperienceForm({
              item: props.item as IExperience,
              cancelEdit: props.cancelEdit,
              onSubmit: props.onSubmit,
              hasCancel: props.hasCancel,
              onChange: handleNothing,
                // timesCombo : timesMultiselect,
            })
          }
        />
        {/*<Route path={`${path}/prices`} component={() => PricesForm(props)} />*/}
        <Route path={`${path}/times`} component={() => ExperienceAvailability(props)} />
        <Route path={`${path}/prices`} component={() => PricesFormBeta(props)} />
        <Route
          path={`${path}/equipments`}
          component={() =>
            EquipmentTable({
              experienceId: currentExperience
                ? (currentExperience.id as number)
                : (props.item?.id as number),
            })
          }
        />
        <Route
          path={`${path}/superAdmin`}
          exact
          component={() =>
              ExperienceSuperAdmin({
                item: props.item as IExperience,
                cancelEdit: props.cancelEdit,
                onSubmit: props.onSubmit,
                hasCancel: props.hasCancel,
                onChange: handleNothing,
              })
          }
        />
      </Switch>
    </Dialog>
  );
};
export default DialogExperienceForm;
