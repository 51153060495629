import {Dialog} from "@progress/kendo-react-dialogs";
import CompanyForm from "./CompanyForm";
import {ICompany} from "../../models/entities/ICompany";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import CustomTitleBar from "../common/CustomTitleBar";

const CompanyDialogForm = (props: EditFormProps<ICompany>) => {
  const handleSubmit = (event: ICompany) => {
    props.onSubmit(event);
      // window.location.reload();
  };

  //PREVIENE CHE IL DIALOG SIA CHIUSO CON ESC
  const handleClose = (event : any) => {

      if (event.nativeEvent.keyCode === 27){
          return false;
      }else{

          handleCancelEdit();
      }
  }

  const handleCancelEdit = () => {
    props.cancelEdit();
  };
  const handleNothing = ()=>{

    }

  return (
    <Dialog
      className={"company-dialog-form"}
      title={
        <CustomTitleBar
          type={"Azienda"}
          label={
            !props.item ||
            !props.item.company_name ||
            props.item.company_name === ""
              ? " Nuova"
              : ""
          }
          icon="business"
        />
      }

      onClose={(e) => handleClose(e)}
      width={"100vw"}
      height={"100vh"}
    >
      <CompanyForm
        cancelEdit={handleCancelEdit}
        onSubmit={handleSubmit}
        item={props.item}
        hasCancel={true}
        onChange={handleNothing}

      />
    </Dialog>
  );
};
export default CompanyDialogForm;
