import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {Error} from "@progress/kendo-react-labels";
import {ICompany} from "../../models/entities/ICompany";
import Grid from "@mui/material/Grid";
import {requireValidator} from "../../utils/validators/requireValidator";

import {digitValidator} from "../../utils/validators/digitValidator";
import {emailValidator} from "../../utils/validators/emailValidator";
import {minLengthValidatorFactory} from "../../utils/validators/minLengthValidator";
import {alfaNumericValidator} from "../../utils/validators/alfaNumericValidator";
import {urlValidator} from "../../utils/validators/urlValidator";
import {alphabeticValidator} from "../../utils/validators/alphabeticValidator";

import {
    AutocompleteCity,
    ValidatedEmail,
    ValidatedFacebook,
    ValidatedIBANField,
    ValidatedInput,
    ValidatedInputUpperCase,
    ValidatedInstagram,
    ValidatedMobile,
    ValidatedWebsite,
} from "../common/ValidatedFields";
import {EditFormProps} from "../../models/helpers/IEditFormProps";

import BaseButton from "components/common/BaseButton";

import {useAddCompanyMutation, useGetCompanyQuery, useUpdateCompanyMutation,} from "../../store/api/companies";
import {Typography} from "@mui/material";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import React, {Fragment, useCallback, useEffect, useState,} from "react";
import {MemoizedFileUploader} from "../common/UploadersCustom";
import CustomDatePicker from "../common/CustomDatePicker";
import {DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {useMe} from "utils/hooks/me";
import {skipToken} from "@reduxjs/toolkit/query/react";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SaveIcon from "@mui/icons-material/Save";
import ServerErrorDialog from "../common/ServerErrorDialog";
import {dateFromStringYYYYMMDD, formatDateForApi,} from "../../utils/dateHelper";
import {useAppSelector} from "store";

const CompanyForm = (props: EditFormProps<ICompany>) => {
  const [addCompany, { isLoading: isAdding, isSuccess }] =
    useAddCompanyMutation();
  const [
    updateCompany,
    {
      isLoading: isUpdating,
    },
  ] = useUpdateCompanyMutation();
  const user = useMe();
  const { isFetching } = useGetCompanyQuery(user?.companyId ?? skipToken);

  const [serverErrorDialog, setServerErrorDialog] = useState(false);
  const isLoadingFile = useAppSelector((state)=>state.loaderInsurance.isLoading)

  const [currentDate, setCurrentDate] = useState<Date | null>((
      props.item.insurance_expire_date !== undefined && props.item.insurance_expire_date !== null && props.item.insurance_expire_date !== "")?
      ((dateFromStringYYYYMMDD(props.item.insurance_expire_date as string) as unknown as string  === "Invalid Date")?
          null : dateFromStringYYYYMMDD(props.item.insurance_expire_date as string))
      : null );

  const [, setCityVal] = useState(props.item.city);
  const [isAllegatiEmpty, ] = useState<boolean>(props.item.insurance_attach?.length === 0);

  function gen4() {
    return Math.random().toString(16).slice(-4);
  }

  // const handleReload = () => {
  //   window.location.reload();
  // }

  const _temp_id = ""
    .concat(
      [gen4(), gen4(), gen4(), gen4(), gen4(), gen4(), gen4(), gen4()].join("")
    )
    .toString();

  const collectAllegati = () => {
    let files_id: string[] = [];
    const files: Element[] = Array.from(
      window.document.getElementsByClassName("k-file")!
    );
    if (files.length !== 0) {
      files.forEach((el: Element) => {
        files_id.push(el.getAttribute("data-uid")!.toString());
      });
    }
    return files_id;
  };
  const handleSubmit = (event: ICompany) => {
    let allegati = collectAllegati();

    event.insurance_attach = allegati;
    // createDateTimeFromDateAndTime(
    //     appointment?.calendar_date as string,
    //     calendarDay?.startTime as string
    // )

    if(event.iban) {
      event.iban = event.iban.replace(/\s/g, "");
    }
    event.insurance_expire_date =(currentDate === null )?  null :  formatDateForApi(currentDate as Date);

    if (!event.id) {
      event.temp_id = props.item.temp_id;
      addCompany(event);
    } else {
      // updateCompany({ id: event.id, company: event });
      updateCompany({
        id: event.id,
        company: { ...event, id: event.id, insurance_expire_date: (currentDate === null )?  null :  formatDateForApi(currentDate as Date), },
      });
    }

    // alert();
    // if(isSuccess) {
      props.onSubmit(event);
    // }else{
      //
      // setServerErrorDialog(true);
    // }
    // window.location.reload();//
    // props.cancelEdit();
  };
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      event.preventDefault();

      return false;
    }
  }, []);


  useEffect(() => {

    window.document.addEventListener("keydown", escFunction, false);
  }, [escFunction]);

  const getPlacesPostCodeById = async (placeId: any) =>
    new Promise<{ [key: string]: string }>((resolve, reject) => {
      if (!placeId) reject("placeId not provided");

      try {
        new window.google.maps.places.PlacesService(
          document.createElement("div")
        ).getDetails(
          {
            placeId,
            fields: ["address_components"],
          },
          (details) => {

            let routeInfo: { [key: string]: string } = {};

            details?.address_components?.forEach((entry) => {
              routeInfo[entry.types?.[0] as string] = entry.long_name;
            });

            return resolve(routeInfo);
          }
        );
      } catch (e) {
        reject(e);
      }
    });

  const UpdateField = (city: string, isEff: boolean) => {
    let targetDiv = null;

    let targetError = null;
    targetError = window.document.getElementsByClassName("comune-validator")[0]!;


    if(targetError !== undefined && targetError.classList !=undefined) {
      targetError.classList.add("display-true");
    }
    // targetError.classList.add("display-true");
    //PER INIZIALIZZARE L'AUTOCOMPLETE
    targetDiv = window.document.getElementById("auto-city-prova")!;
    if (isEff) {
      if (targetDiv !== null && targetDiv !== undefined) {
        if (
          targetDiv.getElementsByClassName("css-1wa3eu0-placeholder")[0] !=
          undefined
        ) {
          targetDiv.getElementsByClassName(
            "css-1wa3eu0-placeholder"
          )[0].textContent = city;
          targetDiv
            .getElementsByClassName("css-1wa3eu0-placeholder")[0]
            .classList.add("css-1uccc91-singleValue");
        }
      }
    } else {
      if (targetDiv !== null && targetDiv !== undefined) {

        if(targetDiv.getElementsByClassName("css-1uccc91-singleValue")[0] === undefined){
          targetDiv
              .getElementsByClassName("css-1wa3eu0-placeholder")[0]
              .classList.add("css-1uccc91-singleValue");
        }

        targetDiv.getElementsByClassName(
          "css-1uccc91-singleValue"
        )[0].textContent = city;
        if(targetError !== undefined && targetError.classList !=undefined)
        {
          targetError.classList.remove("display-true");
        }
      }
    }
  };

  const handleDateChange = (event: DatePickerChangeEvent) => {
    let value = event.target.value;

    setCurrentDate(value as Date);
    // if (value) {
    //   event.value = value;
    // }
  };

  useEffect(() => {
    if (isSuccess) props.cancelEdit();
    //PER INIZIALIZZARE L'AUTOCOMPLETE

    if (props.item?.id === null) {
      props.item.temp_id = _temp_id;
    }

    if (props.item?.city !== undefined) {
      UpdateField(props.item?.city as string, true);
    } else {
      UpdateField("Cerca il comune...", true);
    }
  }, [isSuccess]);



  return isFetching ? (
    <CenteredLoadingSpinner />
  ) : (
    <Form

      onSubmit={handleSubmit}
      initialValues={props.item}
      render={(formRenderProps) => (
        <FormElement
          full-width="true"
          id="form-company"
          className="z-index-label-forms company-form"
        >
          <Grid container alignItems="stretch" spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={4} className="item-media-rule rows-grandi" >
              <Field
                name={"company_name"}
                required
                component={ValidatedInput}
                rows={1}
                label={"Ragione Sociale"}
                value={props.item?.company_name}
                validator={[requireValidator]}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} className="item-media-rule rows-grandi" >
              <Field
                name={"vat"}
                component={ValidatedInput}
                rows={1}
                label={"Partita iva"}
                validator={[ digitValidator]}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} className="item-media-rule rows-grandi">
              <Field
                name={"fiscal_code"}
                component={ValidatedInputUpperCase}
                label={"Codice fiscale"}
                validator={[
                  alfaNumericValidator,
                  // rangeLengthValidator(9, 11),
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2} className="item-media-rule rows-grandi">
              <Field
                name={"cuu_code"}
                component={ValidatedInputUpperCase}
                label={"Codice SDI"}
                validator={[

                  alfaNumericValidator,
                  minLengthValidatorFactory(7),
                  // maxLengthValidatorFactory(6),
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4} className="item-media-rule rows-grandi">
              <Field
                name={"address"}
                required
                component={ValidatedInput}
                rows={1}
                label={"Indirizzo"}
                validator={[requireValidator]}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={1} className="item-media-rule rows-grandi">
              <Field
                name={"address_number"}
                required
                component={ValidatedInput}
                rows={1}
                label={"Civ."}
                validator={[requireValidator]}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4} className="item-media-rule rows-grandi custom-unif-font">
              <Field
                onChange={(e) => {
                  getPlacesPostCodeById(e.value.place_id).then(
                    function (result: { [key: string]: string }) {

                      if (
                        e.value.terms[e.value.terms.length - 1].value !== "" &&
                        e.value.terms[e.value.terms.length - 1].value !== null &&
                        e.value.terms[e.value.terms.length - 1].value !==
                          undefined
                      ) {
                        formRenderProps.onChange("province", {
                          value: e.value.terms[e.value.terms.length - 1].value,
                        });
                      }

                      if (
                        result.postal_code !== "" &&
                        result.postal_code !== null
                      ) {
                        formRenderProps.onChange("postal_code", {
                          value: result.postal_code,
                        });
                      }

                      if (result.locality !== "" && result.locality !== null  ) {
                        formRenderProps.onChange("city", {
                          value: result.locality,
                        });
                        setCityVal(result.locality);
                        UpdateField(result.locality, false);
                      }
                        if (result.administrative_area_level_3 !== "" && result.administrative_area_level_3 !== null && result.locality === undefined ){

                          formRenderProps.onChange("city", {
                            value: result.administrative_area_level_3,
                          });
                          setCityVal(result.administrative_area_level_3);
                          UpdateField(result.administrative_area_level_3, false);
                        }



                      // formRenderProps.onChange("postal_code", { value: result });
                    }
                  );
                }}
                className="autocompletamento-citta"
                name={"city"}
                required
                value={props.item?.city}
                label="Comune *"
                validator={[requireValidator]}
                component={AutocompleteCity}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2} className="item-media-rule rows-grandi">
              <Field
                // value={codPostale}
                name={"postal_code"}
                required
                component={ValidatedInput}
                rows={1}
                label={"Cap"}
                validator={[requireValidator, digitValidator]}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={1} className="item-media-rule rows-grandi">
              <Field
                name={"province"}
                required
                component={ValidatedInput}
                rows={1}
                label={"Prov."}
                validator={[requireValidator, alphabeticValidator]}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} className="item-media-rule rows-grandi">
              <Field
                name={"phone"}
                component={ValidatedMobile}
                label={"Telefono"}
                validator={[
                  digitValidator,
                  // phoneNumberValidator, senza il trattino funziona
                ]}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
              className="item-media-rule rows-grandi"
            >
              <Field
                name={"mobile"}
                required
                component={ValidatedMobile}
                label={"Cellulare"}
                validator={[
                  requireValidator,
                  digitValidator,
                  // phoneNumberValidator
                ]}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
              className="item-media-rule rows-grandi"
            >
              <Field
                name={"pec"}
                component={ValidatedEmail}
                label={"Pec"}
                validator={[emailValidator]}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
              className="item-media-rule rows-grandi"
            >
              <Field
                name={"email"}
                required
                component={ValidatedEmail}
                label={"Email"}
                validator={[emailValidator]}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={4}
              className="item-media-rule rows-grandi"
            >
              <Field
                name={"web_address"}
                component={ValidatedWebsite}
                label={"Sito web"}
                validator={[urlValidator]}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={4}
              className="item-media-rule rows-grandi"
            >
              <Field
                name={"facebook"}
                component={ValidatedFacebook}
                label={"Facebook"}
                // validator={[urlValidator]}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={4}
              className="item-media-rule rows-grandi"
            >
              <Field
                name={"instagram"}
                component={ValidatedInstagram}
                label={"Instagram"}
                // validator={[urlValidator]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="item-media-rule rows-grandi">
              <Field
                  name={"iban"}
                  component={ValidatedIBANField}
                  label={"IBAN"}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="item-media-rule rows-grandi-4"
            >
              <Field
                name={"notes"}
                multiline
                rows={3}
                className={"custom-unif-font"}
                component={ValidatedInput}

                label={"Note"}
                validator={[]}
              />
            </Grid>

            <Grid container item spacing={1} className={"custom-section-forms-container"}>
            <Typography variant="h6">Copertura Assicurativa</Typography>

            <Grid
              container
              alignItems="stretch"
              spacing={1}
              className="item-media-rule custom-section-forms custom-section-forms-width-1"
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                className="item-media-rule rows-grandi"
              >
                <Field
                  name={"insurance_title"}
                  component={ValidatedInput}
                  rows={1}
                  label={"Titolo"}
                  required
                  validator={[requireValidator]}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                className="item-media-rule rows-grandi"
              >
                <Field
                  name={"insurance_code"}
                  component={ValidatedInput}
                  label={"Numero polizza"}
                  rows={1}
                  required
                  validator={[requireValidator]}
                />
              </Grid>
              {/*//todo: data di scadenza, campo già riempito non modificabile*/}
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                className="item-media-rule  rows-grandi"
              >
                <div className={ " std-dropdown-container dropdownModifica custom-unif-dropdown-focus"} >

                  <label
                      className="labelModifica std-dropdown-label"
                      data-shrink="true"
                  >
                    Data scadenza
                  </label>
                  <div className=" spanFiglia-daIngrandire std-dropdown-component">
                    <CustomDatePicker
                        name={"insurance_expire_date"}
                        value={currentDate}
                        format="dd/MM/yyyy"
                        disabled={
                          (user?.role === "superadmin")? false :
                           !(user?.userId !== user?.userRealId)
                        }
                        validator={[requireValidator]}
                        onChange={(e: any) => {
                          formRenderProps.onChange("insurance_expire_date", {
                            value: e,
                          });
                          handleDateChange(e)
                        }}
                        min={new Date()}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {/*SPAZIO TRA RIGHE*/}
                <Field
                    className={"upload-attachments"}
                  name={"insurance_attach"}
                  multiline
                  rows={4}
                  displayError={isAllegatiEmpty}
                  component={MemoizedFileUploader}
                  label={"allegati"}
                  company_id={props.item.id}
                  temp_company_id={props.item.temp_id}
                />
              </Grid>
            </Grid>

            </Grid>
            <Grid container justifyContent="center">
              <div className="k-form-buttons">
                {isAdding || isUpdating ? (
                  <CenteredLoadingSpinner />
                ) : (
                  <Fragment>
                    <BaseButton
                      id={"submit-button-company-id"}
                      className={`${
                          !isLoadingFile
                          ? "custom-unif-viola-back"
                          : ""
                      }`}
                      type={"submit"}
                      variant="contained"
                      disabled={isLoadingFile}
                      startIcon={<SaveIcon />}
                    >
                      Salva
                    </BaseButton>

                      <BaseButton
                        id="cancel-button-company"
                        className={"custom-button-annulla custom-unif-grigio-back left-spacing-10p custom-unif-arancione-back"}
                        onClick={
                          (props.hasCancel) ? props.cancelEdit : formRenderProps.onFormReset
                        }
                        startIcon={<CancelPresentationIcon />}
                      >
                        Annulla
                      </BaseButton>
                  </Fragment>
                )}

                {formRenderProps.visited &&
                  formRenderProps.errors &&
                  formRenderProps.errors.VALIDATION_SUMMARY && (
                    <div className={"k-messagebox k-messagebox-error"}>
                      <Error>{formRenderProps.errors.VALIDATION_SUMMARY}</Error>
                    </div>
                  )}
              </div>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="item-media-rule rows-grandi"
            ></Grid>
          </Grid>
          {serverErrorDialog && (
              <ServerErrorDialog
                  toggleDialog={() => {  setServerErrorDialog(!serverErrorDialog);}}
                  onConfirm={() => {window.location.reload()}}

              />
          )}
        </FormElement>
      )}
    />
  );
};
export default CompanyForm;
