import {Link as RouterLink} from "react-router-dom";
import {Fragment} from "react";
import {useAppDispatch} from "../../store";
import BaseButton from "components/common/BaseButton";
import {emailValidatorNew} from "utils/validators/emailValidator";
import {Box, Icon, InputAdornment, Link, TextField} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {IForgottenPasswordViewModel} from "models/views/IForgottenPasswordViewModel";
import {Controller, useForm} from "react-hook-form";
import {useRecoveryLinkMutation} from "store/api/auth";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";

const useStyles = makeStyles({
    fullWidth: {
        width: "100%",
    },
});

interface RecoverPasswordFormProps {
    // onSubmit: () => void;
}

const RecoverPasswordForm = (props: RecoverPasswordFormProps) => {
    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<IForgottenPasswordViewModel>({
        mode: "onChange",
    });
    const classes = useStyles();
    useAppDispatch();
    const [recoveryLink, { isLoading: isLoadingForgottenPassword }] = useRecoveryLinkMutation()

    return isLoadingForgottenPassword ? (
        <CenteredLoadingSpinner />
    ) : (
        <Fragment>
            <form
                noValidate
                className={classes.fullWidth +" forgot-password-dialog"}
                onSubmit={handleSubmit((data) => {
                    recoveryLink(data);
                })}
            >
                <Controller
                    name="email"
                    control={control}
                    rules={{
                        // required: {
                        //     value: true,
                        //     message: "Il campo è obbligatorio",
                        // },
                        validate: emailValidatorNew
                    }}
                    render={({ field }) => (
                        <Box padding={1} width="100%">
                            <TextField
                                error={Boolean(errors?.email)}
                                helperText={errors.email?.message}                                
                                variant="outlined"
                                className={classes.fullWidth + " rows-grandi custom-unif-font custom-unif-viola"}
                                required
                                label="Email"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon>email</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                                {...field}
                            />
                        </Box>
                    )}
                />

                <Box
                    display="flex"
                    marginBottom={4}
                    justifyContent="flex-end"
                    padding={1}
                >
                    <Link
                        to="/login"
                        component={RouterLink}
                        variant="body2"
                        className="custom-unif-font-bold
             custom-unif-viola"
                    >
                        Torna alla login
                    </Link>
                </Box>
                <BaseButton


                    disabled={!isValid || isLoadingForgottenPassword}
                    variant="outlined"
                    type={"submit"}
                    className={classes.fullWidth + ` custom-unif-font ${
                        (!isValid || isLoadingForgottenPassword)
                            ? " custom-unif-grigio-back "
                            : "custom-unif-viola-back"
                    }`}
                >
                    Richiedi link
                </BaseButton>
            </form>
        </Fragment>
    );
};
export default RecoverPasswordForm;
