import {Dialog} from "@progress/kendo-react-dialogs";
import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import {IUser} from "../../models/entities/IUser";

import {GridLayout, GridLayoutItem} from "@progress/kendo-react-layout";
import {ValidatedDropDownListId, ValidatedInput,} from "../common/ValidatedFields";
import {requireValidator} from "../../utils/validators/requireValidator";
import CustomTitleBar from "../common/CustomTitleBar";
import BaseButton from "components/common/BaseButton";
import {useAppDispatch} from "store";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import {useAddReceptionistMutation, useUpdateUserMutation} from "store/api/users";
import {IUserViewModel} from "models/views/IUserViewModel";
import React, {useEffect, useState} from "react";
import {useSelectUserMutation} from "../../store/api/auth";
import {api} from "../../store/api/api";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SaveIcon from "@mui/icons-material/Save";
import {useGetResellersQuery} from "../../store/api/resellers";
//Alla fine il receptionists è un utente comune con un ruolo.
const ROLE_RECEPTION = 'reception';
const ReceptionistsForm = (props: EditFormProps<IUser>) => {


    const [colWidth ,] = useState<string>('7.1vw')
    const dispatch = useAppDispatch()
    const [addUser, { isLoading: isAdding, isSuccess }] = useAddReceptionistMutation();
    const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();
    const { data: companies = [], isFetching } = useGetResellersQuery();
    const [, {  isSuccess: isSelectSucceded }] = useSelectUserMutation()


    const [valueCompany,setValueCompany] = useState(props.item?.company);




    const handleSubmit = (event: any) => {
        if (!event.id) {
            addUser({
                email: event.email,
                role: ROLE_RECEPTION,
                companyId: event.company_id.id,
                name: event.name,
                surname: event.surname,
            } as IUserViewModel);
        } else {
            updateUser({
                id: event.id,
                user: {
                    email: event.email,
                    role: ROLE_RECEPTION,
                    companyId: event.company_id,
                    name: event.name,
                    surname: event.surname,
                } as IUserViewModel,
            });
        }
        props.onSubmit(event);
        // props.cancelEdit();
    };

    useEffect(() => {
        if (isSuccess)
            props.cancelEdit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    useEffect(() => {
        if (isSelectSucceded) {
            dispatch(api.util.resetApiState())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelectSucceded])

    const handleCompanyChange = (e : any) =>{
        setValueCompany(e.value.company_name);
    }


    return (
        <Dialog
            className={"users-form-media-rule receptionist-form"}
            title={
                <CustomTitleBar
                    type={(!props.item || !props.item.name || props.item.name === "")
                        ? ""
                        : "Utente"}
                    label={
                        (!props.item || !props.item.name || props.item.name === "")
                            ? "Nuovo Utente"
                            : props.item.name
                    }
                    icon="people"
                />
            }
            onClose={props.cancelEdit}
        >
            <Form
                onSubmit={handleSubmit}
                initialValues={props.item}
                render={(formRenderProps) => (
                    <FormElement style={{ maxWidth: 600 }}>
                        <GridLayout
                            gap={{ rows:5, cols: 17 }}
                            //10 righe, magari troviamo il modo di indicarlo diversamente
                            rows={[
                                { height: '90px' }, //1
                                { height: '90px' }, //2
                                { height: '50px' }, //3
                            ]}
                            cols={[
                                { width: colWidth },
                                { width: colWidth },
                                { width: colWidth },
                                { width: colWidth },
                                { width: colWidth },
                                { width: colWidth },
                                { width: colWidth },
                                { width: colWidth },
                                { width: colWidth },
                                { width: colWidth },
                                { width: colWidth },
                                { width: colWidth },
                            ]}
                            align={{ horizontal: "stretch" }}

                        >

                            <GridLayoutItem row={1} colSpan={2}>
                                <Field
                                    name={"name"}
                                    component={ValidatedInput}
                                    label={"Nome"}
                                    validator={[requireValidator]}
                                />
                            </GridLayoutItem>
                            <GridLayoutItem row={1} colSpan={2}>
                                <Field
                                    name={"surname"}
                                    component={ValidatedInput}
                                    label={"Cognome"}
                                    validator={[requireValidator]}
                                />
                            </GridLayoutItem>

                            <GridLayoutItem row={2} colSpan={2}>
                                <Field
                                    name={"email"}
                                    component={ValidatedInput}
                                    label={"Email"}
                                    validator={[requireValidator]}
                                />
                            </GridLayoutItem>

                            <GridLayoutItem  row={2}  colSpan={2}>
                                {isFetching ? (
                                    <CenteredLoadingSpinner />
                                ) : (
                                    <Field
                                        name={"company_id"}
                                        required={true}
                                        disabled={props.item.id}
                                        component={ValidatedDropDownListId}
                                        label={"Hotel"}
                                        key={"id"}
                                        value={valueCompany}
                                        onChange={handleCompanyChange}
                                        textField={"company_name"}
                                        dataItemKey={"id"}
                                        // onChange={(e) => {
                                        //   props.item.company_id = e.value.id;
                                        // }}
                                        data={companies}

                                        // onChange={handleRoleChange}
                                        // data={companies.map((item, index) => {
                                        //   return "id: " + item?.id + ", " + item.company_name;
                                        // })}
                                        // value={props.item?.priority}
                                        validator={[requireValidator]}
                                    />
                                )}
                            </GridLayoutItem>

                        {/*SPAZIO TRA RIGHE*/}
                            <GridLayoutItem  row={3} col={2} colSpan={1} style={{textAlign: 'center'}}>
                                {isAdding || isUpdating ? (
                                    <CenteredLoadingSpinner />
                                ) : (
                                        <BaseButton
                                            className={` ${
                                                formRenderProps.allowSubmit
                                                    ? "custom-unif-viola-back"
                                                    : ""
                                            }`}
                                            type={"submit"}
                                            variant="contained"
                                            disabled={!formRenderProps.allowSubmit}
                                            startIcon={<SaveIcon />}
                                        >
                                            Salva
                                        </BaseButton> )}
                            </GridLayoutItem>
                            <GridLayoutItem  row={3} col={3} colSpan={1}  style={{textAlign: 'center'}}>
                                {props.hasCancel && (
                                    <BaseButton
                                        onClick={props.cancelEdit}
                                        disabled={!formRenderProps.allowSubmit}
                                        className={`custom-button-annulla custom-unif-grigio-back left-spacing-10p ${
                                            formRenderProps.allowSubmit
                                                ? "custom-unif-arancione-back"
                                                : ""
                                        }`}
                                        startIcon={<CancelPresentationIcon />}
                                    >
                                        Annulla
                                    </BaseButton>
                                )}
                            </GridLayoutItem>
                        </GridLayout>
                    </FormElement>
                )}
            />
        </Dialog>
    );
};
export default ReceptionistsForm;
