import CustomTitleBar from "../common/CustomTitleBar";
import {Dialog, DialogProps} from "@progress/kendo-react-dialogs";

export const CustomSchedulerDialog = (props: DialogProps) => {
  // const handleClose = () => {
  //   console.log(props);
  // };
  const children = (props.children as []).slice(0, 1);
  // console.log((props.children as []).slice(0, 1));

  return (
    <Dialog

      children={children}
      className={props.className + " appuntamento-dialog"}
      // minWidth={props.minWidth}
      onClose={props.onClose}
      style={props.style}
      title={
        <CustomTitleBar
          title={(props.closeIcon) ? "Nuovo appuntamento " : ""}
          type={(props.closeIcon) ? "Nuovo appuntamento " : ""}
          label={props.title}
          icon="event"
        />
      }
      //onClose={handleClose}
      height={"100vh"}
      width={"100vw"}
    />
  );
};
