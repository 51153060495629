import { useAppSelector } from "store"

export function useCurrentQueriesStates() {
    const isSomeQueryPending = useAppSelector(state => {
        return Object.values(state['api'].queries).find(el => el?.status === 'pending')
    })
    const isSomeMutationPending = useAppSelector(state => {
        return Object.values(state['api'].mutations).find(el => el?.status === 'pending')
    })

    return {
        isSomeMutationPending,
        isSomeQueryPending
    }
}