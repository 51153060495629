import {ChangeEvent, FC} from "react";
import {FormControl, InputAdornment, OutlinedInput} from "@mui/material";

const PriceInput: FC<{ value: number, onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void }> = (props) => {

  return <FormControl fullWidth variant={"outlined"}>
    <OutlinedInput
        type={"text"}
        // value={(Math.round(props.value* 100) / 100).toFixed(2)}
        value={props.value}
        inputProps={{
            step: "0.5",
        }}
        onWheel={event => { event.preventDefault(); }}
        onChange={props.onChange}
        endAdornment={<InputAdornment position="end">euro</InputAdornment>}
    />
  </FormControl>;
}

export default PriceInput