import {FC, useEffect, useState} from "react";
import {Card, Grid,} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../store";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import ChangePasswordIcon from "@mui/icons-material/VpnKey";
import ChangeProfileIcon from "@mui/icons-material/Person";
import ChangePasswordDialogForm from "./ChangePasswordDialogForm";
import BaseButton from "components/common/BaseButton";
import ChangeProfileDialogForm from "./ChangeProfileDialogForm";
import {api} from "store/api/api";
import {useMe, useMeInfo} from "../../utils/hooks/me";
import {useLogoutMutation} from "../../store/api/auth";
import {selectPlanner} from "../../store/planners";


const UserControl: FC = (props) => {
  const [openPasswordForm, setOpenPasswordForm] = useState<boolean>(false);
  const [openProfileForm, setOpenProfileForm] = useState<boolean>(false);
  const user = useMe()
  const [logout, { isLoading: isLogoutLoading, isSuccess }] = useLogoutMutation()
  const dispatch = useAppDispatch();
  const { roles } = useAppSelector((store) => store.types);
  const role = roles.find((t) => t.key === user?.role);
  useAppSelector((store) => store.auth);
  const userInfo = useMeInfo()

  const handleClick = () => {
    logout();
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(api.util.resetApiState());
      dispatch(selectPlanner(undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  const handleChangeProfile = () => {
    setOpenProfileForm(true);
  };

  const handleChangePassword = () => {
    setOpenPasswordForm(true);
  };




  return (
    <Card variant="outlined" className={"custom-unif-font-bold user-control-container"}>
      <Grid container item xs={12} alignItems="stretch" spacing={3}>

        <Grid item xs={12} className={" rows-piccole item-user-control title-container-user-control"}>
          <span>{userInfo.user?.name} {userInfo.user?.surname}</span>
        </Grid>
        <Grid item xs={12} className={" rows-piccole item-user-control"}>
          <Grid item xs={12}>

            <span ><span className={"custom-unif-font"}>Ruolo:</span> {role?.label} </span>


          </Grid>
        </Grid>

        <Grid item xs={12} className={" spacer-usercontrol item-user-control"} ></Grid>
        <Grid item xs={12} className={" rows-piccole item-user-control"}>
          <BaseButton
            className="buttons-user-control custom-unif-font-bold button-media-rule  custom-unif-viola"
            onClick={handleChangeProfile}
            variant="outlined"
            startIcon={<ChangeProfileIcon />}
          >
            Modifica profilo
          </BaseButton>
        </Grid>
        <Grid item xs={12} className={" rows-piccole item-user-control"}>
          <BaseButton
            className="buttons-user-control custom-unif-font-bold button-media-rule  custom-unif-viola"
            onClick={handleChangePassword}
            variant="outlined"
            startIcon={<ChangePasswordIcon />}
          >
            Cambia password
          </BaseButton>
        </Grid>
        <Grid item xs={12} className={" rows-piccole item-user-control"} >
          <BaseButton
            className="buttons-user-control custom-unif-font-bold button-media-rule logout-media-rule  custom-unif-viola"
            onClick={handleClick}
            disabled={isLogoutLoading}
            variant="outlined"
            startIcon={<LogoutIcon />}
          >
            Logout
          </BaseButton>
        </Grid>
        <Grid item xs={12} className={" spacer-usercontrol item-user-control"} ></Grid>
      </Grid>

      {openPasswordForm && (
        <ChangePasswordDialogForm
          onClose={() => {
            setOpenPasswordForm(false);
          }}
        />
      )}
      {openProfileForm && (
        <ChangeProfileDialogForm
          onClose={() => {
            setOpenProfileForm(false);
          }}
        />
      )}
    </Card>
  );
};

export default UserControl;
