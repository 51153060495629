import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {Error} from "@progress/kendo-react-labels";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import {IPlanner} from "../../models/entities/IPlanner";
import Grid from "@mui/material/Grid";
import {ValidatedInput, ValidatedSwitch,} from "../common/ValidatedFields";
import {requireValidator} from "../../utils/validators/requireValidator";
import * as React from "react";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../store";
import {Box} from "@mui/material";

import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import BaseButton from "components/common/BaseButton";
import {useDeletePlannerMutation, useUpdatePlannerMutation,} from "store/api/planners";
import {ListItemProps, MultiSelectChangeEvent} from "@progress/kendo-react-dropdowns";
import SaveIcon from "@mui/icons-material/Save";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

const PlannerForm = (props: EditFormProps<IPlanner>) => {

  const [shouldRerender, setShouldRerender] = useState(false);

 
  const currentPlanner = useCurrentPlanner();

  const [updatePlanner, { isLoading: isUpdating }] = useUpdatePlannerMutation();
  const [deletePlanner, { isLoading: isDeleting }] = useDeletePlannerMutation();

  const [openDelete, setOpenDelete] = useState(false);
  const { prenotationtypes } = useAppSelector((store) => store.types);
  const [, setValuePrenotationT] = useState(props.item?.booking_mode);

  useEffect(() => {
    setShouldRerender(true);
    // setTimeout(() => setShouldRerender(false), 1);
  }, [props.item]);

  const handleSubmit = (event: IPlanner) => {
    updatePlanner({ id: currentPlanner?.id as number, planner: event });
  };

  const handleClose = () => {
    setOpenDelete(false);
  };

  const handleDelete = () => {
    deletePlanner(currentPlanner?.id as number);
  };



  if (shouldRerender) {
    return <div></div>;
  }
  return (
    <Box paddingTop={2}>
      <Form
        onSubmit={handleSubmit}
        initialValues={props.item}
        render={(formRenderProps) => (
          <FormElement full-width="true">
            <Grid container alignItems="stretch"  spacing={3}>
                <Grid item xs={12} className="rows-grandi">
                  <Field
                    name={"name"}
                    required
                    component={ValidatedInput}
                    label={"Nome"}
                    value={props.item?.name}
                    validator={[requireValidator]}
                  />
                </Grid>

                <Grid item xs={12} className="rows-grandi-2">
                  <Field
                    name={"description"}
                    multiline
                    rows={2}
                    component={ValidatedInput}
                    label={"Descrizione "}
                    validator={[]}
                  />
                </Grid>
              <Grid container item xs={12} spacing={3}></Grid>
            </Grid>
            <Grid
              container
              item
              sm={6}
              xs={12}

              alignItems="flex-end"
              style={{ marginTop: 10 }}
            >
              <Field
                name={"notifications_flag"}
                id={"notifications_flag"}
                value={props.item?.notifications_flag}
                component={ValidatedSwitch}
                label={"Notifiche ai clienti"}
              />
            </Grid>
            <Box
              display="flex"
              flexDirection="row"
              p={3}
              justifyContent="center"
            >
              {/* <ButtonGroup > */}
              <BaseButton
                type="submit"
                // startIcon={<SaveIcon />}
                variant="contained"

                className={` ${formRenderProps.allowSubmit ? "custom-unif-viola-back" : ""}`}
                disabled={!formRenderProps.allowSubmit || isUpdating}
                startIcon={<SaveIcon />}
              >
                Salva
              </BaseButton>
              <BaseButton
                  disabled={!formRenderProps.allowSubmit || isUpdating}
                  className={`custom-button-annulla custom-unif-grigio-back left-spacing-10p ${
                      formRenderProps.allowSubmit
                          ? "custom-unif-arancione-back"
                          : ""
                  }`}
                  startIcon={<CancelPresentationIcon />}
                onClick={
                    formRenderProps.onFormReset
                  // setOpenDelete(true);
                }
                // startIcon={<DeleteIcon />}
              >
                Annulla
              </BaseButton>
              {props.hasCancel && (
                <BaseButton type={"submit"} onClick={props.cancelEdit}>
                  Annulla
                </BaseButton>
              )}
              {formRenderProps.visited &&
                formRenderProps.errors &&
                formRenderProps.errors.VALIDATION_SUMMARY && (
                  <div className={"k-messagebox k-messagebox-error"}>
                    <Error>{formRenderProps.errors.VALIDATION_SUMMARY}</Error>
                  </div>
                )}
            </Box>
          </FormElement>
        )}
      />
      {openDelete && (
        <Dialog
          onClose={handleClose}
          title="Elimina agenda"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          Sei sicuro di voler eliminare questa agenda?
          <DialogActionsBar>
            <BaseButton
              onClick={handleDelete}
              color="secondary"
              disabled={isDeleting}
            >
              Elimina
            </BaseButton>
          </DialogActionsBar>
        </Dialog>
      )}
    </Box>
  );
};
export default PlannerForm;
