import {Avatar, Popover, Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {FC, Fragment, MouseEvent, useState} from "react";
import UserControl from "./UserControl";
import {useMeInfo} from "../../utils/hooks/me";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    secondary: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
    },
  })
);

const UserAvatar: FC = (props) => {
  const userInfo = useMeInfo();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>();
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Fragment>
      <Avatar
        onClick={handleClick}
        className={classes.secondary + " iniziali-button custom-unif-font-bold"}
        style={{ cursor: "pointer" }}
      >
        {
          // @ts-ignore
            userInfo.user?.name[0] + userInfo.user?.surname[0] || "U"
        }
      </Avatar>
      <Popover
          disableEnforceFocus={true}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <UserControl />
      </Popover>
    </Fragment>
  );
};

export default UserAvatar;
