import {
    Chart,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesItemTooltip,
    ChartTooltip,
    TooltipContext,
} from "@progress/kendo-react-charts";
import React, {useEffect, useState} from "react";
import {IAreaChartSubData} from "../../models/entities/IAreaChartData";
import CenteredLoadingSpinner from "../common/CenteredLoadingSpinner";
import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";

export interface HistogramChart {
   monthSelected : string;
   data: any;
   loading: boolean;
}

const HistogramExpPaxChart = (
    props: HistogramChart
) => {

    const [categories, setCategories] =useState();
    const [utilizzati, setUtilizzati]=useState();
    const [dataExists, setDataExists] = useState(false);

    useEffect(()=>{
        if(props.data?.operatorsData !== undefined && props.data.length !== 0 && props.data?.operatorsData[0]?.operatore !== "Totale") {
            setCategories(props.data?.operatorsData.map(function (item: IAreaChartSubData) {
                return item.operatore
            }));
            setUtilizzati(props.data?.operatorsData.map(function (item: IAreaChartSubData) {
                return item.rapporto
            }));
            setDataExists(true);
        }else{
            setDataExists(false);
        }
    },[props.data])

    //
    // const TooltipRenderDisp = (props: TooltipContext) => (
    //     <div>
    //         Partecipanti ricevibili : {props.point.value && props.point.value.to}
    //         <br />
    //         Partecipanti ricevuti : {props.point.value && props.point.value.from}
    //     </div>
    // );

    const TooltipRenderUt = (props: TooltipContext) => (
        <div>
            Percentuale utilizzata : {props.point.value * 100} %
        </div>
    );

    return (
        (props.data && !props.loading && dataExists ) ?

            <Chart className={"custom-chart"} renderAs={'canvas'} >
                <ChartLegend position="top" orientation="horizontal" />
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                        categories={categories}
                    />
                </ChartCategoryAxis>
                <ChartAxisDefaults labels={{ format: 'p0' }} />
                <ChartSeries>
                    <ChartSeriesItem type="area" color={"#f47056"} data={utilizzati} autoFit={false}   size={20}  opacity={1}>
                        <ChartSeriesItemTooltip  render={TooltipRenderUt} />
                    </ChartSeriesItem>
                </ChartSeries>
                <ChartTooltip />
            </Chart>
        :( <>{
            !props.loading ?
                <>
                    <Grid item xs={12} style={{  textAlign:"center"}}>
                        <Typography variant="h5" style={{ margin: "5px" , textAlign:"center"}}>

                            Non sono presenti dati da visualizzare per il mese selezionato
                        </Typography>
                    </Grid>
                    <Chart>
                        <ChartLegend position="top" orientation="horizontal" />
                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem
                                categories={[]}
                            />
                        </ChartCategoryAxis>
                        <ChartAxisDefaults labels={{ format: 'p0' }} />
                        <ChartSeries>
                            <ChartSeriesItem type="area" color={"#f47056"} data={[]}  opacity={1}>
                                <ChartSeriesItemTooltip  render={TooltipRenderUt} />
                            </ChartSeriesItem>
                        </ChartSeries>
                        <ChartTooltip />
                    </Chart>
                </>
                     :
                    <CenteredLoadingSpinner></CenteredLoadingSpinner>
        }


            </>
        )
    );
};


export default HistogramExpPaxChart;

export const MemoizedHistogramExpPaxChart = React.memo(HistogramExpPaxChart);