import axios from "axios";
import { apiPath } from "../../store/api/api";

export const api = () => {
  const token = localStorage.getItem("token");

  const instance = axios.create({
    baseURL: apiPath,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  instance.interceptors.response.use(
    (res) => {
      if (res && res.data && res.data && !res.data.bResult) {
        //console.error(res.data);
        return Promise.reject(res.data.aMessage);
      }
      return res;
    },
    (err) => {
      let errorMessage = err.response.data.aMessage;
      if (err.response.status === 401) {
        localStorage.clear();
      }
      // let errorInfoArray = err.response.data.aInfo;
      // console.log("errorInfoArray");
      // console.log(errorInfoArray);
      // console.log("errorMessage");
      // console.log(errorMessage);
      // if (errorInfoArray != undefined && errorInfoArray[0].day_name != "" && errorInfoArray[0] != undefined) {
      //   errorMessage += "\n conflitti:";
      //   errorInfoArray ??
      //     errorInfoArray?.map((errorInfo: any) => {
      //       errorMessage +=
      //         "\n - " +
      //         errorInfo.day_name +
      //         ": " +
      //         errorInfo.begin_time +
      //         " - " +
      //         errorInfo.end_time;
      //     });
      // }
      return Promise.reject(errorMessage);
    }
  );

  return instance;
};
