import { FieldValidatorType } from "@progress/kendo-react-form";

const regex = new RegExp(/[0-9]$/);

export const digitValidator: FieldValidatorType = (value: string) => {
    if(value==="" || value===undefined || value== null){
        return "";
    }else{
        return regex.test(value) ? "" : "Il campo deve essere un numero";
    }
}
