import { useAppSelector } from "../../store";
import { useGetSubscriberQuery } from "../../store/api/subscribers";
import { skipToken } from "@reduxjs/toolkit/query/react";

export function useCurrentSubscriber() {
  const currentSubscriberId = useAppSelector(
    (s) => s.subscribers.currentSubscriberId
  );
  let selectedSubscriber = useAppSelector(
    (s) => s.subscribers.currentSubscriberItem
  );
  const {
    data: currentSubscriber = selectedSubscriber,
    isLoading: isLoadingCurrentSubscriber,
    isSuccess: isSuccessCurrentSubscriber,
  } = useGetSubscriberQuery(currentSubscriberId ?? skipToken);
  return {
    currentSubscriber,
    isLoadingCurrentSubscriber,
    isSuccessCurrentSubscriber,
  };
}
