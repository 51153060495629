import React, {ChangeEvent, FC} from "react";
import {FormControl, InputAdornment, TextField} from "@mui/material";

const PriceInputFullWidth: FC<{ value: number, onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void }> = (props) => {
    return <FormControl  fullWidth variant={"outlined"}>
        <TextField
            fullWidth
            type="text"
            variant="outlined"
            value={props.value}
            onChange={props.onChange}
            inputProps={{
                step: "1.00",
            }}

            onWheel={event => { event.preventDefault(); }}
            InputProps={{
                inputMode: "numeric",
                endAdornment: (
                    <InputAdornment className={'std-adornment'} position="end">
                        euro
                    </InputAdornment>
                )
            }}

        />
    </FormControl>;
}

export default PriceInputFullWidth