import {api} from "./api";
import {IWeeklyTimetableDay} from "../../models/entities/IWeeklyTimetableDay";
import {IPlannerTimetableDto} from "../../models/dto/timetables/IPlannerTimetableDto";
import {ITimetableDayUpdateViewModel} from "../../models/views/ITimetableDayUpdateViewModel";

const parseTimetable = (res: IPlannerTimetableDto) =>
  res.schedules?.map((s) => ({
    ...s,
    closed: !s.ranges,
    ranges: s.ranges || [],
  })) || [];

const plannerTimetablesApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getDefaultPlannerWeeklyTimetable: build.query<
      IWeeklyTimetableDay[],
      number
    >({
      query: (id) => `schedule/planner/${id}/default`,
      transformResponse: (res: IPlannerTimetableDto, meta) =>
        parseTimetable(res),
      providesTags: (result, error, id) =>
        result
          ? [
            ...result.map(
              (s) =>
              ({
                type: "DefaultPlannerTimetable",
                id: `${id}-${s.date}`,
              } as const)
            ),
            { type: "DefaultPlannerTimetable", id: "LIST" },
          ]
          : [{ type: "DefaultPlannerTimetable", id: "LIST" }],
    }),
    getPlannerWeeklyTimetable: build.query<
      IWeeklyTimetableDay[],
      { plannerId: number; firstWeekDay: string }
    >({
      query: ({ plannerId, firstWeekDay }) =>
        `schedule/planner/${plannerId}/${firstWeekDay}`,
      transformResponse: (res: IPlannerTimetableDto, meta) =>
        parseTimetable(res),
      providesTags: (result, error, id) =>
        result
          ? [
            ...result.map(
              (s) =>
              ({
                type: "CustomPlannerTimetable",
                id: `${id}-${s.date}`,
              } as const)
            ),
            { type: "CustomPlannerTimetable", id: "LIST" },
          ]
          : [{ type: "CustomPlannerTimetable", id: "LIST" }],
    }),
    updateDefaultPlannerWeeklyTimetable: build.mutation<
      IWeeklyTimetableDay[],
      { plannerId: number; viewModel: ITimetableDayUpdateViewModel[] }
    >({
      query: ({ plannerId, viewModel }) => ({
        url: `schedule/planner/${plannerId}/default`,
        method: "POST",
        body: {
          overwriteDefault: 0,
          schedules: viewModel,
        },
      }),
      transformResponse: (res: IPlannerTimetableDto, meta) =>
        parseTimetable(res),
      invalidatesTags: (result, error) => error ? [] : [{ type: "DefaultPlannerTimetable", id: "LIST" }],
    }),
    updatePlannerWeeklyTimetable: build.mutation<
      IWeeklyTimetableDay[],
      {
        plannerId: number;
        firstWeekDay: string;
        viewModel: ITimetableDayUpdateViewModel[];
      }
    >({
      query: ({ plannerId, viewModel, firstWeekDay }) => ({
        url: `schedule/planner/${plannerId}/${firstWeekDay}`,
        method: "POST",
        body: {
          overwriteDefault: 1,
          schedules: viewModel,
        },
      }),
      transformResponse: (res: IPlannerTimetableDto, meta) =>
        parseTimetable(res),
      invalidatesTags: (result, error) => error ? [] : [{ type: "CustomPlannerTimetable", id: "LIST" }],
    }),
    updatePlannerWeeklyTimetableToDefault: build.mutation<
      IWeeklyTimetableDay[],
      { plannerId: number; firstWeekDay: string }
    >({
      query: ({ plannerId, firstWeekDay }) => ({
        url: `schedule/planner/${plannerId}/${firstWeekDay}`,
        method: "POST",
        body: {
          overwriteDefault: 0,
        },
      }),
      transformResponse: (res: IPlannerTimetableDto, meta) =>
        parseTimetable(res),
      invalidatesTags: (result, error) => error ? [] : [{ type: "CustomPlannerTimetable", id: "LIST" }],
    }),
  }),
});

export const {
  useGetDefaultPlannerWeeklyTimetableQuery,
  useGetPlannerWeeklyTimetableQuery,
  useUpdateDefaultPlannerWeeklyTimetableMutation,
  useUpdatePlannerWeeklyTimetableMutation,
  useUpdatePlannerWeeklyTimetableToDefaultMutation,
} = plannerTimetablesApi;
