import {useEffect} from "react";

import LocationForm from "../../components/locations/LocationForm";
import {ILocation} from "../../models/entities/ILocation";
import CenteredLoadingSpinner from "../../components/common/CenteredLoadingSpinner";
import GenericGrid from "../../components/common/GenericGrid";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import {useDeleteLocationMutation, useGetLocationsQuery,} from "../../store/api/locations";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {useWindowSize} from "../../components/common/ScreenSizes";

const LocationsPage = () => {
  const currentPlanner = useCurrentPlanner();
  const {
    data: locations,
    isFetching,
  } = useGetLocationsQuery(currentPlanner?.id ?? skipToken);
  const [deleteLocation] =
    useDeleteLocationMutation();


  const size = useWindowSize();

  let tipologyWidth = null;
  if(size) {
    tipologyWidth = (size < 700) ? null : 1;
  }

  const remove = (dataItem: ILocation) => {
    deleteLocation(dataItem.id as number);
  };

  useEffect(() => {}, [currentPlanner]);

  // pageble = 0|20|50
  return (
    <div style={{ padding: "2.5vh" }} className={"locations-page-grid-container"}>
      {/* <PageTitle icon="room" title="Siti" /> */}
      {isFetching ? (
        <CenteredLoadingSpinner />
      ) : (
        <GenericGrid

            titleDelete={"Conferma cancellazione sito"}
            textDelete={"Sicuro di voler cancellare il sito"}
            icon={"room"}
            title={"Siti"}
            items={locations as ILocation[]}
          columns={[
            { field: "name", title: "Nome",  width : (size && size <  700? "240": "") },
            // { field: "description", title: "Descrizione" },
            // { field: "note", title: "Indicazioni" },
            tipologyWidth!== null? { field: "tipology", title: "Contesto" } : { field: "sta123ffs", title: "Personale",  width :"0" },
          ]}
          enableCancel={true}
          onRemove={remove}
          addFormComponent={LocationForm}
          updateFormComponent={LocationForm}
        />
      )}
    </div>
  );
};

export default LocationsPage;
