import {Dialog} from "@progress/kendo-react-dialogs";
import ChangePasswordForm from "./ChangePasswordForm";
import {useAppDispatch} from "../../store";
import {resetState} from "../../store/changePassword";
import CustomTitleBar from "../common/CustomTitleBar";

interface ChangePasswordDialogFormProps {
  onClose: () => void;
}

const ChangePasswordDialogForm = (props: ChangePasswordDialogFormProps) => {
  // const changePasswordSlice = useAppSelector((store) => store.changePassword);
  const dispatch = useAppDispatch();


  const onClose = () => {
    props.onClose();
    dispatch(resetState());
  };

  return (
    <Dialog className={"change-password-media-rule card-dialog"} title={<CustomTitleBar
        label={'Cambia password'}
        icon="vpn_key"
    />} onClose={onClose} width={400}>
      <ChangePasswordForm onClose={onClose} hasCancel={true} cancelEdit={onClose}/>
    </Dialog>
  );
};
export default ChangePasswordDialogForm;
