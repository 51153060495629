import {Box, CircularProgress} from '@mui/material'

const CenteredLoadingSpinner = () => {
  return (
    <Box
      display="flex"
      flex="1"
      justifyContent="center"
      alignItems="center"
      style={{ height: '100%', width: "100%"}}
    >
      <CircularProgress />
    </Box>
  )
}

export default CenteredLoadingSpinner
