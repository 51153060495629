import {api} from "./api";
import {IUser} from "../../models/entities/IUser";
import {IGetUsersDto} from "../../models/dto/users/IGetUsersDto";
import {IGetUserDto} from "../../models/dto/users/IGetUserDto";
import {IAddUserDto} from "../../models/dto/users/IAddUserDto";
import {IUpdateUserDto} from "models/dto/users/IUpdateUserDto";
import {IUserViewModel} from "models/views/IUserViewModel";

const usersApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getUsers: build.query<IUser[], void>({
      query: (type) => ({
        url: `auth/user`, //${type
      }),
      transformResponse: (baseQueryReturnValue: IGetUsersDto, meta) =>
          baseQueryReturnValue.users,
      providesTags: (result) =>
          result
              ? [
                ...result.map(({ id }) => ({ type: "Users", id } as const)),
                {
                  type: "Users",
                  id: "LIST",
                },
              ]
              : [{ type: "Users", id: "LIST" }],
    }),
    getReceptionists: build.query<IUser[], void>({
      query: (type) => ({
        url: `/receptionist`,
      }),
      transformResponse: (baseQueryReturnValue: IGetUsersDto, meta) =>
          baseQueryReturnValue.users,
      providesTags: (result) =>
          result
              ? [
                ...result.map(({ id }) => ({ type: "Users", id } as const)),
                {
                  type: "Users",
                  id: "LIST_REC",
                },
              ]
              : [{ type: "Users", id: "LIST_REC" }],
    }),
    getUser: build.query<IUser, number>({
      query: (id) => `auth/user/${id}`,
      transformResponse: (baseQueryReturnValue: IGetUserDto, meta) =>
        baseQueryReturnValue.user,
      providesTags: (result, error, id) => [{ type: "Users", id }],
    }),
    addUser: build.mutation<IUser, IUserViewModel>({
      query: (user) => ({
        url: `invite`,
        method: "POST",
        body: user,
      }),
      transformResponse: (res: IAddUserDto, meta) => res.user,
      invalidatesTags: (result, error ) => error ? [] : [{ type: "Users", id: "LIST" }],
    }),
    addReceptionist: build.mutation<IUser, IUserViewModel>({
      query: (user) => ({
        url: `receptionist`,
        method: "POST",
        body: user,
      }),
      transformResponse: (res: IAddUserDto, meta) => res.user,
      invalidatesTags: (result, error ) => error ? [] : [{ type: "Users", id: "LIST_REC" }],
    }),
    resendInviteUser: build.mutation<IUser, { id: number; email: string }>({
      query: ({id, email}) => ({
        url: `sendInvite/${id}`,
        method: "POST",
        body: email,
      }),
      transformResponse: (res: IAddUserDto, meta) => res.user,
      invalidatesTags: (result, error ) => error ? [] : [{ type: "Users", id: "LIST" }],
    }),
    updateUser: build.mutation<IUser, { id: number; user: IUserViewModel }>({
      query: ({ id, user }) => ({
        url: `auth/user/${id}`,
        method: "PUT",
        body: user,
      }),
      transformResponse: (res: IUpdateUserDto, meta) => res.user,
      invalidatesTags: (result, error, { id }) => error ? [] :  [{ type: "Users", id }],
    }),
    updateReceptionist: build.mutation<IUser, { id: number; user: IUserViewModel }>({
      query: ({ id, user }) => ({
        url: `receptionist/${id}`,
        method: "PUT",
        body: user,
      }),
      transformResponse: (res: IUpdateUserDto, meta) => res.user,
      invalidatesTags: (result, error, { id }) => error ? [] :  [{ type: "Users", id }],
    }),
    updateProfile: build.mutation<IUser, { id: number; user: IUserViewModel }>({
      query: ({ id, user }) => ({
        url: `auth/user/profile`,
        method: "PUT",
        body: user,
      }),
      transformResponse: (res: IUpdateUserDto, meta) => res.user,
      invalidatesTags: (result, error, { id }) => error ? [] :  [{ type: "Users", id }],
    }),
    deleteUser: build.mutation<void, number>({
      query: (id) => ({
        url: `auth/user/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => error ? [] : [{ type: "Users", id }],
    }),
    // getHistogramData: build.query<IUser[], void>({
    //   query: (id) => ({
    //     url: "auth/user",
    //   }),
    //   transformResponse: (baseQueryReturnValue: IGetUsersDto, meta) =>
    //       baseQueryReturnValue.users,
    //   providesTags: (result) =>
    //       result
    //           ? [
    //             ...result.map(({ id }) => ({ type: "Users", id } as const)),
    //             {
    //               type: "Users",
    //               id: "LIST",
    //             },
    //           ]
    //           : [{ type: "Users", id: "LIST" }],
    // }),
  }),
});

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useGetReceptionistsQuery,
  useAddUserMutation,
  useAddReceptionistMutation,
  useResendInviteUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useUpdateReceptionistMutation,
  useUpdateProfileMutation,
} = usersApi;
