import {api} from "./api";
import {IExperience} from "../../models/entities/IExperience";
import { IGetExperienceAvailabilityRangeDto, IGetExperienceDto} from "../../models/dto/experiences/IGetExperienceDto";
import {IGetExperiencesDto} from "../../models/dto/experiences/IGetExperiencesDto";
import {
  IAddExperienceAvailabilityDaysDto,
  IAddExperienceAvailabilityRangeDto,
  IAddExperienceDto
} from "../../models/dto/experiences/IAddExperienceDto";
import {
  IUpdateExperienceDto,
  IUpdatesExperienceAvailabilityDaysDto
} from "../../models/dto/experiences/IUpdateExperienceDto";
import {IUpdatePriceViewModel} from "../../models/views/experiences/IUpdatePriceViewModel";
import {IUpdateExperiencePriceDto} from "../../models/dto/experiences/prices/IUpdateExperiencePriceDto";
import {IPrice} from "../../models/entities/prices/IPrice";
import {IExperienceEquipment} from "models/entities/IExperienceEquipment";
import {IUpdateExperienceEquipmentDto} from "models/dto/experiences/equipments/IUpdateExperienceEquipmentDto";
import {IAddExperienceEquipmentDto} from "models/dto/experiences/equipments/IAddExperienceEquipmentDto";
import {IExperienceEquipmentViewModel} from "models/views/experiences/IExperienceEquipmentViewModel";
import {IGetExperiencesForResellersDto} from "../../models/dto/experiences/IGetExperiencesForResellersDto";
import {IExperienceReseller} from "../../models/entities/IExperienceReseller";
import {IExperienceAvailabilityRange} from "../../models/entities/experienceAvailability/IExperienceAvailabilityRange";
import {IType} from "../../models/entities/types/IType";

export interface experienceAvailable {
  id: number,
  available: boolean,
}


const experiencesApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getExperiences: build.query<IExperience[], number>({
      query: (planner_id) => ({
        url: "experience",
        params: { planner_id: planner_id },
      }),
      transformResponse: (baseQueryReturnValue: IGetExperiencesDto, meta) =>
          baseQueryReturnValue.experiences,
      providesTags: (result) =>
          result
              ? [
                ...result.map(({ id }) => ({ type: "Experiences", id } as const)),
                {
                  type: "Experiences",
                  id: "LIST",
                },
              ]
              : [{ type: "Experiences", id: "LIST" }],
    }),
    getExperiencesByResellerID: build.query<IExperienceReseller[], number>({
      query: (reseller_id) => ({
        url: `experience/reseller/${reseller_id}`,
      }),
      transformResponse: (baseQueryReturnValue: IGetExperiencesForResellersDto, meta) =>
          baseQueryReturnValue.experiences,
      providesTags: (result) => [{ type: "ExperiencesByReseller", id: "LIST_R" }],
    }),
    getExperiencesByStaffId: build.query<IExperience[], { plannerId: number, staffId: number, day? : string }>({
      query: ({ staffId, plannerId, day }) => ({
        url: "experience",
        params: { planner_id: plannerId, staff_id: staffId, date : day },
      }),
      transformResponse: (baseQueryReturnValue: IGetExperiencesDto, meta) =>
        baseQueryReturnValue.experiences,
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: "ExperiencesByStaff", id } as const)),
            {
              type: "ExperiencesByStaff",
              id: "LIST",
            },
          ]
          : [{ type: "ExperiencesByStaff", id: "LIST" }],
    }),
    getExperience: build.query<IExperience, number>({
      query: (id) => `experience/${id}`,
      transformResponse: (res: IGetExperienceDto, meta) => res.experience,
      providesTags: (result, error, id) => [{ type: "Experiences", id }],
    }),
    getTimesMultiselect: build.query<IType[], number>({
      query: (id) => `schedule/planner/getTimesMultiselect/${id}`,
      transformResponse: (res: any, meta) => res.schedules,
    }),
    getExperienceAvailability: build.query<IExperienceAvailabilityRange[], number>({
      query: (id) => `experience/${id}/availability`,
      transformResponse: (res: IGetExperienceAvailabilityRangeDto, meta) => res.availability_ranges,
      providesTags: (result, error, id) => [{ type: "ExperienceAvailabilityRange",id: "LIST" }],
    }),
    addExperience: build.mutation<IExperience, IExperience>({
      query: (experience) => ({


        url: `experience`,
        method: "POST",
        body: experience,
      }),
      transformResponse: (res: IAddExperienceDto, meta) => res.experience,
      invalidatesTags: (res, error) => error ? [] : [{ type: "Experiences", id: "LIST" }],
    }),
    addExperienceAvailability: build.mutation<IExperienceAvailabilityRange, IExperienceAvailabilityRange>({
      query: (ava_range) => ({
        url: `experience/${ava_range.experience_id}/availability`,
        method: "POST",
        body: ava_range,
      }),
      transformResponse: (res: IAddExperienceAvailabilityRangeDto, meta) => res.availability_range,
      invalidatesTags: (res, error) => error ? [] : [{ type: "ExperienceAvailabilityRange", id: "LIST" }],
    }),
    updateExperienceAvailability: build.mutation<IExperienceAvailabilityRange, IExperienceAvailabilityRange>({
      query: (ava_range) => ({
        url: `experience/${ava_range.experience_id}/availability/${ava_range.id}`,
        method: "PUT",
        body: ava_range,
      }),
      transformResponse: (res: IAddExperienceAvailabilityRangeDto, meta) => res.availability_range,
      invalidatesTags: (res, error,ava_range) => error ? [] : [{ type: "ExperienceAvailabilityRange",  id: "LIST"}],
    }),
    deleteExperienceAvailability: build.mutation<void, IExperienceAvailabilityRange>({
      query: (ava_range) => ({
        url: `experience/${ava_range.experience_id}/availability/${ava_range.id}`,
        method: "DELETE",
      }),
      invalidatesTags: (res, error,ava_range) => error ? [] : [{ type: "ExperienceAvailabilityRange",  id: "LIST"}],
    }),
    updateExperience: build.mutation<
      IExperience,
      { id: number; experience: IExperience }
    >({
      query: ({ id, experience }) => ({
        url: `experience/${id}`,
        method: "PUT",
        body: experience,
      }),
      transformResponse: (res: IUpdateExperienceDto, meta) => res.experience,
      invalidatesTags: (result, error, { id })  => error ? [] : [{ type: "Experiences", id },{ type: "ExperiencesByStaff", id: "LIST" },{ type: "CalendarDay", id: "LIST" }],
      async onQueryStarted(
        { id, ...experience },
        { dispatch, queryFulfilled }
      ) {
        const putResult = dispatch(
          experiencesApi.util.updateQueryData("getExperience", id, (draft) => {
            Object.assign(draft, experience);
          })
        );
        try {
          await queryFulfilled;
        } catch {
          putResult.undo();
        }
        // queryFulfilled.catch(putResult.undo)
      },
    }),
    updateExperienceAvailableReseller: build.mutation<IExperienceReseller[],{
      reseller_id: number;
      experiences: experienceAvailable[]
    }
    >({
      query: ({ reseller_id, experiences }) => ({
        url: `experience/reseller/${reseller_id}`,
        method: "PUT",
        body: {"experiences": experiences},
      }),
      transformResponse: (res: IGetExperiencesForResellersDto, meta) => res.experiences,
      // invalidatesTags: (result, error, )  => error ? [] : [{ type: "ExperiencesByReseller", id: "LIST_R" }],
    }),
    deleteExperience: build.mutation<void, number>({
      query: (id) => ({
        url: `experience/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id)  => error ? [] : [{ type: "Experiences", id }],
    }),
    //equipment API

    addExperienceEquipment: build.mutation<
      IExperienceEquipment[],
      {
        experienceId: number;
        experienceEquipment: IExperienceEquipmentViewModel;
      }
    >({
      query: ({ experienceId, experienceEquipment }) => ({
        url: `experience/${experienceId}/equipment`,
        method: "POST",
        body: experienceEquipment,
      }),
      transformResponse: (res: IAddExperienceEquipmentDto, meta) =>
        res.experienceEquipments,
      invalidatesTags: (result, error, { experienceId }) => [
        { type: "Experiences", id: experienceId },
      ],
    }),
    updateExperienceEquipment: build.mutation<
      IExperienceEquipment[],
      {
        experienceId: number;
        id: number;
        experienceEquipment: IExperienceEquipmentViewModel;
      }
    >({
      query: ({ experienceId, id, experienceEquipment }) => ({
        url: `experience/${experienceId}/equipment/${id}`,
        method: "PUT",
        body: experienceEquipment,
      }),
      transformResponse: (res: IUpdateExperienceEquipmentDto, meta) =>
        res.equipments,
      invalidatesTags: (result, error, { experienceId }) => [
        { type: "Experiences", id: experienceId },
      ],
    }),
    deleteExperienceEquipment: build.mutation<
      void,
      { experienceId: number; id: number }
    >({
      query: ({ experienceId, id }) => ({
        url: `experience/${experienceId}/equipment/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { experienceId }) => [
        { type: "Experiences", id: experienceId },
      ],
    }),

    //price API
    savePrice: build.mutation<
      IPrice[],
      { experienceId: number; viewModel: IUpdatePriceViewModel }
    >({
      query: ({ experienceId, viewModel }) => ({
        url: `experience/${experienceId}/prices`,
        method: "POST",
        body: viewModel,
      }),
      transformResponse: (res: IUpdateExperiencePriceDto, meta) => res.prices,
      invalidatesTags: (result, error, { experienceId }) => [
        { type: "Experiences", id: experienceId },
      ],
    }),
  }),
});

export const {
  useGetExperienceQuery,
  useGetExperiencesQuery,
  useGetExperiencesByResellerIDQuery,
    useGetTimesMultiselectQuery,
    useAddExperienceAvailabilityMutation,
    useGetExperienceAvailabilityQuery,
    useDeleteExperienceAvailabilityMutation,
    useUpdateExperienceAvailabilityMutation,
  useAddExperienceMutation,
  useDeleteExperienceMutation,
  useUpdateExperienceMutation,
  useUpdateExperienceAvailableResellerMutation,
  useAddExperienceEquipmentMutation,
  useDeleteExperienceEquipmentMutation,
  useUpdateExperienceEquipmentMutation,
  useSavePriceMutation,
  useGetExperiencesByStaffIdQuery
} = experiencesApi;
