import {Icon, Typography} from "@mui/material";
import {useWindowSize} from "./ScreenSizes";
import React from "react";

const max_char_mobile = 30;

const CustomTitleBar = (props: any) => {
  const size = useWindowSize();
  return (
    <div
      className="custom-title"
      style={{ fontSize: "18px", lineHeight: "1.3em" }}
    >
      <Typography variant="h6" style={{ margin: "5px" }}>
        {props.icon === 'calendar' ?
            <div className={"custom-unif-viola effective-title-icon"}
                 style={{display: "block", float: "left",  color:"primary"}}>
              <span className="k-icon k-i-calendar" style={{fontSize: '25px'}}></span></div>
            :
            (<Icon color="primary"
                   className={"custom-unif-viola effective-title-icon"}
                   style={{display: "block", float: "left", fontSize: "2rem"}}>
              {props.icon}
            </Icon>)}

        <span color="primary" className={"effective-title"}
                     style={{ paddingLeft: "15px", display: "block", float: "left" }} >

          {(props.prenoNumb)?
              ((" "+(props.customComp??"" ))+" Prenotazione " +props.prenoNumb +((size&& size<650)? " " :" - ")  ): props.type }
            <b>{(size && size <700 &&  props.label && props.label?.length > max_char_mobile)?
                props.label.substring(0,max_char_mobile)+"..." :
                props.label}
            </b>
        </span>
      </Typography>

    </div>
  );
};

export default CustomTitleBar;