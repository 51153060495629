import {Form, FormElement} from "@progress/kendo-react-form";
import {Box, Theme} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Error} from "@progress/kendo-react-labels";
import PasswordInputField from "../users/PasswordInputField";
import BaseButton from "components/common/BaseButton";
import {useUserCheckRegistrationPasswordMutation} from "store/api/auth";
import {useAppSelector} from "store";
import {IUserCheckRegistrationPasswordViewModel} from "models/views/IUserCheckRegistrationPasswordViewModel";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";

export interface PasswordForm {
    password?: string;
    confirmedpassword?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
    })
);

function PasswordRegisterForm() {
    const classes = useStyles();
    const history = useHistory();
    const errorMsg = "Le password devono coincidere";
    const [isErrorPsw, setIsErrorPsw] = useState(false);
    const [userCheckRegistrationPassword, { isLoading, isSuccess , isError }] =
        useUserCheckRegistrationPasswordMutation();
    const { email, uid, checkcode } = useAppSelector(
        (store) => store.registration
    );

    const onConfirmPassword = (event: PasswordForm) => {
        if(event.password === event.confirmedpassword) {
            setIsErrorPsw(false);
            userCheckRegistrationPassword({
                uid: uid,
                checkcode: checkcode,
                email: email,
                password: event.password,
                passwordconfirm: event.confirmedpassword,
            } as IUserCheckRegistrationPasswordViewModel);
        }else{
            setIsErrorPsw(true);
        }
    };


    useEffect(() => {
        if (isSuccess && !isError) history.push("/dashboard/home");
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess,isLoading,isError]);

    return (
        <Form
            onSubmit={onConfirmPassword}
            render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 950 }}>
                    <Box margin={2} className={"rows-grandi"}>
                        <PasswordInputField name="password" label="Password" />
                    </Box>
                    <Box margin={2} className={"rows-grandi"}>
                        <PasswordInputField

                            name="confirmedpassword"
                            label="Conferma Password"
                        />

                        {isErrorPsw && <Error>{errorMsg}</Error>}
                    </Box>

                    <div className={classes.actionsContainer}>
                        <div>
                            {isLoading ? (
                                <CenteredLoadingSpinner />
                            ) : (
                                <BaseButton
                                    type={"submit"}
                                    variant="contained"
                                    color="primary"
                                    className={"custom-unif-viola-back"}
                                    disabled={!formRenderProps.allowSubmit}
                                >
                                    Accedi
                                </BaseButton>
                            )}
                        </div>
                    </div>

                    {formRenderProps.visited &&
                    formRenderProps.errors &&
                    formRenderProps.errors.VALIDATION_SUMMARY && (
                        <div className={"k-messagebox k-messagebox-error"}>
                            <Error>{formRenderProps.errors.VALIDATION_SUMMARY}</Error>
                        </div>
                    )}
                </FormElement>
            )}
        />
    );
}

export default PasswordRegisterForm;
