import {Box, Grid, Switch, TextField, Typography, useMediaQuery, useTheme} from "@mui/material";
import React, {FC, Fragment, useEffect, useState} from "react";
import {IWeeklyTimetableDay} from "../../models/entities/IWeeklyTimetableDay";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {useTimetable} from "../../utils/hooks/timetable";
import {useAppSelector} from "../../store";
import SaveIcon from "@mui/icons-material/Save";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import {usePlannerCurrentWeek} from "../../utils/hooks/currentWeek";
import BaseButton from "components/common/BaseButton";
import BaseIconButton from "components/common/BaseIconButton";
import {
  useGetDefaultPlannerWeeklyTimetableQuery,
  useUpdateDefaultPlannerWeeklyTimetableMutation,
  useUpdatePlannerWeeklyTimetableMutation,
  useUpdatePlannerWeeklyTimetableToDefaultMutation,
} from "store/api/plannerTimetables";
import {useGetPlannerWeeksQuery} from "store/api/planners";
import {TimePicker} from "@progress/kendo-react-dateinputs";
import {aroundMinutesDate, fromTimeToDate} from "utils/dateHelper";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";


const Timetable: FC<{
  timetable: IWeeklyTimetableDay[];
}> = ({ timetable }) => {


  const {
    timetable: formTimetable,
    setInitialState,
    updateBeginTime,
    updateEndTime,
    addTimeRange,
    removeTimeRange,
    createHistory,
    openDay,
  } = useTimetable();

  const [isDefault, setIsDefault] = useState(false);
  const [hasModified, setHasModified] = useState(false);

  const currentPlanner = useCurrentPlanner();
  const currentWeek = usePlannerCurrentWeek();
  const { currentFirstDayWeek } = useAppSelector((state) => state.planners);
  const [currentRealFirstDayName, setCurrentRealFirstDayName] = useState("");
  const [currentRealLastDayName, setCurrentRealLastDayName] = useState("");

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const {
    isLoading: isLoadingPlannerWeeks,
    isError: isErrorPlannerWeeks,
    refetch: refetchPlannerWeeks,
  } = useGetPlannerWeeksQuery(currentPlanner?.id as number);
  const {
    data: currentDefaultPlannerWeeklyTimetable
  } = useGetDefaultPlannerWeeklyTimetableQuery(currentPlanner?.id as number); //cambiati campi
  const [
    updatePlannerWeeklyTimetable,
    {
      isLoading: isUpdatingPlannerWeeklyTimetable,
      isSuccess: isSuccessPlannerWeeklyTimetable,
    },
  ] = useUpdatePlannerWeeklyTimetableMutation();
  const [
    updateDefaultPlannerWeeklyTimetable,
    {
      isLoading: isUpdatingDefaultPlannerWeeklyTimetable,
      isSuccess: isSuccessDefaultPlannerWeeklyTimetable,
    },
  ] = useUpdateDefaultPlannerWeeklyTimetableMutation();
  const [
    updatePlannerWeeklyTimetableToDefault,
    {
      isLoading: isUpdatingPlannerWeeklyTimetableToDefault,
      isSuccess: isSuccessPlannerWeeklyTimetableToDefault,
    },
  ] = useUpdatePlannerWeeklyTimetableToDefaultMutation();

  useEffect(() => {
    if (currentFirstDayWeek !== "default" && currentWeek) {
      setIsDefault(currentWeek.isDefault);
      // setIsDefaultInitial(currentWeek.isDefault);
    }
  }, [currentWeek, currentFirstDayWeek]);

  useEffect(() => {
    if (timetable) {
      setInitialState(timetable);
      setCurrentRealFirstDayName(timetable[0].day_name);
      setCurrentRealLastDayName(timetable[timetable.length - 1].day_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timetable]);

  const handleSave = () => {
    if (currentFirstDayWeek === "default" || !currentWeek) {
      updateDefaultPlannerWeeklyTimetable({
        plannerId: currentPlanner?.id as number,
        viewModel: createHistory(),
      });
    } else if (currentWeek.isDefault && !isDefault) {
      updatePlannerWeeklyTimetable({
        plannerId: currentPlanner?.id as number,
        firstWeekDay: currentWeek.firstDayWeek as string,
        viewModel: createHistory(true),
      });
    } else if (!currentWeek.isDefault && isDefault) {
      updatePlannerWeeklyTimetableToDefault({
        plannerId: currentPlanner?.id as number,
        firstWeekDay: currentWeek.firstDayWeek as string,
      });
    } else {
      updatePlannerWeeklyTimetable({
        plannerId: currentPlanner?.id as number,
        firstWeekDay: currentWeek.firstDayWeek as string,
        viewModel: createHistory(),
      });
    }
  };

  useEffect(() => {
    if (isSuccessPlannerWeeklyTimetable) {
      //personalizzo
      refetchPlannerWeeks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessPlannerWeeklyTimetable]);

  useEffect(() => {
    if (isSuccessPlannerWeeklyTimetableToDefault) {
      //"faccio update a default"
      refetchPlannerWeeks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessPlannerWeeklyTimetableToDefault]);

  useEffect(() => {
    if (isSuccessDefaultPlannerWeeklyTimetable) {
      //faccio update della default");
      refetchPlannerWeeks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDefaultPlannerWeeklyTimetable]);

  const changeDefaultState = (checked: boolean) => {
    setHasModified(true);

    if (!checked) {
      //TODO rendere null tutti gli id della timetable di default corrente, in modo tale che quando si salva
      //si mandano tutti e vengono inseriti i personalizzati della settimana
      //probabilmente da fare funzione in timetable.ts
      // fromDefaultToCustom()
      // setOverwriteDefault(true);
    } else {
      // setOverwriteDefault(false);
      let completetWeek =
        currentDefaultPlannerWeeklyTimetable as IWeeklyTimetableDay[];
      let insert = false;
      let week = [] as IWeeklyTimetableDay[];
      completetWeek.forEach((t) => {
        if (t.day_name === currentRealFirstDayName) insert = true;
        if (insert) week.push(t);
        if (t.day_name === currentRealLastDayName) insert = false;
      });

      setInitialState(
        week.map((d, dIndex) => ({
          date: d.date,
          date_string: d.date_string,
          day_name: d.day_name,
          ranges: d.ranges?.map((r) => ({
            begin_time: r.begin_time,
            end_time: r.end_time,
          })),
        }))
      );
    }
    setIsDefault(checked);
  };

  return isLoadingPlannerWeeks || isErrorPlannerWeeks ? (
    <Fragment />
  ) : (
    <React.Fragment>
      <Box display="flex" flexDirection="row" justifyContent="center">
        {currentFirstDayWeek !== "default" && currentWeek && (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body1" component="span">
              Orario standard
            </Typography>
            <Switch
                className={"custom-unif-switch"}
              checked={isDefault}
              onChange={(e) => {
                changeDefaultState(e.target.checked);
              }}
            />
          </Box>
        )}
      </Box>
      <Grid container alignItems="stretch" spacing={3} className={"labels-planners  timetable-planner timetable-planner-custom "}>
        <Grid item xs={12} sm={12} md={12} lg={12}></Grid>

        <Grid xs={12} style={{textAlign: "center"}}>
          <Grid
              container
              alignItems="stretch"
              spacing={0}
              className={"labels-planners "}
              style={{paddingLeft: "40px", marginTop: "2vh"}}
          >
            {formTimetable &&
            formTimetable.map((day, dayIndex) => (
                <Grid item  xs={12} sm={12} md={12} lg={12}>
                  <Box
                      // key={day.date}
                      key={dayIndex}
                      display="flex"
                      flexDirection={matches ? "row" : "column"}
                  >
                    <Grid container item xs={12}  className={"timetable-row-container "} alignItems="stretch" spacing={2}>
                      <Grid item xs={12} sm={1} md={2} lg={4}  className={"day-name-timetable"}>
                        <Typography
                            className={`title-giorno-timetable ${
                                (day.closed)
                                    ? "title-giorno-timetable-closed"
                                    : ""
                            }`}

                            variant="h6"
                            // align={matches ? "right" : "left"}
                        >
                          <b>{day.day_name}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={10} md={10} lg={8}>
                        {day.closed ? (
                            <Grid container item    className={" custom-section-forms-timetable align-center-inside-container"}>
                              <Grid item xs={5} sm={5} md={3} lg={4} className="allinea-orari-chiusi "
                                    style={{marginRight:"10px"}}>
                                <TextField
                                    disabled={
                                      isDefault && currentFirstDayWeek !== "default"
                                    }
                                    fullWidth
                                    className={"custom-input-timetable"}
                                    inputProps={{ readOnly: true }}
                                    variant="outlined"
                                    value={"Chiuso"}
                                />
                              </Grid>
                              <Grid  className={"bottoni-timetable add-delete-time"}
                                     item
                                     xs={3}
                                     sm={3}
                                     md={3}
                                     lg={2}
                                     style={{ paddingTop: "12px", textAlign: "left" }}>
                                <BaseIconButton
                                    className={`bottoni-tondi bottoni-tondi-responsive add-time std-bottoni-tondi ${
                                        ( isDefault && currentFirstDayWeek !== "default")
                                            ? ""
                                            : "custom-unif-viola-back"
                                    }`
                                    }
                                    style={{marginLeft:"24px"}}
                                    color="inherit"
                                    onClick={() => openDay(day.date)}
                                    disabled={
                                      isDefault && currentFirstDayWeek !== "default"
                                    }
                                >
                                  <AddIcon fontSize="small" />
                                </BaseIconButton>
                              </Grid>
                            </Grid>
                        ) : (
                            day.ranges?.map((range, rangeIndex) => (
                                <Grid container item   className={"a-bit-of-margin-top custom-section-forms-timetable"}
                                >
                                  <Grid  item xs={12} sm={12} md={3} lg={2}
                                         className="std-time-picker-container inputTimePicker-custom da-a-orari custom-unif-dropdown-focus"
                                  >
                                    <label
                                        className="labelModifica  label-timetable std-time-picker-label"
                                        data-shrink="true"
                                    >
                                      dalle
                                    </label>
                                    <div className="std-time-picker-component">
                                      <TimePicker
                                          disabled={
                                            isDefault && currentFirstDayWeek !== "default"
                                          }
                                          steps={{ minute: 15 }}
                                          // ampm={false}
                                          // inputVariant="outlined"
                                          value={range.begin_time as Date}
                                          onBlur={(event: any) => {
                                            if (event.target.value) {
                                              updateBeginTime(
                                                  day.date,
                                                  rangeIndex,
                                                  aroundMinutesDate(
                                                      fromTimeToDate(event.target.value)
                                                  )
                                              );
                                              setHasModified(true);
                                            }
                                          }}
                                          onChange={(event) => {
                                            if (event.value) {
                                              updateBeginTime(
                                                  day.date,
                                                  rangeIndex,
                                                  event.value as Date
                                              );
                                            }
                                          }}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid  item xs={12} sm={12} md={3} lg={2}
                                         className="std-time-picker-container inputTimePicker-custom  da-a-orari custom-unif-dropdown-focus"
                                  >
                                    <label
                                        className="labelModifica label-timetable std-time-picker-label"
                                        data-shrink="true"
                                    >
                                      alle
                                    </label>
                                    <div className="std-time-picker-component">
                                      <TimePicker
                                          disabled={
                                            isDefault && currentFirstDayWeek !== "default"
                                          }
                                          steps={{ minute: 15 }}
                                          value={range.end_time as Date}
                                          onBlur={(event: any) => {
                                            if (event.target.value) {
                                              updateEndTime(
                                                  day.date,
                                                  rangeIndex,
                                                  aroundMinutesDate(
                                                      fromTimeToDate(event.target.value)
                                                  )
                                              );
                                              setHasModified(true);
                                            }
                                          }}
                                          onChange={(event) => {
                                            if (event.value) {
                                              updateEndTime(
                                                  day.date,
                                                  rangeIndex,
                                                  event.value as Date
                                              );
                                            }
                                          }}
                                      />
                                    </div>
                                  </Grid>

                                  <Grid className={"bottoni-timetable add-delete-time"} item xs={3} sm={3} md={3} lg={3} style={{paddingTop:"12px", textAlign: "left"}}>

                                    <BaseIconButton
                                        color="inherit"
                                        className={`bottoni-tondi bottoni-tondi-responsive add-time std-bottoni-tondi  ${
                                            (isDefault && currentFirstDayWeek !== "default")
                                                ? ""
                                                : "custom-unif-viola-back"
                                        }`}
                                        tabIndex={-1}
                                        style={{marginRight:"16px", marginLeft:"24px"}}
                                        onClick={() => {
                                          setHasModified(true);
                                          addTimeRange(day.date, rangeIndex);
                                        }}
                                        disabled={
                                          isDefault && currentFirstDayWeek !== "default"
                                        }
                                    >
                                      <AddIcon fontSize="small" />
                                    </BaseIconButton>
                                    <BaseIconButton
                                        color="inherit"
                                        className={`bottoni-tondi bottoni-tondi-responsive add-time  std-bottoni-tondi ${
                                            (isDefault && currentFirstDayWeek !== "default")
                                                ? ""
                                                : "custom-unif-arancione-back"
                                        }`}

                                        onClick={() => {
                                          removeTimeRange(day.date, rangeIndex);
                                          setHasModified(true);
                                        }}
                                        disabled={
                                          isDefault && currentFirstDayWeek !== "default"
                                        }
                                    >
                                      <CloseIcon fontSize="small" />
                                    </BaseIconButton>
                                  </Grid>
                                  <Grid item xs={12}></Grid>

                                </Grid>
                            ))
                        )}

                        <Grid container item xs={12} sm={12} md={12} lg={12} ></Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
            ))}

            <Grid container item xs={12} sm={12} md={12} lg={12}></Grid>
          </Grid>
          <Grid container alignItems="center" spacing={3} >
            <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign: "center"}} className={"top-spacing"}>
              <BaseButton
                  disabled={
                    isUpdatingDefaultPlannerWeeklyTimetable ||
                    isUpdatingPlannerWeeklyTimetable ||
                    isUpdatingPlannerWeeklyTimetableToDefault ||
                    !hasModified
                  }
                  variant="contained"
                  className={` ${
                      !(isUpdatingDefaultPlannerWeeklyTimetable ||
                          isUpdatingPlannerWeeklyTimetable ||
                          isUpdatingPlannerWeeklyTimetableToDefault ||
                          !hasModified)
                          ? "custom-unif-viola-back"
                          : ""
                  }`}
                  onClick={handleSave}
                  startIcon={<SaveIcon />}
              >
                Salva
              </BaseButton>
              <BaseButton
                  // id="cancel-button-company"
                  disabled={(isUpdatingDefaultPlannerWeeklyTimetable ||
                      isUpdatingPlannerWeeklyTimetable ||
                      isUpdatingPlannerWeeklyTimetableToDefault ||
                      !hasModified)}
                  className={`custom-button-annulla custom-unif-grigio-back left-spacing-10p ${
                      !(isUpdatingDefaultPlannerWeeklyTimetable ||
                          isUpdatingPlannerWeeklyTimetable ||
                          isUpdatingPlannerWeeklyTimetableToDefault ||
                          !hasModified)
                          ? "custom-unif-arancione-back"
                          : ""
                  }`}
                  startIcon={<CancelPresentationIcon />}

                  onClick={()=>{changeDefaultState(true); setHasModified(false)}}
                  // () =>
                  //   window.confirm('Tutte le modifiche andranno perse, \n sei sicuro di voler uscire?') && props.cancelEdit }
              >
                Annulla
              </BaseButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <ResponseSnackbar
        responseSnackbar={[
          {
            sliceState: updateDefaultWeeklyTimetableState,
            resetFunction: resetUpdateDefaultWeeklyTimetable() as any,
          } as IResponseSnackbar,
          {
            sliceState: updateWeeklyTimetableState,
            resetFunction: resetUpdateWeeklyTimetable() as any,
          } as IResponseSnackbar,
          {
            sliceState: updateWeeklyTimetableToDefaultState,
            resetFunction: resetUpdateWeeklyTimetableToDefault() as any,
          } as IResponseSnackbar,
        ]}
      /> */}
    </React.Fragment>
  );
};

export default Timetable;
