import React, {FC, useState} from "react";
import {Grid, Typography} from "@mui/material";
import PriceInput from "./PriceInput";
import BaseButton from "../common/BaseButton";
import {useAppSelector} from "../../store";
import {GroupPriceItemProps} from "../../models/props/GroupPriceItemProps";

const GroupPriceItem: FC<GroupPriceItemProps> = (props) => {
    const updatePriceNet = () => {
        (window.document.getElementById("input-day-prezzinetti-1")  as HTMLInputElement).value =
            (props.groupPrice.price - props.groupPrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-1") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();

        (window.document.getElementById("input-day-prezzinetti-2")  as HTMLInputElement).value =
           (props.groupPrice.price - props.groupPrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-2") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();

        (window.document.getElementById("input-day-prezzinetti-3")  as HTMLInputElement).value =
            (props.groupPrice.price - props.groupPrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-3") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();

        (window.document.getElementById("input-day-prezzinetti-4")  as HTMLInputElement).value =
            (props.groupPrice.price - props.groupPrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-4") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();

        (window.document.getElementById("input-day-prezzinetti-5")  as HTMLInputElement).value =
            (props.groupPrice.price - props.groupPrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-5") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();

        (window.document.getElementById("input-day-prezzinetti-6")  as HTMLInputElement).value =
            (props.groupPrice.price - props.groupPrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-6") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();

        (window.document.getElementById("input-day-prezzinetti-7")  as HTMLInputElement).value =
            (props.groupPrice.price - props.groupPrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-7") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();


    }


    const handleChange = (e: any) =>{
        props.onUpdate({
            ...props.groupPrice,
            price: isNaN(parseFloat(e.target.value))? 0 : parseFloat(e.target.value),
        });

        if(props.manager?.isActive(props.btnIndex)){
            updatePriceNet();
        }
    }


    const handleViewPrice = (e: string | undefined) => {
        if(e!= undefined) {
            props.manager?.toggleActive(e);
            updatePriceNet();
        }
    }





    return (
        <Grid  container spacing={2} style={{marginBottom:"20px"}}>
            <Grid item xs={12} sm={3} md={2} lg={2} className={"fasce-gruppi-prezzi-2"}>
                <Typography
                    className={"title-giorno-timetable"}
                    align={"right"}
                    variant="h6"
                >
                    {props.groupPrice.price_label_group}
                </Typography>
            </Grid>
            <Grid   item xs={12} sm={3} md={2} lg={2} className={"price-to-discount" + `${(props.manager?.isActive(props.btnIndex))? " currentPriceToDiscount" : ""} `}>
                <PriceInput

                    value={props.groupPrice.price}
                    onChange={(e) =>
                        handleChange(e)
                    }
                />
            </Grid>
            <Grid  item xs={10} sm={5} md={3} lg={3} >
                <BaseButton tabIndex={-1} variant={"outlined"}
                            id={props.btnIndex} onClick={(e) => handleViewPrice(props.btnIndex)}
                            className={ `bottoni-calcola-prezzi  bottoni-tondi-margin ${props.manager?.isActive(props.btnIndex) ? "custom-unif-selected-back" : "custom-unif-unselected-back "} `}
                >
                    calcola prezzi netti
                </BaseButton>
            </Grid>
        </Grid>
    );
};

export default GroupPriceItem;
