import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import BaseButton from "./BaseButton";

export interface DeleteDialogProps {
  toggleDialog: () => void;
  onConfirm: (e: any) => void;
  titleDelete?: string;
  textDelete?: string;
  item? : any;
  additionalclasses?: string;
}

const DeleteDialog = (props: DeleteDialogProps) => {

  return (
    <Dialog
        className={"delete-item-dialog "+(props.additionalclasses ?? ' ')}
      title={(props.titleDelete)? props.titleDelete: "Confermare cancellazione"}
      onClose={props.toggleDialog}
        height={'25vh'}
      style={{ zIndex: 10003  }}
    >
      <p style={{ margin: "25px", marginTop: '0px' , height:'90px', textAlign: "center" }}>
        {props.item !== undefined ? (
            props.textDelete+" "+props.item.name +"?"
        ) : (props.textDelete !== undefined ?  props.textDelete+"?": " Sei sicuro di voler continuare?")
        }

      </p>
      <DialogActionsBar>
        <BaseButton onClick={props.toggleDialog}
                    className={"custom-unif-arancione-back"}>No</BaseButton>
        {/* <button className="k-button" onClick={props.toggleDialog}>
          No
        </button> */}
        <BaseButton
            className={"custom-unif-viola-back"}
          onClick={(e) => {
            props.onConfirm(e);
            props.toggleDialog();
          }}
        >
          Sì
        </BaseButton>
        {/* <button
          className="k-button"
          onClick={() => {
            props.onConfirm();
            props.toggleDialog();
          }}
        >
          Sì
        </button> */}
      </DialogActionsBar>
    </Dialog>
  );
};

export default DeleteDialog;
