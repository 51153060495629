import {FC, useState} from "react";
import {Grid} from "@mui/material";
import PriceInput from "./PriceInput";
import CloseIcon from "@mui/icons-material/Close";
import {SeasonPriceItemProps} from "../../models/props/SeasonPriceItemProps";
import DateRangePicker from "../common/DateRangePicker";
import BaseIconButton from "components/common/BaseIconButton";
import BaseButton from "../common/BaseButton";

const SeasonPriceItem: FC<SeasonPriceItemProps> = (props) => {
    const updatePriceNet = () => {
        (window.document.getElementById("input-day-prezzinetti-1")  as HTMLInputElement).value =
            (props.seasonPrice.price - props.seasonPrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-1") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();

        (window.document.getElementById("input-day-prezzinetti-2")  as HTMLInputElement).value =
            (props.seasonPrice.price - props.seasonPrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-2") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();

        (window.document.getElementById("input-day-prezzinetti-3")  as HTMLInputElement).value =
            (props.seasonPrice.price - props.seasonPrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-3") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();

        (window.document.getElementById("input-day-prezzinetti-4")  as HTMLInputElement).value =
            (props.seasonPrice.price - props.seasonPrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-4") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();

        (window.document.getElementById("input-day-prezzinetti-5")  as HTMLInputElement).value =
            (props.seasonPrice.price - props.seasonPrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-5") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();

        (window.document.getElementById("input-day-prezzinetti-6")  as HTMLInputElement).value =
            (props.seasonPrice.price - props.seasonPrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-6") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();

        (window.document.getElementById("input-day-prezzinetti-7")  as HTMLInputElement).value =
            (props.seasonPrice.price - props.seasonPrice.price * parseInt((window.document.getElementById("sconto-day-prezzinetti-7") as HTMLInputElement)?.value) / 100).toFixed(2).replace(".", ",").toString();


    }
    const handleChange = (e: any) =>{
        props.onUpdate({
            ...props.seasonPrice,
            price: isNaN(parseFloat(e.target.value))? 0 : parseFloat(e.target.value),
        });
        if(props.manager?.isActive(props.btnIndex)){
            updatePriceNet();
        }

    }

  const handleViewPrice = (e: string | undefined) => {
    if(e!= undefined) {
        props.manager?.toggleActive(e);


        updatePriceNet();
    }
  }




    return (
      <Grid
          container spacing={2} style={{marginBottom:"20px"}}
      >
          <Grid item xs={12} sm={12} md={1} lg={2} className={"custom-left-spacing-prices"}></Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}  className={"fasce-anni-prezzi"}>
          <DateRangePicker
              range={{ startDate: props.seasonPrice.date_start as Date, endDate: props.seasonPrice.date_end as Date }}
              onChange={(dateRange) => {
                if (dateRange.endDate !== null && dateRange.startDate !== null)
                  props.onUpdate({ ...props.seasonPrice, date_start: dateRange.startDate, date_end: dateRange.endDate })
              }
              }
              format="dd/MM"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2} lg={1} className={"price-to-discount "+ `${(props.manager?.isActive(props.btnIndex) )? " currentPriceToDiscount" : ""} `}>

            <PriceInput

                value={props.seasonPrice.price}
                onChange={(e) =>
                  handleChange(e)
                }
            />
        </Grid>
          <Grid  item xs={10} sm={6} md={6} lg={3} >
              <BaseButton tabIndex={-1} variant={"outlined"}
                          id={props.btnIndex}
                          // disabled={(props.isBtnActive && !active) }
                          onClick={(e) => handleViewPrice(props.btnIndex)}
                          className={ `bottoni-calcola-prezzi bottoni-tondi-margin  ${( props.manager?.isActive(props.btnIndex)  )? "custom-unif-selected-back" : "custom-unif-unselected-back "} `}>
                  calcola prezzi netti
              </BaseButton>
          </Grid>
        <Grid  item xs={1} sm={1} md={1} lg={1} className={"forza-bottoni-a-sinistra"}>
          <BaseIconButton tabIndex={-1} onClick={props.onDelete} className={"custom-unif-arancione-back delete-fascia-bottone bottoni-tondi std-bottoni-tondi bottoni-tondi-margin"}>
            <CloseIcon />
          </BaseIconButton>
        </Grid>
      </Grid>
  );
};

export default SeasonPriceItem;
