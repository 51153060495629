import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IToken} from "../models/IToken";
import {SliceState} from "../models/SliceState";
import oldApi from "../utils/api/authApi";
import {authApi} from "./api/auth";
import {IUserCheckRegistrationPasswordViewModel} from "../models/views/IUserCheckRegistrationPasswordViewModel";
import {IUserCheckRegistrationViewModel} from "../models/views/IUserCheckRegistrationViewModel";
import {IDataSignIn} from "../models/IDataSignIn";
import {IUserChangeEmailRegistrationViewModel} from "../models/views/IUserChangeEmailRegistrationViewModel";

export const userCheckRegistration = createAsyncThunk(
  "auth/userCheckRegistration",
  async (credentials: IUserCheckRegistrationViewModel, thunkAPI) => {
    return (
      await oldApi.userCheckRegistration(credentials.uid, credentials.checkcode)
    ).data;
  }
);

export const userCheckRecoveryLink = createAsyncThunk(
  "auth/userCheckRecoveryLink",
  async (credentials: IUserCheckRegistrationViewModel, thunkAPI) => {
    return (
      await oldApi.userCheckRecoveryLink(credentials.uid, credentials.checkcode)
    ).data;
  }
);

export const changeLinkRecoveryPassword = createAsyncThunk(
  "auth/changeLinkRecoveryPassword",
  async (credentials: IUserCheckRegistrationPasswordViewModel, thunkAPI) => {
    return (
      await oldApi.changeLinkRecoveryPassword(
        credentials.uid,
        credentials.checkcode,
        credentials.email,
        credentials.password,
        credentials.passwordconfirm
      )
    ).data;
  }
);

export const userCheckRegistrationPassword = createAsyncThunk(
  "auth/userCheckRegistrationPassword",
  async (credentials: IUserCheckRegistrationPasswordViewModel, thunkAPI) => {
    return (
      await oldApi.userCheckRegistrationPassword(
        credentials.uid,
        credentials.checkcode,
        credentials.email,
        credentials.password,
        credentials.passwordconfirm
      )
    ).data;
  }
);

export const userChangeEmail = createAsyncThunk(
  "auth/userChangeEmail",
  async (credentials: IUserChangeEmailRegistrationViewModel, thunkAPI) => {
    return (
      await oldApi.userChangeEmail(
        credentials.uid,
        credentials.checkcode,
        credentials.oldemail,
        credentials.newemail
      )
    ).data;
  }
);

interface AuthState {
  jwt: string;
  loggedIn: boolean;
  authState: SliceState;
  autoLoginState: SliceState;
  user?: IToken;
  dataSignIn?: IDataSignIn;
  // ha provato a fare login (clic pulsante)
  passedInLoginPage: boolean;
}

const initialState: AuthState = {
  jwt: "",
  loggedIn: false,
  authState: {
    state: "idle",
  },
  autoLoginState: {
    state: "idle",
  },
  user: undefined,
  passedInLoginPage: false,
};

// function setAuthState(state: AuthState, token: string, responseData: any) {
//   console.log(responseData);
//   const tokenInfo = decodeToken(token);
//   console.log("token info");
//   console.log(tokenInfo);
//   state.user = {
//     username: tokenInfo.username,
//     id: tokenInfo.userId,
//     operativeId: tokenInfo.userOperativeId,
//     companyId: tokenInfo.companyId,
//     role: tokenInfo.role,
//   };
//   state.jwt = token;
//   state.loggedIn = true;
//   if (state.user)
//     state.user = {
//       ...state.user,
//       email: responseData.user.email
//         ? responseData.user.email
//         : state.user.email ?? "",
//       name: responseData.user.name
//         ? responseData.user.name
//         : state.user.name ?? "",
//       surname: responseData.user.surname
//         ? responseData.user.surname
//         : state.user.surname ?? "",
//       username: responseData.user.username
//         ? responseData.user.username
//         : state.user.username ?? "",
//     };
// }
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setPassedLoggedIn(state, action: PayloadAction<boolean>) {
      state.passedInLoginPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
        localStorage.setItem("token", action.payload.token);
        state.jwt = action.payload.token;
        state.user = action.payload.user;
        state.loggedIn = true;
      })
      .addMatcher(
        authApi.endpoints.autoLogin.matchFulfilled,
        (state, action) => {
          state.jwt = action.payload.token;
          state.user = action.payload.user;
          state.loggedIn = true;
        }
      )
      .addMatcher(
        authApi.endpoints.userCheckRegistrationPassword.matchFulfilled,
        (state, action) => {
          localStorage.setItem("token", action.payload.token);
          state.jwt = action.payload.token;
          state.user = action.payload.user;
          state.loggedIn = true;
        }
      )
      .addMatcher(
        authApi.endpoints.userCheckRecoveryLinkPassword.matchFulfilled,
        (state, action) => {
          localStorage.setItem("token", action.payload.token);
          state.jwt = action.payload.token;
          state.user = action.payload.user;
          state.loggedIn = true;
        }
      )
      .addMatcher(authApi.endpoints.logout.matchFulfilled, (state, action) => {
        // console.log("ACTION LOGOUT");
        localStorage.clear();
        state.jwt = "";
        state.user = undefined;
        state.loggedIn = false;
      })
      .addMatcher(
        authApi.endpoints.selectUser.matchFulfilled,
        (state, action) => {
          state.jwt = action.payload.token;
          state.user = action.payload.user;
          localStorage.clear();
          localStorage.setItem("token", action.payload.token);
          state.loggedIn = true;
        }
      );
  },
});

export const { setPassedLoggedIn } = authSlice.actions;

export default authSlice.reducer;
