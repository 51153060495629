import CompanyForm from "../../components/companies/CompanyForm";
import {ICompany} from "../../models/entities/ICompany";
import CenteredLoadingSpinner from "../../components/common/CenteredLoadingSpinner";
import PageTitle from "../../components/common/PageTitle";
import {useGetCompanyQuery} from "../../store/api/companies";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {useMe} from "../../utils/hooks/me";
import * as React from "react";

const AziendaPage = () => {
  const user = useMe()
  const {
    data: company,
    isLoading,
  } = useGetCompanyQuery(user?.companyId ?? skipToken);



  return (
    <div style={{ paddingTop: "1vh", paddingLeft: "2.5vh", overflowY: "hidden" }}>
      {/* <Typography variant="h4" style={{ margin: "5px" }}><BusinessIcon></BusinessIcon> Azienda di {user?.id}</Typography> */}
      <PageTitle icon="business" title="Azienda" />{" "}
      {/*title={`Azienda di ${user?.username}`}*/}
      {isLoading ? (
        <CenteredLoadingSpinner />
      ) : (
        <CompanyForm
          cancelEdit={() => {}}
          onSubmit={() => {}}
          item={company as ICompany}
          hasCancel={false}
        />
      )}
    </div>
  );
};

export default AziendaPage;
