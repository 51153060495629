import {isFulfilled, isRejectedWithValue, Middleware, MiddlewareAPI,} from "@reduxjs/toolkit";
import toast from "react-hot-toast";


/**
* Set toast display duration
* */
const TOAST_DURATION = 5000;
/**
 * Log a warning and show a toast!
 */
const ignoredRequest = [
  "login",
  "userCheckRecoveryLink",
  "userCheckRegistration",
  "userCheckRegistrationPassword",
];
function isAddRequest(action: any) {
  return action?.meta?.baseQueryMeta?.request?.method === "POST";
}
function isDeleteRequest(action: any) {
  return action?.meta?.baseQueryMeta?.request?.method === "DELETE";
}
function isUpdateRequest(action: any): Boolean {
  // return action?.meta?.baseQueryMeta?.request?.method === "POST";
  return action.meta?.baseQueryMeta !== undefined
    ? action.meta.baseQueryMeta?.request !== undefined
      ? action.meta.baseQueryMeta.request.method === "PUT"
      : false
    : false;
}
function isIgnoredRequest(action: any): Boolean {
  // console.log("action");
  // console.log(action.meta.arg.endpointName);
  if (action.meta.arg.endpointName)
    return ignoredRequest.includes(action.meta.arg.endpointName);
  else return false;
}

const notificationsMiddleware: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
    (next) =>
      (action) => {
        // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
        // toast.error("error");
        // console.log(
        //   action.meta?.baseQueryMeta !== undefined
        //     ? action.meta.baseQueryMeta?.request !== undefined
        //       ? action.meta.baseQueryMeta.request.method === "PUT"
        //       : ""
        //     : ""
        // );

        if (
          ((isFulfilled(action)) &&
          (isAddRequest(action) || isUpdateRequest(action)) &&
          (!isIgnoredRequest(action)) )|| (isDeleteRequest(action))
        ) {
          // https://react-hot-toast.com/docs/toast for custom toast
          // la notifica è customizzabile nell' app.tsx
          // come è fatta adesso si può inserire anche dell'html, per esempio toast.success(`<div><b>ciao</b> ecco</div>`);
            if(action.meta.arg.endpointName !== "readMessage" && action.meta.arg.endpointName !== "sendMessage" ) {
                toast.success("Operazione avvenuta con successo", {duration : TOAST_DURATION });
            }
        }

        // if (isRejected(action)) {
        //   console.log("RIFIUTATO")
        // }

        if (isRejectedWithValue(action)) {
          let message = action.payload?.data?.aMessage;

          if (action.payload?.status === 403)
            toast.error("Utente non autorizzato ad accedere alla funzionalità", {duration : TOAST_DURATION });
          else
            toast.error(message || "Errore, riprova più tardì", {duration : TOAST_DURATION });
          // console.log(action);
          //console.log(message);
        }

        // if (act)

        // console.log(isFulfilled(action) ? isFulfilled(action) : " boh");
        // console.log(action.error ? true : " boh reject");
        if (action.error && isUpdateRequest(action)) {
          // toast.error(action.error.data.message.toString());
          // toast.error(`MODIFICA`);
          // toast.error(`MODIFICA: /n${action.error.data.aMessage}`);
        }
        // if (isFulfilled(action) && isUpdateRequest(action)) {
        //   toast.success(`DATO MODIFICATO`);
        // }

        return next(action);
      };

export default notificationsMiddleware;
