import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface PlannerState {
  currentPlannerId?: number;
  currentSeasonId?: number;
  currentFirstDayWeek: string;
}

const initialState: PlannerState = {
  currentPlannerId: undefined,
  currentSeasonId: undefined,
  currentFirstDayWeek: "default",
};

const plannersSlice = createSlice({
  name: "planners",
  initialState,
  reducers: {
    selectPlanner(state, action: PayloadAction<number|undefined>) {
      state.currentPlannerId = action.payload;
    },
    selectSeason(state, action: PayloadAction<number>) {
      state.currentSeasonId = action.payload;
    },
    selectWeek(state, action: PayloadAction<string>) {
      state.currentFirstDayWeek = action.payload;
    },
  },
});

export const { selectPlanner, selectSeason, selectWeek } =
  plannersSlice.actions;

export default plannersSlice.reducer;
