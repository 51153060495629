import {Dialog} from "@progress/kendo-react-dialogs";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import CustomTitleBar from "../common/CustomTitleBar";
import React, {useEffect, useState} from "react";
import {IGuest} from "../../models/entities/IGuest";
import {apiPath} from "../../store/api/api";
import {IGuestDetails} from "models/entities/IGuestDetails";
import CenteredLoadingSpinner from "../common/CenteredLoadingSpinner";
import {
    Grid,
    GridCellProps,
    GridFooterCellProps,
    GridHeaderCellProps,
    GridRowProps,
    GridSortChangeEvent
} from "@progress/kendo-react-grid";
import {orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import BaseButton from "../common/BaseButton";
import {useMe} from "../../utils/hooks/me";
import {NumberFormatOptions} from "@progress/kendo-react-intl";
import {useAppSelector} from "../../store";
import {useUpdateGuestMutation} from "../../store/api/guests";
import {SCREEN_XL, useWindowSize} from "../common/ScreenSizes";
import {GridLayout, GridLayoutItem} from "@progress/kendo-react-layout";
import {CircularProgress} from "@mui/material";

const MAX_CHAR = 50;
const state0 = 'da fare';
const state1 = 'effettuata';
const state2 = 'cancellata';

const GuestsView = (props: EditFormProps<IGuest>) => {
    const token = localStorage.getItem("token");
    const {  ivacombos} = useAppSelector((store) => store.types);
    const header_get =  {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }


    const [guestDetails, setGuestDetails] = useState<IGuestDetails[]|null>(null)
    const [showError, setShowError] = useState<string>("")
    const [total_taxable_income, setTotal_taxable_income] = useState<string|null>(null)
    const [total, setTotal] = useState<IGuestDetails|null>(null)
    const [isLoadingExport, setLoadingExport] = useState<boolean>(false)
    const user = useMe();

    const [,setGuest] = useState<IGuest>();
    const [sort, setSort] = useState<SortDescriptor[]>([{ field: "date_time_event", dir: "asc" }]);

    const size = useWindowSize();

    const [colWidth , ] = useState<string>('32vw')


    useEffect(()=>{
        if(props.item.id){
            getGuestDetail(props.item.id)

            setShowError("")
        } else{
            setShowError("Non è stato possibile trovare le prenotazioni effettuate, riprova più tardi")
        }

    },[props.item])

    //FETCH DEI FILE
    const getExcelToken = (id: number) => {
        if(user && user.companyId) {
            let optObjHeadBody = {
                method: 'GET',
                headers:header_get,

            };
            fetch(apiPath +"reseller/" + user.companyId +"/reservationsExcel/"+id, optObjHeadBody)
                .then(res => res.json())
                .then(
                    (result) => {
                        if(result.aData) {
                            download(result.aData, "/downloadFile.php?fileName=" +result.aData+"&type=1");
                        }
                    },
                    // Nota: è importante gestire gli errori qui
                    // invece di un blocco catch() in modo da non fare passare
                    // eccezioni da bug reali nei componenti.
                    (error) => {

                        setLoadingExport(false);
                        // setIsLoaded(true);
                        // setError(error);
                    }
                )
            return () =>{}
        }
    }

    const getGuestDetail = (id: number) => {
            let optObjHeadBody = {
                method: 'GET',
                headers:header_get,

            };
            fetch(apiPath +"guest/details/" +id, optObjHeadBody)
                .then(res => res.json())
                .then(
                    (result) => {
                        if(result.bResult) {
                            setGuest(result);
                            setShowError("")
                            setGuestDetails(result.prenotations)
                            setTotal_taxable_income(result.total_taxable_income)
                            setTotal(result.total)

                        }else{
                            setShowError("Non è stato possibile trovare le prenotazioni effettuate, riprova più tardi")
                        }
                    },
                    (response) => {
                        //todo magari custom error
                        setShowError("Non è stato possibile trovare le prenotazioni effettuate, riprova più tardi")
                    },
                )

    }


    const footer_pax_cell = (props: GridFooterCellProps) => {
        return (
            <td colSpan={props.colSpan} style={props.style}>
                Totale
            </td>
        );
    };


    const total_cell = (props: GridFooterCellProps) => {
        return (
            <td colSpan={props.colSpan} style={props.style}>
                {total} €
            </td>
        );
    };
    const td_ref_cell = (props: GridCellProps) => {
        return (
            <td>
                {props.dataItem.refund_amount ? props.dataItem.refund_amountFormatted + " €" : ""  }
            </td>
        );
    };

    const td_deleted_cell = (props: GridCellProps) => {
        let stato;

        if (props.dataItem.state === 0) stato = state0
        if (props.dataItem.state === 1) stato = state1
        if (props.dataItem.state === 2) stato = state2


        return (
            <td>
                {stato}
            </td>
        );
    };
    const th_ref_cell = (props: GridHeaderCellProps) => {
        return (
            <span title={"Rimborso"}>
                Rimborso
            </span>
        );
    };

    const th_pax_cell = (props: GridHeaderCellProps) => {
        return (
            <span title={"Pax"}>
                Pax
            </span>
        );
    };

    const td_total_cell = (props: GridCellProps) => {
        return (
            <td>
                {props.dataItem.totalFormatted} €
            </td>
        );
    };

    const download  = (name: string, URL : string) => {
        fetch(URL, {
            method: 'GET',
            headers: header_get,
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    name,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                if(link.parentNode) {
                    link.parentNode.removeChild(link);
                }

                setLoadingExport(false);

            }, (error) => {


            });
    }
    // }

    const rowRender = (
        trElement: React.ReactElement<HTMLTableRowElement>,
        props: GridRowProps
    ) => {
        let style;
        if(props.dataItem.state === 2) {
            style = {height: "80px", backgroundColor: "#d7d6d3"};
        }else{
            style = { height: "80px" }
        }
        const trProps: any = { style: style };
        return React.cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    };


    return (
        <Dialog
            className={"users-form-media-rule guest-form guest-details-dialog"}
            title={
                <CustomTitleBar
                    type={"Prenotazioni"}
                    label={props.item.fullname}
                    icon="people"
                />
            }
            onClose={props.cancelEdit}
        >
            {
                (guestDetails === null && showError === "") ?
                    ( <CenteredLoadingSpinner />)
                    :
                    (guestDetails && guestDetails?.length !== 0    ) ?
                        <div style={{textAlign: "center"}}>
                        <Grid
                            sortable={true}
                            dataItemKey={"id"}
                            data={orderBy( guestDetails , sort)} // devo far vedere solo le aviable se sono ilr eception
                            rowRender={rowRender}
                            sort={sort}
                            onSortChange={(e: GridSortChangeEvent) => {
                                setSort(e.sort);
                            }}
                            className={"guest-details-grid"}

                        >

                            <Column field="booking_code" title="Codice"    width={140} />
                            <Column field="item_label" title="Esperienza"
                                    cell={(props: GridCellProps) => (

                                        <td style={{cursor: "pointer"}}>
                                            <a  target={"_blank"} title={props.dataItem.item_label}>
                                                {(props.dataItem.item_label.length >= MAX_CHAR && size <= SCREEN_XL) ?
                                                    props.dataItem.item_label.substring(0, MAX_CHAR) + "..."
                                                    : props.dataItem.item_label} </a>
                                        </td>

                                    )}
                            />
                            <Column field="state" width={130} title="Stato"  cell={td_deleted_cell} />
                            <Column field="locality" width={130} title="Località" />
                            <Column field="pax" title="Pax"  width={(size <= SCREEN_XL) ? 60 : 100}  headerCell={th_pax_cell} />
                            <Column field="booking_date" width={140} title="Data Prenotazione"   />
                            <Column field="date_time_event" width={170} title="Data evento" footerCell={footer_pax_cell}  />
                            <Column field="refund_amount" title="Rimborso" width={140}  cell={td_ref_cell} headerCell={th_ref_cell} />
                            {/*<Column field="taxable_incomeFormatted" title="Imponibile"  footerCell={taxable_income_cell} cell={td_taxable_income}  width={140} />*/}
                            {/*<Column field="vat" title="Aliquota Iva" width={90}  cell={td_vat_cell} headerCell={th_vat_cell} />*/}
                            <Column field="totalFormatted" title="Importo"   footerCell={total_cell}   cell={td_total_cell}  width={140} />

                        </Grid>
                            <GridLayout
                                style={{paddingTop: "20px"}}
                                gap={{ rows:5, cols: 17 }}
                                //10 righe, magari troviamo il modo di indicarlo diversamente
                                rows={[
                                        { height: '40px' },
                                    ]
                                }
                                cols={ [
                                    { width: colWidth },
                                    { width: colWidth },
                                    { width: colWidth },
                                ] }
                                align={{ horizontal: "stretch" }}

                            >

                            <GridLayoutItem  row={1} col={2} colSpan={1} style={{textAlign:"center"}}>
                                <BaseButton
                                    className={"custom-unif-viola-back"}
                                    variant="contained"
                                    onClick={() => {
                                        if(props.item.id) {
                                            setLoadingExport(true);
                                            getExcelToken(props.item.id);
                                        }

                                    }}
                                    startIcon={isLoadingExport?  <CircularProgress style={{ width:"20px", height: "20px", color: "white" }}/> : <span className="k-icon k-i-file-excel
                                                                    k-i-file-xls
                                                                    k-i-excel
                                                                    k-i-xls"></span>}
                                >
                                    Esporta Excel
                                </BaseButton>
                            </GridLayoutItem>

                            {/*<GridLayoutItem  row={1} col={3} colSpan={1}  style={{textAlign:"left"}}>*/}
                            {/*    <BaseButton*/}
                            {/*        className={`${*/}
                            {/*            !props.item.paid*/}
                            {/*                ? "custom-unif-viola-back"*/}
                            {/*                : ""*/}
                            {/*        }`}*/}
                            {/*        variant="contained"*/}
                            {/*        style={{marginLeft: "18px", paddingLeft: "18px"}}*/}
                            {/*        onClick={()=>setPaidDialog(true)}*/}
                            {/*        disabled={props.item.paid ?? false}*/}
                            {/*    >*/}
                            {/*        Conto Saldato*/}
                            {/*    </BaseButton>*/}
                            {/*</GridLayoutItem>*/}
                            {/*    {paidDialog && (*/}
                            {/*        <DeleteDialog*/}
                            {/*            titleDelete={"Conferma Saldo"}*/}
                            {/*            textDelete={"Sei sicuro di voler saldare il conto?"}*/}
                            {/*            toggleDialog={() => {*/}
                            {/*                setPaidDialog(!paidDialog);*/}
                            {/*            }}*/}
                            {/*            onConfirm={(e)=>handleSubmitPaid(e)}*/}
                            {/*        />*/}
                            {/*    )}*/}
                            </GridLayout>
                        </div>:
                        <h1>{showError}</h1>
            }
        </Dialog>
    );
};
export default GuestsView;
