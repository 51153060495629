import {Dialog} from "@progress/kendo-react-dialogs";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import CustomTitleBar from "../common/CustomTitleBar";
import React, {Fragment} from "react";
import {ListView, ListViewItemProps} from "@progress/kendo-react-listview";
import {IGuest} from "../../models/entities/IGuest";
import {useAppSelector} from "../../store";



const GuestsLinkView = (props: EditFormProps<IGuest>) => {


    const itemRender = (props: ListViewItemProps) => {
        let item = props.dataItem;
        return (
            <div
                className="k-listview-item listview-link"
            >
                {item}
            </div>
        );
    };
    return (
        <Dialog
            className={"users-form-media-rule guest-link-details-dialog"}
            title={
                <CustomTitleBar
                    type={""}
                    label={props.item.fullname}
                    icon="public"
                />
            }
            onClose={props.cancelEdit}
        >
            {(props.item.when_link_opened && props.item.when_link_opened.length > 0) ?
                (
                    <>
                        <label className={"link-label-dialog"}>{props.item.link_status_label}</label>
                        <br/>
                        <br/>
                        <label className={"link-label-dialog"}>Link aperto: </label>
                        <ListView
                            className={"listview-link-container"}
                            data={props.item.when_link_opened}
                            item={itemRender}

                        />
                    </>
                )
                : 'Link non ancora utilizzato dall\'ospite'
            }
        </Dialog>
    );
};
export default GuestsLinkView;
