import {ISubscriber} from "../../models/entities/calendar/ISubscriber";
import {EditFormProps} from "../../models/helpers/IEditFormProps";
import CustomTitleBar from "../common/CustomTitleBar";
import CenteredLoadingSpinner from "../common/CenteredLoadingSpinner";
import React, {Fragment, useEffect, useState} from "react";
import {Dialog} from "@progress/kendo-react-dialogs";
import {useCurrentAppointment} from "../../utils/hooks/currentAppointment";
import {useGetExperienceQuery} from "../../store/api/experiences";
import {skipToken} from "@reduxjs/toolkit/dist/query/react";
import {CircularProgress, Grid} from "@mui/material";
import {Calendar, CalendarCell, CalendarCellProps,} from "@progress/kendo-react-dateinputs";
import {
    useGetAppointmentPossibleDaysQuery,
    useGetAppointmentPossibleSlotsQuery,
    useInvalidateAppointmentPossibleDaysMutation,
    useSetNewDateMutation
} from "../../store/api/calendar";
import {
    addMinutes,
    dateToStringDDmonthYYYYwithsepator,
    dateToStringYYYYMMDD,
    toDateNoTimeFromApi
} from "../../utils/dateHelper";
import PeopleIcon from '@mui/icons-material/People';
import TodayIcon from '@mui/icons-material/Today';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {CalendarChangeEvent} from "@progress/kendo-react-dateinputs/dist/npm/calendar/components/Calendar";
import {ApiAppointmentExtended, ApiAppointmentSmall} from "../../models/dto/calendar/IGetDaysDto";
import BaseButton from "../common/BaseButton";
import SaveIcon from "@mui/icons-material/Save";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

export const SubscriberChangePren = (props: EditFormProps<ISubscriber>) =>{

    const { currentAppointment  } = useCurrentAppointment();

    const [skip, setSkip] = useState<boolean>(props.item.id === undefined )
    const [subscriberId, setSubscriberId] = useState<number>(props.item.id ?? 0)
    const [dateSelected, setDateSelected] = useState<string>('')
    const [slotSelected, setSlotSelected] = useState<number|null>(null)

    const [DateString, SetDateString] =useState<string>('')
    const [TimeString, SetTimeString] =useState<string>('')
    const [setNewDate, { isLoading: isLoadingNewDate , isSuccess:  isSuccessNewDate , isError: isErrorNewDate,  error: errorNewDate}] =
        useSetNewDateMutation();

    const {
        data : PossibleDays,
        isLoading: isLoadingPossibleDays,
        isFetching: isFetchingPossibleDays} = useGetAppointmentPossibleDaysQuery(props.item.id ?? skipToken);
    const [invalidatePosDays,{isLoading: isInvalidatingCache}] = useInvalidateAppointmentPossibleDaysMutation();

    const {
        data : PossibleSlots,
        isLoading: isLoadingPossibleSlots,
        isFetching: isFetchingPossibleSlots} = useGetAppointmentPossibleSlotsQuery(
            {
                id: subscriberId ,
                day: dateSelected
            } , {skip});

    const CheckDate = (dateyyyymmdd: string): boolean => {
        if(PossibleDays) {
            if(PossibleDays.appointment.find((el: ApiAppointmentSmall) => el.calendar_date === dateyyyymmdd)){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }

    const customCell = (props: CalendarCellProps) => {
        return (
            <CalendarCell
                className={CheckDate(dateToStringYYYYMMDD(props.value))? 'available-day':'not-available-day'}
                isDisabled={CheckDate(dateToStringYYYYMMDD(props.value))}
                {...props}
            />);
    };

    const HandleChangeDate = (e: CalendarChangeEvent) =>{
        if (props.item.prenotation && props.item.id) {
            setSubscriberId(props.item.id)
            setDateSelected(dateToStringYYYYMMDD(e.value))
            setSlotSelected(null);
            setSkip(false);
        }
    }

    useEffect(()=>{
        if(currentAppointment) {
            let dateStart = new Date(currentAppointment.start as string);
            dateStart = addMinutes(dateStart, -0);
            // let dateEnd = new Date(currentAppointment.end as string);
            // dateEnd = addMinutes(dateEnd, -0);
            let tit = `${dateToStringDDmonthYYYYwithsepator(
                dateStart
            )}`;
            SetDateString(tit)
            SetTimeString('Dalle '+currentAppointment.begin_time+" alle "+currentAppointment.end_time);
        }

    },[currentAppointment])


    const HandleSubmit = () => {

        let appFromSlot = PossibleSlots?.appointment.find(el => el.id === slotSelected);
        if(appFromSlot ){
            setNewDate({
                id: subscriberId,
                appointment: appFromSlot
            })
        }

    }

    //gestiamo il ritorno della chiamata setNewDate
    useEffect(()=>{
        if(isSuccessNewDate){
            props.cancelEdit();
        }
    },[isErrorNewDate,isSuccessNewDate])



    return (
    <Dialog
        title={
            <>
                <CustomTitleBar
                    type={"Iscritto"}
                    label={props.item.name }
                    customComp={"Cambio Data/Orario"}
                    icon="calendar"
                    prenoNumb={ props.item.prenotation }
                />
            </>
        }
        className={"dialog-sub dialog-sub-change-pren"}
        onClose={()=>{
            if(props.item.id) {
                invalidatePosDays(props.item.id);
            }
            props.cancelEdit()
        }}
    >

            <Grid container >
                <Grid item xs={12}  sm={12} md={6} lg={3} xl={3}>
                    <h2 className={'custom-unif-viola'}>Prenotazione attuale</h2>
                    <p>
                        <TodayIcon color={'primary'} className={'custom-unif-viola'}  style={{float: "left", marginTop: "-2px"}} />
                        <span className={"string-at-right-of-icon"}>{" "+DateString}</span>
                    </p>
                    <p>
                        <AccessTimeIcon color={'primary'} className={'custom-unif-viola'} style={{float: "left", marginTop: "-2px"}} />
                        <span className={"string-at-right-of-icon"}>{" "+TimeString}</span>
                    </p>
                    <p>
                        <PeopleIcon color={'primary'} className={'custom-unif-viola'} style={{float: "left", marginTop: "-2px"}} />
                        <span className={"string-at-right-of-icon"}>{" "+props.item.total_number+ " partecipanti"}</span>
                    </p>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                    <h2 className={'custom-unif-viola'}>Seleziona una nuova data</h2>
                    {isLoadingPossibleDays || isFetchingPossibleDays || isInvalidatingCache ? (
                        <CenteredLoadingSpinner></CenteredLoadingSpinner>
                    ) : (
                        PossibleDays && PossibleDays.appointment && PossibleDays.appointment.length &&
                            <Calendar
                                    min={toDateNoTimeFromApi(PossibleDays.appointment[0].calendar_date)}
                                    max={toDateNoTimeFromApi(PossibleDays.appointment[PossibleDays.appointment.length -1].calendar_date)}
                                    cell={customCell} onChange={(e: CalendarChangeEvent) => HandleChangeDate(e)}/>

                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    {isLoadingPossibleSlots || isFetchingPossibleSlots ? (
                        <CenteredLoadingSpinner></CenteredLoadingSpinner>
                    ) : (
                       PossibleSlots  ?
<>
                           <h2 className={'custom-unif-viola'}>Seleziona un orario</h2>
                           <div style={{maxHeight: '40vh', overflowY: 'auto'}}>
                               {PossibleSlots.appointment.length > 0 ?
                                       PossibleSlots.appointment.map((el: ApiAppointmentExtended) => {

                                           return (
                                               <p className={slotSelected === el.id ? 'selected-slot' : 'base-slot'}>
                                                   <AccessTimeIcon color={'primary'} className={'custom-unif-viola'}
                                                                   style={{float: "left"}}/>
                                                   <span
                                                       className={"string-at-right-of-icon"}
                                                   >
                                               {'Dalle ' + el.begin_time + " alle " + el.end_time}
                                           </span>
                                                   {slotSelected === el.id ?
                                                       <BaseButton
                                                           className={"custom-unif-arancione-back select-slot-btn"}
                                                           variant="contained"
                                                           size={'small'}
                                                           onClick={() => {
                                                               setSlotSelected(null)
                                                           }}
                                                       >Selezionato</BaseButton>
                                                       :

                                                       <BaseButton
                                                           style={{
                                                               marginTop: "-4px !important",
                                                               marginLeft: "5px !important"
                                                           }}
                                                           className={"custom-unif-viola-back select-slot-btn"}
                                                           variant="contained"
                                                           size={'small'}
                                                           onClick={() => {
                                                               setSlotSelected(el.id)
                                                           }}
                                                       >Seleziona</BaseButton>
                                                   }
                                               </p>
                                           );
                                       })
                                   :
                                   <p className={'custom-unif-arancione'}>
                                       Nessuno Slot disponibile per il giorno selezionato
                                   </p>
                                   }
                        </div>
</>
                        :
                           <>

                           </>
                    )}
                </Grid>
                <Grid container  item xs={12}  justifyContent="center" style={{marginTop: "2vh"}}>

                        <Fragment>
                            <BaseButton
                                className={slotSelected !== null  ? "custom-unif-viola-back" : 'custom-unif-grigio-back '}
                                type={"submit"}
                                variant="contained"
                                startIcon={isLoadingNewDate?  <CircularProgress style={{ width:"20px", height: "20px", color: "white" }}/> :<SaveIcon />}
                                disabled={slotSelected === null}
                                onClick={HandleSubmit}
                            >
                                Salva
                            </BaseButton>
                            {props.hasCancel && (
                                <BaseButton
                                    className={'custom-button-annulla custom-unif-grigio-back left-spacing-10p custom-unif-arancione-back' }
                                    startIcon={<CancelPresentationIcon />}
                                    onClick={props.cancelEdit}
                                >
                                    Annulla</BaseButton>
                            )}
                        </Fragment>
                </Grid>
            </Grid>

    </Dialog>
    );
}