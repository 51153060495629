import {IEquipment} from "../../models/entities/IEquipment";
import {useDeleteEquipmentMutation, useGetEquipmentsQuery,} from "../../store/api/equipments";
import CenteredLoadingSpinner from "../../components/common/CenteredLoadingSpinner";
import GenericGrid from "../../components/common/GenericGrid";
import EquipmentForm from "../../components/equipments/EquipmentForm";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {useEffect} from "react";
import {SCREEN_SM, useWindowSize} from "../../components/common/ScreenSizes";

const EquipmentsPage = () => {
  const currentPlanner = useCurrentPlanner();
  const {
    data: equipments,
    isFetching,
  } = useGetEquipmentsQuery(currentPlanner?.id ?? skipToken);
  const [deleteEquipment] =
    useDeleteEquipmentMutation();

  const remove = (dataItem: IEquipment) => {
    // dispatch(deleteEquipment(dataItem.id as number));
    deleteEquipment(dataItem?.id as number);
  };


  const size = useWindowSize();

  let nameWidth         = null;
  let descriptionWidth  = "0";
  let qtyWidth: string | undefined = "";
  let qtyRepWidth       = null;
  let qtyScrWidth       = null;

  useEffect(()=>{
  },[size]);

  if(size) {
   nameWidth         = (size < 750) ? "250" : "350";
   descriptionWidth  = (size < 1500) ? "0" : "450";
   qtyWidth          = (size < SCREEN_SM) ? "0" : undefined;
   qtyRepWidth       = (size < 1050) ? "0" : "";
   qtyScrWidth       = (size < 1050) ? "0" : "";
  }

  return (
    <div style={{ padding: "2.5vh" }} className={"equipments-grid"}>
      {/* <PageTitle icon="build" title="Attrezzature" /> */}
      {isFetching ? (
        <CenteredLoadingSpinner />
      ) : (
        <GenericGrid
          items={equipments as IEquipment[]}
          titleDelete={"Conferma cancellazione attrezzatura"}
          textDelete={"Sicuro di voler cancellare l'attrezzatura"}
          icon="build" 
          title="Attrezzature"
          itemPage={10}
          columns={[
            { field: "name", title: "Nome",      width : (nameWidth!== null? (  nameWidth): "")   },
            descriptionWidth!=="0" ? ({ field: "description", title: "Descrizione",    width : descriptionWidth  }) :
                ({ field: "qty123", title: "Operative" , width : "0"  }),
            qtyWidth !== undefined ? { field: "qty", title: "Operative" , width : qtyWidth   } :  { field: "qty", title: "Operative"    },
            { field: "repairing_qty", title: "In riparazione" ,  width : (qtyRepWidth!== null? qtyRepWidth: "")  },
            { field: "scrapping_qty", title: "Da rottamare" ,  width : (qtyScrWidth!== null?   qtyScrWidth: "")   },
          ]}
          enableCancel={true}
          onRemove={remove}
          addFormComponent={EquipmentForm}
          updateFormComponent={EquipmentForm}
        />
      )}

      {/*<ResponseSnackbar responseSnackbar={[*/}
      {/*  { sliceState: addEquipmentState, resetFunction: resetAddEquipment() as any} as IResponseSnackbar,*/}
      {/*  { sliceState: updateEquipmentState, resetFunction: resetUpdateEquipment() as any} as IResponseSnackbar,*/}
      {/*  { sliceState: deleteEquipmentState, resetFunction: resetDeleteEquipment() as any} as IResponseSnackbar,*/}
      {/*]} />*/}
    </div>
  );
};

export default EquipmentsPage;
