import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import BaseButton from "./BaseButton";

export interface AlertDialogProps {
  toggleDialog: () => void;
  onConfirm: () => void;
}

const AlertDialog = (props: AlertDialogProps) => {
  return (
    <Dialog
      title={"Ops!"}
      onClose={props.toggleDialog}
      style={{ zIndex: 1301 }}

    >
      <p style={{ margin: "25px", textAlign: "center" }}>
        Si è verificato un errore, riprova più tardi.
      </p>
      <DialogActionsBar>
        <BaseButton onClick={props.onConfirm}>Ok</BaseButton>

      </DialogActionsBar>
    </Dialog>
  );
};

export default AlertDialog;
