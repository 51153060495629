import { IWeek } from "models/entities/IWeek";
import { useGetPlannerWeeksQuery } from "store/api/planners";
import { useGetStaffWeeksQuery } from "store/api/staff";
import { useAppSelector } from "../../store";
import { skipToken } from "@reduxjs/toolkit/query/react";

export function usePlannerCurrentWeek() {
  const id = useAppSelector((s) => s.planners.currentPlannerId);
  const { data: currentPlannerWeeks = [] } = useGetPlannerWeeksQuery(
    id ?? skipToken
  );
  const { currentFirstDayWeek } = useAppSelector((s) => s.planners);

  // if (currentPlannerWeeks && (!currentFirstDayWeek || currentFirstDayWeek !== "default")) {
  //   return currentPlannerWeeks?.find(
  //     (w) => w.firstDayWeek === currentFirstDayWeek
  //   );
  // }

  // if (currentFirstDayWeek === "default") {
  //   return {} as IWeek;
  // }


  // if (currentPlannerWeeks) {
  //   return currentPlannerWeeks[0];
  // }

  // return {} as IWeek;

  return currentFirstDayWeek === "default"
    ? currentPlannerWeeks
      ? currentPlannerWeeks[0]
      : ({} as IWeek)
    : currentPlannerWeeks?.find((w) => w.firstDayWeek === currentFirstDayWeek);

}

export function useStaffCurrentWeek() {
  // const week = useAppSelector((s) =>
  //   s.staff.currentWeeks?.find(
  //     (w) => w.firstDayWeek === s.staff.currentFirstDayWeek
  //   )
  // );
  // const idPlanner = useAppSelector((s) => s.planners.currentPlannerId);
  const id = useAppSelector((s) => s.staff.currentStaffId);
  const { data: currentStaffWeeks = [] } = useGetStaffWeeksQuery(
    id ?? skipToken
  );
  const { currentFirstDayWeek } = useAppSelector((s) => s.staff);

  return currentFirstDayWeek === "default"
    ? currentStaffWeeks
      ? currentStaffWeeks[0]
      : ({} as IWeek)
    : currentStaffWeeks?.find((w) => w.firstDayWeek === currentFirstDayWeek);
}
