import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {SliceState} from "../models/SliceState";
import {IChangePasswordViewModel} from "../models/views/IChangePasswordViewModel";
import authApi from "../utils/api/authApi";

export const changePassword = createAsyncThunk(
  "changePassword/changePassword",
  async (credentials: IChangePasswordViewModel, thunkAPI) => {
    return await authApi.changePassword(
      credentials.oldpassword,
      credentials.newpassword,
      credentials.newpasswordconfirm
    );
  }
);

interface changePasswordState {
  changePasswordState: SliceState;
}

const initialState: changePasswordState = {
  changePasswordState: { state: "idle" },
};

const changePasswordSlice = createSlice({
  name: "changePassword",
  initialState,
  reducers: {
    resetState(state) {
      state.changePasswordState = { state: "idle" };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state, action) => {
        state.changePasswordState = { state: "loading" };
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.changePasswordState = { state: "error", errorMessage: action.error.message};
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.changePasswordState = { state: "success" };
      });
  },
});

export const { resetState } = changePasswordSlice.actions;

export default changePasswordSlice.reducer;
