import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";


export let apiPath : string;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  // console.log(process.env);
  apiPath = "http://nowmyplace.metislab.net/api/";
} else {
  // production code
  // console.log(process.env);
  apiPath = "/api/";
}

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: apiPath,
    prepareHeaders: (headers, api) => {
      const storageToken = localStorage.getItem("token");
      // @ts-ignore
      const storeToken = api.getState().auth.jwt as string
      // console.log(api)
      // @ts-ignore
      if (api.getState().auth.loggedIn && ( !storageToken || !storeToken || storageToken !== storeToken)) {
        window.location.reload()
      }

      headers.set("Authorization", `Bearer ${storageToken}`);
      return headers;
    },
  }),
  tagTypes: [
    "Auth",
    "Planners",
    "CalendarDay",
    "SchedulerMonth",
    "Appointments",
    "Experiences",
    "ExperienceAvailabilityRange",
    "LoaderInsurance",
    "ExperiencesByReseller",
    "ExperiencesByStaff",
    "Companies",
    "Resellers",
    "Equipments",
    "Staffs",
    "Users",
    "Files",
    "Locations",
    "StaffWeeks",
    "Seasons",
    "Subscribers",
    "Guests",
    "PlannerWeeks",
    "DefaultPlannerTimetable",
    "CustomPlannerTimetable",
    "StaffTimetable",
    "PossibleDays",
    "PossibleSlots",
  ],
  endpoints: () => ({}),
});
