import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {IAppointment} from "../models/entities/calendar/IAppointment";
import {calendarApi} from "./api/calendar";

interface appointmentState {
  // dayStartTime: string;
  // dayEndTime: string;
  appointmentTitle: string;
  currentAppointmentId?: number;
  currentAppointmentItem?: IAppointment;
  appointmentIsModified: boolean;
}

const initialState: appointmentState = {
  appointmentTitle: "",
  // dayStartTime: "",
  // dayEndTime: "",
  currentAppointmentId: undefined,
  currentAppointmentItem: undefined,
  appointmentIsModified: false,
};

const appointmentSlice = createSlice({
  name: "appointment",
  initialState,

  reducers: {
    selectAppointment(state, action: PayloadAction<number | undefined>) {
      state.currentAppointmentId = action.payload;
    },
    selectNewAppointment(state, action: PayloadAction<IAppointment>) {
      state.currentAppointmentItem = action.payload;
    },
    setAppointmentTitle(state, action: PayloadAction<string>) {
      // console.log("***setAppointmentTitle");
      state.appointmentTitle = action.payload;
    },
    resetAppointmentState(state) {
      state.currentAppointmentId = undefined;
      state.currentAppointmentItem = undefined;
      state.appointmentIsModified = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        calendarApi.endpoints.addAppointment.matchFulfilled,
        (state, action) => {
          state.currentAppointmentId = action.payload.appointment.id;
          state.appointmentIsModified = true;
        }
      )
      .addMatcher(
        calendarApi.endpoints.updateAppointment.matchFulfilled,
        (state, action) => {
          state.appointmentIsModified = true;
        }
      );
  },
});

export const {
  // updateStartAppointment,
  // updateEndAppointment,
  selectAppointment,
  selectNewAppointment,
  setAppointmentTitle,
  resetAppointmentState,
} = appointmentSlice.actions;

export default appointmentSlice.reducer;
