import React from "react";
import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {Box, InputAdornment, Theme} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ValidatedInput} from "../common/ValidatedFields";
import EmailIcon from "@mui/icons-material/Email";
import {requireValidator} from "../../utils/validators/requireValidator";
import {emailValidator} from "../../utils/validators/emailValidator";
import {Error} from "@progress/kendo-react-labels";
import BaseButton from "components/common/BaseButton";
import {useChangeEmailRegistrationMutation} from "store/api/auth";
import {useAppDispatch, useAppSelector} from "store";
import {IUserChangeEmailRegistrationViewModel} from "models/views/IUserChangeEmailRegistrationViewModel";
import {setStep} from "store/registration";
import CenteredLoadingSpinner from "components/common/CenteredLoadingSpinner";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

function EmailRegisterForm() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [changeEmailRegistration, { isLoading, isSuccess }] =
    useChangeEmailRegistrationMutation();

  const { step, email, uid, checkcode } = useAppSelector(
    (store) => store.registration
  );

  const onConfirmCurrentEmail = () => {
    dispatch(setStep(step + 1));
  };

  const onConfirmEmail = (newEmail: string) => {
    changeEmailRegistration({
      uid: uid,
      checkcode: checkcode,
      oldemail: email,
      newemail: newEmail,
    } as IUserChangeEmailRegistrationViewModel);
  };

  return (
    <Form
      initialValues={{ email: email }}
      render={(formRenderProps) => (
        <FormElement style={{ maxWidth: 950 }}>
          <Box margin={2}>
            <Field
              name={"email"}
              required
              component={ValidatedInput}
              rows={1}
              disabled={isSuccess}
              label={"Email"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              validator={[requireValidator, emailValidator]}
            />
          </Box>
          <div className={classes.actionsContainer}>
            <div>
              {isLoading ? (
                <CenteredLoadingSpinner />
              ) : (
                <BaseButton
                  onClick={
                    formRenderProps.valueGetter("email") === email
                      ? onConfirmCurrentEmail
                      : () =>
                          onConfirmEmail(formRenderProps.valueGetter("email"))
                  }
                  variant="contained"
                  color="primary"
                  disabled={!formRenderProps.valid || isSuccess}
                  className={classes.button + " custom-unif-viola-back"}
                >
                  Prosegui
                </BaseButton>
              )}
            </div>
          </div>
          {formRenderProps.visited &&
            formRenderProps.errors &&
            formRenderProps.errors.VALIDATION_SUMMARY && (
              <div className={"k-messagebox k-messagebox-error"}>
                <Error>{formRenderProps.errors.VALIDATION_SUMMARY}</Error>
              </div>
            )}
        </FormElement>
      )}
    />
  );
}

export default EmailRegisterForm;
