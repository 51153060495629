import {Grid} from "@mui/material";
import {Error} from "@progress/kendo-react-labels";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import React, {FC, Fragment, useState} from "react";
import {useAppDispatch} from "../../store";
import {formatDateForApi} from "../../utils/dateHelper";
import {SeasonFormProps} from "../../models/props/SeasonFormProps";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import BaseButton from "components/common/BaseButton";
import {useAddSeasonMutation} from "store/api/seasons";
import {ISeason} from "models/entities/ISeason";
import {loadMessages,} from "@progress/kendo-react-intl";
import bgMessages from "./../../globalization/DateInputs.json";
import SaveIcon from "@mui/icons-material/Save";
import CustomDatePicker from "../common/CustomDatePicker";
import {requireValidator} from "../../utils/validators/requireValidator";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

loadMessages(bgMessages, "it-IT");

const AddSeasonForm: FC<SeasonFormProps> = ({
  opened,
  closeForm,
  minDate = new Date(),
  maxDate,
  initialRange,
}) => {

  useAppDispatch();
  const currentPlanner = useCurrentPlanner();
  const [addSeason] = useAddSeasonMutation();


  const [startRange, setStartRange] = useState<Date | null>(minDate as Date);
  const [endRange, setEndRange]     = useState<Date | null>(maxDate as Date);
  const [rangeValid, setRangeValid] = useState(false);

  const handleSubmit = () => {
    addSeason({
      planner_id: currentPlanner?.id as number,
      season_begin_day: formatDateForApi(startRange as Date),
      season_end_day: formatDateForApi(endRange as Date),
    } as ISeason);
    closeForm();
  };

  const handleChangeStart = ( start : any) =>{
    // if (initialRange && (initialRange.start as Date) < new Date()) {
    //   e.value.end = e.value.start;
    //   e.value.start = initialRange?.start as Date;
    // }
    // setDateRange(e.value);
    setRangeValid(!((start.value as Date) < (endRange as Date)))
    setStartRange(start.value as Date);
  }
  const handleChangeEnd = ( end : any) =>{
    // if (initialRange && (initialRange.start as Date) < new Date()) {
    //   e.value.end = e.value.start;
    //   e.value.start = initialRange?.start as Date;
    // }

    // setDateRange(e.value);
    setRangeValid(!((end.value as Date) > (startRange as Date)))
    setEndRange(end.value as Date);
  }
  // useEffect(() => {
  //   if (addSeasonState.state === "success") {
  //     dispatch(resetAddSeason());
  //     closeForm();
  //   }
  // }, [addSeasonState, closeForm, dispatch]);

  return (
    <Fragment>
      {opened && (
        <Dialog
            height={'25vh'}
          onClose={() => closeForm()}
          title={"Nuovo periodo di apertura"}
          className={"add-edit-season-dialog"}
        >
          <div style={{  height:'140px', textAlign: "center" }}>
            <Grid container >
              <Grid container spacing={3} justifyContent="center" >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className={ " std-date-picker-container dropdownModifica custom-unif-dropdown-focus"} >

                    <label
                        className="labelModifica std-date-picker-label"
                        data-shrink="true"
                    >
                      Dal
                    </label>
                    <div className=" std-date-picker-component">
                      <CustomDatePicker
                          value={startRange}
                          format="dd/MM/yyyy"
                          required={true}
                          validator={[requireValidator]}
                          onChange={(e: any) => handleChangeStart(e)}
                          min={new Date()}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className={"rows-grandi"}>
                  <div className={ "std-date-picker-container"} >

                    <label
                        className="std-date-picker-label"
                        data-shrink="true"
                    >
                      Al
                    </label>
                    <div className="std-date-picker-component">
                      <CustomDatePicker
                          value={endRange as Date}
                          format="dd/MM/yyyy"
                          required={true}
                          validator={[requireValidator]}
                          onChange={(e: any) => handleChangeEnd(e)}
                          min={new Date()}
                      />
                    </div>
                  </div>
                  {rangeValid && <Error>La data di fine deve essere successiva alla data iniziale</Error>}

                </Grid>
              </Grid>
            </Grid>
          </div>

          <DialogActionsBar>
            <BaseButton
              onClick={handleSubmit}
              color="primary"
              className={"custom-unif-viola"}
              startIcon={<SaveIcon />}
              autoFocus
              disabled={rangeValid}

            >
              Salva
            </BaseButton>
            <BaseButton
                className={`custom-button-annulla custom-unif-arancione`}
                startIcon={<CancelPresentationIcon />}
                onClick={
                  () => closeForm()
                }
            >
              Annulla
            </BaseButton>
          </DialogActionsBar>
        </Dialog>
      )}

      {/* <ResponseSnackbar
        responseSnackbar={[
          {
            sliceState: addSeasonState,
            resetFunction: resetAddSeason() as any,
          } as IResponseSnackbar,
        ]}
      /> */}
    </Fragment>
  );
};

export default AddSeasonForm;
