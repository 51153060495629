import {api} from "./api";
import {IGetGuestsDto} from "../../models/dto/guests/IGetGuestsDto";
import {IGetGuestDto} from "../../models/dto/guests/IGetGuestDto";
import {IAddGuestDto} from "../../models/dto/guests/IAddGuestDto";
import {IUpdateGuestDto} from "../../models/dto/guests/IUpdateGuestDto";
import {IGuest} from "../../models/entities/IGuest";

export const GuestsApi = api.injectEndpoints({
    overrideExisting: false,
    endpoints: build => ({
        getGuests: build.query<IGuest[], number>({
            query: (resellerId) => ({
                url: `guest`,
                params: { 'company_id' : resellerId }
            }),
            transformResponse: (res: IGetGuestsDto, meta) => res.guests,
            providesTags: result => result ? [...result.map(r => ({ type: 'Guests', id: r.id } as const)), { type: 'Guests', id: 'LIST'}] : [{ type: 'Guests', id: 'LIST '}]
        }),
        getGuest: build.query<IGuest, number>({
            query: id => `guest/${id}`,
            transformResponse: (res: IGetGuestDto, meta) => res.guest,
            providesTags: (result, error, id) => [{ type: 'Guests', id : id }]
        }),
        inviteGuest: build.mutation<boolean, { id: number, date: string | Date }>({
            query: ({id, date} ) => ({
                url: `guest/${id}/sendLink`,
                method: 'POST',
                body: {date: date}
            }),
            invalidatesTags: (result, error ) => error ? [] :[{ type: 'Guests',id: 'LIST'}],
        }),
        addGuest: build.mutation<IGuest, IGuest>({
            query: (Guest) => ({
                url: 'guest',
                method: 'POST',
                body: Guest
            }),
            transformResponse: (res: IAddGuestDto , error, meta) => res.guest,
            invalidatesTags: (result, error ) => error ? [] : [{ type: 'Guests',id : result?.id },{ type: 'Guests',id: 'LIST'}],
        }),
        updateGuest: build.mutation<IGuest, { id: number, Guest: IGuest }>({
            query: ({ id, Guest }) => ({
                url: `guest/${id}`,
                method: 'PUT',
                body: Guest
            }),
            transformResponse: (res: IUpdateGuestDto, meta) => res.guest,
            invalidatesTags: (result, error, { id }) => error ? [] :  [{ type: 'Guests', id : id }],

        }),
        setLinkCopied: build.mutation<IGuest, { id: number, Guest: IGuest }>({
            query: ({ id, Guest }) => ({
                url: `guest/${id}`,
                method: 'PUT',
                body: Guest
            }),
            transformResponse: (res: IUpdateGuestDto, meta) => res.guest,
            invalidatesTags: (result, error ) => error ? [] : [{ type: 'Guests',id: 'LIST'}],
        }),
        setLinkSent: build.mutation<IGuest, { id: number, Guest: IGuest }>({
            query: ({ id, Guest }) => ({
                url: `guest/${id}`,
                method: 'PUT',
                body: Guest
            }),
            transformResponse: (res: IUpdateGuestDto, meta) => res.guest,
        }),
        deleteGuest: build.mutation<void, number>({
            query: (id) => ({
                url: `guest/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id ) => error ? [] :  [{type: 'Guests', id}],
        })
    })
})

export const {
    useGetGuestQuery,
    useInviteGuestMutation,
    useGetGuestsQuery,
    useAddGuestMutation,
    useDeleteGuestMutation,
    useUpdateGuestMutation,
    useSetLinkSentMutation,
    useSetLinkCopiedMutation,
} = GuestsApi