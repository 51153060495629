import {Divider, Icon, List, SwipeableDrawer, Theme} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {Link, useLocation} from "react-router-dom";
import menu from "../../models/constants/menu.json";
import {useAppSelector} from "../../store";
import PlannerButton from "../planners/PlannerButton";

export interface SwipeableDrawerMenuProp {
  opened: boolean;
  toggle: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    pusher: {
      //   paddingTop: 64,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: 0,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7) + 2,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  })
);

function SwipeableDrawerMenu(props: SwipeableDrawerMenuProp) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { user } = useAppSelector((store) => store.auth);

  return (
    <SwipeableDrawer
      //   variant="permanent"
      // variant="permanent"
      //   classes={{
      //     paper: clsx({
      //       [classes.drawerOpen]: props.opened,
      //       [classes.drawerClose]: !props.opened,
      //     }),
      //   }}
      open={props.opened}
      onClose={props.toggle(false)}
      onOpen={props.toggle(true)}
      anchor="left"
    >
      <div className={classes.pusher}>
        <List>
          <ListItem key={100}>
         { user?.role!=="superadmin"  && user?.role !== "reception"  && <PlannerButton />}
          </ListItem>
          <Divider />
          {menu?.map((item, index) => {
            return user === undefined || !item.roles.includes(user.role) ? (
              <div></div>
            ) : (
              <ListItem key={index} button component={Link} to={item.path}>
                <ListItemIcon>
                  <Icon
                    color={pathname.includes(item.path) ? "primary" : undefined}
                  >
                    {item.icon}
                  </Icon>
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            );
          })}
        </List>
      </div>
    </SwipeableDrawer>
  );
}

export default SwipeableDrawerMenu;
