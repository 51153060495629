export function isStarted(dateItemString: string): boolean {
  var dateItem = new Date(dateItemString);
  var dateNow = new Date(Date.now());
  return dateItem <= dateNow ? true : false;
}

export function formatDateForApi(d: Date): string {
  const dateMonth = d.getMonth() + 1;
  const day = d.getDate();
  return `${d.getFullYear()}${dateMonth < 10 ? "0" + dateMonth : dateMonth}${
    day < 10 ? "0" + day : day
  }`;
}


export const getFirstDayOfYear =() : Date=>{
  const data = new Date();
  data.setMonth(0);
  data.setDate(1);
  return data
}
export const getLastDayOfYear =(): Date=>{
  const data = new Date();
  data.setMonth(11);
  data.setDate(31);
  return data
}
export function formatDateNoTimeForApi(d: Date): string {
  const dateMonth = d.getUTCMonth() + 1;
  const day = d.getUTCDate();
  return `${d.getUTCFullYear()}${dateMonth < 10 ? "0" + dateMonth : dateMonth}${
    day < 10 ? "0" + day : day
  }`;
}
export function formatDateTimeForApi(d: Date): string {
  const dateMonth = d.getUTCMonth() + 1;
  const day = d.getUTCDate();
  const hour = d.getHours();
  const minute = d.getMinutes();

  return `${d.getUTCFullYear()}-${dateMonth < 10 ? "0" + dateMonth : dateMonth}-${
    day < 10 ? "0" + day : day
  }-T-${hour<10? "0"+hour:hour}:${minute<10?"0"+minute:minute}:00.000Z`;
}
export function formatDateTimeForFronted(date: string): Date {
  let time = date.substring(8)
  date = date.substring(0,4)+"-"+date.substring(4,6)+"-"+date.substring(6,8);
  let datetime = new Date(date+"T"+time+":00");// "2021-09-08T15:00:00.000Z",
  datetime.setFullYear(currentYear);
  datetime.setHours(parseInt(time.substring(0,2)),parseInt(time.substring(3,5)));
  return datetime
}

//-2 if error
//-1 if 2<1 => date1 > date 2
//0 if 1=2 => date1 = date2
//1 if 2>1 => date1 < date2
export function compareDate(date1: Date, date2: Date) {
  if(!date1) return -2
  if(!date2) return -2
  date1.setUTCHours(0,0,0,0);
  date2.setUTCHours(0,0,0,0);
  if(date2 < date1) return -1
  if(date1 === date2) return 0
  if(date1 < date2) return 1
  return -2
}

export function adjustTimezone(date: Date) : Date {
  if(date.getTimezoneOffset() !== 0){
    //correggo l'offset di orario, perchè mi importa solo la data
    // se offset negativo devo aggiungere ( quindi * -1 )
    // se offset positivo devo rimuovere  ( quindi * -1 )
    // poi moltiplico per secondi e per millisecondi
    date.setTime(date.getTime()+(date.getTimezoneOffset()*(-1)*60*1000));
  }
  return date;
}

export function toDateNoTimeFromApi(apiDate: string): Date {
  const year = parseInt((apiDate as string).substring(0, 4));
  const month = (apiDate as string).substring(4, 6);
  const day = (apiDate as string).substring(6, 8);

  const dateMonth =
     ( month[0] === "0" ? parseInt(month[1]) - 1 : parseInt(month) - 1);
  const dateDay = day[0] === "0" ? parseInt(day[1]) : parseInt(day);

  const date = new Date();
  date.setUTCDate(dateDay);
  date.setUTCFullYear(year);
  date.setUTCMonth(dateMonth);
  date.setUTCHours(12,0,0,0);
  // const dateFinal = adjustTimezone(date);

  return date;
}


export function toDateFromApi(apiDate: string): Date {
  const year = (apiDate as string).substring(0, 4);
  const month = (apiDate as string).substring(4, 6);
  const day = (apiDate as string).substring(6, 8);

  const dateMonth =
      month[0] === "0" ? parseInt(month[1]) - 1 : parseInt(month) - 1;
  const dateDay = day[0] === "0" ? parseInt(day[1]) : parseInt(day);

  return new Date(parseInt(year), dateMonth, dateDay);
}
export function toDateFromApiWithSeparator(apiDate: string): Date {

  const year = apiDate.substr(0, 4);
  const month = apiDate.substr(5, 2);
  const day = apiDate.substr(8, 2);

  const dateMonth =
      month[0] === "0" ? parseInt(month[1]) - 1 : parseInt(month) - 1;
  const dateDay = day[0] === "0" ? parseInt(day[1]) : parseInt(day);

  return new Date(parseInt(year), dateMonth, dateDay);
}

export function fromTimeToDate(time: string): Date {
  const [hours, minutes] = time.split(":");
  let d = new Date();
  d.setHours(parseInt(hours));
  d.setMinutes(parseInt(minutes));
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
}

export function fromDateToTime(date: Date) {
  const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  const minutes =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  return `${hours}:${minutes}`;
}

export function addDays(date: Date, days: number) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function addMinutes(date: Date, minutes: number) {
  return new Date(date.getTime() + minutes * 60000);
}

export function dateFromStringYYYYMMDD(dateString: string) {
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);
  let date = new Date(year + "-" + month + "-" + day);
  return date;
}

export function dateToStringYYYYMMDDnoIso(date: Date) {
  date=addMinutes(date,120);
  return date.toISOString().replaceAll("-", "").split("T")[0];
}

export function dateToStringYYYYMMDD(date: Date) {
  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = date.getFullYear();
  return yyyy+mm+dd;
}

export function dateToStringDDMMYYYYwithsepator(date: Date) {
  return (
    (date.getDate() < 10 ? "0" : "") +
    date.getDate() +
    "/" +
    (date.getMonth() < 9 ? "0" : "") +
    (date.getMonth() + 1) +
    "/" +
    date.getFullYear()
  );
}

export function dateToStringDDmonthYYYYwithsepator(date: Date) {
  return (
    (date.getDate() < 10 ? "0" : "") +
    date.getDate() +
    " " +
    (date.toLocaleString('it-IT', { month: 'long' })) +
    " " +
    date.getFullYear()
  );
}

export function timeToStringHHMMwithsepator(date: Date) {
  return (
    (date.getHours() < 10 ? "0" : "") +
    date.getHours() +
    ":" +
    (date.getMinutes() < 10 ? "0" : "") +
    date.getMinutes()
  );
}

export function weekFromFirstDate(date: Date) {
  const dates = [];
  for (let i = 0; i < 7; i++) {
    dates.push(addDays(date, i));
  }
  return dates;
}

export function aroundMinutesDate(date: Date) {
  let minutesMoreLess = date.getMinutes() % 15;
  if (minutesMoreLess > 7.5) {
    date = new Date(date.getTime() + (15 - minutesMoreLess) * 60000);
  } else date = new Date(date.getTime() - minutesMoreLess * 60000);
  return date;
}

export const currentYear = new Date().getFullYear();
export const parseAdjust = (eventDate: any) => {
  let date = new Date(eventDate);
  date.setFullYear(currentYear);
  date = addMinutes(date, -120);
  return date;
};


export const fromPeriodToDateNew = (period: string) => {
  const stringMonth = period.substr(0, 2)
  const stringDay = period.substr(2, 2)
  const currYear = currentYear;
  return new Date(`${currYear}-${stringMonth}-${stringDay}`)
}


export const fromPeriodToDate = (period: string) => {
  const stringMonth = period.substr(0, 2)
  const stringDay = period.substr(2, 2)
  return new Date(`2021-${stringMonth}-${stringDay}`)
}

export const fromDateToPeriod = (date: Date) => {
  const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
  const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`

  return `${month}${day}`
}


export const createDateTimeStringFromDateAndTime = (date: string, time:string) => {
  return date.substring(0,4)+"-"+date.substring(4,6)+"-"+date.substring(6,8)+"T"+time+":00.+00:00";
};

export const createDateTimeFromDateAndTime = (date: string, time:string) => {
  date = date.substring(0,4)+"-"+date.substring(4,6)+"-"+date.substring(6,8);
  let datetime = new Date(date+"T"+time+":00");// "2021-09-08T15:00:00.000Z",
  datetime.setFullYear(currentYear);
  datetime.setHours(parseInt(time.substring(0,2)),parseInt(time.substring(3,5)));
  return datetime;
};
