import {createSlice, PayloadAction} from "@reduxjs/toolkit";


interface LoaderInsuranceState {
    isLoading: boolean;
}

const initialState: LoaderInsuranceState = {
    isLoading: false,
};

const LoaderInsuranceSlice = createSlice({
    name: "loaderInsurance",
    initialState,
    reducers: {
        setLoaderInsurance(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
    },
});

export const {
    setLoaderInsurance,
} = LoaderInsuranceSlice.actions;

export default LoaderInsuranceSlice.reducer;
