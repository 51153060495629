import {createAsyncThunk, createSlice, PayloadAction,} from "@reduxjs/toolkit";

import {SliceState} from "../models/SliceState";
import {ITypes} from "../models/entities/types/ITypes";
import {IRoleType} from "../models/entities/types/IRoleType";
import {IColorType} from "../models/entities/types/IColorType";
import {IPriceType} from "../models/entities/types/IPriceType";
import {IDayType} from "../models/entities/types/IDayType";
import {ILanguageType} from "../models/entities/types/ILanguageType";
import {ISiteType} from "../models/entities/types/ISiteType";
import {IExclusionType} from "../models/entities/types/IExclusionType";
import {IAreaType} from "../models/entities/types/IAreaType";
import {IPriorityType} from "../models/entities/types/IPriorityType";
import {IMeteoIndexes} from "../models/entities/types/IMeteoIndexes";

import typeApi from "../utils/api/typeApi";
import {IType, ITypeKeyNumber} from "../models/entities/types/IType";
import {IExpType} from "../models/entities/types/IExpType";

export const getAllTypes = createAsyncThunk(
  "/type/getAll",
  async (thunkApi) => {
    let data = (await typeApi.getAllTypes()).data.types as ITypes
    return data;
  }
);

interface staffState {
  colors: IColorType[];
  roles: IRoleType[];
  days: IDayType[];
  sitesType: ISiteType[];
  meteoIndexes: IMeteoIndexes[];
  prenotationtypes: IType[];
  experiencetypes: IExpType[];
  ivacombos: IType[];
  apikey: string;
  countries: IType[];
  languages: ILanguageType[];
  exclusions: IExclusionType[];
  difficultylevels: IType[];
  customertargettypes: IType[];
  pricesType: IPriceType[];
  priceLabelTypes: IType[];
  tipologyreseller: IType[];
  priceincreases: ITypeKeyNumber[];
  areas: IAreaType[];
  priorities: IPriorityType[];
  getAllTypesState: SliceState;
}

const initialState: staffState = {
  colors: [],
  roles: [],
  exclusions: [],
  difficultylevels: [],
  customertargettypes: [],
  pricesType: [],
  tipologyreseller: [],
  priceincreases: [],
  priceLabelTypes: [],
  areas: [],
  getAllTypesState: { state: "idle" },
  days: [],
  sitesType: [],
  languages: [],
  priorities: [],
  meteoIndexes: [],
  prenotationtypes: [],
  experiencetypes: [],
  ivacombos: [],
  countries: [],
  apikey: "",
};

// const loggedUser =  useAppSelector((s) => s.auth);


const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    resetGetAllTypes(state) {
      state.getAllTypesState = { state: "idle" };
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTypes.pending, (state, action) => {
        state.getAllTypesState = { state: "loading" };
      })
      .addCase(getAllTypes.rejected, (state, action) => {
        state.getAllTypesState = {
          state: "error",
          errorMessage: action.error.message,
        };
      })
      .addCase(getAllTypes.fulfilled, (state, action: PayloadAction<ITypes>) => {
        if (action.payload.colors)
          state.colors = action.payload.colors;
        if (action.payload.roles) {
          state.roles = action.payload.roles;
        }
        if (action.payload.exclusiontypes)
          state.exclusions = action.payload.exclusiontypes;
        if (action.payload.difficultylevels)
          state.difficultylevels = action.payload.difficultylevels;
        if (action.payload.customertargettypes)
          state.customertargettypes = action.payload.customertargettypes;
        if (action.payload.tipologyreseller)
          state.tipologyreseller = action.payload.tipologyreseller;
        if (action.payload.pricetypes)
          state.pricesType = action.payload.pricetypes;
        if (action.payload.pricelabeltypes)
          state.priceLabelTypes = action.payload.pricelabeltypes;

        if (action.payload.areas)
          state.areas = action.payload.areas;
        if (action.payload.days)
          state.days = action.payload.days;
        if (action.payload.languages)
          state.languages = action.payload.languages;
        if (action.payload.sitetypes)
          state.sitesType = action.payload.sitetypes;
        if (action.payload.meteoindexes)
          state.meteoIndexes = action.payload.meteoindexes;
        if (action.payload.prenotationtypes)
          state.prenotationtypes = action.payload.prenotationtypes;
        if (action.payload.experiencetypes)
          state.experiencetypes = action.payload.experiencetypes;
        if (action.payload.ivacombos)
          state.ivacombos = action.payload.ivacombos;
        if (action.payload.priceincreases)
          state.priceincreases = action.payload.priceincreases;
        if (action.payload.apikey){
          state.apikey = action.payload.apikey;
        }
        if (action.payload.countries)
          state.countries = action.payload.countries;
          if (action.payload.priorities)
          state.priorities = action.payload.priorities;
        state.getAllTypesState = { state: "success" };
      })

  },
});

export const {
  resetGetAllTypes,
} = staffSlice.actions;

export default staffSlice.reducer;
