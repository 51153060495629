import {Fragment, useEffect} from "react";
import {Box} from "@mui/material";
import {Link, Route, Switch, useLocation, useRouteMatch,} from "react-router-dom";
import PlannerFormPage from "./PlannerFormPage";
import WeeklyTimetablePage from "./WeeklyTimetablePage";
import PageTitle from "../../../components/common/PageTitle";
import DefaultWeeklyTimetablePage from "./DefaultWeeklyTimetablePage";
import CustomTab from "../../../components/common/CustomTab";
import CustomTabs from "../../../components/common/CustomTabs";
import {useGetPlannerWeeksQuery} from "store/api/planners";
import {useGetDefaultPlannerWeeklyTimetableQuery,} from "store/api/plannerTimetables";
import {useGetSeasonsQuery} from "store/api/seasons";
import {useCurrentPlanner} from "utils/hooks/currentPlanner";
import {skipToken} from "@reduxjs/toolkit/query/react";

const PlannersPage = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const currentPlanner = useCurrentPlanner();

  const { isLoading: isLoadingPlannerWeeks, refetch: refetchPlannerWeeks } = useGetPlannerWeeksQuery(currentPlanner?.id ?? skipToken);
  const { isLoading: isLoadingDefaultPlannerWeeklyTimetable, refetch: refetchDefaultPlannerWeeklyTimetable } =
    useGetDefaultPlannerWeeklyTimetableQuery(currentPlanner?.id ?? skipToken);
  const { data: seasons = [], isLoading: isLoadingSeasons, } = useGetSeasonsQuery(currentPlanner?.id ?? skipToken);

  const isLoading =
    isLoadingPlannerWeeks || isLoadingDefaultPlannerWeeklyTimetable || isLoadingSeasons;

  // eslint-disable-next-line
  useEffect(() => {
    if (seasons && seasons.length > 0) {
      refetchDefaultPlannerWeeklyTimetable();
      refetchPlannerWeeks();
    }
    // eslint-disable-next-line
  }, [seasons]);


  return (
    <Fragment>
      <Box component={Box}>
        <div style={{ paddingTop: "1vh", paddingLeft: "2.5vh" }}>
          <PageTitle icon="local_library" title="Agenda" />
        </div>
        <CustomTabs
          variant="fullWidth"
          // className={{ indicator: classes.whiteColor}}
          indicatorColor="primary"
          textColor="primary"
          value={location.pathname}
        >
          <CustomTab
            component={Link}
            to={`${path}`}
            value={`${path}`}
            label="Dettaglio"
          />
          <CustomTab
            component={Link}
            to={`${path}/default-weekly-timetable`}
            value={`${path}/default-weekly-timetable`}
            label="Orario al pubblico standard"
            disabled={isLoading || !seasons || seasons.length === 0}
          />
          <CustomTab
            component={Link}
            to={`${path}/custom-weekly-timetable`}
            value={`${path}/custom-weekly-timetable`}
            label="Orario al pubblico personalizzato"
            disabled={isLoading || !seasons || seasons.length === 0}
          />
        </CustomTabs>
      </Box>
      <Switch>
        <Route path={`${path}`} exact component={PlannerFormPage} />
        <Route
            path={`${path}/default-weekly-timetable`}
            component={DefaultWeeklyTimetablePage}
        />
        <Route
          path={`${path}/custom-weekly-timetable`}
          component={WeeklyTimetablePage}
        />
      </Switch>
      {/* <Typography variant="h4" style={{ margin: "5px" }}>
        <PlannerIcon></PlannerIcon> Agende
      </Typography>
      {getAllPlannersState.state === "loading" ? (
        <CenteredLoadingSpinner />
      ) : (
        <GenericGrid
          items={plannersToDisplay}
          columns={[
            { field: "name", title: "Nome" },
            { field: "description", title: "Descrizione" },
            {
              field: "season_begin_day",
              title: "Inizio stagione",
              format: "{0:dd/MM/y}",
            },
            {
              field: "season_end_day",
              title: "Fine stagione",
              format: "{0:dd/MM/y}",
            },
            // { field: "lon", title: "Numero di cellulare" },
            // { field: "type.name", title: "Città" },
          ]}
          enableCancel={true}
          onRemove={remove}
          formComponent={PlannerForm}
        />
      )}
      <ErrorSnackbar
        open={getAllPlannersState.state === "error"}
        message={getAllPlannersState.errorMessage || "Errore"}
        onClose={() => dispatch(resetGetAllPlanners())}
      />
      <ErrorSnackbar
        open={addPlannerState.state === "error"}
        message={addPlannerState.errorMessage || "Errore"}
        onClose={() => dispatch(resetAddPlanner())}
      />
      <ErrorSnackbar
        open={updatePlannerState.state === "error"}
        message={updatePlannerState.errorMessage || "Errore"}
        onClose={() => dispatch(resetUpdatePlanner())}
      />
      <ErrorSnackbar
        open={deletePlannerState.state === "error"}
        message={deletePlannerState.errorMessage || "Errore"}
        onClose={() => dispatch(resetDeletePlanner())}
      /> */}
    </Fragment>
  );
};

export default PlannersPage;
