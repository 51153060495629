import {Box, Card, CardContent, CardHeader} from "@mui/material";
import {makeStyles} from "@mui/styles";
import LoginForm from "../../components/users/LoginForm";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100vh",
  },
  card: {
    width: "90%",
    maxWidth: 600,
  },
});

const LoginPage = () => {
  const classes = useStyles();
  const loginStyle = {
    borderRadius : '25px',
    boxShadow : ' 0px 0px 16px 2px rgba(0,0,0,0.94)'
  }


  return (
    <Box
      className={classes.root + " custom-back"}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Card className={classes.card + " card-login-forpsw"} style={loginStyle}>

        <CardHeader
          className="custom-unif-font custom-unif-viola"
          component={Box}
          margin={3}
          title="Login"
          titleTypographyProps={{
            variant: "h3",
            align: "center",
          }}
        ></CardHeader>
        <CardContent component={Box} marginBottom={3}>
          <LoginForm />
        </CardContent>
      </Card>
    </Box>
  );
};

export default LoginPage;
