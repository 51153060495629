import React, {Fragment, useEffect, useMemo, useState} from "react";
import {IUser} from "../../models/entities/IUser";
import CenteredLoadingSpinner from "../../components/common/CenteredLoadingSpinner";
import GenericPagedGrid from "../../components/common/GenericPagedGrid";
import {useDeleteGuestMutation, useGetGuestsQuery} from "../../store/api/guests";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {useMe} from "../../utils/hooks/me";
import GuestsForm from "../../components/guests/GuestsForm";
import {SCREEN_LG, useWindowSize} from "../../components/common/ScreenSizes";
import {IGuest} from "../../models/entities/IGuest";
import GuestsView from "../../components/guests/GuestsView";
import {toDateFromApi} from "../../utils/dateHelper";
import GuestsLinkView from "../../components/guests/GuestsLinkView";

const GuestsPage = () => {
    const user = useMe();
    const {
        data: guests = [],
        isLoading,
    } = useGetGuestsQuery(user?.companyId ?? skipToken);
    const [deleteUser ] = useDeleteGuestMutation();


    const guestsGrid : IGuest[]
        = useMemo(
        ()=>guests.map(
            (el : IGuest) => (
                {...el,
                    checkin: toDateFromApi(el.checkin as string),
                    checkout: toDateFromApi(el.checkout as string)
                }
                )
        ), [guests]);

    const remove = (dataItem: IUser) => {
        deleteUser(dataItem.id as number);
    };

    const size = useWindowSize();

    const [nameWidth, setNameWidth] = useState<string|null>(null);

    useEffect(()=>{
        if(size) {
            if(size< SCREEN_LG) {
                setNameWidth("250")
            }
        }
    },[size]);


    return (
        <div style={{ padding: "2.5vh" }} className={"users_grid guests-grid"}>
            {isLoading ? (
                <CenteredLoadingSpinner />
            ) : (
                <Fragment>
                    <GenericPagedGrid
                        icon="group" title="Ospiti"
                        // itemsPage={30}

                        items={guestsGrid}
                        columns={[
                            // { field: "name", title: "Nome" },
                            // { field: "surname", title: "Cognome"},
                            (nameWidth)? { field: "fullname", title: "Nome e Cognome", width: nameWidth } :{ field: "fullname", title: "Nome e Cognome" },
                            { field: "nationality", title: "Paese"},
                            { field: "checkin",  title: "Check-In"},
                            { field: "checkout", title: "Check-Out"},
                            { field: "booking_number", title: "Prenotazioni"},
                            // { field: "impersonate", title: "Login", width: 90 },
                        ]}
                        enableCancel={true}
                        onRemove={remove}
                        customFilter={true}
                        addFormComponent={GuestsForm}
                        updateFormComponent={GuestsForm}
                        customFormComponent={GuestsView}
                        customViewLinkComponent={GuestsLinkView}
                        itemsPage={100}
                    />

                </Fragment>
            )}
        </div>
    );
};

export default GuestsPage;
