import React, {useEffect, useState} from "react";
import PageTitle from "../../components/common/PageTitle";
import Grid from "@mui/material/Grid";
import {MemoizedHistogramYearChart,} from "../../components/charts/HistogramYearChart";
import {MemoizedPieChart} from "../../components/charts/PieChart";
import {MemoizedHistogramExpPaxChart} from "../../components/charts/HistogramExpPaxChart";
import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {ValidatedDropDownListKeyLabelTypes} from "../../components/common/ValidatedFields";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Typography} from "@mui/material";
import {apiPath} from "../../store/api/api";
import {IHistogramData} from "../../models/entities/IHistogramData";
import {IPieChartData} from "models/entities/IPieChartData";
import {IAreaChartData} from "../../models/entities/IAreaChartData";
import {useCurrentPlanner} from "../../utils/hooks/currentPlanner";
import {Calendar, DatePicker} from "@progress/kendo-react-dateinputs";
import GetAppIcon from "@mui/icons-material/GetApp";
import BaseButton from "../../components/common/BaseButton";
import {IType} from "../../models/entities/types/IType";
import CenteredLoadingSpinner from "../../components/common/CenteredLoadingSpinner";
//import { useGetActiveUserQuery } from "store/api/auth";



const HomePage = () => {

    const currentPlanner = useCurrentPlanner();

    const [ plannerID, setPlannerID] = useState<number | undefined>(currentPlanner?.id);

    const [histogramGraph, setHistogramGraph] = useState<IHistogramData[]>();
    const [pieChartGraph, setPieChartGraph] = useState<IPieChartData[]>();
    const [pieChartTotalGraph, setPieChartTotalGraph] = useState<number>(0);
    const [areaChartGraph, setAreaChartGraph] = useState<IAreaChartData[]>();


    //todo: integrare month nella get types. 23 passati e 6 futuri
    // const months =  ["gennaio - 2022", "febbraio - 2022", "marzo - 2022", "aprile - 2022", "maggio - 2022", "giugno - 2022", "luglio - 2022", "agosto - 2022", "settembre - 2022", "ottobre - 2022", "novembre - 2022", "dicembre - 2022"];
    const [oldMonth , setOldMonth ] = useState( (new Date()).getMonth()+"-"+(new Date()).getFullYear());
    const [monthSelected, setMonthSelected] = useState<string>(
        (((new Date()).getMonth() + 1).toString().length === 1 ? "0" : "" ) + ((new Date()).getMonth() + 1).toString() +"-"+(new Date()).getFullYear()
    );

    const [yearForArea, setYearForArea] =useState<string>(
        (new Date()).getFullYear().toString()
    );

    const [monthForArea, setMonthForArea] =useState<string>(
        (((new Date()).getMonth() + 1).toString().length === 1 ? "0" : "" ) + ((new Date()).getMonth() + 1).toString()
    );

    const [monthsCombo,]= useState<IType[]>(
       [
                   {key: '01', label:'Gennaio - Febbraio'},
                   {key: '03', label: 'Marzo - Aprile'},
                   {key: '05', label: 'Maggio - Giugno'},
                   {key: '07', label: 'Luglio - Agosto'},
                   {key: '09', label: 'Settembre - Ottobre'},
                   {key: '11', label: 'Novembre - Dicembre'},
                ]
    );

    const [isLoading, setIsLoading] = useState(true);
    const [isPieChartLoading, setIsPieChartLoading] = useState(true);

    //todo: integrare anni nella get types. corrente + 2 passati
    const years  =  [((new Date()).getFullYear() - 2).toString(), ((new Date()).getFullYear() - 1).toString(), ((new Date()).getFullYear()).toString()];
    const [oldYearSelected, setOldYearSelected] = useState<string>("");
    const [yearSelected, setYearSelected] = useState<string>((new Date()).getFullYear().toString());
    const [yearSelectedExport, setYearSelectedExport] = useState<string>((new Date()).getFullYear().toString());

    const [bimesterStart, setBimesterStart] = useState<IType | undefined>( monthsCombo[0]);
    const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
    const [error, ] = useState(false);

    const token = localStorage.getItem("token");
    const get_path = apiPath + "graph";

    const header_get =  {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }


    const  optObjHeadBody = {
        method: 'GET',
        headers:header_get,

    };
    //


    useEffect(()=>{
        if(plannerID === undefined){
            setPlannerID(currentPlanner?.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentPlanner]);

    useEffect(()=>{
        if ((oldYearSelected !== yearSelected  || (plannerID !== currentPlanner?.id))) {
            setIsPieChartLoading(true);

            setOldYearSelected(yearSelected);

            fetch(get_path + "/pieChart?year=" + yearSelected + "&plannerId=" + currentPlanner?.id, optObjHeadBody)
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsPieChartLoading(false);
                        if (result.aInfo.length === 0) {
                            setPieChartGraph([]);
                        } else {
                            setPieChartGraph(result.aInfo as IPieChartData[]);
                        }
                        setPieChartTotalGraph(result.aInfo.totale);
                        // setPieChartGraph(result.aInfo as IPieChartData[]);
                    },
                    // Nota: è importante gestire gli errori qui
                    // invece di un blocco catch() in modo da non fare passare
                    // eccezioni da bug reali nei componenti.
                    (error) => {
                        // setIsLoaded(true);
                        // setError(error);
                    }
                );
            setPlannerID(currentPlanner?.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[yearSelected])

    useEffect(()=>{
        if ((oldMonth !== monthSelected || (plannerID !== currentPlanner?.id))) {
            setIsLoading(true);
            setOldMonth(monthSelected);
            fetch(get_path + "/areaChart?plannerId=" + currentPlanner?.id + "&month=" + monthForArea + "&year=" + yearForArea, optObjHeadBody)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result.bResult) {
                            setAreaChartGraph([]);
                            setIsLoading(false);
                        } else {
                            setAreaChartGraph(result.aInfo as IAreaChartData[]);
                            setIsLoading(false);
                        }

                    },
                    // Nota: è importante gestire gli errori qui
                    // invece di un blocco catch() in modo da non fare passare
                    // eccezioni da bug reali nei componenti.
                    (error) => {
                        // setIsLoaded(true);
                        // setError(error);
                    }
                );
            setPlannerID(currentPlanner?.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[monthSelected])

    useEffect(()=>{
        if (!histogramGraph || (plannerID !== currentPlanner?.id)) {
            fetch(get_path + "/histogram?plannerId=" + currentPlanner?.id, optObjHeadBody)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result) {
                            setHistogramGraph([]);
                        } else {
                            setHistogramGraph(result.aInfo as IHistogramData[]);
                        }
                    },
                    // Nota: è importante gestire gli errori qui
                    // invece di un blocco catch() in modo da non fare passare
                    // eccezioni da bug reali nei componenti.
                    (error) => {
                        // setIsLoaded(true);
                        // setError(error);
                    }
                );
            setPlannerID(currentPlanner?.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const HandleDateChange = (e : any) =>{
        let data = e.value as Date;

        setIsLoading(true);
        setMonthSelected(
            ((data.getMonth() + 1 ).toString().length===1 ? "0" : "" )+(data.getMonth() + 1 ).toString()+"-"+data.getFullYear()
        );

        setYearForArea(
            data.getFullYear().toString()
        );
        setMonthForArea(
            ((data.getMonth() + 1 ).toString().length===1 ? "0" : "" )+(data.getMonth() + 1 ).toString()
        );
    }

    const HandleDateChangeForExport = (e : any) =>{
        if(e.value) {
            if(monthsCombo && monthsCombo.find((i) => i.key === e.value.key)) {
                setBimesterStart(monthsCombo.find((i) => i.key === e.value.key));
            }
        }
    }


    const download  = (name: string, URL : string) => {
        fetch(URL, {
            method: 'GET',
            headers:header_get,
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    name,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                if(link.parentNode) {
                    link.parentNode.removeChild(link);
                }


                setIsLoadingExport(false);
            }, (error) => {


                setIsLoadingExport(false);
            });
    }
    const handleDownloadExcel = () =>{
        setIsLoadingExport(true);
        let start = bimesterStart;
        let year = yearSelectedExport;

        let optObjHeadBody = {
            method: 'GET',
            headers:header_get,

        };
        if(start && year) {
            fetch(get_path + "/experienceExport?plannerId=" + currentPlanner?.id + "&month=" + start.key + "&year=" + year, optObjHeadBody)
                .then(res => res.json())
                .then(
                    (result) => {
                        if(result.aData && result.bResult) {
                            setIsLoadingExport(false);
                            download(result.aData, "/downloadFile.php?fileName=" + result.aData+"&type=1");
                        }else{
                            setIsLoadingExport(false);
                        }
                    },
                    (error) => {

                        setIsLoadingExport(false);
                        // setIsLoaded(true);
                        // setError(error);
                    }
                )
        }

    }

    const CustomCalendar = (props: any) => {
        return (
            <Calendar
                bottomView="year"
                topView="year"
                value={props.value}
                onChange={props.onChange}
            />
        );
    };



    return (
        <div style={{ padding: "2.5vh" }} className={" custom-unif-font dashboard-page"}>
            {/* <Typography variant="h4" style={{ margin: "5px" }}><HomeIcon> </HomeIcon> Dashboard</Typography> */}
            <PageTitle icon="timeline" title="Dashboard" />

            <Grid container alignItems="stretch" spacing={3} >
                <Grid container  item xs={12} md={6} alignItems="stretch"   className={"std-chart-container-histogram chart-container graph"}>

                    <Grid item xs={12} md={8}>
                        <Typography variant="h6" style={{ margin: "5px" , textAlign:"left"}} className={"chart-title"}>
                            Rapporto tra ore pianificate e ore utilizzate
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormElement>
                                <div className={ "std-date-picker-container dropdownModifica custom-unif-dropdown-focus"} style={{width: "94%"}} >

                                    <div className=" spanFiglia-daIngrandire std-date-picker-component">
                                        <DatePicker
                                            defaultValue={new Date()}
                                            format={{
                                                month: "long",
                                                year: "numeric",
                                            }// 'MM/yyyy'
                                            }
                                            calendar={CustomCalendar}
                                            onChange={(e)=>HandleDateChange(e)}
                                        />
                                    </div>
                                </div>

                        </FormElement>
                    </Grid>
                    <Grid item xs={12}>
                        <MemoizedHistogramExpPaxChart loading={isLoading} data={areaChartGraph} monthSelected={monthSelected} />
                    </Grid>
                </Grid>
                <Grid container  item xs={12} md={4} alignItems="stretch"   className={"std-chart-container-pie chart-container graph"}>

                    <Grid item xs={12} md={8}>
                        <Typography variant="h6" style={{ margin: "5px" , textAlign:"left"}} className={"chart-title"}>

                            Fatturato (euro) per esperienza
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormElement>

                            <div>
                                <div className="std-dropdown-container dropdownModifica custom-unif-dropdown-focus">


                                    <div className="custom-unif-font std-dropdown-component">
                                        <DropDownList data={years} value={yearSelected} onChange={(e) => setYearSelected(e.value)} />
                                    </div>
                                </div>
                            </div>

                        </FormElement>
                    </Grid>
                    <Grid item xs={12} md={12} className={" custom-unif-font"}>
                        <MemoizedPieChart data={pieChartGraph} isLoading={isPieChartLoading} total={pieChartTotalGraph}/>
                    </Grid>
                </Grid>
                <Grid container xs={12} sm={12} className={"std-chart-container-area chart-container graph graph-histogram custom-unif-font"}>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ margin: "5px" , textAlign:"center"}} className={"chart-title"}>

                            Fatturato (euro) per mese
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <MemoizedHistogramYearChart data={histogramGraph} />
                    </Grid>

                </Grid>
                <Grid item container xs={12} sm={12} className={"chart-container custom-unif-font"}>
                    <Grid item xs={12} md={12} lg={12}>
                        <PageTitle icon="download" title="Esporta dati" />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                    <Form
                        initialValues={bimesterStart}
                        render={(formRenderProps) => (
                            <FormElement >

                    <Grid item container md={12} >
                        <Grid item style={{padding: "1.5vh"}} xs={12} sm={6} md={3}>
                            <div>
                                <div className="std-dropdown-container dropdownModifica custom-unif-dropdown-focus">
                                    <label
                                        className="labelModifica std-dropdown-label"
                                        data-shrink="true">Seleziona anno
                                    </label>

                                    <div className="custom-unif-font std-dropdown-component">
                                        <DropDownList data={years} value={yearSelectedExport} onChange={(e) => setYearSelectedExport(e.value)} />
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item style={{padding: "1.5vh"}} xs={12} sm={6} md={3}>
                            <Field
                                name={"bim_start"}
                                label={"Seleziona Bimestre"}
                                // required
                                className={"custom-unif-font-2"}
                                component={ValidatedDropDownListKeyLabelTypes}
                                onChange={(e)=> {
                                    HandleDateChangeForExport(e);
                                }}
                                data={monthsCombo}
                                value={bimesterStart}
                                defaultVal={'01'}
                            />
                        </Grid>
                        <Grid  item >
                            {(isLoadingExport)?<CenteredLoadingSpinner /> : ""}

                        </Grid>
                        <Grid item xs={12} sm={12} md={3} style={{textAlign: "center", padding: "1.5vh"}}>
                            <BaseButton
                                tabIndex={-1}
                                variant={"outlined"}
                                startIcon={<GetAppIcon />}
                                onClick={handleDownloadExcel}
                                disabled={error}
                                className={`add-period-season ${
                                    (!error
                                    )? "custom-unif-viola-back" : ""
                                }`}
                            >
                                Esporta Excel
                            </BaseButton>
                        </Grid>
                    </Grid>
                            </FormElement>
                        )}
                    />
                    </Grid>
                </Grid>
            </Grid>


        </div>
    );
};

export default HomePage;
