import {api} from "./api";
import {IReseller} from "../../models/entities/IReseller";
import {IGetResellersDto} from "../../models/dto/resellers/IGetResellersDto";
import {IGetResellerDto} from "../../models/dto/resellers/IGetResellerDto";
import {IAddResellerDto} from "../../models/dto/resellers/IAddResellerDto";
import {IUpdateResellerDto} from "../../models/dto/resellers/IUpdateResellerDto";
import {IFileList} from "../../models/entities/IFileList";

const validateResellerFromServer  = (rawReseller: IReseller):IReseller =>{
  return rawReseller;
}

const validateResellersFromServer  = (rawResellers: IReseller[]):IReseller[] =>{
  return rawResellers;
}

const resellersApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getResellers: build.query<IReseller[], void>({
      query: () => ({
        url: 'reseller',
      }),
      transformResponse: (baseQueryReturnValue: IGetResellersDto, meta) => validateResellersFromServer(baseQueryReturnValue.resellers),
      providesTags: result => result ? [...result.map(({id}) => ({type: 'Resellers', id} as const)), {
        type: 'Resellers',
        id: 'LIST'
      },] : [{type: 'Resellers', id: 'Resellers_LIST'},]
    }),
    getReseller: build.query<IReseller, number>({
      query: (id) => `reseller/${id}`,
      transformResponse:
          (baseQueryReturnValue: IGetResellerDto,
                          meta) => validateResellerFromServer(baseQueryReturnValue.reseller),
      providesTags: (result, error, id) => [{type: 'Resellers', id}],
    }),
    addReseller: build.mutation<IReseller, IReseller>({
      query: (reseller) => ({
        url: `reseller`,
        method: 'POST',
        body: reseller
      }),
      transformResponse: (res: IAddResellerDto, meta) => res.reseller,
      invalidatesTags:  (result, error)  => error ? [] : [{type: 'Resellers', id: 'LIST'}],
    }),
    updateReseller: build.mutation<IReseller, { id: number, reseller: IReseller }>({
      query: ({id, reseller}) => ({
        url: `reseller/${id}`,
        method: 'PUT',
        body: reseller
      }),
      transformResponse: (res: IUpdateResellerDto, meta) => res.reseller,
      invalidatesTags: (result, error, {id}) => error ? [] : [{type: 'Resellers', id: 'LIST'},{ type: "ExperiencesByReseller", id: "LIST_R" }],
      async onQueryStarted({id, ...reseller}, {dispatch, queryFulfilled}) {
        const putResult = dispatch(
            resellersApi.util.updateQueryData('getReseller', id, (draft) => {
              Object.assign(draft, reseller)
            })
        )
        try {
          await queryFulfilled
        } catch {
          putResult.undo()
        }
        // queryFulfilled.catch(putResult.undo)
      },
    }),
    getFilesReseller: build.query< IFileList[], number>({
      query: (id) => ({
        url: `test/getFiles/${id}`,
      }),
      transformResponse:
          (res : any,
           meta) =>  res,
          providesTags: (result, error, id) => [{type: 'Files', id}],
    }),
    deleteReseller: build.mutation<void, number>({
      query: (id) => ({
        url: `reseller/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => error ? [] : [{type: 'Resellers', id}],
    }),
  }),
})

export const {
  useGetResellersQuery,
  useGetResellerQuery,
  useAddResellerMutation,
  useUpdateResellerMutation,
  useGetFilesResellerQuery,
  useDeleteResellerMutation
} = resellersApi