import React, {useEffect} from "react";
import {CssBaseline, StyledEngineProvider, Theme, ThemeProvider} from "@mui/material";
import {Route, Switch, useHistory, useLocation,} from "react-router-dom";
import LoginPage from "./pages/auth/LoginPage";
import {Provider} from "react-redux";
import {store} from "./store";
import theme from "./theme";
import DashboardLayout from "./layouts/DashboardLayout";
import RegisterPage from "./pages/auth/RegisterPage";
import ForgottenPasswordPage from "./pages/auth/ForgottenPasswordPage";
import Error404Page from "./pages/Error404Page";
import {IntlProvider, load, loadMessages, LocalizationProvider,} from "@progress/kendo-react-intl";

import dateFields from "cldr-dates-full/main/it/dateFields.json";
import caGregorian from "cldr-dates-full/main/it/ca-gregorian.json";
import timeZoneNames from "cldr-dates-full/main/it/timeZoneNames.json";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/it/numbers.json";
import currencies from "cldr-numbers-full/main/it/currencies.json";

//import dei messaggi personalizzati
import esMessages from "./models/constants/it.json";
import {ToastBar, Toaster} from "react-hot-toast";
import {useMe} from "./utils/hooks/me";
import {useAutoLoginMutation} from "./store/api/auth";
import CenteredLoadingSpinner from "./components/common/CenteredLoadingSpinner";
import ChangePasswordPage from "pages/auth/ChangePasswordPage";
import menu from "models/constants/menu.json";
import {useCurrentMessage} from "./utils/hooks/currentMessageState";
import {useCurrentAppointmentOpened} from "./utils/hooks/currentAppointmentOpenedState";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);
loadMessages(esMessages, "it-IT");

function App() {

  const history = useHistory();
  // const dispatch = useAppDispatch();
  const location = useLocation();
  // const { passedInLoginPage } = useAppSelector((s) => s.auth);
  const loggedUser = useMe();
  const [autoLogin, { isLoading, isError, data }] = useAutoLoginMutation();

  const messageState = useCurrentMessage();
  const appointmentToOpenState = useCurrentAppointmentOpened();
  // const [logout] = useLogoutMutation();
  // const { autoLoginState, loggedIn } = useAppSelector((store) => store.auth);



  window.onbeforeunload = (e) => {
    e.preventDefault();
    if (
      location.pathname !== "/login" &&
      !location.pathname.startsWith("/register") &&
      !location.pathname.startsWith("/change-password") &&
      location.pathname !== "/forgotten-password" &&
      location.pathname !== "/404page" &&
      location.pathname !== "/" && location.pathname !== ""
    ) {
      localStorage.setItem("last-url", location.pathname);
    }
    // else if (location.pathname === "/" || location.pathname === "") {
    //   //localStorage.removeItem("last-url");
    //  // const lastUrl = localStorage.getItem("last-url");
    //   //history.push(lastUrl || "/dashboard/home");
    //   history.push("/login");
    // }
  };



  useEffect(() => {
    if (localStorage.getItem("token") && location.pathname.startsWith("/dashboard")) {
      autoLogin();
    }
  }, []);

  useEffect(()=>{
    if(isError){
      history.push("/login");
    }
  },[isError,data])

  useEffect(() => {
    if (loggedUser) {
      if (!(location.pathname.startsWith("/register") ||
        location.pathname.startsWith("/change-password") ||
        location.pathname === "/forgotten-password")
      )
     {
       let pageOfLocationPathname = menu.find(t => t.path === location.pathname);
       if (pageOfLocationPathname?.roles.find(t => t === loggedUser.role)) {
         history.push(location.pathname);
       }else{
        const lastUrl = localStorage.getItem("last-url");
        if (lastUrl && !(messageState || appointmentToOpenState)) {
          //controlli per ruolo 
          let page = menu.find(t => t.path === lastUrl);
          if (page?.roles.find(t => t === loggedUser.role))
            history.push(lastUrl);
          else {
            if(loggedUser.role === "superadmin"){
              history.push("/dashboard/users");
            }else if (loggedUser.role === "reception"){
              history.push("/dashboard/guests");
            }else{
              history.push("/dashboard/scheduler");
            }
          }
        }
        else {
          if(messageState) {

            if (loggedUser.role === "superadmin") {
              history.push("/dashboard/users");
            }else if (loggedUser.role === "reception"){
              history.push("/dashboard/guests");
            }else{
              history.push("/dashboard/messages");
            }
          }else{
            if(appointmentToOpenState){
              if (loggedUser.role === "superadmin") {
                history.push("/dashboard/users");
              }else if (loggedUser.role === "reception"){
                history.push("/dashboard/guests");
              }else{
                history.push("/dashboard/scheduler");
              }
            }else {
              if (loggedUser.role === "superadmin") {
                history.push("/dashboard/users");
              } else if (loggedUser.role === "reception") {
                history.push("/dashboard/guests");
              } else {
                history.push("/dashboard/scheduler");
              }
            }
          }
        }
      }
     }
    } else if (
      !localStorage.getItem("token") &&
      !location.pathname.startsWith("/register") &&
      !location.pathname.startsWith("/change-password") &&
      location.pathname !== "/forgotten-password"
    ) {
      history.push("/login");
    }
  }, [loggedUser,messageState,appointmentToOpenState]);

  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
            'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
            'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isLoading ? (
          <div style={{ height: "98vh" }}>
            <CenteredLoadingSpinner></CenteredLoadingSpinner>
          </div>
        ) : (
          <Switch>
            {/*<Redirect exact from="/" to="/login" />*/}
            <Route path="/login" component={LoginPage}></Route>
            <Route path="/register" component={RegisterPage}></Route>
            <Route path="/change-password" component={ChangePasswordPage}></Route>
            <Route
              path="/forgotten-password"
              component={ForgottenPasswordPage}
            ></Route>
            <Route path="/dashboard" component={DashboardLayout}></Route>
            <Route path="/404page" component={Error404Page}></Route>
            <Route component={Error404Page}></Route>
          </Switch>
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

function AppWrapper() {
  return (
    <LocalizationProvider language="it-IT" >
      <IntlProvider locale="it">
        {/*<MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>*/}
          <Provider store={store}>
            <App />
            <Toaster
              containerStyle={{ position: "absolute", zIndex: 10002 }}
              position={"bottom-center"}
            >
              {(t) => (
                <ToastBar toast={t}>
                  {({ icon, message }) => (
                    <div>
                      {icon}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t.message as string,
                        }}
                      />
                    </div>
                  )}
                </ToastBar>
              )}
            </Toaster>
          </Provider>
        {/*</MuiPickersUtilsProvider>*/}
      </IntlProvider>
    </LocalizationProvider>
  );
}

export default AppWrapper;
