import {createSlice, PayloadAction} from "@reduxjs/toolkit";


interface MessageState {
    currentMessage?: string | null;
}

const initialState: MessageState = {
    currentMessage: null,
};

const MessageSlice = createSlice({
    name: "Message",
    initialState,
    reducers: {
        setMessageState( state, action: PayloadAction<null | string>) {
            state.currentMessage = action.payload;
        },
    },
});

export const {
    setMessageState,
} = MessageSlice.actions;

export default MessageSlice.reducer;
