import React, {FC, useEffect, useState} from "react";
import {DateRangePickerProps} from "../../models/props/DateRangePickerProps";
import CustomDatePicker, {CustomCalendar, CustomDatePickerForRanges} from "./CustomDatePicker";
import {Error} from "@progress/kendo-react-labels";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {adjustTimezone, compareDate, toDateNoTimeFromApi} from "../../utils/dateHelper";
import {Calendar} from "@progress/kendo-react-dateinputs";
import {CalendarChangeEvent} from "@progress/kendo-react-dateinputs/dist/npm/calendar/components/Calendar";

const DateRangePickerKendo: FC<DateRangePickerProps> = (props) => {
    const [rangeValid, setRangeValid]
        = useState(false);

    const onStartChange = (date: Date) => {
        let tmpDate = adjustTimezone(date);
        props.onChange({ ...props.range, startDate: tmpDate})
    }
    const onEndChange = (date: Date) => {
        let tmpDate = adjustTimezone(date);
        props.onChange({ ...props.range, endDate: tmpDate})
    }

    const handleChangeStart = ( start : any) =>{
        if(props.range.endDate !== undefined) {
            setRangeValid(!((start.value as Date) < (props.range.endDate as Date)))
        }else{
            setRangeValid(false)
        }

        onStartChange(start.value as Date)
    }

    const handleChangeEnd = ( end : any) =>{
        if(props.range.startDate !== undefined) {
            setRangeValid(!((end.value as Date) > (props.range.startDate as Date)))
        }else{
            setRangeValid(false)
        }
        onEndChange(end.value as Date)
    }



    return (
        <GridLayout
            className={props.className ?? ''}
                rows={[{height: '55px'},{height: '20px'}]}
                cols={[
                    {width: '140px'},
                    {width: '140px'},
                ]}
                gap={{ rows: 1, cols: 10 }}

            >
            <GridLayoutItem row={1} col={1}>
                <div className={ "std-date-picker-container dropdownModifica custom-unif-dropdown-focus"} >

                    <label
                        className="labelModifica std-date-picker-label"
                        data-shrink="true"
                    >
                        Dal
                    </label>
                    <div className=" spanFiglia-daIngrandire std-date-picker-component">
                        <CustomDatePickerForRanges
                            disabled={props?.disabled ?? false}
                            name={"valid_period_start"}
                            value={props.range.startDate}
                            format="dd/MM"
                            onChange={(date: any) => {

                                handleChangeStart(date);
                                // onStartChange(date.value as Date)
                            }}
                        />
                    </div>
                </div>

            </GridLayoutItem>
            <GridLayoutItem row={1}  col={2}>
                <div className={ "std-date-picker-container dropdownModifica custom-unif-dropdown-focus"} >

                    <label
                        className="labelModifica std-date-picker-label"
                        data-shrink="true"
                    >
                        Al
                    </label>
                    <div className=" spanFiglia-daIngrandire std-date-picker-component">
                        <CustomDatePickerForRanges
                            disabled={props?.disabled ?? false}
                            name={"valid_period_start"}
                            value={props.range.endDate}
                            format="dd/MM"
                            onChange={(date: any) => {
                                handleChangeEnd(date);
                                // onEndChange(date.value as Date)
                            }}
                        />
                    </div>
                </div>

            </GridLayoutItem>

            <GridLayoutItem row={2}  col={1} colSpan={2} >
                {rangeValid && <Error>La data di fine deve essere successiva alla data iniziale</Error>}
                </GridLayoutItem>
        </GridLayout>
    )
}

export default DateRangePickerKendo