import {api} from "./api";
import {IGetCompaniesDto} from "../../models/dto/companies/IGetCompaniesDto";
import {ICompany} from "../../models/entities/ICompany";
import {IFileList} from "../../models/entities/IFileList";
import {IGetCompanyDto} from "../../models/dto/companies/IGetCompanyDto";
import {IAddCompanyDto} from "../../models/dto/companies/IAddCompanyDto";
import {IUpdateCompanyDto} from "../../models/dto/companies/IUpdateCompanyDto";


const companiesApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getCompanies: build.query<ICompany[], void>({
      query: () => ({
        url: 'company',
      }),
      transformResponse: (baseQueryReturnValue: IGetCompaniesDto, meta) => baseQueryReturnValue.companies,
      providesTags: result => result ? [...result.map(({id}) => ({type: 'Companies', id} as const)), {
        type: 'Companies',
        id: 'LIST'
      },] : [{type: 'Companies', id: 'LIST'},]
    }),
    getCompany: build.query<ICompany, number>({
      query: (id) => `company/${id}`,
      transformResponse:
          (baseQueryReturnValue: IGetCompanyDto,
                          meta) => baseQueryReturnValue.company,
      providesTags: (result, error, id) => [{type: 'Companies', id}],
    }),
    addCompany: build.mutation<ICompany, ICompany>({
      query: (company) => ({
        url: `company`,
        method: 'POST',
        body: company
      }),
      transformResponse: (res: IAddCompanyDto, meta) => res.company,
      invalidatesTags:  (result, error)  => error ? [] : [{type: 'Companies', id: 'LIST'}],
    }),
    updateCompany: build.mutation<ICompany, { id: number, company: ICompany }>({
      query: ({id, company}) => ({
        url: `company/${id}`,
        method: 'PUT',
        body: company
      }),
      transformResponse: (res: IUpdateCompanyDto, meta) => res.company,
      invalidatesTags: (result, error, {id}) => error ? [] : [{type: 'Companies', id}],
      async onQueryStarted({id, ...company}, {dispatch, queryFulfilled}) {
        const putResult = dispatch(
            companiesApi.util.updateQueryData('getCompany', id, (draft) => {
              Object.assign(draft, company)
            })
        )
        try {
          await queryFulfilled
        } catch {
          putResult.undo()
        }
        // queryFulfilled.catch(putResult.undo)
      },
    }),
    getFilesCompany: build.query< IFileList[], number>({
      query: (id) => ({
        url: `test/getFiles/${id}`,
      }),
      transformResponse:
          (res : any,
           meta) =>  res,
      providesTags: (result, error, id) => [{type: 'Files', id}],
}),
    deleteCompany: build.mutation<void, number>({
      query: (id) => ({
        url: `company/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => error ? [] : [{type: 'Companies', id}],
    }),
  }),
})

export const {
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useAddCompanyMutation,
  useUpdateCompanyMutation,
  useGetFilesCompanyQuery,
  useDeleteCompanyMutation
} = companiesApi